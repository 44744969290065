import { Modal, ModalBody } from "react-bootstrap";
import Closebutton from "../../../Utils/Icons/close.svg";
import "./BasicModal.scss";

const BasicModal = (props) => {
  const { openModal, handleClose } = props;

  return (
    <Modal show={openModal} animation={false} className="basic-modal-wrapper">
      <ModalBody className="">
        <div className="basic-modal-body">
          <div className="close-btn-container">
            <img
              className="close_btn"
              src={Closebutton}
              onClick={handleClose}
              alt="Close"
            />
          </div>
          {props.children}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default BasicModal;
