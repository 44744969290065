import React, { useEffect } from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import "./OrderLogout.scss";
import Closebutton from "../../../Utils/Icons/close2.svg";
import { string } from "../../../Localization";

const OrderLogout = ({
  openModal,
  handleLogoutClose,
  updatePersonalNotePreference,
}) => {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleLogoutClose();
    }, 15 * 60 * 1000);

    // Clear the timeout if the component is unmounted before it expires
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      <Modal show={openModal} animation={false} className="order-logout-block">
        <ModalBody className="order-logout-wrapper">
          <div className="order-logout-body">
            <div className="close-btn-container">
              <img
                className="close-btn"
                src={Closebutton}
                onClick={handleLogoutClose}
                alt="Close"
              />
            </div>
            <div className="pt-3 px-5 d-flex flex-column align-item-center">
              <div className="d-flex justify-content-center mb-4">
                <span className="title d-block ">
                  {string.THANK_YOU_FOR_YOUR_ORDER}
                </span>
              </div>
              <span className="appreciation-text mb-5">
                {string.APPRECIATION_TEXT}
              </span>
              <div className="d-flex justify-content-center">
                <Button
                  className="order-button no-button me-3"
                  onClick={() => {
                    updatePersonalNotePreference(false);
                  }}
                >
                  {string.NO}
                </Button>
                <Button
                  className="order-button yes-button"
                  onClick={() => {
                    updatePersonalNotePreference(true);
                  }}
                >
                  {string.YES}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrderLogout;
