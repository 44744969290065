import guideimg1 from "../../../Utils/Icons/guide1.svg";
import guideimg2 from "../../../Utils/Icons/guide2.svg";
import guideimg3 from "../../../Utils/Icons/guide3.svg";
import guideimg4 from "../../../Utils/Icons/guide4.svg";
import guideimg5 from "../../../Utils/Icons/guide5.svg";
import { string } from "../../../Localization";

const data = [
  {
    id: 1,
    images: guideimg1,
    body: string.QUICK_START_GUIDE_1,
  },
  {
    id: 2,
    images: guideimg2,
    body: string.QUICK_START_GUIDE_2,
  },

  {
    id: 3,
    images: guideimg3,
    body: string.QUICK_START_GUIDE_3,
  },
  {
    id: 4,
    images: guideimg4,
    body: string.QUICK_START_GUIDE_4,
  },

  {
    id: 5,
    images: guideimg5,
    body: string.QUICK_START_GUIDE_5,
  },
];

export default data;
