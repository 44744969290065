import React, { useEffect, useState } from "react";
import HpLogoBlue from "../../Utils/Icons/hp_blue.svg";
import { string } from "../../Localization";
import ShipmentLogo from "../../Utils/Icons/local_shipping.svg";
import "./Header.scss";

const Header = (props) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const { projectSummaryData } = props;

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1110);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="main-header">
      <div className={isSmallScreen ? "d-flex head" : "d-flex"}>
        {projectSummaryData?.user?.logo.trim() ||
        projectSummaryData?.user?.artist_name.trim() ? (
          <>
            {projectSummaryData?.user?.logo ? (
              <div className="customer-logo-block">
                <img
                  className="customer-logo"
                  src={projectSummaryData?.user?.logo}
                  alt=""
                />
              </div>
            ) : (
              <div className="artist-name">
                {projectSummaryData?.user?.artist_name?.trim().toUpperCase()}
              </div>
            )}
          </>
        ) : null}

        {projectSummaryData?.user?.logo.trim() ||
        projectSummaryData?.user?.artist_name.trim() ? (
          <div className="seperator" />
        ) : null}

        <div
          className={
            projectSummaryData?.user?.logo.trim() ||
            projectSummaryData?.user?.artist_name.trim()
              ? "hp-logo-block"
              : "hp-logo-block logo-container"
          }
        >
          <img className="hp-logo" src={HpLogoBlue} alt="" />
        </div>
        <div className="partnernship-text">
          {string.UNDER_LICENSE_FROM}&nbsp;
          {projectSummaryData?.user?.artist_name}&nbsp;{string.FOR_HP}
        </div>
      </div>

      <div className="d-flex  ship-container align-items-center">
        <div className="shipment-logo-block">
          <img className="shipment-logo" src={ShipmentLogo} alt=""></img>
        </div>
        <div className="content">{string.WE_ONLY_DELIVER_ORDERS_TO_UK}</div>
      </div>
    </div>
  );
};

export default Header;
