import React, { useEffect } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { handleResize } from "../../../Utils/PreviewHelper";
import PrintedCertificate from "../../Certificate/PrintedCertificate/PrintedCertificate";
import DigitalCertficate from "../../Certificate/DigitalCertficate/DigitalCertficate";
import "./CardCanvas.scss";
import { useSelector } from "react-redux";
import { string } from "../../../Localization";

const CardCanvas = () => {
  const { apiLoading } = useSelector((state) => state.modal);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!apiLoading.canShowApiLoadingModal) {
      handleResize();
    }
  }, [apiLoading]);

  return (
    <>
      <div className="card-canvas-container certificate">
        <Tabs>
          <TabList className="mt--10">
            <Tab>
              <div>{string.PRINTED_CERTIFICATE}</div>{" "}
            </Tab>
            <Tab>
              <div>{string.DIGITAL_CERTIFICATE}</div>{" "}
            </Tab>
          </TabList>

          <TabPanel>
            <div className="sizing-list printed-certidficate-scroll">
              <PrintedCertificate />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="sizing-lists">
              <DigitalCertficate />
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};

export default CardCanvas;
