import { useEffect, useState } from "react";
import Feed from "../../Components/Feed/Feed";
import "./PhotographerGallery.scss";
import Footer from "../LandingPage/Footer/Footer";
import HpLogoBlue from "../../Components/HpLogoBlue/HpLogoBlue";
import {
  getPhotographerFeed,
  getPhotographerPreviewData,
} from "../../Utils/ApiHelper";
import { string } from "../../Localization";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  sendGAClickedViewDetailsInGallery,
  sendGALoadedPhotographerGalleryPage,
} from "../../Utils/GAHelper";
import PhotographerProfile from "./PhotographerProfile/PhotographerProfile";

const PhotographerGallery = () => {
  const [data, setData] = useState([]);
  const [photographer, setPhotographerDetails] = useState();
  const [loadScreen, setLoadScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);

  const [searchParams] = useSearchParams();
  const { user_name } = useParams();

  const isPrivate = window.location.pathname.includes("/mygallery");

  const queryStrings = Object.fromEntries([...searchParams]);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 600);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isSmallScreen]);

  const feedCardDetails = (i_data) => (
    <div className="feed-detail-wrapper">
      <div className="title text mb-1">{i_data?.title}</div>
      <div className="i-price text mb-2">{i_data?.selling_price}</div>
      <a
        className="view-detail-button"
        href={
          isPrivate
            ? `/mygallery/designs/${i_data?.project_token}`
            : `/gallery/designs/${i_data?.project_token}?utm_source=gallery`
        }
      >
        <span className="view-detail-text">{string.VIEW_DETAILS}</span>
      </a>
    </div>
  );

  const getFeedDataFromAPI = (page, limit) => {
    setLoadScreen(false);
    return new Promise((resolve) => {
      getPhotographerFeed(page, limit, user_name).then((res) => {
        resolve(res);

        if (res?.photographer_details) {
          setPhotographerDetails(res?.photographer_details);
          sendGALoadedPhotographerGalleryPage(
            res?.photographer_details?.id,
            queryStrings["utm_source"] ?? ""
          );
          setLoadScreen(true);
        }
      });
    });
  };

  const getPreviewDataFromAPI = (page, limit) => {
    setLoadScreen(false);
    return new Promise((resolve, reject) => {
      getPhotographerPreviewData(user_name, page, limit)
        .then((res) => {
          console.log("res preview data", res);
          resolve(res);

          if (res?.photographer_details) {
            setPhotographerDetails(res?.photographer_details);
            sendGALoadedPhotographerGalleryPage(
              res?.photographer_details?.id,
              queryStrings["utm_source"] ?? ""
            );
            setLoadScreen(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return (
    <>
      <div className="photographer-wrapper">
        <HpLogoBlue />

        <div
          className={
            photographer?.biograpghy
              ? "photographer-feeds-wrapper private-gallery-container-bg-image"
              : "photographer-feeds-wrapper "
          }
        >
          {!isPrivate && (
            <>
              {loadScreen && (
                <>
                  {isSmallScreen ? (
                    <>
                      {" "}
                      <div className="photography_gallery_breadcrumb">
                        <a href="/gallery" className="breadcrumb_links">
                          <div>{"  <  "}</div>
                          <div>{string.HOME}</div>
                        </a>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="photography_gallery_breadcrumb">
                        <Breadcrumb
                          items={[
                            {
                              label: `${string.HOME}`,
                              link: "/gallery?utm_source=gallery",
                            },
                            {
                              label: `${photographer?.artist_name} ${string.GALLERY_1}`,
                            },
                          ]}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
          {isPrivate && (
            <PhotographerProfile
              artistName={photographer?.artist_name ?? ""}
              bio={photographer?.biography}
              photo={photographer?.display_image ?? ""}
            />
          )}
          <div
            className={
              isPrivate
                ? "photographer-name photographer_name_pt"
                : "photographer-name"
            }
          >
            {photographer?.artist_name}
          </div>
          <Feed
            data={data}
            setData={setData}
            feedCardDetails={feedCardDetails}
            getFeedDataFromAPI={
              isPrivate ? getPreviewDataFromAPI : getFeedDataFromAPI
            }
            isLightMode
          />
        </div>
        <div className="footer-wrapper">
          <Footer isLandingPage={false} />
        </div>
      </div>
    </>
  );
};

export default PhotographerGallery;
