import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import "./ProjectLoader.scss";
import { string } from "../../../Localization";

const ProjectLoader = () => {
  const { apiLoading } = useSelector((state) => state.modal);
  return (
    <Modal
      show={apiLoading.canShowApiLoadingModal}
      className="loading-modal"
      backdrop="static"
      keyboard={false}
      centered
      backdropClassName=""
    >
      <Modal.Body className={"modal-body"}>
        <div className="body-container">
          <div className="scale_spinner">
            <div className="circular-loader" />
          </div>
          <div className="modal-text">{string.LOADING}</div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProjectLoader;
