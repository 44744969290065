import React, { useState } from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import Closebutton from "../../../Utils/Icons/close.svg";
import { string } from "../../../Localization";
import backArrowSvg from "../../../Utils/Icons/arrow_back.svg";
import ProjectLoader from "../../Loaders/ProjectLoader/ProjectLoader";
import EditIcon from "../../../Utils/Icons/edit.svg";
import ProjectSpinner from "../../Loaders/ProjectSpinner/ProjectSpinner";
import { useDispatch, useSelector } from "react-redux";
import {
  sendGACheckedTermsWithAI,
  sendGAUncheckedTermsWithAI,
} from "../../../Utils/GAHelper";
import {
  showOrHideApiLoadingModal,
  showOrHideUploadAISelectModal,
} from "../../../Store/Project/ModalSlice";
import { processUploadResponse } from "../../../Utils/projectHelper";
import { uploadImageUsingUrl } from "../../../Utils/ApiHelper";

const UploadResultModal = ({
  isShowModal,
  closeClick,
  handleEdit,
  searchResults,
  handleSearchClick,
  privacyPolicy,
  setPrivacyPolicy,
  imageName,
}) => {
  const dispatch = useDispatch();
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [imgLoadedCount, setImgLoadedCount] = useState(0);
  const { footerLinksData } = useSelector((state) => state.cache);
  const { canShowUploadAISelectModal } = useSelector((state) => state.modal);

  const handleRegeneratePhoto = () => {
    setSelectedImageUrl(null);
    handleSearchClick();
    setImgLoadedCount(0);
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = currentDate.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleSelectedPhoto = () => {
    if (selectedImageUrl) {
      dispatch(showOrHideUploadAISelectModal(false));
      dispatch(showOrHideApiLoadingModal(true));

      const formData = new FormData();
      formData.append("project[imageUrl]", selectedImageUrl);
      formData.append("project[edited_date]", getCurrentDate());
      formData.append("project[title]", imageName);

      const inDetails = {
        name: "imageName",
        dimensions: "1792 X 1024",
        size: "3",
        date: getCurrentDate(),
        preview: selectedImageUrl,
      };
      uploadImageUsingUrl(formData)
        .then((res) => {
          processUploadResponse(res, inDetails);
          dispatch(showOrHideApiLoadingModal(false));
        })
        .catch((error) => {
          dispatch(showOrHideApiLoadingModal(false));
        });
    }
  };

  const increamentLoadCount = () => {
    setImgLoadedCount((prevCount) => prevCount + 1);
  };

  return (
    <>
      <ProjectLoader />
      <Modal
        show={canShowUploadAISelectModal}
        className="search-result-container-wrap"
      >
        <ModalBody className="search-result-modal-container">
          <div className="close-btn-container">
            <div className="search-result-back-container" onClick={handleEdit}>
              <img src={backArrowSvg} alt="back"></img>
              <div className="back-text">{string.EDIT}</div>
            </div>
            <img
              className="close-btn"
              src={Closebutton}
              onClick={closeClick}
              alt="Close"
            />
          </div>
          <div className="search-result-body">
            <h4 className="search-result-header">
              {string.WE_HAVE_CREATED_PHOTO_FOR_YOU}
            </h4>
            <div className="prompt-container">
              <h3 className="edit-prompt">
                {string.PROMPT}
                <span>AI</span>
              </h3>
              <img
                src={EditIcon}
                className="prompt-edit-icon"
                onClick={handleEdit}
                alt="edit"
              />
            </div>
            <div className="search-result-photos">
              {searchResults.length > 0 ? (
                searchResults.map((photo, index) => (
                  <>
                    {imgLoadedCount < searchResults.length && (
                      <ProjectSpinner
                        isLoading={true}
                        customClass="ai-result-spinner"
                      />
                    )}
                    <img
                      key={index}
                      src={photo}
                      className={`search-result-photo ${
                        selectedImageUrl
                          ? selectedImageUrl === photo
                            ? "selected"
                            : "not-selected"
                          : ""
                      }`}
                      alt={`Result ${index + 1}`}
                      onLoad={increamentLoadCount}
                      onClick={() => {
                        setSelectedImageUrl(photo);
                      }}
                    />
                  </>
                ))
              ) : (
                <div className="search-result-photo"></div>
              )}
            </div>
            <div className="terms-container">
              <label className="checkbox-container">
                <input
                  type="checkbox"
                  checked={privacyPolicy}
                  onChange={() => {
                    setPrivacyPolicy(!privacyPolicy);
                    //   if (!privacyPolicy) {
                    //     sendGACheckedTermsWithAI();
                    //   } else {
                    //     sendGAUncheckedTermsWithAI();
                    //   }
                  }}
                />
                <span className="checkmark"></span>
              </label>

              <p className="upload-image-privacy-policy">
                {string.I_HAVE_READ_AND_ACCEPT_THE}&nbsp;
                <a
                  href={footerLinksData["privacy-statement"]}
                  target="_blank"
                  rel="noreferrer"
                >
                  {string.HP} {string.PRIVACY_STATEMENT}
                </a>
                &nbsp;{string.AND}&nbsp;
                <a
                  href={footerLinksData["terms-and-conditions"]}
                  target="_blank"
                  rel="noreferrer"
                >
                  {string.TERMS_AND_CONDITIONS}
                </a>
              </p>
            </div>
            <div className="search-result-button-container">
              <Button
                className={`search-result-button select-btn ${
                  !selectedImageUrl || !privacyPolicy ? "disabled" : ""
                }`}
                onClick={handleSelectedPhoto}
                disabled={!selectedImageUrl || !privacyPolicy}
              >
                {string.USE_PHOTO}
              </Button>
              <Button
                className="search-result-button generate-more-btn"
                onClick={handleRegeneratePhoto}
              >
                {string.GENERATE_MORE}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default UploadResultModal;
