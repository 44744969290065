import React, { useEffect, useState } from "react";
import "./AdminNavBar.scss";
import UserTable from "../AdminTables/UserTable";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import MetricsTable from "../AdminTables/MetricsTable";
import OrdersTable from "../AdminTables/OrdersTable";
import SalesTable from "../AdminTables/SalesTable";
import Web2PrintOrdersTable from "../AdminTables/Web2PrintOrdersTable";
import LimitedEditionTable from "../AdminTables/LimitedEditionTable";
import {
  LIMITED_OPEN_TAB,
  METRICS_TAB,
  NFT_TAB,
  ORDERS_TAB,
  SALES_TAB,
  USAGE_SUMMARY,
  USERS_TAB,
  WEB_PRINT_ORDERS_TAB,
} from "../../../Utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { setAdminTabDetails } from "../../../Store/Project/AdminSlice";
import { AdminSearchReset } from "../../../Utils/projectHelper";
import UsageSummary from "../AdminSummary/UsageSummary";
import { string } from "../../../Localization";
import NFTTables from "../AdminTables/NFTTables";

const AdminNavBar = () => {
  const { adminTabDetails } = useSelector((state) => state.admin);
  const { currentUserDetails } = useSelector((state) => state.user);

  const [selectedTabIndex, setSelectedTabIndex] = useState(adminTabDetails);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAdminTabDetails(selectedTabIndex));
  }, [selectedTabIndex]);

  const showNftTab = currentUserDetails?.opensea_admin === true;

  return (
    <>
      <div className="admin_NavBar_wrapper">
        <Tabs defaultIndex={selectedTabIndex}>
          <TabList className="mt--10">
            <Tab
              onClick={() => {
                AdminSearchReset();
                setSelectedTabIndex(USERS_TAB);
              }}
            >
              <div className="admin_navBar_tabs">
                <div className="admin_navBar_items">{string.USERS}</div>
              </div>{" "}
            </Tab>
            <Tab
              onClick={() => {
                AdminSearchReset();
                setSelectedTabIndex(LIMITED_OPEN_TAB);
              }}
            >
              {" "}
              <div className="admin_navBar_tabs">
                <div className="admin_navBar_items">
                  {string.LIMITED_EDITIONS_OPEN_EDITION}
                </div>
              </div>{" "}
            </Tab>
            <Tab
              onClick={() => {
                AdminSearchReset();
                setSelectedTabIndex(ORDERS_TAB);
              }}
            >
              <div className="admin_navBar_tabs">
                <div className="admin_navBar_items">{string.ORDERS}</div>
              </div>{" "}
            </Tab>

            {showNftTab && (
              <Tab
                onClick={() => {
                  AdminSearchReset();
                  setSelectedTabIndex(NFT_TAB);
                }}
              >
                <div className="admin_navBar_tabs">
                  <div className="admin_navBar_items">
                    {string.PUBLIC_NFT} 's
                  </div>
                </div>
              </Tab>
            )}

            <Tab
              onClick={() => {
                AdminSearchReset();
                setSelectedTabIndex(SALES_TAB);
              }}
            >
              <div className="admin_navBar_tabs">
                <div className="admin_navBar_items">{string.SALES_ORDERS}</div>
              </div>{" "}
            </Tab>
            <Tab
              onClick={() => {
                AdminSearchReset();
                setSelectedTabIndex(WEB_PRINT_ORDERS_TAB);
              }}
            >
              <div className="admin_navBar_tabs">
                <div className="admin_navBar_items">
                  {string.WEB2PRINT_ORDERS}
                </div>
              </div>{" "}
            </Tab>
            <Tab
              onClick={() => {
                AdminSearchReset();
                setSelectedTabIndex(METRICS_TAB);
              }}
            >
              <div className="admin_navBar_tabs">
                <div className="admin_navBar_items">{string.METRICS}</div>
              </div>{" "}
            </Tab>

            <Tab
              onClick={() => {
                AdminSearchReset();
                setSelectedTabIndex(USAGE_SUMMARY);
              }}
            >
              <div className="admin_navBar_tabs">
                <div className="admin_navBar_items">{string.USAGE_SUMMARY}</div>
              </div>{" "}
            </Tab>
          </TabList>

          <TabPanel>
            <div className="sizing-list">
              <UserTable />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="sizing-list">
              <LimitedEditionTable />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="sizing-list">
              <OrdersTable />
            </div>
          </TabPanel>

          {showNftTab && (
            <TabPanel>
              <div className="sizing-list">
                {" "}
                <NFTTables />{" "}
              </div>
            </TabPanel>
          )}

          <TabPanel>
            <div className="sizing-list">
              <SalesTable />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="sizing-list">
              <Web2PrintOrdersTable />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="sizing-list">
              <MetricsTable />
            </div>
          </TabPanel>

          <TabPanel>
            <div className="sizing-list">
              <UsageSummary />
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};

export default AdminNavBar;
