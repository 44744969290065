import { string } from "../Localization";
import csc from "country-state-city";
import {
  toggleEditAddressModal,
  toggleEditCustomerModal,
} from "../Store/Project/ModalSlice";
import { setUpdatedCustomerInfo } from "../Store/Project/OrderSlice";
import { store } from "../Store/Store";
import { updateAddressDetails, updateCustomerDetails } from "./ApiHelper";
import { SUPPORTED_COUNTRY } from "./Constants";

const isEmpty = (value) => ["", undefined, null].includes(value);

export const isEmptyField = (errorHash, key, value) => {
  if (isEmpty(value)) {
    errorHash[key] = key + " " + string.CANNOT_BE_BLANK;
    return errorHash;
  } else {
    return errorHash;
  }
};

export const validMail = (mail) => {
  return /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>().,;\s@"]+\.{0,1})+([^<>().,;:\s@"]{2,}|[\d.]+))$/.test(
    mail
  );
};

export const validateAddress = (countryCode, address, setError) => {
  var errorHash = {};

  if (countryCode === SUPPORTED_COUNTRY) {
    errorHash = isEmptyField(errorHash, string.COUNTRY, address?.country.name);
    errorHash = isEmptyField(errorHash, string.POST_CODE, address?.postCode);
    errorHash = isEmptyField(
      errorHash,
      string.ADDRESS_LINE_1,
      address?.addressLine1
    );
    errorHash = isEmptyField(errorHash, string.TOWN_CITY, address?.city);
  } else {
    errorHash["Country"] = string.SELECTED_COUNTRY_IS_NOT_SUPPORTED;
  }

  setError(errorHash);
  return Object.keys(errorHash).length === 0;
};

export const handleAddressEdit = (
  countryCode,
  address,
  setError,
  order_token
) => {
  const isValid = validateAddress(countryCode, address, setError);
  if (isValid) {
    const payload = {
      country: address?.country?.name,
      countryCode: address?.country?.countryCode,
      postalCode: address?.postCode,
      addressLine1: address?.addressLine1,
      addressLine2: address?.addressLine2,
      city: address?.city,
      region: address?.county,
    };
    updateAddressDetails(order_token, payload)
      .then((res) => {
        store.dispatch(toggleEditAddressModal(false));
        setUpdatedInfoFlag();
      })
      .catch(() => {});
  }
};

const validateCustomer = (details, setError) => {
  var errorHash = {};
  errorHash = isEmptyField(errorHash, string.FIRST_NAME, details?.firstName);
  errorHash = isEmptyField(errorHash, string.LAST_NAME, details?.lastName);
  errorHash = isEmptyField(errorHash, string.PHONE_NUMBER, details?.phone);

  if (!isEmpty(details?.email)) {
    if (!validMail(details?.email))
      errorHash[string.EMAIL] = string.EMAIL_IS_INVALID;
  } else {
    isEmptyField(errorHash, string.EMAIL, details?.email);
  }

  setError(errorHash);

  return Object.keys(errorHash).length === 0;
};

const setUpdatedInfoFlag = () => {
  const { updatedCustomerInfo } = store.getState().order;
  store.dispatch(setUpdatedCustomerInfo(!updatedCustomerInfo));
};

export const handleCustomerEdit = (customerDetail, setError, order_token) => {
  const isValid = validateCustomer(customerDetail, setError);

  if (isValid) {
    const payload = {
      firstName: customerDetail?.firstName,
      lastName: customerDetail?.lastName,
      email: customerDetail?.email,
      phoneNumber: customerDetail?.phone,
    };
    updateCustomerDetails(order_token, payload)
      .then((res) => {
        store.dispatch(toggleEditCustomerModal(false));
        setUpdatedInfoFlag();
      })
      .catch(() => {});
  }
};

const countries = csc.getAllCountries();

export const defaultCountryObj = {
  id: "230",
  label: "United Kingdom",
  name: "United Kingdom",
  phonecode: "44",
  countryCode: "GB",
  value: "230",
};

export const updatedCountries = countries.map((country) => ({
  label: country.name,
  value: country.id,
  countryCode: country.sortname,
  ...country,
}));

export const getCountryByName = (name) => {
  const found = updatedCountries.filter((country) => country.name === name);
  return found ? found[0] : defaultCountryObj;
};
