import { createSlice } from "@reduxjs/toolkit";
import { ADMIN_NAV_BAR, USERS_TAB } from "../../Utils/Constants";

const initialState = {
  userSearch: "",
  userColumnKey: "",
  userTypeKey: "",
  userSearchKey: "",
  page: 0,
  adminTabDetails: USERS_TAB,
  projectToken: "",
  orderData: [],
  activePageIndex: ADMIN_NAV_BAR,
  userKeyId: "",
  orderToken: "",
  startDate: "",
  endDate: "",
};

export const AdminSlice = createSlice({
  name: "admin",
  initialState: initialState,
  reducers: {
    //UserTable
    setUserSearch(state, action) {
      state.userSearch = action.payload;
    },
    setUserColumnKey(state, action) {
      state.userColumnKey = action.payload;
    },
    setUserTypeKey(state, action) {
      state.userTypeKey = action.payload;
    },
    setUserSearchKey(state, action) {
      state.userSearchKey = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setAdminOrderDetails(state, action) {
      state.adminOrderDetails = action.payload;
    },
    setAdminTabDetails(state, action) {
      state.adminTabDetails = action.payload;
    },
    setProjectToken(state, action) {
      state.projectToken = action.payload;
    },
    setOrdersData: (state, action) => {
      state.orderData = action.payload;
    },
    setActivePageIndex(state, action) {
      state.activePageIndex = action.payload;
    },
    setUserKeyId(state, action) {
      state.userKeyId = action.payload;
    },
    setOrderToken(state, action) {
      state.orderToken = action.payload;
    },

    setFromDate(state, action) {
      state.startDate = action.payload;
    },
    setToDate(state, action) {
      state.endDate = action.payload;
    },
  },
});

export const {
  setUserSearch,
  setUserColumnKey,
  setUserTypeKey,
  setPage,
  setUserSearchKey,
  setAdminOrderDetails,
  setAdminTabDetails,
  setProjectToken,
  setOrdersData,
  setActivePageIndex,
  setUserKeyId,
  setOrderToken,
  setFromDate,
  setToDate,
} = AdminSlice.actions;
export default AdminSlice.reducer;
