import React, { useEffect, useState } from "react";
import "./AddTrackingNumber.scss";
import LogoHP from "../../Components/LogoHP/LogoHP";
import { useParams } from "react-router-dom";
import {
  footerLinks,
  getOrderInfo,
  updateTrackingInfo,
} from "../../Utils/ApiHelper";
import { string } from "../../Localization";
import { setCanShowAlertModal } from "../../Store/Project/ProjectSlice";
import { useDispatch, useSelector } from "react-redux";
import DialogBox from "../../Containers/DialogBox/DialogBox";
import footerLogo from "../../Utils/Icons/footer-logo.svg";
import ProjectLoader from "../Loaders/ProjectLoader/ProjectLoader";
import { SUPPORTED_COUNTRY } from "../../Utils/Constants";
import {
  sendGALoadedAddTackingInfo,
  sendGASubmitTackingDetails,
  sendGASubmitTackingInfo,
} from "../../Utils/GAHelper";

const AddTrackingNumber = () => {
  const { canShowAlertModal } = useSelector((state) => state.project);
  const { order_token, sec_token } = useParams();

  const dispatch = useDispatch();

  const [orderDetails, setOrderDetails] = useState([]);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [trackingUrl, setTrackingUrl] = useState("");
  const [alert, setAlert] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [links, setLinks] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    footerLinks(SUPPORTED_COUNTRY).then((res) => {
      setLinks(res?.data);
    });

    getOrderInfo(order_token)
      .then((res) => {
        const response = res?.data?.data;
        setOrderDetails(response);
        sendGALoadedAddTackingInfo(response?.user?.id, order_token);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalOpen = () => {
    dispatch(setCanShowAlertModal(true));
    setIsOpen(true);
  };

  const handleModalClose = () => {
    dispatch(setCanShowAlertModal(false));
    setIsOpen(false);
  };

  const handleTackingNumberSubmit = () => {
    if (trackingNumber) {
      const formData = new FormData();
      formData.append("tracking_number", trackingNumber);
      formData.append("tracking_url", trackingUrl);
      if (trackingNumber && trackingUrl) {
        sendGASubmitTackingDetails(order_token);
      } else if (!trackingUrl) {
        sendGASubmitTackingInfo(order_token);
      }
      updateTrackingInfo(order_token, sec_token, formData)
        .then((res) => {
          setOrderDetails(res?.data?.data);
        })
        .catch((err) => console.log(err));
    }
  };
  const handleTackingNumberChange = (e) => {
    const newValue = e.target.value;
    setTrackingNumber(newValue);
    if (newValue) {
      setAlert(false);
    } else {
      setAlert(true);
    }
  };
  const handleSubmitBtn = () => {
    if (!trackingNumber) {
      setAlert(true);
    } else {
      handleModalOpen();
    }
  };
  return (
    <>
      <ProjectLoader />
      <div className="AddTackingNumber-container ">
        <div className="AddTackingNumber-text">
          <div className="AddTackingNumber-top-header">
            <LogoHP />
          </div>
          <div className="AddTackingNumber_main container">
            <div className="AddTackingNumber_left">
              <div className="AddTackingNumber_img">
                <img
                  alt="preview"
                  className="AddPersonal_image"
                  src={orderDetails?.project?.preview}
                />
              </div>
              <div className="AddTackingNumber_info spacing">
                <div className="order-title">
                  {string.GENERAL_INFO_ABOUT_THE_PRINT}
                </div>
                <div className="order-desc-block">
                  <div className="d-flex order-desc">
                    <div className="order-desc-text order-title">
                      {string.PHOTO_TITLE}
                    </div>
                    <div className="order-desc-value">
                      {orderDetails?.project?.title}
                    </div>
                  </div>
                  <div className="d-flex order-desc">
                    <div className="order-desc-text">{string.PRINT_SIZE}:</div>
                    <div className="order-desc-value">
                      {orderDetails?.project?.print_size}
                    </div>
                  </div>
                  <div className="d-flex order-desc">
                    <div className="order-desc-text">{string.PHOTO_SIZE}</div>
                    <div className="order-desc-value">
                      {orderDetails?.project?.photo_size}
                    </div>
                  </div>
                  <div className="d-flex order-desc">
                    <div className="order-desc-text">{string.PAPER_TYPE}</div>
                    <div className="order-desc-value">
                      {orderDetails?.project?.media_type_name}
                    </div>
                  </div>
                  <div className="d-flex order-desc">
                    <div className="order-desc-text">{string.EDITION}:</div>
                    <div className="order-desc-value">
                      {orderDetails?.order?.edition}
                    </div>
                  </div>
                </div>
              </div>
              <div className="AddTackingNumber_info ">
                <div className="order-title">{string.CUSTOMER_INFORMATION}</div>
                <div className="order-desc-block">
                  <div className="d-flex order-desc">
                    <div className="order-desc-text order-title">
                      {string.NAME_1}:
                    </div>
                    <div className="order-desc-value">
                      {orderDetails?.order?.customer_name}
                    </div>
                  </div>
                  <div className="d-flex order-desc">
                    <div className="order-desc-text">
                      {string.CITY_AND_COUNTRY}:
                    </div>
                    <div className="order-desc-value">
                      {orderDetails?.order?.address?.city},{" "}
                      {orderDetails?.order?.address?.country}
                    </div>
                  </div>
                  <div className="d-flex order-desc">
                    <div className="order-desc-text">
                      {string.ORDER_NUMBER_1}:
                    </div>
                    <div className="order-desc-value">
                      {orderDetails?.order?.id}
                    </div>
                  </div>
                  <div className="d-flex order-desc">
                    <div className="order-desc-text">
                      {string.ORDER_DATE_AND_TIME}:
                    </div>
                    <div className="order-desc-value order-desc-value-2 ">
                      {orderDetails?.order?.order_date_time}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="seperator"></div>
            <div className="AddTackingNumber_bottom">
              {orderDetails?.order?.can_update_tracking_number ? (
                <div className="AddTackingNumber_textbox">
                  <div className="text-button-container">
                    <div>
                      <div className="info-title d-flex">
                        {string.PLEASE_ADD_HERE_THE_TRACKING_NUMBER}
                      </div>
                      <input
                        className="input-title"
                        type="text"
                        onChange={(e) => {
                          handleTackingNumberChange(e);
                        }}
                        value={trackingNumber}
                        placeholder="#00000000"
                      />
                      {alert ? (
                        <div className="error-div">
                          {" "}
                          {string.TRACKING_NUMBER_IS_REQUIRED}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="info-title d-flex info-title-2">
                        {string.PLEASE_ADD_HERE_THE_TRACKING_URL}
                      </div>
                      <input
                        className="input-title"
                        type="text"
                        value={trackingUrl}
                        onChange={(e) => setTrackingUrl(e.target.value)}
                        placeholder="Type here the url"
                      />
                    </div>
                  </div>

                  <div className="tracking-submit-btn-container">
                    {" "}
                    <button
                      className={`submit-btn ${
                        trackingNumber ? "active-btn" : "disable"
                      }`}
                      onClick={() => handleSubmitBtn()}
                    >
                      {string.SUBMIT}
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="tracking-feild">
                    {string.TRACKING_NUMBER_1}:{" "}
                    {orderDetails?.order?.tracking_number}
                  </div>
                  <div className="tracking-feild">
                    {string.TRACKING_URL}:{" "}
                    <span
                      className="tracking-url"
                      onClick={() =>
                        orderDetails?.order?.tracking_urlr !== "-"
                          ? window.open(
                              orderDetails.order.tracking_url,
                              "_blank"
                            )
                          : ""
                      }
                    >
                      {orderDetails?.order?.tracking_url}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="error-page-copyright">
          <div className="sub-container-footer">
            <div className="error-page-footer-img">
              <img
                src={footerLogo}
                alt="hp footer logo"
                className="error-page-footer-logo"
              />
            </div>
            <div className="error-page-footer-copyright">
              {string.HP_DEVELOPMENT_COMPANY_FOOTER}
            </div>
          </div>
          <div className="AddTackingNumber-footer-right">
            <a
              href={links["terms-and-conditions"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="AddTackingNumber-footer-text">
                {string.TERMS_AND_CONDITION_FOOTER}
              </div>
            </a>
            <a
              href={links["personal-data-rights"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="AddTackingNumber-footer-text">
                {string.PERSONAL_DATA_RIGHTS_NOTICE}
              </div>
            </a>
            <a
              href={links["cookies"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className="AddTackingNumber-footer-text"
                style={{ border: "none" }}
              >
                {string.COOKIES}
              </div>
            </a>
          </div>
        </div>
      </div>
      {isOpen && (
        <DialogBox
          customStyles={{
            width: windowWidth < 597 ? "100%" : "",
          }}
          canShow={canShowAlertModal}
          heading={string.TRACKING_NUMBER}
          text1={string.TRACKING_MESSAGE_WARNING}
          submitData={{
            submitText: string.SEND,
            submitHandler: handleTackingNumberSubmit,
            cancelBtn: string.CANCEL,
            cancelHandler: handleModalClose,
          }}
        />
      )}
    </>
  );
};

export default AddTrackingNumber;
