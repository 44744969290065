import { store } from "../Store/Store";
import {
  PAGE_CREATE_PROJECT,
  PROJECT_PREVIEW_MODE_CROPPED,
  UNIT_SYSTEM_MM,
  STANDARD_PPI,
  ONE_MB_IN_BYTES,
  BASE_URL,
} from "./Constants";
import {
  applySuperResolutionForImage,
  cancelUpload,
  fetchMediaTypes,
  fetchQrCode,
  generateTargetURL,
  sendPreviewPhoto,
  uploadImage,
  uploadToTargetURL,
} from "./ApiHelper";
import {
  setActivePreviewMode,
  setIsMagnifierModeOn,
  setMagnifierIsCompleted,
  setSizeSelectionDetails,
  setProjectToken,
  setUploadedImageData,
  setSelectedMediaTypeId,
  setPrintSizeString,
  setBorderString,
  setImagePreviewData,
  setProjectDetails,
  setIsUploadCancelled,
  setMaxSuperResolutionScale,
} from "../Store/Project/ProjectSlice";
import { setActivePageIndex } from "../Store/Project/PageSlice";
import {
  activeMediaTypeData,
  convertInchesToMm,
  convertMegaBytesToBytes,
  dataURLToBlob,
  getSelectedSizeInInches,
  Size,
  startPollingForUploadValidation,
} from "./Helper";
import {
  setShowSuperResolutionLoader,
  showOrHideUploadAIFileModal,
  showOrHideUploadAISelectModal,
  showOrHideUploadFileModal,
} from "../Store/Project/ModalSlice";
import {
  setHorizontalRefImageUrl,
  setMediaTypesData,
  setQrCodeUrl,
  setVerticalRefImageUrl,
} from "../Store/Project/CacheSlice";
import { resetAndRedirectToHomePage, resetProjectDetails } from "./DraftHelper";
import {
  sendGACancelUpload,
  sendGAUploadFail,
  sendGAUploadSuccess,
} from "./GAHelper";
import {
  updateProgressBarValue,
  updateProgressBarVisibilty,
} from "../Store/Project/ProgressBarSlice";
import {
  setFromDate,
  setToDate,
  setUserColumnKey,
  setUserSearch,
  setUserSearchKey,
  setUserTypeKey,
} from "../Store/Project/AdminSlice";
import { generatePreviewImage } from "./CanvasDownload";
import { handleSaveDraft } from "./SaveDraft";

export const setUpProject = (data) => {
  resetProjectDetails();
  const {
    project_token,
    image_dimensions,
    thumbnail_url,
    image_url,
    scale,
    maxSuperResolutionScale,
  } = data;

  const dimensions = image_dimensions;
  store.dispatch(
    setUploadedImageData({
      originalImageURL: image_url,
      imageURL: thumbnail_url,
      lowResolutionSize: Size(
        dimensions.thumbnail_width,
        dimensions.thumbnail_height
      ),
      imageScale: scale,
    })
  );
  store.dispatch(showOrHideUploadFileModal(false));
  store.dispatch(setProjectToken(project_token));
  store.dispatch(setActivePageIndex(PAGE_CREATE_PROJECT));
  store.dispatch(setMaxSuperResolutionScale(maxSuperResolutionScale));
  loadMediaTypes();
  loadQRCode();
};

export const handleUpload = (inImage, inDetails) => {
  return new Promise((resolve, reject) => {
    store.dispatch(setIsUploadCancelled(false));
    store.dispatch(updateProgressBarVisibilty(true));
    if (inImage?.size < ONE_MB_IN_BYTES * 25) {
      const formData = new FormData();
      formData.append("project[image]", inImage);
      formData.append("project[edited_date]", inDetails?.date);
      formData.append("project[upload_file_name]", inDetails?.name);
      uploadImage(formData)
        .then((res) => {
          processUploadResponse(res, inDetails);
          resolve();
        })
        .catch((res) => {
          sendGAUploadFail();
          hideUploadProgress();
          let message = null;
          if (res) {
            if (res.message) {
              message = res.message;
            } else {
              message = res.response?.data?.error_message;
            }
          }
          reject(message);
        });
    } else {
      const sizeInBytes = convertMegaBytesToBytes(inDetails?.size);
      generateTargetURL(inDetails?.date, inDetails?.name, sizeInBytes)
        .then((resp) => {
          const { token, upload_url } = resp?.data?.data;
          const { isUploadCancelled } = store.getState().project;
          if (!isUploadCancelled) {
            uploadToTargetURL(upload_url, inImage).then(() => {
              if (!isUploadCancelled) {
                startPollingForUploadValidation(token)
                  .then((res) => {
                    processUploadResponse(res, inDetails);
                    resolve();
                  })
                  .catch(() => {
                    sendGAUploadFail();
                    hideUploadProgress();
                    reject();
                  });
              }
            });
          }
        })
        .catch((res) => {
          sendGAUploadFail();
          hideUploadProgress();
          reject(res?.response?.data?.error_message);
        });
    }
  });
};

export const processUploadResponse = (res, inDetails) => {
  const {
    project_token,
    image_dimensions,
    thumbnail_url,
    image_url,
    scale,
    ai_image,
    image_token,
    sofa_ref_image,
    human_ref_image,
    max_super_resolution_scale,
  } = res?.data?.data;
  const data = {
    project_token,
    image_dimensions,
    thumbnail_url,
    image_url,
    scale,
    maxSuperResolutionScale: max_super_resolution_scale,
  };
  setUpProject(data);
  hideUploadProgress();

  setUpVirtualRefImages(sofa_ref_image, human_ref_image);

  ai_image
    ? store.dispatch(
        setProjectDetails({
          id: "title",
          value: inDetails.name,
        })
      )
    : store.dispatch(
        setProjectDetails({
          id: "title",
          value: inDetails.name.slice(0, -4).slice(0, 40),
        })
      );
  store.dispatch(
    setProjectDetails({
      id: "isAiImage",
      value: ai_image,
    })
  );

  sendGAUploadSuccess(image_token);

  store.dispatch(
    setImagePreviewData({
      title: inDetails.name,
      size: inDetails.size,
      dimension: inDetails.dimensions,
    })
  );
};

export const hideUploadProgress = () => {
  store.dispatch(updateProgressBarVisibilty(false));
  store.dispatch(updateProgressBarValue(0));
};

export const setUpVirtualRefImages = (sofa_ref_image, human_ref_image) => {
  store.dispatch(setHorizontalRefImageUrl(sofa_ref_image));
  store.dispatch(setVerticalRefImageUrl(human_ref_image));
};

export const handleUploadClose = () => {
  store.dispatch(showOrHideUploadAISelectModal(false));
  store.dispatch(setIsUploadCancelled(true));
  store.dispatch(showOrHideUploadFileModal(false));
  store.dispatch(updateProgressBarVisibilty(false));
  store.dispatch(updateProgressBarValue(0));
  store.dispatch(showOrHideUploadAIFileModal(false));
  cancelUpload();
  sendGACancelUpload();
};

export const loadQRCode = () => {
  const { qrCodeUrl } = store.getState().cache;
  if (!qrCodeUrl) {
    fetchQrCode()
      .then((response) => {
        const qrCodeUrl = response.data.data.qr_code_url;
        if (qrCodeUrl) {
          store.dispatch(setQrCodeUrl(qrCodeUrl));
        }
      })
      .catch(function (error) {
        console.error("Error:", error);
      });
  }
};

export const loadMediaTypes = () => {
  fetchMediaTypes().then((res) => {
    store.dispatch(setMediaTypesData(res.data.data));
    if (res.data.data.length > 0) {
      store.dispatch(setSelectedMediaTypeId(activeMediaTypeData()[0].id));
    }
  });
};

export const fetchMediaTypesForDraftFlow = () => {
  return new Promise((resolve) => {
    fetchMediaTypes().then((res) => {
      store.dispatch(setMediaTypesData(res.data.data));
      resolve();
    });
  });
};

export const generateProjectPreview = (croppedImageCanvas) => {
  return croppedImageCanvas.toDataURL();
};

export const applyPrintSize = () => {
  store.dispatch(setActivePreviewMode(PROJECT_PREVIEW_MODE_CROPPED));
};

export const setAndGetBestMatchDimension = () => {
  const { originalProjectSize } = store.getState().project;
  const imageAR = originalProjectSize.width / originalProjectSize.height;

  const { imageDimensions } = store.getState().project;
  const allDimensions = Object.values(imageDimensions);
  const mergedDimensions = [].concat.apply([], allDimensions);

  var closest = mergedDimensions.reduce((prev, curr) => {
    const prevSizeAR = prev.width / prev.height;
    const curSizeAR = curr.width / curr.height;

    return Math.abs(curSizeAR - imageAR) < Math.abs(prevSizeAR - imageAR) &&
      !hasPixelationWarning(curr.width, curr.height)
      ? curr
      : prev;
  });

  const defaultDimension = mergedDimensions[0];
  // if closest dimension is the default dimension
  if (closest.id === defaultDimension?.id) {
    // defaultDimensions in each orientation
    const defaultDimensions = allDimensions.map((innerArray) => innerArray[0]);

    closest = defaultDimensions.reduce((prev, curr) => {
      const prevSizeAR = prev.width / prev.height;
      const curSizeAR = curr.width / curr.height;

      return Math.abs(curSizeAR - imageAR) < Math.abs(prevSizeAR - imageAR)
        ? curr
        : prev;
    });
  }

  store.dispatch(
    setSizeSelectionDetails({ id: "bestMatchId", value: closest.id })
  );

  return closest.id;
};

export const extractCurrencyValue = (currencySymbol, currency, psp_cost) => {
  let priceValue = null;
  if (
    currencySymbol.toLowerCase() === "$" ||
    currency.toLowerCase() === "usd"
  ) {
    priceValue = parseFloat(psp_cost.split("$")[1]);
  } else if (
    currencySymbol.toLowerCase() === "£" ||
    currency.toLowerCase() === "GBP"
  ) {
    priceValue = parseFloat(psp_cost.split("£")[1]);
  }
  return priceValue;
};
export const extractCurrencyValues = (minimum) => {
  let priceValue = null;
  priceValue = parseFloat(minimum?.split("£")[1]);
  return priceValue;
};

export const handleMagnifierClick = (clickState) => {
  store.dispatch(setMagnifierIsCompleted(false));
  store.dispatch(setIsMagnifierModeOn(clickState));
};

export const getSelectedSizeDimension = () => {
  return store.getState().project.sizeSelectionDetails.selectedDimension;
};

export const setHighlightedPrintSizeValues = () => {
  const { sizeSelectionDetails, selectedUnitSystem } = store.getState().project;

  const { selectedId } = sizeSelectionDetails;
  const size = getSelectedSizeInInches(selectedId);
  const widthInInches = size.width;
  const heightInInches = size.height;

  const photoWidthString =
    selectedUnitSystem === UNIT_SYSTEM_MM
      ? `${Math.trunc(convertInchesToMm(widthInInches))}mm`
      : `${Math.trunc(widthInInches)}"`;

  const photoHeightString =
    selectedUnitSystem === UNIT_SYSTEM_MM
      ? `${Math.trunc(convertInchesToMm(heightInInches))}mm`
      : `${Math.trunc(heightInInches)}"`;

  const photoSizeString = `${photoWidthString} x ${photoHeightString}`;
  store.dispatch(setPrintSizeString(photoSizeString));
};

export const getBestMatchString = () => {
  const { sizeSelectionDetails, selectedUnitSystem } = store.getState().project;

  const { bestMatchId } = sizeSelectionDetails;
  const size = getSelectedSizeInInches(bestMatchId);
  const widthInInches = size?.width;
  const heightInInches = size?.height;

  const photoWidthString =
    selectedUnitSystem === UNIT_SYSTEM_MM
      ? `${Math.trunc(convertInchesToMm(widthInInches))}mm`
      : `${Math.trunc(widthInInches)}"`;

  const photoHeightString =
    selectedUnitSystem === UNIT_SYSTEM_MM
      ? `${Math.trunc(convertInchesToMm(heightInInches))}mm`
      : `${Math.trunc(heightInInches)}"`;

  return `${photoWidthString} x ${photoHeightString}`;
};

export const setHighlightedBorderValues = () => {
  const { selectedUnitSystem, borderWidth } = store.getState().project;
  const borderValue =
    selectedUnitSystem === UNIT_SYSTEM_MM
      ? Math.trunc(convertInchesToMm(borderWidth))
      : borderWidth;

  store.dispatch(setBorderString(borderValue));
};

export const hasPixelationWarning = (inWidthInches, inHeightInches) => {
  const { uploadedImageData } = store.getState().project;
  const { lowResolutionSize, imageScale } = uploadedImageData;
  const actualImageWidth = lowResolutionSize.width * imageScale;
  const actualImageHeight = lowResolutionSize.height * imageScale;
  if (
    actualImageWidth < inWidthInches * STANDARD_PPI ||
    actualImageHeight < inHeightInches * STANDARD_PPI
  ) {
    return true;
  }
  return false;
};

const bestSuperResolutionScale = (actualSize, printSize) => {
  const { selectedScale } = store.getState().project.superResolution;

  const { actualImageWidth, actualImageHeight } = actualSize;
  const { printSizeWidth, printSizeHieght } = printSize;

  const scales = Array.from(
    { length: getMaximumSuperResolutionScale() },
    (_, index) => index + 1
  );

  let bestScale = scales.find((scale) => {
    return (
      (scale * actualImageWidth) / selectedScale > printSizeWidth &&
      (scale * actualImageHeight) / selectedScale > printSizeHieght
    );
  });
  return bestScale;
};

export const getMaximumSuperResolutionScale = () =>
  store.getState().project.superResolution.maximumScale;

export const getPixelatedMessage = (inWidthInches, inHeightInches) => {
  const { uploadedImageData } = store.getState().project;
  const { lowResolutionSize, imageScale } = uploadedImageData;

  const actualImageWidth = Math.round(lowResolutionSize.width * imageScale);
  const actualImageHeight = Math.round(lowResolutionSize.height * imageScale);

  const printSizeWidth = inWidthInches * STANDARD_PPI;
  const printSizeHieght = inHeightInches * STANDARD_PPI;

  const bestScale = bestSuperResolutionScale(
    { actualImageWidth, actualImageHeight },
    {
      printSizeWidth,
      printSizeHieght,
    }
  );

  if (
    actualImageWidth < printSizeWidth ||
    actualImageHeight < printSizeHieght
  ) {
    return (
      `The image quality (${actualImageWidth} X ${actualImageHeight}) is not good enough to cover the print area (${printSizeWidth} X ${printSizeHieght}).<br/> ` +
      (bestScale
        ? `You can increase the image size with our AI tool. <b>${bestScale}x</b> is recommended for the selected print area.`
        : "")
    );
  }
  return "";
};

// generate preview image with out edition and signature
export const sendPhotoPreview = () => {
  return new Promise((resolve) => {
    const projectToken = store.getState().project.projectToken;
    generatePreviewImage(true).then((image) => {
      let photoPreview = dataURLToBlob(image);
      const formData = new FormData();
      formData.append("display_image", photoPreview);
      sendPreviewPhoto(formData, projectToken).then(() => {});
      resolve();
    });
  });
};

export const applySuperResolution = (resolution) => {
  store.dispatch(setShowSuperResolutionLoader(true));
  const projectToken = store.getState().project?.projectToken;
  handleSaveDraft(true)
    .then(() => {
      const payload = {
        superResolutionScale: resolution,
      };

      applySuperResolutionForImage(payload, projectToken)
        .then(() => {
          resetAndRedirectToHomePage();
          store.dispatch(setShowSuperResolutionLoader(false));
        })
        .catch((error) => {
          console.log("error", error);
          store.dispatch(setShowSuperResolutionLoader(false));
        });
    })
    .catch((error) => {
      console.log("error", error);
      store.dispatch(setShowSuperResolutionLoader(false));
    });
};

export const AdminSearchReset = () => {
  store.dispatch(setUserSearch(""));
  store.dispatch(setUserSearchKey(""));
  store.dispatch(setUserTypeKey(""));
  store.dispatch(setUserColumnKey(""));
  store.dispatch(setFromDate(""));
  store.dispatch(setToDate(""));
};
export function stringAvatar(i_name, i_letters) {
  const trimmedName = i_name.trim();
  if (!trimmedName) {
    return;
  } else {
    const nameArr = trimmedName.split(" ");
    if (nameArr.length === 1) {
      return {
        children: trimmedName[0].toUpperCase(),
      };
    } else {
      const initials = i_letters;
      return {
        children: initials.toUpperCase(),
      };
    }
  }
}
export const redirectToHomePage = () => {
  window.location.replace(`${BASE_URL}`);
};
