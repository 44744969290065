import { fabric } from "fabric";
import {
  createVirtualCanvas,
  getScaledInchesToPixels,
  insetSize,
} from "./Helper";
import { store } from "../Store/Store";
import { NO_OF_COPIES } from "./Constants";
import { setSignatureScale } from "../Store/Project/ProjectSlice";
import { getEditionData, getSignatureCoords } from "./PersonalizationHelper";

var FontFaceObserver = require("fontfaceobserver");

export const createCanvas = (inSize) => {
  const canvas = createVirtualCanvas();
  canvas.setDimensions({ width: inSize.width, height: inSize.height });
  return canvas;
};

const preparePreviewData = () => {
  const { projectImageScale, uploadedImageData, projectCropRect, borderWidth } =
    store.getState().project;
  const { signatureImage, canShowSignature } = store.getState().signature;

  const previewData = {
    previewSource: uploadedImageData.imageURL,
    imageScale: projectImageScale,
    cropRect: projectCropRect,
    borderWidth: borderWidth,
  };
  if (canShowSignature) {
    previewData["signatureData"] = {
      image: signatureImage,
      coords: getSignatureCoords(),
    };
  }
  const editionData = getEditionData();
  if (editionData) {
    previewData["editionData"] = editionData;
  }
  return previewData;
};

export const generatePreviewImage = (isPhotoOnlyPreview = false) => {
  // "isSizeOnlyPreview => true" when preview image with out Signature and edition is required
  const previewData = preparePreviewData();
  return createPreviewImage(previewData, isPhotoOnlyPreview);
};

export const createPreviewImage = (inPreviewData, isPhotoOnlyPreview) => {
  return new Promise((resolve) => {
    const {
      previewSource,
      imageScale,
      cropRect,
      borderWidth,
      signatureData,
      editionData,
    } = inPreviewData;

    const { left: x, top: y, width, height } = cropRect;
    const cropCanvas = createCanvas({
      width: width,
      height: height,
    });

    renderImage(cropCanvas, {
      imageSrc: previewSource,
      imageScale: imageScale,
    }).then(() => {
      const cropped = cropCanvas.toDataURL({
        left: x,
        top: y,
        width: width,
        height: height,
      });

      const projectCanvas = createCanvas({
        width: width,
        height: height,
      });
      renderImageWithBorderInPreview(
        projectCanvas,
        cropped,
        cropRect,
        borderWidth
      ).then(() => {
        if (isPhotoOnlyPreview) {
          resizePreviewImage(projectCanvas).then((myResizedData) => {
            resolve(myResizedData);
          });
        } else {
          renderSignatureInPreview(projectCanvas, signatureData).then(() => {
            renderLimitedEditionInPreview(projectCanvas, editionData).then(
              () => {
                resizePreviewImage(projectCanvas).then((myResizedData) => {
                  resolve(myResizedData);
                });
              }
            );
          });
        }
      });
    });
  });
};

const renderImageWithBorderInPreview = (
  inCanvas,
  imageSrc,
  inCropRect,
  inBorderWidth
) => {
  return new Promise((resolve) => {
    const borderThicknessInPixels = getScaledInchesToPixels(inBorderWidth);

    const newSizeInPixels = insetSize(
      { width: inCropRect.width, height: inCropRect.height },
      borderThicknessInPixels
    );

    const { width: borderedWidth, height: borderedHeight } = inCropRect;
    const { width: widthMinusBorder, height: heightMinusBorder } =
      newSizeInPixels;

    const scale =
      widthMinusBorder > heightMinusBorder
        ? widthMinusBorder / borderedWidth
        : heightMinusBorder / borderedHeight;

    renderImage(inCanvas, {
      imageSrc: imageSrc,
      imageScale: scale,
      isCenterAlign: true,
    }).then(() => {
      const rect = new fabric.Rect({
        layer: "Border",
        height: borderedHeight,
        width: borderedWidth,
        left: -borderThicknessInPixels,
        top: -borderThicknessInPixels,
        selectable: false,
        fill: "rgba(0, 0, 0, 0)",
        strokeWidth: borderThicknessInPixels * 2,
        stroke: "white",
      });
      inCanvas.add(rect);
      resolve();
    });
  });
};

const resizePreviewImage = (projectCanvas) => {
  return new Promise((resolve) => {
    const bigPreviewImageSrc = projectCanvas.toDataURL();
    const bigPreviewImage = new Image();
    bigPreviewImage.src = bigPreviewImageSrc;
    bigPreviewImage.onload = function () {
      const imageAR = bigPreviewImage.height / bigPreviewImage.width;
      var resizedSmallCanvas = document.createElement("canvas");
      let smallCanvasWidth = 1000;
      let smallCanvasHeight = 1000;
      if (bigPreviewImage.width > bigPreviewImage.height) {
        smallCanvasHeight = 1000 * imageAR;
      } else {
        smallCanvasWidth = 1000 / imageAR;
      }
      resizedSmallCanvas.width = smallCanvasWidth;
      resizedSmallCanvas.height = smallCanvasHeight;
      var resizedContext = resizedSmallCanvas.getContext("2d");
      resizedContext.drawImage(
        bigPreviewImage,
        0,
        0,
        bigPreviewImage.width,
        bigPreviewImage.height,
        0,
        0,
        smallCanvasWidth,
        smallCanvasHeight
      );

      resolve(resizedSmallCanvas.toDataURL("image/png"));
    };
  });
};

export const renderSignatureInPreview = (inCanvas, inData) => {
  if (inData) {
    const { image, coords } = inData;
    return renderImage(inCanvas, {
      imageSrc: image,
      imageScale: 1,
      isCenterAlign: false,
      coords: coords,
    });
  } else {
    return Promise.resolve();
  }
};

export const renderLimitedEditionInPreview = (inCanvas, inData) => {
  return new Promise((resolve) => {
    if (inData) {
      const { text, font, coords } = inData;
      var fontObj = new FontFaceObserver(font, {
        weight: 400,
      });
      fontObj.load().then(function () {
        const textContent = new fabric.Text(text, {
          fontFamily: font,
          id: NO_OF_COPIES,
          fill: "#212121",
          fontWeight: 400,
          fontSize: coords.fontSize,
          textAlign: "center",
          editable: false,
          borderScaleFactor: 4,
          left: coords.x,
          top: coords.y,
        });
        inCanvas.add(textContent);
        resolve();
      });
    } else {
      resolve();
    }
  });
};

export const renderImage = (inCanvas, inImageDetails) => {
  return new Promise((resolve) => {
    const { imageSrc, imageScale, isCenterAlign, coords, id } = inImageDetails;
    if (!imageSrc) {
      resolve();
      return;
    }
    var imageEle = new Image();
    imageEle.crossOrigin = "Anonymous";
    imageEle.src = imageSrc;

    imageEle.onload = function () {
      const image = new fabric.Image(imageEle);
      image.layer = "Image";
      inCanvas.add(image);
      image.scale(imageScale).setCoords();

      if (isCenterAlign) {
        image.viewportCenter().setCoords();
      }
      if (coords) {
        const newScale = coords.width / image.width;
        store.dispatch(setSignatureScale(newScale)); //to be used in "updateEditionSignaturePlotDetails"
        image.scale(newScale).setCoords();
        image.left = coords.x;
        image.top = coords.y;
        image.borderScaleFactor = 4;
        image.setCoords();
      }

      if (id) {
        image.id = id;
      }

      resolve();
    };
  });
};

/* Download image 

async function downloadImage(imageSrc) {
  const image = await fetch(imageSrc);
  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);

  const link = document.createElement("a");
  link.href = imageURL;
  link.download = "image file name here";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

*/
