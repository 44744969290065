import React from "react";
import LogoHP from "../../Components/LogoHP/LogoHP";
import welcomeImage from "../../Utils/Icons/welcome-image.jpg";
import ProfileButton from "../ProfileButton/ProfileButton";
import { string } from "../../Localization";
import SinglePageFooter from "../../Components/ModalFooter/SinglePageFooter";
import { reActivateAccount } from "../../Utils/ApiHelper";
import { redirectToHomePage } from "../../Utils/projectHelper";
import { setCurrentUserDetails } from "../../Store/Project/UserSlice";
import { setCanShowStatusModal } from "../../Store/Project/ProjectSlice";
import { useDispatch, useSelector } from "react-redux";
import DialogBox from "../DialogBox/DialogBox";
import ProjectLoader from "../../Components/Loaders/ProjectLoader/ProjectLoader";
import { showOrHideApiLoadingModal } from "../../Store/Project/ModalSlice";

const ReActivateAccount = () => {
  const { currentUserDetails } = useSelector((state) => state.user);
  const { canShowStatusModal } = useSelector((state) => state.project);

  const dispatch = useDispatch();

  const handleReactivateAccount = () => {
    dispatch(showOrHideApiLoadingModal(true));

    reActivateAccount()
      .then((res) => {
        if (res?.status == 200) {
          dispatch(setCurrentUserDetails(res?.data?.data));
          dispatch(setCanShowStatusModal(false));
          redirectToHomePage();
          dispatch(showOrHideApiLoadingModal(false));
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const cancelHandler = () => {
    dispatch(setCanShowStatusModal(false));
  };

  const ReActivateHandler = () => {
    handleReactivateAccount();
  };

  return (
    <>
      <ProjectLoader />
      <div className="welcome-page-component">
        <div className="welcome-page-text-container">
          <div className="welcome-text">
            <div className="welcome-top-header">
              <LogoHP />
              <div className="welcome-profile-button-container">
                <ProfileButton />
              </div>
            </div>
            <div className="welcome-page-text">
              <div className="welcome-to-HP-limited-editions reactivate_w1">
                {string.WELCOME_BACK_TO_LIMITED_EDITIONS}
              </div>

              <div className="limited-editions-container">
                <div className="tr1 reactivate_w2">
                  {string.WELCOME_TO_HP_LIMITED_EDITIONS_TEXT_1}
                  <br />
                  {string.WELCOME_TO_HP_LIMITED_EDITIONS_TEXT_2}{" "}
                  {currentUserDetails?.reactivation_allowed_date}{" "}
                  {string.WELCOME_TO_HP_LIMITED_EDITIONS_TEXT_3}
                </div>
                <button
                  className="reactivate_page_btn enable_username"
                  onClick={() => {
                    dispatch(setCanShowStatusModal(true));
                  }}
                >
                  {string.RE_ACTIVATE_ACCOUNT}
                </button>
              </div>
            </div>
            <div className="welcome-page-copyright">
              <SinglePageFooter />
            </div>
          </div>
        </div>
        <div className="welcome-image-container">
          <img
            src={welcomeImage}
            alt="welcome-image"
            className="welcome-image"
          />
        </div>
      </div>
      <DialogBox
        canShow={canShowStatusModal}
        heading={string.REACTIVATE_ACCOUNT}
        text1={string.REACTIVATE_ACCOUNT_TEXT1}
        text2={string.REACTIVATE_ACCOUNT_TEXT2}
        submitData={{
          submitText: string.RE_ACTIVATE_ACCOUNT,
          submitHandler: ReActivateHandler,
          cancelBtn: string.CANCEL,
          cancelHandler: cancelHandler,
        }}
      />
    </>
  );
};

export default ReActivateAccount;
