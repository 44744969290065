import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./RangeDatePicker.scss";
import { format } from "date-fns";
import { Icons } from "../../../Utils/Icons";
import downloadIcon from "../../../Utils/Icons/download-icon.svg";
import { Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { setFromDate, setToDate } from "../../../Store/Project/AdminSlice";
import { string } from "../../../Localization";

const RangeDatePicker = () => {
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState(["", ""]);
  const [formatedFromDate, setFormatedFromDate] = useState("");
  const [formatedEndDate, setFormatedEndDate] = useState("");
  const [isDisabled, setDisabled] = useState(true);

  const currentDate = new Date();

  const handleCancel = () => {
    setDateRange([null, null]);
    dispatch(setFromDate(""));
    dispatch(setToDate(""));
    setFormatedFromDate("");
    setFormatedEndDate("");
    setDisabled(true);
  };

  const handleFilter = (startDate, endDate) => {
    dispatch(setFromDate(startDate));
    dispatch(setToDate(endDate));
  };

  return (
    <div className="date-picker-container">
      <div className="date-sub-container">
        <div className="date-range-picker">
          <DatePicker
            onChange={(range) => {
              setDateRange(range);
              const [start, end] = range;
              if (start && end) {
                setDisabled(false);
                let startDate = format(start, "yyyy-MM-dd");
                let endDate = format(end, "yyyy-MM-dd");
                setFormatedFromDate(startDate);
                setFormatedEndDate(endDate);
              }
            }}
            startDate={dateRange[0]}
            endDate={dateRange[1]}
            selectsRange
            dateFormat="yyyy-MM-dd"
            placeholderText="Select date range: YYYY-MM-DD to YYYY-MM-DD"
            maxDate={currentDate}
          />
        </div>
        <div className="buttons">
          <Tooltip
            title={
              isDisabled ? "Apply a valid date range to enable this button" : ""
            }
          >
            <div>
              <button
                className={`apply-btn filter-btn`}
                onClick={() => handleFilter(formatedFromDate, formatedEndDate)}
                disabled={isDisabled}
              >
                {string.FILTER}{" "}
                <span>
                  <img src={Icons.filterIcon} alt="fliter" />
                </span>
              </button>
            </div>
          </Tooltip>

          <a
            className="apply-btn filter-btn"
            href={`/admins/download_usage_metrics?start_date=${formatedFromDate}&end_date=${formatedEndDate}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {string.DOWNLOAD}
            <span>
              <img src={downloadIcon} alt="download" />
            </span>
          </a>
        </div>
      </div>

      {dateRange[0] && dateRange[1] && (
        <div className="buttons">
          <button className="cancle-btn" onClick={handleCancel}>
            {string.CLEAR}{" "}
          </button>
        </div>
      )}
    </div>
  );
};

export default RangeDatePicker;
