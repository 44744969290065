import { setOnClickLoader } from "../Store/Project/ProjectSlice";
import { store } from "../Store/Store";
import { orderArtistProof, saveDraft } from "./ApiHelper";
import { PROJECT_PREVIEW_MODE_CROPPED } from "./Constants";
import { getProjectPayload, resetAndRedirectToHomePage } from "./DraftHelper";
import { dataURLToBlob } from "./Helper";
import { sendPhotoPreview } from "./projectHelper";

export const handleSaveDraft = (isSuperResolutionApplied = false) => {
  return new Promise((resolve) => {
    const { previewImage, projectToken, sizeApplied } =
      store.getState().project;
    const { activeStep } = store.getState().page;

    let finalPreview = previewImage ? dataURLToBlob(previewImage) : "";
    const formData = getProjectPayload(finalPreview, false);

    const functionArguments = {
      formData,
      projectToken,
      resolve,
      isSuperResolutionApplied,
    };

    // send preview only when "save as draft" is done in step 1, for the next steps "send preview" is handled in normal flow
    if (sizeApplied && activeStep < PROJECT_PREVIEW_MODE_CROPPED) {
      sendPhotoPreview().then(() => {
        continueDraft(functionArguments);
      });
    } else {
      continueDraft(functionArguments);
    }
  });
};

const continueDraft = ({
  formData,
  projectToken,
  resolve,
  isSuperResolutionApplied,
}) => {
  saveDraft(formData, projectToken)
    .then((res) => {
      console.log(res);
      if (!isSuperResolutionApplied) {
        resetAndRedirectToHomePage();
      }
      resolve();
    })
    .catch((error) => {
      console.log(error);

      store.dispatch(setOnClickLoader(false));
    });
};

export const handleOrderArtistProof = () => {
  const { projectToken, previewImage } = store.getState().project;

  let finalPreview = previewImage ? dataURLToBlob(previewImage) : "";
  const formData = getProjectPayload(finalPreview, true);

  saveDraft(formData, projectToken)
    .then((res) => {
      orderArtistProof(projectToken)
        .then((res) => {
          window.location.href = res?.data?.data?.redirect_url;
        })
        .catch((error) => {
          console.log(error);
          store.dispatch(setOnClickLoader(false));
        });
    })
    .catch((error) => {
      console.log(error);
      store.dispatch(setOnClickLoader(false));
    });
};
