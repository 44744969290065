import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  progressBarVisibilty: false,
  progressBarValue: 0,
};

export const progressBarSlice = createSlice({
  name: "progressBar",
  initialState: initialState,
  reducers: {
    updateProgressBarVisibilty(state, action) {
      state.progressBarVisibilty = action.payload;
    },
    updateProgressBarValue(state, action) {
      state.progressBarValue = action.payload;
    },
  },
});

export const { updateProgressBarVisibilty, updateProgressBarValue } =
  progressBarSlice.actions;
export default progressBarSlice.reducer;
