import React, { useState } from "react";
import "./ConfirmUsernameModal.scss";
import { Modal, ModalBody } from "react-bootstrap";
import Closebutton from "../../../Utils/Icons/close.svg";
import { setCanShowStatusModal } from "../../../Store/Project/ProjectSlice";
import { useDispatch } from "react-redux";
import { string } from "../../../Localization";

const ConfirmUsernameModal = (props) => {
  const { canShow, instaUrl, handleInstagramUpdateProfile } = props;

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setCanShowStatusModal(false));
  };
  const handleConfirmClick = () => {
    handleInstagramUpdateProfile();
    handleClose();
  };

  return (
    <Modal
      show={canShow}
      // animation={false}
      className="customise-container-wrap"
    >
      <ModalBody className="confirm_user_container_modal">
        <div className="confirm_username_modal_container">
          <div className="close-btn-container">
            <img
              className="close-btn"
              src={Closebutton}
              onClick={handleClose}
              alt="Close"
            />
          </div>
          <div className="confirm_username_modal_text_wrapper">
            <div className="confirm_username_modal_item1">
              {string.PLEASE_CONFIRM_YOUR_USERNAME}
            </div>
            <div className="confirm_username_modal_item2">
              <div className="confirm_username_modal_text1">
                {string.ARE_YOU_SURE_THIS_IS_THE_RIGHT_USERNAME}
              </div>
              <div className="confirm_username_modal_text1">
                {string.ARE_YOU_SURE_THIS_IS_THE_RIGHT_USERNAME_1}
              </div>
            </div>

            <div className="confirm_username_modal_item3">
              <span>{string.INSTAGRAM_USERNAME}*</span>
              <input
                type="text"
                className="input_title"
                placeholder="Enter username"
                value={instaUrl}
                disabled={true}
              />
            </div>

            <div className="confirm_username_modal_item4">
              <button
                className="confirm_username_btn"
                onClick={handleConfirmClick}
              >
                {string.CONFIRM}
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmUsernameModal;
