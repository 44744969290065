import React, { useEffect } from "react";
import "./SubscriptionTermsOfService.scss";
import { sendGALoadedSubscriptionTermsOfService } from "../../../../../Utils/GAHelper";
import { string } from "../../../../../Localization";

const SubscriptionTermsOfService = () => {
  const listItems = [
    string.INTERPRETATION_PT_1,
    string.INTERPRETATION_PT_2,
    string.INTERPRETATION_PT_3,
    string.INTERPRETATION_PT_4,
    string.INTERPRETATION_PT_5,
    string.INTERPRETATION_PT_6,
    string.INTERPRETATION_PT_7,
    string.INTERPRETATION_PT_8,
  ];

  useEffect(() => {
    sendGALoadedSubscriptionTermsOfService();
  }, []);

  return (
    <div className="subscriptionTermsOfService-container">
      <div className="main-container">
        <div className="title-block">
          <h6 className="text-normal">
            {
              string.HP_LIMITED_EDITION_PRINT_SERVICES_SUBSCRIBER_TERMS_OF_SERVICE
            }
          </h6>
          <h6 className="text-normal"> {string.DATE_OF_RELEASE}</h6>
        </div>
        <h1 className="heading letter-spacing">
          {
            string.HP_LIMITED_EDITION_PRINT_SERVICES_SUBSCRIBER_TERMS_OF_SERVICE_1
          }
        </h1>
        <p className="contents">
          {
            string.HP_LIMITED_EDITION_PRINT_SERVICES_SUBSCRIBER_TERMS_OF_SERVICE_2
          }{" "}
          <u>
            <a
              href="https://www.hp.com/gb-en/terms-of-use.html"
              target="_blank"
              rel="noreferrer"
            >
              {string.HP_STANDARD_WEBSITE_TERMS_OF_USE}
            </a>
          </u>{" "}
          ({string.COLLECTIVELY_THE} <strong>"{string.AGREEMENT}"</strong>){" "}
          {string.SUBSCRIBER_TERMS_CONTENT_1a} (<strong>“{string.HP}"</strong>{" "}
          {string.OR} <strong>"{string.WE}"</strong> {string.OR}{" "}
          <strong>"{string.US}"</strong> {string.OR}{" "}
          <strong>“{string.OUR}”</strong>) {string.SUBSCRIBER_TERMS_CONTENT_1b}{" "}
          (<strong>"{string.YOU}"</strong> {string.OR}
          <strong> "{string.YOUR}"</strong> {string.OR}{" "}
          <strong>“{string.SUBSCRIBER}”</strong>){" "}
          {string.SUBSCRIBER_TERMS_CONTENT_1c}{" "}
          <strong>“{string.PARTY}” </strong>
          {string.SUBSCRIBER_TERMS_CONTENT_1d}
          <strong>“{string.PARTIES}”</strong>.
        </p>
        <p className="contents">{string.SUBSCRIBER_TERMS_CONTENT_2}</p>
        <p className="contents">{string.SUBSCRIBER_TERMS_CONTENT_3}</p>
        <p className="contents">
          {string.SUBSCRIBER_TERMS_CONTENT_4a}{" "}
          <a
            href="/docs/terms/uk/photographer/payment-terms-of-service.html"
            target="_blank"
            rel="noreferrer"
          >
            {string.SUBSCRIBER_TERMS_CONTENT_4_LINK}{" "}
          </a>
          {string.SUBSCRIBER_TERMS_CONTENT_4b} ({string.THE}{" "}
          <strong>“{string.SUBSCRIBER_TERMS_CONTENT_4c}”</strong>).
        </p>
        <p className="contents">{string.SUBSCRIBER_TERMS_CONTENT_5} </p>
        <p className="contents">{string.SUBSCRIBER_TERMS_CONTENT_8} </p>{" "}
        <p className="contents">{string.SUBSCRIBER_TERMS_CONTENT_7} </p>
        <ol className="main-order-list">
          <li>
            <span className="main-list">{string.INTERPRETATION}.</span>
            {listItems.map((item, index) => (
              <div key={index} className="list-elements">
                <span>{`1.${index + 1}`}</span>
                <div>{`${item}`}</div>
              </div>
            ))}
          </li>
          <li>
            <span className="main-list"> {string.DEFINITIONS}.</span>
            <p className="list-element-content">
              <strong>{string.ACCEPTED_IMAGES}: </strong>
              {string.DIGITAL_IMAGES_UPLOADED_BY_YOU_TO_WEBSITE}
            </p>
            <p className="list-element-content">
              <strong>{string.AFFILIATE}: </strong>
              {string.DEFINITIONS_PT_2}
            </p>
            <p className="list-element-content">
              <strong>{string.COLLECTOR}: </strong> {string.DEFINITIONS_PT_3}
            </p>
            <p className="list-element-content">
              <strong>{string.CONFIDENTIAL_INFORMATION}: </strong>
              {string.DEFINITIONS_PT_4} 24 ({" "}
              <strong>“{string.NOTICES_1}”</strong>).
            </p>
            <p className="list-element-content">
              <strong>{string.HP_ENTERPRISE_LEDGER}: </strong>
              {string.HP_ENTERPRISE_LEDGER_TEXT_1}
            </p>
            <p className="list-element-content">
              <strong>{string.DIGITAL_IMAGE}:</strong>{" "}
              {string.DIGITAL_IMAGE_TEXT_1}
            </p>
            <p className="list-element-content">
              <strong>{string.DOCUMENTATION}: </strong>{" "}
              {string.DEFINITIONS_PT_7}
            </p>
            <p className="list-element-content">
              <strong>{string.INTELLECTUAL_PROPERTY_RIGHTS}: </strong>
              {string.DEFINITIONS_PT_8}
            </p>
            <p className="list-element-content">
              <strong>{string.NORMAL_BUISNESS_HOUR}: 1 </strong>
              {string.DEFINITIONS_PT_9}
            </p>
            <p className="list-element-content">
              <strong>{string.RESELLER}: </strong>
              {string.DEFINITIONS_PT_10}{" "}
              <a
                href="/docs/terms/uk/photographer/payment-terms-of-service.html"
                target="_blank"
                rel="noreferrer"
              >
                {string.SUBSCRIBER_TERMS_CONTENT_4_LINK}
              </a>
              .
            </p>
            <p className="list-element-content">
              <strong>{string.SERVICES}: </strong>
              {string.DEFINITIONS_PT_11}
            </p>
            <p className="list-element-content">
              <strong>{string.SOFTWARE}: </strong> {string.DEFINITIONS_PT_12}
            </p>
            <p className="list-element-content">
              <strong>{string.SUPPORT_SERVICES}: </strong>{" "}
              {string.DEFINITIONS_PT_13}
            </p>
            <p className="list-element-content">
              <strong>{string.STANDARD_SUBSCRIPTION}: </strong>{" "}
              {string.STANDARD_SUBSCRIPTION_TEXT_1}
            </p>
            <p className="list-element-content">
              <strong>
                {string.PRO}
                <span>-</span>
                {string.SUBSCRIPTION}:{" "}
              </strong>
              {string.DEFINITIONS_PT_15}
            </p>
            <p className="list-element-content">
              <strong>{string.SUBSCRIPTION_FEE}: </strong>{" "}
              {string.DEFINITIONS_PT_16}
            </p>
            <p className="list-element-content">
              <strong>{string.SUBSCRIPTION_TERM}: </strong>
              {string.DEFINITIONS_PT_17} 14.
            </p>
            <p className="list-element-content">
              <strong>{string.VIRUS}: </strong> {string.DEFINITIONS_PT_18}
            </p>
            <p className="list-element-content">
              <strong>{string.VULNERABILITY}: </strong>{" "}
              {string.DEFINITIONS_PT_19}{" "}
              <strong>“{string.VULNERABILITIES}”</strong>{" "}
              {string.DEFINITIONS_PT_20a}
            </p>
            <p className="list-element-content">
              <strong>{string.WEBSITE}: </strong>
              <a
                href="https://www.hplimiteditions.com/"
                target="_blank"
                rel="noreferrer"
              >
                https://www.hplimiteditions.com/
              </a>
              {string.DEFINITIONS_PT_20}
            </p>
          </li>
          <li>
            <span className="main-list">
              {string.SUBMISSION_ACCEPTANCE_PROPERTY_IMAGES}.
            </span>
            <div className="list-elements">
              <span>3.1</span>
              <div>{string.SUBMISSION_ACCEPTANCE_PROPERTY_IMAGES_PT_1}</div>
            </div>
            <div className="list-elements">
              <span>3.2</span>
              <div>{string.SUBMISSION_ACCEPTANCE_PROPERTY_IMAGES_PT_2}</div>
            </div>
            <div className="list-elements">
              <span>3.3</span>
              <div>
                {string.SUBMISSION_ACCEPTANCE_PROPERTY_IMAGES_PT_3a}:
                <i> "[{string.CONTRIBUTORS}]"</i>,{" "}
                {string.SUBMISSION_ACCEPTANCE_PROPERTY_IMAGES_PT_3ab}
              </div>
            </div>
            <div className="list-elements">
              <span>3.4</span>
              <div>{string.SUBMISSION_ACCEPTANCE_PROPERTY_IMAGES_PT_4}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.USER_SUBSCRIPTION}.</span>
            <div className="list-elements">
              <span>4.1</span>
              <div>{string.USER_SUBSCRIPTION_PT_1}</div>
            </div>
            <div className="list-elements">
              <span>4.2</span>
              <div>{string.USER_SUBSCRIPTION_PT_2}</div>
            </div>
            <div className="list-elements">
              <span>4.3</span>
              <div>
                {string.USER_SUBSCRIPTION_PT_3}
                <ol type="a" className="suborder-list">
                  <li>{string.USER_SUBSCRIPTION_PT_3a}</li>
                  <li>{string.USER_SUBSCRIPTION_PT_3b} </li>
                  <li>{string.USER_SUBSCRIPTION_PT_3c}</li>
                  <li>{string.USER_SUBSCRIPTION_PT_3d}</li>
                  <li>{string.USER_SUBSCRIPTION_PT_3e}</li>
                  <li>{string.USER_SUBSCRIPTION_PT_3g}</li>
                </ol>
              </div>
            </div>
            <div className="list-elements">
              <span></span>
              <div>{string.USER_SUBSCRIPTION_PT_3h}</div>
            </div>
            <div className="list-elements">
              <span>4.4</span>
              <div>
                {string.USER_SUBSCRIPTION_PT_4}
                <ol type="i" className="suborder-list">
                  <li>
                    {string.USER_SUBSCRIPTION_PT_4a}
                    <ol type="a">
                      <li>{string.USER_SUBSCRIPTION_PT_4a1}</li>
                      <li>{string.USER_SUBSCRIPTION_PT_4a2}</li>
                    </ol>
                  </li>
                  <li>{string.USER_SUBSCRIPTION_PT_4b}</li>
                  <li>{string.USER_SUBSCRIPTION_PT_4c}</li>
                  <li>{string.USER_SUBSCRIPTION_PT_4d}</li>
                  <li>{string.USER_SUBSCRIPTION_PT_4e}</li>
                  <li>{string.USER_SUBSCRIPTION_PT_4f}</li>
                </ol>
              </div>
            </div>
            <div className="list-elements">
              <span>4.5</span>
              <div>{string.USER_SUBSCRIPTION_PT_5}</div>
            </div>
            <div className="list-elements">
              <span>4.6</span>
              <div>{string.USER_SUBSCRIPTION_PT_6}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.SUBSCRIPTION_SERVICE}</span>
            <div className="list-elements">
              <span>5.1</span>
              <div>
                <u>{string.SERVICES}.</u> {string.SUBSCRIPTION_SERVICE_PT_1}
              </div>
            </div>
            <div className="list-elements">
              <span>5.2</span>
              <div>
                <u>{string.SUBSCRIPTION_FEE}.</u>{" "}
                {string.SUBSCRIPTION_SERVICE_PT_2}
              </div>
            </div>
            <div className="list-elements">
              <span>5.3</span>
              <div>
                <u>{string.SUPPORT_SERVICES}.</u>{" "}
                {string.SUBSCRIPTION_SERVICE_PT_3}
              </div>
            </div>
            <div className="list-elements">
              <span>5.4</span>
              <div>{string.SUBSCRIPTION_SERVICE_PT_4}</div>
            </div>
            <div className="list-elements">
              <span></span>
              <div>
                <u></u>
              </div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.PROPERTY_RIGHTS_LISENSE}.</span>
            <div className="list-elements">
              <span>6.1</span>
              <div>{string.PROPERTY_RIGHTS_LISENSE_PT_1} </div>
            </div>
            <div className="list-elements">
              <span>6.2</span>
              <div>
                <u>{string.PROPERTY_RIGHTS_LISENSE_PT_2u}.</u>{" "}
                {string.PROPERTY_RIGHTS_LISENSE_PT_2}
              </div>
            </div>
            <div className="list-elements">
              <span>6.3</span>
              <div>
                <u>{string.PROPERTY_RIGHTS_LISENSE_PT_3u}.</u>{" "}
                {string.PROPERTY_RIGHTS_LISENSE_PT_3}
              </div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.PAYING_SERVICES}</span>
            <div className="list-elements">
              <span>7.1</span>
              <div>{string.PAYING_SERVICES_PT_1}</div>
            </div>
            <div className="list-elements">
              <span>7.2</span>
              <div>{string.PAYING_SERVICES_PT_2}</div>
            </div>
            <div className="list-elements">
              <span>7.3</span>
              <div>{string.PAYING_SERVICES_PT_3}</div>
            </div>
            <div className="list-elements">
              <span>7.4</span>
              <div>{string.PAYING_SERVICES_PT_4}</div>
            </div>
            <div className="list-elements">
              <span>7.5</span>
              <div>
                <u>{string.PAYING_SERVICES_PT_5u}</u>{" "}
                {string.PAYING_SERVICES_PT_5}
              </div>
            </div>
            <div className="list-elements">
              <span>7.6</span>
              <div>
                <u>{string.PAYING_SERVICES_PT_6u}.</u>{" "}
                {string.PAYING_SERVICES_PT_6}
              </div>
            </div>
            <div className="list-elements">
              <span>7.7</span>
              <div>
                <u>{string.PAYING_SERVICES_PT_7u}.</u>{" "}
                {string.PAYING_SERVICES_PT_7}
              </div>
            </div>
            <div className="list-elements">
              <span>7.8</span>
              <div>
                <u>{string.PAYING_SERVICES_PT_8u}.</u>{" "}
                {string.PAYING_SERVICES_PT_8}
              </div>
            </div>
            <div className="list-elements">
              <span>7.9</span>
              <div>
                <u>{string.PAYING_SERVICES_PT_9u}.</u>{" "}
                {string.PAYING_SERVICES_PT_9a}
                <strong> “{string.PAYING_SERVICES_PT_9u}”</strong>
                {string.PAYING_SERVICES_PT_9b}
              </div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.THIRD_PARTY_PROVIDERS}.</span>
            <div className="list-elements">
              <div>{string.THIRD_PARTY_PROVIDERS_PT_1}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.CONFIDENTIALITY_AND_DATA}</span>
            <div className="list-elements">
              <span>9.1</span>
              <div>{string.CONFIDENTIALITY_AND_DATA_PT_1}</div>
            </div>
            <div className="list-elements">
              <span>9.2</span>
              <div>
                <u>{string.CONFIDENTIALITY_AND_DATA_PT_2u}.</u>{" "}
                {string.CONFIDENTIALITY_AND_DATA_PT_2}{" "}
                <u>
                  <a
                    href="https://www.hp.com/gb-en/privacy/privacy-central.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.hp.com/go/privacy
                  </a>
                </u>
                .
              </div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.YOUR_UNDERTAKINGS}</span>
            <div className="list-elements">
              <span>10.1.</span>
              <div>
                {string.YOUR_UNDERTAKINGS_PT_1}
                <ol type="i">
                  <li>{string.YOUR_UNDERTAKINGS_PT_1a}</li>
                  <li>{string.YOUR_UNDERTAKINGS_PT_1b}</li>
                  <li>{string.YOUR_UNDERTAKINGS_PT_1c}</li>
                  <li>{string.YOUR_UNDERTAKINGS_PT_1d}</li>
                  <li>{string.YOUR_UNDERTAKINGS_PT_1e}</li>
                  <li>{string.YOUR_UNDERTAKINGS_PT_1f}</li>
                  <li>
                    {string.YOUR_UNDERTAKINGS_PT_1g}
                    <ol type="a">
                      <li>{string.YOUR_UNDERTAKINGS_PT_1g1}</li>
                      <li>{string.YOUR_UNDERTAKINGS_PT_1g2}</li>
                    </ol>
                  </li>
                </ol>
              </div>
            </div>
            <div className="list-elements">
              <span>10.2</span>
              <div>{string.YOUR_UNDERTAKINGS_PT_2}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.INDEMNITY}</span>
            <div className="list-elements">
              <div>{string.INDEMNITY_PT_1}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.LIMITATION_AND_LIABILITY}</span>
            <div className="list-elements">
              {string.LIMITATION_AND_LIABILITY_PT_1i}
            </div>
            <div className="list-elements">
              {string.LIMITATION_AND_LIABILITY_PT_1ii}
            </div>
          </li>
          <li>
            <span className="main-list">
              {string.COMPLIANCE_WITH_LAWS_EXPORT}.
            </span>
            <div className="list-elements">
              {string.COMPLIANCE_WITH_LAWS_EXPORT_PT_1}
            </div>
          </li>
          <li>
            <span className="main-list">{string.TERMS_AND_TERMINATION}</span>
            <div className="list-elements">
              <span>14.1.</span>
              <div>{string.TERMS_AND_TERMINATION_PT_1}</div>
            </div>
            <div className="list-elements">
              <span>14.2</span>
              <div>{string.TERMS_AND_TERMINATION_PT_2}</div>
            </div>
            <div className="list-elements">
              <span>14.3</span>
              <div>{string.TERMS_AND_TERMINATION_PT_3}</div>
            </div>
            <div className="list-elements">
              <span>14.4</span>
              <div>
                {string.TERMS_AND_TERMINATION_PT_4}
                <ol type="i">
                  <li>{string.TERMS_AND_TERMINATION_PT_4a}</li>
                  <li>{string.TERMS_AND_TERMINATION_PT_P6}</li>
                  <li>{string.TERMS_AND_TERMINATION_PT_4c}</li>
                  <li>{string.TERMS_AND_TERMINATION_PT_4d}</li>
                </ol>
              </div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.FORCE_MAJEURE}</span>
            <div className="list-elements">
              <div>{string.FORCE_MAJEURE_PT_1}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.VARIATION}</span>
            <div className="list-elements">
              <div>{string.VARIATION_PT_1}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.WAIVER}</span>
            <div className="list-elements">
              <div>{string.WAIVER_PT_1}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.RIGHTS_AND_REMEDIES}</span>
            <div className="list-elements">
              <div>{string.RIGHTS_AND_REMEDIES_PT_1}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.SEVERANCE}</span>
            <div className="list-elements">
              <span>19.1</span>
              <div>{string.SEVERANCE_PT_1}</div>
            </div>
            <div className="list-elements">
              <span>19.2</span>
              <div>{string.SEVERANCE_PT_2}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.ENTIRE_AGREEMENT}</span>
            <div className="list-elements">
              <span>20.1</span>
              <div>{string.ENTIRE_AGREEMENT_PT_1}</div>
            </div>
            <div className="list-elements">
              <span>20.2</span>
              <div>{string.ENTIRE_AGREEMENT_PT_2}</div>
            </div>
            <div className="list-elements">
              <span>20.3</span>
              <div>{string.ENTIRE_AGREEMENT_PT_3}</div>
            </div>
            <div className="list-elements">
              <span>20.4</span>
              <div>{string.ENTIRE_AGREEMENT_PT_4}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.ASSIGNMENT}</span>
            <div className="list-elements">
              <span>21.1</span>
              <div>{string.ASSIGNMENT_PT_1}</div>
            </div>
            <div className="list-elements">
              <span>21.2</span>
              <div>{string.ASSIGNMENT_PT_2}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.NO_PARTNERSHIP_AGENCY}</span>
            <div className="list-elements">
              <div>{string.NO_PARTNERSHIP_AGENCY_PT_1}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.THIRD_PARTY_RIGHTS}</span>
            <div className="list-elements">
              <div>{string.THIRD_PARTY_RIGHTS_PT_1}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.NOTICES}</span>
            <div className="list-elements">
              <span>24.1</span>
              <div>
                {string.NOTICES_PT_1}
                <ol type="i">
                  <li>{string.NOTICES_PT_1a}</li>
                  <li>{string.NOTICES_PT_1b}</li>
                </ol>
              </div>
            </div>
            <div className="list-elements">
              <span>24.2</span>
              <div>{string.NOTICES_PT_2}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.GOVERNING_LAW}</span>
            <div className="list-elements">
              <div>{string.GOVERNING_LAW_PT_1}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.JURISDICTION}</span>
            <div className="list-elements">
              <div>{string.JURISDICTION_PT_1}</div>
            </div>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default SubscriptionTermsOfService;
