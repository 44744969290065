import "./UploadImage.scss";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  convertBytesToMegaBytes,
  getImageDimension,
  formatDateTime,
  getFileExtension,
  getPreviewUrlFromFile,
} from "../../../Utils/Helper";
import { handleUpload, handleUploadClose } from "../../../Utils/projectHelper";
import Dropzone from "react-dropzone";
import uploadSvg from "../../../Utils/Icons/upload.svg";
import closeSvg from "../../../Utils/Icons/close.svg";
import UploadDialog from "../UploadDialog/UploadDialog";
import UploadSpinner from "../../Loaders/UploadSpinner/UploadSpinner";
import { string } from "../../../Localization";
import { MAX_SIZE_IN_BYTES } from "../../../Utils/Constants";
import { showOrHideApiLoadingModal } from "../../../Store/Project/ModalSlice";
import ProjectLoader from "../../Loaders/ProjectLoader/ProjectLoader";
import ModalFooter from "../../ModalFooter/ModalFooter";

const UploadImage = () => {
  const dispatch = useDispatch();

  const { canShowUploadFileModal } = useSelector((state) => state.modal);
  const { progressBarValue } = useSelector((state) => state.progressBar);
  const [imageDetails, setImageDetails] = useState(null);
  const [inputImage, setInputImage] = useState({});
  const [uploadSuccess, setUploadSuccess] = useState(true);
  const [response, setResponse] = useState("");
  const [selectedFiles, setSelectedFiles] = useState();

  const resetFileSelection = () => {
    setImageDetails(null);
    setInputImage("");
    setUploadSuccess(true);
  };

  const onDrop = (files) => {
    if (files.length > 0) {
      let fileUploaded = files[0];
      setSelectedFiles(fileUploaded);

      if (fileUploaded?.size > MAX_SIZE_IN_BYTES) {
        dispatch(showOrHideApiLoadingModal(true));
      }
      try {
        getImageDimension(fileUploaded).then((imageDimensions) => {
          setImageDetails({
            name: fileUploaded.name,
            dimensions: imageDimensions,
            size: convertBytesToMegaBytes(fileUploaded.size),
            date: formatDateTime(new Date(fileUploaded.lastModified)),
            extension: getFileExtension(fileUploaded.name),
            preview: getPreviewUrlFromFile(fileUploaded, fileUploaded.size),
          });
          dispatch(showOrHideApiLoadingModal(false));
        });
      } catch (error) {
        console.log(error);
        dispatch(showOrHideApiLoadingModal(false));
      }
      setInputImage(fileUploaded);
    }
  };

  const handleUploadButton = () => {
    const fileExtension = getFileExtension(selectedFiles?.name);

    if (
      fileExtension === "jpg" ||
      fileExtension === "jpeg" ||
      fileExtension === "tiff" ||
      fileExtension === "tif"
    ) {
      if (selectedFiles.size <= MAX_SIZE_IN_BYTES) {
        handleUpload(inputImage, imageDetails)
          .then(() => {
            setUploadSuccess(true);
          })
          .catch((inErrorMessage) => {
            if (inErrorMessage !== "canceled") {
              setResponse(inErrorMessage);
              setUploadSuccess(false);
            } else {
              resetFileSelection();
            }
          });
      } else {
        setResponse(string.FILE_SIZE_EXCEEDED);
        setUploadSuccess(false);
      }
    } else {
      setResponse(string.UPLOAD_INVALID_FORMAT_MESSAGE);
      setUploadSuccess(false);
    }
  };

  const bodyClassName =
    progressBarValue === 100 && uploadSuccess
      ? "modal-body-container-spinner"
      : "modal-body-container";

  const headerClassName =
    progressBarValue === 100 && uploadSuccess
      ? "header-background header-background-spinner"
      : "header-background";

  return (
    <>
      <ProjectLoader />
      <Modal isOpen={canShowUploadFileModal} className="upload-modal" centered>
        <ModalHeader className={headerClassName}>
          <img
            alt="close"
            src={closeSvg}
            className="close-modal-svg"
            onClick={() => {
              handleUploadClose();
              resetFileSelection();
            }}
          />
          <>
            {progressBarValue === 100 &&
            uploadSuccess ? null : uploadSuccess ? (
              <>
                <ModalHeaderComponent
                  uploadPhoto={string.UPLOAD_PHOTO}
                  photoDescription={string.UPLOAD_PHOTO_DESCRIPTION}
                />
              </>
            ) : (
              <>
                <p className="modal-title-text">
                  {string.SOMETHING_WENT_WRONG}
                </p>
                <p className="modal-title-description">
                  {string.UPLOAD_FAILED_DESCRIPTION}
                </p>
              </>
            )}
          </>
        </ModalHeader>
        <ModalBody className={bodyClassName}>
          {progressBarValue === 100 && uploadSuccess ? (
            <UploadSpinner />
          ) : imageDetails && imageDetails.name ? (
            <>
              <UploadDialog
                uploadAction={handleUploadButton}
                imageDetails={imageDetails}
                uploadSuccess={uploadSuccess}
                onReset={resetFileSelection}
                termsAndCondition={true}
                response={response}
              />
            </>
          ) : (
            <Dropzone onDrop={onDrop} multiple={false}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <>
                      <img src={uploadSvg} alt=""></img>
                      <p className="upload-text">
                        {string.CLICK_TO_UPLOAD}&nbsp; <span>{string.OR}</span>
                        &nbsp;
                        {string.DRAG_AND_DROP}
                      </p>
                      <p className="upload-description">
                        {string.JPG_OR_TIFF}
                        <br /> {string.MAX_400MB}
                      </p>
                    </>
                  </div>
                  {/* footer links in upload image */}
                  <div className="upload_image_footer_links">
                    <ModalFooter />
                  </div>
                </section>
              )}
            </Dropzone>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

const ModalHeaderComponent = ({ uploadPhoto, photoDescription }) => {
  return (
    <>
      <p className="modal-title-text">{uploadPhoto}</p>
      <p className="modal-title-description">{photoDescription}</p>
    </>
  );
};

export default UploadImage;
