import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "font-awesome/css/font-awesome.min.css";
import { setOrderDashboardInfo } from "../../../Store/Project/OrderManagementSlice";
import { setOrderToken } from "../../../Store/Project/OrderSlice";
import ProjectSpinner from "../../../Components/Loaders/ProjectSpinner/ProjectSpinner";
import {
  getOrderManagementDashboardInfo,
  getOrderManagementRecords,
} from "../../../Utils/ApiHelper";
import "./OrderManagement.scss";
import SplineGraph from "../../../Components/Graph/SplineGraph";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  sendGAClickedGraph,
  sendGALoadedOrderManagement,
  sendGAScrolledOrderManagement,
  sendGASelectedOrder,
} from "../../../Utils/GAHelper";
import { capitalizeFirstLetter, getCustomName } from "../../../Utils/Helper";
import { string } from "../../../Localization";
import { showOrHideApiLoadingModal } from "../../../Store/Project/ModalSlice";
import ProjectLoader from "../../../Components/Loaders/ProjectLoader/ProjectLoader";
import InfiniteScrollLoader from "../../../Components/Loaders/InfiniteScrollLoader";

const OrderManagement = ({ onClickRow }) => {
  const dispatch = useDispatch();

  const { orderDashboardInfo } = useSelector((state) => state.orderManagement);
  const [orderManagementRecords, setOrderManagementRecords] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [noMore, setNoMore] = useState(true);

  const initialLoadComplete = false;
  const pageLimit = 10;
  const sortBy = 0;
  const sortType = 1;

  const data = {
    labels: orderDashboardInfo?.monthwise_data_bifurcation?.labels,
    count: orderDashboardInfo?.monthwise_data_bifurcation?.count,
  };

  useEffect(() => {
    dispatch(showOrHideApiLoadingModal(true));

    getOrderManagementDashboardInfo().then((res) => {
      dispatch(setOrderDashboardInfo(res.data.data));
      dispatch(showOrHideApiLoadingModal(false));
    });
    sendGALoadedOrderManagement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchNextPage = () => {
    getOrderManagementRecords(currentPage, pageLimit, sortBy, sortType).then(
      (res) => {
        const resp = res.data.data;

        setTotalPages(resp.total_pages);

        setOrderManagementRecords((prev) => ({
          ...prev,
          orders_arr: [
            ...(prev.orders_arr ? prev.orders_arr : []),
            ...resp.orders_arr,
          ],
        }));

        setTotalPages(resp.total_pages);
        setCurrentPage(currentPage + 1);
        setIsPageLoading(false);
        if (resp.orders_arr?.length === 0) {
          setNoMore(false);
        }
        sendGAScrolledOrderManagement();
      }
    );
  };

  useEffect(() => {
    if (!initialLoadComplete) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, sortType, totalPages]);

  const handleSelect = (data) => {
    dispatch(setOrderToken(data.token));
    onClickRow(data);
    sendGASelectedOrder();
  };

  return (
    <div className="order-management">
      <ProjectLoader />
      <div className="main-title">{string.ORDER_MANAGEMENT}</div>
      <div className="order-management-container">
        <div className="panels">
          <div className="panel order-count">
            <div className="panel-padding">
              <div className="title">{orderDashboardInfo.total_orders}</div>
              <div className="body">{string.ORDERS}</div>
            </div>
          </div>
          <div className="panel order-cost">
            <div className="panel-padding">
              <div className="title">{orderDashboardInfo.total_sales}</div>
              <div className="body">{string.SALES}</div>
            </div>
          </div>
          <div className="panel order-profit">
            {" "}
            <div className="panel-padding">
              <div className="title">{orderDashboardInfo.total_profit}</div>
              <div className="body">{string.PROFIT}</div>
            </div>
          </div>
        </div>
        <div className="graph-container" onClick={sendGAClickedGraph}>
          {data.labels && data.count && <SplineGraph data={data} />}
        </div>
      </div>

      {orderManagementRecords?.orders_arr?.length > 0 && (
        <div className="content-block">
          <div className="infinate-parent" id="orderManagment">
            <InfiniteScroll
              dataLength={
                orderManagementRecords.orders_arr
                  ? orderManagementRecords.orders_arr.length
                  : 0
              }
              hasMore={noMore}
              next={fetchNextPage}
              scrollableTarget="orderManagment"
              loader={<InfiniteScrollLoader />}
              endMessage={""}
            >
              <div>
                <table className="table table-borderless table-block">
                  <thead>
                    <tr className="tabel-row">
                      <th scope="col">{string.PHOTO}</th>
                      <th scope="col">{string.CUSTOMER_NAME}</th>
                      <th scope="col">{string.ORDER_DATE}</th>
                      <th scope="col">{string.COUNTRY}</th>
                      <th scope="col">{string.EDITION}</th>
                      <th scope="col">{string.ORDER_NUMBER}</th>
                      <th scope="col">{string.STATUS}</th>
                      <th scope="col">{string.SELLING_PRICE}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {orderManagementRecords.orders_arr &&
                      orderManagementRecords.orders_arr.map((data, index) => {
                        return (
                          <tr
                            key={index}
                            className="row_table"
                            onClick={() => handleSelect(data)}
                          >
                            <td>
                              <img
                                className="row-image"
                                src={data.preview}
                                alt="preview"
                              ></img>
                            </td>

                            <td>{data.customer_name}</td>
                            <td>{data.created_at}</td>
                            <td>{data.country}</td>
                            <td>{data.edition}</td>
                            <td>{data.order_number}</td>
                            <td>
                              <div
                                className={getCustomName(data.status).className}
                              >
                                <span className="status-icon">
                                  {getCustomName(data.status).statusIcon && (
                                    <img
                                      src={
                                        getCustomName(data.status).statusIcon
                                      }
                                      alt={data.status}
                                    />
                                  )}
                                </span>
                                <span>
                                  {" "}
                                  {capitalizeFirstLetter(data.status)}
                                </span>
                              </div>
                            </td>
                            <td>{data.selling_price}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <ProjectSpinner
                isLoading={isPageLoading}
                customClass={"gallery-spinner"}
              />
            </InfiniteScroll>
          </div>
        </div>
      )}
    </div>
  );
};
export default OrderManagement;
