import React from "react";
import "./LandingPlan.scss";
import right_image from "../../../Utils/Icons/right.svg";
import wrong_image from "../../../Utils/Icons/wrong.svg";
import {
  setCanShowAlertModal,
  setCanShowErrorAlertModal,
} from "../../../Store/Project/ProjectSlice";

import {
  sendGAClickedChooseBasicPlan,
  sendGAClickedChooseProPlan,
} from "../../../Utils/GAHelper";
import { string } from "../../../Localization";
import { useDispatch, useSelector } from "react-redux";
import PlanModal from "./PlanModal";

const LandingPlan = () => {
  const dispatch = useDispatch();

  const { canShowAlertModal, CanShowErrorAlertModal } = useSelector(
    (state) => state.project
  );

  const bacisPlans = [
    string.BASIC_PLANS_PT_A1 +
      " <b>10 " +
      string.LIMITED_EDITION +
      " </b>" +
      string.BASIC_PLANS_PT_A2,
    string.BASIC_PLANS_PT_B1 +
      "<b> " +
      string.BASIC_PLANS_PT_B2 +
      " </b>" +
      string.BASIC_PLANS_PT_B3,

    "<b>" + string.BASIC_PLANS_PT_C1 + " </b>" + string.BASIC_PLANS_PT_C2,
    string.BASIC_PLANS_PT_D1 + " <b>" + string.BASIC_PLANS_PT_D2 + "</b>",
    string.BASIC_PLANS_PT_E1 + " - <b> " + string.BASIC_PLANS_PT_E2 + "</b>",
  ];

  const proPlans = [
    string.BASIC_PLANS_PT_A1 +
      " <b> 500 " +
      string.LIMITED_EDITION +
      " </b> " +
      string.BASIC_PLANS_PT_A2,
    string.BASIC_PLANS_PT_B1 +
      " <b> " +
      string.BASIC_PLANS_PT_B2 +
      " </b> " +
      string.BASIC_PLANS_PT_B3,
    "<b> " + string.BASIC_PLANS_PT_C1 + " </b> " + string.BASIC_PLANS_PT_C2,
    string.BASIC_PLANS_PT_D1 + " <b>" + string.BASIC_PLANS_PT_D2 + "</b>",
    string.BASIC_PLANS_PT_E1 + " - <b> " + string.BASIC_PLANS_PT_E2 + "</b>",
    string.PRO_PLANS_PT_A1 + " <b> " + string.PRO_PLANS_PT_A2 + "</b>",
    string.PRO_PLANS_PT_B1 +
      " <b> " +
      string.PRO_PLANS_PT_B2 +
      " </b> " +
      string.PRO_PLANS_PT_B3 +
      " <b> " +
      string.PRO_PLANS_PT_B4 +
      " </b> " +
      string.PRO_PLANS_PT_B5,
    string.PRO_PLANS_PT_C1 + " <b> " + string.PRO_PLANS_PT_C2 + "</b>​",
    "<b>" + string.PRO_PLANS_PT_D1 + "</b>​",
  ];

  const notAppliedBacisPlans = [
    string.NOT_APPICABLE_PLANS_PT1,
    string.NOT_APPICABLE_PLANS_PT2,
    string.NOT_APPICABLE_PLANS_PT3,
    string.NOT_APPICABLE_PLANS_PT4,
  ];

  const statusDescription = (
    <div className="modal_description_style">
      <div>
        {" "}
        {string.STATUS_DESCRIPTION_1} &nbsp;
        <b>
          {string.PRO}&nbsp;{string.SUBSCRIPTION}
        </b>{" "}
        {string.SERVICE_FOR} <b>{string.FREE_IN_THE_UK_ONLY}</b>,{" "}
        {string.STATUS_DESCRIPTION_2}&nbsp;{string.STATUS_DESCRIPTION_3}{" "}
      </div>
      <div>{string.SIGN_UP_NOW_TO_ACTIVATE}</div>
    </div>
  );
  const statusDescription1 = (
    <div className="modal_description_style">
      <div>
        {" "}
        {string.STATUS_DESCRIPTION_1} &nbsp;
        <b>
          {string.PREMIUM}&nbsp;{string.SUBSCRIPTION}
        </b>{" "}
        {string.SERVICE_FOR} <b>{string.FREE_IN_THE_UK_ONLY}</b>,{" "}
        {string.STATUS_DESCRIPTION_2}&nbsp;{string.STATUS_DESCRIPTION_3}{" "}
      </div>
      <div>{string.SIGN_UP_NOW_TO_ACTIVATE_PREMIUM}</div>
    </div>
  );

  const cancelHandler = () => {
    dispatch(setCanShowAlertModal(false));
  };

  const closeBtnFtn = () => {
    dispatch(setCanShowAlertModal(false));
  };
  const closeHandlers = () => {
    dispatch(setCanShowErrorAlertModal(false));
  };
  const closeBtnFtn1 = () => {
    dispatch(setCanShowErrorAlertModal(false));
  };
  return (
    <>
      <div className="landing_plan_container">
        <div className="landing_plan_card1 card1">
          <LandingPagePlan
            planName={string.PREMIUM}
            plans={bacisPlans}
            planCost={string.FREE}
            planOffers={string.INTRODUCTORY_OFFER}
          />
          <LandingPageBasicPlans plans={notAppliedBacisPlans} />
          <button
            className="choose_the_plan"
            onClick={() => {
              sendGAClickedChooseBasicPlan();
              dispatch(setCanShowErrorAlertModal(true));
            }}
          >
            {string.CHOOSE_THIS_PLAN}
          </button>
        </div>
        <div className="main_card_container">
          <div className="landing_plan_most_popular">{string.MOST_POPULAR}</div>
          <div className="landing_plan_card2 card1">
            <div className="most_popular_container">
              <LandingPagePlan
                planName={string.PRO}
                currencyType="£"
                plans={proPlans}
                planCost="9,99"
                planOffers={string.PER_MONTH}
              />
              <button
                className="choose_the_plan"
                onClick={() => {
                  sendGAClickedChooseProPlan();
                  dispatch(setCanShowAlertModal(true));
                }}
              >
                {string.CHOOSE_THIS_PLAN}
              </button>
            </div>
          </div>
        </div>
      </div>
      <PlanModal
        canShow={canShowAlertModal}
        statusDescription={statusDescription}
        closeHandler={cancelHandler}
        closeBtnFtn={closeBtnFtn}
      />
      <PlanModal
        canShow={CanShowErrorAlertModal}
        statusDescription={statusDescription1}
        closeHandler={closeHandlers}
        closeBtnFtn={closeBtnFtn1}
      />
    </>
  );
};

const LandingPagePlan = (props) => {
  const { planName, currencyType, plans, planCost, planOffers } = props;
  return (
    <>
      <div className="landing_plan_content">
        <div className="plan_type_container">
          <div className="plan_name">{planName}</div>
          <div className="plan_type">
            <div className="plan_cost">
              <sup className="currecncy_type">{currencyType}</sup>
              {planCost}
            </div>
            <div className="plan_offer">{planOffers}</div>
          </div>
        </div>
        <div className="plan_line"></div>
        <ul className="plan_lists">
          {plans.map((message, index) => (
            <div className="plan_list-items" key={index}>
              <img src={right_image} alt="" />
              <li>
                <span className="plan_list_contants">
                  {message.split(/(<b>.*?<\/b>)/g).map((part, index) => {
                    if (/<b>.*?<\/b>/.test(part)) {
                      return (
                        <span
                          key={index}
                          style={{ color: "#F8F8F8" }}
                          dangerouslySetInnerHTML={{ __html: part }}
                        ></span>
                      );
                    } else {
                      return (
                        <span
                          key={index}
                          style={{ color: "#BDBDBD" }}
                          dangerouslySetInnerHTML={{ __html: part }}
                        ></span>
                      );
                    }
                  })}
                </span>
              </li>
            </div>
          ))}
        </ul>
      </div>
    </>
  );
};

const LandingPageBasicPlans = (props) => {
  const { plans } = props;
  return (
    <>
      <div className="landing_plan_content">
        <ul className="plan_lists">
          {plans.map((message, index) => (
            <div className="plan_list-items" key={index}>
              <img src={wrong_image} alt="" />
              <li>
                <div className="list-item-container">
                  <span
                    className="plan_list_contants"
                    style={{ color: "#737373" }}
                    dangerouslySetInnerHTML={{ __html: message }}
                  ></span>
                </div>
              </li>
            </div>
          ))}
        </ul>
      </div>
    </>
  );
};
export default LandingPlan;
