import { useEffect, useRef, useState } from "react";
import { string } from "../../Localization";
import {
  getOrders,
  getPhotoPrintDetails,
  startSelling,
  updateProject,
} from "../../Utils/ApiHelper";
import { CERTIFICATE, PAGE_HOME, PHOTO_PRINT } from "../../Utils/Constants";
import { Icons } from "../../Utils/Icons";
import TopBar from "../TopBar/TopBar";
import PhotoPrint from "../../Components/PhotoPrint/PhotoPrint";
import PreviewPanel from "./PreviewPanel/PreviewPanel";
import { useDispatch, useSelector } from "react-redux";
import { setActivePageIndex } from "../../Store/Project/PageSlice";
import PrintedCertificate from "../Certificate/PrintedCertificate/PrintedCertificate";
import { setOrderToken } from "../../Store/Project/OrderSlice";
import OrderDetails from "../Preview/OrderDetails/OrderDetails";
import NavigateBackButton from "../../Components/NavigateBackButton/NavigateBackButton";
import ProfileButton from "../ProfileButton/ProfileButton";
import {
  sendGALoadedPhotoPrintSection,
  sendGALoadedPrintedCertificateSection,
  sendGAUpdatedDescription,
  sendGAUpdatedSellingPrice,
  sendGAUpdatingDescription,
  sendGAUpdatingSellingPrice,
} from "../../Utils/GAHelper";
import "./PhotoPrintDetails.scss";
import { extractCurrencyValues } from "../../Utils/projectHelper";
import addIcon from "../../Utils/Icons/add-yellow.svg";
import { capitalizeFirstLetter, getCustomName } from "../../Utils/Helper";
import { showOrHideApiLoadingModal } from "../../Store/Project/ModalSlice";
import ProjectLoader from "../../Components/Loaders/ProjectLoader/ProjectLoader";

const PhotoPrintDetails = () => {
  const { projectToken } = useSelector((state) => state.project);

  const dispatch = useDispatch();

  const wrapperRef = useRef(null);
  const descriptionRef = useRef(null);

  const [currentTab, setCurrentTab] = useState(PHOTO_PRINT);
  const [priceEditable, setPriceEditable] = useState(false);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [descriptionEditable, setDescriptionEditable] = useState(false);
  const [newDescription, setNewDescription] = useState("");
  const [photoPrintDetails, setPhotoPrintDetails] = useState({});
  const [income, setIncome] = useState(0);
  const [orders, setOrders] = useState([]);
  const [orderDetails, setOrderDetails] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageLeftDisabled, setPageLeftDisabled] = useState(false);
  const [pageRightDisabled, setPageRightDisabled] = useState(false);

  const [tabActive, setTabActive] = useState({
    PhotoPrint: true,
    Certificate: false,
  });

  const perPage = 4;
  const sortTypeKey = 1;
  const sortColumnKey = 0;

  useEffect(() => {
    const fetchOrders = () => {
      getOrders(
        projectToken,
        currentPage,
        perPage,
        sortColumnKey,
        sortTypeKey
      ).then((resp) => {
        setOrders(resp.data.data.orders_arr);
        setTotalPages(resp.data.data.total_pages);
      });
    };

    fetchOrders();
  }, [projectToken, currentPage, perPage]);

  useEffect(() => {
    setPageLeftDisabled(currentPage === 1);
    setPageRightDisabled(currentPage === totalPages);
  }, [currentPage, totalPages]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setPriceEditable(false);
      }
      if (
        descriptionRef.current &&
        !descriptionRef.current.contains(event.target)
      ) {
        setDescriptionEditable(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, descriptionRef]);

  useEffect(() => {
    setIncome(currentPrice);
  }, [currentPrice]);

  useEffect(() => {
    dispatch(showOrHideApiLoadingModal(true));
    getPhotoPrintDetails(projectToken).then((res) => {
      sendGALoadedPhotoPrintSection();
      const response = res.data.data;

      const price = response.price;
      setPhotoPrintDetails(response);
      const currentAmount = extractCurrencyValues(price.income);
      setCurrentPrice(currentAmount);

      // let projectToken = "b9e5c6ea-3931-6803-cd19-cfcdf3594a0f";
      dispatch(showOrHideApiLoadingModal(false));
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDecrement = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleIncrement = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleCostChange = (event) => {
    const regex = /^\d+(\.\d{0,2})?$/;
    if (regex.test(event.target.value) || event.target.value === "") {
      setCurrentPrice(event.target.value);
    }
  };

  const handleTabs = (key) => {
    const tabs = {
      PhotoPrint: false,
      Certificate: false,
    };
    setCurrentTab(key);
    switch (key) {
      case PHOTO_PRINT:
        setTabActive({ ...tabs, PhotoPrint: true });
        break;
      case CERTIFICATE:
        setTabActive({ ...tabs, Certificate: true });
        break;
      default:
        break;
    }
  };

  const renderTabView = (currentTab) => {
    switch (currentTab) {
      case PHOTO_PRINT:
        return (
          <PhotoPrint
            preview_url={
              photoPrintDetails.project &&
              photoPrintDetails.project.preview_image
            }
          />
        );
      case CERTIFICATE:
        return <PrintedCertificate details={photoPrintDetails} />;
      default:
        break;
    }
  };

  const togglePriceEdit = () => {
    setDescriptionEditable(false);
    if (priceEditable) {
      const formData = new FormData();
      formData.append("price[income]", income);
      updateProject(projectToken, formData).then((res) => {
        setPhotoPrintDetails(res.data.data);
      });
    }
    setPriceEditable(!priceEditable);
  };

  const handleStartSelling = () => {
    dispatch(showOrHideApiLoadingModal(true));
    startSelling(projectToken)
      .then(() => {
        dispatch(showOrHideApiLoadingModal(false));
        setPhotoPrintDetails((prevState) => ({
          ...prevState,
          project: {
            ...prevState.project,
            public_access: true,
          },
        }));
      })
      .catch(() => {
        dispatch(showOrHideApiLoadingModal(false));
      });
  };

  const toggleDescriptionEdit = () => {
    setPriceEditable(false);
    if (descriptionEditable) {
      const formData = new FormData();
      formData.append("project[description]", newDescription);
      updateProject(projectToken, formData).then((res) => {
        setPhotoPrintDetails(res.data.data);
      });
    }
    setDescriptionEditable(!descriptionEditable);
  };

  const handleNavigateBack = () => {
    dispatch(setActivePageIndex(PAGE_HOME));
  };

  const onClickRow = () => {
    setOrderDetails(false);
  };

  const onClickBack = () => {
    setOrderDetails(true);
  };

  const handleSelect = (data) => {
    dispatch(setOrderToken(data.token));
    onClickRow();
  };

  return (
    <>
      <ProjectLoader />

      {orderDetails ? (
        <>
          <TopBar
            pageTitle="Photo print details"
            onNavigateBack={handleNavigateBack}
          />
          <div className="photoprint-summary-block container">
            {photoPrintDetails.project && (
              <>
                <div className="tab-block">
                  <div className="d-flex custom-tabs">
                    <div
                      className={
                        tabActive.PhotoPrint
                          ? "tab-view tab-active"
                          : "tab-view"
                      }
                      onClick={() => {
                        handleTabs(PHOTO_PRINT);
                        sendGALoadedPhotoPrintSection();
                      }}
                    >
                      {string.PHOTO_PRINT}
                    </div>
                    <div
                      className={
                        tabActive.Certificate
                          ? "tab-view tab-active"
                          : "tab-view"
                      }
                      onClick={() => {
                        handleTabs(CERTIFICATE);
                        sendGALoadedPrintedCertificateSection();
                      }}
                    >
                      {string.PRINTED_CERTIFICATE}
                    </div>
                  </div>
                  <div className="tab-content">{renderTabView(currentTab)}</div>
                </div>
                <div className="content-block">
                  <PreviewPanel
                    projectDetails={photoPrintDetails?.project}
                    handleStartSelling={handleStartSelling}
                  />
                  <div className="content-header">
                    <div className="py-2 title">
                      {photoPrintDetails.project.title}
                    </div>
                    <div ref={descriptionRef}>
                      {!descriptionEditable ? (
                        <>
                          {photoPrintDetails &&
                          photoPrintDetails.project.description !==
                            "No description is Available" ? (
                            <div className="d-flex justify-content-between">
                              <div className="py-2 description input-description-disabled">
                                {photoPrintDetails.project.description}
                              </div>
                              <div
                                onClick={() => {
                                  toggleDescriptionEdit();
                                  sendGAUpdatingDescription();
                                }}
                              >
                                <img
                                  className="edit-icon description-edit"
                                  alt="edit icon"
                                  src={Icons.edit.default}
                                />
                              </div>
                            </div>
                          ) : (
                            <div
                              className="no-description"
                              onClick={() => {
                                toggleDescriptionEdit();
                                sendGAUpdatingDescription();
                              }}
                            >
                              <p>{string.ADD_DESCRIPTION}</p>
                              <img src={addIcon} alt="add" />
                            </div>
                          )}
                        </>
                      ) : (
                        <div>
                          <div className="d-flex">
                            <textarea
                              className="input-description-enabled"
                              rows="5"
                              cols="33"
                              maxLength={500}
                              placeholder={string.TYPE_HERE}
                              onChange={(e) =>
                                setNewDescription(e.target.value)
                              }
                            >
                              {photoPrintDetails.project.description ===
                              "No description is Available"
                                ? ""
                                : photoPrintDetails.project.description}
                            </textarea>
                          </div>
                          <div
                            onClick={() => {
                              toggleDescriptionEdit();
                              sendGAUpdatedDescription();
                            }}
                            className="save-button"
                          >
                            {string.SAVE_CHANGES}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="py-2 text-color">
                      {photoPrintDetails.project.copyright_text}
                    </div>
                  </div>
                  <div className="content-body">
                    <div className="d-flex justify price">
                      <div className="d-flex w-100 add-gap justify-content-sm-between">
                        <div className="fixed-width ">
                          <div className="py-2 title">
                            {string.PRINT_DETAILS}
                          </div>
                          <div>
                            <div className="d-flex">
                              <div className="description label add-margin-bottom paper-type-wrap">
                                {string.PAPER_TYPE}
                              </div>
                              <div className="px-1  label-value">
                                {photoPrintDetails.project.media_type_name}
                              </div>
                            </div>
                            <div className="d-flex">
                              <div className="description label add-margin-bottom">
                                {string.PRINT_SIZE}:
                              </div>
                              <div className="px-1 label-value">
                                {photoPrintDetails.project.print_size}
                              </div>
                            </div>
                            <div className="d-flex">
                              <div className="description label ">
                                {string.PHOTO_SIZE}
                              </div>
                              <div className="px-1  label-value">
                                {photoPrintDetails.project.photo_size}
                              </div>
                            </div>
                          </div>{" "}
                        </div>

                        <div className="fixed-width">
                          <div className="py-2 title">
                            {string.PRICING_AND_INCOME}
                          </div>
                          <div className="justify-content-sm-between">
                            <div className="d-flex justify-content-sm-between">
                              <div ref={wrapperRef}>
                                {!priceEditable ? (
                                  <>
                                    <div className="d-flex justify-content-between artist-field">
                                      <div className="description d-flex artist-income artist-income-edit ">
                                        {string.ARTIST_INCOME} (
                                        {string.EXCLUDING_TAXES})
                                      </div>
                                      <div className="d-flex hash-value artist-income-price">
                                        <div className="input-price-disabled">
                                          {photoPrintDetails.price.income}
                                        </div>
                                        <div
                                          onClick={() => {
                                            togglePriceEdit();
                                            sendGAUpdatingSellingPrice();
                                          }}
                                          className="edit-icon-price"
                                        >
                                          <img
                                            alt="edit icon"
                                            className="edit-icon"
                                            src={Icons.edit.default}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <div className="fixed-width-edit-open">
                                    <div className=" py-2 description ">
                                      {string.ARTIST_INCOME}&nbsp;(
                                      {string.EXCLUDING_TAXES})
                                    </div>
                                    <div>
                                      <div>
                                        {/* <p className="p-col">
                                          You can edit the income
                                        </p> */}
                                        <div className="input-price-block">
                                          <input
                                            type="number"
                                            className="input-price-enabled"
                                            step="0.01"
                                            value={currentPrice}
                                            onChange={handleCostChange}
                                          />
                                        </div>
                                        <div
                                          onClick={() => {
                                            togglePriceEdit();
                                            sendGAUpdatedSellingPrice();
                                          }}
                                          className="save-button"
                                        >
                                          {string.SAVE_CHANGES}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {/* <p className="tax-info py-3">
                                    The selling price shown to customers will
                                    include taxes according to the country of
                                    purchase.
                                  </p> */}
                              </div>
                            </div>
                            <div className="d-flex justify-content-sm-between  py-2">
                              <div className="description">
                                {string.SERVICE_FEES}
                              </div>
                              <div className="hash-value ">
                                {photoPrintDetails.price.service_fees}
                              </div>
                            </div>
                            <div className="d-flex justify-content-sm-between add-margin-bottom">
                              <div className="description ">
                                {string.SHIPPING_FEES}
                              </div>
                              <div className="hash-value ">
                                {photoPrintDetails.price.shipping_fees}
                              </div>
                            </div>
                            <div className="d-flex justify-content-sm-between ">
                              <div className="description income-value income ">
                                {string.SELLING_PRICE}
                              </div>
                              <div className="hash-value income-value ">
                                {photoPrintDetails.price.selling_price}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className=" d-flex w-100 add-gap justify-content-sm-between">
                        <div className="fixed-width">
                          <div className="py-2 title ">
                            {string.PERSONALIZATION}
                          </div>

                          <div>
                            <div className="d-flex">
                              <div className="d-flex fixed-width">
                                <div className="description ">
                                  {string.NUMBER_OF_COPIES}
                                </div>
                                <div className="px-1 label-value">
                                  {photoPrintDetails.project.no_of_copies}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="fixed-width justify-content-sm-between">
                          {photoPrintDetails.blockchain.ledger && (
                            <div className="d-flex justify-content-sm-between">
                              <div className="blockchain-contents">
                                <div className="py-2 title">
                                  {string.HP_BLOCKCHAIN_LEDGER}
                                </div>
                                <div className="description ledger">
                                  {photoPrintDetails.blockchain.ledger}

                                  <br></br>
                                  {photoPrintDetails.blockchain.publish_time}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {orders.length > 0 && <div className="price"></div>}
                    {orders.length > 0 && (
                      <div>
                        <div className="justify-content-sm-between">
                          <div>
                            <div className="py-2 title2">{string.ORDERS}</div>
                            <div className="tabel-container">
                              <table className="table table-borderless table-block">
                                <thead>
                                  <tr className="tabel-row">
                                    <th scope="col">{string.CUSTOMER_NAME}</th>
                                    <th scope="col">{string.ORDER_DATE}</th>
                                    <th scope="col">{string.EDITION}</th>
                                    <th scope="col">{string.STATUS}</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {/* <InfiniteScroll
                                  dataLength={orders.length}
                                  next=""
                                  hasMore=""
                                  loader={<h4>Loading...</h4>}
                                  endMessage={<p>No more data to load</p>}
                                > */}

                                  {orders &&
                                    orders.map((data, index) => {
                                      return (
                                        <tr
                                          key={index}
                                          className="row_table"
                                          onClick={() => handleSelect(data)}
                                        >
                                          <td>{data.customer_name}</td>
                                          <td>{data.created_at}</td>
                                          <td>{data.edition_str}</td>
                                          <td>
                                            <div
                                              className={
                                                getCustomName(data.status)
                                                  .className
                                              }
                                            >
                                              <span className="status-icon">
                                                {getCustomName(data.status)
                                                  .statusIcon && (
                                                  <img
                                                    src={
                                                      getCustomName(data.status)
                                                        .statusIcon
                                                    }
                                                    alt={data.status}
                                                  />
                                                )}
                                              </span>
                                              <span>
                                                {" "}
                                                {capitalizeFirstLetter(
                                                  data.status
                                                )}
                                              </span>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  {/* </InfiniteScroll> */}
                                </tbody>
                              </table>
                            </div>
                            <div className="d-flex paginate">
                              <div className="px-1">
                                {currentPage + " of " + totalPages}
                              </div>
                              <div className="px-1">
                                <i
                                  className={
                                    pageLeftDisabled === true
                                      ? "fa fa-angle-left disabled-page"
                                      : "fa fa-angle-left cursor-pointer"
                                  }
                                  onClick={handleDecrement}
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="px-1 cursor-pointer">
                                <i
                                  className={
                                    pageRightDisabled === true
                                      ? "fa fa-angle-right disabled-page"
                                      : "fa fa-angle-right cursor-pointer"
                                  }
                                  onClick={handleIncrement}
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <div style={{ padding: "30px" }}>
          <div className="d-flex justify-content-between align-items-center">
            <NavigateBackButton onNavigateBack={onClickBack} />
            <ProfileButton />
          </div>

          <OrderDetails />
        </div>
      )}
    </>
  );
};

export default PhotoPrintDetails;
