import { CERTIFICATE_CARD_WIDTH } from "./Constants";

export const handleResize = () => {
  const root = document.querySelector(":root");

  const cardContainer = document.getElementById("card-canvas-container");
  var cardWidth = CERTIFICATE_CARD_WIDTH;
  if (cardContainer) {
    cardWidth = cardContainer.clientWidth;
  }
  root.style.setProperty("--certificate-card-width", cardWidth);

  var canvasWidth = 300;
  const canvasContainer = document.getElementById("cropped-canvas");
  if (canvasContainer) {
    canvasWidth = canvasContainer.clientWidth;
  }

  const scale = 1.1;
  const space = 30;
  const translateWidth = -(cardWidth / 2 + scale * (canvasWidth / 2) + space);

  root.style.setProperty("--scale", scale);
  root.style.setProperty("--translate-width", `${translateWidth}px`);
};
