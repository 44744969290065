import "./InfiniteScrollLoader.scss";

const InfiniteScrollLoader = (props) => {
  const { spinner_size_style, loader_style } = props;
  return (
    <div className="spinner-size" style={spinner_size_style}>
      <div className="loader-container">
        <div class="loader_spinner" style={loader_style}></div>
      </div>
    </div>
  );
};

export default InfiniteScrollLoader;
