import React from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import "./SearchByAiError.scss";
import ErrorIcon from "../../../Utils/Icons/error_icon.svg";
import { string } from "../../../Localization";

const SearchByAIError = ({ isOpen, onClose, errorMessage }) => {
  return (
    <Modal show={isOpen} className="serach-error-container-wrap">
      <ModalBody className="search-error-modal-container">
        <div className="search-error-body">
          <div className="search-error-icon">
            <img src={ErrorIcon} alt="error icon" />
          </div>
          <h4 className="search-error-header">{string.SOMETHING_WENT_WRONG}</h4>
          <div className="search-error-description">
            <p className="text">{errorMessage}</p>
          </div>
          <div className="search-error-button-container">
            <Button onClick={onClose} className="search-error-button">
              {string.CLOSE}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SearchByAIError;
