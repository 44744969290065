import React, { useEffect, useState } from "react";
import "./PhotographerDetails.scss";
import { string } from "../../Localization";
import { useNavigate, useSearchParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { sendGAClickedViewGallery } from "../../Utils/GAHelper";
import { stringAvatar } from "../../Utils/projectHelper";

const PhotographerDetails = (props) => {
  const [name, setName] = useState("");
  const [spacing, setSpacing] = useState(0);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    profilePic,
    artistName,
    description,

    userId,
    showViewGallery,
    userName,
  } = props;

  const splitFirstName = name.split(" ");
  const firstLetter = splitFirstName[0].charAt(0);
  const lastLetter = splitFirstName[splitFirstName.length - 1].charAt(0);
  const letters = `${firstLetter}${lastLetter}`;

  const queryStrings = Object.fromEntries([...searchParams]);

  useEffect(() => {
    if (name && name === "") {
      setSpacing(name.split(" ").length);
    } else {
      setSpacing(-1);
    }
    setName(artistName);
  }, [artistName]);

  return (
    <>
      <div className="photographer_details_container">
        <div className="photographer_details_contant_1">
          {profilePic == "" ? (
            <>
              <Stack
                direction="row"
                spacing={spacing}
                className="photographer_details_image"
              >
                <div
                  {...stringAvatar(name, letters)}
                  className="photographer_details_images"
                />
              </Stack>
            </>
          ) : (
            <img
              src={profilePic}
              alt="pic4"
              className="photographer_details_image"
            />
          )}
        </div>
        <div className="photographer_details_contant_2">{artistName}</div>
        <div className="photographer_details_contant_3">{description}</div>
        <div className="photographer_details_contant_4">
          {showViewGallery && (
            <button
              onClick={() => {
                navigate(`/gallery/${userName}?utm_source=gallery`);
                sendGAClickedViewGallery(
                  userId,
                  queryStrings["utm_source"] ?? ""
                );
              }}
            >
              {string.VIEW_GALLERY}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default PhotographerDetails;
