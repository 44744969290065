import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./Checkout.scss";
import { string } from "../../../Localization";
import { getOrderInfo } from "../../../Utils/ApiHelper";
import { useDispatch } from "react-redux";
import { setOrderToken } from "../../../Store/Project/OrderSlice";

const Checkout = (props) => {
  const { order_token } = useParams();
  const { setProjectSummaryData, projectSummaryData } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [iframeLoaded, setIframeLoaded] = useState(false);

  useEffect(() => {
    getOrderInfo(order_token).then((res) => {
      const response = res?.data?.data;
      setProjectSummaryData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const postToIframe = () => {
    var iframeWin = document.getElementById("pgsIframe")?.contentWindow;
    var msg = {
      ActionCode: "PGSSubmit",
    };
    iframeWin?.postMessage(JSON.stringify(msg), "*");
  };

  const projectToken = projectSummaryData?.google_analytics?.project_token;

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  return (
    <div className="checkout-order-block">
      {/* <div className="top-bar"></div> */}
      {!iframeLoaded && <div className="checkout-loader"></div>}
      <div className="offwhite-bg">
        <iframe
          className="iframe-block"
          id="pgsIframe"
          title="checkout"
          src={"/web/orders/" + order_token + "/payment"}
          onLoad={handleIframeLoad}
        />
      </div>
      <div
        className={`container checkout-submit-block ${
          !iframeLoaded ? "checkout-submit-block-blur" : ""
        }`}
      >
        <div
          className="d-flex submit-button cancel-button me-3"
          onClick={() => {
            dispatch(setOrderToken(order_token));
            navigate(`/web/orders/${order_token}/review`);
          }}
        >
          {string.CANCEL}
        </div>
        <div className="d-flex submit-button" onClick={() => postToIframe()}>
          {string.CONFIRM_PURCHASE}
        </div>
      </div>
    </div>
  );
};

export default Checkout;
