import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Measurement.scss";
import {
  PROJECT_PREVIEW_MODE_CERTIFICATE,
  UNIT_SYSTEM_INCHES,
  UNIT_SYSTEM_MM,
} from "../../../Utils/Constants";
import { convertInchesToMm } from "../../../Utils/Helper";
import addSvg from "../../../Utils/Icons/add.svg";
import minimizeSvg from "../../../Utils/Icons/minimize.svg";
import { setBorderMeasurementStatus } from "../../../Store/Project/ProjectSlice";
import { string } from "../../../Localization";

const Measurement = () => {
  const borderWidth = useSelector((state) => state.project.borderWidth);
  const photoSize = useSelector((state) => state.project.sizeSelectionDetails);
  const {
    borderMeasurementStatus,
    selectedUnitSystem,
    activePreviewMode,
    isMagnifierModeOn,
  } = useSelector((state) => state.project);

  const photoDimensions = photoSize.selectedDimension;

  const dispatch = useDispatch();
  const aspectRatio = photoDimensions.width / photoDimensions.height;
  let widthInPix = 80;
  let heightInPix = 80 / aspectRatio;
  if (photoDimensions.width < photoDimensions.height) {
    heightInPix = 80;
    widthInPix = heightInPix * aspectRatio;
  }

  const photoWidthInCurrentUnitSystem =
    selectedUnitSystem === UNIT_SYSTEM_MM
      ? convertInchesToMm(photoDimensions.width)
      : photoDimensions.width;

  const photoHeightInCurrentUnitSystem =
    selectedUnitSystem === UNIT_SYSTEM_MM
      ? convertInchesToMm(photoDimensions.height)
      : photoDimensions.height;

  const borderWidthInCurrentUnitSystem =
    selectedUnitSystem === UNIT_SYSTEM_MM
      ? convertInchesToMm(borderWidth)
      : borderWidth;

  return (
    activePreviewMode !== PROJECT_PREVIEW_MODE_CERTIFICATE &&
    isMagnifierModeOn === false && (
      <div className="measurement-wrapper">
        {borderMeasurementStatus ? (
          <>
            <div
              className="measurement-header"
              onClick={() => {
                dispatch(setBorderMeasurementStatus(false));
              }}
            >
              <div className="minimize-title">{string.MEASUREMENTS}</div>
              <div className="minimize-icn">
                <img src={minimizeSvg} alt="" />
              </div>
            </div>
            <div className="measurement-details">
              <div className="measurement-rectangle">
                <div
                  className="measurement-rectangle-box"
                  style={{
                    width: `${widthInPix}px`,
                    height: `${heightInPix}px`,
                    border: `${borderWidth * 3}px solid white`,
                    transition: "all 0.1s",
                  }}
                ></div>
              </div>
              <div className="measurement-desc">
                {borderWidthInCurrentUnitSystem > 0 && (
                  <div className="photo-desc">
                    {string.PHOTO_SIZE_1} <br />
                    {selectedUnitSystem === UNIT_SYSTEM_INCHES ? (
                      <>
                        {" "}
                        <span className="desc-value">
                          {Math.trunc(photoWidthInCurrentUnitSystem) -
                            2 * Math.trunc(borderWidthInCurrentUnitSystem)}
                          ” x{" "}
                          {Math.trunc(photoHeightInCurrentUnitSystem) -
                            2 * Math.trunc(borderWidthInCurrentUnitSystem)}
                          ”
                        </span>
                      </>
                    ) : (
                      <>
                        {" "}
                        <span className="desc-value">
                          {Math.trunc(photoWidthInCurrentUnitSystem) -
                            2 * Math.trunc(borderWidthInCurrentUnitSystem)}
                          {string.MM} x{" "}
                          {Math.trunc(photoHeightInCurrentUnitSystem) -
                            2 * Math.trunc(borderWidthInCurrentUnitSystem)}
                          {string.MM}
                        </span>
                      </>
                    )}
                  </div>
                )}
                <div className="print-desc" style={{ transition: "all 0.5s" }}>
                  {string.PRINT}&nbsp;{string.SIZE} <br />
                  {selectedUnitSystem === UNIT_SYSTEM_INCHES ? (
                    <>
                      <span className="desc-value">
                        {Math.trunc(photoWidthInCurrentUnitSystem)}” x{" "}
                        {Math.trunc(photoHeightInCurrentUnitSystem)}”
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="desc-value">
                        {Math.trunc(photoWidthInCurrentUnitSystem)}
                        {string.MM} x{" "}
                        {Math.trunc(photoHeightInCurrentUnitSystem)}
                        {string.MM}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className="measurement-header"
              onClick={() => {
                dispatch(setBorderMeasurementStatus(true));
              }}
            >
              <div className="minimize-title">{string.MEASUREMENTS}</div>
              <div className="minimize-icn">
                <img src={addSvg} alt="" />
              </div>
            </div>
          </>
        )}
      </div>
    )
  );
};

export default Measurement;
