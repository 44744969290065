import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./UpdateImage.scss";
import imagesmode from "../../../Utils/Icons/imagesmode.svg";
import { getProjectDetails } from "../../../Utils/ApiHelper";
import { sendGAClickedUpdatePhoto } from "../../../Utils/GAHelper";
import Dropzone from "react-dropzone";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import {
  convertBytesToMegaBytes,
  getImageDimension,
  formatDateTime,
  getFileExtension,
  getPreviewUrlFromFile,
} from "../../../Utils/Helper";
import { handleUpload, handleUploadClose } from "../../../Utils/projectHelper";
import UploadDialog from "../../../Components/Modal/UploadDialog/UploadDialog";
import closeSvg from "../../../Utils/Icons/close.svg";
import UploadSpinner from "../../../Components/Loaders/UploadSpinner/UploadSpinner";
import { string } from "../../../Localization";
import uploadSvg from "../../../Utils/Icons/upload.svg";
import { MAX_SIZE_IN_BYTES } from "../../../Utils/Constants";
import { showOrHideApiLoadingModal } from "../../../Store/Project/ModalSlice";
import ModalFooter from "../../../Components/ModalFooter/ModalFooter";

const UpdateImage = () => {
  const dispatch = useDispatch();
  const { uploadedImageData, projectToken, projectDetails } = useSelector(
    (state) => state.project
  );
  const { progressBarValue } = useSelector((state) => state.progressBar);

  const { isAiImage } = projectDetails;
  const { imageURL } = uploadedImageData;

  const [imageDetails, setImageDetails] = useState(null);
  const [inputImage, setInputImage] = useState({});
  const [modal, setModal] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(true);
  const [response, setResponse] = useState("");
  const [selectedFiles, setSelectedFiles] = useState();
  const [imageData, setImageData] = useState(null);

  useEffect(() => {
    if (projectToken) {
      getProjectDetails(projectToken)
        .then((response) => {
          setImageData(
            response &&
              response.data &&
              response.data.data &&
              response.data.data.user_photo
          );
        })
        .catch((e) => {
          // console.log("e", e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectToken]);

  const resetFileSelection = () => {
    setImageDetails(null);
    setInputImage("");
    setUploadSuccess(true);
  };

  const onDrop = (files) => {
    if (files.length > 0) {
      let fileUploaded = files[0];
      setSelectedFiles(fileUploaded);
      if (fileUploaded?.size > MAX_SIZE_IN_BYTES) {
        dispatch(showOrHideApiLoadingModal(true));
      }

      getImageDimension(fileUploaded).then((imageDimensions) => {
        setImageDetails({
          name: fileUploaded.name,
          dimensions: imageDimensions,
          size: convertBytesToMegaBytes(fileUploaded.size),
          date: formatDateTime(new Date(fileUploaded.lastModified)),
          extension: getFileExtension(fileUploaded.name),
          preview: getPreviewUrlFromFile(fileUploaded, fileUploaded.size),
        });
        dispatch(showOrHideApiLoadingModal(false));
      });
      setInputImage(fileUploaded);
    }
    setModal(true);
  };

  const handleUploadButton = () => {
    const fileExtension = getFileExtension(selectedFiles?.name);

    if (
      fileExtension === "jpg" ||
      fileExtension === "jpeg" ||
      fileExtension === "tiff" ||
      fileExtension === "tif"
    ) {
      if (selectedFiles.size <= MAX_SIZE_IN_BYTES) {
        handleUpload(inputImage, imageDetails)
          .then(() => {
            setUploadSuccess(true);
            setModal(false);
          })
          .catch((inErrorMessage) => {
            if (inErrorMessage !== "canceled") {
              setResponse(inErrorMessage);
              setUploadSuccess(false);
            } else {
              resetFileSelection();
            }
          });
      } else {
        setResponse(string.FILE_SIZE_EXCEEDED);
        setUploadSuccess(false);
      }
    } else {
      setResponse(string.UPLOAD_INVALID_FORMAT_MESSAGE);
      setUploadSuccess(false);
    }
  };

  const bodyClassName =
    progressBarValue === 100 && uploadSuccess
      ? "modal-body-container-spinner"
      : "modal-body-container";

  const headerClassName =
    progressBarValue === 100 && uploadSuccess
      ? "header-background header-background-spinner"
      : "header-background";

  return (
    <>
      <div className="img-edit-container">
        <>
          <Modal isOpen={modal} className="upload-modal" centered>
            <ModalHeader className={headerClassName}>
              <img
                src={closeSvg}
                className="close-modal-svg"
                onClick={() => {
                  handleUploadClose();
                  setUploadSuccess(true);
                  setModal(false);
                }}
                alt="close"
              />

              <>
                {progressBarValue === 100 &&
                uploadSuccess ? null : uploadSuccess ? (
                  <>
                    <ModalHeaderComponent
                      uploadPhoto={string.UPLOAD_PHOTO}
                      photoDescription={string.UPLOAD_PHOTO_DESCRIPTION}
                    />
                  </>
                ) : (
                  <>
                    <p className="modal-title-text">
                      {string.SOMETHING_WENT_WRONG}
                    </p>
                    <p className="modal-title-description">
                      {string.UPLOAD_FAILED_DESCRIPTION}
                    </p>
                  </>
                )}
              </>
            </ModalHeader>

            <ModalBody className={bodyClassName}>
              {progressBarValue === 100 && uploadSuccess ? (
                <UploadSpinner />
              ) : imageDetails && imageDetails.name ? (
                <>
                  <UploadDialog
                    uploadAction={handleUploadButton}
                    imageDetails={imageDetails}
                    uploadSuccess={uploadSuccess}
                    onReset={resetFileSelection}
                    termsAndCondition={true}
                    response={response}
                  />
                </>
              ) : (
                <Dropzone onDrop={onDrop} multiple={false}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <>
                          <img src={uploadSvg} alt=""></img>
                          <p className="upload-text">
                            {string.CLICK_TO_UPLOAD}&nbsp;{" "}
                            <span>{string.OR}</span>&nbsp;
                            {string.DRAG_AND_DROP}
                          </p>
                          <p className="upload-description">
                            {string.JPG_OR_TIFF}
                            <br /> {string.MAX_400MB}
                          </p>
                        </>
                      </div>
                      <div className="upload_image_footer_links">
                        <ModalFooter />
                      </div>
                    </section>
                  )}
                </Dropzone>
              )}
            </ModalBody>
          </Modal>
        </>

        <div className="img-edit-img">
          <div className="img-edit-image-container">
            <img src={imageURL} alt="" className="update-image" />
          </div>
          <div className="img-edit-details">
            <div className="image-name">
              {imageData && imageData.upload_file_name
                ? imageData?.upload_file_name
                : imageData?.image_file_name}
            </div>
            <div className="image-details">
              {imageData && imageData.image_size}
              {string.MB}&nbsp;|&nbsp;
              {imageData && imageData.image_dimensions_in_inches}
            </div>
          </div>
        </div>
        {isAiImage ? (
          ""
        ) : (
          <div
            className="img-edit-btn"
            onClick={() => {
              sendGAClickedUpdatePhoto();
            }}
          >
            <Dropzone onDrop={onDrop} multiple={false}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />

                    <>
                      <div className="update-image-dropzone">
                        <div className="img-btn-text">
                          {string.UPDATE_PHOTO}
                        </div>
                        <div>
                          <img
                            src={imagesmode}
                            alt=""
                            className="img-update-btn"
                          />
                        </div>
                      </div>
                    </>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        )}
      </div>
    </>
  );
};

const ModalHeaderComponent = ({ uploadPhoto, photoDescription }) => {
  return (
    <>
      <p className="modal-title-text">{uploadPhoto}</p>
      <p className="modal-title-description">{photoDescription}</p>
    </>
  );
};

export default UpdateImage;
