import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  setCanShowBackAlertModal,
  setCanShowErrorAlertModal,
  setImageErrorMessage,
} from "../../../Store/Project/ProjectSlice";
import Closebutton from "../../../Utils/Icons/close.svg";
import { setCanShowAlertModal } from "../../../Store/Project/ProjectSlice";
import "./ProjectSummaryModal.scss";
import { setActivePageIndex } from "../../../Store/Project/PageSlice";
import { store } from "../../../Store/Store";
import { encrypt } from "../../../Utils/ApiHelper";
import {
  dataURLToBlob,
  startPollingForBlockchainValidation,
  validateImage,
} from "../../../Utils/Helper";
import { PAGE_PHOTO_PRINT_DETAILS } from "../../../Utils/Constants";
import { getProjectPayload } from "../../../Utils/DraftHelper";
import {
  sendGACanceledEncryptAndLockinModal,
  sendGAClickedEncryptAndLockinModal,
  sendGAClickedGoToFinalProduct,
} from "../../../Utils/GAHelper";
import { string } from "../../../Localization";
import { setImageAlreadyPresentError } from "../../../Store/Project/ModalSlice";
import StatusModal from "../../../Components/StatusModal/StatusModal";
import error_icon from "../../../Utils/Icons/error_icon.svg";
import status_right from "../../../Utils/Icons/status_right.svg";
import { sendVisualRefImages } from "../../../Utils/VirtualViewHelper";

const ProjectSummaryModal = (props) => {
  const { previewImage, projectToken, sizeSelectionDetails } =
    store.getState().project;

  const { canShow, submitData } = props;
  const { submitText, cancelBtn, cancelHandler } = submitData;
  const [startLoader, setStartLoader] = useState(true);
  const [encryptSuccess, setEncryptSuccess] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); //encryption error modal state
  const [isOpen, setIsOpen] = useState(false); //something went wrong error modal state

  const dispatch = useDispatch();

  const validateImageBeforeEncrypt = () => {
    validateImage(projectToken, sizeSelectionDetails.selectedId)
      .then((res) => {
        handleEncryptAndSell();
      })
      .catch((error) => {
        console.log(error);
        setStartLoader(true);
        console.log("validate-image FAILED");
        dispatch(setCanShowBackAlertModal(false));
        if (error.response.status === 424) {
          store.dispatch(setCanShowErrorAlertModal(true));
          store.dispatch(setImageAlreadyPresentError(true));
          store.dispatch(
            setImageErrorMessage(string.THIS_IMAGE_ALREADY_EXISTS_DESCRIPTION)
          );
        } else if (error.response.status === 422) {
          setIsModalOpen(true);
          dispatch(setCanShowErrorAlertModal(false));
          dispatch(setCanShowBackAlertModal(false));
        } else {
          dispatch(setCanShowBackAlertModal(false));
          store.dispatch(setCanShowErrorAlertModal(false));
          setIsOpen(true);
        }
      });
  };

  const handleEncryptAndSell = () => {
    const finalPreview = dataURLToBlob(previewImage);
    const formData = getProjectPayload(finalPreview, null);
    encrypt(formData, projectToken)
      .then((res) => {
        if (res.status === 200) {
          startPollingForBlockchainValidation(projectToken)
            .then((res) => {
              if (res.status === 200) {
                sendVisualRefImages().then(() => {
                  setEncryptSuccess(true);
                });
              }
            })
            .catch(function (error) {
              dispatch(setCanShowBackAlertModal(false));
              if (error.response.status === 408) {
                store.dispatch(setCanShowErrorAlertModal(true));
                store.dispatch(setImageAlreadyPresentError(true));
                store.dispatch(
                  setImageErrorMessage(
                    string.THIS_IMAGE_ALREADY_EXISTS_DESCRIPTION
                  )
                );
              } else if (error.response.status === 422) {
                setIsModalOpen(true);
                dispatch(setCanShowErrorAlertModal(false));
                dispatch(setCanShowBackAlertModal(false));
              } else {
                dispatch(setCanShowBackAlertModal(false));
                store.dispatch(setCanShowErrorAlertModal(false));
                setIsOpen(true);
              }
              console.log("ERROR MESSAGE ", error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(setCanShowBackAlertModal(false));
        if (error.response.status === 424) {
          store.dispatch(setCanShowErrorAlertModal(true));
          store.dispatch(setImageAlreadyPresentError(true));
          store.dispatch(
            setImageErrorMessage(string.THIS_IMAGE_ALREADY_EXISTS_DESCRIPTION)
          );
        } else if (error.response.status === 422) {
          setIsModalOpen(true);
          dispatch(setCanShowErrorAlertModal(false));
          dispatch(setCanShowBackAlertModal(false));
        } else {
          dispatch(setCanShowBackAlertModal(false));
          store.dispatch(setCanShowErrorAlertModal(false));
          setIsOpen(true);
        }
      });
  };
  const closeHandler = () => {
    setIsModalOpen(false);
  };
  const closeErrorHandler = () => {
    setIsOpen(false);
  };
  return (
    <>
      <div className="summary-dialog-container">
        <Modal show={canShow} className="modal-container-wrap">
          <Modal.Body className="summary-modal-container">
            {startLoader && (
              <>
                <div className="close-dialog">
                  <img
                    style={{ cursor: "pointer" }}
                    src={Closebutton}
                    onClick={() => {
                      dispatch(setCanShowAlertModal(false));
                      dispatch(setCanShowBackAlertModal(false));
                      sendGACanceledEncryptAndLockinModal();
                    }}
                    alt="cancel"
                  />
                </div>
                <div className="summary-modal-content">
                  <div className="summary-modal-heading">
                    {string.ENCRYPT_AND_LOCK_EDITION}
                  </div>
                  <div>
                    <div className="summary-modal-body">
                      {string.ENCRYPT_AND_LOCK_EDITION_TEXT_1}
                    </div>
                    <div className="summary-modal-body2">
                      {string.ENCRYPT_AND_LOCK_EDITION_TEXT_2}
                    </div>{" "}
                  </div>
                  <div className="dialog-buttons">
                    <button
                      className="dialog-cancel"
                      onClick={() => {
                        cancelHandler();
                        sendGACanceledEncryptAndLockinModal();
                      }}
                    >
                      {cancelBtn}
                    </button>
                    <button
                      className="dialog-log"
                      onClick={() => {
                        setStartLoader(false);
                        validateImageBeforeEncrypt();
                        sendGAClickedEncryptAndLockinModal();
                      }}
                    >
                      {submitText}
                    </button>
                  </div>
                </div>
              </>
            )}
            {!startLoader && (
              <>
                {encryptSuccess ? (
                  <div className="go_to_final_product">
                    <div className="emty-space-for-alignment"></div>
                    <div className="status_right_image">
                      {" "}
                      <img alt="status" src={status_right} />
                    </div>
                    <div className="summary-modal-content">
                      <div className="summary-modal-heading">
                        {string.PHOTO_ENCRYPTED_LOCKED}
                      </div>
                      <div>
                        <div className="finalSummary-modal-body">
                          {string.PHOTO_ENCRYPTED_LOCKED_TEXT_1}
                        </div>
                        <div className="finalSummary-modal-body2">
                          {string.PHOTO_ENCRYPTED_LOCKED_TEXT_2}
                        </div>{" "}
                      </div>
                      <div className="dialog-buttons">
                        <button
                          className="dialog-final"
                          onClick={() => {
                            dispatch(
                              setActivePageIndex(PAGE_PHOTO_PRINT_DETAILS)
                            );
                            cancelHandler();
                            sendGAClickedGoToFinalProduct();
                          }}
                        >
                          {string.GO_TO_FINAL_PRODUCT}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="emty-space-for-alignment"></div>
                    <div className="summary-modal-content">
                      <div className="summary-modal-heading">
                        {string.ENCRYPTING_AND_LOCKING_EDITION}
                      </div>

                      <div>
                        <div className="modal-loader-body">
                          {string.WRITING_HP_LEDGER}
                        </div>
                        <div className="modal-loader-body2">
                          {string.RECORDING_IMAGE_DIMENSIONS}
                        </div>
                      </div>
                      <div
                        className="upload-spinner"
                        style={{
                          width: "93px",
                          height: "93px",
                          padding: "15px",
                          marginTop: "30px",
                        }}
                      ></div>
                    </div>
                  </>
                )}
              </>
            )}
          </Modal.Body>
        </Modal>
      </div>
      <StatusModal
        canShow={isModalOpen}
        stautsbackground={{ background: "#212121" }}
        statusimge={error_icon}
        statusHeading={"The image was not encrypted"}
        statusDescription={`We're sorry, your image could not be encrypted. Please try again in a few moments.`}
        closebackground={{ background: "#212121" }}
        closeData={{
          closeBtn: string.CLOSE,
          closeHandler: closeHandler,
        }}
        width={"100%"}
        subHeadingWidth={"400px"}
      />
      <StatusModal
        canShow={isOpen}
        stautsbackground={{ background: "#212121" }}
        statusimge={error_icon}
        statusHeading={string.SOMETHING_WENT_WRONG}
        statusDescription={`Please try again in a few moments.`}
        closebackground={{ background: "#212121" }}
        closeData={{
          closeBtn: string.CLOSE,
          closeHandler: closeErrorHandler,
        }}
        width={"100%"}
        subHeadingWidth={"400px"}
      />
    </>
  );
};

export default ProjectSummaryModal;
