import React from "react";
import { useSelector } from "react-redux";
import {
  ORDER_WITH_COUPON_STEPS,
  VIEW_PAGE_STEPS,
} from "../../Utils/Constants";
import checkSvg from "../../Utils/Icons/check_black.svg";
import "./Steps.scss";

const Steps = (props) => {
  const { currentStep } = props;
  const { isCouponUsed } = useSelector((state) => state.order);

  const pageSteps = isCouponUsed ? ORDER_WITH_COUPON_STEPS : VIEW_PAGE_STEPS;

  return (
    <div className="steps-wrapper d-flex ">
      {pageSteps?.map((step, i) => (
        <div
          key={step.id}
          className={
            step.id < currentStep
              ? "i-step d-flex flex-column complete"
              : "i-step d-flex flex-column"
          }
        >
          {step.id < currentStep ? (
            <div className="circle">
              <img src={checkSvg} alt="" />{" "}
            </div>
          ) : (
            <div
              className={
                currentStep === step.id
                  ? "circle active-step"
                  : "circle next-steps"
              }
            >
              {i + 1}
            </div>
          )}

          <span
            className={
              step.id < currentStep
                ? "mt-1 complete"
                : step.id === currentStep
                ? "mt-1 selected"
                : "mt-1 pending"
            }
          >
            {step.name}
          </span>
        </div>
      ))}
    </div>
  );
};

export default Steps;
