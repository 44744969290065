import { createSlice } from "@reduxjs/toolkit";
import {
  COPIES_HEIGHT,
  COPIES_WIDTH,
  DEFAULT_NO_OF_COPIES,
  fontOptions,
} from "../../Utils/Constants";

const initialState = {
  noOfCopies: DEFAULT_NO_OF_COPIES,
  isLimited: true,
  canShowNoOfCopies: false,
  font: {
    type: fontOptions[0].type,
    family: fontOptions[0].family,
  },
  plot: {
    size: {
      width: COPIES_WIDTH,
      height: COPIES_HEIGHT,
    },
    position: {
      x: 0,
      y: 0,
    },
    fontSize: null,
  },
  limitedEditionPlotIsSet: false,
  textHeightToFontSizeRatio: 1,
  limitedEditionSelected: false,
};

const limitedEditionSlice = createSlice({
  name: "limited",
  initialState: initialState,

  reducers: {
    resetLimitedEdition: () => initialState,

    setIsLimited(state, action) {
      state.isLimited = action.payload;
    },

    setNoOfCopies(state, action) {
      state.noOfCopies = action.payload;
    },

    setCanShowNoOfCopies(state, action) {
      state.canShowNoOfCopies = action.payload;
    },

    setNoOfCopiesPlot(state, action) {
      state.plot = { ...state.plot, ...action.payload };
      state.limitedEditionPlotIsSet = true;
    },

    setFontFamily(state, action) {
      state.font = { type: action.payload.type, family: action.payload.family };
    },

    setTextHeightToFontSizeRatio(state, action) {
      state.textHeightToFontSizeRatio = action.payload;
    },
    setLimitedEditionSelected(state, action) {
      state.limitedEditionSelected = action.payload;
    },
  },
});

export const {
  resetLimitedEdition,
  setIsLimited,
  setNoOfCopies,
  setFontFamily,
  setCanShowNoOfCopies,
  setNoOfCopiesPlot,
  setTextHeightToFontSizeRatio,
  setLimitedEditionSelected,
} = limitedEditionSlice.actions;
export default limitedEditionSlice.reducer;
