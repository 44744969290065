import React, { useEffect, useState } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { useDispatch, useSelector } from "react-redux";
import warning_size from "../../../Utils/Icons/warning_size.svg";
import {
  setActivePreviewMode,
  setImageDimensions,
  setSelectedUnitSystem,
  setSizeApplied,
} from "../../../Store/Project/ProjectSlice";
import {
  PROJECT_PREVIEW_MODE_SIZE_SELECTION,
  UNIT_SYSTEM_INCHES,
  UNIT_SYSTEM_MM,
} from "../../../Utils/Constants";
import {
  convertInchesToMm,
  getOrientation,
  getSelectedMediaTypeData,
  validateImage,
} from "../../../Utils/Helper";
import infoIcon from "../../../Utils/Icons/info.svg";
import Select from "react-select";
import "./PrintSize.scss";
import RadioButton from "../RadioButton/RadioButton";
import {
  applyPrintSize,
  applySuperResolution,
  getMaximumSuperResolutionScale,
  getPixelatedMessage,
  hasPixelationWarning,
  setAndGetBestMatchDimension,
  setHighlightedPrintSizeValues,
} from "../../../Utils/projectHelper";
import { string } from "../../../Localization";
import BasicModal from "../../../Components/Modal/BasicModal/BasicModal";

const PrintSize = (props) => {
  const {
    imageDimensions,
    sizeSelectionDetails,
    originalProjectSize,
    selectedUnitSystem,
    projectToken,
    selectedMediaTypeId,
    isCanvasLoading,
    superResolution,
  } = useSelector((state) => state.project);
  const superResoltionSelectedScale = superResolution.selectedScale;

  const { mediaTypesData } = useSelector((state) => state.cache);
  const { selectedId, bestMatchId } = sizeSelectionDetails;

  const maximimScale = getMaximumSuperResolutionScale();

  const options = Array.from({ length: maximimScale }, (_, index) => ({
    value: index + 1,
    label: `${index + 1}.0`,
  }));

  const [tabIndex, setTabIndex] = useState(0);
  const [selectedResolution, setSelectedResolution] = useState(null); //selected print size
  const [sizeStatus, setSizeStatus] = useState("");
  const [selectedScale, setSelectedScale] = useState(
    superResoltionSelectedScale ?? 1
  );
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  const unitSystemChoices = [
    {
      text: UNIT_SYSTEM_MM,
      value: UNIT_SYSTEM_MM,
      id: "printSizeMm",
      group: "printSize",
    },
    {
      text: UNIT_SYSTEM_INCHES,
      value: UNIT_SYSTEM_INCHES,
      id: "printSizeInches",
      group: "printSize",
    },
  ];

  useEffect(() => {
    setLoading(isCanvasLoading);
  }, [isCanvasLoading]);

  useEffect(() => {
    const selectedData = getSelectedMediaTypeData();
    if (
      originalProjectSize.width !== 0 &&
      originalProjectSize.height !== 0 &&
      selectedData &&
      !selectedId
    ) {
      const selectedDimensions = selectedData["product_sizes"];
      dispatch(setImageDimensions(selectedDimensions));

      const matchId = setAndGetBestMatchDimension();
      const mergedArray = [].concat.apply(
        [],
        Object.values(selectedDimensions)
      );

      let matchedDimension = null;
      const matchedDimensions = mergedArray.filter(
        (dimension) => dimension.id === matchId
      );
      if (matchedDimensions.length > 0) {
        matchedDimension = matchedDimensions[0];
      }

      const imageOrientation = getOrientation(matchedDimension);
      orientationIsChanged(selectedDimensions, imageOrientation);

      handleSize(matchedDimension);
    } else if (
      originalProjectSize.width !== 0 &&
      originalProjectSize.height !== 0 &&
      selectedData &&
      selectedId
    ) {
      const dimensions = selectedData["product_sizes"];
      dispatch(setImageDimensions(dimensions));
      const mergedArray = [].concat.apply([], Object.values(dimensions));

      const selectedDimension = mergedArray.find(
        (dimension) => dimension.id === selectedId
      );
      if (selectedDimension) {
        setSelectedResolution(selectedDimension);
        setSizeStatus(selectedDimension.resolution_in_inches);
      } else {
        // when mediatype is changed, if previously selected size in not available for new mediaType, then the best match size is set as default.
        const bestMatchId = setAndGetBestMatchDimension();
        let matchedDimension = null;
        const matchedDimensions = mergedArray.filter(
          (dimension) => dimension.id === bestMatchId
        );
        if (matchedDimensions.length > 0) {
          matchedDimension = matchedDimensions[0];
          props.onChangeSizeSelection(matchedDimension.id, {
            width: matchedDimension.width,
            height: matchedDimension.height,
          });
          setSelectedResolution(matchedDimension);
          setSizeStatus(matchedDimension.resolution_in_inches);
          dispatch(setSizeApplied(false));
          dispatch(setActivePreviewMode(PROJECT_PREVIEW_MODE_SIZE_SELECTION));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalProjectSize, mediaTypesData, selectedMediaTypeId]);

  const orientationIsChanged = (inDimensions, inOrientation) => {
    const orientationTabIndex = Object.keys(inDimensions).findIndex(
      (key) => key === inOrientation
    );
    setTabIndex(orientationTabIndex);
  };

  const applyCrop = () => {
    setSelectedResolution("");
    dispatch(setSizeApplied(true));
    applyPrintSize();
  };

  const verifyImage = () => {
    validateImage(projectToken, selectedId)
      .then(() => {
        applyCrop();
      })
      .catch(() => {});
  };

  const handleSize = (size) => {
    setSelectedResolution(size);
    setSizeStatus(size.resolution_in_inches);
    dispatch(setSizeApplied(false)); //size is just selected not applied yet
    props.onChangeSizeSelection(size.id, {
      width: size.width,
      height: size.height,
    });
  };

  const unitIsChanged = (e) => {
    dispatch(setSelectedUnitSystem(e.target.value));
    setHighlightedPrintSizeValues();
  };

  const getDisplaySize = (size) => {
    if (selectedUnitSystem === UNIT_SYSTEM_MM) {
      const widthInMm = parseInt(convertInchesToMm(size.width));
      const heightInMm = parseInt(convertInchesToMm(size.height));
      return `${widthInMm} x ${heightInMm}`;
    } else {
      return `${size.width} x ${size.height}`;
    }
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "transparent",
      borderRadius: "8px 8px 8px 8px",
      borderColor: "#737373",
      color: "black",
      "&:hover": {
        borderColor: "#737373",
        boxShadow: "none",
      },

      boxShadow: "none",
      "&:focus": {
        borderColor: "#737373",
        boxShadow: "none",
      },
      width: "100%",
      height: "29px",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: "6px",
      border: "1px solid #737373",
      boxShadow: "none",
      scrollbarWidth: "none",
      maxHeight: "300px",
      overflow: "scroll",
      marginTop: "-1px",

      "&::-webkit-scrollbar": {
        display: "none",
      },
    }),
    menuList: (base) => ({
      ...base,
      color: "black",
      marginTop: "0px",
      padding: "0px",
      margin: "0px",
      border: "none",
      boxShadow: "none",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        width: "2px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#1a1e22",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#353535",
      },
    }),
    placeholder: (base) => ({
      ...base,
      color: "#F8F8F8",
    }),
    singleValue: (base) => ({
      ...base,
      color: "#F8F8F8",
    }),
    input: (base) => ({
      ...base,
      color: "#F8F8F8",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    option: (base, state) => ({
      ...base,
      border: "none",
      boxShadow: "none",
      backgroundColor: state.isFocused ? "#1a1e22" : "#404040",
      color: state.isFocused ? "white" : "#DBDBDB",
      "&:hover": {
        backgroundColor: "#1a1e22",
        color: "white",
      },
      "&:active": {
        backgroundColor: "#353535",
        color: "white",
      },
    }),
  };

  return (
    <>
      {maximimScale > 1 && (
        <div className="super-resolution">
          <div className="d-flex align-items-center">
            <span>{string.IMPROVE_IMAGE_QUALITY}</span>
            <img
              alt=""
              src={infoIcon}
              className="ms-2 cursor-pointer"
              onClick={() => {
                setShowModal(true);
              }}
            />
          </div>
          <div className="ai_resolution_contant">
            <Select
              id="resolution"
              name="resolution"
              placeholder={selectedScale}
              options={options}
              value={selectedScale}
              styles={customStyles}
              onChange={(value) => {
                setSelectedScale(value.label);
              }}
            />
            <button
              className={`ai_btn ${
                superResoltionSelectedScale !== selectedScale &&
                loading === false
                  ? "ai_btn_enable"
                  : "ai_btn_disable"
              }`}
              onClick={() => {
                if (
                  superResoltionSelectedScale !== selectedScale &&
                  loading === false
                ) {
                  applySuperResolution(selectedScale);
                }
              }}
            >
              {string.IMPROVE_RESOLUTION}
            </button>
          </div>
        </div>
      )}

      <div className="sizing-wrapper">
        <Tabs
          selectedIndex={tabIndex}
          onSelect={(index) => {
            setTabIndex(index);
          }}
        >
          <TabList className="mt--10">
            {Object.keys(imageDimensions).map((sizeOrientation) => (
              <Tab>{sizeOrientation}</Tab>
            ))}
          </TabList>
          <div className="printsize-radiobutton">
            {unitSystemChoices.map((unitSystemChoice) => (
              <RadioButton
                details={unitSystemChoice}
                onChange={(e) => {
                  unitIsChanged(e);
                }}
                activeValue={selectedUnitSystem}
              />
            ))}
          </div>
          {Object.values(imageDimensions).map((data, i) => (
            <TabPanel key={i}>
              {data.map((size, index) => (
                <div
                  key={size.toString()}
                  className="sizing-list"
                  style={{
                    background:
                      size.resolution_in_inches === sizeStatus ? "#404040" : "",
                  }}
                  onClick={() => {
                    handleSize(size);
                    dispatch(
                      setActivePreviewMode(PROJECT_PREVIEW_MODE_SIZE_SELECTION)
                    );
                  }}
                >
                  <div className="size_heads">
                    <span className="size_head"> {getDisplaySize(size)}</span>
                    {hasPixelationWarning(size.width, size.height) && (
                      <Tooltip
                        content={getPixelatedMessage(size.width, size.height)}
                      >
                        <img
                          className="warning_img"
                          alt="warning"
                          src={warning_size}
                        />
                      </Tooltip>
                    )}
                  </div>

                  {bestMatchId === size.id ? (
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginLeft: "29px",
                      }}
                      className={size === selectedResolution ? "target" : ""}
                    >
                      <span className="size_label">{string.BEST_MATCH}</span>{" "}
                    </span>
                  ) : (
                    ""
                  )}
                  {selectedResolution === size ? (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        verifyImage();
                      }}
                      style={{ textDecoration: "underline" }}
                      className="animated fadeIn"
                    >
                      {string.APPLY_CHANGES}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </TabPanel>
          ))}
        </Tabs>
      </div>
      <BasicModal
        openModal={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
      >
        <div className="info_heading">Super Resolution Tool</div>
        <div className="info_content">
          <div className="info_text1">
            {string.SUPER_RESOLUTION_MODAL_TEXT1}
          </div>
          <div className="info_text1">
            {string.SUPER_RESOLUTION_MODAL_TEXT2}
          </div>
        </div>
      </BasicModal>
    </>
  );
};

const Tooltip = ({ content, children }) => {
  return (
    <div className="tooltip-container">
      {children}
      <div className="tooltip-content left-align">
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>
    </div>
  );
};

export default PrintSize;
