import React from "react";
import "./LogoHP.scss";
import Logo from "../../Utils/Icons/new-logo-hp.svg";
import { string } from "../../Localization";

const LogoHP = () => {
  return (
    <>
      <div className="welcome-page-logo">
        <div className="welcome-page-logo-container">
          <img src={Logo} alt="" className="welcome-logo" />
        </div>
        <div className="welcome-page-logo-container-text">
          {string.AN_HP_DEVELOPMENT_BETA}
        </div>
      </div>
    </>
  );
};

export default LogoHP;
