import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Preview.scss";
import {
  PROJECT_PREVIEW_MODE_INITIAL,
  PROJECT_PREVIEW_MODE_SIZE_SELECTION,
  PROJECT_PREVIEW_MODE_CROPPED,
  PROJECT_PREVIEW_MODE_CERTIFICATE,
  PROJECT_PREVIEW_MODE_PERSONALIZATION,
  STEP_ONE_PHOTO_DETAILS,
  HUMAN_VIEW,
  SOFA_VIEW,
  VIEW_TYPES,
  UNIT_SYSTEM_MM,
  HUMAN_HEIGHT_INCHES,
  SOFA_WIDTH_INCHES,
  NORMAL_VIEW,
} from "../../Utils/Constants";
import ImageCanvas from "./ImageCanvas/ImageCanvas";
import CroppedCanvas from "./CroppedCanvas/CroppedCanvas";
import magnifierIcon from "../../Utils/Icons/image_search.svg";
import magnifierIconGreen from "../../Utils/Icons/image_search2.svg";
import closeSvg from "../../Utils/Icons/close.svg";
import closeSvgGray from "../../Utils/Icons/close_gray2.svg";
import Measurement from "./Measurement/Measurement";
import { handleMagnifierClick } from "../../Utils/projectHelper";
import backGround1 from "../../Utils/Icons/human.svg";
import backGround2 from "../../Utils/Icons/sofa.svg";
import {
  sendGAClickedMagnifierIcon,
  sendGAClickedVirtaulPreviewPerson,
  sendGAExitedMagnifierView,
} from "../../Utils/GAHelper";
import { setCanvasViewMode } from "../../Store/Project/ProjectSlice";
import {
  getHumanViewSize,
  getSofaViewSize,
  setLabelPosition,
} from "../../Utils/VirtualViewHelper";
import ProjectLoader from "../../Components/Loaders/ProjectLoader/ProjectLoader";
import SuperResFlowLoader from "../../Components/Loaders/SuperResFlowLoader/SuperResFlowLoader";

const Preview = () => {
  const dispatch = useDispatch();
  const sofaRef = useRef();
  const virtualButtons = useRef();

  const {
    activePreviewMode,
    canvasViewMode,
    isMagnifierModeOn,
    selectedUnitSystem,
  } = useSelector((state) => state.project);

  const { activeStep } = useSelector((state) => state.page);

  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (
      activePreviewMode === PROJECT_PREVIEW_MODE_SIZE_SELECTION &&
      canvasViewMode !== NORMAL_VIEW
    ) {
      dispatch(setCanvasViewMode(NORMAL_VIEW));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePreviewMode]);

  useEffect(() => {
    if (canvasViewMode !== SOFA_VIEW) {
      root.style.setProperty("--virtual-button-bottom", `30px`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasViewMode]);

  const renderPreview = () => {
    switch (activePreviewMode) {
      case PROJECT_PREVIEW_MODE_INITIAL:
      case PROJECT_PREVIEW_MODE_SIZE_SELECTION:
        return <ImageCanvas />;
      case PROJECT_PREVIEW_MODE_CROPPED:
      case PROJECT_PREVIEW_MODE_PERSONALIZATION:
      case PROJECT_PREVIEW_MODE_CERTIFICATE:
        return <CroppedCanvas />;
      default:
        return "";
    }
  };

  let styleName = "preview-wrapper";

  const humanHeight =
    selectedUnitSystem === UNIT_SYSTEM_MM ? "1.74m" : `${HUMAN_HEIGHT_INCHES}"`;

  const sofaWidth =
    selectedUnitSystem === UNIT_SYSTEM_MM ? "2.6m" : `${SOFA_WIDTH_INCHES}"`;

  const root = document.querySelector(":root");
  const humanSize = getHumanViewSize();
  if (humanSize) {
    root.style.setProperty("--human-width", `${humanSize.width}px`);
    root.style.setProperty("--human-height", `${humanSize.height}px`);
  }

  const sofaSize = getSofaViewSize();
  if (sofaSize) {
    root.style.setProperty("--sofa-width", `${sofaSize.width}px`);
    root.style.setProperty("--sofa-height", `${sofaSize.height}px`);
  }

  const onLoad = () => {
    setLabelPosition(sofaRef, virtualButtons);
  };

  return (
    <div
      id="preview-wrapper"
      className={styleName}
      style={{ flexDirection: canvasViewMode === SOFA_VIEW ? "column" : "row" }}
    >
      {activePreviewMode === PROJECT_PREVIEW_MODE_PERSONALIZATION && (
        <img
          onClick={() => {
            handleMagnifierClick(!isMagnifierModeOn);
            isMagnifierModeOn
              ? sendGAExitedMagnifierView()
              : sendGAClickedMagnifierIcon();
          }}
          src={
            isMagnifierModeOn
              ? hover
                ? closeSvg
                : closeSvgGray
              : hover
              ? magnifierIconGreen
              : magnifierIcon
          }
          className="magnifier-button"
          alt=""
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        />
      )}
      {renderPreview()}
      {activeStep === STEP_ONE_PHOTO_DETAILS &&
        canvasViewMode === HUMAN_VIEW && (
          <>
            <div className="human-view-wrapper">
              <img src={backGround1} alt="" />
              <div className="view-label human-view-box">{humanHeight}</div>
            </div>
          </>
        )}
      {activeStep === STEP_ONE_PHOTO_DETAILS &&
        canvasViewMode === SOFA_VIEW && (
          <>
            <div className="sofa-view-wrapper ">
              <img src={backGround2} alt="" ref={sofaRef} onLoad={onLoad} />
              <div className="view-label sofa-view-box">{sofaWidth}</div>
            </div>
          </>
        )}
      <div className="measurement-container">
        <Measurement />
      </div>
      {activeStep === STEP_ONE_PHOTO_DETAILS && (
        <>
          <div className="Cropped-button" ref={virtualButtons}>
            {VIEW_TYPES.map((view) => (
              <button
                className={
                  canvasViewMode === view.name
                    ? "single-cropped-button active-cropped-button"
                    : "single-cropped-button"
                }
                style={
                  view && view.name === HUMAN_VIEW
                    ? { borderRadius: "0px" }
                    : view && view.name === SOFA_VIEW
                    ? { borderRadius: "0px 11px 11px 0px" }
                    : { borderRadius: "11px 0px 0px 11px" }
                }
                onClick={() => {
                  dispatch(setCanvasViewMode(view.name));
                  sendGAClickedVirtaulPreviewPerson(view.name);
                }}
                disabled={
                  view.name !== NORMAL_VIEW &&
                  activePreviewMode !== PROJECT_PREVIEW_MODE_CROPPED
                }
              >
                <img src={view.url} alt="view" />
              </button>
            ))}
          </div>
        </>
      )}
      <ProjectLoader />

      <SuperResFlowLoader />
    </div>
  );
};

export default Preview;
