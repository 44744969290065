import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import "./TermsAndCondition.scss";
import {
  disableTermsAndCondition,
  footerLinks,
  updateUserCountry,
} from "../../../Utils/ApiHelper";
import { redirectToHpSignOutPage } from "../../../Utils/Helper";
import Closebutton from "../../../Utils/Icons/close_black.svg";
import DialogBox from "../../../Containers/DialogBox/DialogBox";
import { string } from "../../../Localization";
import Select from "react-select";
import csc from "country-state-city";
import { SUPPORTED_COUNTRY } from "../../../Utils/Constants";
import {
  sendGAAcceptedTermsAndCondition,
  sendGAForCollectorCountryPreference,
} from "../../../Utils/GAHelper";
import { useParams } from "react-router-dom";
import PhotographerLicense from "../../../Containers/HPPrintServices/PhotographersLicense/PhotographerLicense";
import SubscriberTerms from "../../../Containers/HPPrintServices/PhotographersLicense/SubscriberTerms";
import { useDispatch, useSelector } from "react-redux";
import { setFooterLinksData } from "../../../Store/Project/CacheSlice";
import { updateAcceptLegalTerms } from "../../../Utils/UserHelper";

const TermsAndCondition = ({ canShow }) => {
  const countries = csc.getAllCountries();
  const scrollableElementRef = useRef(null);

  const { project_token } = useParams();

  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [isDeclined, setIsDeclined] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ country: null });
  const [selectedCountry, setSelectedCountry] = useState({
    label: string.COUNTRY_NAME,
    value: "230",
    name: string.COUNTRY_NAME,
    phonecode: "44",
    countryCode: "GB",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    footerLinks().then((res) => {
      dispatch(setFooterLinksData(res.data));
    });
  }, []);

  const isValidCountry = selectedCountry.countryCode === SUPPORTED_COUNTRY;

  const formattedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country,
  }));

  const validateCountry = (value) => {
    setSelectedCountry({
      label: value.name,
      value: value.id,
      name: value.name,
      phonecode: value.phonecode,
      countryCode: value.sortname,
    });

    if (value.sortname === SUPPORTED_COUNTRY) {
      setAlertMessage({ country: "" });
    } else {
      setAlertMessage({ country: string.SELECTED_COUNTRY_IS_NOT_SUPPORTED });
    }
    sendGAForCollectorCountryPreference(project_token, value["name"]);
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "transparent",
      borderRadius: "5px",
      borderColor: "gray",
      color: "black",
      "&:hover": {
        borderColor: "gray",
      },

      boxShadow: "none",
      "&:focus": {
        borderColor: "gray",
        boxShadow: "none",
      },
      width: "100%",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: "6px",
      border: "1px solid #737373",
      marginTop: "-1px",
      maxHeight: "200px",
      overflow: "scroll",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: "200px",
      overflowY: "scroll",
      color: "black",
      padding: " 0px",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        width: "2px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "white",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#737373",
      },
    }),
    placeholder: (base) => ({
      ...base,
      color: "gray",
    }),
    singleValue: (base) => ({
      ...base,
      color: "black",
    }),
    input: (base) => ({
      ...base,
      color: "black",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused ? "#176267" : "transparent",
      color: state.isFocused ? "white" : "black",
      "&:hover": {
        opacity: 0.6,
        color: "white",
      },
      "&:active": {
        backgroundColor: "#176267",
        color: "white",
      },
    }),
  };

  const handleAcceptClick = async () => {
    await disableTermsAndCondition();
    await updateUserCountry(selectedCountry.countryCode);
    setIsDeclined(true);
    setShowDialog(false);
    sendGAAcceptedTermsAndCondition();
    updateAcceptLegalTerms();
  };

  const handleDeclineClick = () => {
    setIsDeclined(true);
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setIsDeclined(false);
    setShowDialog(false);
  };

  const handleScroll = () => {
    const scrollableElement = scrollableElementRef.current;

    if (!scrollableElement) {
      return;
    }

    const scrollTop = scrollableElement.scrollTop;
    const scrollHeight = scrollableElement.scrollHeight;
    const clientHeight = scrollableElement.clientHeight;
    const scrollableHeight = scrollHeight - scrollTop - 1;
    setIsScrolledToBottom(scrollableHeight <= clientHeight);
  };

  return (
    <>
      {!isDeclined && (
        <div className="TCdialog-container">
          <Modal show={canShow} className="TCmodal-container-wrap">
            <Modal.Body className="TCmodal-container">
              <div className="TCclose-dialog">
                <img
                  className="close-btn"
                  src={Closebutton}
                  onClick={() => {
                    handleDeclineClick();
                  }}
                  alt="close"
                />
              </div>

              <div className="TCbody">
                <h4 className="TCheader">{string.TERMS_AND_CONDITIONS}</h4>
                <div
                  className="TCcontent"
                  id="TCcontent"
                  ref={scrollableElementRef}
                  onScroll={handleScroll}
                >
                  <h6 className="TCcontent-head">
                    {string.WHAT_IS_YOUR_COUNTRY}
                  </h6>
                  <div className="country-dropdown">
                    <Select
                      id="country"
                      name="country"
                      placeholder={string.SELECT__YOUR_COUNTRY_REGION}
                      options={formattedCountries}
                      value={selectedCountry}
                      styles={customStyles}
                      onChange={(value) => validateCountry(value)}
                    />

                    <div className="error-div">{alertMessage.country}</div>
                  </div>

                  <div className="terms_and_condition_container">
                    <PhotographerLicense />
                    <SubscriberTerms />
                  </div>
                </div>
                <div className="TCbuttons">
                  <button
                    className="TCcancelbutton"
                    onClick={handleDeclineClick}
                  >
                    {string.DECLINE}
                  </button>
                  <button
                    className={
                      isScrolledToBottom && isValidCountry
                        ? "TCacceptbutton"
                        : " TCacceptbutton disabledTCAccept"
                    }
                    onClick={
                      isScrolledToBottom && isValidCountry
                        ? handleAcceptClick
                        : undefined
                    }
                  >
                    {string.I_HAVE_READ_AND_ACCEPT}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}

      <DialogBox
        closeRequired={true}
        customStyles={{
          background: "#1a1e22",
          marginBottom: "20px",
        }}
        canShow={showDialog}
        heading={string.TERMS_AND_CONDITIONS}
        text1={string.TERMS_AND_CONDITIONS_MODAL_TEXT_1}
        text2={string.TERMS_AND_CONDITIONS_MODAL_TEXT_2}
        submitData={{
          submitText: string.YES,
          submitHandler: redirectToHpSignOutPage,
          cancelBtn: string.BACK,
          cancelHandler: handleCloseDialog,
        }}
      />
    </>
  );
};

export default TermsAndCondition;
