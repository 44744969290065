import React from "react";
import "./SideViewBar.scss";
import Stepper from "./Stepper/Stepper";
import backArrowSvg from "../../Utils/Icons/arrow_back.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  FEEDS,
  MY_GALLERY,
  ORDER_TAB,
  PAGE_HOME,
  PHOTOS_TAB,
} from "../../Utils/Constants";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import { setActiveTab } from "../../Store/Project/PageSlice";
import { setCanShowBackAlertModal } from "../../Store/Project/ProjectSlice";
import DialogBox from "../DialogBox/DialogBox";
import { Link, useNavigate } from "react-router-dom";
import { handleSaveDraft } from "../../Utils/SaveDraft";
import footerLogo from "../../Utils/Icons/footer-logo.svg";
import { resetAndRedirectToHomePage } from "../../Utils/DraftHelper";
import {
  sendGAClickedSaveAsDraft,
  sendGAGalleryView,
  sendGALoadedModalBackHome,
} from "../../Utils/GAHelper";
import LogoHP from "../../Components/LogoHP/LogoHP";
import { showOrHideApiLoadingModal } from "../../Store/Project/ModalSlice";
import ProjectLoader from "../../Components/Loaders/ProjectLoader/ProjectLoader";
import { string } from "../../Localization";

const SideViewBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { activePageIndex, activeTab } = useSelector((state) => state.page);
  const { CanShowBackAlertModal } = useSelector((state) => state.project);
  const { footerLinksData } = useSelector((state) => state.cache);
  const { currentUserDetails } = useSelector((state) => state.user);

  const submitHandler = () => {
    navigate("/");
    resetAndRedirectToHomePage();
  };

  const cancelHandler = () => {
    dispatch(showOrHideApiLoadingModal(true));
    handleSaveDraft();
    dispatch(setCanShowBackAlertModal(false));
    sendGAClickedSaveAsDraft();
  };

  let divSet = null;

  if (activePageIndex === PAGE_HOME) {
    divSet = (
      <>
        <Col sm={2} className="sidebar-wrapper">
          <div className="logo_icon padding_left">
            <LogoHP />
          </div>
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              <Nav.Link
                eventKey="My photos"
                onClick={() => {
                  dispatch(setActiveTab(PHOTOS_TAB));
                  sendGAGalleryView();
                }}
                className={activeTab === PHOTOS_TAB ? "active" : "not-active"}
              >
                {string.MY_PHOTOS}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="Order management"
                onClick={() => {
                  dispatch(setActiveTab(ORDER_TAB));
                }}
                className={activeTab === ORDER_TAB ? "active" : "not-active"}
              >
                {string.ORDER_MANAGEMENT}
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Link to={`/gallery/`} target="_blank" className="nav-link">
                {string.HP_LE_GALLERY}
              </Link>
            </Nav.Item>
            {
              <Nav.Item>
                <Link
                  to={`/mygallery/${currentUserDetails?.user_name}`}
                  className="nav-link"
                  target="_blank"
                >
                  {string.MY_GALLERY}
                </Link>
              </Nav.Item>
            }
            {currentUserDetails?.enable_feeds && (
              <Nav.Item>
                <Nav.Link
                  eventKey="Feeds"
                  onClick={() => {
                    dispatch(setActiveTab(FEEDS));
                  }}
                  className={activeTab === FEEDS ? "active" : "not-active"}
                >
                  {string.FEEDS}
                </Nav.Link>
              </Nav.Item>
            )}

            {/* <Nav.Item>
          <Nav.Link eventKey="Plans">Plans</Nav.Link>
        </Nav.Item> */}
          </Nav>

          <div className="side_view_bar_footer_container">
            <div className="side_view_bar_footer">
              <div className="side_view_bar_link">
                <a
                  href={footerLinksData["company-details"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="side_view_bar_text">
                    {string.COMPANY_DETAILS}
                  </div>
                </a>
              </div>
            </div>
            <div className="footer-wrap">
              <div className="footer-img">
                <img
                  src={footerLogo}
                  alt="Footer Logo"
                  className="footer-logo"
                />
              </div>
              <div className="footer-copyright">
                {string.HP_DEVELOPMENT_COMPANY_FOOTER}
              </div>
            </div>
          </div>
        </Col>
      </>
    );
  } else {
    divSet = (
      <>
        <ProjectLoader />

        <Col sm={3} className="sideViewBar-wrapper" id="sideview-bar-wrapper">
          <div
            className="back-navigation"
            onClick={() => {
              dispatch(setCanShowBackAlertModal(true));
              sendGALoadedModalBackHome();
            }}
          >
            <div className="back-navigation-content">
              <img src={backArrowSvg} alt="Back Arrow"></img>
              <div className="back-navigation-text">{string.BACK_HOME}</div>
            </div>
          </div>

          <div className="stepper-container">
            <Stepper />
          </div>
        </Col>
        <DialogBox
          canShow={CanShowBackAlertModal}
          heading={string.BACK_HOME}
          text1={string.ARE_YOU_SURE_THAT_YOU_WANT_TO_GO_BACK_HOME}
          text2={string.SAVE_A_DRAFT_FIRST}
          submitData={{
            submitText: string.BACK_HOME,
            submitHandler: submitHandler,
            cancelBtn: string.SAVE_AS_DRAFT,
            cancelHandler: cancelHandler,
          }}
          navigateTo={"/"}
        />
      </>
    );
  }

  return divSet;
};

export default SideViewBar;
