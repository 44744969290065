import React from "react";
import { string } from "../../Localization";
import "./CreateNew.scss";
import AddMore from "../../Utils/Icons/Vector.svg";
import { sendGAClickedUploadPhoto } from "../../Utils/GAHelper";

const CreateNew = (props) => {
  const { onClick, viewType } = props;
  const className = viewType === "center" ? "center" : "grid-view";

  return (
    <div onClick={onClick} className={`${className}`}>
      <div
        className={"create-print-container"}
        onClick={() => {
          sendGAClickedUploadPhoto();
        }}
      >
        <img src={AddMore} className="icon" alt="" />
        <p className="create-print-text">{string.UPLOAD_PHOTO}</p>
      </div>
    </div>
  );
};

export default CreateNew;
