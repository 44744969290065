import React, { useEffect, useState } from "react";
import CustomRadiobutton from "../CustomRadiobutton/CustomRadiobutton";
import {
  setDefaultSignatureOption,
  handleDeleteSignature,
  hasProfileSignature,
} from "../../../Utils/signatureHelper";
import "./Signature.scss";
import SignatureUpload from "../SignatureUpload/SignatureUpload";
import { useDispatch, useSelector } from "react-redux";
import { PROFILE } from "../../../Utils/Constants";
import {
  setCanShowSignature,
  setCoordsAltered,
  setSignatureType,
} from "../../../Store/Project/signatureSlice";
import { string } from "../../../Localization";
import { sendGAAddedSignInPersonalization } from "../../../Utils/GAHelper";

const Signature = () => {
  const { signatureType, canShowSignature } = useSelector(
    (state) => state && state.signature
  );

  const isFirstOptionToShow = () => {
    if (canShowSignature == null || canShowSignature) {
      return true;
    } else if (!canShowSignature) {
      return false;
    }
  };

  const isFirstOption = isFirstOptionToShow();
  const [canAddSignature, setCanAddSignature] = useState(isFirstOption);

  const dispatch = useDispatch();

  useEffect(() => {
    // canShowSignature is null means for the first time. Hence show first option by default
    if (canShowSignature == null) {
      sendGAAddedSignInPersonalization();
      if (signatureType == null) {
        setDefaultSignatureOption();
      }
    }
    if (!canAddSignature) {
      dispatch(setSignatureType(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddSignature = () => {
    dispatch(setSignatureType(PROFILE));
    setCanAddSignature(true);
  };

  const handleNoSignature = () => {
    setCanAddSignature(false);
    dispatch(setCanShowSignature(false));
    handleDeleteSignature().then(() => {});
    dispatch(setCoordsAltered(false));
  };

  const uploadSignatureText = hasProfileSignature()
    ? string.UPLOAD_A_NEW_SIGNATURE_AND_SAVE_IT
    : string.UPLOAD_AND_SAVE_IT_IN_MY_PROFILE;

  return (
    <div className="signature-wrapper">
      <div className="signature-options">
        <div className="signature-option1">
          <div>
            <CustomRadiobutton
              name="radio1"
              id="radio1"
              title={string.I_WANT_TO_SIGN_THIS_PRINT_EDITION}
              isChecked={canAddSignature}
              handleRadio={handleAddSignature}
            />
          </div>
          <div className="option-description">{string.CUSTOMIZE_SIGNATURE}</div>
          {canAddSignature && (
            <div className="signature-subOptions">
              <SignatureUpload uploadText={uploadSignatureText} />
            </div>
          )}
        </div>
        <div className="signature-option2">
          <div>
            <CustomRadiobutton
              name="radio1"
              id="radio4"
              title={string.I_DONT_WANT_TO_ADD_A_SIGNATURE}
              isChecked={!canAddSignature}
              handleRadio={handleNoSignature}
            />
          </div>
          <div className="option-description">
            {string.CERTIFICATE_OF_AUTHENTICITY_CAN_BE_APPLIED_LATER}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signature;
