import React from "react";
import AccordianComponent from "../Accordian/AccordianComponent";
import LimitedEdition from "../LimitedEditions/LimitedEdition";
import Signature from "../Signature/Signature";
import "./Personalization.scss";
import { SECTION_EDITION, SECTION_SIGNATURE } from "../../../Utils/Constants";
import { string } from "../../../Localization";

const Personalization = () => {
  const PersonalizationData = [
    {
      id: SECTION_SIGNATURE,
      heading: string.SIGNATURE,
      content: " ",
      dropContent: <Signature />,
    },
    {
      id: SECTION_EDITION,
      heading: string.LIMITED_EDITIONS_1,
      content: " ",
      dropContent: <LimitedEdition />,
    },
  ];

  return (
    <>
      <div className="create-print-photo">
        <div className="create-print-photo-text">{string.PERSONALIZATION}</div>
        <AccordianComponent accordionData={PersonalizationData} />
      </div>
    </>
  );
};

export default Personalization;
