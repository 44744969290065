import React from "react";
import { string } from "../../../Localization";
import "./PhotographerLicense.scss";
import { useSelector } from "react-redux";

const PhotographerLicense = () => {
  const { footerLinksData } = useSelector((state) => state.cache);
  const data = [
    //1
    {
      label: string.INTERPRETATION,

      children: [
        {
          label: string.INTERPRETATION_PT_1,
        },
        {
          label: string.INTERPRETATION_PT_2,
        },
        {
          label: string.INTERPRETATION_PT_3,
        },
        {
          label: string.INTERPRETATION_PT_4,
        },
        {
          label: string.INTERPRETATION_PT_5,
        },
        {
          label: string.INTERPRETATION_PT_6,
        },
        {
          label: string.INTERPRETATION_PT_7,
        },
        {
          label: string.INTERPRETATION_PT_9,
        },
      ],
    },
    //2
    {
      label: string.DEFINITIONS,
      subHeading: true,
      children: [
        {
          label:
            "<b>" + string.ACCEPTED_IMAGES + ": </b>" + string.DEFINITIONS_PT_1,
        },
        {
          label: "<b>" + string.AFFILIATE + ": </b>" + string.DEFINITIONS_PT_2,
        },
        {
          label: "<b>" + string.COLLECTOR + ": </b>" + string.DEFINITIONS_PT_P3,
        },
        {
          label:
            "<b>" +
            string.CONFIDENTIAL_INFORMATION +
            ": </b>" +
            string.DEFINITIONS_PT_4 +
            " 20 (“" +
            string.NOTICES_1 +
            "”). ",
        },
        {
          label:
            "<b>" + string.DOCUMENTATION + ":</b> " + string.DEFINITIONS_PT_7,
        },
        {
          label:
            "<b>" +
            string.HP_ENTERPRISE_LEDGER +
            ":</b> " +
            string.DEFINITIONS_PT_5,
        },
        {
          label:
            "<b>" + string.DIGITAL_IMAGE + ":</b> " + string.DEFINITIONS_PT_P6,
        },
        {
          label:
            "<b>" +
            string.INTELLECTUAL_PROPERTY_RIGHTS +
            ":</b> " +
            string.DEFINITIONS_PT_P8,
        },
        {
          label:
            "<b>" +
            string.LICENSOR_CONTENT +
            ":</b> " +
            string.LICENSOR_CONTENT_TEXT,
        },
        {
          label:
            "<b>" +
            string.NORMAL_BUISNESS_HOUR +
            ":</b> " +
            string.DEFINITIONS_PT_9,
        },
        {
          label: "<b>" + string.RESELLER + ":</b>" + string.RESELLER_TEXT,
        },
        {
          label:
            "<b>" +
            string.SERVICES +
            ":</b> " +
            string.SERVICES_TEXT_1 +
            " <a href={footerLinksData['photographer-subscription-terms-of-service']} target='_blank'> " +
            string.SUBSCRIBER_TERMS_OF_SERVICE +
            ".</a>",
        },

        {
          label: "<b>" + string.SOFTWARE + ":</b> " + string.DEFINITIONS_PT_12,
        },
        {
          label:
            "<b>" + string.TERM + ":</b> " + string.DEFINITIONS_PT_17 + " 10.",
        },
        {
          label: "<b>" + string.VIRUS + ":</b> " + string.VIRUS_TEXT_1,
        },
        {
          label:
            "<b>" +
            string.VULNERABILITY +
            ":</b> " +
            string.DEFINITIONS_PT_19 +
            " <b> &quot;" +
            string.VULNERABILITIES +
            "&quot; </b> " +
            string.DEFINITIONS_PT_20a,
        },
        {
          label:
            "<b>" +
            string.WEBSITE +
            ":</b> <a href='https://www.hplimiteditions.com/' target='_blank'>https://www.hplimiteditions.com/</a> " +
            string.DEFINITIONS_PT_P20,
        },
      ],
    },
    //3
    {
      label: string.SUBMISSION_ACCEPTANCE_PROPERTY_IMAGES,

      children: [
        {
          label: string.SUBMISSION_ACCEPTANCE_PROPERTY_IMAGES_PT_P1,
        },
        {
          label: string.SUBMISSION_ACCEPTANCE_PROPERTY_IMAGES_PT_P2,
        },
        {
          label:
            string.SUBMISSION_ACCEPTANCE_PROPERTY_IMAGES_PT_3a +
            ":  &quot;[" +
            string.CONTRIBUTORS +
            "]&quot; " +
            string.SUBMISSION_ACCEPTANCE_PROPERTY_IMAGES_PT_3ab,
        },
        {
          label: string.SUBMISSION_ACCEPTANCE_PROPERTY_IMAGES_PT_4,
        },
      ],
    },
    //4
    {
      label: string.PROPERTY_RIGHTS_LISENSE,

      children: [
        {
          label:
            string.LICENSE_RIGHTS_TEXT_2 +
            " (<b>&quot;" +
            string.LICENSE_RIGHTS +
            "&quot;</b>). " +
            string.LICENSE_RIGHTS_TEXT_1 +
            " (<b>&quot;" +
            string.LICENSE_FEE +
            "&quot;</b>).",
        },
        {
          label: string.PROPERTY_RIGHTS_LISENSE_PT_P2,
        },
        {
          label:
            "<u>" +
            string.PROPERTY_RIGHTS_LISENSE_PT_2u +
            ".</u> " +
            string.PROPERTY_RIGHTS_LISENSE_PT_P3,
        },
      ],
    },
    //5
    {
      label: string.PRICING_PAYMENT,

      children: [
        {
          label:
            "<u>" + string.LICENSE_FEE + ":</u> " + string.PRICING_PAYMENT_PT1,
        },
        {
          label:
            "<u>" +
            string.SELLING_PRICE +
            ":</u> " +
            string.PRICING_PAYMENT_PT2 +
            " (<b>&quot;" +
            string.SELLING_PRICE +
            "&quot;</b>). " +
            string.PRICING_PAYMENT_PT3,
        },
        {
          label: string.PRICING_PAYMENT_PT4,
        },
        {
          label: string.PRICING_PAYMENT_PT5,
        },
        {
          label: string.PRICING_PAYMENT_PT6,
        },
        {
          label: string.PRICING_PAYMENT_PT7,
        },
        {
          label: string.PRICING_PAYMENT_PT8,
        },
        {
          label:
            "<u>" +
            string.PAYING_SERVICES_PT_9u +
            ".</u> " +
            string.PRICING_PAYMENT_PT9 +
            " <b>&quot;" +
            string.PAYING_SERVICES_PT_9u +
            "&quot;</b>). " +
            string.PRICING_PAYMENT_PT10 +
            " <br><br>" +
            string.PRICING_PAYMENT_PT11,
        },
        {
          label: string.PRICING_PAYMENT_PT12,
        },
      ],
    },
    //6
    {
      label: string.CONFIDENTIALITY_AND_DATA,

      children: [
        {
          label: string.CONFIDENTIALITY_AND_DATA_PT_P1,
        },
      ],
    },
    //7
    {
      label: string.YOUR_UNDERTAKINGS,
      children: [
        {
          label: string.YOUR_UNDERTAKINGS_PT_1,
          children: [
            {
              label: string.YOUR_UNDERTAKINGS_PT_P1,
            },
            {
              label: string.YOUR_UNDERTAKINGS_PT_1b,
            },
            {
              label: string.YOUR_UNDERTAKINGS_PT_1c,
            },
            {
              label: string.YOUR_UNDERTAKINGS_PT_1d,
            },
            {
              label: string.YOUR_UNDERTAKINGS_PT_1e,
            },
            {
              label: string.YOUR_UNDERTAKINGS_PT_1f,
            },
            {
              label: string.YOUR_UNDERTAKINGS_PT_1g,
              children: [
                {
                  label: string.YOUR_UNDERTAKINGS_PT_1g1,
                },
                {
                  label: string.YOUR_UNDERTAKINGS_PT_1g2,
                },
              ],
            },
          ],
        },
        {
          label: string.YOUR_UNDERTAKINGS_PT_P2,
        },
      ],
    },
    //8
    {
      label: string.INDEMNITY,
      subHeading: true,
      children: [
        {
          label: string.INDEMNITY_PT_P1,
        },
      ],
    },
    //9
    {
      label: string.LIMITATION_AND_LIABILITY,
      subHeading: true,
      children: [
        {
          label:
            string.LIMITATION_AND_LIABILITY_PT_P1 +
            "<br><br>" +
            string.LIMITATION_AND_LIABILITY_PT_P2,
        },
      ],
    },
    //10
    {
      label: string.TERMS_AND_TERMINATION,
      children: [
        {
          label: string.TERMS_AND_TERMINATION_PT_P1,
        },
        {
          label: string.TERMS_AND_TERMINATION_PT_P2,
        },
        {
          label: string.TERMS_AND_TERMINATION_PT_4,
          children: [
            {
              label: string.TERMS_AND_TERMINATION_PT_P3,
              children: [
                {
                  label: string.TERMS_AND_TERMINATION_PT_P4,
                },
                {
                  label: string.TERMS_AND_TERMINATION_PT_P5,
                },
                {
                  label: string.TERMS_AND_TERMINATION_PT_P6,
                },
                {
                  label: string.TERMS_AND_TERMINATION_PT_4d + "<br><br>",
                },
              ],
            },
          ],
        },
      ],
    },
    //11
    {
      label: string.FORCE_MAJEURE,
      subHeading: true,
      children: [
        {
          label: string.FORCE_MAJEURE_PT_1,
        },
      ],
    },
    //12
    {
      label: string.VARIATION,
      subHeading: true,
      children: [
        {
          label: string.VARIATION_PT_2,
        },
      ],
    },
    //13
    {
      label: string.WAIVER,
      subHeading: true,
      children: [
        {
          label: string.WAIVER_PT_1,
        },
      ],
    },
    //14
    {
      label: string.RIGHTS_AND_REMEDIES,
      subHeading: true,
      children: [
        {
          label: string.RIGHTS_AND_REMEDIES_PT_1,
        },
      ],
    },
    //15
    {
      label: string.SEVERANCE,
      subHeading: true,
      children: [
        {
          label: string.SEVERANCE_PT_P3,
        },
      ],
    },
    //16
    {
      label: string.ENTIRE_AGREEMENT,

      children: [
        {
          label: string.ENTIRE_AGREEMENT_PT_1,
        },
        {
          label: string.ENTIRE_AGREEMENT_PT_2,
        },
        {
          label: string.ENTIRE_AGREEMENT_PT_3,
        },
        {
          label: string.ENTIRE_AGREEMENT_PT_4,
        },
      ],
    },
    //17
    {
      label: string.ASSIGNMENT,

      children: [
        {
          label: string.ASSIGNMENT_PT_3,
        },
        {
          label: string.ASSIGNMENT_PT_2,
        },
      ],
    },
    //18
    {
      label: string.NO_PARTNERSHIP_AGENCY,
      subHeading: true,
      children: [
        {
          label: string.NO_PARTNERSHIP_AGENCY_PT_1,
        },
      ],
    },
    //19
    {
      label: string.THIRD_PARTY_RIGHTS,
      subHeading: true,
      children: [
        {
          label: string.THIRD_PARTY_RIGHTS_PT_1,
        },
      ],
    },
    //20
    {
      label: string.NOTICES,

      children: [
        {
          label: string.NOTICES_PT_1,
          children: [
            {
              label: string.NOTICES_PT_1a,
            },
            {
              label: string.NOTICES_PT_1b,
            },
          ],
        },
        {
          label: string.NOTICES_PT_2,
        },
      ],
    },
    //21
    {
      label: string.GOVERNING_LAW,
      subHeading: true,
      children: [
        {
          label: string.GOVERNING_LAW_PT_1,
        },
      ],
    },
    //22
    {
      label: string.JURISDICTION,
      subHeading: true,
      children: [
        {
          label: string.JURISDICTION_PT_1,
        },
      ],
    },
  ];
  return (
    <div className="photographer_license_container">
      <div className="photographer_license_content">
        <div className="photographer_license_heading">
          {string.HP_LIMITED_EDITION_PRINT_SERVICES} –{" "}
          {string.PHOTOGRAPHERS_LICENSE_PAYMENT_TERMS}
        </div>
        <div className="photographer_license_content_1">
          {string.PHOTOGRAPHERS_LICENSE_PAYMENT_TERMS_PT1}{" "}
          <a
            href={footerLinksData["photographer-subscription-terms-of-service"]}
            target="_blank"
          >
            {string.SUBSCRIBER_TERMS_OF_SERVICE}
          </a>{" "}
          {string.AND}{" "}
          <a href={footerLinksData["terms-and-conditions"]} target="_blank">
            {string.LINK_TO_SUBSCRIBER}
          </a>{" "}
          ({string.COLLECTIVELY}, {string.THE} <b>"{string.AGREEMENT}"</b>){" "}
          {string.SUBSCRIBER_TERMS_CONTENT_1a} (<b>"{string.HP}"</b> {string.OR}
          <b> "{string.WE}"</b> {string.OR} <b>"{string.US}"</b> {string.OR}{" "}
          <b>“{string.LICENSEE}”</b> {string.OR} <b>“{string.OUR}”</b>){" "}
          {string.SUBSCRIBER_TERMS_CONTENT_1f} (<b>"{string.YOU}" </b>
          {string.OR}
          <b> "{string.YOUR}"</b> {string.OR} <b>“{string.LICENSOR}”</b>){" "}
          {string.SUBSCRIBER_TERMS_CONTENT_1e} <b>“{string.PARTY}”</b>{" "}
          {string.SUBSCRIBER_TERMS_CONTENT_1d} <b>“{string.PARTIES}”</b>.
        </div>
        <div className="photographer_license_content_2">
          {string.PHOTOGRAPHERS_LICENSE_CONTENT_2}
        </div>

        <div className="photographer_license_content_3">
          {string.PHOTOGRAPHERS_LICENSE_CONTENT_3}
        </div>

        <div className="photographer_license_content_4">
          {string.SUBSCRIBER_TERMS_CONTENT_4a}{" "}
          <a
            href={footerLinksData["photographer-subscription-terms-of-service"]}
            target="_blank"
          >
            {string.SUBSCRIBER_TERMS_OF_SERVICE}
          </a>{" "}
          {string.SUBSCRIBER_TERMS_CONTENT_4d} ({string.THE}{" "}
          <b>“{string.SUBSCRIBER_TERMS_CONTENT_4c}”</b>).
        </div>
        <div className="photographer_license_content_5">
          {string.PHOTOGRAPHERS_LICENSE_CONTENT_5}
        </div>
        <div className="photographer_license_content_6">
          {string.PHOTOGRAPHERS_LICENSE_CONTENT_6}
        </div>
        <div className="photographer_license_content_7 photographer_license_list">
          <NestedList items={data} />
        </div>
      </div>
    </div>
  );
};

const NestedList = ({ items, depth = 0 }) => {
  return (
    <ol className={`list-depth-${depth}`}>
      {items.map((item, index) => {
        return (
          <li key={index}>
            {item.subHeading ? (
              <>
                <span className="photographer_license_interpretation_list">
                  <span dangerouslySetInnerHTML={{ __html: item.label }} />{" "}
                </span>
                {item.children.map((ele, eleIndex) => (
                  <div
                    key={eleIndex}
                    className="photographer_license_list2"
                    dangerouslySetInnerHTML={{ __html: ele.label }}
                  ></div>
                ))}
              </>
            ) : (
              <>
                <span className={`item-label-depth-${depth}`}>
                  <span dangerouslySetInnerHTML={{ __html: item.label }} />
                </span>
                {item.children && (
                  <NestedList items={item.children} depth={depth + 1} />
                )}
              </>
            )}
          </li>
        );
      })}
    </ol>
  );
};

export default PhotographerLicense;
