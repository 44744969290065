import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCanShowBackAlertModal,
  setDraftStatus,
} from "../../Store/Project/ProjectSlice";
import {
  DRAFT_PROJECT_TYPE,
  HANDLE_DRAFT_GALLERY,
} from "../../Utils/Constants";
import moreIcon from "../../Utils/Icons/more_vert.svg";
import deleteIcon from "../../Utils/Icons/delete_black.svg";
import pauseIcon from "../../Utils/Icons/pause.svg";
import startIcon from "../../Utils/Icons/start.svg";
import editIcon from "../../Utils/Icons/edit_black.svg";
import resolution from "../../Utils/Icons/resolution_bulb.svg";
import { string } from "../../Localization";
import InfiniteScrollLoader from "../../Components/Loaders/InfiniteScrollLoader";
import { setCanShowResolutionModal } from "../../Store/Project/ProjectSlice";
import SuperResolutionModal from "../../Components/Modal/SuperResolutionModal/SuperResolutionModal";

const Card = (props) => {
  const [showOption, setShowOption] = useState(false);

  const {
    data,
    index,
    handleDraft,
    loadPhotoPrintDetails,
    setShowStopSellingModal,
    setShowStartSellingModal,
    setSelectedToken,
    setPublicUrl,
  } = props;

  const { project_token, public_view_url, super_resoultion_initiated } = data;

  const dispatch = useDispatch();

  const handleImageClick = (data) => {
    if (
      data.status.toLowerCase() === DRAFT_PROJECT_TYPE &&
      !super_resoultion_initiated
    ) {
      dispatch(setDraftStatus(HANDLE_DRAFT_GALLERY));
      handleDraft(data.project_token);
    } else if (data.status === "In progress") {
      dispatch(setCanShowResolutionModal(true));
    } else {
      loadPhotoPrintDetails(data);
    }
  };

  return (
    <>
      <div key={index}>
        <div
          className={
            showOption
              ? "gallery thumbnail"
              : "gallery thumbnail hovering" +
                (super_resoultion_initiated ? " disabled_image" : "")
          }
          onMouseLeave={() => {
            setShowOption(false);
          }}
        >
          <div className="image-container">
            <div className="top-content">
              <div className="gallery_heading">
                <div className="status">{data.status}</div>
                {data.status === "In progress" && (
                  <InfiniteScrollLoader
                    spinner_size_style={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "5px",
                      background: "white",
                    }}
                    loader_style={{
                      width: "15px",
                      height: "15px",
                      border: "2px solid #dbdbdb;",
                      borderTop: "2px solid #176267",
                      animation: "spin 1s linear infinite",
                    }}
                  />
                )}
              </div>

              {data.status !== "Sold out" && (
                <div
                  className="right"
                  onClick={() => {
                    setShowOption(true);
                    setSelectedToken(project_token);
                    setPublicUrl(public_view_url);
                  }}
                >
                  <img
                    src={moreIcon}
                    alt="show more"
                    className="more"
                    style={{
                      width: "3.33px",
                      height: "13.33px",
                      display: "flex",
                      alignContent: "center",
                      margin: "4px",
                    }}
                  />
                </div>
              )}
              <img
                className={"image-thumbnail-grid "}
                src={data.thumbnail}
                alt=""
                disabled={super_resoultion_initiated}
                onClick={() => {
                  handleImageClick(data);
                }}
              />

              {showOption && (
                <div className="dropdown">
                  {data.status === "Draft" ? (
                    <button
                      className="dropbtn"
                      onClick={() => {
                        handleImageClick(data);
                      }}
                      disabled={super_resoultion_initiated}
                    >
                      <img src={editIcon} alt="pause" />
                      {string.EDIT}
                    </button>
                  ) : (
                    <>
                      {data.status !== "Sold out" ? (
                        data.public_access ? (
                          <button
                            className="dropbtn"
                            onClick={() => {
                              setShowStopSellingModal(true);
                              setShowOption(false);
                            }}
                          >
                            <img src={pauseIcon} alt="pause" />
                            {string.STOP_SELLING}
                          </button>
                        ) : (
                          <button
                            className="dropbtn"
                            onClick={() => {
                              setShowStartSellingModal(true);
                              setShowOption(false);
                            }}
                          >
                            <img src={startIcon} alt="pause" />
                            {string.START_SELLING}
                          </button>
                        )
                      ) : null}
                    </>
                  )}
                  <button
                    className="dropbtn"
                    onClick={() => {
                      dispatch(setCanShowBackAlertModal(true));
                    }}
                  >
                    <img src={deleteIcon} alt="delete" />
                    {string.DELETE}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
