import React from "react";
import "../Admin.scss";
import { Outlet, useNavigate } from "react-router-dom";
import LogoHP from "../../../Components/LogoHP/LogoHP";
import ProfileButton from "../../ProfileButton/ProfileButton";

const Layout = () => {
  const navigate = useNavigate();

  return (
    <div className="admin_container_wrapper">
      <div className="admin_container">
        <div
          className="admin_logo"
          onClick={() => {
            navigate("/");
          }}
          style={{ cursor: "pointer" }}
        >
          <LogoHP />
        </div>
        <div className="admin_profile_button">
          <ProfileButton />
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default Layout;
