import React, { useState } from "react";
import Select from "react-select";
import csc from "country-state-city";
import { string } from "../../../Localization";
import { SUPPORTED_COUNTRY } from "../../../Utils/Constants";
import {
  getCountryByName,
  handleAddressEdit,
} from "../../../Utils/OrderHelper";
import { useParams } from "react-router-dom";
import Asterick from "../../Asterick/Asterick";
import { sendGACollectorUpdatedDeliveryAddressDetails } from "../../../Utils/GAHelper";

const EditAddressModal = ({ customerAddress }) => {
  const [address, setAddress] = useState({
    country: getCountryByName(customerAddress?.country),
    postCode: customerAddress?.zip_code,
    addressLine1: customerAddress?.address_line1,
    addressLine2: customerAddress?.address_line2,
    city: customerAddress?.city,
    county: customerAddress?.region,
  });

  const [error, setError] = useState({});

  const { order_token } = useParams();

  const validateCountry = (value) => {
    setAddress({
      ...address,
      country: {
        label: value.name,
        value: value.id,
        name: value.name,
        phonecode: value.phonecode,
        countryCode: value.sortname,
      },
    });
    if (value["sortname"] === SUPPORTED_COUNTRY) {
      setError({ Country: "" });
    } else {
      setError({ Country: string.SELECTED_COUNTRY_IS_NOT_SUPPORTED });
    }
  };

  const countries = csc.getAllCountries();

  const formattedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country,
  }));

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "transparent",
      borderRadius: "6px",
      borderColor: "#BDBDBD",
      color: "black",
      "&:hover": {
        borderColor: "#737373",
        boxShadow: "none",
      },

      boxShadow: "none",
      "&:focus": {
        borderColor: "#737373",
        boxShadow: "none",
      },
      width: "100%",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: "6px",
      border: "1px solid #737373",
      boxShadow: "none",
      scrollbarWidth: "none",
      maxHeight: "300px",
      overflow: "scroll",
      marginTop: "-1px",

      "&::-webkit-scrollbar": {
        display: "none",
      },
    }),
    menuList: (base) => ({
      ...base,
      color: "black",
      marginTop: "0px",
      padding: "0px",
      margin: "0px",
      border: "none",
      boxShadow: "none",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        width: "2px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#FFFFFF",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#757575",
      },
    }),
    placeholder: (base) => ({
      ...base,
      color: "#000000",
    }),
    singleValue: (base) => ({
      ...base,
      color: "#000000",
    }),
    input: (base) => ({
      ...base,
      color: "#000000",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    option: (base, state) => ({
      ...base,
      border: "none",
      boxShadow: "none",
      backgroundColor: state.isFocused ? "#757575" : "#F8F8F8",
      color: "#000000",
      "&:hover": {
        backgroundColor: "#7575752b",
        color: "#000000",
      },
      "&:active": {
        backgroundColor: "#7575752b",
        color: "#000000",
      },
    }),
  };

  const switchAddressFormat = () => {
    switch (address.country?.countryCode) {
      case SUPPORTED_COUNTRY:
        return (
          <>
            {" "}
            <div className="edit_text_box">
              <label htmlFor="">
                {string.POST_CODE1}
                <Asterick />
              </label>
              <input
                type="text"
                className="input_title"
                placeholder="00000"
                value={address.postCode}
                onChange={(e) => {
                  setAddress({
                    ...address,
                    postCode: e.currentTarget.value,
                  });
                }}
              />
              <div className="error-div">{error[`${string.POST_CODE}`]}</div>
            </div>
            <div className="edit_text_box">
              <label htmlFor="">
                {string.ADDRESS_LINE_1}
                <Asterick />
              </label>
              <input
                type="text"
                className="input_title"
                placeholder={string.TYPE_YOUR_STREET}
                value={address.addressLine1}
                onChange={(e) => {
                  setAddress({
                    ...address,
                    addressLine1: e.currentTarget.value,
                  });
                }}
              />
              <div className="error-div">
                {error[`${string.ADDRESS_LINE_1}`]}
              </div>
            </div>
            <div className="edit_text_box">
              <label htmlFor="">{string.ADDRESS_LINE_2_OPTIONAL}</label>
              <input
                type="text"
                className="input_title"
                placeholder={string.TYPE_YOUR_STREET}
                value={address.addressLine2}
                onChange={(e) => {
                  setAddress({
                    ...address,
                    addressLine2: e.currentTarget.value,
                  });
                }}
              />
            </div>
            <div className="edit_text_box">
              <label htmlFor="">
                {string.TOWN_CITY}
                <Asterick />
              </label>
              <input
                type="text"
                className="input_title"
                placeholder={string.TYPE_YOUR_CITY}
                value={address.city}
                onChange={(e) => {
                  setAddress({
                    ...address,
                    city: e.currentTarget.value,
                  });
                }}
              />
              <div className="error-div">{error[`${string.TOWN_CITY}`]}</div>
            </div>
            <div className="edit_text_box">
              <label htmlFor="">{string.COUNTY}</label>
              <input
                type="text"
                className="input_title"
                placeholder={string.TYPE_YOUR_CITY}
                value={address.county}
                onChange={(e) => {
                  setAddress({
                    ...address,
                    county: e.currentTarget.value,
                  });
                }}
              />
            </div>
          </>
        );
      default:
      // code block
    }
  };

  return (
    <>
      <div className="edit_text_box">
        <div>
          <label htmlFor="">
            {string.COUNTRY}
            <Asterick />
          </label>
        </div>

        <div className="country-dropdown">
          <Select
            id="country"
            name="country"
            placeholder={string.SELECT__YOUR_COUNTRY_REGION}
            options={formattedCountries}
            value={address.country}
            styles={customStyles}
            onChange={(value) => validateCountry(value)}
          />
        </div>
        <div className="error-div">{error[`${string.COUNTRY}`]}</div>
      </div>
      {switchAddressFormat()}
      <div className="update_btn_container">
        {" "}
        <button
          className={
            address?.country?.countryCode === SUPPORTED_COUNTRY
              ? "update_button"
              : "update_button disbale"
          }
          onClick={() => {
            sendGACollectorUpdatedDeliveryAddressDetails();
            handleAddressEdit(
              address?.country?.countryCode,
              address,
              setError,
              order_token
            );
          }}
        >
          {string.UPDATE}
        </button>
      </div>
    </>
  );
};

export default EditAddressModal;
