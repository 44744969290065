import React, { createContext, useReducer } from "react";
import AppReducer from "./AppReducer";

const initialState = {
  imageCanvas: null,
  croppedImageCanvas: null,
  signature: {
    mainCanvas: null,
    signatureCanvas: null,
  },
  finalCanvasURL: {
    frameCanvasURL: null,
    croppedImageCanvasURL: null,
  },
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  function setImageCanvas(value) {
    dispatch({
      type: "SET_IMAGE_CANVAS",
      payload: value,
    });
  }

  function setCroppedImageCanvas(value) {
    dispatch({
      type: "SET_CROPPED_IMAGE_CANVAS",
      payload: value,
    });
  }

  function setMainCanvas(value) {
    dispatch({
      type: "SET_MAIN_CANVAS",
      payload: value,
    });
  }
  function setSignatureCanvas(value) {
    dispatch({
      type: "SET_SIGNATURE_CANVAS",
      payload: value,
    });
  }

  function setFinalCanvasURL(value) {
    dispatch({
      type: "SET_FINAL_CANVAS_URL",
      payload: value,
    });
  }

  const values = {
    finalCanvasURL: state.finalCanvasURL,
    imageCanvas: state.imageCanvas,
    setImageCanvas,
    croppedImageCanvas: state.croppedImageCanvas,
    setCroppedImageCanvas,
    signature: state.signature,
    setMainCanvas,
    setSignatureCanvas,
    setFinalCanvasURL,
  };

  return (
    <GlobalContext.Provider value={values}>{children}</GlobalContext.Provider>
  );
};
