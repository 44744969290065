import React, { useEffect, useState } from "react";
import ProjectLoader from "../../../Components/Loaders/ProjectLoader/ProjectLoader";
import { string } from "../../../Localization";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setActivePageIndex,
  setOrderToken,
  setUserColumnKey,
  setUserTypeKey,
} from "../../../Store/Project/AdminSlice";
import {
  ASCENDING_ORDERS,
  DESCENDING_ORDERS,
  EMAIL_SORT,
  FIRST_NAME_SORT,
  LAST_NAME_SORT,
  ORDER_DETAILS_PAGE,
  PHONE_NUMBER,
} from "../../../Utils/Constants";
import { Tooltip } from "@mui/material";

const AdminOrderTable = (props) => {
  const { response, isOrderTable } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [createdAtSort, setCreatedAtSort] = useState(true);
  const [contactNumberSort, setContactNumberSort] = useState(true);
  const [data, setData] = useState([]);
  const [email, setemail] = useState(true);
  const [firstNameSort, setFistNameSort] = useState(true);
  const [lastNameSort, setLastNameSort] = useState(true);

  useEffect(() => {
    setData(response);
  }, [response]);

  const viewButton = (imageLink, label) => (
    <div className="d-flex justify-content-center">
      {imageLink ? (
        <a
          className="image-tag-2"
          href={imageLink}
          alt="view"
          target="_blank"
          rel="noopener noreferrer"
        >
          {label ? label : string.VIEW}
        </a>
      ) : (
        <>-</>
      )}
    </div>
  );

  return (
    <>
      <div className="admin_tables_wrapper">
        <ProjectLoader />
        <table className="table table-borderless table_block">
          <thead className="table_head">
            <tr>
              <th>
                #
                {createdAtSort ? (
                  <div
                    className="admin_navBar_sort fa fa-angle-down"
                    onClick={() => {
                      dispatch(setUserColumnKey(""));
                      dispatch(setUserTypeKey(ASCENDING_ORDERS));
                      setCreatedAtSort(false);
                    }}
                  ></div>
                ) : (
                  <div
                    className="admin_navBar_sort fa fa-angle-up"
                    onClick={() => {
                      dispatch(setUserColumnKey(""));
                      dispatch(setUserTypeKey(DESCENDING_ORDERS));
                      setCreatedAtSort(true);
                    }}
                  ></div>
                )}
              </th>
              <th>{string.CREATED_AT}</th>
              <th>{string.EDITION}</th>
              <th>{string.THUMBNAIL}</th>
              <th>{string.IS_ARTIST_PROOF}</th>
              <th>
                {string.FIRST_NAME}{" "}
                {firstNameSort ? (
                  <div
                    className="admin_navBar_sort fa fa-angle-down "
                    onClick={() => {
                      setFistNameSort(false);
                      dispatch(setUserColumnKey(FIRST_NAME_SORT));
                      dispatch(setUserTypeKey(ASCENDING_ORDERS));
                    }}
                  ></div>
                ) : (
                  <div
                    className="admin_navBar_sort fa fa-angle-up"
                    onClick={() => {
                      setFistNameSort(true);
                      dispatch(setUserColumnKey(FIRST_NAME_SORT));
                      dispatch(setUserTypeKey(DESCENDING_ORDERS));
                    }}
                  ></div>
                )}
              </th>
              <th>
                {string.LAST_NAME}
                {lastNameSort ? (
                  <div
                    className="admin_navBar_sort fa fa-angle-down "
                    onClick={() => {
                      setLastNameSort(false);
                      dispatch(setUserColumnKey(LAST_NAME_SORT));
                      dispatch(setUserTypeKey(ASCENDING_ORDERS));
                    }}
                  ></div>
                ) : (
                  <div
                    className="admin_navBar_sort fa fa-angle-up"
                    onClick={() => {
                      setLastNameSort(true);
                      dispatch(setUserColumnKey(LAST_NAME_SORT));
                      dispatch(setUserTypeKey(DESCENDING_ORDERS));
                    }}
                  ></div>
                )}
              </th>
              <th>
                {string.EMAIL}
                {email ? (
                  <div
                    className="admin_navBar_sort fa fa-angle-down"
                    onClick={() => {
                      dispatch(setUserColumnKey(EMAIL_SORT));
                      dispatch(setUserTypeKey(ASCENDING_ORDERS));
                      setemail(false);
                    }}
                  ></div>
                ) : (
                  <div
                    className="admin_navBar_sort fa fa-angle-up"
                    onClick={() => {
                      dispatch(setUserColumnKey(EMAIL_SORT));
                      dispatch(setUserTypeKey(DESCENDING_ORDERS));
                      setemail(true);
                    }}
                  ></div>
                )}
              </th>
              <th>{string.ADDRESS}</th>
              <th>
                {string.PHONE_NUMBER}
                {contactNumberSort ? (
                  <div
                    className="admin_navBar_sort fa fa-angle-down"
                    onClick={() => {
                      dispatch(setUserColumnKey(PHONE_NUMBER));
                      dispatch(setUserTypeKey(ASCENDING_ORDERS));
                      setContactNumberSort(false);
                    }}
                  ></div>
                ) : (
                  <div
                    className="admin_navBar_sort fa fa-angle-up"
                    onClick={() => {
                      dispatch(setUserColumnKey(PHONE_NUMBER));
                      dispatch(setUserTypeKey(DESCENDING_ORDERS));
                      setContactNumberSort(true);
                    }}
                  ></div>
                )}
              </th>
              <th>{string.UPLOADED_IMAGE}</th>
              <th>{string.PREVIEW_IMAGE}</th>
              <th>{string.FINAL_IMAGE}</th>
              <th>{string.PRINTABLE_IMAGE}</th>
              <th>{string.PRINTED_CERTIFICATE}</th>
              <th>{string.PERSONAL_MESSAGE}</th>
              <th>{string.PACKING_SLIP}</th>
              <th>{string.PRINTABLE_ASSET}</th>
              <th>{string.TRACKING_CODE}</th>
              <th>{string.TRACKING_URL}</th>
              <th>{string.VIEW_COUNT}</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr className="admin_table_row" key={index}>
                  {isOrderTable == true ? (
                    <td
                      className="order_number"
                      onClick={() => {
                        dispatch(setOrderToken(item.order_token));
                        navigate(`order_details/${item.order_token}`);
                        dispatch(setActivePageIndex(ORDER_DETAILS_PAGE));
                      }}
                    >
                      <Tooltip title={string.CLICK_TO_VIEW_DETAILS} arrow>
                        <span>{item.id}</span>
                      </Tooltip>
                    </td>
                  ) : (
                    <>
                      <td
                        className="order_number"
                        onClick={() => {
                          dispatch(setOrderToken(item.order_token));
                          navigate(`../order_details/${item.order_token}`);
                        }}
                      >
                        <Tooltip title={string.CLICK_TO_VIEW_DETAILS} arrow>
                          <span>{item.id}</span>
                        </Tooltip>
                      </td>
                    </>
                  )}
                  <td>{item.created_at}</td>

                  <td>{item.edition}</td>
                  <td>
                    <img
                      src={item.preview_thubmnail_url}
                      className="thumbnail-img"
                    />
                  </td>
                  <td>{item.is_artist_proof ? "Yes" : "No"}</td>
                  <td>{item.first_name}</td>
                  <td>{item.last_name}</td>
                  <td className="text-start">{item.email}</td>
                  <td>
                    {item.address_line1}
                    {item.address_line2 ? `, ${item.address_line2}` : ""}
                  </td>
                  <td>{item.contact_number}</td>

                  <td>{viewButton(item.uploaded_image)}</td>
                  <td>{viewButton(item.preview_image)}</td>
                  <td>{viewButton(item.final_image)}</td>
                  <td>{viewButton(item.printable_image_pdf)}</td>
                  <td>{viewButton(item.blockchain_coa_card_pdf)}</td>
                  <td>{viewButton(item.personal_message)}</td>
                  <td>{viewButton(item.packing_slip)}</td>
                  <td>{viewButton(item.printable_assests, "Zip")}</td>
                  <td>
                    {item.shipping_tracking_number
                      ? item.shipping_tracking_number
                      : "-"}
                  </td>
                  <td>
                    {item.tracking_url ? (
                      <a
                        className="tracking-url"
                        href={item.tracking_url}
                        alt="tracking url"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.tracking_url}
                      </a>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>{item.view_count}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AdminOrderTable;
