import { useEffect, useState } from "react";
import "./PaymentSuccess.scss";
import {
  getOrderInfo,
  updateCollectorNotePreference,
} from "../../../Utils/ApiHelper";
import OrderSummaryInfo from "../OrderSummaryInfo/OrderSummaryInfo";
import OrderSummaryFooter from "../OrderSummaryFooter/OrderSummaryFooter";
import { useParams } from "react-router-dom";
import { sendGALoadedPaymentSuccessPage } from "../../../Utils/GAHelper";
import Footer from "../../LandingPage/Footer/Footer";
import { string } from "../../../Localization";
import OrderLogout from "../../../Components/Modal/OrderLogout/OrderLogout";

const PaymentSuccess = () => {
  const [orderDetails, setOrderDetails] = useState();
  const [openModal, setOpenModal] = useState(false);

  const { order_token } = useParams();

  const isInsideIframe = window.self !== window.top;

  useEffect(() => {
    if (isInsideIframe) {
      window.top.location.href = window.location.href;
    } else {
      getOrderInfo(order_token).then((res) => {
        const response = res.data.data;
        const userID = res?.data.data?.user?.id;
        const projectToken = res?.data?.data?.google_analytics?.project_token;
        const isCouponFlow = response?.order?.valid_coupon;
        sendGALoadedPaymentSuccessPage(
          userID,
          order_token,
          projectToken,
          isCouponFlow
        );
        if (response?.order?.show_collector_note_modal) {
          setOpenModal(true);
        }
        setOrderDetails(response);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isInsideIframe) return null;

  const updatePersonalNotePreference = (appreciate) => {
    const payload = {
      collectorNotePreference: appreciate,
    };
    updateCollectorNotePreference(order_token, payload)
      .then(() => {
        setOpenModal(false);
      })
      .catch(() => {
        setOpenModal(false);
      });
  };

  return (
    orderDetails && (
      <div className="payment-success-container">
        <div className="payment-success-block">
          <div className="d-flex preview-block">
            <div className="preview-image-block">
              <img
                className="preview-image"
                src={orderDetails?.project?.preview}
                alt="preview"
              ></img>
            </div>

            <div className="preview-text-block">
              <div className="preview-title">
                {string.THANK_YOU_YOUR_ORDER}&nbsp;
                {orderDetails?.order?.id}&nbsp;
                {string.WAS_SUBMITTED}
              </div>
              <div className="preview-description">
                {string.YOUR_ORDER_WAS_SUCCESSFULLY_SUBMITTED}
              </div>
            </div>
          </div>
          <div className="seperator" />
          <OrderSummaryInfo orderData={orderDetails} />

          <OrderSummaryFooter orderData={orderDetails} />
          <div className="payment-success-footer">
            <Footer isLandingPage={false} />
          </div>
        </div>
        <OrderLogout
          openModal={openModal}
          handleLogoutClose={() => setOpenModal(false)}
          updatePersonalNotePreference={updatePersonalNotePreference}
        />
      </div>
    )
  );
};

export default PaymentSuccess;
