import React, { useEffect, useState } from "react";
import { getFeedData, update_feed_likes } from "../../../Utils/ApiHelper";
import Feed from "../../../Components/Feed/Feed";
import favourite from "../../../Utils/Icons/favorite.svg";
import favourite2 from "../../../Utils/Icons/favorite2.svg";
import "./InternalFeed.scss";
import { sendGALoadedInternalFeed } from "../../../Utils/GAHelper";

const InternalFeed = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    sendGALoadedInternalFeed();
  }, []);

  const like = (i_data) => {
    setData(
      [...data].map((object) => {
        if (i_data.id === object.id) {
          const count = object.liked_by_user ? -1 : +1;
          return {
            ...object,
            liked_by_user: !object.liked_by_user,
            no_of_likes: object.no_of_likes + count,
          };
        } else return object;
      })
    );
    update_feed_likes(i_data?.project_token);
  };

  const feedCardDetails = (i_data) => (
    <>
      <div className="limited-edition">{i_data.edition_str}</div>
      <div className="">
        <div className="d-flex justify-content-between">
          <span className="font-weight-700">{i_data.artist_name}</span>
          <div className="">
            <img
              src={i_data.liked_by_user ? favourite2 : favourite}
              className="likes"
            />
            <span>{i_data.no_of_likes}</span>
          </div>
        </div>
        <div className="project-title">{i_data.title}</div>
      </div>

      <div className="favourite-wrapper cursor-pointer">
        <img
          src={i_data.liked_by_user ? favourite2 : favourite}
          alt=""
          style={{ opacity: i_data.liked_by_user ? 1 : "0.7" }}
          onClick={(e) => like(i_data)}
        />
      </div>
    </>
  );

  return (
    <>
      <Feed
        data={data}
        setData={setData}
        feedCardDetails={feedCardDetails}
        getFeedDataFromAPI={getFeedData}
      />
    </>
  );
};

export default InternalFeed;
