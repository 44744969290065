import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orderDetails: null,
  orderToken: null,
  updatedCustomerInfo: false, //this state is used to flag customer info updation, value is toggled on every update
  acceptTerms: false,
  acceptSurvey: false,
  isCouponUsed: false,
  couponCode: null,
  validCoupon: false,
};

export const orderSlice = createSlice({
  name: "orderDetails",
  initialState: initialState,
  reducers: {
    setOrderDetails(state, action) {
      state.orderDetails = action.payload;
    },
    setOrderToken(state, action) {
      state.orderToken = action.payload;
    },
    setUpdatedCustomerInfo(state, action) {
      state.updatedCustomerInfo = action.payload;
    },
    setAcceptTerms(state, action) {
      state.acceptTerms = action.payload;
    },
    setAcceptSurvey(state, action) {
      state.acceptSurvey = action.payload;
    },
    setCouponUsed(state, action) {
      state.isCouponUsed = action.payload;
    },
    setCouponCode(state, action) {
      state.couponCode = action.payload;
    },
    setValidCoupon(state, action) {
      state.validCoupon = action.payload;
    },
  },
});

export const {
  setOrderDetails,
  setOrderToken,
  setUpdatedCustomerInfo,
  setAcceptTerms,
  setAcceptSurvey,
  setCouponUsed,
  setCouponCode,
  setValidCoupon,
} = orderSlice.actions;
export default orderSlice.reducer;
