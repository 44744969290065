import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GlobalContext } from "../../../Context/GlobalState";
import {
  applyCrop,
  didChangeBorderSelection,
  resetCanvas,
  scaleProjectToFitIntoPreview,
} from "../../../Utils/ProjectRender";
import { DropTarget } from "react-drag-drop-container";
import "./CroppedCanvas.scss";
import { showSignatureinPreview } from "../../../Utils/signatureHelper";
import {
  NORMAL_VIEW,
  NO_OF_COPIES,
  PROJECT_PREVIEW_MODE_CERTIFICATE,
  PROJECT_PREVIEW_MODE_CROPPED,
  PROJECT_PREVIEW_MODE_PERSONALIZATION,
  SIGNATURE,
  SOFA_VIEW,
  STEP_ONE_PHOTO_DETAILS,
  STEP_TWO_PERSONALISATION,
} from "../../../Utils/Constants";
import CardCanvas from "../CardCanvas/CardCanvas";
import {
  addLimitedEditionToCanvas,
  addSignatureToCanvas,
  getCanvasElement,
  getLimitedEditionCoords,
  getMagnifierVisibleCanvasCoords,
  getScrollableCropCanvasContainer,
  getSignatureCoords,
  removeLimitedEdition,
  removeSignature,
  setLimitedEditionCoordinates,
  setSignatureCoordinates,
  toggleElementState,
} from "../../../Utils/PersonalizationHelper";
import infoSvg from "../../../Utils/Icons/info.svg";
import {
  setMagnifierIsCompleted,
  setShouldReposition,
} from "../../../Store/Project/ProjectSlice";
import { showOrHideApiLoadingModal } from "../../../Store/Project/ModalSlice";
import { string } from "../../../Localization";

const CroppedCanvas = () => {
  const dispatch = useDispatch();
  const mounted = useRef();

  const { setCroppedImageCanvas, croppedImageCanvas } =
    useContext(GlobalContext);

  const {
    activePreviewMode,
    borderWidth,
    isMagnifierModeOn,
    shouldReposition,
    canvasViewMode,
    isCanvasLoading,
  } = useSelector((state) => state.project);

  const { activeStep } = useSelector((state) => state.page);
  const { signatureImage, canShowSignature } = useSelector(
    (state) => state.signature
  );
  const { canShowNoOfCopies, noOfCopies, isLimited } = useSelector(
    (state) => state.limitedEdition
  );

  const [canSlideLeft, setCanSlideLeft] = useState(false);
  const [canvasLoaded, setCanvasLoaded] = useState(false);

  useEffect(() => {
    applyCrop(null, (inCanvas) => {
      setCanvasLoaded(true);
      setCroppedImageCanvas(inCanvas);
      if (borderWidth > 0) {
        didChangeBorderSelection(inCanvas);
      }

      if (shouldReposition) {
        setLimitedEditionCoordinates();
        setSignatureCoordinates();
        dispatch(setShouldReposition(false));
      }
      if (canShowSignature) {
        checkAndAddSignature(inCanvas);
      }
      if (canShowNoOfCopies) {
        checkAndAddEdition(inCanvas);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // When size is re-modified and come to step2 signature or edition disappears. Hence
    if (activeStep === STEP_TWO_PERSONALISATION) {
      if (canShowSignature) {
        checkAndAddSignature();
      }
      if (canShowNoOfCopies) {
        checkAndAddEdition();
      }
    } else if (activeStep === STEP_ONE_PHOTO_DETAILS) {
      removeSignature(croppedImageCanvas);
      removeLimitedEdition(croppedImageCanvas);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  useEffect(() => {
    if (croppedImageCanvas) {
      scaleProjectToFitIntoPreview(
        croppedImageCanvas,
        PROJECT_PREVIEW_MODE_CROPPED
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasViewMode]);

  useEffect(() => {
    if (canShowSignature) {
      checkAndAddSignature();
    } else {
      removeSignature(croppedImageCanvas);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canShowSignature]);

  useEffect(() => {
    if (canShowNoOfCopies) {
      checkAndAddEdition();
    } else {
      removeLimitedEdition(croppedImageCanvas);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canShowNoOfCopies]);

  useEffect(() => {
    if (canShowNoOfCopies || !isLimited) {
      checkAndAddEdition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noOfCopies, isLimited]);

  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      mounted.current = true;
    } else {
      dispatch(showOrHideApiLoadingModal(true));
      // do componentDidUpdate logic
      if (croppedImageCanvas) {
        const backupCanvas = croppedImageCanvas;
        resetCanvas(croppedImageCanvas);
        setCroppedImageCanvas(null);

        setTimeout(() => {
          applyCrop(backupCanvas, (inCanvas) => {
            setCroppedImageCanvas(inCanvas);
            if (borderWidth > 0) {
              didChangeBorderSelection(inCanvas);
            }
            // Set this first. Bcz left and top calculations of signature canvas is based on this
            if (isMagnifierModeOn) {
              const zoomToWho = canShowNoOfCopies ? NO_OF_COPIES : SIGNATURE;
              resizeCanvas(true, zoomToWho);
            } else {
              const ele = getScrollableCropCanvasContainer();
              ele.style.width = "auto";
              ele.style.height = "auto";
            }

            if (canShowSignature) {
              checkAndAddSignature();
            }
            if (canShowNoOfCopies) {
              checkAndAddEdition();
            }
            dispatch(showOrHideApiLoadingModal(false));
            dispatch(setMagnifierIsCompleted(true));
          });
        }, 10);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMagnifierModeOn]);

  useEffect(() => {
    if (activePreviewMode === PROJECT_PREVIEW_MODE_CERTIFICATE) {
      if (canvasLoaded) {
        setCanSlideLeft(true);
      }
      toggleEdit(false);
    } else {
      if (canSlideLeft) {
        setCanSlideLeft(false);
      }
    }
    if (activePreviewMode === PROJECT_PREVIEW_MODE_PERSONALIZATION) {
      toggleEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePreviewMode, canvasLoaded]);

  useEffect(() => {
    dispatch(showOrHideApiLoadingModal(isCanvasLoading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCanvasLoading]);

  const resizeCanvas = (shouldScroll, zoomTo) => {
    const croppedCanvasContainer = getScrollableCropCanvasContainer();
    const magnifierCoords = getMagnifierVisibleCanvasCoords();
    if (magnifierCoords) {
      croppedCanvasContainer.style.width = `${magnifierCoords.width}px`;
      croppedCanvasContainer.style.height = `${magnifierCoords.height}px`;
      if (shouldScroll) {
        let positionX = croppedCanvasContainer.scrollWidth,
          positionY = croppedCanvasContainer.scrollHeight,
          itemWidth = 0,
          itemHeight = 0;
        if (zoomTo === SIGNATURE) {
          const { x, y, width, height } = getSignatureCoords();
          positionX = x;
          positionY = y;
          itemWidth = width;
          itemHeight = height;
        }
        if (zoomTo === NO_OF_COPIES) {
          const { x, y, width, height } = getLimitedEditionCoords();
          positionX = x;
          positionY = y;
          itemWidth = width;
          itemHeight = height;
        }

        croppedCanvasContainer.scrollTo({
          left: positionX + itemWidth / 2 - magnifierCoords.width / 2,
          top: positionY + itemHeight / 2 - magnifierCoords.height / 2,
          behavior: "smooth",
        });
      }
    }
  };

  const checkAndAddSignature = (inCanvas) => {
    const renderCanvas = inCanvas ? inCanvas : croppedImageCanvas;
    removeSignature(renderCanvas);
    if (signatureImage && renderCanvas) {
      addSignatureToCanvas(renderCanvas, signatureImage);
      if (isMagnifierModeOn) {
        resizeCanvas(true, SIGNATURE);
      }
    }
  };

  const checkAndAddEdition = (inCanvas) => {
    const renderCanvas = inCanvas ? inCanvas : croppedImageCanvas;
    if (canShowNoOfCopies && renderCanvas) {
      removeLimitedEdition(renderCanvas);
      addLimitedEditionToCanvas(renderCanvas);
      if (isMagnifierModeOn) {
        resizeCanvas(true, NO_OF_COPIES);
      }
    }
  };

  const toggleEdit = (enable) => {
    if (croppedImageCanvas) {
      const signatureElement = getCanvasElement(croppedImageCanvas, SIGNATURE);
      if (signatureElement) {
        toggleElementState(
          croppedImageCanvas,
          signatureElement,
          enable,
          SIGNATURE
        );
      }

      const editionElement = getCanvasElement(croppedImageCanvas, NO_OF_COPIES);
      if (editionElement) {
        toggleElementState(
          croppedImageCanvas,
          editionElement,
          enable,
          NO_OF_COPIES
        );
      }
    }
  };

  const handleResize = () => {
    if (isMagnifierModeOn) {
      resizeCanvas(false);
    }
  };

  let containerClassName = "crop-canvas-style";
  if (!isMagnifierModeOn) {
    if (canSlideLeft) {
      containerClassName = "cropped-canvas-main-wrapper slide";
    } else {
      containerClassName = "cropped-canvas-main-wrapper";
    }
  }

  return (
    <>
      <div
        className={containerClassName}
        style={{ height: canvasViewMode === SOFA_VIEW ? "auto" : "100%" }}
      >
        <DropTarget
          targetKey="dnd-signature"
          onHit={() => {
            showSignatureinPreview(croppedImageCanvas);
          }}
        >
          <div id="cropped-canvas-container">
            <canvas id="cropped-canvas" />
          </div>
        </DropTarget>
        {borderWidth !== 0 &&
        activePreviewMode === PROJECT_PREVIEW_MODE_CROPPED &&
        canvasViewMode === NORMAL_VIEW ? (
          <>
            <div className="border-text-container">
              <div className="border-imp-img">
                <img src={infoSvg} alt="" className="border-info-img" />
              </div>
              <div className="border-text">{string.IMPORTANT_NOTE}</div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      {activePreviewMode === PROJECT_PREVIEW_MODE_CERTIFICATE && <CardCanvas />}
    </>
  );
};

export default CroppedCanvas;
