import React, { useEffect, useState } from "react";
import "./SinglePageFooter.scss";
import footerLogo from "../../Utils/Icons/footer-logo.svg";
import { string } from "../../Localization";
import { footerLinks } from "../../Utils/ApiHelper";
import { getCurrentYear } from "../../Utils/Helper";

const SinglePageFooter = () => {
  const [footerLinksData, setFooterLinksData] = useState("");
  useEffect(() => {
    footerLinks().then((res) => {
      setFooterLinksData(res.data);
    });
  }, []);

  return (
    <div className="single_page_footer_container">
      <div className="single_page_footer_top">
        <div className="single_page_footer_image">
          <img
            src={footerLogo}
            alt="copyright"
            className="single_page_footer_img"
          />
        </div>
        <div className="single_page_footer_text">
          © Copyright {getCurrentYear()} HP Development Company, L.P.
        </div>
      </div>
      <div className="single_page_footer_line"></div>
      <div className="single_page_footer_bottom">
        <div className="single_page_footer_item single_page_footer1">
          <a
            href={footerLinksData["terms-and-conditions"]}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="single_page_footer_text">
              {string.TERMS_AND_CONDITION_FOOTER}
            </div>
          </a>
        </div>
        <div className="single_page_footer_item single_page_footer2">
          <a
            href={footerLinksData["personal-data-rights"]}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="single_page_footer_text">
              {string.PERSONAL_DATA_RIGHTS_NOTICE}
            </div>
          </a>
        </div>
        <div className="single_page_footer_item single_page_footer3">
          <a
            href={footerLinksData["cookies"]}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="single_page_footer_text">{string.COOKIES}</div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default SinglePageFooter;
