import React, { useState, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import closeBtn from "../../../Utils/Icons/close.svg";
import closeBtnCanvas from "../../../Utils/Icons/close_black.svg";
import UploadDialog from "../../../Components/Modal/UploadDialog/UploadDialog";
import {
  DRAW,
  STEP_THREE_CERTIFICATE,
  STEP_TWO_PERSONALISATION,
  UPLOAD,
} from "../../../Utils/Constants";
import {
  handleUploadSignature,
  showSignatureinPreview,
} from "../../../Utils/signatureHelper";
import { GlobalContext } from "../../../Context/GlobalState";
import { fabric } from "fabric";
import "./SignatureModal.scss";
import "../../../Components/Modal/UploadImage/UploadImage.scss";
import { useDispatch, useSelector } from "react-redux";
import ProjectSpinner from "../../../Components/Loaders/ProjectSpinner/ProjectSpinner";
import resetIcon from "../../../Utils/Icons/restart_alt.svg";
import { string } from "../../../Localization";
import resetIconGreen from "../../../Utils/Icons/restart_alt2.svg";
import {
  setCanShowSignature,
  setSignatureImage,
} from "../../../Store/Project/signatureSlice";

const SignatureModal = (props) => {
  const {
    signatureType,
    imageDetails,
    inputImage,
    canOpenModal,
    modalHandler,
    isProfileUpdatePage,
    onConfirmDrawSignature,
  } = props;

  const { setSignatureCanvas, signature, croppedImageCanvas } =
    useContext(GlobalContext);
  const { signatureCanvas } = signature;

  const { activeStep } = useSelector((state) => state && state.page);
  const { drawSignatureCache } = useSelector((state) => state.signature);

  const [showLoader, setShowLoader] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(true);
  const [hover, setHover] = useState(false);
  const [response, setResponse] = useState("");
  const [begin, setbegin] = useState(false);

  const dispatch = useDispatch();

  const modalBackgroundStyle =
    signatureType === UPLOAD ? "upload-modal signature-upload-modal" : "";
  const modalHeaderWrapperStyle =
    signatureType === UPLOAD ? "Sign-modal-header" : "draw-signature-content";

  useEffect(() => {
    const canvas = new fabric.Canvas("user-signature", {
      backgroundColor: "",
      isDrawingMode: true,
    });
    canvas.freeDrawingBrush.width = 2;
    canvas.on("path:created", function (e) {
      e.path.set();
      canvas.renderAll();
    });
    canvas.setWidth(550);
    canvas.setHeight(250);
    setSignatureCanvas(canvas);

    canvas.wrapperEl.addEventListener("mousedown", function (event) {
      setbegin(true);
    });

    canvas.wrapperEl.addEventListener("touchstart", function (event) {
      setbegin(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signatureType, canOpenModal]);

  const confirmDrawSignature = () => {
    setShowLoader(true);
    if (!isProfileUpdatePage) {
      const svgData = signatureCanvas.toSVG();
      handleUploadSignature(DRAW, svgData).then(() => {
        if (activeStep === STEP_TWO_PERSONALISATION) {
          showSignatureinPreview(croppedImageCanvas);
        }
        modalHandler(false);
        setShowLoader(false);
      });
    } else {
      const svgData = signatureCanvas.toSVG();
      onConfirmDrawSignature(svgData);
      modalHandler(false);
      setShowLoader(false);
    }
    setbegin(false);
  };

  const handleUpload = () => {
    handleUploadSignature(UPLOAD, inputImage)
      .then((res) => {
        if (activeStep === STEP_TWO_PERSONALISATION) {
          showSignatureinPreview(croppedImageCanvas);
        }
        setUploadSuccess(true);
        modalHandler(false);
      })
      .catch((err) => {
        setResponse(err?.response?.data?.data?.error_message);
        setUploadSuccess(false);
      });
  };

  const resetFileSelection = () => {
    modalHandler(false);
    setUploadSuccess(true);
  };

  const resetCanvas = () => {
    signatureCanvas.clear();
    setbegin(false);
  };

  return (
    <Modal show={canOpenModal} className={modalBackgroundStyle} centered>
      <div className={modalHeaderWrapperStyle}>
        {signatureType === UPLOAD && (
          <div className="header-background">
            {uploadSuccess === true ? (
              <>
                <p className="modal-title-text">{string.UPLOAD_SIGNATURE}</p>
              </>
            ) : (
              <>
                <p className="modal-title-text">
                  {string.SOMETHING_WENT_WRONG}
                </p>
              </>
            )}
          </div>
        )}
        <img
          src={signatureType === UPLOAD ? closeBtn : closeBtnCanvas}
          className="close-modal-svg"
          onClick={() => {
            setUploadSuccess(true);
            modalHandler(false);
            resetCanvas();
            if (activeStep === STEP_TWO_PERSONALISATION) {
              dispatch(setCanShowSignature(true));
            }
            if (signatureType === DRAW) {
              dispatch(setSignatureImage(drawSignatureCache));
            }
          }}
          alt="close"
        />

        <Modal.Body>
          {signatureType === UPLOAD ? (
            <>
              <UploadDialog
                imageDetails={imageDetails}
                uploadAction={handleUpload}
                onReset={resetFileSelection}
                uploadSuccess={uploadSuccess}
                termsAndCondition={false}
                response={response}
                isSignatureModal={true}
              />
            </>
          ) : (
            <>
              <div className="signature-canvas-wrapper">
                <div className="sig-canvas">
                  <canvas id="user-signature" />
                </div>
                {begin && (
                  <div className="d-flex justify-content-center button-container">
                    <div
                      className="d-flex justify-content-center reset-btn"
                      onMouseEnter={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}
                    >
                      <img
                        src={hover ? resetIconGreen : resetIcon}
                        alt="reset"
                        onClick={resetCanvas}
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        className="sig-confirm-btn"
                        onClick={confirmDrawSignature}
                      >
                        {string.CONFIRM}
                      </button>
                    </div>
                  </div>
                )}
                <ProjectSpinner
                  isLoading={showLoader}
                  customClass="draw-signature-loader"
                />
              </div>
              {!begin && (
                <>
                  <div className="sig-line-seperator"></div>
                  <div className="signature-text">
                    {string.PLEASE_SIGN_IN_THE_CENTER}
                  </div>
                </>
              )}
            </>
          )}
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default SignatureModal;
