import React, { useEffect } from "react";
import "./LimitedEdition.scss";
import CustomRadiobutton from "../CustomRadiobutton/CustomRadiobutton";
import { useDispatch, useSelector } from "react-redux";
import {
  setCanShowNoOfCopies,
  setIsLimited,
  setNoOfCopies,
} from "../../../Store/Project/limitedEditionSlice";
import { setLimitedEditionCoordinates } from "../../../Utils/PersonalizationHelper";
import { string } from "../../../Localization";
import CopyToPrint from "./CopyToPrint";

const LimitedEdition = () => {
  const dispatch = useDispatch();

  const {
    noOfCopies,
    isLimited,
    font,
    canShowNoOfCopies,
    limitedEditionPlotIsSet,
  } = useSelector((state) => state.limitedEdition);

  useEffect(() => {
    if (!limitedEditionPlotIsSet) {
      setLimitedEditionCoordinates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let styleName = "";
  if (!isLimited) {
    styleName = "LE-option2";
  }

  return (
    <>
      <div className="limitedEdition-wrapper">
        <div>
          <CustomRadiobutton
            title={string.I_WANT_TO_LIMIT_EDITION_COPIES}
            name="radio5"
            id="radio5"
            isChecked={isLimited}
            handleRadio={() => {
              dispatch(setIsLimited(true));
            }}
          />
          {isLimited && (
            <CopyToPrint
              isLimited
              noOfCopies={noOfCopies}
              font={font}
              canShowNoOfCopies={canShowNoOfCopies}
              setCanShowNoOfCopies={setCanShowNoOfCopies}
            />
          )}
        </div>

        <div className={styleName}>
          <CustomRadiobutton
            title={string.I_WANT_OPEN_EDITIONS}
            name="radio5"
            id="radio6"
            isChecked={!isLimited}
            handleRadio={(a) => {
              dispatch(setCanShowNoOfCopies(true));
              dispatch(setIsLimited(false));
              dispatch(setNoOfCopies(1));
            }}
          />

          <div className="text-description">
            {string.OPEN_EDITIIONS_ARE_UNLIMITED_COPIES}
          </div>

          {!isLimited && (
            <CopyToPrint
              isLimited={false}
              noOfCopies={noOfCopies}
              font={font}
              canShowNoOfCopies={canShowNoOfCopies}
              setCanShowNoOfCopies={setCanShowNoOfCopies}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default LimitedEdition;
