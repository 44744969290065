import { useEffect, useState } from "react";
import "./StartPrinting.scss";
import { footerLinks, triggerJondoOrder } from "../../Utils/ApiHelper";
import { useNavigate, useParams } from "react-router-dom";
import footerLogo from "../../Utils/Icons/footer-logo.svg";
import {
  sendGALoadedStartPrintingPage,
  sendGAOrderWithoutPersonalMessage,
} from "../../Utils/GAHelper";
import { string } from "../../Localization";
import LogoHP from "../../Components/LogoHP/LogoHP";
import { SUPPORTED_COUNTRY } from "../../Utils/Constants";

const StartPrinting = () => {
  const { order_token } = useParams();

  const navigate = useNavigate();

  const [links, setLinks] = useState("");

  useEffect(() => {
    sendGALoadedStartPrintingPage(order_token);
    footerLinks(SUPPORTED_COUNTRY).then((res) => {
      setLinks(res.data);
    });
  }, []);

  const JondoOrder = () => {
    triggerJondoOrder(order_token)
      .then((res2) => {
        console.log(res2.data.data);
        navigate("/");
      })
      .catch(function (error) {
        navigate("/");
      });
    sendGAOrderWithoutPersonalMessage(order_token);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <>
      <div className="StartPrinting-container">
        <div className="StartPrinting-text">
          <div className="StartPrinting-top-header">
            <LogoHP />
          </div>
          <div className="StartPrinting-page-text">
            <div className="StartPrinting-title">
              {string.ARE_YOU_SURE_YOU_WANT_TO_PROCEED}
            </div>
            <div className="StartPrinting-content-text">
              {/* <div className="tr1">{string.START_PRINTING_TEXT_1}</div>
              <div className="tr2">{string.START_PRINTING_TEXT_2}</div> */}
              <button className="StartPrinting-button" onClick={JondoOrder}>
                {string.PROCEED_WITH_PRINT}
              </button>
            </div>
          </div>
        </div>
        <div className="footer-container-persoanlNote">
          <div className="StartPrinting-page-copyright">
            <div className="StartPrinting-page-footer-img">
              <img
                src={footerLogo}
                alt=""
                className="StartPrinting-page-footer-logo"
              />
            </div>
            <div className="StartPrinting-page-footer-copyright">
              {string.HP_DEVELOPMENT_COMPANY_FOOTER}
            </div>
          </div>
          <div className="StartPrinting-footer-right">
            <a
              href={links["terms-and-conditions"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className="StartPrinting-footer-text"
                style={{ paddingLeft: "0px" }}
              >
                {string.TERMS_AND_CONDITION_FOOTER}
              </div>
            </a>
            <a
              href={links["personal-data-rights"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="StartPrinting-footer-text">
                {string.PERSONAL_DATA_RIGHTS_NOTICE}
              </div>
            </a>
            <a
              href={links["cookies"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className="StartPrinting-footer-text"
                style={{ border: "none" }}
              >
                {string.COOKIES}
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartPrinting;
