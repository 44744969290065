import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCanShowErrorAlertModal,
  setProjectDetails,
  setPriceInfo,
  setSelectedMediaTypeId,
  setShouldReposition,
  setSizeSelectionDetails,
  setBorderWidth,
} from "../../../Store/Project/ProjectSlice";
import {
  PRICE_CURRENCY_GBP,
  SECTION_BORDER,
  SECTION_INFO,
  SECTION_PAPER,
  SECTION_PRICING,
  SECTION_PRINT_SIZE,
} from "../../../Utils/Constants";
import {
  checkIfPhotoSizeIsZero,
  getSelectedMediaTypeName,
} from "../../../Utils/Helper";
import AccordianComponent from "../Accordian/AccordianComponent";
import Border from "../Border/Border";
import Information from "../Information/Information";
import Paper from "../Paper/Paper";
import Pricing from "../Pricing/Pricing";
import PrintSize from "../PrintSize/PrintSize";
import "./PhotoDetails.scss";
import {
  setHighlightedBorderValues,
  setHighlightedPrintSizeValues,
} from "../../../Utils/projectHelper";
import StatusModal from "../../../Components/StatusModal/StatusModal";
import error_icon from "../../../Utils/Icons/error_icon.svg";
import cancel_icon from "../../../Utils/Icons/cancel.svg";
import { string } from "../../../Localization";
import { GlobalContext } from "../../../Context/GlobalState";
import { didChangeBorderSelection } from "../../../Utils/ProjectRender";

const PhotoDetails = () => {
  const dispatch = useDispatch();
  const { project } = useSelector((state) => state);

  const { croppedImageCanvas } = useContext(GlobalContext);

  const {
    projectDetails,
    priceInfo,
    selectedMediaTypeId,
    borderWidth,
    selectedUnitSystem,
    printSizeString,
    borderString,
    CanShowErrorAlertModal,
    imageErrorMessage,
  } = project;

  const { title, description } = projectDetails;

  const currency = "£";

  const handleInfoChange = (title, description) => {
    dispatch(setProjectDetails({ id: "title", value: title }));
    dispatch(
      setProjectDetails({
        id: "description",
        value: description,
      })
    );
  };

  const handlePaperSelection = (paperId) => {
    if (selectedMediaTypeId !== paperId) {
      // dispatch(setSizeSelectionDetails({ id: "selectedId", value: null }));
      dispatch(setSelectedMediaTypeId(paperId));
      // dispatch(setActivePreviewMode(PROJECT_PREVIEW_MODE_SIZE_SELECTION));
    }
  };

  const handleSizeSelection = (selectedId, selectedDimension) => {
    dispatch(
      setSizeSelectionDetails({
        id: "selectedId",
        value: selectedId,
      })
    );
    dispatch(
      setSizeSelectionDetails({
        id: "selectedDimension",
        value: selectedDimension,
      })
    );
    // if photo size <= 0 i.e when border size > print size, reset the border to 0.
    if (checkIfPhotoSizeIsZero()) {
      dispatch(setBorderWidth(0));

      if (croppedImageCanvas) {
        didChangeBorderSelection(croppedImageCanvas);
        setHighlightedBorderValues();
      }
    }
    dispatch(setShouldReposition(true));
    setHighlightedPrintSizeValues();
  };

  const handlePriceSelection = (inPrice) => {
    dispatch(setPriceInfo({ id: "income", value: inPrice }));
    dispatch(setPriceInfo({ id: "currency", value: PRICE_CURRENCY_GBP }));
  };

  let errorDiv = null;

  errorDiv = (
    <>
      <div className="error-mandate-wrapper">
        <div className="error-mandate">
          <img src={cancel_icon} alt="" className="error-mandate-cancel" />
          <div>{string.THE_TITLE_IS_MANDATORY}</div>
        </div>
      </div>
    </>
  );

  const photoPrintData = [
    {
      id: SECTION_INFO,
      heading: string.INFO,
      content: title ? title : errorDiv,
      dropContent: (
        <Information
          onChangeInfo={handleInfoChange}
          inputTitle={title}
          inputDescription={description}
        />
      ),
    },

    {
      id: SECTION_PAPER,
      heading: string.PAPER,
      content: getSelectedMediaTypeName(),
      dropContent: (
        <Paper
          onChangePaper={handlePaperSelection}
          mediaTypeId={selectedMediaTypeId}
        />
      ),
    },

    {
      id: SECTION_PRINT_SIZE,
      heading: string.PRINT_SIZE,
      content: printSizeString,
      dropContent: <PrintSize onChangeSizeSelection={handleSizeSelection} />,
    },
    {
      id: SECTION_BORDER,
      heading: `${string.BORDER} (${selectedUnitSystem})`,
      content: borderString,
      dropContent: <Border borderWidth={borderWidth} />,
    },
    {
      id: SECTION_PRICING,
      heading: string.PRICING,
      content: `${string.ARTIST_INCOME}: ${currency} ${priceInfo.income}`,

      dropContent: (
        <Pricing
          onChangePrice={handlePriceSelection}
          income={priceInfo.income}
          currency={priceInfo.currency}
        />
      ),
    },
  ];

  const closeHandler = () => {
    dispatch(setCanShowErrorAlertModal(false));
  };

  return (
    <>
      <div className="create-print-photo">
        <div className="create-print-photo-text">
          {string.CREATING_A_PHOTO_PRINT}
        </div>
        <AccordianComponent accordionData={photoPrintData} />

        <StatusModal
          canShow={CanShowErrorAlertModal}
          stautsbackground={{ background: "#212121" }}
          statusimge={error_icon}
          statusHeading={string.THIS_IMAGE_ALREADY_EXISTS}
          statusDescription={`${imageErrorMessage}.`}
          closebackground={{ background: "#212121" }}
          closeData={{
            closeBtn: string.CLOSE,
            closeHandler: closeHandler,
          }}
        />
      </div>
    </>
  );
};

export default PhotoDetails;
