import { useEffect, useState } from "react";
import "./PaymentFailure.scss";
import { getOrderInfo } from "../../../Utils/ApiHelper";
import { string } from "../../../Localization";
import OrderSummaryFooter from "../OrderSummaryFooter/OrderSummaryFooter";
import Error from "../../../Utils/Icons/error.svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  sendGAClickedRetryPayment,
  sendGALoadedPaymentFailurePage,
} from "../../../Utils/GAHelper";
import Footer from "../../LandingPage/Footer/Footer";

const PaymentFailure = () => {
  const { order_token } = useParams();

  const navigate = useNavigate();

  const [orderDetails, setOrderDetails] = useState();
  const [onSubmit, setOnSubmit] = useState(false);

  const isInsideIframe = window.self !== window.top;

  useEffect(() => {
    if (isInsideIframe) {
      window.top.location.href = window.location.href;
    } else {
      getOrderInfo(order_token).then((res) => {
        const response = res.data.data;
        const userID = res?.data.data?.user?.id;
        sendGALoadedPaymentFailurePage(userID, order_token);
        setOrderDetails(response);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const retryPgsData = () => {
    setOnSubmit(true);
    navigate("/web/orders/" + order_token + "/checkout");
  };

  if (isInsideIframe) return null;

  return (
    orderDetails && (
      <div className="payment-failure-container">
        <div className="payment-failure-block">
          <div className="preview-block">
            <div className="error-logo-block">
              <img className="error-logo" src={Error} alt="error"></img>
            </div>
            <div className="preview-text-block">
              <div className="preview-title">{string.PAYMENT_DECLINED}</div>
              <div className="preview-description">
                {string.PAYMENT_DECLINED_text_1}
                <br /> {string.PAYMENT_DECLINED_text_2}
              </div>
            </div>
          </div>
          <div className="seperator-failure" />
          <div className="failure-retry-block">
            <div className="failure-retry-text">
              {string.PAYMENT_DECLINED_text_3}
              <br /> {string.PAYMENT_DECLINED_text_4}
            </div>
            <div
              className={
                onSubmit
                  ? "d-flex failure-retry-button btn-disabled"
                  : "d-flex failure-retry-button"
              }
              onClick={() => {
                retryPgsData();
                sendGAClickedRetryPayment(order_token);
              }}
            >
              {string.TRY_AGAIN}
            </div>
          </div>
        </div>
        <OrderSummaryFooter orderData={orderDetails} />
        <div className="payment-failure-footer">
          <Footer isLandingPage={false} />
        </div>
      </div>
    )
  );
};

export default PaymentFailure;
