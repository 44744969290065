import React from "react";
import "../LandingSubdivision/LandingSubdivision.scss";
const LandingSubdivision = (props) => {
  const {
    landingimage,
    landing_title,
    landing_text1,
    landing_text2,
    landing_text3,
    landing_style,
    landing_titlestyle,
    landing_textstyle,
  } = props;

  return (
    <>
      <div className="landingsubdivision_container" style={landing_style}>
        <div className="landingsub_image">
          <img
            className="landingsub_img"
            src={landingimage}
            alt="landing preview"
          />
        </div>
        <div className="landingsub_content">
          <div className="landingsub_title" style={landing_titlestyle}>
            {landing_title}
          </div>
          <div className="landingsub_text_content">
            <div className="landingsub_text" style={landing_textstyle}>
              {landing_text1}
            </div>
            <div className="landingsub_text" style={landing_textstyle}>
              {landing_text2}
            </div>
            <div className="landingsub_text" style={landing_textstyle}>
              {landing_text3}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingSubdivision;
