import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  setCanShowAlertModal,
  setCanShowStatusModal,
} from "../../Store/Project/ProjectSlice";
import { setCanShowBackAlertModal } from "../../Store/Project/ProjectSlice";
import Closebutton from "../../Utils/Icons/close.svg";
import "./DialogBox.scss";

const DialogBox = (props) => {
  const dispatch = useDispatch();
  const {
    canShow,
    heading,
    text1,
    text2,
    submitData,
    closeRequired,
    customStyles = {},
  } = props;
  const { submitText, submitHandler, cancelBtn, cancelHandler } = submitData;
  const { background, marginBottom, width, modalBody_background } =
    customStyles;

  return (
    <>
      <div className="dialog-container">
        <Modal
          dialogClassName="mw-100"
          show={canShow}
          className="modal-container-wrap"
          style={{
            background: background ? background : "",
            width: width ? width : "",
          }}
        >
          <Modal.Body
            className="modal-container"
            style={{
              background: modalBody_background ? modalBody_background : "",
              width: width ? width : "",
            }}
          >
            <div className="close-dialog">
              {!closeRequired && (
                <img
                  alt="close"
                  style={{ cursor: "pointer" }}
                  src={Closebutton}
                  onClick={() => {
                    dispatch(setCanShowAlertModal(false));
                    dispatch(setCanShowBackAlertModal(false));
                    dispatch(setCanShowStatusModal(false));
                  }}
                />
              )}
            </div>
            <div className="dialog-content">
              <div className="dialog-heading">{heading}</div>
              <div className="dialog-text-wrap">
                <div
                  className="dialog-body"
                  style={{
                    marginBottom: marginBottom ? marginBottom : "",
                    width: width ? width : "",
                  }}
                >
                  {text1}
                </div>
                <div
                  className="dialog-body"
                  style={{ width: width ? width : "" }}
                >
                  {text2}
                </div>
              </div>
              <div className="dialog-buttons">
                <button
                  className="dialog-cancel"
                  onClick={() => {
                    cancelHandler();
                  }}
                >
                  {cancelBtn}
                </button>
                <button
                  className="dialog-log"
                  onClick={() => {
                    dispatch(setCanShowAlertModal(false));
                    dispatch(setCanShowBackAlertModal(false));
                    submitHandler();
                  }}
                >
                  {submitText}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default DialogBox;
