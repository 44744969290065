import React, { useEffect } from "react";
import "./Certificate.scss";
import AccordianComponent from "../Accordian/AccordianComponent";
import SignatureCertificate from "../SignatureCertificate/SignatureCertificate";
import { useSelector } from "react-redux";
import { SECTION_CERTIFICATE } from "../../../Utils/Constants";
import { string } from "../../../Localization";
import { sendGAAddedSignatureInCertificate } from "../../../Utils/GAHelper";

const Certificate = () => {
  const { canShowSignature } = useSelector(
    (state) => state && state.signature && state.signature
  );

  useEffect(() => {
    // trigger GA event -> Added signature in certificate ( signature in certificate is added only if canShowSignature is false here)
    if (!canShowSignature) {
      sendGAAddedSignatureInCertificate();
    }
  }, []);

  const certificateData = [
    {
      id: SECTION_CERTIFICATE,
      heading: string.SIGNATURE,
      content: "",
      dropContent: <SignatureCertificate />,
    },
  ];
  return (
    <>
      <div className="create-print-photo">
        <div className="create-print-photo-text">{string.CERTIFICATE}</div>
        <div className="certificate-text">
          {string.CERTIFICATE_OF_AUTHENTICITY_1}
        </div>
        {canShowSignature ? null : (
          <AccordianComponent accordionData={certificateData} />
        )}
      </div>
    </>
  );
};

export default Certificate;
