import "./UploadImageFirst.scss";
import "../../Components/Modal/UploadImage/UploadImage.scss";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  showOrHideApiLoadingModal,
  showOrHideUploadFileModal,
} from "../../Store/Project/ModalSlice";
import {
  convertBytesToMegaBytes,
  getImageDimension,
  formatDateTime,
  getFileExtension,
  getPreviewUrlFromFile,
} from "../../Utils/Helper";
import { handleUpload, handleUploadClose } from "../../Utils/projectHelper";
import Dropzone from "react-dropzone";
import uploadSvg from "../../Utils/Icons/upload.svg";
import closeSvg from "../../Utils/Icons/close.svg";
import newImageUpload from "../../Utils/Icons/newImgUpload.svg";
import UploadDialog from "../../Components/Modal/UploadDialog/UploadDialog";
import {
  sendGAEmptyGallery,
  sendGAUploadedFirstPhoto,
} from "../../Utils/GAHelper";
import UploadSpinner from "../../Components/Loaders/UploadSpinner/UploadSpinner";
import { string } from "../../Localization";
import { MAX_SIZE_IN_BYTES } from "../../Utils/Constants";
import ProjectLoader from "../../Components/Loaders/ProjectLoader/ProjectLoader";
import ModalFooter from "../../Components/ModalFooter/ModalFooter";

const UploadImageFirst = ({ showOpenAi }) => {
  const dispatch = useDispatch();

  const { progressBarValue } = useSelector((state) => state.progressBar);

  const [imageDetails, setImageDetails] = useState(null);
  const [inputImage, setInputImage] = useState({});
  const [uploadSuccess, setUploadSuccess] = useState(true);
  const [modal, setModal] = useState(false);
  const [response, setResponse] = useState("");
  const [selectedFiles, setSelectedFiles] = useState();

  useEffect(() => {
    sendGAEmptyGallery();
  }, []);

  const resetFileSelection = () => {
    setImageDetails(null);
    setInputImage("");
    setUploadSuccess(true);
  };

  const onDrop = (files) => {
    if (files.length > 0) {
      let fileUploaded = files[0];
      setSelectedFiles(fileUploaded);

      if (fileUploaded?.size > MAX_SIZE_IN_BYTES) {
        dispatch(showOrHideApiLoadingModal(true));
      }

      getImageDimension(fileUploaded).then((imageDimensions) => {
        setImageDetails({
          name: fileUploaded.name,
          dimensions: imageDimensions,
          size: convertBytesToMegaBytes(fileUploaded.size),
          date: formatDateTime(new Date(fileUploaded.lastModified)),
          extension: getFileExtension(fileUploaded.name),
          preview: getPreviewUrlFromFile(fileUploaded, fileUploaded.size),
        });
      });
      dispatch(showOrHideApiLoadingModal(false));
      setInputImage(fileUploaded);
    }
    setModal(true);
  };

  const handleUploadButton = () => {
    const fileExtension = getFileExtension(selectedFiles?.name);

    if (
      fileExtension === "jpg" ||
      fileExtension === "jpeg" ||
      fileExtension === "tiff" ||
      fileExtension === "tif"
    ) {
      if (selectedFiles.size <= MAX_SIZE_IN_BYTES) {
        handleUpload(inputImage, imageDetails)
          .then(() => {
            setUploadSuccess(true);
          })
          .catch((inErrorMessage) => {
            if (inErrorMessage !== "canceled") {
              setResponse(inErrorMessage);
              setUploadSuccess(false);
            } else {
              resetFileSelection();
            }
          });
      } else {
        setResponse(string.FILE_SIZE_EXCEEDED);
        setUploadSuccess(false);
      }
    } else {
      setResponse(string.UPLOAD_INVALID_FORMAT_MESSAGE);
      setUploadSuccess(false);
    }
  };

  const UploadFile = () => {
    dispatch(showOrHideUploadFileModal(true));
  };

  const bodyClassName =
    progressBarValue === 100 && uploadSuccess
      ? "modal-body-container-spinner"
      : "modal-body-container";

  const headerClassName =
    progressBarValue === 100 && uploadSuccess
      ? "header-background header-background-spinner"
      : "header-background";

  return (
    <>
      <ProjectLoader />
      <div
        className={`upload-image-first-container ${
          showOpenAi ? "upload-image-first-add-container" : ""
        }`}
      >
        <Modal isOpen={modal} className="upload-modal" centered>
          <ModalHeader className={headerClassName}>
            <img
              alt="close"
              src={closeSvg}
              className="close-modal-svg"
              onClick={() => {
                handleUploadClose();
                setUploadSuccess(true);
                setModal(false);
              }}
            />
            <>
              {progressBarValue === 100 &&
              uploadSuccess ? null : uploadSuccess ? (
                <>
                  <ModalHeaderComponent
                    uploadPhoto={string.UPLOAD_PHOTO}
                    photoDescription={string.UPLOAD_PHOTO_DESCRIPTION}
                  />
                </>
              ) : (
                <>
                  <p className="modal-title-text">
                    {string.SOMETHING_WENT_WRONG}
                  </p>
                  <p className="modal-title-description">
                    {string.UPLOAD_FAILED_DESCRIPTION}
                  </p>
                </>
              )}
            </>
          </ModalHeader>

          <ModalBody className={bodyClassName}>
            {progressBarValue === 100 && uploadSuccess ? (
              <UploadSpinner />
            ) : imageDetails && imageDetails.name ? (
              <>
                <UploadDialog
                  uploadAction={handleUploadButton}
                  imageDetails={imageDetails}
                  uploadSuccess={uploadSuccess}
                  onReset={resetFileSelection}
                  termsAndCondition={true}
                  response={response}
                />
              </>
            ) : (
              <Dropzone onDrop={onDrop} multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <>
                        <img src={uploadSvg} alt="uploadSvg"></img>
                        <p className="upload-text">
                          {string.CLICK_TO_UPLOAD}&nbsp;{" "}
                          <span>{string.OR}</span>&nbsp;
                          {string.DRAG_AND_DROP}
                        </p>
                        <p className="upload-description">
                          {string.JPG_OR_TIFF}
                          <br /> {string.MAX_400MB}
                        </p>
                      </>
                    </div>
                    <div className="upload_image_footer_links">
                      <ModalFooter />
                    </div>
                  </section>
                )}
              </Dropzone>
            )}
          </ModalBody>
        </Modal>

        <div className="upload-image-first-text">
          <div className="drag-drop-above-text">
            <div
              className={`drag-and-drop-first-line-text ${
                showOpenAi ? "drag-and-drop-first-line-text-2" : ""
              }`}
            >
              {string.UPLOAD_YOUR_FIRST_PHOTO_TO_SELL} &nbsp;
              <span
                onClick={() => {
                  UploadFile();
                  sendGAUploadedFirstPhoto();
                }}
              >
                {string.HERE}
              </span>
              &nbsp;{string.DRAG_AND_DROP_IT_BELOW}
            </div>

            <div className="drag-and-drop-second-line-text">
              {string.ONLY_ONE_IMAGE_MAY_BE_UPLOADED_AT_A_TIME}
            </div>
          </div>

          <Dropzone onDrop={onDrop} multiple={false}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <>
                    <img
                      src={newImageUpload}
                      className="new-upload-image"
                      alt="new"
                    />
                  </>
                </div>
              </section>
            )}
          </Dropzone>

          <div className="drag-drop-below-text">
            <div className="drag-drop-new-text">
              {string.THE_PHOTO_SHOULD_BE_IN_JPG_OR_TIFF_FORMATS}
            </div>
            <div className="drag-drop-new-text">
              {string.SHOULDNT_BE_MORE_THAN_20MB}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ModalHeaderComponent = ({ uploadPhoto, photoDescription }) => {
  return (
    <>
      <p className="modal-title-text">{uploadPhoto}</p>
      <p className="modal-title-description">{photoDescription}</p>
    </>
  );
};

export default UploadImageFirst;
