import React from "react";
import "./AdminViewModal.scss";
import Closebutton from "../../../Utils/Icons/close.svg";
import { Modal, ModalBody } from "react-bootstrap";
import { setSalesOrderModal } from "../../../Store/Project/ModalSlice";
import { useDispatch } from "react-redux";

const AdminViewModal = (props) => {
  const { handleClose, jsonStringData, openModal, onViewClick, isOnlyText } =
    props;
  const dispatch = useDispatch();
  const handleViewClick = () => {
    onViewClick(jsonStringData);
    dispatch(setSalesOrderModal(false));
  };

  return (
    <Modal show={openModal} animation={false} className="adminModal-block">
      <ModalBody
        className="sales_modal-wrapper"
        style={{
          height: isOnlyText ? "285px" : "528px",
        }}
      >
        <div className="sales_modal_body">
          <div className="sales_close_btn_container">
            <img
              className="close_btn"
              src={Closebutton}
              onClick={handleClose}
              alt="Close"
            />
          </div>
          {isOnlyText ? (
            <>
              <div
                className="admin_sales_modal_contant contant_type"
                style={{ height: "183px" }}
              >
                {jsonStringData}
              </div>
            </>
          ) : (
            <>
              <div
                className="admin_sales_modal_contant"
                style={{ height: "393px" }}
              >
                <pre className="json-string">{jsonStringData}</pre>
              </div>
              <div className="copy_btn_wrapper">
                <div className="copy_btn" onClick={handleViewClick}>
                  <div className="sales_modal_copy">Copy</div>
                  <div className="fa fa-clone"></div>
                </div>
              </div>
            </>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AdminViewModal;
