import React from "react";
import { useDispatch } from "react-redux";
import BasicModal from "../../../Components/Modal/BasicModal/BasicModal";
import { string } from "../../../Localization";
import { showOrHideApiLoadingModal } from "../../../Store/Project/ModalSlice";
import { stopSelling } from "../../../Utils/ApiHelper";
import "./StartOrStopSelling.scss";

const StopSelling = (props) => {
  const { show, handleClose, projectToken, initializeGallery } = props;

  const dispatch = useDispatch();

  const handleStopSelling = () => {
    dispatch(showOrHideApiLoadingModal(true));
    stopSelling(projectToken)
      .then(() => {
        dispatch(showOrHideApiLoadingModal(false));
        initializeGallery();
        handleClose();
      })
      .catch(() => {
        dispatch(showOrHideApiLoadingModal(false));
      });
  };

  return (
    <BasicModal openModal={show} handleClose={handleClose}>
      <div className="start-stop-selling ">
        <div className="header">{string.STOP_SELLING}</div>
        <div className="description">{string.STOP_SELLING_TEXT}</div>
        <div>
          <button
            className="basic-button cancel-button me-3"
            onClick={() => handleClose()}
          >
            {string.CANCEL}
          </button>
          <button
            className="basic-button start-stop-button"
            onClick={() => handleStopSelling()}
          >
            {string.STOP_SELLING}
          </button>
        </div>
      </div>
    </BasicModal>
  );
};

export default StopSelling;
