import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../Modal/Modal";
import infoIcon from "../../../Utils/Icons/info.svg";
import boundingIcon from "../../../Utils/Icons/down-arrow.svg";
import "./Paper.scss";
import { string } from "../../../Localization";
import { activeMediaTypeData } from "../../../Utils/Helper";
import { setSelectedMediaTypeId } from "../../../Store/Project/ProjectSlice";

const Paper = (props) => {
  const { mediaTypeId, onChangePaper } = props;
  const { mediaTypesData } = useSelector((state) => state.cache);
  const { notSupportedMediaModal } = useSelector((state) => state.modal);

  const [canShowModal, setCanShowModal] = useState(false);
  const [mediaTypeInfo, setMediaTypeInfo] = useState(null);
  const [info, setInfo] = useState(false);
  const [infoDropDown, setInfoDropDown] = useState(false);
  const [selectedId, setSelectedId] = useState(mediaTypeId);

  const dispatch = useDispatch();

  useEffect(() => {
    if (mediaTypeId) {
      setSelectedId(mediaTypeId);
    }
  }, [mediaTypeId]);

  useEffect(() => {
    // in draftflow, if selected media type is disabled, then a default active media type is set
    if (notSupportedMediaModal.show) {
      dispatch(setSelectedMediaTypeId(activeMediaTypeData()[0]?.id));
    }
  }, []);

  const selectedMediaTypeName = () => {
    const mediaTypes = mediaTypesData.filter(
      (mediaType) => mediaType.id === selectedId
    );
    if (mediaTypes.length > 0) {
      return mediaTypes[0].name;
    }
    return "";
  };

  const handlePaperSelection = (inId) => {
    setSelectedId(inId);
    onChangePaper(inId);
  };

  const handleModalClose = () => {
    setCanShowModal(false);
  };

  return (
    <>
      <div className="text-box">
        <label htmlFor="" className="text-box-label">
          {string.PAPER_TYPE_2}
        </label>
        <div className="custom-select">
          <div className="select">
            <input
              type="radio"
              name="option"
              onClick={() => {
                setInfo(true);
                setInfoDropDown(!infoDropDown);
              }}
              className="radio-option-select"
            />
            <div
              className={
                infoDropDown === false
                  ? "placeholderarrow"
                  : "placeholderarrow placeholderarrowOpen"
              }
            >
              <span className="placeholder">{selectedMediaTypeName()}</span>
              <img className="arrow" alt="arrow" src={boundingIcon} />
            </div>
            {infoDropDown === true ? (
              <div className="dropdown-option">
                {" "}
                {mediaTypesData.map((mediaType) => {
                  return (
                    mediaType.active && (
                      <label className="option">
                        <input
                          type="radio"
                          name="option"
                          onChange={() => handlePaperSelection(mediaType.id)}
                          onClick={() => {
                            setInfoDropDown(false);
                          }}
                        />

                        <span
                          className="title animated fadeIn"
                          onClick={() => {
                            setInfo(false);
                          }}
                        >
                          {mediaType.name}
                          {info && (
                            <img
                              className="info-icon"
                              src={infoIcon}
                              onClick={(e) => {
                                setMediaTypeInfo(mediaType);
                                setCanShowModal(true);
                              }}
                              alt="info"
                            />
                          )}
                        </span>
                      </label>
                    )
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {canShowModal && (
          <Modal
            closeAction={handleModalClose}
            modalTitle={mediaTypeInfo.name}
            modalContent={mediaTypeInfo.description}
            paperWeight={mediaTypeInfo.weight}
            paperThickness={mediaTypeInfo.thickness}
            paperOpacity={mediaTypeInfo.opacity}
            paperFinish={mediaTypeInfo.finish}
            paperCatg={mediaTypeInfo.sub_category}
            icc_profile={mediaTypeInfo.icc_profile}
          />
        )}
      </div>
    </>
  );
};

export default Paper;
