import React, { useEffect, useState } from "react";
import "./AdminTables.scss";
import Pagination from "./Pagination/Pagination";
import AdminSearchBar from "../AdminSearchBar/AdminSearchBar";
import { RetriveWeb2PrintOrders } from "../../../Utils/ApiHelper";
import {
  ASCENDING_ORDERS,
  DESCENDING_ORDERS,
  ORDER_DETAILS_PAGE,
  ORDER_ID,
  PAGE_SIZE,
  TOKEN,
} from "../../../Utils/Constants";
import {
  setSalesOrderModal,
  showOrHideApiLoadingModal,
} from "../../../Store/Project/ModalSlice";
import { useDispatch, useSelector } from "react-redux";
import { Icons } from "../../../Utils/Icons";
import { setCanShowBackAlertModal } from "../../../Store/Project/ProjectSlice";
import { string } from "../../../Localization";
import AdminViewModal from "../../../Components/Modal/AdminViewModal/AdminViewModal";
import StatusModal from "../../../Components/StatusModal/StatusModal";
import status_right from "../../../Utils/Icons/status_right.svg";
import {
  setActivePageIndex,
  setOrderToken,
  setPage,
  setUserColumnKey,
  setUserTypeKey,
} from "../../../Store/Project/AdminSlice";
import ProjectLoader from "../../../Components/Loaders/ProjectLoader/ProjectLoader";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { showAdminSearchBox } from "../../../Utils/Helper";

const Web2PrintOrdersTable = () => {
  const { salesOrderModal } = useSelector((state) => state.modal);
  const { CanShowBackAlertModal } = useSelector((state) => state.project);
  const { userSearch, userColumnKey, userTypeKey, userSearchKey } = useSelector(
    (state) => state.admin
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [web2PrintData, setWeb2PrintData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [jsonStringData, setJsonStringData] = useState([]);
  const [tokenSort, setTokenSort] = useState(true);
  const [orderId, setOrderId] = useState(true);
  const [createdAtSort, setCreatedAtSort] = useState(true);
  const [subHeading, setSubHeading] = useState("");
  const [recordCount, setRecordCount] = useState(0);
  const [refresh, setRefresh] = useState(true);

  const options = [
    { value: "0", label: "Order ID" },
    { value: "1", label: "Token" },
  ];

  useEffect(() => {
    dispatch(showOrHideApiLoadingModal(true));
    RetriveWeb2PrintOrders(
      currentPage - 1,
      PAGE_SIZE,
      userTypeKey,
      userColumnKey,
      userSearchKey,
      userSearch
    )
      .then((res) => {
        setWeb2PrintData(res?.data?.data?.records_arr);
        setTotalPage(res?.data?.data?.total_pages);
        dispatch(showOrHideApiLoadingModal(false));
        setRecordCount(res?.data?.data?.records_count);
      })
      .catch((err) => {
        dispatch(showOrHideApiLoadingModal(false));
      });
  }, [
    userColumnKey,
    userSearch,
    userTypeKey,
    userSearchKey,
    currentPage,
    refresh,
  ]);

  const handleViewClick = (jsonString) => {
    setJsonStringData(jsonString);
    dispatch(setSalesOrderModal(true));
  };

  const handleCopyClick = (jsonString) => {
    navigator.clipboard
      .writeText(jsonString)
      .then(() => {
        dispatch(setCanShowBackAlertModal(true));
      })
      .catch((error) => {
        console.error("Failed to copy JSON to clipboard:", error);
      });
  };

  const handleClose = () => {
    dispatch(setSalesOrderModal(false));
  };

  const closeHandler = () => {
    dispatch(setCanShowBackAlertModal(false));
  };

  const totalCount = totalPage * PAGE_SIZE;

  return (
    <>
      {" "}
      <div className="admin_tables">
        <div className="d-flex  align-items-center">
          {showAdminSearchBox(userSearchKey, recordCount) && (
            <>
              <div className="admin_search_bar_container">
                <AdminSearchBar
                  setUserTypeKey={setUserTypeKey}
                  setUserColumnKey={setUserColumnKey}
                  options={options}
                />
              </div>
              <i
                className="fa refresh_load"
                onClick={() => {
                  setRefresh(!refresh);
                }}
              >
                &#xf021;
              </i>
            </>
          )}
        </div>
        {recordCount == 0 ? (
          <>
            <ProjectLoader />
            <div className="admin_user_back_btn">
              <div className="admin_no_user_details">
                <div className="order_no_user_details_text">
                  {string.NO_RECORDS_FOUND}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="admin_tables_wrapper">
              <ProjectLoader />
              <table className="table table-borderless table_block">
                <thead className="table_head">
                  <tr>
                    <th>
                      #
                      {createdAtSort ? (
                        <div
                          className="admin_navBar_sort fa fa-angle-down"
                          onClick={() => {
                            dispatch(setUserColumnKey(""));
                            dispatch(setUserTypeKey(ASCENDING_ORDERS));
                            setCreatedAtSort(false);
                          }}
                        ></div>
                      ) : (
                        <div
                          className="admin_navBar_sort fa fa-angle-up"
                          onClick={() => {
                            dispatch(setUserColumnKey(""));
                            dispatch(setUserTypeKey(DESCENDING_ORDERS));
                            setCreatedAtSort(true);
                          }}
                        ></div>
                      )}
                    </th>
                    <th>
                      {string.ORDER_ID}
                      {orderId ? (
                        <div
                          className="admin_navBar_sort fa fa-angle-down "
                          onClick={() => {
                            setOrderId(false);
                            dispatch(setUserColumnKey(ORDER_ID));
                            dispatch(setUserTypeKey(ASCENDING_ORDERS));
                          }}
                        ></div>
                      ) : (
                        <div
                          className="admin_navBar_sort fa fa-angle-up"
                          onClick={() => {
                            setOrderId(true);
                            dispatch(setUserColumnKey(ORDER_ID));
                            dispatch(setUserTypeKey(DESCENDING_ORDERS));
                          }}
                        ></div>
                      )}
                    </th>
                    <th>
                      {string.TOKEN}
                      {tokenSort ? (
                        <div
                          className="admin_navBar_sort fa fa-angle-down "
                          onClick={() => {
                            setTokenSort(false);
                            dispatch(setUserColumnKey(TOKEN));
                            dispatch(setUserTypeKey(ASCENDING_ORDERS));
                          }}
                        ></div>
                      ) : (
                        <div
                          className="admin_navBar_sort fa fa-angle-up"
                          onClick={() => {
                            setTokenSort(true);
                            dispatch(setUserColumnKey(TOKEN));
                            dispatch(setUserTypeKey(DESCENDING_ORDERS));
                          }}
                        ></div>
                      )}
                    </th>
                    <th>{string.REQUEST_BODY}</th>
                    <th>{string.RESPONSE_CODE}</th>
                    <th>{string.RESPONSE_BODY}</th>
                    <th>{string.MESSAGE}</th>
                    <th>{string.CREATED_AT}</th>
                  </tr>
                </thead>
                <tbody>
                  {web2PrintData.map((item, index) => {
                    const jsonString = JSON.stringify(item.req_body, null, 2);
                    const jsonStringRespBody = JSON.stringify(
                      item.resp_body,
                      null,
                      2
                    );

                    return (
                      <tr className="admin_table_row" key={index}>
                        <td>{item.id}</td>
                        <td
                          className="order_number"
                          onClick={() => {
                            dispatch(setOrderToken(item.order_token));
                            navigate(`order_details/${item.order_token}`);
                            dispatch(setActivePageIndex(ORDER_DETAILS_PAGE));
                          }}
                        >
                          <Tooltip title={string.CLICK_TO_VIEW_DETAILS} arrow>
                            <span> {item.order_id}</span>
                          </Tooltip>
                        </td>
                        <td>{item.token}</td>

                        <td>
                          <div className="admin_view_btn d-flex justify-content-center">
                            <div
                              className="view_modal"
                              onClick={() => {
                                handleViewClick(jsonString);
                                setSubHeading(
                                  string.WEB2PRINT_REQUEST_BODY_COPIED_SUCCESSFULLY
                                );
                              }}
                            >
                              {string.VIEW}{" "}
                            </div>
                            <div>
                              <img
                                src={Icons.copy.default}
                                className="copy-icon"
                                alt="copy"
                                onClick={() => {
                                  setSubHeading(
                                    string.WEB2PRINT_REQUEST_BODY_COPIED_SUCCESSFULLY
                                  );
                                  handleCopyClick(jsonString);
                                }}
                              />
                            </div>
                          </div>
                        </td>
                        <td>{item.resp_code === "" ? "-" : item.resp_code}</td>
                        <td>
                          <div className="admin_view_btn d-flex justify-content-center">
                            {jsonStringRespBody === "{}" ? (
                              "-"
                            ) : (
                              <>
                                <div
                                  className="view_modal"
                                  onClick={() => {
                                    setSubHeading(
                                      string.WEB2PRINT_RESPONSE_BODY_COPIED_SUCCESSFULLY
                                    );
                                    handleViewClick(jsonStringRespBody);
                                  }}
                                >
                                  {string.VIEW}{" "}
                                </div>
                                <div>
                                  <img
                                    src={Icons.copy.default}
                                    className="copy-icon"
                                    alt="copy"
                                    onClick={() => {
                                      setSubHeading(
                                        string.WEB2PRINT_RESPONSE_BODY_COPIED_SUCCESSFULLY
                                      );
                                      handleCopyClick(jsonStringRespBody);
                                    }}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </td>
                        <td>
                          {item.message === "" || item.message === null
                            ? "-"
                            : item.message}
                        </td>
                        <td>{item.created_at}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="admin_pagination">
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={totalCount}
                pageSize={PAGE_SIZE}
                onPageChange={(page) => {
                  setCurrentPage(page);
                  dispatch(setPage(page));
                }}
              />
            </div>
          </>
        )}
      </div>
      <StatusModal
        canShow={CanShowBackAlertModal}
        stautsbackground={{ background: "#176267" }}
        statusimge={status_right}
        statusHeading={string.COPIED_SUCCESSFULLY}
        statusDescription={subHeading}
        closebackground={{ background: "#176267" }}
        closeData={{
          closeBtn: string.CLOSE,
          closeHandler: closeHandler,
        }}
      />
      <div className="sales_order_request_body">
        <AdminViewModal
          handleClose={handleClose}
          jsonStringData={jsonStringData}
          openModal={salesOrderModal}
          onViewClick={handleCopyClick}
        />
      </div>
    </>
  );
};

export default Web2PrintOrdersTable;
