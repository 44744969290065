import React, { useEffect, useState } from "react";
import "./AdminSummary.scss";
import { RetrieveUsageSummary } from "../../../Utils/ApiHelper";
import { useDispatch, useSelector } from "react-redux";
import RangeDatePicker from "../AdminDatePicker/RangeDatePicker";
import { showOrHideApiLoadingModal } from "../../../Store/Project/ModalSlice";
import ProjectLoader from "../../../Components/Loaders/ProjectLoader/ProjectLoader";
import { string } from "../../../Localization";
import UsageReportHash from "./UsageReportHash";

const UsageSummary = () => {
  const dispatch = useDispatch();
  const { startDate, endDate } = useSelector((state) => state.admin);
  const { apiLoading } = useSelector((state) => state.modal);

  const [productSize, setProductSize] = useState([]);
  const [mediaTypeData, setMediaTypeData] = useState([]);
  const [usageReportHash, setUsageReportHash] = useState([]);

  useEffect(() => {
    dispatch(showOrHideApiLoadingModal(true));
    RetrieveUsageSummary(startDate, endDate)
      .then((res) => {
        setUsageReportHash(UsageReportHash(res.data?.data));
        setProductSize(res?.data?.data?.product_size_data);
        setMediaTypeData(res?.data?.data?.media_type_info);
        dispatch(showOrHideApiLoadingModal(false));
      })
      .catch((error) => {
        dispatch(showOrHideApiLoadingModal(false));
      });
  }, [startDate, endDate]);

  return (
    <div className="dashboard-container">
      <ProjectLoader />
      {!apiLoading.canShowApiLoadingModal && (
        <>
          <RangeDatePicker />
          <div className="dashboard">
            {usageReportHash.map((report, index) => (
              <div key={index} className="section">
                <h2>{report.title}</h2>
                <p>{report.value}</p>
              </div>
            ))}
          </div>

          <div className="table-section">
            <h2>{string.PRODUCT_SIZE_DETAIL} </h2>
            <table className="product-size-data">
              <thead>
                <tr>
                  <th className="category-border">{string.CATEGORY}</th>
                  <th>{string.DIMENSION}</th>
                  <th>{string.COUNT}</th>
                  <th>{string.USAGE_PERCENT}</th>
                  <th>{string.TOTAL_PRICING}</th>
                  <th>{string.AVG_PRICING}</th>
                  <th>{string.TOTAL_HP_REVENUE}</th>
                  <th>{string.TOTAL_PSP_REVENUE}</th>
                  <th>{string.TOTAL_PHOTOGRAPHER_REVENUE}</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(productSize).map((category) => {
                  const dimensions = productSize[category];
                  const dimensionKeys = Object.keys(dimensions);

                  return Object.keys(dimensions).map((dimension, index) => (
                    <tr
                      key={dimension}
                      className={
                        category === "Landscape"
                          ? index === 0
                            ? "add-category-border-top"
                            : index === dimensionKeys.length - 1
                            ? "add-category-border-bottom"
                            : ""
                          : ""
                      }
                    >
                      {index === 0 && (
                        <td
                          className="category-name"
                          rowSpan={Object.keys(dimensions).length}
                        >
                          {category}
                        </td>
                      )}
                      <td>{dimension}</td>
                      <td>{dimensions[dimension].count}</td>
                      <td>{dimensions[dimension].usage_perc}%</td>
                      <td>{dimensions[dimension].total_sales}</td>
                      <td>{dimensions[dimension].avg}</td>
                      <td>{dimensions[dimension].total_hp_revenue}</td>
                      <td>{dimensions[dimension].total_psp_revenue}</td>
                      <td>
                        {dimensions[dimension].total_photographer_revenue}
                      </td>
                    </tr>
                  ));
                })}
              </tbody>
            </table>
          </div>

          <div className="table-section">
            <h2>{string.MEDIA_TYPE_DETAIL} </h2>
            <table className="product-size-data">
              <thead>
                <tr>
                  <th className="category-border">{string.PAPER_TYPE}</th>
                  <th>{string.NO_OF_ARTIST_PROOF_INITIATED}</th>
                  <th>{string.LIMITED_EDITION_CREATED}</th>
                  <th>{string.NUMBER_OF_AUTHORIZED_LE_CREATED}</th>
                  <th>{string.NUMBER_OF_AUTHORIZED_COPIES} </th>
                  <th>{string.NO_OF_OE_CREATED} </th>
                  <th>{string.NO_OF_ARTIST_PROOF_SOLD_1} </th>
                  <th>{string.NO_OF_OE_PRINT_SOLD}</th>
                  <th>{string.NO_OF_LE_PRINT_SOLD}</th>
                </tr>
              </thead>

              <tbody>
                {Object.keys(mediaTypeData).map((category, index) => (
                  <tr className="category" key={index}>
                    <td className="category-name">{category}</td>
                    <td>{mediaTypeData[category].artist_proof_count}</td>
                    <td key={index}>
                      {mediaTypeData[category].limited_edition_count}
                    </td>
                    <td>
                      {mediaTypeData[category].authorized_limited_edition_count}
                    </td>
                    <td>{mediaTypeData[category].no_of_authorized_copies}</td>
                    <td>{mediaTypeData[category].open_edition_count}</td>
                    <td>{mediaTypeData[category].no_of_artist_proofs_sold}</td>
                    <td>
                      {mediaTypeData[category].no_of_open_edition_prints_sold}
                    </td>
                    <td>{mediaTypeData[category].no_of_prints_sold}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default UsageSummary;
