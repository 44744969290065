import { createSlice } from "@reduxjs/toolkit";
import {
  NORMAL_VIEW,
  PROJECT_PREVIEW_MODE_INITIAL,
  UNIT_SYSTEM_INCHES,
} from "../../Utils/Constants";

const initialProjectDetail = {
  title: "",
  description: "",
  copyrightText: "",
  isExclusive: true,
  isAiImage: false,
};

const initialState = {
  uploadedImageData: {
    originalImageURL: "",
    imageURL: "",
    lowResolutionSize: { width: 0, height: 0 },
    imageScale: 1,
  },
  projectToken: null,
  originalProjectSize: { width: 0, height: 0 },
  projectImageScale: 1,
  borderScale: 1,
  projectCropRect: { left: 0, top: 0, width: 0, height: 0 },
  isImageFitToCropRect: false,
  borderWidth: 0,
  borderMeasurementStatus: false,
  imageDimensions: [],
  isCanvasLoading: false,
  imagePreviewData: {
    title: "",
    size: "",
    dimension: "",
  },
  projectDetails: initialProjectDetail,
  sizeSelectionDetails: {
    selectedId: null,
    bestMatchId: null,
    selectedDimension: { width: 0, height: 0 },
  },
  sizeApplied: false,
  printSizeString: "",
  borderString: 0,
  selectedMediaTypeId: null,
  priceInfo: {
    income: 0,
    currency: "",
  },
  isOrderSubmitted: true,
  isOrderDeleted: true,
  isPrintOrderPlaced: true,
  isSignedOut: true,
  isProjectCreated: true,
  isPolling: false,
  previewImage: null,
  magnifierImage: null,
  activePreviewMode: PROJECT_PREVIEW_MODE_INITIAL,
  canShowAlertModal: false,
  canShowSuccessModal: false,
  CanShowBackAlertModal: false,
  CanShowErrorAlertModal: false,
  canShowStatusModal: false,
  canShowResolutionModal: false,
  imageErrorMessage: "",
  selectedUnitSystem: UNIT_SYSTEM_INCHES,
  isMagnifierModeOn: false,
  croppedCanvasActiveObject: null,
  signatureScale: 1,
  onClickLoader: false,
  magnifierIsCompleted: false,
  shouldReposition: false,
  canvasViewMode: NORMAL_VIEW,
  draftStatus: "",
  incomeModal: false,
  isUploadCancelled: false,
  aiOrientation: "",
  aiOrientationKey: "",
  superResolution: {
    selectedScale: 1,
    maximumScale: 5,
  },
};

export const projectSlice = createSlice({
  name: "project",
  initialState: initialState,
  reducers: {
    resetAllProjectDetails: () => initialState,

    setImagePreviewData(state, action) {
      state.imagePreviewData = action.payload;
    },

    setUploadedImageData(state, action) {
      state.uploadedImageData = action.payload;
    },

    setProjectToken(state, action) {
      state.projectToken = action.payload;
    },

    setProjectCropRect(state, action) {
      state.projectCropRect = action.payload;
    },

    setOriginalProjectSize(state, action) {
      state.originalProjectSize = action.payload;
    },

    setProjectImageScale(state, action) {
      state.projectImageScale = action.payload;
    },

    setBorderScale(state, action) {
      state.borderScale = action.payload;
    },

    setImageDimensions(state, action) {
      state.imageDimensions = action.payload;
    },

    setIsCanvasLoading(state, action) {
      state.isCanvasLoading = action.payload;
    },

    setProjectDetails(state, action) {
      const { id, value } = action.payload;
      state.projectDetails[id] = value;
    },

    setSizeSelectionDetails(state, action) {
      const { id, value } = action.payload;
      state.sizeSelectionDetails[id] = value;
    },

    setPriceInfo(state, action) {
      const { id, value } = action.payload;
      state.priceInfo[id] = value;
    },

    setIsOrderSubmited(state, action) {
      state.isOrderSubmitted = action.payload;
    },

    setIsOrderDeleted(state, action) {
      state.isOrderDeleted = action.payload;
    },

    setIsPrintOrderPlaced(state, action) {
      state.isPrintOrderPlaced = action.payload;
    },

    setIsSignedOut(state, action) {
      state.isSignedOut = action.payload;
    },

    setIsImageFitToCropRect(state, action) {
      state.isImageFitToCropRect = action.payload;
    },

    setIsPolling(state, action) {
      state.isPolling = action.payload;
    },

    setIsProjectCreated(state, action) {
      state.isProjectCreated = action.payload;
    },

    setActivePreviewMode(state, action) {
      state.activePreviewMode = action.payload;
    },

    setSelectedMediaTypeId(state, action) {
      state.selectedMediaTypeId = action.payload;
    },

    setBorderWidth(state, action) {
      state.borderWidth = action.payload;
    },

    setBorderMeasurementStatus(state, action) {
      state.borderMeasurementStatus = action.payload;
    },

    setPreviewImage(state, action) {
      state.previewImage = action.payload;
    },

    setMagnifierImage(state, action) {
      state.magnifierImage = action.payload;
    },

    setCanShowAlertModal(state, action) {
      state.canShowAlertModal = action.payload;
    },
    setCanShowSuccessModal(state, action) {
      state.canShowSuccessModal = action.payload;
    },

    setCanShowResolutionModal(state, action) {
      state.canShowResolutionModal = action.payload;
    },
    setCanShowBackAlertModal(state, action) {
      state.CanShowBackAlertModal = action.payload;
    },
    setCanShowErrorAlertModal(state, action) {
      state.CanShowErrorAlertModal = action.payload;
    },
    setCanShowStatusModal(state, action) {
      state.canShowStatusModal = action.payload;
    },

    setImageErrorMessage(state, action) {
      state.imageErrorMessage = action.payload;
    },

    setSelectedUnitSystem(state, action) {
      state.selectedUnitSystem = action.payload;
    },

    setIsMagnifierModeOn(state, action) {
      state.isMagnifierModeOn = action.payload;
    },
    setCroppedCanvasActiveObject(state, action) {
      state.croppedCanvasActiveObject = action.payload;
    },
    setSignatureScale(state, action) {
      state.signatureScale = action.payload;
    },
    setOnClickLoader(state, action) {
      state.onClickLoader = action.payload;
    },
    setMagnifierIsCompleted(state, action) {
      state.magnifierIsCompleted = action.payload;
    },
    setShouldReposition(state, action) {
      state.shouldReposition = action.payload;
    },

    setPrintSizeString(state, action) {
      state.printSizeString = action.payload;
    },

    setBorderString(state, action) {
      state.borderString = action.payload;
    },

    setCanvasViewMode(state, action) {
      state.canvasViewMode = action.payload;
    },
    setDraftStatus(state, action) {
      state.draftStatus = action.payload;
    },
    setIncomeModal(state, action) {
      state.incomeModal = action.payload;
    },

    setIsUploadCancelled(state, action) {
      state.isUploadCancelled = action.payload;
    },
    setIsAiImage(state, action) {
      state.isAiImage = action.payload;
    },
    setSizeApplied(state, action) {
      state.sizeApplied = action.payload;
    },
    setAiOrientation(state, action) {
      state.aiOrientation = action.payload;
    },
    setAiOrientationKey(state, action) {
      state.aiOrientationKey = action.payload;
    },
    setSelectedSuperResolutionScale(state, action) {
      state.superResolution.selectedScale = action.payload;
    },
    setMaxSuperResolutionScale(state, action) {
      state.superResolution.maximumScale = action.payload;
    },
  },
});

export const {
  setUploadedImageData,
  setImagePreviewData,
  setProjectToken,
  resetAllProjectDetails,
  setProjectCropRect,
  setOriginalProjectSize,
  setImageDimensions,
  setIsCanvasLoading,
  setProjectDetails,
  setSizeSelectionDetails,
  setPriceInfo,
  setIsOrderSubmited,
  setIsOrderDeleted,
  setIsPrintOrderPlaced,
  setIsSignedOut,
  setIsImageFitToCropRect,
  setIsPolling,
  setIsProjectCreated,
  setProjectImageScale,
  setBorderScale,
  setActivePreviewMode,
  setSelectedMediaTypeId,
  setBorderWidth,
  setBorderMeasurementStatus,
  setPreviewImage,
  setMagnifierImage,
  setCanShowAlertModal,
  setCanShowSuccessModal,
  setCanShowBackAlertModal,
  setCanShowErrorAlertModal,
  setCanShowResolutionModal,
  setCanShowStatusModal,
  setImageErrorMessage,
  setSelectedUnitSystem,
  setIsMagnifierModeOn,
  setCroppedCanvasActiveObject,
  setSignatureScale,
  setOnClickLoader,
  setMagnifierIsCompleted,
  setShouldReposition,
  setPrintSizeString,
  setBorderString,
  setCanvasViewMode,
  setDraftStatus,
  setIncomeModal,
  setIsUploadCancelled,
  setSizeApplied,
  setAiOrientation,
  setAiOrientationKey,
  setSelectedSuperResolutionScale,
  setMaxSuperResolutionScale,
} = projectSlice.actions;

export default projectSlice.reducer;
