import { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import Steps from "../../Components/Steps/Steps";
import {
  PAYMENT_STEP,
  REVIEW_STEP,
  SUBMIT_ORDER_STEP,
} from "../../Utils/Constants";
import Footer from "../LandingPage/Footer/Footer";
import Checkout from "./Checkout/Checkout";
import SubmitOrder from "./SubmitOrder/SubmitOrder";
import "./Order.scss";
import ReviewOrder from "./ReviewOrder/ReviewOrder";

const Order = (props) => {
  const [projectSummaryData, setProjectSummaryData] = useState({});
  const { currentStep } = props;

  return (
    <div className="order-wrapper">
      <Header projectSummaryData={projectSummaryData} />
      <Steps currentStep={currentStep} />
      {currentStep === SUBMIT_ORDER_STEP ? (
        <SubmitOrder
          setProjectSummaryData={setProjectSummaryData}
          projectSummaryData={projectSummaryData}
        />
      ) : currentStep === PAYMENT_STEP ? (
        <Checkout
          setProjectSummaryData={setProjectSummaryData}
          projectSummaryData={projectSummaryData}
        />
      ) : currentStep === REVIEW_STEP ? (
        <ReviewOrder
          setProjectSummaryData={setProjectSummaryData}
          projectSummaryData={projectSummaryData}
        />
      ) : (
        <></>
      )}
      <div
        className={
          currentStep === PAYMENT_STEP
            ? "Submit_footer Payment_footer"
            : "Submit_footer"
        }
      >
        <Footer isLandingPage={false} />
      </div>
    </div>
  );
};

export default Order;
