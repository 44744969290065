// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case "SET_IMAGE_CANVAS":
      return { ...state, imageCanvas: action.payload };
    case "SET_CROPPED_IMAGE_CANVAS":
      return { ...state, croppedImageCanvas: action.payload };
    case "SET_MAIN_CANVAS":
      return {
        ...state,
        signature: {
          ...state.signature,
          mainCanvas: action.payload,
        },
      };
    case "SET_SIGNATURE_CANVAS":
      return {
        ...state,
        signature: {
          ...state.signature,
          signatureCanvas: action.payload,
        },
      };
    case "SET_FINAL_CANVAS_URL":
      return {
        ...state,
        finalCanvasURL: action.payload,
      };

    default:
      return state;
  }
};
