import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import "./SearchByAIModal.scss";
import Closebutton from "../../../Utils/Icons/close.svg";
import { generateImage } from "../../../Utils/ApiHelper";
import SearchResultModal from "../SearchResultModal/SearchResultModal";
import { useDispatch, useSelector } from "react-redux";
import { showOrHideUploadAIOptionModal } from "../../../Store/Project/ModalSlice";
import { string } from "../../../Localization";
import ProjectLoader from "../../Loaders/ProjectLoader/ProjectLoader";
import SearchByAIError from "../SearchByAiError/SearchByAiError";
import ModalFooter from "../../ModalFooter/ModalFooter";
import {
  sendGAClickedAISearch,
  sendGAUpdatedSearchContentForAI,
} from "../../../Utils/GAHelper";
import Select from "react-select";
import {
  setAiOrientation,
  setAiOrientationKey,
} from "../../../Store/Project/ProjectSlice";
import backArrowSvg from "../../../Utils/Icons/arrow_back.svg";
import UploadSpinner from "../../Loaders/UploadSpinner/UploadSpinner";

const SearchByAIModal = ({ isModalOpen, onClose, setIsModalOpen }) => {
  const { aiOrientation, aiOrientationKey } = useSelector(
    (state) => state.project
  );

  const [searchText, setSearchText] = useState("");
  const [resultUrl, setResultUrl] = useState([]);
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);
  const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [isPrompt, setIsPrompt] = useState(false);

  const dispatch = useDispatch();

  const options = [
    { value: "0", label: "Landscape" },
    { value: "1", label: "Portrait" },
    { value: "2", label: "Square" },
  ];

  useEffect(() => {
    if (options?.length > 0) {
      dispatch(setAiOrientation(options[0].label));
      dispatch(setAiOrientationKey(options[0].value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsSearchButtonDisabled(searchText.trim() === "");
  }, [searchText]);

  const handleCloseClick = () => {
    setSearchText("");
    setPrivacyPolicy(false);
    onClose();
    dispatch(setAiOrientation(options[0].label));
    dispatch(setAiOrientationKey(options[0].value));
    setErrorMessage("");
    setIsPrompt(false);
  };

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleSearchClick = () => {
    setIsPrompt(true);
    setResultUrl([]);
    if (searchText.trim() !== "") {
      sendGAClickedAISearch();
      generateImage(searchText, aiOrientationKey)
        .then((response) => {
          setResultUrl(response?.data?.data?.map((item) => item.url) || []);
          setIsResultModalOpen(true);
          onClose();
          setIsPrompt(false);
        })
        .catch((error) => {
          setErrorMessage(error?.response?.data?.error_message);
          console.error(error);
        });
    }
  };

  const handleCloseResultModal = () => {
    setIsResultModalOpen(false);
    setIsPrompt(false);
  };

  const handleEdit = () => {
    setIsPrompt(false);
    setIsResultModalOpen(false);
    setIsModalOpen(true);
    sendGAUpdatedSearchContentForAI();
  };

  const handleBack = () => {
    setIsResultModalOpen(false);
    setIsModalOpen(false);
    dispatch(showOrHideUploadAIOptionModal(true));
    setIsPrompt(false);
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "transparent",
      borderRadius: "8px 8px 8px 8px",
      borderColor: "#737373",
      color: "black",
      "&:hover": {
        borderColor: "#737373",
        boxShadow: "none",
      },

      boxShadow: "none",
      "&:focus": {
        borderColor: "#737373",
        boxShadow: "none",
      },
      width: "100%",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: "6px",
      border: "1px solid #737373",
      boxShadow: "none",
      scrollbarWidth: "none",
      maxHeight: "300px",
      overflow: "scroll",
      marginTop: "-1px",

      "&::-webkit-scrollbar": {
        display: "none",
      },
    }),
    menuList: (base) => ({
      ...base,
      color: "black",
      marginTop: "0px",
      padding: "0px",
      margin: "0px",
      border: "none",
      boxShadow: "none",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        width: "2px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#1a1e22",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#353535",
      },
    }),
    placeholder: (base) => ({
      ...base,
      color: "#F8F8F8",
    }),
    singleValue: (base) => ({
      ...base,
      color: "#F8F8F8",
    }),
    input: (base) => ({
      ...base,
      color: "#F8F8F8",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    option: (base, state) => ({
      ...base,
      border: "none",
      boxShadow: "none",
      backgroundColor: state.isFocused ? "#1a1e22" : "#404040",
      color: state.isFocused ? "white" : "#DBDBDB",
      "&:hover": {
        backgroundColor: "#1a1e22",
        color: "white",
      },
      "&:active": {
        backgroundColor: "#353535",
        color: "white",
      },
    }),
  };

  const spinnerClass = isPrompt
    ? "search-modal-container spinner"
    : "search-modal-container";

  return (
    <>
      <ProjectLoader />
      <Modal show={isModalOpen} className="search-modal-container-wrap">
        <ModalBody className={spinnerClass}>
          <div className="close-btn-container">
            <div className="search-result-back-container" onClick={handleBack}>
              <img src={backArrowSvg} alt="back"></img>
              <div className="back-text">Back</div>
            </div>
            <img
              className="close-btn"
              src={Closebutton}
              onClick={handleCloseClick}
              alt="Close"
            />
          </div>
          {isPrompt ? (
            <>
              <div className="upload_spinner_container">
                <UploadSpinner message="Processing" />
              </div>
            </>
          ) : (
            <>
              <div className="search-body">
                <h4 className="search-header">
                  Please provide your prompt to generate an image using AI
                </h4>
                <div className="searchAI-text">
                  You can just type a word or a text and we generate a photo for
                  you.
                </div>
                <div className="search-input-container">
                  <div className="prompt-text">Prompt text</div>
                  <textarea
                    placeholder="Type here"
                    className="search-input"
                    rows={4}
                    onChange={handleInputChange}
                    value={searchText}
                  />
                </div>
                <div className="ai_dropdown_container">
                  <div className="ai_orientation">{string.ORIENTATION}</div>
                  <div className="ai_modal_dropdown">
                    <Select
                      id="orientation"
                      name="orientation"
                      placeholder={aiOrientation}
                      options={options}
                      value={aiOrientation}
                      styles={customStyles}
                      onChange={(value) => {
                        dispatch(setAiOrientation(value.label));
                        dispatch(setAiOrientationKey(value.value));
                      }}
                    />
                  </div>
                </div>

                <div className="search-button-container">
                  <Button
                    disabled={isSearchButtonDisabled}
                    className={`search-button ${
                      isSearchButtonDisabled ? "disabled" : ""
                    }`}
                    onClick={handleSearchClick}
                  >
                    Generate an image
                  </Button>
                </div>
                <div className="search_by_ai_modal_footer">
                  <ModalFooter />
                </div>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>

      <SearchResultModal
        setSearchText={setSearchText}
        searchText={searchText}
        isOpen={isResultModalOpen}
        onClose={handleCloseResultModal}
        searchResults={resultUrl}
        setResultUrl={setResultUrl}
        handleSearchClick={handleSearchClick}
        handleEdit={handleEdit}
        privacyPolicy={privacyPolicy}
        setPrivacyPolicy={setPrivacyPolicy}
      />
      <SearchByAIError
        isOpen={errorMessage}
        onClose={handleCloseClick}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default SearchByAIModal;
