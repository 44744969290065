import React, { useEffect, useState } from "react";
import "./Footer.scss";
import Flag from "../../../Utils/Icons/flag.svg";
import hpicon from "../../../Utils/Icons/hpicon.svg";
import { footerLinks } from "../../../Utils/ApiHelper";
import { string } from "../../../Localization";
import { SUPPORTED_COUNTRY } from "../../../Utils/Constants";

const Footer = (isLandingPage) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [links, setLinks] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1110);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    footerLinks(SUPPORTED_COUNTRY).then((res) => {
      setLinks(res.data);
    });
  }, []);

  const consumer_legal_guarantee =
    "https://support.hp.com/gb-en/document/c03922002";
  return (
    <>
      <div className="footer_container">
        <div className="footer">
          <div className="footer1">
            <div className="region">
              {string.COUNTRY} / {string.REGION}:
            </div>
            <img className="flag" alt="" src={Flag} />
            <div className="country">{string.COUNTRY_NAME}</div>
          </div>
          {!isSmallScreen ? (
            <div
              className={
                isLandingPage?.isLandingPage
                  ? "footer2  landing_footer2 submit_order_margin"
                  : "footer2  submit_order_footer2 submit_order_margin"
              }
            >
              <a
                href={links["company-details"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="text">{string.COMPANY_DETAILS}</div>
              </a>
              <a
                href={links["terms-and-conditions"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="text">{string.TERMS_AND_CONDITION_FOOTER}</div>
              </a>
              <a
                href={consumer_legal_guarantee}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="text">{string.CONSUMER_LEGAL_GUARANTEE}</div>
              </a>
              <a
                href={links["consumer-terms-of-service"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="text">{string.RETURNS_POLICY}</div>
              </a>
              <a
                href={links["privacy-statement"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="text">{string.ONLINE_PRIVACY_STATEMENT}</div>
              </a>
              <a
                href={links["personal-data-rights"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="text">{string.PERSONAL_DATA_RIGHTS_NOTICE}</div>
              </a>
              <a
                href={links["cookies"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="text" style={{ borderRight: "none" }}>
                  {string.COOKIES}
                </div>
              </a>
            </div>
          ) : (
            <div
              className={
                isLandingPage?.isLandingPage
                  ? "footer2  landing_footer2"
                  : "footer2  submit_order_footer2"
              }
            >
              <div className="d-flex footer2-content">
                <a
                  href={links["company-details"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className={
                      isLandingPage?.isLandingPage
                        ? "text"
                        : "text_small_screen"
                    }
                  >
                    {string.COMPANY_DETAILS}
                    <span className="footer-right-left-border"></span>
                  </div>
                </a>
                <a
                  href={links["terms-and-conditions"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className={
                      isLandingPage?.isLandingPage
                        ? "text"
                        : "text_small_screen"
                    }
                  >
                    {string.TERMS_AND_CONDITION_FOOTER}
                  </div>
                </a>
              </div>
              <div className="d-flex footer2-content">
                <a
                  href={consumer_legal_guarantee}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className={
                      isLandingPage?.isLandingPage
                        ? "text"
                        : "text_small_screen"
                    }
                  >
                    {string.CONSUMER_LEGAL_GUARANTEE}
                    <span className="footer-right-left-border"></span>
                  </div>
                </a>
                <a
                  href={links["consumer-terms-of-service"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className={
                      isLandingPage?.isLandingPage
                        ? "text"
                        : "text_small_screen"
                    }
                  >
                    {string.RETURNS_POLICY}
                  </div>
                </a>
              </div>
              <div className="d-flex footer2-content">
                <a
                  href={links["privacy-statement"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className={
                      isLandingPage?.isLandingPage
                        ? "text"
                        : "text_small_screen"
                    }
                  >
                    {string.ONLINE_PRIVACY_STATEMENT}
                  </div>
                </a>
              </div>
              <div className="d-flex footer2-content">
                <a
                  href={links["personal-data-rights"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className={
                      isLandingPage?.isLandingPage
                        ? "text"
                        : "text_small_screen"
                    }
                  >
                    {string.PERSONAL_DATA_RIGHTS_NOTICE}
                    <span className="footer-right-left-border"></span>
                  </div>
                </a>
                <a
                  href={links["cookies"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className={
                      isLandingPage?.isLandingPage
                        ? "text"
                        : "text_small_screen"
                    }
                    style={{ borderRight: "none" }}
                  >
                    {string.COOKIES}
                  </div>
                </a>
              </div>
            </div>
          )}
          <div className="footer3">
            <img className="hp_img" src={hpicon} alt="hp logo" />
            <div>{string.FOOTER_COPYRIGHT}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
