export const Icons = {
  arrowDown: require("./arrow-down.svg"),
  arrowUp: require("./arrow-up.svg"),
  arrow_back_ios_new: require("./arrow_back_ios_new.png"),
  back: require("./back.png"),
  blockchainLogo: require("./blockchain_logo.png"),
  copy: require("./copy.svg"),
  copyRight: require("./CopyrightText.png"),
  checked: require("./checked.png"),
  checkmark: require("./checkmark.png"),
  download: require("./download.svg"),
  downArrow: require("./down-arrow.png"),
  defaultIcon: require("./no-preview.png"),
  digitalPattern: require("./digital_pattern.png"),
  edit: require("./edit.svg"),
  eye: require("./eye_icon.svg"),
  framed: require("./framed.png"),
  filterIcon: require("./filter.png"),
  fit: require("./fit.png"),
  grid: require("./grid.png"),
  gridDisabled: require("./grid-disabled.png"),
  hpLogo: require("./hp_logo_black.png"),
  hpLogoSideway: require("./hp_logo_black_sideway.png"),
  jpgDefault: require("./jpgIcon.png"),
  logo: require("./Logo.png"),
  newWindow: require("./new-window.png"),
  photo_rag_baryta: require("./media_type_3.png"),
  plus: require("./plus.svg"),
  preview: require("./preview.svg"),
  previewObj1: require("./preview_obj1.svg"),
  previewObj2: require("./preview_obj2.svg"),
  printedPattern: require("./printed-pattern.png"),
  package1: require("./package1.png"),
  package2: require("./package2.png"),
  pdfIcon: require("./pdf-icon.png"),
  createNewIcon: require("./plus_circle.png"),
  plusDisabled: require("./plus-disabled.svg"),
  qrCode: require("./qr-code-new.png"),
  resetZoom: require("./reset-zoom.png"),
  signature: require("./signature.png"),
  smooth_matte: require("./media_type_1.png"),
  shippingView: require("./shipping-view.svg"),
  tile: require("./tile.png"),
  tileDisabled: require("./tile-disabled.png"),
  tiffIcon: require("./tiff-icon.png"),
  unchecked: require("./unchecked.png"),
  unframed: require("./unframed.png"),
  velvet: require("./media_type_2.png"),
};
