import React from "react";
import { Modal } from "react-bootstrap";
import "./StatusModal.scss";
import closebtn from "../../Utils/Icons/close.svg";
import { string } from "../../Localization";

const StatusModal = (props) => {
  const {
    canShow,
    stautsbackground,
    statusimge,
    statusHeading,
    statusDescription,
    closebackground,
    closeData,
    isCloseVisible,
    closeBtnFtn,
    width,
    subHeadingWidth,
    enableCopyLink,
    copyLink,
  } = props;

  const { closeBtn, closeHandler } = closeData;

  return (
    <>
      <div>
        <div className="dialog-container">
          <Modal show={canShow} className="dialog-container-status">
            <Modal.Body
              className="status-modal-container"
              style={stautsbackground}
            >
              {isCloseVisible && (
                <div className="close-modal-btn">
                  <img
                    src={closebtn}
                    onClick={closeBtnFtn}
                    style={{ cursor: "pointer" }}
                    alt="close"
                  />
                </div>
              )}

              <div className="status-modal-content">
                <img alt="status" src={statusimge} />
                <div
                  className="status-modal-heading"
                  style={{ width: width ? width : "" }}
                >
                  {statusHeading}
                </div>
                <div
                  className="status-modal-description"
                  style={{ width: subHeadingWidth ? subHeadingWidth : "" }}
                >
                  {statusDescription}
                </div>
                {enableCopyLink && (
                  <div className="share-link-copy">
                    <div className="copy-link">{copyLink}</div>
                    <div
                      className="copy-button"
                      onClick={() => {
                        navigator.clipboard.writeText(copyLink);
                        closeHandler();
                      }}
                    >
                      {string.COPY}
                    </div>
                  </div>
                )}
                <button
                  className="status-modal-cancel"
                  style={closebackground}
                  onClick={() => {
                    closeHandler();
                  }}
                >
                  {closeBtn}
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default StatusModal;
