import React from "react";
import { useDispatch, useSelector } from "react-redux";
import BasicModal from "../../../Components/Modal/BasicModal/BasicModal";
import StatusModal from "../../../Components/StatusModal/StatusModal";
import status_right from "../../../Utils/Icons/status_right.svg";
import { string } from "../../../Localization";
import { showOrHideApiLoadingModal } from "../../../Store/Project/ModalSlice";
import { setCanShowStatusModal } from "../../../Store/Project/ProjectSlice";
import { startSelling } from "../../../Utils/ApiHelper";
import "./StartOrStopSelling.scss";

const StartSelling = (props) => {
  const { show, handleClose, projectToken, publicViewUrl, initializeGallery } =
    props;

  const { canShowStatusModal } = useSelector((state) => state.project);

  const dispatch = useDispatch();

  const handleStartSelling = () => {
    dispatch(showOrHideApiLoadingModal(true));
    startSelling(projectToken)
      .then(() => {
        dispatch(showOrHideApiLoadingModal(false));
        initializeGallery();
        dispatch(setCanShowStatusModal(true));
      })
      .catch(() => {
        dispatch(showOrHideApiLoadingModal(false));
      });
  };

  const closeHandler = () => {
    dispatch(setCanShowStatusModal(false));
    handleClose();
  };

  return (
    <>
      <BasicModal openModal={show} handleClose={handleClose}>
        <div className="start-stop-selling ">
          <div className="header">{string.START_SELLING_AGAIN}</div>
          <div className="description">
            {string.START_SELLING_TEXT1}
            <br />
            {string.START_SELLING_TEXT2}
          </div>

          <button
            className="basic-button start-stop-button"
            onClick={() => handleStartSelling()}
          >
            {string.START_SELLING}
          </button>
        </div>
      </BasicModal>
      <StatusModal
        canShow={canShowStatusModal}
        stautsbackground={{ background: "#176267" }}
        statusimge={status_right}
        statusHeading={string.CONGRATULATIONS}
        statusDescription={string.YOUR_PHOTOGRAPH_IS_LIVE}
        closebackground={{ background: "#176267" }}
        closeData={{
          closeBtn: string.CLOSE,
          closeHandler: closeHandler,
        }}
        enableCopyLink={true}
        copyLink={publicViewUrl}
      />
    </>
  );
};

export default StartSelling;
