import React, { useEffect, useState } from "react";
import "./AdminTables.scss";
import Pagination from "./Pagination/Pagination";
import AdminSearchBar from "../AdminSearchBar/AdminSearchBar";
import {
  setPage,
  setUserColumnKey,
  setUserTypeKey,
} from "../../../Store/Project/AdminSlice";
import { useDispatch, useSelector } from "react-redux";
import { RetrieveProject } from "../../../Utils/ApiHelper";
import { PAGE_SIZE } from "../../../Utils/Constants";
import ProjectLoader from "../../../Components/Loaders/ProjectLoader/ProjectLoader";
import { showOrHideApiLoadingModal } from "../../../Store/Project/ModalSlice";
import { string } from "../../../Localization";
import LimitedEditionUserTable from "./LimitedEditionUserTable";
import { useNavigate } from "react-router-dom";
import { showAdminSearchBox } from "../../../Utils/Helper";

const LimitedEditionTable = () => {
  const { userSearch, userColumnKey, userTypeKey, userSearchKey } = useSelector(
    (state) => state.admin
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [recordCount, setRecordCount] = useState(0);
  const [refresh, setRefresh] = useState(true);

  const options = [
    { value: 0, label: "Email" },
    { value: 1, label: "Artist Name" },
    { value: 2, label: "Title" },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(showOrHideApiLoadingModal(true));
    retriveProject();
  }, [
    userColumnKey,
    userTypeKey,
    currentPage,
    userSearch,
    userSearchKey,
    refresh,
  ]);

  const retriveProject = () => {
    RetrieveProject(
      currentPage - 1,
      PAGE_SIZE,
      userTypeKey,
      userColumnKey,
      userSearchKey,
      userSearch
    )
      .then((res) => {
        setData(res?.data?.data?.records_arr);
        setTotalPage(res?.data?.data?.total_pages);
        dispatch(showOrHideApiLoadingModal(false));
        setRecordCount(res?.data?.data?.records_count);
      })
      .catch((err) => {
        console.log(err);
        dispatch(showOrHideApiLoadingModal(false));
        if (err?.response?.status === 401) {
          navigate("/");
        }
      });
  };

  const totalCount = totalPage * PAGE_SIZE;

  return (
    <>
      <div className="admin_tables">
        <div className="d-flex  align-items-center">
          {showAdminSearchBox(userSearchKey, recordCount) && (
            <>
              <div className="admin_search_bar_container">
                <AdminSearchBar
                  setUserTypeKey={setUserTypeKey}
                  setUserColumnKey={setUserColumnKey}
                  options={options}
                />
              </div>
              <i
                className="fa refresh_load"
                onClick={() => {
                  setRefresh(!refresh);
                }}
              >
                &#xf021;
              </i>
            </>
          )}
        </div>
        {recordCount == 0 ? (
          <>
            <ProjectLoader />
            <div className="admin_user_back_btn">
              <div className="admin_no_user_details">
                <div className="order_no_user_details_text">
                  {string.NO_RECORDS_FOUND}{" "}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <LimitedEditionUserTable result={data} isLimitedEdition={true} />
            <div className="admin_pagination">
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={totalCount}
                pageSize={PAGE_SIZE}
                onPageChange={(page) => {
                  setCurrentPage(page);
                  dispatch(setPage(page));
                }}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default LimitedEditionTable;
