import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { string } from "../../../Localization";
import { getOrderInfo, updateOrder } from "../../../Utils/ApiHelper";
import OrderInfo from "../OrderInfo/OrderInfo";
import PriceInfo from "../PriceInfo/PriceInfo";
import CustomerInfo from "./CustomerInfo/CustomerInfo";
import dropdownIconDown from "../../../Utils/Icons/dropdown_arrow.svg";
import dropdownIconUP from "../../../Utils/Icons/dropdown_arrow_up.svg";
import "./ReviewOrder.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleEditAddressModal,
  toggleEditCustomerModal,
} from "../../../Store/Project/ModalSlice";
import EditInfoModal from "../../../Components/Modal/EditInfoModal/EditInfoModal";
import {
  sendGALoadedReviewPageWithOutCouponCode,
  sendGALoadedReviewPageWithValidCoupon,
} from "../../../Utils/GAHelper";
import PublicViewLoader from "../../../Components/Loaders/PublicViewLoader/PublicViewLoader";

const ReviewOrder = (props) => {
  const { order_token } = useParams();
  const navigate = useNavigate();

  const { setProjectSummaryData, projectSummaryData } = props;

  const { updatedCustomerInfo, isCouponUsed } = useSelector(
    (state) => state.order
  );
  const { showEditAddressModal, showEditCustomerModal } = useSelector(
    (state) => state.modal
  );

  const dispatch = useDispatch();

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [onSubmit, setOnSubmit] = useState(false);
  const [isOpenReviewOrder, setIsOpenReviewOrder] = useState(false);
  const [isCouponValid, setCouponValid] = useState(false);
  const [infoLoaded, setInfoLoaded] = useState(false);
  const [sendGAForTheFirstTime, setSendGAForTheFirstTime] = useState(true);

  useEffect(() => {
    setInfoLoaded(false);
    getOrderInfo(order_token).then((res) => {
      const response = res?.data?.data;

      setProjectSummaryData(response);
      setCouponValid(response?.order?.valid_coupon);
      if (sendGAForTheFirstTime) {
        if (response?.order?.valid_coupon) {
          sendGALoadedReviewPageWithValidCoupon(response?.user?.id);
          setSendGAForTheFirstTime(false);
        } else {
          sendGALoadedReviewPageWithOutCouponCode(response?.user?.id);
          setSendGAForTheFirstTime(false);
        }
      }

      setInfoLoaded(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedCustomerInfo]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1110);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const closeEditAddressModal = () => {
    dispatch(toggleEditAddressModal(false));
  };
  const closeEditCustomerModal = () => {
    dispatch(toggleEditCustomerModal(false));
  };

  const handleReviewOrderClick = () => {
    setIsOpenReviewOrder(!isOpenReviewOrder);
  };

  const editAddressDetails = () => {
    const payload = {
      country: "",
      city: "",
      postalCode: "",
      addressLine1: "",
    };
    updateOrder(order_token, payload)
      .then((res) => {
        setOnSubmit(false);
        navigate(`/web/orders/${order_token}/payment_success`);
      })
      .catch(() => {
        setOnSubmit(false);
        navigate(`/web/orders/${order_token}/payment_failure`);
      });
  };

  const project_token = projectSummaryData?.google_analytics?.project_token;

  return (
    <>
      <div className="review-order-block">
        {projectSummaryData?.project && (
          <div className="review-order-body">
            <div className={onSubmit ? "blur-background" : null}>
              <div className="review-order-title">
                {string.REVIEW_ORDER_FROM_HP}
              </div>
              {!isSmallScreen ? (
                <div className="review-order-description">
                  <OrderInfo
                    project={projectSummaryData.project}
                    isSmallScreen={isSmallScreen}
                    isReviewStep
                  />
                  <CustomerInfo
                    projectSummaryData={projectSummaryData}
                    orderToken={order_token}
                    isCouponUsed={isCouponUsed}
                  />
                  <PriceInfo
                    projectToken={project_token}
                    price={projectSummaryData.price}
                    orderData={{}}
                    isReviewStep
                    setAlertMessage={(e) => {}}
                    orderToken={order_token}
                    setOnSubmit={setOnSubmit}
                    reviewOrderCouponValid={isCouponValid}
                    infoLoaded={infoLoaded}
                    userId={projectSummaryData?.user?.id}
                  />
                </div>
              ) : (
                <>
                  <div
                    className={
                      isOpenReviewOrder
                        ? "dropdown-container top-bottom-border"
                        : "dropdown-container "
                    }
                  >
                    <div className="dropdown-button">
                      <button
                        className={`dropdown-option ${
                          isOpenReviewOrder ? "active" : ""
                        }`}
                        onClick={() => handleReviewOrderClick()}
                        style={{
                          background: isOpenReviewOrder ? "#EEEEEE" : "#ffffff",
                        }}
                      >
                        {string.REVIEW_ORDER}
                        {isOpenReviewOrder ? (
                          <img src={dropdownIconUP} alt="dropup" />
                        ) : (
                          <img src={dropdownIconDown} alt="dropdown" />
                        )}
                      </button>
                    </div>
                    {isOpenReviewOrder && (
                      <div className="review-order-description">
                        <OrderInfo
                          project={projectSummaryData.project}
                          isSmallScreen={isSmallScreen}
                          isReviewStep
                        />
                      </div>
                    )}
                  </div>

                  <div className="review-order-description">
                    <div className="address-container">
                      <CustomerInfo
                        projectSummaryData={projectSummaryData}
                        orderToken={order_token}
                        isCouponUsed={isCouponUsed}
                      />
                    </div>
                    <div className="seperator-price" />
                  </div>
                  <PriceInfo
                    projectToken={project_token}
                    orderToken={order_token}
                    price={projectSummaryData.price}
                    // orderData={orderData}
                    orderData={{}}
                    // alertMessage={alertMessage}
                    // addressLine1={addressLine1}
                    setAlertMessage={(e) => {}}
                    setProjectSummaryData={setProjectSummaryData}
                    setOnSubmit={setOnSubmit}
                    isReviewStep
                    reviewOrderCouponValid={isCouponValid}
                    infoLoaded={infoLoaded}
                    userId={projectSummaryData?.user?.id}
                  />
                </>
              )}
            </div>
            {onSubmit && <PublicViewLoader />}
          </div>
        )}
      </div>
      {showEditAddressModal && (
        <>
          <EditInfoModal
            title={string.EDIT_ADDRESS_DETAILS}
            isEditAddressModal={true}
            closeHandler={closeEditAddressModal}
            projectSummaryData={projectSummaryData}
          />
        </>
      )}
      {showEditCustomerModal && (
        <>
          <EditInfoModal
            title={string.EDIT_CUSTOMER_DETAILS}
            isEditCustomerModal={true}
            closeHandler={closeEditCustomerModal}
            projectSummaryData={projectSummaryData}
          />
        </>
      )}
    </>
  );
};

export default ReviewOrder;
