import React from "react";
import "./carousal.scss";

const Carousal = ({ activeIndex, data, className }) => {
  return (
    <>
      <section className="scrollinmg">
        {data.map((slide, index) => (
          <div
            key={index}
            className={index === activeIndex ? "slides active" : "inactive"}
          >
            <div>
              <div className="modal-body">
                <div className="guide-Main">
                  <div className="guide-Main-img">
                    <img alt="guide" className="guide-img" src={slide.images} />
                  </div>
                  <div
                    className={
                      slide.id === 4
                        ? "increase_width"
                        : "guide-text d-flex justify-content-center"
                    }
                  >
                    {slide.body}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>
    </>
  );
};

export default Carousal;
