import "./PhotoPrint.scss";

const PhotoPrint = (url) => {
  return (
    <div>
      <img className="photo-preview" src={url.preview_url} alt="preview"></img>
    </div>
  );
};

export default PhotoPrint;
