import { store } from "../Store/Store";
import { fabric } from "fabric";
import { createCanvas, renderImage } from "./CanvasDownload";
import { dataURLToBlob, getSelectedSizeInInches } from "./Helper";
import {
  HUMAN_IMAGE_HEIGHT,
  HUMAN_IMAGE_WIDTH,
  HUMAN_WIDTH_INCHES,
  IMAGE_ELEMENT_OFFSET,
  SOFA_IMAGE_HEIGHT,
  SOFA_IMAGE_WIDTH,
  SOFA_WIDTH_INCHES,
} from "./Constants";
import { uploadVisualRefImages } from "./ApiHelper";

export const getHumanViewSize = () => {
  const availablePreviewHeight = getAvailablePreviewHeight();
  const humanRenderHeight = availablePreviewHeight;
  const humanRenderWidth =
    humanRenderHeight * (HUMAN_IMAGE_WIDTH / HUMAN_IMAGE_HEIGHT);

  return { width: humanRenderWidth, height: humanRenderHeight };
};

export const getSofaViewSize = () => {
  // const availablePreviewWidth = getAvailablePreviewWidth();
  // const availablePreviewHeight = getAvailablePreviewHeight();

  // const sofaRenderWidth = availablePreviewWidth;
  // const sofaRenderHeight =
  // sofaRenderWidth * (SOFA_IMAGE_HEIGHT / SOFA_IMAGE_WIDTH);

  // const { selectedId } = store.getState().project.sizeSelectionDetails;

  // let pxToInchScale = sofaRenderHeight / SOFA_HEIGHT_INCHES;
  // const selectedSizeInInches = getSelectedSizeInInches(selectedId);
  // const totalHeightInInches = SOFA_HEIGHT_INCHES + selectedSizeInInches?.height;

  // pxToInchScale = availablePreviewHeight / totalHeightInInches;

  // return {
  //   width: SOFA_WIDTH_INCHES * pxToInchScale,
  //   height: SOFA_HEIGHT_INCHES * pxToInchScale,
  // };

  let heigth = window.innerHeight > 800 ? 190 : 0.18 * window.innerHeight;
  let width = heigth * (SOFA_IMAGE_WIDTH / SOFA_IMAGE_HEIGHT);
  return { width, heigth };
};

export const getAvailablePreviewWidth = () => {
  const sideViewContainer = document.getElementById("sideview-bar-wrapper");
  let sidebarWidth = 0;
  if (sideViewContainer) {
    sidebarWidth = sideViewContainer.clientWidth;
  }
  return window.innerWidth - sidebarWidth - IMAGE_ELEMENT_OFFSET - 200;
};

export const getAvailablePreviewHeight = () => {
  return window.innerHeight - 2 * 180;
};

export const setLabelPosition = (sofaRef, virtualButtons) => {
  const sofaBottom = sofaRef?.current?.getBoundingClientRect().bottom;
  const virtualButtonsTop =
    virtualButtons?.current?.getBoundingClientRect().top;

  let labelTop = 30;
  let labelBoxHeight = 40;
  let buttonbottom = 30;
  let bottonHeight = 32;
  const isOverLapping =
    sofaBottom + labelTop + labelBoxHeight > virtualButtonsTop;

  if (isOverLapping) {
    let gapAvailable = virtualButtonsTop - sofaBottom;

    if (gapAvailable < labelBoxHeight) {
      gapAvailable = window.innerHeight - sofaBottom;
      let availableSpace = gapAvailable - bottonHeight - labelBoxHeight;
      let space = availableSpace / 3;

      labelTop = space;
      buttonbottom = space;
    } else {
      labelTop = (gapAvailable - labelBoxHeight) / 2;
    }
  }

  const root = document.querySelector(":root");
  root.style.setProperty("--virtual-label-top", `${labelTop}px`);
  root.style.setProperty("--virtual-button-bottom", `${buttonbottom}px`);
};

export const sendVisualRefImages = () => {
  return new Promise((resolve) => {
    const { projectToken } = store.getState().project;
    const formData = new FormData();

    generateSofaRefImage().then((horizontalImage) => {
      let horizontalRefImage = dataURLToBlob(horizontalImage);
      formData.append("horizontalRefImage", horizontalRefImage);
      generateHumanRefImage().then((verticalImage) => {
        let verticalRefImage = dataURLToBlob(verticalImage);
        formData.append("verticalRefImage", verticalRefImage);
        uploadVisualRefImages(formData, projectToken).then(() => {
          resolve();
        });
      });
    });
  });
};

const totalWidthInPx = 900;
const totalHeightInPx = 900;
const refLabelGap = 30; //gap b/w ref( i.e sofa or Human) and dimension label
const previewRefGap = 30;

const addLabel = (
  canvas,
  refPositionY,
  refHeigntInPx,
  wrapperWidth,
  xOffset = 0
) => {
  const labelText = new fabric.Text(`76.4"`, {
    editable: false,
    left: xOffset + (wrapperWidth - 70) / 2 + 23,
    top: refPositionY + refHeigntInPx + refLabelGap + 12,
    selectable: false,
    hasControls: false,
    fontSize: 13,
    textAlign: "center",
    hasRotatingPoint: false,
    fill: "#bdbdbd",
    hoverCursor: "default",
  });

  const labelBorder = new fabric.Rect({
    fill: "#1a1e22",
    left: xOffset + (wrapperWidth - 70) / 2,
    top: refPositionY + refHeigntInPx + refLabelGap,
    height: 40,
    width: 70,
    stroke: "#bdbdbd",
    strokeWidth: 2,
    selectable: false,
    hasControls: false,
    borderColor: "black",
    hasRotatingPoint: false,
    hasBorders: true,
    hoverCursor: "default",
    rx: 10,
  });
  canvas.add(labelBorder);
  canvas.add(labelText);
};

const previewSizeInPx = (scale) => {
  const { selectedId } = store.getState().project.sizeSelectionDetails;
  const previewSizeInInches = getSelectedSizeInInches(parseInt(selectedId));
  const previewWidthInPx = previewSizeInInches.width * scale;
  const previewHeightInPx = previewSizeInInches.height * scale;
  return { previewWidthInPx, previewHeightInPx };
};

export const generateSofaRefImage = () => {
  return new Promise((resolve) => {
    const { horizontalRefImageUrl } = store.getState().cache;
    const canvas = createCanvas({
      width: totalWidthInPx,
      height: totalHeightInPx,
    });
    canvas.backgroundColor = "#1a1e22";

    const sofaWidthInPx = 600;
    const sofaHeigntInPx = 216;
    const sofaViewScale = sofaWidthInPx / SOFA_WIDTH_INCHES;

    const { previewWidthInPx, previewHeightInPx } =
      previewSizeInPx(sofaViewScale);

    const sofaPositionX = (totalWidthInPx - sofaWidthInPx) / 2;
    const previewPositionY =
      (totalHeightInPx -
        sofaHeigntInPx -
        previewHeightInPx -
        previewRefGap -
        refLabelGap) /
      2;
    const sofaPositionY = previewPositionY + previewHeightInPx + previewRefGap;

    renderImage(canvas, {
      imageSrc: horizontalRefImageUrl,
      coords: {
        width: sofaWidthInPx,
        height: sofaHeigntInPx,
        x: sofaPositionX,
        y: sofaPositionY,
      },
    }).then(() => {
      const { previewImage } = store.getState().project;

      renderImage(canvas, {
        imageSrc: previewImage,
        coords: {
          width: previewWidthInPx,
          height: previewHeightInPx,
          x: sofaPositionX + sofaWidthInPx / 2 - previewWidthInPx / 2,
          y: previewPositionY,
        },
      }).then(() => {
        addLabel(canvas, sofaPositionY, sofaHeigntInPx, totalWidthInPx);
        resolve(canvas.toDataURL("image/png"));
      });
    });
  });
};

export const generateHumanRefImage = () => {
  return new Promise((resolve) => {
    const { verticalRefImageUrl } = store.getState().cache;
    const canvas = createCanvas({
      width: totalWidthInPx,
      height: totalHeightInPx,
    });
    canvas.backgroundColor = "#1a1e22";

    const refWidthInPx = 197;
    const refHeigntInPx = 600;
    const refViewScale = refWidthInPx / HUMAN_WIDTH_INCHES;

    const { previewWidthInPx, previewHeightInPx } =
      previewSizeInPx(refViewScale);

    const previewPositionY = (totalHeightInPx - previewHeightInPx) / 2;
    const previewPositionX =
      (totalWidthInPx - previewWidthInPx - refWidthInPx - previewRefGap) / 2;
    const refPositionX = previewPositionX + previewWidthInPx + previewRefGap;
    const refPositionY = (totalHeightInPx - refHeigntInPx - refLabelGap) / 2;

    renderImage(canvas, {
      imageSrc: verticalRefImageUrl,
      coords: {
        width: refWidthInPx,
        height: refHeigntInPx,
        x: refPositionX,
        y: refPositionY,
      },
    }).then(() => {
      const { previewImage } = store.getState().project;

      renderImage(canvas, {
        imageSrc: previewImage,
        coords: {
          width: previewWidthInPx,
          height: previewHeightInPx,
          x: previewPositionX,
          y: previewPositionY,
        },
      }).then(() => {
        const xOffset = previewPositionX + previewWidthInPx + previewRefGap;
        addLabel(canvas, refPositionY, refHeigntInPx, refWidthInPx, xOffset);
        resolve(canvas.toDataURL("image/png"));
      });
    });
  });
};
