import imagesmode from "../Utils/Icons/imagesmodecropped.svg";
import man_2 from "../Utils/Icons/man_2.svg";
import chair from "../Utils/Icons/chair.svg";

export const STORE_ID = 886;
export const API_END_POINT = `${process.env.REACT_APP_BASE_URL}/api/v1`;
export const API_V2_END_POINT = `${process.env.REACT_APP_BASE_URL}/api/v2`;
export const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

export const STATUS_CODE_SUCCESS = 200;
export const STATUS_CODE_ACCEPTED = 202;
export const STATUS_CODE_SESSION_TIMED_OUT = 408;
export const STATUS_CODE_UNPROCESSABLE_ENTITY = 422;

export const ERROR = "ERROR";
export const FAILED = "FAILED";

export const CROP = "crop";
export const FLIP = "flip";

export const ONE_MB_IN_BYTES = 1048576;
export const FILE_UPLOAD_PROGRESS = "File upload is in progress";
export const PROCESSING = "Processing...";

export const DEFAULT_PROJECT_WIDTH = 144;
export const DEFAULT_PROJECT_HEIGHT = 96;

export const PAGE_HOME = 0;
export const PAGE_CREATE_PROJECT = 1;
export const PAGE_PROJECT_SUMMARY = 2;
export const PAGE_ORDER_DETAILS = 3;
export const PAGE_SHIPPING_DETAILS = 4;
export const PAGE_PHOTO_PRINT_DETAILS = 5;

export const PHOTOS_TAB = 1;
export const ORDER_TAB = 2;
export const FEEDS = 3;
export const MY_GALLERY = 4;

export const STEP_ONE_PHOTO_DETAILS = 1;
export const STEP_TWO_PERSONALISATION = 2;
export const STEP_THREE_CERTIFICATE = 3;

export const PAGE_SIZE_SELECTION = 9999;
export const PAGE_MEDIA_TYPE_SELECTION = 999;

export const PROJECT_PREVIEW_MODE_INITIAL = 0;
export const PROJECT_PREVIEW_MODE_SIZE_SELECTION = 1;
export const PROJECT_PREVIEW_MODE_CROPPED = 2;
export const PROJECT_PREVIEW_MODE_PERSONALIZATION = 3;
export const PROJECT_PREVIEW_MODE_CERTIFICATE = 4;

export const CERTIFICATE_CARD_WIDTH = 532;

export const BROWN = "#964B00";
export const BLACK = "#000000";
export const WHITE = "#e9eae9";

export const DEFAULT = 50;
export const MEDIUM = 30;
export const SMALL = 20;

export const FRAMED = "Framed";
export const UNFRAMED = "Unframed";

export const FRAME_COLORS = [
  { id: 2, name: "Black", value: BLACK },
  { id: 1, name: "Brown", value: BROWN },
  { id: 3, name: "White", value: WHITE },
];
export const FRAME_MATTINGS = [
  {
    id: 1,
    name: "Default",
    value: DEFAULT,
    styleClass: "color-item matting default-matting",
  },
  {
    id: 2,
    name: "Medium",
    value: MEDIUM,
    styleClass: "color-item matting medium-matting",
  },
  {
    id: 3,
    name: "Small",
    value: SMALL,
    styleClass: "color-item matting small-matting",
  },
];

export const FRAME_BORDER = 18;
export const SMOOTH_MATTE = "Smooth Matte 0.34mm";
export const VELVET = "Velvet 0.50mm";
export const PHOTO_RAG_BARYTA = "Photo Rag Baryta 0.50mm";

export const GALLERY_TITLE = "Gallery";
export const CREATE_NEW_PROJECT_TITLE = "Create a new project";
export const CHOOSE_IMAGE_SIZE_TITLE = "Choose your image size";
export const CHOOSE_MEDIA_TYPE_TITLE = "Choose your media type";
export const CHOOSE_FRAME_OPTION_TITLE = "Choose your framing option";
export const SIGN_YOUR_WORK_TITLE = "Sign your work";
export const SUMMARY_TITLE = "Summary";

export const PORTRAIT = "Portrait";
export const LANDSCAPE = "Landscape";
export const SQUARE = "Square";

export const STANDARD_PPI = 300;

export const LEFT_SIDEBAR_WIDTH = 380;
export const RIGHT_SIDEBAR_WIDTH = 520;
export const TOP_BAR_HEIGHT = 60;

export const TITLE_ID = "1";
export const DESCRIPTION_ID = "2";
export const ARTIST_NAME_ID = "3";
export const WEB_LINK_ID = "4";
export const QR_ID = "5";
export const SIGNATURE_ID = "6";
export const IMAGE_ELEMENT_OFFSET = 200;

export const HOME_ALERT_TEXT =
  "Your changes will be lost. Are you sure you want to leave this page?";
export const DELETE_ALERT_TEXT =
  "Are you sure you want to delete the following record?";
export const PRINT_ALERT_TEXT = "Are you sure you want to place an order?";
export const SIGN_OUT_ALERT_TEXT = "Are you sure you want to sign out?";
export const ORDER_PLACED_TEXT = "Order has been placed";
export const SOLD_OUT_TEXT =
  "This order is limited edition order.\nThis cannot be printed anymore.";

export const IMAGE_UPLOAD_API = "Image Upload API";
export const POST_API = "Post API";

//SignYourWork radio buttons
export const PROFILE = "profile";
export const UPLOAD = "upload";
export const DRAW = "draw";

//Tooltip messages
export const TOOLTIP_PRINT_MESSAGE =
  "Send the print to HP PSP Network for local printing";
export const TOOLTIP_DELETE_MESSAGE =
  "Deleting only removes the project, does not delete on the Blockchain ledger";
export const TOOLTIP_SIZE_SELECT_MESSAGE = "Please select a size";

export const CANVAS_PRINTS = "Canvas Prints";
export const METAL_PRINTS = "Metal Prints";
export const PAPER_PRINTS = "Paper Prints";
export const PLASTIC = "Plastic";

export const SIGNATURE_CANVAS_WIDTH = 250;
export const SIGNATURE_CANVAS_HEIGHT = 100;

export const TERMS_AND_CONDITION_URL =
  "https://www.hp.com/us-en/terms-of-use.html";
export const PRIVACY_URL = "https://www8.hp.com/us/en/privacy/ww-privacy.html";
export const COOKIE_URL =
  "https://www.hp.com/us-en/privacy/use-of-cookies.html";

export const AnimationType = {
  CIRCULAR: "circular",
  GIF_START: "gif_start",
  GIF_END: "gif_end",
};

export const INCHES_UNIT = "″";

export const PDF = "pdf";
export const TIFF = "tiff";
export const TIF = "tif";
export const PNG = "png";
export const SVG = "svg";
export const JPG = "jpg";
export const JPEG = "jpeg";

export const TRUE = "true";
export const FALSE = "false";

export const SIGNATURE_WIDTH = 120;
export const SIGNATURE_HEIGHT = 50;

export const COPIES_WIDTH = 73;
export const COPIES_HEIGHT = 45;
export const DEFAULT_NO_OF_COPIES = 1;

export const SENT = "sent";
export const IN_PROCESS = "in_progress";
export const PRINTING = "printing";
export const CANCELLED = "cancelled";

export const SORT_AS_ASC = 0;
export const SORT_AS_DESC = 1;
export const SORT_BY_DATE = 0;
export const SORT_BY_NAME = 1;

export const PHOTO_PRINT = "PhotoPrint";
export const CERTIFICATE = "Certificate";
export const PACKAGING = "Packaging";

export const SIGNATURE = "signature";
export const NO_OF_COPIES = "noOfCopies";

export const BORDER_UNIT_VALUE_FIRST = 0;
export const BORDER_UNIT_VALUE_SECOND = 1;
export const BORDER_UNIT_VALUE_THIRD = 2;
export const BORDER_UNIT_VALUE_FOURTH = 3;
export const BORDER_UNIT_VALUE_FIFTH = 4;
export const BORDER_UNIT_VALUE_SIXTH = 5;

// export const BORDER_INCHES = [0, 0.5, 1, 1.5, 2, 2.5];
export const BORDER_INCHES = [0, 1, 2, 3, 4, 5];

export const BORDER_MM = [0, 10, 20, 30, 40, 50];

export const UNIT_SYSTEM_MM = "mm";
export const UNIT_SYSTEM_INCHES = "inches";

export const MM_TO_INCHES = 0.0393701;

export const PRICE_CURRENCY_GBP = "GBP";
export const PRICE_CURRENCY_DOLLAR = "Dollar";
export const PRICE_CURRENCY_GBP_ID = 3;
export const PRICE_CURRENCY_DOLLAR_ID = 1;

export const DESIGN_STEPS = ["Photo details", "Personalisation", "Certificate"];

export const SUBMIT_ORDER_STEP = 1;
export const REVIEW_STEP = 2;
export const PAYMENT_STEP = 3;

export const VIEW_PAGE_STEPS = [
  {
    id: SUBMIT_ORDER_STEP,
    name: "Your information",
  },
  {
    id: REVIEW_STEP,
    name: "Review order",
  },
  {
    id: PAYMENT_STEP,
    name: "Payment",
  },
];

export const ORDER_WITH_COUPON_STEPS = [
  {
    id: SUBMIT_ORDER_STEP,
    name: "Your information",
  },
  {
    id: REVIEW_STEP,
    name: "Review order",
  },
];
export const fontOptions = [
  {
    type: "Basic",
    family: "Open Sans",
  },
  {
    type: "Condensed",
    family: "Roboto Condensed",
  },
  {
    type: "Handwritting",
    family: "Mynerve",
  },
  {
    type: "Monospace",
    family: "Courier",
  },
  {
    type: "Montserrat",
    family: "Montserrat",
  },
  {
    type: "Serif",
    family: "PT Serif",
  },
];

export const RECENT_PROJECT_TYPE = "recent";
export const DRAFT_PROJECT_TYPE = "draft";
export const STOPPED_PROJECT_TYPE = "stopped";
export const SOLD_OUT_PROJECT_TYPE = "soldout";

export const BASIC_INFORMATION = "BasicInformation";
export const PROFILE_SIGNATURE = "Signature";
export const PROFILE_LOGOTYPE = "Logotype";
export const PUBLIC_GALLERY = "PublicGallery";

export const PROJECT_TYPES = [
  RECENT_PROJECT_TYPE,
  DRAFT_PROJECT_TYPE,
  STOPPED_PROJECT_TYPE,
  SOLD_OUT_PROJECT_TYPE,
];

export const NORMAL_VIEW = "normal";
export const HUMAN_VIEW = "person";
export const SOFA_VIEW = "sofa";

export const HUMAN_HEIGHT_INCHES = 68.5;
export const HUMAN_WIDTH_INCHES = 22.5;
export const SOFA_WIDTH_INCHES = 76.4;
export const SOFA_HEIGHT_INCHES = 26.28;

export const VIEW_TYPES = [
  { name: NORMAL_VIEW, url: imagesmode },
  { name: HUMAN_VIEW, url: man_2 },
  { name: SOFA_VIEW, url: chair },
];

export const ORDER_TOKEN = "dimension3";
export const PROJECT_TOKEN = "dimension2";
export const USER_ID = "dimension1";
export const USER_PHOTO_TOKEN = "dimension4";

export const MAXIMUM_CANVAS_SIZE = 3000;

export const SOFA_IMAGE_WIDTH = 689;
export const SOFA_IMAGE_HEIGHT = 237;

export const HUMAN_IMAGE_WIDTH = 198;
export const HUMAN_IMAGE_HEIGHT = 603;

export const LANDINGPAGE_ACCORDIAN1 = 1;
export const LANDINGPAGE_ACCORDIAN2 = 2;
export const LANDINGPAGE_ACCORDIAN3 = 3;
export const LANDINGPAGE_ACCORDIAN4 = 4;

export const SECTION_INFO = 0;
export const SECTION_PAPER = 1;
export const SECTION_PRINT_SIZE = 2;
export const SECTION_BORDER = 3;
export const SECTION_PRICING = 4;
export const SECTION_SIGNATURE = 5;
export const SECTION_EDITION = 6;
export const SECTION_CERTIFICATE = 7;

export const HANDLE_DRAFT_GALLERY = 1;
export const HANDLE_DRAFT_SIDEVIEWBAR = 2;
export const SUPPORTED_COUNTRY = "GB";

export const MAX_SIZE_IN_BYTES = 419430400; //400mb
export const MAX_SIZE_AI_IN_BYTES = 4194304; //4mb

export const MAX_PROFILE_PHOTO_SIZE_IN_BYTES = 4 * 1024 * 1024;

export const MISSING_ICC_PROFILE = "/icc_profiles/original/missing.png";

export const EMAIL_SORT = 0;
export const FIRST_NAME_SORT = 1;
export const LAST_NAME_SORT = 2;
export const COMPANY_SORT = 3;
export const TOKEN = 1;
export const ARTIST_NAME_SORT = 1;
export const TITLE_SORT = 2;
export const PHONE_NUMBER = 3;

export const USERS_TAB = 0;
export const LIMITED_OPEN_TAB = 1;
export const ORDERS_TAB = 2;
export const SALES_TAB = 3;
export const WEB_PRINT_ORDERS_TAB = 4;
export const METRICS_TAB = 5;
export const USAGE_SUMMARY = 6;
export const NFT_TAB = 7;

export const ORDER_ID = 0;
export const ASCENDING_ORDERS = 0;
export const DESCENDING_ORDERS = 1;

export const PAGE_SIZE = 10;

export const ADMIN_NAV_BAR = 0;
export const ORDER_DETAILS_PAGE = 1;
export const PROJECT_ORDERS_PAGE = 2;
export const USER_PROJECT_PAGE = 3;
export const REPORT_DATE = 0;

export const TAG = 0;
export const STATE = 1;
export const PROJECT_ID = 2;

export const PRINTED_CERTIFICATE = "printed_certificate";
export const LANDING_PAGE = "limitededition_design";
export const PUBLIC_VIEW = "photo_print_details";

export const GALLERY_TYPE_PUBLIC = "public";
export const GALLERY_TYPE_PRIVATE = "private";
export const GALLERY_TYPE_DEFAULT = "none";

export const MAXIMUM_SUPER_RESOLUTION_SCALE = 5;

export const OPENSEA = "opensea";
