import React, { useContext } from "react";
import Dropdown from "../Dropdown/Dropdown";
import {
  setFontFamily,
  setNoOfCopies,
} from "../../../Store/Project/limitedEditionSlice";
import { updateFontFamilyInCanvas } from "../../../Utils/PersonalizationHelper";
import { useDispatch } from "react-redux";
import { GlobalContext } from "../../../Context/GlobalState";
import { string } from "../../../Localization";
import { sendGAAddedEditionInPersonalization } from "../../../Utils/GAHelper";

const CopyToPrint = (props) => {
  const { croppedImageCanvas } = useContext(GlobalContext);

  const {
    noOfCopies,
    font,
    canShowNoOfCopies,
    isLimited,
    setCanShowNoOfCopies,
  } = props;

  const dispatch = useDispatch();

  const fontFamilyIsChanged = (inFont) => {
    dispatch(
      setFontFamily({
        type: inFont.type,
        family: inFont.family,
      })
    );
    updateFontFamilyInCanvas(croppedImageCanvas, inFont.type);
  };

  return (
    <div className="option1-description">
      {isLimited && (
        <div className="text-box">
          <label htmlFor="" className="text-label">
            {string.COPIES}
          </label>
          <input
            type="number"
            className="input-title"
            maxlength="3"
            pattern="\d*"
            value={noOfCopies}
            placeholder={1}
            onChange={(e) => {
              if (
                /^\d*$/.test(e.target.value) &&
                e.target.value >= 0 &&
                e.target.value <= 100 &&
                e.target.value.length < 4
              ) {
                dispatch(setNoOfCopies(e.target.value));
              }
            }}
          />
        </div>
      )}

      <div className="text-description">
        {isLimited && (
          <>
            {string.LIMITED_EDITIONS_TEXT_1}
            <br /> {string.LIMITED_EDITIONS_TEXT_2}
          </>
        )}
      </div>
      <div className="toggle-option">
        <div className="toggle-desc">
          {string.ADD_EDITION_COPY_NUMBER_TO_PRINT}
        </div>
        <div className="toggle-btn">
          <label className="switch">
            <input
              type="checkbox"
              checked={canShowNoOfCopies}
              onChange={() => {
                dispatch(setCanShowNoOfCopies(!canShowNoOfCopies));
                sendGAAddedEditionInPersonalization();
              }}
            />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
      <div
        className="LE-dropdown"
        style={{ opacity: canShowNoOfCopies ? 1 : 0 }}
      >
        <label htmlFor="">{string.FONT_TYPE}</label>
        <Dropdown selected={font} setSelected={fontFamilyIsChanged} />
      </div>
    </div>
  );
};

export default CopyToPrint;
