import { createSlice } from "@reduxjs/toolkit";
import {
  PAGE_HOME,
  PHOTOS_TAB,
  STEP_ONE_PHOTO_DETAILS,
} from "../../Utils/Constants";

const initialState = {
  activePageIndex: PAGE_HOME,
  showSidebar: false,
  showGuideModal: true,
  activeTab: PHOTOS_TAB,
  activeStep: STEP_ONE_PHOTO_DETAILS,
};

export const pageSlice = createSlice({
  name: "activePageIndex",
  initialState: initialState,
  reducers: {
    setActivePageIndex(state, action) {
      state.activePageIndex = action.payload;
    },
    toggleSidebar(state) {
      state.showSidebar = !state.showSidebar;
    },
    setShowGuideModal(state, action) {
      state.showGuideModal = action.payload;
    },
    setActiveTab(state, action) {
      state.activeTab = action.payload;
    },
    setActiveStep(state, action) {
      state.activeStep = action.payload;
    },
  },
});

export const {
  setActivePageIndex,
  toggleSidebar,
  setShowGuideModal,
  setActiveTab,
  setActiveStep,
} = pageSlice.actions;
export default pageSlice.reducer;
