import Preview from "../Preview/Preview";
import React from "react";
import { FEEDS, PAGE_HOME, PHOTOS_TAB } from "../../Utils/Constants";
import { useSelector } from "react-redux";
import Gallery from "../Gallery/Gallery";
import NavOrderManagement from "../Preview/OrderManagement/NavOrderManagement";
import ProfileButton from "../ProfileButton/ProfileButton";
import "./Body.scss";
import InternalFeed from "../Admin/InternalFeed/InternalFeed";

const Body = (props) => {
  const { onLoadingComplete } = props;
  const { activePageIndex, activeTab } = useSelector((state) => state.page);

  const handleGalleryLoadingComplete = () => {
    onLoadingComplete(false);
  };

  let divSet = null;

  if (activePageIndex === PAGE_HOME) {
    divSet = (
      <div className="w-100">
        <div className="w-100 tab-scroll">
          {activeTab === PHOTOS_TAB ? (
            <>
              <div className="align-profile">
                <ProfileButton />
              </div>

              <Gallery
                onGalleryLoadingComplete={handleGalleryLoadingComplete}
              />
            </>
          ) : activeTab === FEEDS ? (
            <>
              {" "}
              <div className="align-profile">
                <ProfileButton />
              </div>
              <InternalFeed />
            </>
          ) : (
            <NavOrderManagement />
          )}
        </div>
      </div>
    );
  } else {
    divSet = <Preview />;
  }

  return divSet;
};

export default Body;
