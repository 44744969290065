import React, { useEffect, useState } from "react";
import "./AddPersonalMessage.scss";
import LogoHP from "../../Components/LogoHP/LogoHP";
import { useParams } from "react-router-dom";
import {
  footerLinks,
  generatePersonalNote,
  getOrderInfo,
  updatePersonalMessage,
} from "../../Utils/ApiHelper";
import { string } from "../../Localization";
import { setCanShowAlertModal } from "../../Store/Project/ProjectSlice";
import { useDispatch, useSelector } from "react-redux";
import DialogBox from "../../Containers/DialogBox/DialogBox";
import footerLogo from "../../Utils/Icons/footer-logo.svg";
import {
  sendGAAddPersonalPageUsingAI,
  sendGALoadedAddPersonalNotePage,
  sendGASendPersonalMessage,
} from "../../Utils/GAHelper";
import { showOrHideApiLoadingModal } from "../../Store/Project/ModalSlice";
import ProjectLoader from "../Loaders/ProjectLoader/ProjectLoader";
import SearchByAIError from "../Modal/SearchByAiError/SearchByAiError";
import { SUPPORTED_COUNTRY } from "../../Utils/Constants";

const AddPersonalMessage = () => {
  const { canShowAlertModal } = useSelector((state) => state.project);
  const { order_token, sec_token } = useParams();

  const dispatch = useDispatch();

  const [orderDetails, setOrderDetails] = useState([]);
  const [personalMessage, setPersonalMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [links, setLinks] = useState("");
  const [error, setError] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    footerLinks(SUPPORTED_COUNTRY).then((res) => {
      setLinks(res?.data);
    });

    getOrderInfo(order_token)
      .then((res) => {
        const response = res.data.data;
        const userId = response?.user?.id;
        const projectToken = response?.google_analytics?.project_token;
        const orderToken = response?.google_analytics?.order_token;

        sendGALoadedAddPersonalNotePage(userId, projectToken, orderToken);
        setOrderDetails(response);
        setPersonalMessage(response?.order?.personal_message);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalOpen = () => {
    dispatch(setCanShowAlertModal(true));
    setIsOpen(true);
  };

  const handleModalClose = () => {
    dispatch(setCanShowAlertModal(false));

    setIsOpen(false);
  };

  const toggleDescriptionEdit = () => {
    if (personalMessage) {
      const formData = new FormData();
      formData.append("personal_message", personalMessage);
      updatePersonalMessage(order_token, sec_token, formData)
        .then((res) => {
          setOrderDetails(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handelMessageSend = () => {
    toggleDescriptionEdit();
    sendGASendPersonalMessage(order_token, orderDetails?.user?.id);
    setIsOpen(false);
  };

  const handlePersonalNoteAI = () => {
    dispatch(showOrHideApiLoadingModal(true));
    sendGAAddPersonalPageUsingAI(order_token, orderDetails?.user?.id);
    generatePersonalNote(order_token)
      .then((res) => {
        const response = res?.data?.data;
        const formattedResponse = response.replace(/\\n/g, "\n");
        setPersonalMessage(formattedResponse);
        dispatch(showOrHideApiLoadingModal(false));
      })
      .catch((error) => {
        dispatch(showOrHideApiLoadingModal(false));
        setError(error?.response?.data?.error_message);
      });
  };

  const closeHandler = () => {
    setError("");
  };
  return (
    <>
      <ProjectLoader />
      <div className="AddPersonalMessage-container ">
        <div className="AddPersonalMessage-text">
          <div className="AddPersonalMessage-top-header">
            <LogoHP />
          </div>
          <div className="AddPersonalMessage_main container">
            <div className="AddPersonalMessage_left">
              <div className="AddPersonalMessage_img">
                <img
                  alt="preview"
                  className="AddPersonal_image"
                  src={orderDetails?.project?.preview}
                />
              </div>
              <div className="AddPersonalMessage_info spacing">
                <div className="order-title">
                  {string.GENERAL_INFO_ABOUT_THE_PRINT}
                </div>
                <div className="order-desc-block">
                  <div className="d-flex order-desc">
                    <div className="order-desc-text order-title">
                      {string.PHOTO_TITLE}
                    </div>
                    <div className="order-desc-value">
                      {orderDetails?.project?.title}
                    </div>
                  </div>
                  <div className="d-flex order-desc">
                    <div className="order-desc-text">{string.PRINT_SIZE}:</div>
                    <div className="order-desc-value">
                      {orderDetails?.project?.print_size}
                    </div>
                  </div>
                  <div className="d-flex order-desc">
                    <div className="order-desc-text">{string.PHOTO_SIZE}</div>
                    <div className="order-desc-value">
                      {orderDetails?.project?.photo_size}
                    </div>
                  </div>
                  <div className="d-flex order-desc">
                    <div className="order-desc-text">{string.PAPER_TYPE}</div>
                    <div className="order-desc-value">
                      {orderDetails?.project?.media_type_name}
                    </div>
                  </div>
                  <div className="d-flex order-desc">
                    <div className="order-desc-text">{string.EDITION}:</div>
                    <div className="order-desc-value">
                      {orderDetails?.order?.edition}
                    </div>
                  </div>
                </div>
              </div>
              <div className="AddPersonalMessage_info ">
                <div className="order-title">{string.CUSTOMER_INFORMATION}</div>
                <div className="order-desc-block">
                  <div className="d-flex order-desc">
                    <div className="order-desc-text order-title">
                      {string.NAME_1}:
                    </div>
                    <div className="order-desc-value">
                      {orderDetails?.order?.customer_name}
                    </div>
                  </div>
                  <div className="d-flex order-desc">
                    <div className="order-desc-text">
                      {string.CITY_AND_COUNTRY}:
                    </div>
                    <div className="order-desc-value">
                      {orderDetails?.order?.address?.city},{" "}
                      {orderDetails?.order?.address?.country}
                    </div>
                  </div>
                  <div className="d-flex order-desc">
                    <div className="order-desc-text">
                      {string.ORDER_NUMBER_1}:
                    </div>
                    <div className="order-desc-value">
                      {orderDetails?.order?.id}
                    </div>
                  </div>
                  <div className="d-flex order-desc">
                    <div className="order-desc-text">
                      {string.ORDER_DATE_AND_TIME}:
                    </div>
                    <div className="order-desc-value order-desc-value-2 ">
                      {orderDetails?.order?.order_date_time}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="seperator"></div>
            <div className="AddPersonalMessage_bottom">
              <div className="AddPersonalMessage_textbox">
                <div className="text-button-container">
                  <div>
                    <div className="info-title d-flex">
                      {string.PERSONAL_MESSAGE_FOR_CUSTOMER}
                    </div>
                    {orderDetails?.order?.can_update_personal_message ? (
                      <div className="info-field-key">
                        {string.YOU_STILL_HAVE}&nbsp;
                        {orderDetails?.order.time_remaining}&nbsp;
                        {string.TO_LEAVE_THE_MESSAGE}
                      </div>
                    ) : null}
                  </div>
                  {orderDetails?.order?.can_update_personal_message &&
                    orderDetails?.user?.open_ai && (
                      <div className="ai-button-container">
                        <button
                          onClick={() => {
                            handlePersonalNoteAI();
                          }}
                          className="ai-button"
                        >
                          {string.GENERATE_PERSONAL_NOTE_THROUGH_AI}
                        </button>
                      </div>
                    )}
                </div>
                {orderDetails?.order?.can_update_personal_message ? (
                  <div>
                    <div className="d-flex">
                      <textarea
                        className="input-description-enabled"
                        rows="5"
                        cols="33"
                        value={personalMessage}
                        placeholder={string.TYPE_A_MESSAGE_HERE}
                        onChange={(e) => setPersonalMessage(e.target.value)}
                        maxLength={600}
                      >
                        {personalMessage}
                      </textarea>
                    </div>
                    <div className="max-characters">
                      {string.MAXIMUM_CHARACTERS}: 600
                    </div>
                    <div className="button-container">
                      <button
                        onClick={() => {
                          personalMessage && handleModalOpen();
                        }}
                        className={`save-button ${
                          personalMessage ? "enable" : "disable"
                        }`}
                      >
                        {string.SUBMIT}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex">
                    <div className="py-2 description input-description-disabled">
                      {personalMessage === "" ? (
                        "-"
                      ) : (
                        <div className="final-personal-message">
                          {personalMessage}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="error-page-copyright">
          <div className="sub-container-footer">
            <div className="error-page-footer-img">
              <img
                src={footerLogo}
                alt="hp footer logo"
                className="error-page-footer-logo"
              />
            </div>
            <div className="error-page-footer-copyright">
              {string.HP_DEVELOPMENT_COMPANY_FOOTER}
            </div>
          </div>
          <div className="addPersonalMessage-footer-right">
            <a
              href={links["terms-and-conditions"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="addPersonalMessage-footer-text">
                {string.TERMS_AND_CONDITION_FOOTER}
              </div>
            </a>
            <a
              href={links["personal-data-rights"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="addPersonalMessage-footer-text">
                {string.PERSONAL_DATA_RIGHTS_NOTICE}
              </div>
            </a>
            <a
              href={links["cookies"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className="addPersonalMessage-footer-text"
                style={{ border: "none" }}
              >
                {string.COOKIES}
              </div>
            </a>
          </div>
        </div>
      </div>
      {isOpen && (
        <DialogBox
          customStyles={{
            width: windowWidth < 597 ? "100%" : "",
          }}
          canShow={canShowAlertModal}
          heading={string.PERSONAL_MESSAGE}
          text1={string.PERSONAL_MESSAGE_WARNING}
          submitData={{
            submitText: string.SEND,
            submitHandler: handelMessageSend,
            cancelBtn: string.CANCEL,
            cancelHandler: handleModalClose,
          }}
        />
      )}
      <SearchByAIError
        isOpen={error}
        onClose={closeHandler}
        errorMessage={error}
      />
    </>
  );
};

export default AddPersonalMessage;
