import React, { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import "./AdminTables.scss";
import Pagination from "./Pagination/Pagination";
import AdminSearchBar from "../AdminSearchBar/AdminSearchBar";
import { RetrieveMulesoftOrders } from "../../../Utils/ApiHelper";
import { useDispatch, useSelector } from "react-redux";
import {
  setSalesOrderModal,
  showOrHideApiLoadingModal,
} from "../../../Store/Project/ModalSlice";
import { Icons } from "../../../Utils/Icons";
import StatusModal from "../../../Components/StatusModal/StatusModal";
import { setCanShowBackAlertModal } from "../../../Store/Project/ProjectSlice";
import status_right from "../../../Utils/Icons/status_right.svg";
import { string } from "../../../Localization";
import ProjectLoader from "../../../Components/Loaders/ProjectLoader/ProjectLoader";
import {
  ASCENDING_ORDERS,
  DESCENDING_ORDERS,
  ORDER_DETAILS_PAGE,
  ORDER_ID,
  PAGE_SIZE,
  TOKEN,
} from "../../../Utils/Constants";
import {
  setActivePageIndex,
  setOrderToken,
  setPage,
  setUserColumnKey,
  setUserTypeKey,
} from "../../../Store/Project/AdminSlice";
import AdminViewModal from "../../../Components/Modal/AdminViewModal/AdminViewModal";
import { useNavigate } from "react-router-dom";
import { showAdminSearchBox } from "../../../Utils/Helper";

const SalesTable = () => {
  const { userSearch, userColumnKey, userTypeKey, userSearchKey, page } =
    useSelector((state) => state.admin);
  const { CanShowBackAlertModal } = useSelector((state) => state.project);
  const { salesOrderModal } = useSelector((state) => state.modal);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [jsonStringData, setJsonStringData] = useState([]);
  const [subHeading, setSubHeading] = useState("");
  const [createdAtSort, setCreatedAtSort] = useState(true);
  const [orderIdSort, setOrderIdSort] = useState(true);
  const [tokenSort, setTokenSort] = useState(true);
  const [recordCount, setRecordCount] = useState(0);
  const [refresh, setRefresh] = useState(true);

  const options = [
    { value: "order_id", label: "Order ID" },
    { value: "token", label: "Token" },
  ];

  useEffect(() => {
    dispatch(showOrHideApiLoadingModal(true));
    RetrieveMulesoftOrders(
      currentPage - 1,
      PAGE_SIZE,
      userTypeKey,
      userColumnKey,
      userSearchKey,
      userSearch
    )
      .then((res) => {
        // console.log(res);
        setData(res?.data?.data?.records_arr);
        setTotalPage(res?.data?.data?.total_pages);
        dispatch(showOrHideApiLoadingModal(false));
        setRecordCount(res?.data?.data?.records_count);
      })
      .catch((err) => {
        console.log(err);
        dispatch(showOrHideApiLoadingModal(false));
      });
  }, [
    userColumnKey,
    userSearch,
    userTypeKey,
    userSearchKey,
    currentPage,
    refresh,
  ]);

  const isExpanded = new Array(data.length).fill(false);

  const handleCopyClick = (jsonString) => {
    navigator.clipboard
      .writeText(jsonString)
      .then(() => {
        dispatch(setCanShowBackAlertModal(true));
      })
      .catch((error) => {
        console.error("Failed to copy JSON to clipboard:", error);
      });
  };

  const closeHandler = () => {
    dispatch(setCanShowBackAlertModal(false));
  };

  const totalCount = totalPage * PAGE_SIZE;
  const handleClose = () => {
    dispatch(setSalesOrderModal(false));
  };

  const handleViewClick = (jsonString) => {
    setJsonStringData(jsonString);

    dispatch(setSalesOrderModal(true));
  };

  return (
    <>
      <div className="admin_tables">
        <div className="d-flex  align-items-center">
          {showAdminSearchBox(userSearchKey, recordCount) && (
            <>
              <div className="admin_search_bar_container">
                <AdminSearchBar
                  setUserTypeKey={setUserTypeKey}
                  setUserColumnKey={setUserColumnKey}
                  options={options}
                />
              </div>
              <i
                className="fa refresh_load"
                onClick={() => {
                  setRefresh(!refresh);
                }}
              >
                &#xf021;
              </i>
            </>
          )}
        </div>
        {recordCount == 0 ? (
          <>
            <ProjectLoader />
            <div className="admin_user_back_btn">
              <div className="admin_no_user_details">
                <div className="order_no_user_details_text">
                  {string.NO_RECORDS_FOUND}{" "}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="admin_tables_wrapper">
              <ProjectLoader />

              <table className="table table-borderless table_block">
                <thead className="table_head">
                  <tr>
                    <th>
                      #{" "}
                      {createdAtSort ? (
                        <div
                          className="admin_navBar_sort fa fa-angle-down"
                          onClick={() => {
                            dispatch(setUserColumnKey(""));
                            dispatch(setUserTypeKey(ASCENDING_ORDERS));
                            setCreatedAtSort(false);
                          }}
                        ></div>
                      ) : (
                        <div
                          className="admin_navBar_sort fa fa-angle-up"
                          onClick={() => {
                            dispatch(setUserColumnKey(""));
                            dispatch(setUserTypeKey(DESCENDING_ORDERS));
                            setCreatedAtSort(true);
                          }}
                        ></div>
                      )}
                    </th>
                    <th>
                      {string.ORDER_ID}
                      {orderIdSort ? (
                        <div
                          className="admin_navBar_sort fa fa-angle-down"
                          onClick={() => {
                            dispatch(setUserColumnKey(ORDER_ID));
                            dispatch(setUserTypeKey(ASCENDING_ORDERS));
                            setOrderIdSort(false);
                          }}
                        ></div>
                      ) : (
                        <div
                          className="admin_navBar_sort fa fa-angle-up"
                          onClick={() => {
                            dispatch(setUserColumnKey(ORDER_ID));
                            dispatch(setUserTypeKey(DESCENDING_ORDERS));
                            setOrderIdSort(true);
                          }}
                        ></div>
                      )}
                    </th>
                    <th>
                      {string.TOKEN}
                      {tokenSort ? (
                        <div
                          className="admin_navBar_sort fa fa-angle-down"
                          onClick={() => {
                            dispatch(setUserColumnKey(TOKEN));
                            dispatch(setUserTypeKey(ASCENDING_ORDERS));
                            setTokenSort(false);
                          }}
                        ></div>
                      ) : (
                        <div
                          className="admin_navBar_sort fa fa-angle-up"
                          onClick={() => {
                            dispatch(setUserColumnKey(TOKEN));
                            dispatch(setUserTypeKey(DESCENDING_ORDERS));
                            setTokenSort(true);
                          }}
                        ></div>
                      )}
                    </th>
                    <th>{string.REQUEST_BODY}</th>
                    <th>{string.RESPONSE_CODE}</th>
                    <th>{string.RESPONSE_BODY}</th>
                    <th>{string.MESSAGE}</th>
                    <th>{string.CREATED_AT}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => {
                    const jsonString = JSON.stringify(item.req_body, null, 2);
                    const jsonStringRespBody = JSON.stringify(
                      item.resp_body,
                      null,
                      2
                    );

                    return (
                      <tr className="admin_table_row" key={item.id}>
                        <td
                          className={
                            hoveredRowIndex === index ? "expanded" : ""
                          }
                        >
                          {item.id}
                        </td>
                        <td
                          className={`order_number ${
                            hoveredRowIndex === index ? "expanded" : ""
                          }`}
                          onClick={() => {
                            dispatch(setOrderToken(item.order_token));
                            navigate(`order_details/${item.order_token}`);
                            dispatch(setActivePageIndex(ORDER_DETAILS_PAGE));
                          }}
                        >
                          <Tooltip title={string.CLICK_TO_VIEW_DETAILS} arrow>
                            <span> {item.order_id}</span>
                          </Tooltip>
                        </td>
                        <td
                          className={
                            hoveredRowIndex === index ? "expanded" : ""
                          }
                        >
                          {item.token}
                        </td>
                        {/* request body */}
                        <td
                          className={`hover-expandable ${
                            hoveredRowIndex === index ? "expanded" : ""
                          }`}
                        >
                          <div className="admin_view_btn d-flex justify-content-center">
                            <div
                              className="view_modal"
                              onClick={() => {
                                setSubHeading(
                                  string.SALES_REQUEST_BODY_COPIED_SUCCESSFULLY
                                );
                                handleViewClick(jsonString);
                              }}
                            >
                              {string.VIEW}
                            </div>
                            <div
                              className={
                                isExpanded[index]
                                  ? "expanded-content d-flex"
                                  : "collapsed-content d-flex"
                              }
                            >
                              <img
                                className={`copy-button
                       ${hoveredRowIndex === index ? "copy-expanded" : ""}
                       `}
                                src={Icons.copy.default}
                                alt="copy"
                                onClick={() => {
                                  setSubHeading(
                                    string.SALES_REQUEST_BODY_COPIED_SUCCESSFULLY
                                  );
                                  handleCopyClick(jsonString);
                                }}
                              />
                            </div>
                          </div>
                        </td>
                        {/* request body */}
                        <td
                          className={
                            hoveredRowIndex === index ? "expanded" : ""
                          }
                        >
                          {item.resp_code ? item.resp_code : "-"}
                        </td>
                        {/* response body */}
                        <td>
                          <div className="admin_view_btn d-flex justify-content-center">
                            {jsonStringRespBody === "{}" ? (
                              "-"
                            ) : (
                              <>
                                <div
                                  className="view_modal"
                                  onClick={() => {
                                    setSubHeading(
                                      string.SALES_RESPONSE_BODY_COPIED_SUCCESSFULLY
                                    );
                                    handleViewClick(jsonStringRespBody);
                                  }}
                                >
                                  {string.VIEW}{" "}
                                </div>
                                <div>
                                  <img
                                    src={Icons.copy.default}
                                    className="copy-icon"
                                    alt="copy"
                                    onClick={() => {
                                      setSubHeading(
                                        string.SALES_RESPONSE_BODY_COPIED_SUCCESSFULLY
                                      );
                                      handleCopyClick(jsonStringRespBody);
                                    }}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </td>
                        {/* response body */}
                        <td
                          className={
                            hoveredRowIndex === index ? "expanded" : ""
                          }
                        >
                          {item.message ? item.message : "-"}
                        </td>
                        <td
                          className={
                            hoveredRowIndex === index ? "expanded" : ""
                          }
                        >
                          {item.created_at}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="admin_pagination">
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={totalCount}
                pageSize={PAGE_SIZE}
                onPageChange={(page) => {
                  setCurrentPage(page);
                  dispatch(setPage(page));
                }}
              />
            </div>
          </>
        )}
      </div>
      <StatusModal
        canShow={CanShowBackAlertModal}
        stautsbackground={{ background: "#176267" }}
        statusimge={status_right}
        statusHeading={string.COPIED_SUCCESSFULLY}
        statusDescription={subHeading}
        closebackground={{ background: "#176267" }}
        closeData={{
          closeBtn: string.CLOSE,
          closeHandler: closeHandler,
        }}
      />

      <div className="sales_order_request_body">
        <AdminViewModal
          handleClose={handleClose}
          jsonStringData={jsonStringData}
          openModal={salesOrderModal}
          onViewClick={handleCopyClick}
        />
      </div>
    </>
  );
};

export default SalesTable;
