import { Spinner } from "reactstrap";
import "./ProjectSpinner.scss";

const ProjectSpinner = (props) => {
  const { isLoading, customClass } = props;

  const getStyle = () => {
    let style = {};
    if (customClass === undefined) {
      style = {
        position: "absolute",
        bottom: "100%",
        left: "40%",
        color: "#176267",
      };
    }
    return style;
  };

  return (
    <>
      {isLoading ? (
        <Spinner
          style={getStyle()}
          className={`loader-spinner ${customClass}`}
          animation="border"
        />
      ) : null}
    </>
  );
};

export default ProjectSpinner;
