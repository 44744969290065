import React from "react";
import "./PhotographerLicense.scss";
import { string } from "../../../Localization";
import { useSelector } from "react-redux";

const SubscriberTerms = () => {
  const { footerLinksData } = useSelector((state) => state.cache);
  const data = [
    //1
    {
      label: string.INTERPRETATION,

      children: [
        {
          label: string.INTERPRETATION_PT_1,
        },
        {
          label: string.INTERPRETATION_PT_2,
        },
        {
          label: string.INTERPRETATION_PT_3,
        },
        {
          label: string.INTERPRETATION_PT_4,
        },
        {
          label: string.INTERPRETATION_PT_5,
        },
        {
          label: string.INTERPRETATION_PT_6,
        },
        {
          label: string.INTERPRETATION_PT_7,
        },
        {
          label: string.INTERPRETATION_PT_8,
        },
      ],
    },
    //2
    {
      label: string.DEFINITIONS,
      subHeading: true,
      children: [
        {
          label:
            "<b>" +
            string.ACCEPTED_IMAGES +
            ": </b> " +
            string.DEFINITIONS_PT_1,
        },
        {
          label: "<b>" + string.AFFILIATE + ": </b> " + string.DEFINITIONS_PT_2,
        },
        {
          label: "<b>" + string.COLLECTOR + ": </b> " + string.DEFINITIONS_PT_3,
        },
        {
          label:
            "<b>" +
            string.CONFIDENTIAL_INFORMATION +
            ": </b> " +
            string.DEFINITIONS_PT_4 +
            " 24 (“<b>" +
            string.NOTICES_1 +
            "</b>”). ",
        },
        {
          label:
            "<b>" +
            string.HP_ENTERPRISE_LEDGER +
            ": </b> " +
            string.DEFINITIONS_PT_5,
        },
        {
          label:
            "<b>" + string.DIGITAL_IMAGE + ":  </b> " + string.DEFINITIONS_PT_6,
        },
        {
          label:
            "<b>" + string.DOCUMENTATION + ": </b> " + string.DEFINITIONS_PT_7,
        },
        {
          label:
            "<b>" +
            string.INTELLECTUAL_PROPERTY_RIGHTS +
            ": </b> " +
            string.DEFINITIONS_PT_8,
        },
        {
          label:
            "<b>" +
            string.NORMAL_BUISNESS_HOUR +
            ": </b> " +
            string.DEFINITIONS_PT_9,
        },
        {
          label:
            "<b>" +
            string.RESELLER +
            ": </b> " +
            string.DEFINITIONS_PT_10 +
            " <a  href={footerLinksData['photographer-license-terms-of-service']} target='_blank'> " +
            string.HP_LIMITED_PHOTOGRAPHER_LICENSE_TERMS +
            " </a> ",
        },
        {
          label: "<b>" + string.SERVICES + ": </b> " + string.DEFINITIONS_PT_11,
        },
        {
          label: "<b>" + string.SOFTWARE + ": </b> " + string.DEFINITIONS_PT_12,
        },
        {
          label:
            "<b>" +
            string.SUPPORT +
            " " +
            string.SERVICES +
            ": </b> " +
            string.DEFINITIONS_PT_13,
        },
        {
          label:
            "<b>" +
            string.STANDARD +
            " " +
            string.SUBSCRIPTION +
            ": </b> " +
            string.DEFINITIONS_PT_14,
        },
        {
          label:
            "<b>" +
            string.PRO +
            " " +
            "-" +
            " " +
            string.SUBSCRIPTION +
            ": </b> " +
            string.DEFINITIONS_PT_15,
        },
        {
          label:
            "<b>" +
            string.SUBSCRIPTION +
            " " +
            string.FEE +
            ": </b> " +
            string.DEFINITIONS_PT_16,
        },
        {
          label:
            "<b>" +
            string.SUBSCRIPTION +
            " " +
            string.TERM +
            ": </b> " +
            string.DEFINITIONS_PT_17 +
            " 14.",
        },
        {
          label: "<b>" + string.VIRUS + ": </b> " + string.DEFINITIONS_PT_18,
        },
        {
          label:
            "<b>" +
            string.VULNERABILITY +
            ": </b> " +
            string.DEFINITIONS_PT_19 +
            " <b> &quot;" +
            string.VULNERABILITIES +
            "&quot; </b> " +
            string.DEFINITIONS_PT_20a,
        },
        {
          label:
            "<b>" +
            string.WEBSITE +
            ": </b> <a href='https://www.hplimiteditions.com/' target='_blank'>https://www.hplimiteditions.com/</a> " +
            string.DEFINITIONS_PT_20,
        },
      ],
    },
    //3
    {
      label: string.SUBMISSION_ACCEPTANCE_PROPERTY_IMAGES,

      children: [
        {
          label: string.SUBMISSION_ACCEPTANCE_PROPERTY_IMAGES_PT_1,
        },
        {
          label: string.SUBMISSION_ACCEPTANCE_PROPERTY_IMAGES_PT_2,
        },
        {
          label:
            string.SUBMISSION_ACCEPTANCE_PROPERTY_IMAGES_PT_3a +
            ": <i>&quot;[" +
            string.CONTRIBUTORS +
            "]&quot;</i>, " +
            string.SUBMISSION_ACCEPTANCE_PROPERTY_IMAGES_PT_3ab,
        },
        {
          label: string.SUBMISSION_ACCEPTANCE_PROPERTY_IMAGES_PT_4,
        },
      ],
    },
    //4
    {
      label: string.USER_SUBSCRIPTION,

      children: [
        {
          label: string.USER_SUBSCRIPTION_PT_1,
        },
        {
          label: string.USER_SUBSCRIPTION_PT_2,
        },
        {
          label: string.USER_SUBSCRIPTION_PT_3,
          children: [
            {
              label: string.USER_SUBSCRIPTION_PT_3a,
            },
            {
              label: string.USER_SUBSCRIPTION_PT_3b,
            },
            {
              label: string.USER_SUBSCRIPTION_PT_3c,
            },
            {
              label: string.USER_SUBSCRIPTION_PT_3d,
            },
            {
              label: string.USER_SUBSCRIPTION_PT_3e,
            },
            {
              label: string.USER_SUBSCRIPTION_PT_3f,
            },
          ],
        },
        {
          label: string.USER_SUBSCRIPTION_PT_4,
          children: [
            {
              label: string.USER_SUBSCRIPTION_PT_4a,
              children: [
                {
                  label: string.USER_SUBSCRIPTION_PT_4a1,
                },
                {
                  label: string.USER_SUBSCRIPTION_PT_4a2,
                },
              ],
            },
            {
              label: string.USER_SUBSCRIPTION_PT_4b,
            },
            {
              label: string.USER_SUBSCRIPTION_PT_4c,
            },
            {
              label: string.USER_SUBSCRIPTION_PT_4d,
            },
            {
              label: string.USER_SUBSCRIPTION_PT_4e,
            },
            {
              label: string.USER_SUBSCRIPTION_PT_4f,
            },
          ],
        },
        {
          label: string.USER_SUBSCRIPTION_PT_5,
        },
        {
          label: string.USER_SUBSCRIPTION_PT_6,
        },
      ],
    },
    //5
    {
      label: string.SUBSCRIPTION_SERVICE,

      children: [
        {
          label:
            "<u>" +
            string.SERVICES +
            ". </u> " +
            string.SUBSCRIPTION_SERVICE_PT_1,
        },
        {
          label:
            "<u>" +
            string.SUBSCRIPTION +
            " " +
            string.FEE +
            ". </u>" +
            string.SUBSCRIPTION_SERVICE_PT_2,
        },
        {
          label:
            "<u>" +
            string.SUPPORT +
            " " +
            string.SERVICES +
            ".</u>" +
            string.SUBSCRIPTION_SERVICE_PT_3,
        },
        {
          label: string.SUBSCRIPTION_SERVICE_PT_4,
        },
      ],
    },
    //6
    {
      label: string.PROPERTY_RIGHTS_LISENSE,

      children: [
        {
          label: string.PROPERTY_RIGHTS_LISENSE_PT_1,
        },
        {
          label:
            "<u>" +
            string.PROPERTY_RIGHTS_LISENSE_PT_2u +
            ".</u> " +
            string.PROPERTY_RIGHTS_LISENSE_PT_2,
        },
        {
          label:
            "<u>" +
            string.PROPERTY_RIGHTS_LISENSE_PT_3u +
            ".</u> " +
            string.PROPERTY_RIGHTS_LISENSE_PT_3,
        },
      ],
    },
    //7
    {
      label: string.PAYING_SERVICES,

      children: [
        {
          label: string.PAYING_SERVICES_PT_1,
        },
        {
          label: string.PAYING_SERVICES_PT_2,
        },
        {
          label: string.PAYING_SERVICES_PT_3,
        },
        {
          label: string.PAYING_SERVICES_PT_4,
        },
        {
          label:
            "<u>" +
            string.PAYING_SERVICES_PT_5u +
            "</u> " +
            string.PAYING_SERVICES_PT_5,
        },
        {
          label:
            "<u>" +
            string.PAYING_SERVICES_PT_6u +
            ".</u> " +
            string.PAYING_SERVICES_PT_6,
        },
        {
          label:
            "<u>" +
            string.PAYING_SERVICES_PT_7u +
            "</u> " +
            string.PAYING_SERVICES_PT_7,
        },
        {
          label:
            "<u>" +
            string.PAYING_SERVICES_PT_8u +
            "</u> " +
            string.PAYING_SERVICES_PT_8,
        },
        {
          label:
            "<u>" +
            string.PAYING_SERVICES_PT_9u +
            "</u> " +
            string.PAYING_SERVICES_PT_9,
        },
      ],
    },
    //8
    {
      label: string.THIRD_PARTY_PROVIDERS,
      subHeading: true,
      children: [
        {
          label: string.THIRD_PARTY_PROVIDERS_PT_1,
        },
      ],
    },
    //9
    {
      label: string.CONFIDENTIALITY_AND_DATA,

      children: [
        {
          label: string.CONFIDENTIALITY_AND_DATA_PT_1,
        },
        {
          label:
            "<u>" +
            string.CONFIDENTIALITY_AND_DATA_PT_2u +
            ".</u> " +
            string.CONFIDENTIALITY_AND_DATA_PT_2 +
            " <a href='https://www.hp.com/us-en/privacy/privacy-central.html' target='_blank'>www.hp.com/go/privacy.</a>",
        },
      ],
    },
    //10
    {
      label: string.YOUR_UNDERTAKINGS,
      children: [
        {
          label: string.YOUR_UNDERTAKINGS_PT_1,

          children: [
            {
              label: string.YOUR_UNDERTAKINGS_PT_1a,
            },
            {
              label: string.YOUR_UNDERTAKINGS_PT_1b,
            },
            {
              label: string.YOUR_UNDERTAKINGS_PT_1c,
            },
            {
              label: string.YOUR_UNDERTAKINGS_PT_1d,
            },
            {
              label: string.YOUR_UNDERTAKINGS_PT_1e,
            },
            {
              label: string.YOUR_UNDERTAKINGS_PT_1f,
            },
            {
              label: string.YOUR_UNDERTAKINGS_PT_1g,
              children: [
                {
                  label: string.YOUR_UNDERTAKINGS_PT_1g1,
                },
                {
                  label: string.YOUR_UNDERTAKINGS_PT_1g2,
                },
              ],
            },
          ],
        },
        {
          label: string.YOUR_UNDERTAKINGS_PT_2,
        },
      ],
    },
    //11
    {
      label: string.INDEMNITY,
      subHeading: true,
      children: [
        {
          label: string.INDEMNITY_PT_1,
        },
      ],
    },
    //12
    {
      label: string.LIMITATION_AND_LIABILITY,
      subHeading: true,
      children: [
        {
          label:
            string.LIMITATION_AND_LIABILITY_PT_1i +
            "<br> <br>" +
            string.LIMITATION_AND_LIABILITY_PT_1ii,
        },
      ],
    },
    //13
    {
      label: string.COMPLIANCE_WITH_LAWS_EXPORT,
      subHeading: true,
      children: [
        {
          label: string.COMPLIANCE_WITH_LAWS_EXPORT_PT_1,
        },
      ],
    },
    //14
    {
      label: string.TERMS_AND_TERMINATION,

      children: [
        {
          label: string.TERMS_AND_TERMINATION_PT_1,
        },
        {
          label: string.TERMS_AND_TERMINATION_PT_2,
        },
        {
          label: string.TERMS_AND_TERMINATION_PT_3,
        },
        {
          label: string.TERMS_AND_TERMINATION_PT_4,
          children: [
            {
              label: string.TERMS_AND_TERMINATION_PT_4a,
            },
            {
              label: string.TERMS_AND_TERMINATION_PT_4b,
            },
            {
              label: string.TERMS_AND_TERMINATION_PT_4c,
            },
            {
              label: string.TERMS_AND_TERMINATION_PT_4d,
            },
          ],
        },
      ],
    },
    //15
    {
      label: string.FORCE_MAJEURE,
      subHeading: true,
      children: [
        {
          label: string.FORCE_MAJEURE_PT_1,
        },
      ],
    },
    //16
    {
      label: string.VARIATION,
      subHeading: true,
      children: [
        {
          label: string.VARIATION_PT_1,
        },
      ],
    },
    //17
    {
      label: string.WAIVER,
      subHeading: true,
      children: [
        {
          label: string.WAIVER_PT_1,
        },
      ],
    },
    //18
    {
      label: string.RIGHTS_AND_REMEDIES,
      subHeading: true,
      children: [
        {
          label: string.RIGHTS_AND_REMEDIES_PT_1,
        },
      ],
    },
    //19
    {
      label: string.SEVERANCE,

      children: [
        {
          label: string.SEVERANCE_PT_1,
        },
        {
          label: string.SEVERANCE_PT_2,
        },
      ],
    },
    //20
    {
      label: string.ENTIRE_AGREEMENT,

      children: [
        {
          label: string.ENTIRE_AGREEMENT_PT_1,
        },
        {
          label: string.ENTIRE_AGREEMENT_PT_2,
        },
        {
          label: string.ENTIRE_AGREEMENT_PT_3,
        },
        {
          label: string.ENTIRE_AGREEMENT_PT_4,
        },
      ],
    },

    //21
    {
      label: string.ASSIGNMENT,

      children: [
        {
          label: string.ASSIGNMENT_PT_1,
        },
        {
          label: string.ASSIGNMENT_PT_2,
        },
      ],
    },
    //22
    {
      label: string.NO_PARTNERSHIP_AGENCY,
      subHeading: true,
      children: [
        {
          label: string.NO_PARTNERSHIP_AGENCY_PT_1,
        },
      ],
    },
    //23
    {
      label: string.THIRD_PARTY_RIGHTS,
      subHeading: true,
      children: [
        {
          label: string.THIRD_PARTY_RIGHTS_PT_1,
        },
      ],
    },
    //24
    {
      label: string.NOTICES,

      children: [
        {
          label: string.NOTICES_PT_1,
          children: [
            {
              label: string.NOTICES_PT_1a,
            },
            {
              label: string.NOTICES_PT_1b,
            },
          ],
        },
        {
          label: string.NOTICES_PT_2,
        },
      ],
    },
    //25
    {
      label: string.GOVERNING_LAW,
      subHeading: true,
      children: [
        {
          label: string.GOVERNING_LAW_PT_1,
        },
      ],
    },
    //26
    {
      label: string.JURISDICTION,
      subHeading: true,
      children: [
        {
          label: string.JURISDICTION_PT_1,
        },
      ],
    },
  ];

  return (
    <div className="photographer_license_container">
      <div className="photographer_license_content">
        <div className="photographer_license_heading">
          {string.HP_LIMITED_EDITION_PRINT_SERVICES} –{" "}
          {string.SUBDCRIBER_TERMS_AND_SERVICE}
        </div>
        <div className="photographer_license_content_1">
          {string.THIS_HP_LIMITED_PRINT_SERVICES} –{" "}
          {string.SUBSCRIBER_SERVICE_TEXT}{" "}
          <a href={footerLinksData["terms-and-conditions"]} target="_blank">
            {string.LINK_TO_SUBSCRIBER}
          </a>
          &nbsp;({string.COLLECTIVELY}, {string.THE} <b>"{string.AGREEMENT}"</b>
          ) {string.SUBSCRIBER_TERMS_CONTENT_1a} (<b>“{string.HP}"</b>{" "}
          {string.OR} <b>"{string.WE}"</b> {string.OR} <b>"{string.US}"</b>{" "}
          {string.OR} <b>“{string.OUR}”</b>){" "}
          {string.SUBSCRIBER_TERMS_CONTENT_1b} (<b>"{string.YOU}"</b>{" "}
          {string.OR} <b>"{string.YOUR}"</b> {string.OR}{" "}
          <b>“{string.SUBSCRIBER}”</b>) {string.SUBSCRIBER_TERMS_CONTENT_1c}{" "}
          <b>“{string.PARTY}”</b> {string.SUBSCRIBER_TERMS_CONTENT_1d}{" "}
          <b>“{string.PARTIES}”</b>.
        </div>
        <div className="photographer_license_content_2">
          {string.SUBSCRIBER_TERMS_CONTENT_2}
        </div>

        <div className="photographer_license_content_3">
          {string.SUBSCRIBER_TERMS_CONTENT_3}
        </div>

        <div className="photographer_license_content_4">
          {string.SUBSCRIBER_TERMS_CONTENT_4a}{" "}
          <a
            href={footerLinksData["photographer-license-terms-of-service"]}
            target="_blank"
          >
            {string.SUBSCRIBER_TERMS_CONTENT_4_LINK}
          </a>{" "}
          {string.SUBSCRIBER_TERMS_CONTENT_4b} ({string.THE}
          <b>“{string.SUBSCRIBER_TERMS_CONTENT_4c}”</b>).
        </div>
        <div className="photographer_license_content_5">
          {string.SUBSCRIBER_TERMS_CONTENT_5}
        </div>
        <div className="photographer_license_content_6">
          {string.SUBSCRIBER_TERMS_CONTENT_6}
        </div>
        <div className="photographer_license_content_6">
          {string.SUBSCRIBER_TERMS_CONTENT_7}
        </div>
        <div className="photographer_license_content_7 photographer_license_list">
          <NestedList items={data} />
        </div>
      </div>
    </div>
  );
};
const NestedList = ({ items, depth = 0 }) => {
  return (
    <ol className={`list-depth-${depth}`}>
      {items.map((item, index) => {
        return (
          <li key={index}>
            {item.subHeading ? (
              <>
                <span className="photographer_license_interpretation_list">
                  <span dangerouslySetInnerHTML={{ __html: item.label }} />{" "}
                </span>
                {item.children.map((ele, eleIndex) => (
                  <div
                    key={eleIndex}
                    className="photographer_license_list2"
                    dangerouslySetInnerHTML={{ __html: ele.label }}
                  ></div>
                ))}
              </>
            ) : (
              <>
                <span className={`item-label-depth-${depth}`}>
                  <span dangerouslySetInnerHTML={{ __html: item.label }} />
                </span>
                {item.children && (
                  <NestedList items={item.children} depth={depth + 1} />
                )}
              </>
            )}
          </li>
        );
      })}
    </ol>
  );
};

export default SubscriberTerms;
