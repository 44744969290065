import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  deletePhoto,
  getProjectCount,
  getProjects,
} from "../../Utils/ApiHelper";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_PHOTO_PRINT_DETAILS, PROJECT_TYPES } from "../../Utils/Constants";
import { setActivePageIndex } from "../../Store/Project/PageSlice";
import {
  showOrHideApiLoadingModal,
  showOrHideProfileUpdateModal,
  showOrHideUploadFileModal,
} from "../../Store/Project/ModalSlice";
import UploadImage from "../../Components/Modal/UploadImage/UploadImage";
import "font-awesome/css/font-awesome.min.css";
import "./Gallery.scss";
import SortFilter from "../../Utils/Icons/sort.svg";
import { handleDraft } from "../../Utils/DraftHelper";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import TabContent from "./TabContent";
import UploadImageFirst from "../UploadImageFirst/UploadImageFirst";
import { setProjectToken } from "../../Store/Project/ProjectSlice";
import {
  sendGARecentGalleryView,
  sendGAScrolledGalleryView,
  sendGAClickedGalleryView,
  sendGAClickedSorting,
} from "../../Utils/GAHelper";
import ProjectLoader from "../../Components/Loaders/ProjectLoader/ProjectLoader";
import { string } from "../../Localization";
import FirstAiImage from "../FirstAiImage/FirstAiImage";
import InfiniteScrollLoader from "../../Components/Loaders/InfiniteScrollLoader";

const Gallery = (props) => {
  const { onGalleryLoadingComplete } = props;

  const { currentUserDetails } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [items, setItems] = useState([]);
  const [noMore, setNoMore] = useState(true);
  const [page, setPage] = useState(5);
  const [sortByDesc, setSortByDesc] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [projectsPresent, setProjectsPresent] = useState(false);
  const [sortIcon, setSortIcon] = useState(false);
  const [projectCount, setProjectCount] = useState(0);
  const [showOpenAi, setShowOpenApi] = useState(false);

  const getCount = async (recordType) => {
    const projectCount = await getProjectCount(recordType);

    const count = projectCount.data.project_count;
    setProjectCount(count);
  };

  useEffect(() => {
    initializeGallery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortByDesc, tabIndex]);

  useEffect(() => {
    if (currentUserDetails.signature.endsWith("missing.png")) {
      dispatch(showOrHideProfileUpdateModal(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserDetails.signature]);

  const initializeGallery = async () => {
    dispatch(showOrHideApiLoadingModal(true));
    if (currentUserDetails?.open_ai) {
      setShowOpenApi(currentUserDetails?.open_ai);
    }
    const data = await getProjects(1, 24, sortByDesc, PROJECT_TYPES[tabIndex]);
    setItems(data.data);
    //tabIndex - 0 -> recent tab, it fetches all projects - new,draft,soldout etc.
    if (tabIndex === 0) {
      getCount("recent");
      setProjectsPresent(data?.data?.length !== 0);
    }
    setIsPageLoading(false);
    sendGARecentGalleryView(PROJECT_TYPES[tabIndex]);
    dispatch(showOrHideApiLoadingModal(false));
    onGalleryLoadingComplete(false);
  };

  const fetchNextPage = async () => {
    const projectsFromServer = await getProjects(
      page,
      6,
      sortByDesc,
      PROJECT_TYPES[tabIndex]
    );
    if (projectsFromServer.data.length === 0) {
      setNoMore(false);
    }
    setItems([...items, ...projectsFromServer.data]);
    setPage(page + 1);
    setIsPageLoading(false);

    sendGAScrolledGalleryView();
  };

  const handleClick = (value) => () => {
    switch (value) {
      case true:
        setSortByDesc(false);
        dispatch(showOrHideApiLoadingModal(true));
        setIsPageLoading(true);
        break;
      case false:
        setSortByDesc(true);
        setIsPageLoading(true);
        dispatch(showOrHideApiLoadingModal(true));
        break;
      default:
        break;
    }
    sendGAClickedSorting(value);
    setSortIcon(!sortIcon);
  };

  const UploadFile = () => {
    dispatch(showOrHideUploadFileModal(true));
  };

  const loadPhotoPrintDetails = (data) => {
    dispatch(setProjectToken(data.project_token));
    dispatch(setActivePageIndex(PAGE_PHOTO_PRINT_DETAILS));
  };

  const tabHeaders = [
    string.RECENTS,
    string.DRAFTS,
    string.STOPPED,
    string.SOLD_OUT,
  ];

  const handleDelete = (projectToken) => {
    deletePhoto(projectToken);
    const filteredItems = items.filter(
      (item) => item.project_token !== projectToken
    );
    setItems(filteredItems);
  };

  return (
    <>
      <div className="gallery-wrapper" id="gallery-wrapper">
        <InfiniteScroll
          dataLength={items.length}
          next={fetchNextPage}
          hasMore={noMore}
          scrollableTarget="gallery-wrapper"
          loader={<InfiniteScrollLoader />}
          endMessage={""}
        >
          {projectsPresent ? (
            <>
              <div className="gallery-selection-bar">
                <span className="bold-string head">{string.MY_PHOTOS}</span>
                <div className="head filter-view row">
                  <div
                    className="btn grid-icon-spacing"
                    onClick={handleClick(sortByDesc)}
                  >
                    {sortIcon ? (
                      <img src={SortFilter} alt="" />
                    ) : (
                      <img src={SortFilter} alt="" className="sortRotate" />
                    )}

                    {/* <span className="text_sort">Sort by: newest first</span> */}
                    {/* <img src={Icons.downArrow} alt="" className="downArrow" /> */}
                  </div>
                </div>
              </div>
              <div className="react-tab-gallery">
                <Tabs
                  selectedIndex={tabIndex}
                  onSelect={(index) => {
                    setTabIndex(index);
                    getCount(PROJECT_TYPES[index]);
                    setIsPageLoading(true);
                    sendGAClickedGalleryView(PROJECT_TYPES[index]);
                  }}
                >
                  <TabList className="mt--100">
                    <Tab>{string.RECENTS}</Tab>
                    <Tab>{string.DRAFTS}</Tab>
                    <Tab>{string.STOPPED}</Tab>
                    <Tab>{string.SOLD_OUT}</Tab>
                  </TabList>
                  {tabHeaders.map((tabHeader) => (
                    <TabPanel>
                      <TabContent
                        items={items}
                        setProjectCount={setProjectCount}
                        projectCount={projectCount}
                        UploadFile={UploadFile}
                        heading={tabHeader}
                        isPageLoading={isPageLoading}
                        handleDraft={handleDraft}
                        loadPhotoPrintDetails={loadPhotoPrintDetails}
                        shouldShowUpload={tabIndex !== 3 && tabIndex !== 2}
                        handleDelete={handleDelete}
                        initializeGallery={initializeGallery}
                      />
                    </TabPanel>
                  ))}
                </Tabs>
              </div>
            </>
          ) : (
            !isPageLoading && (
              <div className={showOpenAi ? "first_page_container" : ""}>
                <UploadImageFirst showOpenAi={showOpenAi} />

                {showOpenAi && <FirstAiImage />}
              </div>
            )
          )}
          <ProjectLoader />
        </InfiniteScroll>
        <UploadImage />
      </div>
    </>
  );
};

export default Gallery;
