import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ADMIN_NAV_BAR, PAGE_SIZE } from "../../../Utils/Constants";
import {
  setActivePageIndex,
  setPage,
  setUserColumnKey,
  setUserTypeKey,
} from "../../../Store/Project/AdminSlice";
import backArrowSvg from "../../../Utils/Icons/arrow_back.svg";
import ProjectLoader from "../../../Components/Loaders/ProjectLoader/ProjectLoader";
import { RetrieveProjectOrders } from "../../../Utils/ApiHelper";
import "../AdminOrderDetails/AdminOrderDetails.scss";
import "../AdminTables/AdminTables.scss";
import AdminSearchBar from "../AdminSearchBar/AdminSearchBar";
import { string } from "../../../Localization";
import { useNavigate, useParams } from "react-router-dom";
import { showOrHideApiLoadingModal } from "../../../Store/Project/ModalSlice";
import { AdminSearchReset } from "../../../Utils/projectHelper";
import Pagination from "../AdminTables/Pagination/Pagination";
import AdminOrderTable from "../AdminTables/AdminOrderTable";
import DataNotFound from "../DataNotFound";
import { showAdminSearchBox } from "../../../Utils/Helper";

const AdminProjectOrders = () => {
  const {
    userSearch,
    userColumnKey,
    userTypeKey,
    userSearchKey,
    page,
    projectToken,
  } = useSelector((state) => state.admin);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { project_token } = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [projectOrderDetail, setProjectOrderDetail] = useState([]);
  const [recordCount, setRecordCount] = useState([]);
  const [userData, setUserData] = useState([]);
  const [failed, setFailed] = useState(false);

  useEffect(() => {
    dispatch(showOrHideApiLoadingModal(true));
    RetrieveProjectOrders(
      currentPage - 1,
      PAGE_SIZE,
      userTypeKey,
      userColumnKey,
      userSearchKey,
      userSearch,
      project_token
    )
      .then((res) => {
        setFailed(false);
        setRecordCount(res?.data?.data?.records_count);
        setProjectOrderDetail(res?.data?.data?.records_arr);
        setUserData(res?.data?.data?.parent_record);
        setTotalPage(res?.data?.data?.total_pages);
        dispatch(showOrHideApiLoadingModal(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(showOrHideApiLoadingModal(false));
        if (err?.response?.status === 401) {
          navigate("/");
        } else if (err?.response?.status === 422) {
          setFailed(true);
        }
      });
  }, [
    userColumnKey,
    userSearch,
    userTypeKey,
    userSearchKey,
    userSearch,
    currentPage,
    projectToken,
  ]);

  const totalCount = totalPage * PAGE_SIZE;

  const options = [
    { value: 0, label: "Email" },
    { value: 1, label: "First Name" },
    { value: 2, label: "Last Name" },
    { value: 3, label: "Contact Number" },
    { value: 4, label: "Token" },
  ];

  const onNavigateBack = () => {
    navigate(-1);
    dispatch(setActivePageIndex(ADMIN_NAV_BAR));
  };

  return (
    <>
      <ProjectLoader />
      <div className="admin_tables">
        {!failed ? (
          <div className="admin_project_user_details">
            <div className="admin_user_back_btn">
              <div className="admin_back_btn">
                <div
                  className="navigateBackContainer"
                  onClick={() => {
                    AdminSearchReset();
                    onNavigateBack();
                  }}
                >
                  <img src={backArrowSvg} alt="" />
                  <span>{string.BACK}</span>
                </div>
              </div>
              <div className="admin_order_details">
                <div className="order_details_text">
                  {string.PROJECT_ORDER_DETAILS}
                </div>
                <div className="user-details-container">
                  <div className="thumnail-img">
                    <img src={userData?.preview_thubmnail_url} alt="preview" />
                  </div>
                  <div className="user-details-container-1">
                    <div>
                      <span className="user-label">
                        {" "}
                        {string.USER} {string.ID}:
                      </span>{" "}
                      <span className="user-value">{userData?.user_id}</span>
                    </div>
                    <div>
                      <span className="user-label"> {string.NAME}:</span>{" "}
                      <span className="user-value">
                        {userData?.artist_name ? userData?.artist_name : "-"}
                      </span>
                    </div>
                    <div>
                      <span className="user-label"> {string.EMAIL_1}:</span>{" "}
                      <span className="user-value">{userData?.email}</span>
                    </div>{" "}
                    <div>
                      <span className="user-label">
                        {" "}
                        {string.DESCRIPTION.toUpperCase()}:
                      </span>{" "}
                      <span className="user-value">
                        {userData?.description === "No description is Available"
                          ? "-"
                          : userData?.description}
                      </span>
                    </div>
                    <div>
                      <span className="user-label"> {string.COPY_RIGHT}:</span>{" "}
                      <span className="user-value">
                        {userData?.copyright_text
                          ? userData?.copyright_text
                          : "-"}
                      </span>
                    </div>
                    <div>
                      <span className="user-label">{string.TITLE}: </span>{" "}
                      <span className="user-value">{userData?.title}</span>
                    </div>{" "}
                    <div>
                      <span className="user-label"> {string.STATUS} : </span>{" "}
                      <span className="user-value">{userData?.status}</span>
                    </div>
                    <div>
                      <span className="user-label">
                        {" "}
                        {string.LIMITED_EDITION}:
                      </span>{" "}
                      <span className="user-value">
                        {userData?.limited_edition ? "Yes" : "No"}
                      </span>
                    </div>
                  </div>
                  <div className="user-details-container-2">
                    <div>
                      <span className="user-label">
                        {string.REMAINING_COPIES}:
                      </span>{" "}
                      <span className="user-value">
                        {userData?.remaining_print_count}
                      </span>
                    </div>
                    <div>
                      <span className="user-label">{string.NO_OF_COPIES}:</span>{" "}
                      <span className="user-value">
                        {" "}
                        {userData?.no_of_copies}
                      </span>
                    </div>{" "}
                    <div>
                      <span className="user-label">{string.VIEW_COUNT}:</span>{" "}
                      <span className="user-value">{userData?.view_count}</span>
                    </div>
                    <div>
                      <span className="user-label">{string.PRICE}:</span>{" "}
                      <span className="user-value">
                        {userData?.price ? userData?.price : "-"}
                      </span>
                    </div>
                    <div>
                      <span className="user-label">{string.AI_IMAGE}:</span>{" "}
                      <span className="user-value">
                        {userData?.ai_image ? "True" : "False"}
                      </span>
                    </div>
                    <div>
                      <span className="user-label">{string.OPEN_AI}:</span>{" "}
                      <span className="user-value">
                        {userData?.open_ai ? "True" : "False"}
                      </span>
                    </div>{" "}
                    <div>
                      <span className="user-label">
                        {" "}
                        {string.ORDERS_PLACED}:
                      </span>{" "}
                      <span className="user-value">
                        {userData?.orders_placed}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {showAdminSearchBox(userSearchKey, recordCount) && (
              <div className="admin_search_bar_container">
                <AdminSearchBar
                  setUserTypeKey={setUserTypeKey}
                  setUserColumnKey={setUserColumnKey}
                  options={options}
                />
              </div>
            )}
            {recordCount == 0 ? (
              <>
                <div className="admin_user_back_btn">
                  <div className="admin_order_details">
                    <div className="empty-records">
                      {string.NO_ORDERS_FOUND}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <AdminOrderTable response={projectOrderDetail} />
                <div className="admin_pagination">
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={totalCount}
                    pageSize={PAGE_SIZE}
                    onPageChange={(page) => {
                      setCurrentPage(page);
                      dispatch(setPage(page));
                    }}
                  />
                </div>
              </>
            )}
          </div>
        ) : (
          <DataNotFound />
        )}
      </div>
    </>
  );
};

export default AdminProjectOrders;
