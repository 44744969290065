import { fabric } from "fabric";
import { store } from "../Store/Store";
import { addCanvasEditedNotifications } from "./ProjectRender";
import {
  footerLinks,
  pollBlockchainStatus,
  pollUploadImageStatus,
  verifyBlockchainStatus,
} from "./ApiHelper";
import { updateProgressBarValue } from "../Store/Project/ProgressBarSlice";
import { PDFDocument } from "pdf-lib";
import {
  FRAME_MATTINGS,
  STANDARD_PPI,
  STATUS_CODE_ACCEPTED,
  STATUS_CODE_SUCCESS,
  INCHES_UNIT,
  PORTRAIT,
  LANDSCAPE,
  SQUARE,
  ONE_MB_IN_BYTES,
  PDF,
  TIFF,
  TIF,
  PROJECT_PREVIEW_MODE_INITIAL,
  MM_TO_INCHES,
  PNG,
  SVG,
  JPEG,
  JPG,
  SUPPORTED_COUNTRY,
  BORDER_UNIT_VALUE_FIFTH,
  BORDER_UNIT_VALUE_FIRST,
  BORDER_UNIT_VALUE_FOURTH,
  BORDER_UNIT_VALUE_SECOND,
  BORDER_UNIT_VALUE_THIRD,
  BORDER_UNIT_VALUE_SIXTH,
  BASE_URL,
} from "./Constants";
import { setOrderDetails } from "../Store/Project/OrderSlice";
import {
  setImageAlreadyPresentError,
  showOrHideApiLoadingModal,
} from "../Store/Project/ModalSlice";
import { string } from "../Localization";
import { Icons } from "./Icons/Icons";
import {
  setCanShowErrorAlertModal,
  setImageErrorMessage,
} from "../Store/Project/ProjectSlice";
import checkIcon from "../Utils/Icons/check_OM.svg";
import closeIcon from "../Utils/Icons/close_red.svg";
import pauseIcon from "../Utils/Icons/pause_OM.svg";
import sendIcon from "../Utils/Icons/send.svg";
import processingIcon from "../Utils/Icons/processing.svg";
import cancelIcon from "../Utils/Icons/error-cancel.svg";
import editIcon from "../Utils/Icons/edit_white.svg";
import pendingIcon from "../Utils/Icons/pendingIcon.svg";
import { setFooterLinksData } from "../Store/Project/CacheSlice";

export const Size = (width, height) => {
  return { width, height };
};

export const Rect = (left, top, width, height) => {
  return { left, top, width, height };
};

export const updateImageProperty = (imageEle) => {
  if (imageEle) {
    const { activePreviewMode, sizeSelectionDetails } =
      store.getState().project;

    const canDisable =
      activePreviewMode === PROJECT_PREVIEW_MODE_INITIAL &&
      sizeSelectionDetails.selectedId
        ? true
        : false;
    setSizeSelectionImageProperty(imageEle, canDisable);
  }
};

export const setImageProperty = (inImage) => {
  inImage.layer = "Image";
  disableElement(inImage);
};

export const setWhitebackgroundProperty = (inElement) => {
  inElement.layer = "WhiteBackground";
  inElement.evented = false;
  disableElement(inElement);
};

export const setSizeSelectionImageProperty = (inImage, canDisable) => {
  inImage.layer = "Image";
  inImage.lockMovementX = canDisable;
  inImage.lockMovementY = canDisable;
  inImage.hasControls = false;
  inImage.selectable = !canDisable;
};

export const setSelectionProperty = (inSelectionElement) => {
  inSelectionElement.layer = "Selection";
  inSelectionElement.evented = false;
  disableElement(inSelectionElement);
};

const disableElement = (ele) => {
  ele.lockMovementX = true;
  ele.lockMovementY = true;
  ele.hasControls = false;
  ele.selectable = false;
  ele.hoverCursor = Object.prototype.hasOwnProperty.call(ele, "id") //main image does not have id, signature has id.
    ? "move"
    : "default";
};

export const getPixelsToInches = (inPixels) => {
  return inPixels / STANDARD_PPI;
};

export const getInchesToPixels = (inInches) => {
  return inInches * STANDARD_PPI;
};

export const createImagePreviewCanvas = () => {
  const canvas = new fabric.Canvas("image-canvas", {
    backgroundColor: "",
    preserveObjectStacking: true,
  });
  addCanvasEditedNotifications(canvas);
  return canvas;
};

export const createCroppedPreviewCanvas = () => {
  const canvas = new fabric.Canvas("cropped-canvas", {
    backgroundColor: "#ffffff",
  });
  return canvas;
};

export const createSignaturePreviewCanvas = () => {
  const canvas = new fabric.Canvas("signature-canvas", {
    backgroundColor: "",
  });
  return canvas;
};

export const createEditionPreviewCanvas = () => {
  const canvas = new fabric.Canvas("edition-canvas", {
    backgroundColor: "",
  });
  return canvas;
};

export const createFrameCanvas = () => {
  const canvas = new fabric.Canvas("frame-canvas", {
    backgroundColor: "#ffffff",
  });
  return canvas;
};

export const createVirtualCanvas = () => {
  const canvas = new fabric.Canvas("", {
    backgroundColor: "#ffffff",
  });
  return canvas;
};

export const getSelectedMediaTypeName = () => {
  const data = getSelectedMediaTypeData();
  return data ? data.name : "-";
};

export const getSelectedMediaTypeData = () => {
  const { selectedMediaTypeId } = store.getState().project;
  const { mediaTypesData } = store.getState().cache;

  const matchedMediaTypes = mediaTypesData.filter(
    (mediaType) => mediaType.id === selectedMediaTypeId
  );
  return matchedMediaTypes.length > 0 ? matchedMediaTypes[0] : null;
};

export const activeMediaTypeData = () =>
  store
    .getState()
    .cache?.mediaTypesData.filter((mediaType) => mediaType.active === true);

export const handleUploadProgress = (progressEvent) => {
  const progress = Math.round(
    (progressEvent.loaded * 100) / progressEvent.total
  );
  store.dispatch(updateProgressBarValue(progress));
};

export const redirectToHpSignInPage = () => {
  window.location.replace(`${BASE_URL}/photographer_sign_in`);
};

export const redirectToHpSignUpPage = () => {
  window.location.replace(`${BASE_URL}/photographer_sign_up`);
};

export const redirectToHpSignOutPage = () => {
  window.location.replace(`${BASE_URL}/photographer_sign_out`);
};

export const resetOrderDetailsPage = () => {
  store.dispatch(setOrderDetails(null));
};

export const getFrameMattingValueFromName = (name) => {
  const matchedMatting = FRAME_MATTINGS.filter((frameMatting) => {
    return frameMatting.name === name;
  });
  if (matchedMatting.length > 0) {
    return matchedMatting[0].value;
  }
  return 0;
};

let statusPoller = null;

const stopPolling = () => {
  clearInterval(statusPoller);
};

export const startPollingForUploadValidation = (userPhotoToken) => {
  return new Promise((resolve, reject) => {
    let iterations = 0;
    let interval = 5000;

    statusPoller = setInterval(function () {
      pollUploadImageStatus(userPhotoToken)
        .then((res) => {
          switch (res.status) {
            case STATUS_CODE_SUCCESS:
              stopPolling();
              resolve(res);
              break;
            case STATUS_CODE_ACCEPTED:
            default:
          }
        })
        .catch(function (error) {
          reject(error);
          stopPolling();
        });

      if (iterations >= 6) {
        interval = 10000;
      }
    }, interval);
  });
};

export const startPollingForBlockchainValidation = (projectToken) => {
  return new Promise((resolve, reject) => {
    statusPoller = setInterval(function () {
      pollBlockchainStatus(projectToken)
        .then((res) => {
          switch (res.status) {
            case STATUS_CODE_SUCCESS:
              stopPolling();
              resolve(res);
              break;
            case STATUS_CODE_ACCEPTED:
            default:
          }
        })
        .catch(function (error) {
          reject(error);
          stopPolling();
        });
    }, 5000);
  });
};

export const validateImage = (projectToken, selectedId) => {
  return new Promise((resolve, reject) => {
    store.dispatch(showOrHideApiLoadingModal(true));
    verifyBlockchainStatus(projectToken, selectedId)
      .then((res) => {
        startPollingForBlockchainValidation(projectToken)
          .then(() => {
            resolve();
          })
          .catch(function (error) {
            store.dispatch(showOrHideApiLoadingModal(false));

            store.dispatch(setCanShowErrorAlertModal(true));
            // show "image already exist" alert
            if (error.response.status === 424) {
              store.dispatch(
                setImageErrorMessage(
                  string.THIS_IMAGE_ALREADY_EXISTS_DESCRIPTION
                )
              );
              store.dispatch(setImageAlreadyPresentError(true));
            }

            console.log("ERROR MESSAGE ", error);
            reject(error);
          });
      })
      .catch((error) => {
        store.dispatch(showOrHideApiLoadingModal(false));
        reject(error);
      });
  });
};

export const getCropRectSizeInPixels = (sizeInInches) => {
  let { uploadedImageData } = store.getState().project;
  const { imageScale } = uploadedImageData;
  const widthInPixels = getInchesToPixels(sizeInInches.width) / imageScale;
  const heightInPixels = getInchesToPixels(sizeInInches.height) / imageScale;
  return Size(widthInPixels, heightInPixels);
};

export const dataURLToBlob = (dataURL) => {
  const BASE64_MARKER = ";base64,";
  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    var parts = dataURL.split(",");
    var contentType = parts[0].split(":")[1];
    var raw = decodeURIComponent(parts[1]);
    return new Blob([raw], { type: contentType });
  }
  parts = dataURL.split(BASE64_MARKER);
  contentType = parts[0].split(":")[1];
  raw = window.atob(parts[1]);
  var rawLength = raw.length;
  var uInt8Array = new Uint8Array(rawLength);
  for (var i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }
  return new Blob([uInt8Array], { type: contentType });
};

export const appendTimeStampToPath = (path) => {
  if (path) {
    let parameter = "?timestamp=";
    if (path && path.indexOf("?") >= 0) {
      parameter = "&timestamp=";
    }
    return `${path}${parameter}${Date.now()}`;
  }
  return path;
};

export const getOrientation = (size) => {
  if (size.width > size.height) {
    return LANDSCAPE;
  } else if (size.width < size.height) {
    return PORTRAIT;
  }
  return SQUARE;
};

export const debounce = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 500);
  };
};

export const convertBytesToMegaBytes = (size) => {
  return (size / ONE_MB_IN_BYTES).toFixed(2);
};

export const convertMegaBytesToBytes = (sizeInMb) => sizeInMb * ONE_MB_IN_BYTES;

// export const getImageDimension = (file) => {
//   console.log("file helper", file);
//   return new Promise((resolve) => {
//     let img = new Image();
//     img.src = getPreviewUrlFromFile(file);

//     img.onload = () => {
//       resolve(
//         getPixelsToInches(img.width).toFixed(2) +
//           INCHES_UNIT +
//           " x " +
//           getPixelsToInches(img.height).toFixed(2) +
//           INCHES_UNIT
//       );
//     };
//   });
// };

// doesn't check .tiff and .tif files
export const getImageDimension = (file) => {
  return new Promise(async (resolve, reject) => {
    const extension = file.name.split(".").pop().toLowerCase();
    if (extension === TIF || extension === TIFF) {
      resolve("");
    } else if (extension === PDF) {
      file
        .arrayBuffer()
        .then(async (arrayBuffer) => {
          try {
            const pdfDoc = await PDFDocument.load(arrayBuffer);
            const firstPage = pdfDoc.getPages()[0];
            const { width, height } = firstPage.getSize();

            const dimensions = `${getPixelsToInches(width).toFixed(
              2
            )}${INCHES_UNIT} x ${getPixelsToInches(height).toFixed(
              2
            )}${INCHES_UNIT}`;
            resolve(dimensions);
          } catch (error) {
            reject(error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      let img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const dimensions = `${getPixelsToInches(img.width).toFixed(
          2
        )}${INCHES_UNIT} x ${getPixelsToInches(img.height).toFixed(
          2
        )}${INCHES_UNIT}`;
        resolve(dimensions);
      };

      img.onerror = (error) => {
        resolve("");
      };
    }
  });
};

export const formatDateTime = (date) => {
  date = new Date(String(date));
  const day = date.getDate();
  const monthAndYear = date
    .toLocaleString(string.DATE_LOCALES, {
      month: "long",
      year: "numeric",
    })
    .split(" ");
  return day + " " + monthAndYear[0] + " " + monthAndYear[1];
};

export const getPreviewUrlFromFile = (file, size) => {
  switch (getFileExtension(file.name)) {
    case PDF:
      return Icons.pdfIcon;
    case TIFF:
    case TIF:
      return Icons.tiffIcon;
    case PNG:
    case SVG:
    case JPEG:
      if (convertBytesToMegaBytes(size) < 300 || size === undefined) {
        return window.URL.createObjectURL(file);
      } else {
        return Icons.defaultIcon;
      }
    case JPG:
      if (convertBytesToMegaBytes(size) < 300 || size === undefined) {
        return window.URL.createObjectURL(file);
      } else {
        return Icons.jpgDefault;
      }

    default:
      return Icons.defaultIcon;
  }
};

export const getFileExtension = (file) => {
  return file.split(".").pop();
};

export const insetSize = (inputSize, offset) => {
  let outputSize = inputSize;
  outputSize.width -= 2 * offset;
  outputSize.height -= 2 * offset;
  return outputSize;
};

export const getScaledInchesToPixels = (inches) => {
  let { uploadedImageData } = store.getState().project;
  return getInchesToPixels(inches) / uploadedImageData.imageScale;
};

export const convertMmToInches = (inMM) => {
  return inMM * MM_TO_INCHES;
};

export const convertInchesToMm = (inInches) => {
  return inInches / MM_TO_INCHES;
};

export const convertSelectedUnitSystem = (unit) => {
  return unit === "inches" ? "imperial" : "metric";
};

export const getSelectedSizeInInches = (selectedSizeId) => {
  const { imageDimensions } = store.getState().project;

  const allDimesions = Object.values(imageDimensions);
  const dimensionArray = [].concat.apply([], allDimesions);

  const selectedSize = dimensionArray.filter(
    (dimension) => dimension.id === selectedSizeId
  );
  return selectedSize[0];
};

export const getCropRectDimension = (xoffset, yoffset, selectedSizeId) => {
  const { imageScale } = store.getState().project.uploadedImageData;
  const sizeInInches = getSelectedSizeInInches(selectedSizeId);

  const widthInPixels = getInchesToPixels(sizeInInches.width) / imageScale;
  const heightInPixels = getInchesToPixels(sizeInInches.height) / imageScale;
  return {
    left: xoffset / imageScale,
    top: yoffset / imageScale,
    width: widthInPixels,
    height: heightInPixels,
  };
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getCustomName = (i_status) => {
  let className = "";
  let statusIcon = "";
  const status = i_status?.toLowerCase();
  switch (status) {
    case "accepted":
    case "completed":
    case "success":
    case "printed":
      className = "status-Accepted";
      statusIcon = checkIcon;
      break;
    case "rejected":
    case "failed":
    case "errored":
      className = "status-Rejected";
      statusIcon = closeIcon;
      break;
    case "on hold":
    case "stopped":
    case "print pending":
      className = "status-On-hold";
      statusIcon = pauseIcon;
      break;
    case "processing":
    case "printing":
      className = "status-Processing";
      statusIcon = processingIcon;
      break;
    case "shipped":
      className = "status-Shipped";
      statusIcon = sendIcon;
      break;
    case "cancelled":
      className = "status-Cancelled";
      statusIcon = cancelIcon;
      break;
    case "draft":
      className = "status-Processing";
      statusIcon = editIcon;
      break;
    case "pending":
    case "request intiated":
      className = "status-Processing";
      statusIcon = pendingIcon;
      break;
    default:
      className = "no-status";
      break;
  }

  return { className, statusIcon };
};

export const handleFooterLinks = () => {
  footerLinks(SUPPORTED_COUNTRY).then((res) => {
    store.dispatch(setFooterLinksData(res.data));
  });
};

export const borderOptions = () => [
  {
    value: BORDER_UNIT_VALUE_FIRST,
    active: !checkIfPhotoSizeIsZero(BORDER_UNIT_VALUE_FIRST),
  },
  {
    value: BORDER_UNIT_VALUE_SECOND,
    active: !checkIfPhotoSizeIsZero(BORDER_UNIT_VALUE_SECOND),
  },
  {
    value: BORDER_UNIT_VALUE_THIRD,
    active: !checkIfPhotoSizeIsZero(BORDER_UNIT_VALUE_THIRD),
  },
  {
    value: BORDER_UNIT_VALUE_FOURTH,
    active: !checkIfPhotoSizeIsZero(BORDER_UNIT_VALUE_FOURTH),
  },
  {
    value: BORDER_UNIT_VALUE_FIFTH,
    active: !checkIfPhotoSizeIsZero(BORDER_UNIT_VALUE_FIFTH),
  },
  {
    value: BORDER_UNIT_VALUE_SIXTH,
    active: !checkIfPhotoSizeIsZero(BORDER_UNIT_VALUE_SIXTH),
  },
];

export const checkIfPhotoSizeIsZero = (border) => {
  const { sizeSelectionDetails, borderWidth } = store.getState().project;
  const { selectedDimension } = sizeSelectionDetails;
  const { width, height } = selectedDimension;
  let borderSize = border ? border : borderWidth;

  const photoWidth = width - 2 * borderSize;
  const photoHeight = height - 2 * borderSize;
  return photoWidth <= 0 || photoHeight <= 0;
};

export const getHostUrl = () => {
  return window.location.protocol + "//" + window.location.host;
};

export const showAdminSearchBox = (userSearchKey, recordCount) => {
  return !(userSearchKey === "") || (userSearchKey === "" && recordCount > 0);
};

export const getCurrentYear = () => new Date().getFullYear();
