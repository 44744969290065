import React, { useEffect, useState } from "react";
import "./Information.scss";
import { string } from "../../../Localization";
import { generateAutoCompletedMessage } from "../../../Utils/ApiHelper";
import ProjectLoader from "../../../Components/Loaders/ProjectLoader/ProjectLoader";
import { showOrHideApiLoadingModal } from "../../../Store/Project/ModalSlice";
import { useDispatch, useSelector } from "react-redux";
import { sendGAGenerateDescriptionWithAI } from "../../../Utils/GAHelper";

const Information = (props) => {
  const { inputTitle, inputDescription } = props;
  const dispatch = useDispatch();

  const { currentUserDetails } = useSelector((state) => state.user);

  const [title, setTitle] = useState(inputTitle);
  const [description, setDescription] = useState(inputDescription);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [showOpenAi, setShowOpenApi] = useState(false);

  useEffect(() => {
    if (currentUserDetails?.open_ai) {
      setShowOpenApi(currentUserDetails?.open_ai);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTitle(inputTitle.replace(/\.$/, ""));
  }, [inputTitle]);

  useEffect(() => {
    setDescription(inputDescription);
  }, [inputDescription]);

  const handleTitleChange = (inTitle) => {
    setTitle(inTitle);
    props.onChangeInfo(inTitle, description);
  };

  const handleDescriptionChange = (desc) => {
    setDescription(desc);
    props.onChangeInfo(title, desc);
  };

  const getSuggestedMessage = () => {
    setDisabledBtn(true);
    dispatch(showOrHideApiLoadingModal(true));
    sendGAGenerateDescriptionWithAI();
    generateAutoCompletedMessage(title)
      .then((res) => {
        handleDescriptionChange(res?.data?.data);
        setDisabledBtn(false);
        dispatch(showOrHideApiLoadingModal(false));
      })
      .catch((err) => {
        setDisabledBtn(false);
        dispatch(showOrHideApiLoadingModal(false));
      });
  };

  return (
    <>
      <ProjectLoader />
      <div className="info-underLine"></div>
      <div className="information-container">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.33337 10.3333H7.66671V6.33334H6.33337V10.3333ZM7.00004 5.00001C7.18893 5.00001 7.34737 4.93601 7.47537 4.80801C7.60293 4.68045 7.66671 4.52223 7.66671 4.33334C7.66671 4.14445 7.60293 3.98601 7.47537 3.85801C7.34737 3.73045 7.18893 3.66668 7.00004 3.66668C6.81115 3.66668 6.65293 3.73045 6.52537 3.85801C6.39737 3.98601 6.33337 4.14445 6.33337 4.33334C6.33337 4.52223 6.39737 4.68045 6.52537 4.80801C6.65293 4.93601 6.81115 5.00001 7.00004 5.00001ZM7.00004 13.6667C6.07782 13.6667 5.21115 13.4916 4.40004 13.1413C3.58893 12.7916 2.88337 12.3167 2.28337 11.7167C1.68337 11.1167 1.20849 10.4111 0.858707 9.60001C0.508485 8.7889 0.333374 7.92223 0.333374 7.00001C0.333374 6.07779 0.508485 5.21112 0.858707 4.40001C1.20849 3.5889 1.68337 2.88334 2.28337 2.28334C2.88337 1.68334 3.58893 1.20823 4.40004 0.85801C5.21115 0.508232 6.07782 0.333344 7.00004 0.333344C7.92226 0.333344 8.78893 0.508232 9.60004 0.85801C10.4112 1.20823 11.1167 1.68334 11.7167 2.28334C12.3167 2.88334 12.7916 3.5889 13.1414 4.40001C13.4916 5.21112 13.6667 6.07779 13.6667 7.00001C13.6667 7.92223 13.4916 8.7889 13.1414 9.60001C12.7916 10.4111 12.3167 11.1167 11.7167 11.7167C11.1167 12.3167 10.4112 12.7916 9.60004 13.1413C8.78893 13.4916 7.92226 13.6667 7.00004 13.6667ZM7.00004 12.3333C8.48893 12.3333 9.75004 11.8167 10.7834 10.7833C11.8167 9.75001 12.3334 8.4889 12.3334 7.00001C12.3334 5.51112 11.8167 4.25001 10.7834 3.21668C9.75004 2.18334 8.48893 1.66668 7.00004 1.66668C5.51115 1.66668 4.25004 2.18334 3.21671 3.21668C2.18337 4.25001 1.66671 5.51112 1.66671 7.00001C1.66671 8.4889 2.18337 9.75001 3.21671 10.7833C4.25004 11.8167 5.51115 12.3333 7.00004 12.3333Z"
            fill="#DBDBDB"
          />
        </svg>
        <div className="info-text">{string.INFORMATION_DESCRIPTION}</div>
      </div>

      <div className="info-items">
        {" "}
        <div autoComplete="off" className="text-box">
          <label htmlFor="">{string.TITLE}*</label>
          <input
            type="text"
            id="infofocus"
            className="input-title"
            autoFocus
            maxlength="40"
            placeholder={string.ADD_HERE_A_TITLE_FOR_YOUR_PHOTO}
            value={title}
            onChange={(e) => {
              handleTitleChange(e.target.value.slice(0, 40));
            }}
          />
          <div className="max-characters">{string.MAXIMUM_CHARACTERS}: 40</div>
          {showOpenAi && (
            <button
              className={
                disabledBtn
                  ? "generate-desc-btn disabled-btn"
                  : "generate-desc-btn"
              }
              onClick={getSuggestedMessage}
            >
              {string.GENERATE_DESCRIPTION_THROUGH_AI}
            </button>
          )}
        </div>
        <div className="text-box">
          <label htmlFor="">{string.DESCRIPTION}</label>
          <textarea
            className="input-description input-title"
            maxlength="500"
            placeholder={string.ADD_HERE_A_DESCRIPTION_FOR_YOUR_PHOTO}
            style={{
              minHeight: "32px",
              maxHeight: "220px",
            }}
            value={description}
            onChange={(e) => {
              handleDescriptionChange(e.target.value.slice(0, 500));
            }}
          />
          <div className="max-characters">{string.MAXIMUM_CHARACTERS}: 500</div>
        </div>
      </div>
    </>
  );
};

export default Information;
