import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import InfiniteScrollLoader from "../Loaders/InfiniteScrollLoader";
import "./Feed.scss";
import { showOrHideApiLoadingModal } from "../../Store/Project/ModalSlice";
import { useDispatch } from "react-redux";
import ProjectLoader from "../Loaders/ProjectLoader/ProjectLoader";
import { string } from "../../Localization";
import PublicViewLoader from "../Loaders/PublicViewLoader/PublicViewLoader";

const Feed = (props) => {
  const [page, setPage] = useState(0);
  const [moreDataPresent, setMoreDataPresent] = useState(true);
  const [dataLength, setDataLength] = useState(30);
  const [showLoader, setShowLoader] = useState(false);

  const { data, setData, getFeedDataFromAPI, feedCardDetails, isLightMode } =
    props;

  const dispatch = useDispatch();

  useEffect(() => {
    fetchFeedData();
  }, []);

  const fetchFeedData = async () => {
    toggleLoader(true);
    const feedData = await getFeedDataFromAPI(page, 30);

    toggleLoader(false);
    setData([...data, ...feedData?.feeds_arr]);
    setPage(page + 1);
    setDataLength(dataLength + feedData?.feeds_arr?.length);
    if (feedData?.feeds_arr?.length === 0) {
      setMoreDataPresent(false);
    }
  };

  const toggleLoader = (shouldShow) => {
    if (isLightMode) {
      setShowLoader(shouldShow);
    } else {
      dispatch(showOrHideApiLoadingModal(shouldShow));
    }
  };

  return (
    <>
      <ProjectLoader />
      <div className="feed-container" id="feed-container">
        {data.length > 0 ? (
          <InfiniteScroll
            dataLength={dataLength}
            next={() => {
              fetchFeedData();
            }}
            hasMore={moreDataPresent}
            scrollableTarget="feed-container"
            loader={<InfiniteScrollLoader />}
            endMessage={""}
          >
            <div
              className="feed-wrapper"
              style={{ backgroundColor: isLightMode ? "#eeeeee" : "#1a1e22" }}
            >
              {data?.map((i_data) => (
                <div className="feed-thumbnail" key={i_data.id}>
                  <img
                    src={i_data.thumbnail}
                    alt=""
                    className="thumbnail mb-2"
                  />
                  {feedCardDetails(i_data)}
                </div>
              ))}
            </div>
          </InfiniteScroll>
        ) : (
          !isLightMode && <p className="empty-feeds">{string.NO_FEEDS_FOUND}</p>
        )}
        {isLightMode && showLoader && <PublicViewLoader />}
      </div>
    </>
  );
};

export default Feed;
