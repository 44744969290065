import React from "react";
import { getCustomName } from "../../../Utils/Helper";

const StatusColumn = ({ status }) => {
  const { className, statusIcon } = getCustomName(status);

  return (
    <div className="d-flex justify-content-center">
      <div className={className + " status-column"}>
        <span className="status-icon">
          {statusIcon && <img src={statusIcon} alt={status} />}
        </span>
        <span> {status}</span>
      </div>
    </div>
  );
};

export default StatusColumn;
