import React from "react";
import { string } from "../../../../Localization";
import editIcon from "../../../../Utils/Icons/edit_black.svg";
import "./CustomerInfo.scss";
import { useDispatch } from "react-redux";
import {
  toggleEditAddressModal,
  toggleEditCustomerModal,
} from "../../../../Store/Project/ModalSlice";
import { useNavigate } from "react-router-dom";
import {
  sendGACollectorClickedEditCustomerDetails,
  sendGACollectorclickedEditDeliveryAddress,
} from "../../../../Utils/GAHelper";

const CustomerInfo = (props) => {
  const { projectSummaryData, orderToken, isCouponUsed } = props;
  const order = projectSummaryData?.order;
  const address = order?.address;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="review-main-container">
      <div className="review-head ">
        <div className="review-details-title">{string.ADDRESS_DETAILS}</div>
        <div
          onClick={() => {
            sendGACollectorclickedEditDeliveryAddress();
            dispatch(toggleEditAddressModal(true));
          }}
        >
          <img src={editIcon} alt="edit" className="edit-icon" />
        </div>
      </div>
      <div className="review-address-details">
        <div className="details">
          <div className="input-label">{string.COUNTRY}:</div>
          <div className="input-value">{address?.country}</div>
        </div>
        <div className="details">
          <div className="input-label">{string.POST_CODE}:</div>
          <div className="input-value">{address?.zip_code}</div>
        </div>
        <div className="details">
          <div className="input-label">{string.ADDRESS_LINE}:</div>
          <div className="input-value">{address?.address_line1}</div>
        </div>
        <div className="details">
          <div className="input-label">{string.TOWN_CITY}:</div>
          <div className="input-value">{address?.city}</div>
        </div>
        <div className="details">
          <div className="input-label">{string.COUNTY}:</div>
          <div className="input-value">{address?.region}</div>
        </div>
      </div>
      <div className="review-customer-details-container">
        <div className="review-head">
          <div className="review-details-title">{string.CUSTOMER_DETAILS}</div>
          <div
            onClick={() => {
              sendGACollectorClickedEditCustomerDetails();
              dispatch(toggleEditCustomerModal(true));
            }}
          >
            <img src={editIcon} alt="edit" className="edit-icon" />
          </div>
        </div>
        <div className="review-address-details">
          <div className="details">
            <div className="input-label">{string.FIRST_NAME}:</div>
            <div className="input-value">{order?.first_name}</div>
          </div>
          <div className="details">
            <div className="input-label">{string.LAST_NAME}:</div>
            <div className="input-value">{order?.last_name}</div>
          </div>
          <div className="details">
            <div className="input-label">{string.PHONE_NUMBER}:</div>
            <div className="input-value">{order?.contact_number}</div>
          </div>
          <div className="details">
            <div className="input-label">{string.E_MAIL_ADDRESS}:</div>
            <div className="input-value">{order?.email}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerInfo;
