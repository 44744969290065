import { string } from "../../../../Localization";

const UKFormat = (props) => {
  const {
    orderData,
    setOrderData,
    alertMessage,
    addressLine1,
    setAddressLine1,
  } = props;
  const { postalCode, addressLine2, city, region } = orderData;

  return (
    <>
      <div className="input-block">
        <div className="input-label">
          {string.ZIP_CODE} / {string.POST_CODE}
        </div>
        <div>
          <span>
            <input
              type="text"
              className="input-box"
              value={postalCode ? postalCode : ""}
              placeholder="00000"
              onChange={(e) => {
                setOrderData({
                  ...props.orderData,
                  postalCode: e.currentTarget.value,
                });
              }}
            />
          </span>
        </div>
        <div className="error-div">{alertMessage[string.POST_CODE]}</div>
      </div>

      <div className="input-block">
        <div className="input-label">{string.ADDRESS_LINE_1}</div>
        {/* <span>
          <AutocompleteComponent
            countryCode={country.countryCode}
            postalCode={postalCode}
            project_token={project_token}
            setAddressLine1={setAddressLine1}
          />
        </span> */}
        <span>
          <input
            type="text"
            className="input-box"
            value={addressLine1 ? addressLine1 : ""}
            onChange={(e) => {
              setAddressLine1(e.currentTarget.value);
            }}
          />
        </span>
        <div className="error-div">{alertMessage[string.ADDRESS_LINE_1]}</div>
      </div>

      <div className="input-block">
        <div className="input-label">
          {string.ADDRESS_LINE_2 + " " + string.OPTIONAL}
        </div>
        <span>
          <input
            type="text"
            className="input-box"
            value={addressLine2 ? addressLine2 : ""}
            onChange={(e) => {
              setOrderData({
                ...props.orderData,
                addressLine2: e.currentTarget.value,
              });
            }}
          />
        </span>
      </div>

      <div className="input-block">
        <div className="input-label">{string.TOWN_CITY}</div>
        <span>
          <input
            type="text"
            className="input-box"
            value={city ? city : ""}
            placeholder={string.TYPE_YOUR_CITY}
            onChange={(e) => {
              setOrderData({
                ...props.orderData,
                city: e.currentTarget.value,
              });
            }}
          />
        </span>
        <div className="error-div">{alertMessage[string.TOWN_CITY]}</div>
      </div>

      <div className="input-block">
        <div className="input-label">{string.COUNTY}</div>
        <span>
          <input
            type="text"
            className="input-box"
            value={region ? region : ""}
            onChange={(e) => {
              setOrderData({
                ...props.orderData,
                region: e.currentTarget.value,
              });
            }}
          />
        </span>
      </div>
    </>
  );
};

export default UKFormat;
