import React, { useEffect, useState } from "react";
import "./SystemDownMessage.scss";
import closeBtn from "../../Utils/Icons/system-down-close.svg";
import infoBtn from "../../Utils/Icons/system-down-info.svg";
import { checkDeploymentStatus } from "../../Utils/ApiHelper";

const SystemDownMessage = () => {
  const [message, setMessage] = useState("");
  const [deploymentFlag, setDeploymentFlag] = useState(false);

  useEffect(() => {
    checkDeploymentStatus()
      .then((res) => {
        setDeploymentFlag(res?.data?.data?.deployment_flag);
        setMessage(res?.data?.data?.message);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  return (
    <>
      {deploymentFlag ? (
        <div className="system_down_message">
          <div className="system_down_message_container">
            <div className="system_down_info">
              <img src={infoBtn} alt="" className="system_down_info_img" />
            </div>
            <div className="system_down_info_text">{message}</div>
          </div>
          <div
            className="system_down_message_img"
            onClick={() => {
              setDeploymentFlag(false);
            }}
          >
            <img src={closeBtn} alt="" className="system_down_close_img" />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default SystemDownMessage;
