import React, { useState } from "react";
import "./AdminSearchBar.scss";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserSearch,
  setUserSearchKey,
} from "../../../Store/Project/AdminSlice";
import { DESCENDING_ORDERS, METRICS_TAB } from "../../../Utils/Constants";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

const AdminSearchBar = (props) => {
  const { options, setUserTypeKey, setUserColumnKey } = props;
  const { adminTabDetails } = useSelector((state) => state.admin);

  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState(options[0].label);
  const [searchValue, setSearchValue] = useState("");
  const [warning, setWarning] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);

  const currentDate = new Date();

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "transparent",
      borderRadius: "8px 0px 0px 8px",
      borderColor: "#737373",
      color: "black",
      "&:hover": {
        borderColor: "#737373",
        boxShadow: "none",
      },

      boxShadow: "none",
      "&:focus": {
        borderColor: "#737373",
        boxShadow: "none",
      },
      width: "100%",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: "6px",
      border: "1px solid #737373",
      boxShadow: "none",
      scrollbarWidth: "none",
      maxHeight: "300px",
      overflow: "scroll",
      marginTop: "-1px",

      "&::-webkit-scrollbar": {
        display: "none",
      },
    }),
    menuList: (base) => ({
      ...base,
      color: "black",
      marginTop: "0px",
      padding: "0px",
      margin: "0px",
      border: "none",
      boxShadow: "none",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        width: "2px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#1a1e22",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#353535",
      },
    }),
    placeholder: (base) => ({
      ...base,
      color: "#F8F8F8",
    }),
    singleValue: (base) => ({
      ...base,
      color: "#F8F8F8",
    }),
    input: (base) => ({
      ...base,
      color: "#F8F8F8",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    option: (base, state) => ({
      ...base,
      border: "none",
      boxShadow: "none",
      backgroundColor: state.isFocused ? "#353535" : "#1a1e22",
      color: state.isFocused ? "white" : "#DBDBDB",
      "&:hover": {
        backgroundColor: "#353535",
        color: "white",
      },
      "&:active": {
        backgroundColor: "#353535",
        color: "white",
      },
    }),
  };
  const handleSearch = () => {
    if (searchValue.trim()) {
      setWarning("");
      dispatch(setUserSearch(searchValue));
      dispatch(setUserTypeKey(DESCENDING_ORDERS));
      dispatch(
        setUserSearchKey(
          selectedOption.value ? selectedOption.value : options[0].value
        )
      );
    } else {
      setWarning("Invalid date format. Please use YYYY-MM-DD.");
    }
  };

  const handleChange = (e) => {
    setWarning("");
    setSearchValue(e.target.value);
    if (e.target.value.trim() === "") {
      setSearchValue("");
      dispatch(setUserTypeKey(""));
      dispatch(setUserColumnKey(""));
      setSelectedOption(options[0].label);
      dispatch(setUserSearch(""));
      dispatch(setUserSearchKey(""));
      setSelectedDate(null);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };
  return (
    <div
      className={`admin_drop_down_container ${
        adminTabDetails === METRICS_TAB ? "admin-input-2" : ""
      }`}
    >
      <div className="admin_dropdown">
        <Select
          id="country"
          name="country"
          placeholder={selectedOption}
          options={options}
          value={selectedOption}
          styles={customStyles}
          onChange={(value) => {
            setSelectedOption(value);
          }}
        />
      </div>

      <div className="admin_search_input">
        {adminTabDetails === METRICS_TAB ? (
          <ReactDatePicker
            className="admin_search_field custom-datepicker"
            selected={selectedDate}
            onChange={(date) => {
              setWarning("");
              const formattedDate = date ? format(date, "yyyy-MM-dd") : "";
              setSearchValue(formattedDate);
              setSelectedDate(date);

              if (date === null) {
                setSearchValue("");
                dispatch(setUserTypeKey(""));
                dispatch(setUserColumnKey(""));
                setSelectedOption(options[0].label);
                dispatch(setUserSearch(""));
                dispatch(setUserSearchKey(""));
              }
            }}
            placeholderText="Select Date: YYYY-MM-DD"
            dateFormat="yyyy-MM-dd"
            onKeyDown={handleKeyPress}
            maxDate={currentDate}
          />
        ) : (
          <input
            type="text"
            placeholder="Search..."
            onChange={(e) => handleChange(e)}
            onKeyDown={handleKeyPress}
            className="admin_search_field"
          />
        )}
        <div
          className="search_icon fa fa-search"
          style={{ color: "white", fontSize: "17px" }}
          onClick={() => handleSearch()}
        ></div>
        <div>{warning && <p className="warning-text">{warning}</p>}</div>
      </div>
    </div>
  );

  // return (
  //   <div
  //     className={`admin_drop_down_container ${
  //       adminTabDetails === METRICS_TAB ? "admin-input-2" : ""
  //     }`}
  //   >
  //     <div className="admin_dropdown">
  //       <Select
  //         id="country"
  //         name="country"
  //         placeholder={selectedOption}
  //         options={options}
  //         value={selectedOption}
  //         styles={customStyles}
  //         onChange={(value) => {
  //           setSelectedOption(value);
  //         }}
  //       />
  //     </div>

  //     <div className="admin_search_input">
  //       <input
  //         type="text"
  //         placeholder={
  //           adminTabDetails === METRICS_TAB ? "YYYY-MM-DD" : "Search..."
  //         }
  //         onChange={(e) => handleChange(e)}
  //         onKeyDown={handleKeyPress}
  //         className="admin_search_field"
  //       />
  //       <div
  //         className="search_icon fa fa-search"
  //         style={{ color: "white", fontSize: "17px" }}
  //         onClick={() => handleSearch()}
  //       ></div>
  //       <div>{warning && <p className="warning-text">{warning}</p>}</div>
  //     </div>
  //   </div>
  // );
};

export default AdminSearchBar;
