import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import dropdown from "../../Utils/Icons/profile-drop-down.svg";
import "./ProfileButton.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCanShowAlertModal } from "../../Store/Project/ProjectSlice";
import {
  sendGAClickedLogout,
  sendGAClickedProfileDropdown,
  sendGALoadedProfileSettings,
} from "../../Utils/GAHelper";
import { setCurrentUserDetails } from "../../Store/Project/UserSlice";
import { getCurrentUser, signOut } from "../../Utils/ApiHelper";
import DialogBox from "../DialogBox/DialogBox";
import { redirectToHpSignOutPage } from "../../Utils/Helper";
import { string } from "../../Localization";

function stringToColor() {
  let color = "#176267";
  return color;
}

const ProfileButton = () => {
  const dispatch = useDispatch();
  const { currentUserDetails } = useSelector((state) => state.user);
  const { canShowAlertModal } = useSelector((state) => state.project);
  const navigate = useNavigate();

  const [profile, setProfile] = useState(false);
  const [spacing, setSpacing] = useState(0);

  const firstname = currentUserDetails?.first_name || " ";
  const lastname = currentUserDetails?.last_name || "";
  const email = currentUserDetails?.email || "";
  const isAdmin = currentUserDetails?.is_admin;
  const trimmedFirstName = firstname.trim();
  const trimmedLastName = lastname.trim();
  const splitFirstName = trimmedFirstName.split(" ");
  const firstLetter = splitFirstName[0].charAt(0);
  const lastLetter = splitFirstName[splitFirstName.length - 1].charAt(0);
  const letters = `${firstLetter}${lastLetter}`;
  const name = firstname.trim() + " " + trimmedLastName;

  useEffect(() => {
    if (!currentUserDetails) {
      getCurrentUser().then((res) => {
        dispatch(setCurrentUserDetails(res.data.data));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (name && name === "") {
      setSpacing(name.split(" ").length);
    } else {
      setSpacing(-1);
    }
  }, [name]);

  function stringAvatar(name) {
    const trimmedName = name.trim();
    if (!trimmedName) {
      return;
    } else {
      const nameArr = trimmedName.split(" ");
      if (nameArr.length === 1) {
        return {
          sx: {
            bgcolor: stringToColor(),
          },
          children: trimmedName[0].toUpperCase(),
        };
      } else {
        const firstName = trimmedFirstName;
        const lastName =
          trimmedLastName === "" ? "" : trimmedLastName.charAt(0);

        const initials = lastName
          ? `${firstName.charAt(0)}${lastName}`
          : letters;
        return {
          sx: {
            bgcolor: stringToColor(),
          },
          children: initials.toUpperCase(),
        };
      }
    }
  }

  const actionLogout = () => {
    redirectToHpSignOutPage();
  };

  const cancelHandler = () => {
    dispatch(setCanShowAlertModal(false));
  };

  return (
    <div className="profile-wrap">
      <div className="profile-button-parent">
        <div
          className="profileButtonContainer"
          onClick={() => {
            setProfile(true);
            sendGAClickedProfileDropdown();
          }}
        >
          <Stack direction="row" spacing={spacing}>
            <Avatar {...stringAvatar(name)} className="avatar-text" />
          </Stack>
          <div className="profile-wrap">
            <div className="profileName">{name}</div>
            <div className="profileDropdown">
              <img src={dropdown} alt="" />
            </div>
          </div>
        </div>
        {profile === true ? (
          <>
            <div className="profile-drop-down">
              <div className="profile-drop-container">
                <Stack direction="row" spacing={spacing}>
                  <Avatar {...stringAvatar(name)} />
                </Stack>
                <div className="profile-drop-namemail">
                  <div className="profile-drop-name">{name}</div>
                  <div className="profile-drop-email">{email}</div>
                </div>
              </div>
              {currentUserDetails?.beta_user &&
                !currentUserDetails?.unsubscribed && (
                  <div
                    className="profile-account"
                    onClick={() => {
                      navigate("/profile");
                      sendGALoadedProfileSettings();
                    }}
                  >
                    <div className="profile-account-my">
                      {string.MY_ACCOUNT}
                    </div>
                  </div>
                )}
              {isAdmin && (
                <>
                  <div
                    className="profile-account"
                    onClick={() => {
                      window.open(
                        window.location.origin + "/admin",
                        "_self"
                      );
                    }}
                  >
                    <div className="profile-account-my">{string.ADMIN}</div>
                  </div>
                </>
              )}
              <a
                href="mailto:hpsupport.limitededitions@hp.com"
                target="_blank"
                className="support-profile"
                rel="noopener noreferrer"
              >
                <div className="profile-account">
                  <div className="profile-account-my"> {string.SUPPORT} </div>
                </div>
              </a>
              <div
                className="profile-account"
                onClick={() => {
                  dispatch(setCanShowAlertModal(true));
                  setProfile(false);
                  sendGAClickedLogout();
                }}
              >
                <div className="profile-account-my">{string.LOGOUT}</div>
              </div>
            </div>
            <div className="overlay" onClick={() => setProfile(false)}></div>
          </>
        ) : (
          ""
        )}
        <DialogBox
          canShow={canShowAlertModal}
          heading={string.LOGOUT}
          text1={string.ARE_YOU_SURE_YOU_WANT_TO_LOGOUT}
          text2={string.YOU_WONT_LOSE_ANYTHING_WE_SAVED_BEFORE}
          submitData={{
            submitText: string.LOGOUT,
            submitHandler: actionLogout,
            cancelBtn: string.CANCEL,
            cancelHandler: cancelHandler,
          }}
        />
      </div>
    </div>
  );
};

export default ProfileButton;
