import NavigateBackButton from "../../Components/NavigateBackButton/NavigateBackButton";
import ProfileButton from "../ProfileButton/ProfileButton";
import "./TopBar.scss";

const TopBar = ({ pageTitle, onNavigateBack }) => {
  return (
    <div className="top-bar-container">
      <div
        className="d-flex justify-content-between"
        style={{ width: "540px" }} //same width as photo-preview width
      >
        <NavigateBackButton onNavigateBack={onNavigateBack} />
        <div className="title-container d-flex justify-content-around w-100">
          <span className="page-title">{pageTitle}</span>
        </div>
      </div>
      <ProfileButton />
    </div>
  );
};

export default TopBar;
