import React, { useEffect, useState } from "react";
import "./PublicGallery.scss";
import internalFeedCoverMobile from "../../Utils/Icons/internalFeedCoverMobile.png";
import internalFeedCoverDesktop from "../../Utils/Icons/internalFeedCoverDesktop.png";
import public_image from "../../Utils/Icons/public_image.png";
import internalFeedLogo from "../../Utils/Icons/hplogo.svg";
import PhotographerDetails from "../PhotographerDetails/PhotographerDetails";
import leftArrow from "../../Utils/Icons/carouselPrev.svg";
import rightArrow from "../../Utils/Icons/carouselNext.svg";
import { string } from "../../Localization";
import Footer from "../LandingPage/Footer/Footer";
import { retrievePhotographers } from "../../Utils/ApiHelper";
import HpLogoBlue from "../../Components/HpLogoBlue/HpLogoBlue";
import { sendGALoadedGalleryHome } from "../../Utils/GAHelper";
import { useNavigate, useSearchParams } from "react-router-dom";

const PublicGallery = () => {
  const [carouselItems, setCarouselItems] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);
  const [carouselBtn, setCarouselBtn] = useState(window.innerWidth <= 811);
  // const [carouselItemWidth, setCarouselItemWidth] = useState();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const queryStrings = Object.fromEntries([...searchParams]);

  useEffect(() => {
    sendGALoadedGalleryHome(queryStrings["utm_source"] ?? "");
    retrievePhotographers().then((res) => {
      setCarouselItems(res);
    });
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollBy(0, -window.scrollY);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 600);
    setCarouselBtn(window.innerWidth <= 811);
  };

  // useEffect(() => {
  //   setCarouselItemWidth(document.querySelector(".product_container"));
  // }, []);

  const btnPressPrev = () => {
    const scrollConatiner = document.querySelector(".product_container");
    let width = 380;
    if (scrollConatiner) {
      scrollConatiner.scrollLeft = scrollConatiner.scrollLeft - width;
    }
  };
  const btnPressNext = () => {
    const scrollConatiner = document.querySelector(".product_container");
    let width = 380;
    if (scrollConatiner) {
      scrollConatiner.scrollLeft = scrollConatiner.scrollLeft + width;
    }
  };

  return (
    <div className="photographer_landing_page_container">
      {isSmallScreen && <HpLogoBlue />}

      <div className="photographer_landing_page_wrapper">
        <div className="photographer_landing_page_contant_1">
          <img
            src={
              !isSmallScreen
                ? internalFeedCoverDesktop
                : internalFeedCoverMobile
            }
            alt="internalFeedCoverDesktop"
            className={
              !isSmallScreen
                ? "landing_page_cover"
                : "landing_page_cover_mobile"
            }
          />

          <div className="logo  d-flex align-items-center beta-text">
            <img
              src={internalFeedLogo}
              alt="internalFeedLogo"
              className=" cursor-pointer"
              style={{ height: "53px" }}
              onClick={() => navigate("/?utm_source=gallery")}
            />
            <span>{string.AN_HP_DEVELOPMENT_BETA}</span>
          </div>
          <button className="public_section4_content">
            <a href="#Become_member" className="button_public_top_link">
              {string.BECOME_MEMBER}
            </a>
          </button>
        </div>
        <div className="photographer_landing_page_contant_2">
          <div className="limited_editions_gallery">
            <div className="limited_editions_gallery_heading">
              {string.HP_LIMITED_EDITIONS_GALLERY}
            </div>
            <div className="limited_editions_gallery_body">
              <div className="limited_editions_gallery_text1">
                {string.HP_LIMITED_EDITIONS_GALLERY_TITLE}
              </div>
              <div className="limited_editions_gallery_text2">
                <div className="limited_editions_gallery_sub_txt1">
                  {string.HP_LIMITED_EDITIONS_GALLERY_TEXT1a}{" "}
                </div>
                <div className="limited_editions_gallery_sub_txt2">
                  <div className="limited_editions_gallery_sub_txt2a">
                    <b>{string.HP_LIMITED_EDITIONS_GALLERY_TEXT2a}</b>{" "}
                    {string.HP_LIMITED_EDITIONS_GALLERY_TEXT2b}
                  </div>
                  <div className="limited_editions_gallery_sub_txt2a">
                    {string.SELL} <b>{string.INTERNATIONALLY}</b>{" "}
                    {string.HP_LIMITED_EDITIONS_GALLERY_TEXT2c}
                  </div>
                  <div className="limited_editions_gallery_sub_txt2a">
                    {string.HP_LIMITED_EDITIONS_GALLERY_TEXT2d}{" "}
                    <b>{string.HP_LIMITED_EDITIONS_GALLERY_TEXT2e}</b>{" "}
                    {string.HP_LIMITED_EDITIONS_GALLERY_TEXT2f}
                  </div>
                  <div className="limited_editions_gallery_sub_txt2a">
                    {string.HP_LIMITED_EDITIONS_GALLERY_TEXT2g}{" "}
                    <b>{string.PROXIMITY}</b>{" "}
                    {string.HP_LIMITED_EDITIONS_GALLERY_TEXT2h}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <>
            {carouselItems.length > 0 && (
              <div className="carousel_container">
                <div className="carousel_text">
                  <div className="carousel_text_heading">
                    {string.DISCOVER_OUR_FOUNDING_ARTISTS}
                  </div>
                </div>{" "}
                <div className="product_carousel">
                  {!carouselBtn && carouselItems.length > 1 && (
                    <div className="carousel_button">
                      <button className="pre_btn" onClick={btnPressPrev}>
                        <img
                          src={leftArrow}
                          alt="leftArrow"
                          className="leftArrow"
                        />
                      </button>
                      <button className="next_btn" onClick={btnPressNext}>
                        <img
                          src={rightArrow}
                          alt="rightArrow"
                          className="rightArrow"
                        />
                      </button>
                    </div>
                  )}

                  <div className="product_container">
                    {carouselItems.map((item, index) => (
                      <PhotographerDetails
                        key={index}
                        profilePic={item?.display_image}
                        artistName={item.artist_name}
                        description={item.biography}
                        userId={item?.id}
                        showViewGallery={item?.design_available}
                        userName={item?.user_name}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </>
        </div>
        <div className="public_section4" id="Become_member">
          <div className="public_section4_container1">
            <div className="public_section4_content1">
              {string.BECOME_MEMBER_QUE}
            </div>
            <div className="public_section4_content2">
              {string.BECOME_MEMBER_TEXT}
            </div>
            <button className="public_section4_content3">
              <a href="/?utm_source=gallery" className="button_public_link">
                {string.BECOME_MEMBER}
              </a>
            </button>
          </div>
          <div className="public_section4_img">
            <img src={public_image} alt="image" />
          </div>
        </div>
        <div className="photography_gallery_footer">
          <div className="photography_gallery_inner_container">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicGallery;
