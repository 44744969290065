import React from "react";
import "../SearchByAI/SearchByAI.scss";
import SearchBlub from "../../Utils/Icons/lightbulb.svg";
import { useDispatch, useSelector } from "react-redux";
import { showOrHideUploadAIOptionModal } from "../../Store/Project/ModalSlice";
import UploadOptionsModal from "../Modal/UploadOptionsModal/UploadOptionsModal";

const UploadOptions = () => {
  const { canShowUploadAIOptionModal } = useSelector((state) => state.modal);

  const dispatch = useDispatch();

  const handleModalOpen = () => {
    dispatch(showOrHideUploadAIOptionModal(true));
  };

  return (
    <>
      <div className="search-by-ai-conatiner" onClick={handleModalOpen}>
        <img src={SearchBlub} className="search-icon" alt="Search Icon" />
        <p className="search-text">Create Photo with AI</p>
      </div>
      <UploadOptionsModal isOpen={canShowUploadAIOptionModal} />
    </>
  );
};

export default UploadOptions;
