import React, { useEffect, useState } from "react";
import CreateNew from "../../Components/CreateNew/CreateNew";
import { useDispatch, useSelector } from "react-redux";
import { string } from "../../Localization";
import Card from "./Card";
import { setCanShowBackAlertModal } from "../../Store/Project/ProjectSlice";
import DialogBox from "../DialogBox/DialogBox";
import StopSelling from "./StartOrStopSelling/StopSelling";
import StartSelling from "./StartOrStopSelling/StartSelling";
import SuperResolutionModal from "../../Components/Modal/SuperResolutionModal/SuperResolutionModal";
import { pollPendingProjects } from "../../Utils/ApiHelper";
import UploadOptions from "../../Components/UploadOptions/UploadOptions";

const TabContent = (props) => {
  const {
    items,
    heading,
    UploadFile,
    isPageLoading,
    handleDraft,
    loadPhotoPrintDetails,
    shouldShowUpload,
    handleDelete,
    projectCount,
    setProjectCount,
    initializeGallery,
  } = props;

  const { canShowResolutionModal } = useSelector((state) => state.project);
  const { currentUserDetails } = useSelector((state) => state.user);
  const { CanShowBackAlertModal } = useSelector((state) => state.project);

  const [showOpenAi, setShowOpenApi] = useState(false);
  const [selectedToken, setSelectedToken] = useState("");
  const [publicUrl, setPublicUrl] = useState();
  const [showStopSellingModal, setShowStopSellingModal] = useState(false);
  const [showStartSellingModal, setShowStartSellingModal] = useState(false);

  const dispatch = useDispatch();
  let intervalId = null;

  // const socketRef = useRef(null);

  // useEffect(() => {
  //   // Establish WebSocket connection
  //   const newSocket = connectWebSocket(
  //     onMessage,
  //     onClose,
  //     "ProjectChannel",
  //     "gallery"
  //   );
  //   socketRef.current = newSocket;

  //   return () => {
  //     if (newSocket) {
  //       closeWebSocket(newSocket);
  //     }
  //   };
  // }, []);

  useEffect(() => {
    if (currentUserDetails?.open_ai) {
      setShowOpenApi(currentUserDetails?.open_ai);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Call the method after 5 seconds
    const timer = setTimeout(resetGallery, 5000);

    intervalId = setInterval(() => {
      resetGallery();
    }, 30000);

    return () => {
      clearTimeout(timer);
      clearInterval(intervalId);
    };
  }, []);

  // const onMessage = (event) => {
  //   const data = JSON.parse(event.data);
  //   console.log("Received message:", data);
  //   if (data?.message?.status === "refresh") {
  //     console.log("Refreshing gallery elements...");
  //     initializeGallery();
  //   }
  //   if (data?.message?.status === "completed") {
  //     console.log("Closing WebSocket...");
  //     console.log("Socket:", socketRef.current);
  //     closeWebSocket(socketRef.current);
  //   }
  // };

  // const onClose = () => {
  //   console.log("WebSocket connection closed.");
  // };

  const resetGallery = () => {
    pollPendingProjects().then((res) => {
      const statusCode = parseInt(res?.status);

      if (statusCode === 200) {
        initializeGallery();
        clearInterval(intervalId);
      }
    });
  };

  const handleDeleteClick = () => {
    handleDelete(selectedToken);
    setProjectCount((prevCount) => prevCount - 1);
  };

  const handleModalClose = () => {
    dispatch(setCanShowBackAlertModal(false));
  };

  return (
    <>
      <div className="sizing-list">
        <div className="gallery-heading-container">
          <div className="gallery-heading">{heading}</div>
          <div className="number-photos">
            <div className="gallery-number">{projectCount}</div>
            <div className="gallery-photos">
              {projectCount !== 1 ? string.PHOTOS : string.PHOTO}
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          isPageLoading ? "gallery-block page-disabled" : "gallery-block"
        }
      >
        {shouldShowUpload && <CreateNew onClick={UploadFile} />}
        {showOpenAi && shouldShowUpload && <UploadOptions />}
        {items &&
          items.map((data, index) => (
            <Card
              key={data.project_token}
              data={data}
              index={index}
              handleDraft={handleDraft}
              loadPhotoPrintDetails={loadPhotoPrintDetails}
              handleDelete={handleDelete}
              setProjectCount={setProjectCount}
              setSelectedToken={setSelectedToken}
              setPublicUrl={setPublicUrl}
              setShowStopSellingModal={setShowStopSellingModal}
              setShowStartSellingModal={setShowStartSellingModal}
            />
          ))}
      </div>
      <DialogBox
        customStyles={{
          modalBody_background: "#404040",
        }}
        canShow={CanShowBackAlertModal}
        heading={string.DELETE_IMAGE}
        text1={string.DELETE_IMAGE_TEXT_1}
        text2={string.DELETE_IMAGE_TEXT_2}
        submitData={{
          submitText: string.DELETE,
          submitHandler: handleDeleteClick,
          cancelBtn: string.CANCEL,
          cancelHandler: handleModalClose,
        }}
      />

      <StopSelling
        show={showStopSellingModal}
        handleClose={() => setShowStopSellingModal(false)}
        projectToken={selectedToken}
        initializeGallery={initializeGallery}
      />

      <StartSelling
        show={showStartSellingModal}
        handleClose={() => setShowStartSellingModal(false)}
        projectToken={selectedToken}
        publicViewUrl={publicUrl}
        initializeGallery={initializeGallery}
      />
      <SuperResolutionModal
        canShow={canShowResolutionModal}
        heading={string.SUPER_RESOLUTION_PROGRESS}
        text1={string.SUPER_RESOLUTION_PROGRESS_TEXT1}
        text2={string.SUPER_RESOLUTION_PROGRESS_TEXT2}
      />
    </>
  );
};
export default TabContent;
