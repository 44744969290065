import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateProgressBarVisibilty,
  updateProgressBarValue,
} from "../../../Store/Project/ProgressBarSlice";
import deleteSvg from "../../../Utils/Icons/delete.svg";
import cancelSvg from "../../../Utils/Icons/cancel.svg";
import "./UploadDialog.scss";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import {
  sendGACheckedAgreedToTerms,
  sendGAReuploadedPhotoAfterUploadFailure,
  sendGAUploadedPhoto,
} from "../../../Utils/GAHelper";
import { footerLinks } from "../../../Utils/ApiHelper";
import { string } from "../../../Localization";
import {
  STEP_THREE_CERTIFICATE,
  STEP_TWO_PERSONALISATION,
  SUPPORTED_COUNTRY,
} from "../../../Utils/Constants";
import ModalFooter from "../../ModalFooter/ModalFooter";
import { Icons } from "../../../Utils/Icons/Icons";

const UploadDialog = (props) => {
  const {
    imageDetails,
    uploadAction,
    onReset,
    uploadSuccess,
    termsAndCondition,
    response,
    isSignatureModal,
  } = props;

  const dispatch = useDispatch();
  const progressBarValue = useSelector((state) => state.progressBar);

  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [deleteBtn, setDeleteBtn] = useState(false);
  const [links, setLinks] = useState([]);
  const [showCheckbox, setShowCheckbox] = useState(false);

  useEffect(() => {
    footerLinks(SUPPORTED_COUNTRY).then((res) => {
      setLinks(res.data);
    });
  }, []);

  const { activeStep } = useSelector((state) => state.page);

  const imageOnError = (event) => {
    event.currentTarget.src = Icons.defaultIcon;
  };

  return (
    <>
      <div className="modal-sign">
        {uploadSuccess === true ? (
          <>
            <div className="upload-image-preview">
              <img
                src={imageDetails?.preview}
                alt="Preview"
                onLoad={() => setShowCheckbox(true)}
                onError={imageOnError}
              />

              {progressBarValue.progressBarVisibilty === false &&
              deleteBtn === false ? (
                <>
                  <img
                    alt="close-icon"
                    src={deleteSvg}
                    className="close-image-svg"
                    onClick={() => {
                      onReset();
                      setDisableButton(false);
                      setPrivacyPolicy(false);
                      dispatch(updateProgressBarVisibilty(false));
                      dispatch(updateProgressBarValue(0));
                      setShowCheckbox(false);
                    }}
                  />
                </>
              ) : (
                ""
              )}
              <div className="upload-image-details">
                <p className="image-name">{imageDetails?.name}</p>

                {progressBarValue.progressBarVisibilty === false ? (
                  <>
                    <p className="image-size">
                      {imageDetails?.size} {string.MB}
                    </p>

                    <p className="image-size">{imageDetails?.dimensions}</p>
                  </>
                ) : (
                  ""
                )}
                {progressBarValue.progressBarVisibilty && (
                  <>
                    <>
                      <div className="progress-bar-animation">
                        <div id="myProgress" className="myProgress">
                          <div
                            id="myBar"
                            className="myBar"
                            style={{
                              backgroundColor: "#176267",
                              width: `${progressBarValue.progressBarValue}%`,
                            }}
                          ></div>
                        </div>
                        {progressBarValue.progressBarValue !== 0 ? (
                          <>
                            <div className="counter">
                              <TransitionGroup component="span">
                                <CSSTransition
                                  classNames="ctr"
                                  timeout={{ enter: 300, exit: 300 }}
                                  key={Math.random()}
                                  unmountOnExit
                                >
                                  <span className="counter__value">
                                    {progressBarValue.progressBarValue}%
                                  </span>
                                </CSSTransition>
                              </TransitionGroup>
                            </div>
                          </>
                        ) : (
                          spinner()
                        )}
                      </div>
                    </>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="upload-error-preview">
              <div className="upload-error-container">
                <div className="upload-container">
                  <div className="upload-error">
                    <img
                      alt="cancel"
                      src={cancelSvg}
                      className="cancel-image-svg"
                    />
                  </div>
                  <div className="upload-messages">
                    <div className="upload-error-message">
                      {string.UPLOAD_FAILED}
                    </div>
                    <div className="upload-error-description">{response}</div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {termsAndCondition === true && uploadSuccess === true ? (
          <>
            <div className="terms-and-conditions">
              {showCheckbox ? (
                <label className="checkbox-container">
                  <input
                    type="checkbox"
                    checked={privacyPolicy}
                    onChange={() => {
                      setPrivacyPolicy(!privacyPolicy);
                      !privacyPolicy && sendGACheckedAgreedToTerms();
                    }}
                  />

                  <span className="checkmark"></span>
                </label>
              ) : (
                spinner()
              )}

              <p className="upload-image-privacy-policy">
                {string.I_HAVE_READ_AND_ACCEPT_THE}&nbsp;
                <a
                  href={links["privacy-statement"]}
                  target="_blank"
                  rel="noreferrer"
                >
                  {string.HP} {string.PRIVACY_STATEMENT}
                </a>
                &nbsp;{string.AND}&nbsp;
                <a
                  href={links["terms-and-conditions"]}
                  target="_blank"
                  rel="noreferrer"
                >
                  {string.TERMS_AND_CONDITIONS}
                </a>
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="terms-and-conditions"></div>
          </>
        )}

        {uploadSuccess === true ? (
          <>
            {disableButton === false ? (
              <>
                <button
                  className={
                    privacyPolicy === true || termsAndCondition === false
                      ? "upload-button upload-btn-dimensions btn-enabled"
                      : "upload-button upload-btn-dimensions btn-disabled"
                  }
                  disabled={
                    privacyPolicy === true || termsAndCondition === false
                      ? false
                      : true
                  }
                  onClick={() => {
                    setDisableButton(true);
                    setDeleteBtn(true);
                    uploadAction();
                    sendGAUploadedPhoto();
                  }}
                >
                  {string.UPLOAD}
                </button>
              </>
            ) : (
              <>
                <button
                  className="upload-button upload-btn-dimensions btn-disabled"
                  disabled
                  onClick={() => {
                    sendGAUploadedPhoto();
                  }}
                >
                  {string.UPLOAD}
                </button>
              </>
            )}
          </>
        ) : (
          <>
            {activeStep !== STEP_THREE_CERTIFICATE &&
            activeStep !== STEP_TWO_PERSONALISATION ? (
              <button
                className="upload-button upload-new-btn-dimensions btn-enabled"
                onClick={() => {
                  onReset();
                  dispatch(updateProgressBarVisibilty(false));
                  dispatch(updateProgressBarValue(0));
                  setPrivacyPolicy(false);
                  sendGAReuploadedPhotoAfterUploadFailure();
                }}
              >
                {string.UPLOAD_A_NEW_PHOTO}
              </button>
            ) : (
              <div className="empty-btn"></div>
            )}
          </>
        )}
      </div>
      {/* footer links in upload image */}
      {!isSignatureModal && (
        <div className="upload_image_footer_links">
          <ModalFooter />
        </div>
      )}
    </>
  );
};

const spinner = () => (
  <div className="loader-container">
    <div class="loader_spinner"></div>
  </div>
);

export default UploadDialog;
