import React, { useEffect } from "react";
import { setDefaultSignatureOption } from "../../../Utils/signatureHelper";
import SignatureUpload from "../SignatureUpload/SignatureUpload";
import "./SignatureCertificate.scss";
import { string } from "../../../Localization";

const SignatureCertificate = () => {
  useEffect(() => {
    setDefaultSignatureOption();
  }, []);

  return (
    <div className="certificate-sig-wrapper">
      <SignatureUpload uploadText={string.UPLOAD_MY_SIGNATURE} />
    </div>
  );
};

export default SignatureCertificate;
