import { string } from "../../../../Localization";
import "./CustomerDetails.scss";
import Select from "react-select";
import { sendGAForCollectorCountryPreference } from "../../../../Utils/GAHelper";
import { useParams } from "react-router-dom";
import { SUPPORTED_COUNTRY } from "../../../../Utils/Constants";
import UKFormat from "../AddressFormats/UKFormat";
import { updatedCountries } from "../../../../Utils/OrderHelper";

const CustomerDetails = (props) => {
  const {
    orderData,
    setOrderData,
    alertMessage,
    setAlertMessage,
    addressLine1,
    setAddressLine1,
  } = props;
  const { firstName, lastName, email, country, phoneNumber } = orderData;

  const { project_token } = useParams();

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "transparent",
      borderRadius: "6px",
      borderColor: "gray",
      color: "black",
      "&:hover": {
        borderColor: "gray",
        boxShadow: "none",
      },

      boxShadow: "none",
      "&:focus": {
        borderColor: "gray",
        boxShadow: "none",
      },
      width: "100%",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: "6px",
      border: "1px solid gray",
      boxShadow: "none",
      scrollbarWidth: "none",
      maxHeight: "300px",
      overflow: "scroll",
      marginTop: "-1px",

      "&::-webkit-scrollbar": {
        display: "none",
      },
    }),
    menuList: (base) => ({
      ...base,
      color: "black",
      marginTop: "0px",
      padding: "0px",
      margin: "0px",
      border: "none",
      boxShadow: "none",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        width: "2px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#FFFFFF",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#757575",
      },
    }),
    placeholder: (base) => ({
      ...base,
      color: "#000000",
    }),
    singleValue: (base) => ({
      ...base,
      color: "#000000",
    }),
    input: (base) => ({
      ...base,
      color: "#000000",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    option: (base, state) => ({
      ...base,
      border: "none",
      boxShadow: "none",
      backgroundColor: state.isFocused ? "#757575" : "#F8F8F8",
      color: "#000000",
      "&:hover": {
        backgroundColor: "#7575752b",
        color: "#000000",
      },
      "&:active": {
        backgroundColor: "#7575752b",
        color: "#000000",
      },
    }),
  };
  // const customStyles = {
  //   control: (base) => ({
  //     ...base,
  //     background: "transparent",
  //     borderRadius: "5px",
  //     borderColor: "gray",
  //     color: "black",
  //     "&:hover": {
  //       borderColor: "gray",
  //     },
  //     width: "100%",
  //   }),
  //   menu: (base) => ({
  //     ...base,
  //     borderRadius: 0,
  //   }),
  //   menuList: (base) => ({
  //     ...base,
  //     color: "black",
  //   }),
  //   placeholder: (base) => ({
  //     ...base,
  //     color: "gray",
  //   }),
  //   singleValue: (base) => ({
  //     ...base,
  //     color: "black",
  //   }),
  //   input: (base) => ({
  //     ...base,
  //     color: "black",
  //   }),
  // };

  const handleWheel = (e) => {
    e.currentTarget.blur();
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };

  const validateCountry = (value) => {
    setOrderData({
      ...props.orderData,
      country: {
        id: value["value"],
        label: value["label"],
        name: value["name"],
        countryCode: value["sortname"],
        phoneCode: value["phonecode"],
      },
    });
    if (value["sortname"] === SUPPORTED_COUNTRY) {
      setAlertMessage({ Country: "" });
    } else {
      setAlertMessage({ Country: string.SELECTED_COUNTRY_IS_NOT_SUPPORTED });
    }
    sendGAForCollectorCountryPreference(project_token, value["name"]);
  };

  const formattedAddressFields = () => {
    switch (country?.countryCode) {
      case SUPPORTED_COUNTRY:
        return (
          <UKFormat
            orderData={orderData}
            setOrderData={setOrderData}
            addressLine1={addressLine1}
            setAddressLine1={setAddressLine1}
            alertMessage={alertMessage}
          />
        );
      default:
      // code block
    }
  };

  return (
    <div className="customer-details-block">
      <div>
        <div className="customer-details-title">{string.ADDRESS_DETAILS}</div>

        <div className="input-block">
          <div className="input-label">{string.COUNTRY}</div>
          <Select
            id="country"
            name="country"
            placeholder={string.SEARCH_FOR_YOUR_COUNTRY}
            options={updatedCountries}
            value={country.id ? country : null}
            styles={customStyles}
            onChange={(value) => {
              validateCountry(value);
            }}
          />
          <div className="error-div">{alertMessage[string.COUNTRY]}</div>
        </div>
        {formattedAddressFields()}
      </div>

      <div className="customer-block">
        <div className="customer-details-title">{string.CUSTOMER_DETAILS}</div>
        <div className="input-block">
          <div className="input-label">{string.FIRST_NAME}</div>
          <div>
            <input
              type="text"
              className="input-box"
              value={firstName ? firstName : ""}
              placeholder={string.TYPE_YOUR_FIRST_NAME}
              onChange={(e) => {
                setOrderData({
                  ...props.orderData,
                  firstName: e.currentTarget.value,
                });
              }}
            />
          </div>
          <div className="error-div">{alertMessage[string.FIRST_NAME]}</div>
        </div>

        <div className="input-block">
          <div className="input-label">{string.LAST_NAME}</div>
          <div>
            <input
              type="text"
              className="input-box"
              value={lastName ? lastName : ""}
              placeholder={string.TYPE_YOUR_LAST_NAME}
              onChange={(e) => {
                setOrderData({
                  ...props.orderData,
                  lastName: e.currentTarget.value,
                });
              }}
            />
          </div>
          <div className="error-div">{alertMessage[string.LAST_NAME]}</div>
        </div>

        <div className="input-block">
          <div className="input-label">{string.EMAIL_ADDRESS}</div>
          <div>
            <input
              type="text"
              className="input-box"
              value={email ? email : ""}
              placeholder={string.EXAMPL_GMAIL_COM}
              onChange={(e) => {
                setOrderData({
                  ...props.orderData,
                  email: e.currentTarget.value,
                });
              }}
            />
          </div>
          <div className="error-div">{alertMessage[string.EMAIL]}</div>
        </div>

        <div className="input-block">
          <div className="input-label">{string.MOBILE_NUMBER}</div>
          <div>
            <input
              type="number"
              className="input-box"
              onChange={(e) => {
                setOrderData({
                  ...props.orderData,
                  phoneNumber: String(e.currentTarget.value),
                });
              }}
              value={phoneNumber ? phoneNumber : ""}
              placeholder="000 000 000"
              onWheel={handleWheel}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="error-div">{alertMessage[string.PHONE_NUMBER]}</div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;
