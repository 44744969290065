import React, { useState } from "react";
import "./FirstAiImage.scss";
import search_a_photo from "../../Utils/Icons/search_a_photo.svg";
import { string } from "../../Localization";
import { showOrHideUploadAIOptionModal } from "../../Store/Project/ModalSlice";
import { useDispatch, useSelector } from "react-redux";
import UploadOptionsModal from "../../Components/Modal/UploadOptionsModal/UploadOptionsModal";

const FirstAiImage = () => {
  const { canShowUploadAIOptionModal } = useSelector((state) => state.modal);

  const dispatch = useDispatch();

  const handleModalOpen = () => {
    dispatch(showOrHideUploadAIOptionModal(true));
  };

  return (
    <>
      <div className="FirstAiImage_container">
        <div className="search_a_photo">{string.CREATE_PHOTO_WITH_AI}</div>
        <div className="search_a_photo_text">
          {string.YOU_CAN_JUST_TYPE_A_WORD}
        </div>

        <div className="search_a_photo_image">
          <img
            src={search_a_photo}
            alt="search_a_photo"
            className="search_a_photo_img"
          />
        </div>

        <div className="search_a_photo_button">
          <button className="search_a_photo_btn" onClick={handleModalOpen}>
            {string.TRY_NOW}
          </button>
        </div>
      </div>
      <UploadOptionsModal isOpen={canShowUploadAIOptionModal} />
    </>
  );
};

export default FirstAiImage;
