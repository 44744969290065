import React from "react";
import "./UploadSpinner.scss";
import { string } from "../../../Localization";

const UploadSpinner = (props) => {
  const { text } = props;
  const message = text ?? string.PROCESSING;

  return (
    <>
      <div className="upload-spinner"></div>
      <div className="processing-text">{message}</div>
    </>
  );
};

export default UploadSpinner;
