import React from "react";
import "./Admin.scss";
import AdminNavBar from "./AdminNavBar/AdminNavBar";
import { ADMIN_NAV_BAR } from "../../Utils/Constants";
import { useSelector } from "react-redux";

const Admin = () => {
  const { activePageIndex } = useSelector((state) => state.admin);
  return (
    <>
      <div className="admin_navBar_container">
        {activePageIndex === ADMIN_NAV_BAR && <AdminNavBar />}
      </div>
    </>
  );
};

export default Admin;
