import { certificateData } from "../../../Utils/CertificateHelper";
import { Icons } from "../../../Utils/Icons";
import { useEffect } from "react";
import { sendGALoadedPrintedCertifacte } from "../../../Utils/GAHelper";
import blockchain_logo from "../../../Utils/Icons/blockchain_logo.svg";
import "./PrintedCertificate.scss";
import { string } from "../../../Localization";

const PrintedCertificate = ({ details }) => {
  const data = details ? details : certificateData();

  const title = details ? data.project.title : data.title;
  const artistName = details ? data.project.artist_name : data.artistName;
  const year = details ? data.project.creation_year : data.year;
  const dimensions = details ? data.project.print_size : data.dimensions;
  const paperType = details ? data.project.media_type_name : data.paperType;
  const token = details ? data.blockchain.nft_token : data.token;
  const mintTime = details ? data.blockchain.nft_mint_time : data.mintTime;
  const qrCode = details ? data.project.qr_code_url : data.qrCode;
  const edition = details
    ? data.project.edition_str
    : data.isLimited
    ? data.edition
    : "1";
  const noOfCopies = details ? data.project.no_of_copies : data.noOfCopies;
  const previewImage = details ? data.project.preview_image : data.previewImage;
  const signature = details ? data.project.signature : data.signature;

  useEffect(() => {
    sendGALoadedPrintedCertifacte();
  }, []);

  return (
    <div
      className={
        details
          ? "printed-certificate-block"
          : "printed-certificate-block summary-certifiacate"
      }
      style={{ backgroundImage: `url(${Icons.printedPattern})` }}
    >
      <div className="title">{title}</div>
      <div className="artist-name">{artistName}</div>

      <div className="d-flex justify-content-between description-block">
        <div className="">
          <div className="printed-grid-block">
            <div className="head-content">{string.YEAR}</div>
            <div className="body-content">{year}</div>
          </div>
          <div className="printed-grid-block">
            <div className="head-content">{string.DIMENSIONS}</div>
            <div className="body-content">{dimensions}</div>
          </div>
          <div className="printed-grid-block">
            <div className="head-content">{string.PAPER_TYPE_1}</div>
            <div className="body-content">{paperType}</div>
          </div>
          <div className="printed-grid-block grid-seperator">
            <div className="head-content">{string.TOKEN}</div>
            <div className="body-content">{token}</div>
          </div>
          <div className="printed-grid-block">
            <div className="head-content">{string.MINT_TIME}</div>
            <div className="body-content">{mintTime}</div>
          </div>

          <div className="qr-code-blockChain-container">
            {" "}
            <div className="qr-code-block">
              <div>
                <img alt="qr-code" className="qr-code" src={qrCode} />
              </div>
              <div className="font-coa">{string.DIGITAL_COA}</div>
            </div>
            <div className="qr-code-block">
              <div>
                <img
                  alt="block-code"
                  className="block-code"
                  src={blockchain_logo}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="preview-block">
          <div>
            <div className="edition-font">
              {details
                ? edition
                : data.isLimited
                ? edition + "/" + noOfCopies
                : edition}
            </div>

            <img alt="" className="final-image" src={previewImage} />
          </div>
          {signature && (
            <div className="signature-block">
              <img alt="signature" className="signature" src={signature} />
            </div>
          )}
        </div>
      </div>

      <div className="d-flex footer-cert">
        <div>
          <img alt="hp-logo" className="hp-logo-black" src={Icons.hpLogo} />
        </div>
        <div className="footer-left-text">{string.POWERED_BY_HP}</div>
        <div className="footer-right-text">
          {string.BLOCKCHAIN_AUTHENTICATED_CERTIFICATE}
        </div>
      </div>
    </div>
  );
};

export default PrintedCertificate;
