import React, { useEffect, useState } from "react";
import "./Graph.scss";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    const month = label;
    const count = payload[0]?.value;
    return (
      <div className="custom-tooltip-box">
        <div className="tooltip-arrow"></div>
        <div className="dialog-content">
          <p className="label">{`${month}: ${count} ${
            count !== 1 ? "orders" : "order"
          }`}</p>
        </div>
      </div>
    );
  }

  return null;
};

const SplineGraph = ({ data }) => {
  const [animationActive, setAnimationActive] = useState(false);
  const [chartData, setChartData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [datapoints, setDatapoints] = useState(data);

  useEffect(() => {
    const getData = datapoints?.labels?.map((label, index) => ({
      labels: label,
      count: datapoints.count[index],
    }));

    setChartData(getData);
    setAnimationActive(true);
  }, [datapoints]);

  const onAnimationEnd = () => {
    setAnimationActive(false);
  };

  return (
    <ResponsiveContainer>
      <LineChart
        data={chartData}
        margin={{
          top: 25,
          right: 45,
          left: -10,
          bottom: 5,
        }}
        className="line-chart"
      >
        <CartesianGrid vertical={false} stroke="#BDBDBD" />
        <XAxis dataKey="labels" tickLine={false} />
        <YAxis dataKey="count" axisLine={false} tickLine={false} />

        <Tooltip content={<CustomTooltip />} />
        <Line
          type="monotone"
          dataKey="count"
          stroke="#176267"
          activeDot={{
            fill: "#176267",
            stroke: "#FFFFFF",
            strokeWidth: 5,
            r: 5,
          }}
          dot={{ stroke: "#176267", fill: "#176267", strokeWidth: 2, r: 3 }}
          animationDuration={1500}
          onAnimationEnd={onAnimationEnd}
          isAnimationActive={animationActive}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
export default SplineGraph;
