import React from "react";
import { Modal } from "react-bootstrap";
import { string } from "../../../Localization";
import { setCanShowResolutionModal } from "../../../Store/Project/ProjectSlice";
import { useDispatch } from "react-redux";
import Closebutton from "../../../Utils/Icons/close.svg";

const SuperResolutionModal = (props) => {
  const dispatch = useDispatch();
  const { canShow, heading, text1, text2 } = props;

  return (
    <>
      <div className="dialog-container">
        <Modal
          dialogClassName="mw-100"
          show={canShow}
          className="modal-container-wrap"
        >
          <Modal.Body className="modal-container">
            <div className="close-dialog">
              <img
                alt="close"
                style={{ cursor: "pointer" }}
                src={Closebutton}
                onClick={() => {
                  dispatch(setCanShowResolutionModal(false));
                }}
              />
            </div>
            <div className="dialog-content">
              <div className="dialog-heading">{heading}</div>
              <div className="dialog-text-wrap">
                <div className="dialog-body">{text1}</div>
                <div className="dialog-body">{text2}</div>
              </div>
              <div
                className="upload-spinner"
                style={{
                  width: "93px",
                  height: "93px",
                  padding: "15px",
                }}
              ></div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default SuperResolutionModal;
