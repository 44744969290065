import React from "react";
import "./ModalFooter.scss";
import { useSelector } from "react-redux";
import { string } from "../../Localization";

const ModalFooter = () => {
  const { footerLinksData } = useSelector((state) => state.cache);

  return (
    <div className="modal_footer_container">
      <div className="modal_footer_item modal_item1">
        <a
          href={footerLinksData["terms-and-conditions"]}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="modal_footer_text">
            {string.TERMS_AND_CONDITION_FOOTER}
          </div>
        </a>
      </div>
      <div className="modal_footer_item modal_item2">
        <a
          href={footerLinksData["privacy-statement"]}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            className="modal_footer_text
          "
          >
            {string.ONLINE_PRIVACY_STATEMENT}
          </div>
        </a>
      </div>
      <div className="modal_footer_item modal_item3">
        <a
          href={footerLinksData["personal-data-rights"]}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            className="modal_footer_text
          "
          >
            {string.PERSONAL_DATA_RIGHTS_NOTICE}
          </div>
        </a>
      </div>
    </div>
  );
};

export default ModalFooter;
