import React, { useEffect, useState } from "react";
import private_gallery from "../../../Utils/Icons/private_gallery.svg";
import "./PhotographerProfile.scss";
import { stringAvatar } from "../../../Utils/projectHelper";
import Stack from "@mui/material/Stack";
import { string } from "../../../Localization";

const PhotographerProfile = (props) => {
  const [photographerPhoto, setPhotographerPhoto] = useState("");
  const [spacing, setSpacing] = useState(0);
  const [smallScreen, setSmallScreen] = useState(window.innerWidth < 900);

  const { artistName, bio, photo } = props;

  const splitFirstName = artistName.split(" ");
  const firstLetter = splitFirstName[0].charAt(0);
  const lastLetter = splitFirstName[splitFirstName.length - 1].charAt(0);
  const letters = `${firstLetter}${lastLetter}`;

  useEffect(() => {
    if (artistName && artistName === "") {
      setSpacing(artistName?.split(" ").length);
    } else {
      setSpacing(-1);
    }
  }, [artistName]);

  useEffect(() => {
    profilePhoto();
    const handleResize = () => {
      setSmallScreen(window.innerWidth < 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const profilePhoto = () => {
    if (photo != "") {
      setPhotographerPhoto(photo);
    }
  };

  const profilePicture = () =>
    photographerPhoto == "" ? (
      <>
        <Stack
          direction="row"
          spacing={spacing}
          className="photographer_details_image"
        >
          <div
            {...stringAvatar(artistName, letters)}
            className="photographer_details_images"
          />
        </Stack>
      </>
    ) : (
      <>
        <img
          src={photographerPhoto}
          alt="private_gallery_pic"
          className="private_gallery_pic"
        />
      </>
    );

  return (
    <>
      <div className={"private_gallery_container "}>
        <div className="private_text_container">
          <div className="private_text_title">
            {artistName} x {string.HP_LIMITED_EDITION}
          </div>
          <div
            className="d-flex"
            style={{
              flexDirection: smallScreen ? "column" : "row",
            }}
          >
            <div
              className="private_text_texts"
              style={{
                marginRight: smallScreen ? "0px" : "92px",
              }}
            >
              <div className="private_text_text1">
                {string.HP_DESCRIPTION_TEXT1}
              </div>
              <div className="private_text_text1">
                {string.HP_DESCRIPTION_TEXT2}
              </div>
            </div>

            {!bio && (
              <div
                style={{
                  marginTop: smallScreen ? "20px" : "0px",
                }}
              >
                {profilePicture()}
              </div>
            )}
          </div>
        </div>

        {artistName && bio && (
          <div className="private_second_container">
            {profilePicture()}

            {artistName && bio && (
              <div
                className="private_text_container"
                style={{ width: "unset" }}
              >
                <div
                  className="private_text_title"
                  style={{ marginTop: "unset" }}
                >
                  {string.BIO}
                </div>
                <div className="private_text_texts">
                  <div className="private_text_text1">{bio}</div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default PhotographerProfile;
