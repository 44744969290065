import React from "react";
import "./Breadcrumb.scss";
import { useNavigate } from "react-router-dom";

const Breadcrumb = ({ items }) => {
  const navigate = useNavigate();

  return (
    <div className="breadcrumb_container">
      <>
        {" "}
        <ol className="breadcrumb">
          {items.map((item, index) => (
            <li
              key={item.label}
              className={`breadcrumb_item_list${
                index === items.length - 1 ? " active" : ""
              }`}
            >
              {index === items.length - 1 ? (
                item.label
              ) : (
                <a className="breadcrumb_links" href={item.link}>
                  <div>{item.label}</div>
                  <div>{"  >  "}</div>
                </a>
              )}
            </li>
          ))}
        </ol>
      </>
    </div>
  );
};

export default Breadcrumb;
