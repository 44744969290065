import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../../Context/GlobalState";
import { useSelector } from "react-redux";
import ProjectSpinner from "../../../Components/Loaders/ProjectSpinner/ProjectSpinner";
import {
  didChangeCropSizeSelection,
  getImageElement,
  loadProject,
  resetCanvas,
} from "../../../Utils/ProjectRender";
import { updateImageProperty } from "../../../Utils/Helper";
import { string } from "../../../Localization";
import { getSelectedSizeDimension } from "../../../Utils/projectHelper";

const ImageCanvas = () => {
  const { imageCanvas, setImageCanvas, setCroppedImageCanvas } =
    useContext(GlobalContext);
  const {
    isCanvasLoading,
    isPolling,
    sizeSelectionDetails,
    activePreviewMode,
  } = useSelector((state) => state.project);

  const { uploadedImageData } = useSelector((state) => state.project);
  const { selectedDimension } = sizeSelectionDetails;

  useEffect(() => {
    if (uploadedImageData.imageURL) {
      const backupCanvas = imageCanvas;
      resetCanvas(backupCanvas);
      setImageCanvas(null);

      loadProject(backupCanvas, activePreviewMode, (inCanvas) => {
        setImageCanvas(inCanvas);
        const selectedSizeDimension = getSelectedSizeDimension();
        sizeSelectionIsChanged(selectedSizeDimension, inCanvas);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedImageData]);

  useEffect(() => {
    return () => {
      setImageCanvas(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sizeSelectionIsChanged(selectedDimension, imageCanvas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDimension]);

  useEffect(() => {
    if (imageCanvas) {
      const ele = getImageElement(imageCanvas);
      updateImageProperty(ele);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePreviewMode]);

  const sizeSelectionIsChanged = (inDimension, inCanvas) => {
    //to avoid adding crop rectangle in create project page when a size is not selected
    if (inDimension && inDimension.width !== 0 && inCanvas) {
      setCroppedImageCanvas(null);
      didChangeCropSizeSelection(inDimension, inCanvas);
    }
  };

  return (
    <>
      <div>
        <canvas id="image-canvas" />
      </div>
      <div style={{ position: "absolute", display: "flex" }}>
        <div>
          <ProjectSpinner isLoading={isCanvasLoading || isPolling} />
        </div>
        {isCanvasLoading ? (
          <div style={{ padding: "5px 0px 0px 18px" }}>{string.LOADING}</div>
        ) : isPolling ? (
          <div>{string.VALIDATING_WITH_BLOCKCHAIN}</div>
        ) : null}
      </div>
    </>
  );
};

export default ImageCanvas;
