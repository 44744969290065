import { useEffect, useState } from "react";
import { string } from "../../../Localization";
import {
  getCurrentUser,
  getOrderInfo,
  updateOrder,
} from "../../../Utils/ApiHelper";
import "./OrderDetails.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  sendGALoadedOrderDetails,
  sendGAUpdatedPersonalMessage,
} from "../../../Utils/GAHelper";
import { capitalizeFirstLetter, getCustomName } from "../../../Utils/Helper";
import { useNavigate, useParams } from "react-router-dom";
import {
  setCanShowAlertModal,
  setProjectToken,
} from "../../../Store/Project/ProjectSlice";
import { setCurrentUserDetails } from "../../../Store/Project/UserSlice";
import { showOrHideApiLoadingModal } from "../../../Store/Project/ModalSlice";
import ProjectLoader from "../../../Components/Loaders/ProjectLoader/ProjectLoader";
import DialogBox from "../../DialogBox/DialogBox";

const OrderDetails = () => {
  const { order_token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { order } = useSelector((state) => state);
  const { canShowAlertModal, projectToken } = useSelector(
    (state) => state.project
  );

  const [orderDetails, setOrderDetails] = useState([]);
  const [orderToken, setOrderToken] = useState();
  const [personalMessage, setPersonalMessage] = useState();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    dispatch(showOrHideApiLoadingModal(true));
    getCurrentUser()
      .then((res1) => {
        dispatch(setCurrentUserDetails(res1.data.data));

        getOrderInfo(
          order_token === undefined ? order.orderToken : order_token
        ).then((res2) => {
          const response = res2.data.data;
          setOrderDetails(response);
          dispatch(setProjectToken(response?.google_analytics?.project_token));
          dispatch(showOrHideApiLoadingModal(false));
          setPersonalMessage(response.order.personal_message);
          dispatch(setOrderToken(response?.google_analytics?.order_token));
        });
        sendGALoadedOrderDetails(projectToken);
      })
      .catch(function (error) {
        navigate("/");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleDescriptionEdit = () => {
    if (personalMessage) {
      const formData = new FormData();
      formData.append("personal_message", personalMessage);
      updateOrder(orderToken, formData).then((res) => {
        setOrderDetails(res.data.data);
      });
    }
  };

  const statusData = getCustomName(orderDetails?.order?.status);

  const handleModalOpen = () => {
    dispatch(setCanShowAlertModal(true));
    setIsOpen(true);
  };

  const handleModalClose = () => {
    dispatch(setCanShowAlertModal(false));

    setIsOpen(false);
  };

  const handelMessageSend = () => {
    toggleDescriptionEdit();
    sendGAUpdatedPersonalMessage();
    setIsOpen(false);
  };

  const formattedAddressDetails = () => {
    return (
      <>
        <div>{orderDetails.order.address.address_line1}</div>
        <div>{orderDetails.order.address.address_line2}</div>
        <div>
          {orderDetails.order.address.zip_code +
            ", " +
            orderDetails.order.address.city}
        </div>
        <div>{orderDetails.order.address.region}</div>
        <div>{orderDetails.order.address.country}</div>
      </>
    );
  };
  return (
    <>
      <ProjectLoader />

      <div className="order-info-container">
        {orderDetails.project && (
          <div className="order-info">
            <div className="main-title">{string.ORDER_DETAILS}</div>
            <div className="details-container">
              <div className="d-flex">
                <div>
                  <img
                    className="preview-img"
                    src={orderDetails.project.preview}
                    alt=""
                  ></img>
                </div>
                <div className="img-description">
                  <div className="img-title">{orderDetails.project.title}</div>
                  <div className="img-size">
                    {orderDetails.project.print_size}
                  </div>
                  <div className="img-material">
                    {orderDetails.project.media_type_name}
                  </div>
                  <div className="img-edition">
                    {orderDetails.order.edition}
                  </div>
                  <div className="img-message">
                    {string.BLOCKCHAIN_AUTHENTICITY_MESSAGE}
                  </div>
                  <div className="img-message">
                    {string.ORDER_NUMBER_1}: {orderDetails?.order?.id}
                  </div>
                </div>
              </div>

              <div className="seperator"></div>
              <div className="info-block">
                <div className="px-2">
                  <div className="info-title">
                    {string.CUSTOMER_INFORMATION}
                  </div>
                  <div className="py-2">
                    <div className="info-field-key">{string.NAME}</div>
                    <div>{orderDetails.order.customer_name}</div>
                  </div>
                  {/* <div className="py-2">
                    <div className="info-field-key">
                      {string.SHIPPING_ADDRESS}
                    </div>
                    {formattedAddressDetails()}
                  </div> */}
                  <div className="py-2">
                    <div className="info-field-key">
                      {string.DELIVERY_ADDRESS}
                    </div>
                    {formattedAddressDetails()}
                  </div>
                  <div className="py-2">
                    <div className="info-field-key">{string.EMAIL_1}</div>
                    <div>{orderDetails.order.email}</div>
                  </div>
                  <div className="py-2">
                    <div className="info-field-key">
                      {string.MOBILE_NUMBER_1}
                    </div>
                    <div>{orderDetails.order.contact_number}</div>
                  </div>
                </div>
                <div className="px-2">
                  <div className="info-title">{string.PRICING_AND_INCOME}</div>
                  <div className="income-container py-2">
                    <div>
                      <div className="info-field-key">
                        {string.ARTIST_INCOME.toUpperCase()}
                      </div>
                      <div>{orderDetails.price.income}</div>
                    </div>
                    <div>
                      <div className="info-field-key">
                        {string.SERVICE_FEES_1}
                      </div>
                      <div>{orderDetails.price.service_fees}</div>
                    </div>
                    <div>
                      <div className="info-field-key">
                        {string.SELLING_PRICE_1}
                      </div>
                      <div>{orderDetails.price.selling_price}</div>
                    </div>

                    {orderDetails.price.coupon.code && (
                      <div>
                        <div className="info-field-key">
                          {string.DISCOUNT_1}
                        </div>
                        <div className="">
                          {orderDetails.price.coupon.discount_amount} (
                          {orderDetails.price.coupon.code} )
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="seperator"></div>
                  <div className="py-2">
                    <div className="info-title">{string.BLOCKCHAIN_LEDGER}</div>
                    <div className="py-2">
                      {orderDetails.project.blockchain.blockchain_ledger +
                        " " +
                        orderDetails.project.blockchain.blockchain_publish_time}
                    </div>
                  </div>
                </div>
                <div className="px-2">
                  <div>
                    <div className="info-title">{string.ORDER_INFORMATION}</div>
                    <div className="py-2">
                      <div className="info-field-key bottom">
                        {string.STATUS}
                      </div>
                      <div className={statusData.className}>
                        <span className="status-icon">
                          {statusData.statusIcon && (
                            <img
                              src={statusData.statusIcon}
                              alt={orderDetails.order.status}
                            />
                          )}
                        </span>
                        <span>
                          {" "}
                          {capitalizeFirstLetter(orderDetails.order.status)}
                        </span>
                      </div>
                    </div>
                    {orderDetails?.order?.status === "Shipped" ? (
                      <div>
                        <div className="py-4">
                          <div className="info-field-key">
                            {string.PURCHASE_ORDER_NUMBER}
                          </div>
                          <div>{orderDetails.order.po_number}</div>
                        </div>
                        <div className="py-2">
                          <div className="info-field-key">
                            {string.TRACKING_NUMBER}
                          </div>
                          <div
                            className={
                              orderDetails.order.tracking_number !== "-"
                                ? "tracking_url"
                                : ""
                            }
                            onClick={() =>
                              orderDetails.order.tracking_number !== "-"
                                ? window.open(
                                    orderDetails.order.tracking_url,
                                    "_blank"
                                  )
                                : ""
                            }
                          >
                            {" "}
                            <span
                              className={
                                orderDetails.order.tracking_number === "-"
                                  ? ""
                                  : "tracking_number"
                              }
                            >
                              {orderDetails.order.tracking_number}
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="seperator"></div>

                  <div>
                    <div className="info-title d-flex">
                      {string.PERSONAL_MESSAGE_FOR_CUSTOMER}
                    </div>
                    {orderDetails.order.can_update_personal_message ? (
                      <div className="info-field-key bottom">
                        {string.YOU_STILL_HAVE}&nbsp;
                        {orderDetails.order.time_remaining}&nbsp;
                        {string.TO_LEAVE_THE_MESSAGE}
                      </div>
                    ) : null}

                    {orderDetails.order.can_update_personal_message ? (
                      <div>
                        <div className="d-flex">
                          <textarea
                            className="input-description-enabled"
                            rows="5"
                            cols="33"
                            placeholder={string.TYPE_A_MESSAGE_HERE}
                            onChange={(e) => setPersonalMessage(e.target.value)}
                            maxLength={600}
                          >
                            {personalMessage}
                          </textarea>
                        </div>
                        <div className="max-characters">
                          {string.MAXIMUM_CHARACTERS}: 600
                        </div>
                        <div
                          onClick={() => {
                            handleModalOpen();
                          }}
                          className="save-button"
                        >
                          {string.SAVE_CHANGES}
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex">
                        <div className="py-2 description input-description-disabled">
                          {personalMessage === "" ? "-" : personalMessage}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isOpen && (
          <DialogBox
            canShow={canShowAlertModal}
            heading={string.PERSONAL_MESSAGE}
            text1={string.PERSONAL_MESSAGE_WARNING}
            submitData={{
              submitText: string.SEND,
              submitHandler: handelMessageSend,
              cancelBtn: string.CANCEL,
              cancelHandler: handleModalClose,
            }}
          />
        )}
      </div>
    </>
  );
};
export default OrderDetails;
