import React from "react";
import "./RadioButton.scss";
import { PRICE_CURRENCY_DOLLAR } from "../../../Utils/Constants";

const RadioButton = (props) => {
  const { details, onChange, activeValue } = props;
  const { id, text, group, value } = details;

  return (
    <div className="radio-button-items">
      <div className="radio-item1">
        <input
          type="radio"
          id={id}
          name={group}
          value={value}
          className="rad-input"
          onChange={onChange}
          checked={activeValue === value}
          disabled={text === PRICE_CURRENCY_DOLLAR ? true : false}
        />
        <label className="rad-text" for={id}>
          {text}
        </label>
      </div>
    </div>
  );
};

export default RadioButton;
