import { string } from "../../../Localization";
import "./PriceInfo.scss";
import { SUPPORTED_COUNTRY } from "../../../Utils/Constants";
import { useEffect, useState } from "react";
import {
  confirmOrder,
  createOrder,
  footerLinks,
  getProjectSummary,
  validateCouponCode,
} from "../../../Utils/ApiHelper";
import MasterCard from "../../../Utils/Icons/mastercard.svg";
import Visa from "../../../Utils/Icons/visa.svg";
import Axp from "../../../Utils/Icons/amex.svg";
import {
  sendGAAppliedInValidCoupon,
  sendGAAppliedValidCoupon,
  sendGACheckedAgreedtoTerms,
  sendGACollectorCheckedraffleTerms,
  sendGACollectorClickedReviewOrder,
  sendGACollectorclickedGotoSecurePayment,
  sendGACollectorclickedsupportemail,
  sendGAOrderCreationFailure,
  sendGAToNonPGSPaymentPage,
  sendGAToPGSPaymentPage,
  sendGAAddedCustomerDetails,
  sendGAAddedDeliveryAddress,
} from "../../../Utils/GAHelper";
import WWF from "../../../Utils/Icons/WWF_icon.svg";
import forest from "../../../Utils/Icons/forest_icon.svg";
import StatusModal from "../../../Components/StatusModal/StatusModal";
import { setCanShowErrorAlertModal } from "../../../Store/Project/ProjectSlice";
import { useDispatch, useSelector } from "react-redux";
import error_icon from "../../../Utils/Icons/error_icon.svg";
import closeIcon from "../../../Utils/Icons/close_gray.svg";
import alternate_email from "../../../Utils/Icons/alternate_email.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Icons } from "../../../Utils/Icons/Icons";
import { isEmptyField, validMail } from "../../../Utils/OrderHelper";
import {
  setAcceptSurvey,
  setAcceptTerms,
  setCouponCode,
  setCouponUsed,
  setValidCoupon,
} from "../../../Store/Project/OrderSlice";

const PriceInfo = (props) => {
  const {
    projectToken,
    price,
    orderData,
    alertMessage,
    addressLine1,
    setAlertMessage,
    setProjectSummaryData,
    setOnSubmit,
    isReviewStep,
    orderToken,
    isStepOne,
    reviewOrderCouponValid,
    infoLoaded,
    userId,
    isSubmitOrder,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    country,
    postalCode,
    addressLine2,
    city,
    region,
  } = orderData;
  const { CanShowErrorAlertModal } = useSelector((state) => state.project);
  const { acceptTerms, couponCode, acceptSurvey, validCoupon } = useSelector(
    (state) => state.order
  );

  const [errorMessage, setErrorMessage] = useState("");
  const [links, setLinks] = useState([]);
  const [checkBox, setCheckBox] = useState(acceptTerms);
  const [isAccepted, setIsAccepted] = useState(acceptSurvey);

  const [searchParams] = useSearchParams();
  const queryStrings = Object.fromEntries([...searchParams]);
  const source = queryStrings["source"];

  useEffect(() => {
    footerLinks().then((res) => {
      setLinks(res.data);
    });
  }, []);

  const validateAddress = () => {
    var alertHash = {};
    if (country.countryCode === SUPPORTED_COUNTRY) {
      alertHash = isEmptyField(alertHash, string.FIRST_NAME, firstName);
      alertHash = isEmptyField(alertHash, string.LAST_NAME, lastName);
      alertHash = isEmptyField(alertHash, string.EMAIL, email);
      alertHash = isEmptyField(alertHash, string.PHONE_NUMBER, phoneNumber);
      alertHash = isEmptyField(alertHash, string.COUNTRY, country.id);
      alertHash = isEmptyField(alertHash, string.POST_CODE, postalCode);
      alertHash = isEmptyField(alertHash, string.ADDRESS_LINE_1, addressLine1);
      alertHash = isEmptyField(alertHash, string.TOWN_CITY, city);
    } else {
      alertHash["Country"] = string.SELECTED_COUNTRY_IS_NOT_SUPPORTED;
    }
    setAlertMessage(alertHash);
    if (Object.keys(alertHash).length === 0) {
      if (!validMail(email.trim())) {
        setAlertMessage({ Email: string.EMAIL_IS_INVALID });
      } else {
        setOnSubmit(true);
        submitOrder();
        sendGAAddedCustomerDetails(userId, projectToken);
        sendGAAddedDeliveryAddress(userId, projectToken);
      }
    }
  };

  const submitOrder = () => {
    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("phoneNumber", phoneNumber);
    formData.append("country", country.name);
    formData.append("countryCode", country.countryCode);
    formData.append("postalCode", postalCode);
    formData.append("addressLine1", addressLine1);
    formData.append("addressLine2", addressLine2);
    formData.append("city", city);
    formData.append("region", region);
    formData.append("couponCode", validCoupon ? couponCode : "");
    formData.append("collectorAgreesForSurvey", acceptSurvey);
    if (source === "opensea") {
      formData.append("source", "opensea");
    }
    createOrder(projectToken, formData)
      .then((orderRes) => {
        navigate("/web/orders/" + orderRes?.data?.data?.token + "/review");
        setOnSubmit(false);
      })
      .catch(function (error) {
        sendGAOrderCreationFailure(projectToken);
        setErrorMessage(error.response.data.error_message);
        dispatch(setCanShowErrorAlertModal(true));
        setOnSubmit(false);
      });
  };

  const validateCoupon = () => {
    setOnSubmit(true);
    validateCouponCode(projectToken, couponCode, source)
      .then((res) => {
        dispatch(setValidCoupon(true));
        setOnSubmit(false);
        sendGAAppliedValidCoupon(projectToken, userId);
        setProjectSummaryData(res.data.data);
        setAlertMessage({ "Promo Code": string.PROMO_CODE_IS_VALID });
        dispatch(setCouponUsed(true));
      })
      .catch(function (error) {
        dispatch(setValidCoupon(false));
        setOnSubmit(false);
        sendGAAppliedInValidCoupon(projectToken);
        setAlertMessage({ "Promo Code": error.response.data.error_message });
      });
  };

  const closeHandler = () => {
    dispatch(setCanShowErrorAlertModal(false));
  };

  const setPriceData = (projectToken) => {
    getProjectSummary(projectToken, source).then((res) => {
      setProjectSummaryData(res.data.data);
    });
  };

  const handleConfirmPurchase = () => {
    setOnSubmit(true);
    setTimeout(() => {
      confirmOrder(orderToken).then((res) => {
        if (reviewOrderCouponValid) {
          sendGAToNonPGSPaymentPage(projectToken, userId);
          navigate("/web/orders/" + orderToken + "/payment_success");
        } else {
          sendGAToPGSPaymentPage(projectToken, userId);
          navigate("/web/orders/" + orderToken + "/checkout");
        }
        setOnSubmit(false);
      });
    }, 2000);
  };

  const handleAccepted = () => {
    dispatch(setAcceptSurvey(!isAccepted));
    setIsAccepted(!isAccepted);
  };

  return (
    <div className="price-block">
      {isReviewStep ? (
        <span className="price-details-title">{string.PRICE_DETAILS}</span>
      ) : (
        <div className="coupon-block">
          <div className="input-label">{string.PROMO_CODE}</div>
          <div className="d-flex">
            {validCoupon ? (
              <div className="d-flex label">
                {couponCode}
                <div
                  className="close-button"
                  onClick={() => {
                    dispatch(setValidCoupon(false));
                    dispatch(setCouponCode(""));
                    setAlertMessage({ "Promo Code": "" });
                    setPriceData(projectToken);
                    dispatch(setCouponUsed(false));
                  }}
                >
                  <img src={closeIcon} alt="close" />
                </div>
              </div>
            ) : (
              <>
                <div className="text-block">
                  <input
                    type="text"
                    className="input-box"
                    value={couponCode ? couponCode : ""}
                    placeholder={string.ENTER_THE_CODE_HERE}
                    onChange={(e) => {
                      dispatch(
                        setCouponCode(e.currentTarget.value.toUpperCase())
                      );
                      setAlertMessage({ "Promo Code": "" });
                      dispatch(setValidCoupon(false));
                    }}
                  />
                </div>
                <div
                  className={
                    couponCode ? "apply-button" : "apply-button apply-disabled"
                  }
                  onClick={() => {
                    validateCoupon();
                    // sendGAUpdatedCustomerDetails();
                  }}
                >
                  {string.APPLY}
                </div>
              </>
            )}
          </div>
          <div className={validCoupon ? "success-div" : "error-div"}>
            {alertMessage[string.PROMO_CODE]}
          </div>
        </div>
      )}

      <div>
        <div className="price-title"></div>
        <div className="py-3">
          <div className="d-flex justify-content-between price-desc-data">
            <div>{string.PRICE}</div>
            <div>{price?.selling_price}</div>
          </div>
          <div className="d-flex justify-content-between price-desc-data">
            <div>{string.SHIPPING_COSTS}</div>
            <div>{price?.shipping_fees}</div>
          </div>
          {validCoupon && couponCode && (
            <div className="d-flex justify-content-between price-desc-data">
              <div>{string.DISCOUNT + " (" + couponCode + ")"}</div>
              <div>{price?.discount_amount}</div>
            </div>
          )}
          <div className="d-flex justify-content-between price-desc-data">
            <div>{string.VAT}</div>
            <div>{price?.vat}</div>
          </div>
          <div className="d-flex justify-content-between total-price">
            <div>{string.TOTAL_PRICE}</div>
            <div>{price?.total_price}</div>
          </div>
        </div>
      </div>
      <div className="submit-block">
        {isStepOne ? (
          <>
            <div className="d-flex privacy-text align-items-center">
              {checkBox ? (
                <>
                  <img
                    src={Icons.checked}
                    alt="privacy checkbox checked"
                    onClick={() => {
                      dispatch(setAcceptTerms(false));
                      setCheckBox(false);
                    }}
                  />
                </>
              ) : (
                <>
                  <img
                    src={Icons.unchecked}
                    alt="privacy checkbox unchecked"
                    onClick={() => {
                      dispatch(setAcceptTerms(true));
                      setCheckBox(true);
                      sendGACheckedAgreedtoTerms(projectToken, userId);
                    }}
                  />
                </>
              )}

              <div className="agreement-block">
                {string.I_HAVE_READ_AND_ACCEPT_THE}
                <a
                  href={links["privacy-statement"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {string.HP} {string.PRIVACY_POLICY}
                </a>
                {string.AND}
                <a
                  href={links["consumer-terms-of-service"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {string.TERMS_AND_CONDITIONS}
                </a>
              </div>
            </div>
            <div className="d-flex survey-container">
              <img
                className="survey_checkbox"
                src={isAccepted ? Icons.checked : Icons.unchecked}
                alt="checkbox"
                onClick={() => {
                  handleAccepted();
                  sendGACollectorCheckedraffleTerms();
                }}
              />

              <div className="checkbox-text">
                {string.CONSENT_TEXT}{" "}
                <a
                  href={links["consumer-raffle-info"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {string.RAFFLE}
                </a>
              </div>
            </div>
            <div
              className={
                acceptTerms
                  ? "my-2 submit-button"
                  : "my-2 submit-button disabled-div"
              }
              onClick={() => {
                validateAddress();
                sendGACollectorClickedReviewOrder();
              }}
            >
              {validCoupon ? string.PLACE_ORDER : string.REVIEW_ORDER}
            </div>
          </>
        ) : (
          infoLoaded && (
            <div
              className={"my-2 submit-button confirm-order-button"}
              onClick={() => {
                handleConfirmPurchase();
                sendGACollectorclickedGotoSecurePayment();
              }}
            >
              {reviewOrderCouponValid
                ? string.CONFIRM_ORDER
                : string.GO_TO_SECURE_PAYMENT}
            </div>
          )
        )}

        <div className="payment-type-block">
          {!isReviewStep && (
            <>
              <div className="payment-type-header">
                {string.AVAILABLE_PAYMENT_METHODS}
              </div>
              <div className="d-flex mb-5">
                <div className="payment-type-logo-block">
                  <img
                    className="payment-type-logo"
                    src={MasterCard}
                    alt="Master Card"
                  ></img>
                </div>
                <div className="payment-type-logo-block">
                  <img
                    className="payment-type-logo"
                    src={Visa}
                    alt="visa"
                  ></img>
                </div>
                <div>
                  <img className="payment-type-logo" src={Axp} alt="amex"></img>
                </div>
              </div>
            </>
          )}

          <div className="helper-container">
            <p className="help-text">{string.DO_YOU_NEED_HELP}</p>
            <div className="d-flex support-container">
              <img src={alternate_email} alt="at" className="support-icon" />
              <div className="support-contents">
                <p className="support-text">{string.HP_SUPPORT}</p>
                <a
                  className="support-link"
                  href="mailto:hpsupport.limitededitions@hp.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={sendGACollectorclickedsupportemail}
                >
                  {string.HP_SUPPORT_LIMITED_EDITIONS}
                </a>
              </div>
            </div>
          </div>
          {isSubmitOrder === true ? (
            <>
              <div className="substainibility_container">
                <div className="substainibility_title">
                  {string.SUSTAINABILITY}
                </div>
                <div className="sustainability_icons">
                  <img className="sustainability" src={WWF} alt="WWF" />
                  <img className="sustainability" src={forest} alt="forest" />
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      {errorMessage && (
        <div className="priceInfo-status-modal">
          <StatusModal
            canShow={CanShowErrorAlertModal}
            stautsbackground={{ background: "#212121" }}
            statusimge={error_icon}
            // statusHeading="This coupon code has been already used "
            statusDescription={errorMessage}
            closebackground={{ background: "#212121" }}
            closeData={{
              closeBtn: string.CLOSE,
              closeHandler: closeHandler,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default PriceInfo;
