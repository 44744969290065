import React, { useState } from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import {
  showOrHideApiLoadingModal,
  showOrHideUploadAIFileModal,
  showOrHideUploadAIOptionModal,
} from "../../../Store/Project/ModalSlice";
import { useDispatch, useSelector } from "react-redux";
import Closebutton from "../../../Utils/Icons/close.svg";
import Prompt1 from "../../../Utils/Icons/prompt1.svg";
import Prompt2 from "../../../Utils/Icons/prompt2.svg";
import AiUpload from "../../../Utils/Icons/ai_upload.svg";
import { string } from "../../../Localization";
import "./UploadOptionsModal.scss";
import ModalFooter from "../../ModalFooter/ModalFooter";
import SearchByAIModal from "../SearchByAi/SearchByAIModal";
import UploadByAIModal from "../UploadByAIModal/UploadByAIModal";
import {
  convertBytesToMegaBytes,
  formatDateTime,
  getFileExtension,
  getImageDimension,
  getPreviewUrlFromFile,
} from "../../../Utils/Helper";
import { MAX_SIZE_AI_IN_BYTES } from "../../../Utils/Constants";

const UploadOptionsModal = ({ isOpen }) => {
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { canShowUploadAIFileModal } = useSelector((state) => state.modal);

  const [imageDetails, setImageDetails] = useState(null);
  const [inputImage, setInputImage] = useState({});

  const fileInput = React.useRef(null);

  const handleModalOpenAI = () => {
    fileInput.current.click();
  };

  const handleCloseClick = () => {
    dispatch(showOrHideUploadAIOptionModal(false));
  };

  const handleModalOpen = () => {
    dispatch(showOrHideUploadAIOptionModal(false));
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFileSelection = (event) => {
    const fileUploaded = event.target.files[0];

    if (fileUploaded?.size > MAX_SIZE_AI_IN_BYTES) {
      dispatch(showOrHideApiLoadingModal(true));
    }
    try {
      getImageDimension(fileUploaded).then((imageDimensions) => {
        setImageDetails({
          name: fileUploaded.name,
          dimensions: imageDimensions,
          size: convertBytesToMegaBytes(fileUploaded.size),
          date: formatDateTime(new Date(fileUploaded.lastModified)),
          extension: getFileExtension(fileUploaded.name),
          preview: getPreviewUrlFromFile(fileUploaded, fileUploaded.size),
        });
        dispatch(showOrHideApiLoadingModal(false));
      });
    } catch (error) {
      dispatch(showOrHideApiLoadingModal(false));
    }
    setInputImage(fileUploaded);

    dispatch(showOrHideUploadAIFileModal(true));
    dispatch(showOrHideUploadAIOptionModal(false));
  };

  return (
    <>
      <Modal show={isOpen} className="search-modal-container-wrap">
        <ModalBody className="search-modal-container">
          <div
            className="close-btn-container"
            style={{ justifyContent: "flex-end" }}
          >
            <img
              className="close-btn"
              src={Closebutton}
              onClick={handleCloseClick}
              alt="Close"
            />
          </div>
          <div className="search-body">
            <h4 className="search-header">{string.CREATE_PHOTO_WITH_AI}</h4>
            <div className="search-description-container">
              <div
                className="option_container"
                style={{ borderRight: "1px solid #737373" }}
              >
                <div className="search-description">
                  <div className="d-flex flex-column  align-items-center search-description-content">
                    <div className="prompt-header">
                      {string.FROM_PROMPT_TO_IMAGE}
                    </div>
                    <div className="prompt-img-container mt-4">
                      <div className="prompt-search ps-2">
                        <span className="type-prompt">
                          {string.TYPE_PROMPT}
                        </span>
                      </div>
                      <div className="mt-4">
                        <img alt="" src={Prompt1} className="me-2" />
                        <img alt="" src={Prompt2} />
                      </div>
                    </div>
                    <p className="texts">{string.CHOOSE_AI_TEXT_BASED}</p>
                  </div>
                </div>
                <Button className="modal_option" onClick={handleModalOpen}>
                  {string.INPUT_A_PROMPT}
                </Button>
              </div>

              <div className="option_container">
                <div className="search-description">
                  <div className="d-flex flex-column  align-items-center search-description-content">
                    <div className="prompt-header">
                      {string.FROM_IMAGE_TO_IMAGE}
                    </div>{" "}
                    <img alt="" src={AiUpload} className="d-block mt-3" />
                    <p className="texts">{string.CHOOSE_AI_IMAGE_BASED}</p>
                  </div>
                </div>
                <Button className="modal_option" onClick={handleModalOpenAI}>
                  {string.UPLOAD_AN_IMAGE}
                </Button>{" "}
                <input
                  type="file"
                  ref={fileInput}
                  onChange={handleFileSelection}
                  style={{ display: "none" }}
                />
              </div>
            </div>
            <div className="search_by_ai_modal_footer">
              <ModalFooter />
            </div>
          </div>
        </ModalBody>
      </Modal>
      <SearchByAIModal
        isModalOpen={isModalOpen}
        onClose={handleCloseModal}
        setIsModalOpen={setIsModalOpen}
      />
      {imageDetails && inputImage && (
        <UploadByAIModal
          IsOpen={canShowUploadAIFileModal}
          inputImageContent={inputImage}
          uploadedImageDetails={imageDetails}
        />
      )}
    </>
  );
};

export default UploadOptionsModal;
