import React, { useEffect, useState } from "react";
import {
  getFileExtension,
  getImageDimension,
  getPreviewUrlFromFile,
} from "../../../Utils/Helper";
import "./LogoUpload.scss";
import Dropzone from "react-dropzone";
import { PNG, SVG, UPLOAD } from "../../../Utils/Constants";
import { DragDropContainer } from "react-drag-drop-container";
import deleteBtn from "../../../Utils/Icons/delete-signature.svg";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUserDetails } from "../../../Store/Project/UserSlice";
import StatusModal from "../../../Components/StatusModal/StatusModal";
import error_icon from "../../../Utils/Icons/error_icon.svg";
import { string } from "../../../Localization";

const LogoUpload = (props) => {
  const { logoText, uploadLogoText, onLogoChange, isValid, isDistroyed } =
    props;
  const dispatch = useDispatch();
  const { currentUserDetails } = useSelector((state) => state.user);

  const [logoImage, setLogoImage] = useState("");
  const [isError, setIsError] = useState(false);

  const logoType = UPLOAD;

  useEffect(
    () =>
      (window.onbeforeunload = function () {
        localStorage.removeItem("logoImage");
      }),
    []
  );

  const handleLogoImage = (logoImage) => {
    setLogoImage(logoImage);
  };

  const onDrop = (files) => {
    if (files.length > 0) {
      let fileUploaded = files[0];
      const fileExtension = getFileExtension(fileUploaded.name);

      if (fileExtension === PNG || fileExtension === SVG) {
        getImageDimension(fileUploaded).then((imageDimensions) => {
          onLogoChange(fileUploaded);
          isValid(true);
          handleLogoImage(getPreviewUrlFromFile(fileUploaded));
        });
      } else {
        setIsError(true);
      }
    }
  };

  const handleDeleteLogo = () => {
    setLogoImage("");
    onLogoChange("");
    isDistroyed(true);
    dispatch(
      setCurrentUserDetails({
        ...currentUserDetails,
        logo: "/logos/original/missing.png",
      })
    );
  };

  const handleClose = () => {
    setIsError(false);
  };

  return (
    <div>
      <div className="signature-subOption1">
        {(logoImage && logoType === UPLOAD) ||
        currentUserDetails?.logo !== "/logos/original/missing.png" ? (
          <>
            <div className="subOption-description">{logoText}</div>
            <DragDropContainer targetKey="dnd-signature" dragClone>
              <div className="logo-type-container">
                <div className="signature-container">
                  <div>
                    <img
                      draggable="false"
                      className="signature-image"
                      src={
                        currentUserDetails?.logo ===
                        "/logos/original/missing.png"
                          ? logoImage
                          : currentUserDetails?.logo
                      }
                      alt="logoImage"
                    />
                  </div>
                </div>

                <img
                  draggable="false"
                  className="logo-delete-btn"
                  src={deleteBtn}
                  alt=""
                  onClick={() => {
                    handleDeleteLogo();
                  }}
                />
              </div>
            </DragDropContainer>
          </>
        ) : (
          <>
            {logoType === UPLOAD && (
              <Dropzone onDrop={onDrop} multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzonee" })}>
                    <input {...getInputProps()} />

                    <>
                      <div className="upload-btn-wrapper">
                        <button className="upload-signature">
                          {string.UPLOAD_LOGOTYPE}
                        </button>
                      </div>
                    </>
                  </div>
                )}
              </Dropzone>
            )}
            {logoImage && (
              <img
                draggable="false"
                className="signature-image"
                src={logoImage}
                alt="logoImage"
              />
            )}
            <div className="subOption-description">{uploadLogoText}</div>
          </>
        )}

        {isError && (
          <StatusModal
            canShow={isError}
            stautsbackground={{ background: "#212121" }}
            statusimge={error_icon}
            statusHeading={string.INVALID_IMAGE_FORMAT}
            statusDescription={string.LOGO_FORMATS}
            closebackground={{ background: "#212121" }}
            closeData={{
              closeBtn: string.CLOSE,
              closeHandler: handleClose,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default LogoUpload;
