import React from "react";
import { sendGAAddedSignInPersonalization } from "../../../Utils/GAHelper";
import "./CustomRadiobutton.scss";

const CustomRadiobutton = (props) => {
  const { id, title, handleRadio, name, isChecked } = props;

  return (
    <div>
      <div
        className="radio-button-item"
        onClick={(e) => {
          handleRadio(true);
        }}
      >
        <input
          type="radio"
          id={id}
          name={name}
          className="rad-input"
          checked={isChecked}
          onChange={() => {
            // send GA for added signature in Personalization
            if (id == "radio1") {
              sendGAAddedSignInPersonalization();
            }
          }}
        />
        <label className="rad-text" for={id}>
          {title}
        </label>
      </div>
    </div>
  );
};

export default CustomRadiobutton;
