import React, { useEffect, useState } from "react";
import "./AdminOrderDetails.scss";
import { useDispatch, useSelector } from "react-redux";
import { ADMIN_NAV_BAR } from "../../../Utils/Constants";
import { setActivePageIndex } from "../../../Store/Project/AdminSlice";
import backArrowSvg from "../../../Utils/Icons/arrow_back.svg";
import { string } from "../../../Localization";
import { LoadBillingInformationForOrder } from "../../../Utils/ApiHelper";
import {
  setCheckBoxModal,
  setSalesOrderModal,
  showOrHideApiLoadingModal,
} from "../../../Store/Project/ModalSlice";
import { setCanShowBackAlertModal } from "../../../Store/Project/ProjectSlice";
import AdminViewModal from "../../../Components/Modal/AdminViewModal/AdminViewModal";
import ProjectLoader from "../../../Components/Loaders/ProjectLoader/ProjectLoader";
import StatusModal from "../../../Components/StatusModal/StatusModal";
import DataNotFound from "../DataNotFound";
import status_right from "../../../Utils/Icons/status_right.svg";
import { useNavigate, useParams } from "react-router-dom";

const AdminOrderDetails = () => {
  const { salesOrderModal, checkBoxModal } = useSelector(
    (state) => state.modal
  );
  const { CanShowBackAlertModal } = useSelector((state) => state.project);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState("");
  const [jsonStringdata, setJsonStringdata] = useState("");
  const [authresp, setAuthresp] = useState("");
  const [jsonData, setJsonData] = useState(null);
  const [failed, setFailed] = useState(false);
  const [largeData, setLargeData] = useState("");

  const { order_token } = useParams();

  useEffect(() => {
    dispatch(showOrHideApiLoadingModal(true));
    LoadBillingInformationForOrder(order_token)
      .then((res) => {
        setFailed(false);
        setData(res?.data?.data);
        setAuthresp(data?.billing?.auth_resp_body);
        dispatch(showOrHideApiLoadingModal(false));
      })
      .catch((err) => {
        dispatch(showOrHideApiLoadingModal(false));
        console.log(err);
        if (err?.response?.status === 401) {
          navigate("/");
        } else if (err?.response?.status === 422) {
          setFailed(true);
        }
      });
  }, []);

  useEffect(() => {
    setJsonData(parseXML(authresp));
  }, []);

  const handleClose = () => {
    dispatch(setSalesOrderModal(false));
    dispatch(setCheckBoxModal(false));
  };

  const onNavigateBack = () => {
    navigate(-1);
    dispatch(setActivePageIndex(ADMIN_NAV_BAR));
  };

  const handleCopyClick = (jsonString) => {
    navigator.clipboard
      .writeText(jsonString)
      .then(() => {
        dispatch(setCanShowBackAlertModal(true));
      })
      .catch((error) => {
        console.error("Failed to copy JSON to clipboard:", error);
      });
  };

  const handleViewClick = (jsonString) => {
    setJsonStringdata(jsonString);
    dispatch(setSalesOrderModal(true));
  };

  const handleViewClickPickupId = (data) => {
    setLargeData(data);
    dispatch(setCheckBoxModal(true));
  };

  const formattedAddressDetails = () => {
    return (
      <>
        <div>
          {data?.order?.address_line1}
          {data?.order?.address_line2 ? "," : ""}
        </div>
        <div>
          {data?.order?.address_line2 ? data?.order?.address_line2 : ""}
        </div>
      </>
    );
  };

  const jsonStrings = JSON.stringify(data?.billing?.pgs_resp_body, null, 2);

  const closeHandler = () => {
    dispatch(setCanShowBackAlertModal(false));
  };

  const parseXML = (xmlString) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "text/xml");
    const parseNode = (node) => {
      const data = {};
      for (let i = 0; i < node.childNodes.length; i++) {
        const child = node.childNodes[i];
        if (child.nodeType === 1) {
          if (child.childNodes.length > 0) {
            data[child.nodeName] = parseNode(child);
          } else {
            data[child.nodeName] = child.textContent;
          }
        }
      }
      return data;
    };
    return parseNode(xmlDoc.documentElement);
  };

  const jsondatas = JSON.stringify(jsonData, null, 2);

  return (
    <div className="admin_order_details_parent_container">
      <ProjectLoader />
      {!failed ? (
        <div className="admin_order_details_container">
          <div className="back_btn">
            <div className="admin_back_btn">
              <div
                className="navigateBackContainer"
                onClick={() => {
                  onNavigateBack();
                }}
              >
                <img src={backArrowSvg} alt="" />
                <span>{string.BACK}</span>
              </div>
            </div>
            <div className="admin_order_details">
              <div className="order_details_text">{string.ORDER_DETAILS}</div>
            </div>
          </div>

          <div className="admin_order_container">
            <div className="admin_order_wrapper">
              <div className="admin_order_image">
                <img
                  src={data?.order?.preview_image}
                  alt="preview"
                  className="order_image"
                />
              </div>
              <div className="admin_order_image_text">
                <div className="admin_img_description">
                  <div className="admin_img_title">{data?.order?.title}</div>
                  <div className="admin_img_size">{data?.order?.dimension}</div>
                  <div className="admin_img_material">
                    {data?.order?.media_type_name}
                  </div>
                  <div className="admin_img_edition">
                    {data?.order?.edition}
                  </div>
                  <div className="admin_img_message">
                    {string.BLOCKCHAIN_AUTHENTICITY_MESSAGE}
                  </div>
                  <div className="admin_img_order">
                    {string.ORDER_NUMBER_1}: #{data?.order?.id}
                  </div>
                </div>
              </div>
            </div>
            <div className="admin_line"></div>
            <div className="admin_order_wrapper_2">
              <div className="admin_order_contant_1">
                <div className="admin_title">{string.CUSTOMER_INFORMATION}</div>
                <div className="admin_item">
                  <div className="admin_info_field_key">{string.NAME}</div>
                  <div className="admin_info_field_text">
                    {data?.order?.customer_name}
                  </div>
                </div>
                <div className="admin_item">
                  <div className="admin_info_field_key">
                    {string.DELIVERY_ADDRESS}
                  </div>
                  <div className="admin_info_field_text">
                    {formattedAddressDetails()}
                  </div>
                </div>
                <div className="admin_item">
                  <div className="admin_info_field_key">{string.EMAIL_1}</div>
                  <div className="admin_info_field_text">
                    {data?.order?.email}
                  </div>
                </div>
                <div className="admin_item">
                  <div className="admin_info_field_key">
                    {string.MOBILE_NUMBER_1}
                  </div>
                  <div className="admin_info_field_text">
                    {data?.order?.contact_number}
                  </div>
                </div>
              </div>
              <div className="admin_order_contant_2">
                <div className="admin_title">{string.PRICING_AND_INCOME}</div>
                <div className="admin_item">
                  <div className="admin_info_field_key">
                    {string.ARTIST_INCOME.toUpperCase()}
                  </div>
                  <div className="admin_info_field_text">
                    {data?.price?.income}
                  </div>
                </div>
                <div className="admin_item">
                  <div className="admin_info_field_key">
                    {string.SERVICE_FEES_1}
                  </div>
                  <div className="admin_info_field_text">
                    {data?.price?.service_fees}
                  </div>
                </div>
                <div className="admin_item">
                  <div className="admin_info_field_key">
                    {string.SELLING_PRICE_1}
                  </div>
                  <div className="admin_info_field_text">
                    {data?.price?.selling_price}
                  </div>
                </div>
                {data?.price?.coupon?.discount_amount && (
                  <div className="admin_item">
                    <div className="admin_info_field_key">
                      {string.DISCOUNT_1}
                    </div>
                    <div className="admin_info_field_text">
                      {data?.price?.coupon?.discount_amount} ({" "}
                      {data?.price?.coupon?.code})
                    </div>
                  </div>
                )}
              </div>
              <div className="admin_order_contant_3">
                <div className="admin_title">{string.ORDER_DETAILS}</div>
                <div className="admin_item">
                  <div className="admin_info_field_key">
                    {string.TRACKING_NUMBER_1}
                  </div>
                  <div className="admin_info_field_text">
                    {data?.order?.tracking_number
                      ? data?.order?.tracking_number
                      : "-"}
                  </div>
                </div>
                <div className="admin_item">
                  <div className="admin_info_field_key">
                    {string.TRACKING_URL}
                  </div>
                  <div className="admin_info_field_text">
                    {data?.order?.tracking_url ? (
                      <a
                        className="admin-order-details-personal-message"
                        href={data?.order?.tracking_url}
                        alt="printable pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {string.VIEW}
                      </a>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
                <div className="admin_item">
                  <div className="admin_info_field_key">
                    {string.PERSONAL_MESSAGE}
                  </div>
                  <div className="admin_info_field_text">
                    {data?.order?.personal_message != "" ? (
                      <a
                        className="admin-order-details-personal-message"
                        href={data?.order?.personal_message}
                        alt="printable pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {string.VIEW}
                      </a>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="Billing_content">{string.BILLING_INFORMATION}</div>
            <div className="admin_order_wrapper_2">
              <div className="admin_order_contant_3">
                <div className="admin_title_billing">{string.BASIC_INFO}</div>
                <div className="admin_basic_info_container">
                  <div className="admin_item">
                    <div className="admin_info_field_key">RMsg</div>
                    <div className="admin_info_field_text">
                      {data?.billing?.r_msg ? data?.billing?.r_msg : "-"}
                    </div>
                  </div>
                  <div className="admin_item">
                    <div className="admin_info_field_key">RCode</div>
                    <div className="admin_info_field_text">
                      {data?.billing?.r_code ? data?.billing?.r_code : "-"}
                    </div>
                  </div>
                  <div className="admin_item">
                    <div className="admin_info_field_key">RFlag</div>
                    <div className="admin_info_field_text">
                      {data?.billing?.r_flag ? data?.billing?.r_flag : "-"}
                    </div>
                  </div>
                </div>
                <div className="admin_item">
                  <div className="admin_info_field_key">CC Token</div>
                  <div className="admin_info_field_text">
                    {data?.billing?.cc_token ? data?.billing?.cc_token : "-"}
                  </div>
                </div>
                <div className="admin_item">
                  <div className="admin_info_field_key">CC Signature</div>
                  <div className="admin_info_field_text">
                    {data?.billing?.cc_signature
                      ? data?.billing?.cc_signature
                      : "-"}
                  </div>
                </div>
                <div className="admin_item">
                  <div className="admin_info_field_key">CVV Token</div>
                  <div className="admin_info_field_text">
                    {data?.billing?.cvv_token ? data?.billing?.cvv_token : "-"}
                  </div>
                </div>

                <div className="admin_item">
                  <div className="admin_info_field_key">Merchant Name</div>
                  <div className="admin_info_field_text">
                    {data?.billing?.merchant_name
                      ? data?.billing?.merchant_name
                      : "-"}
                  </div>
                </div>
              </div>

              <div className="admin_order_contant_3">
                <div className="admin_title_billing">PSG Info</div>
                <div className="admin_item">
                  <div className="admin_info_field_key">PGS Order Id</div>
                  <div className="admin_info_field_text">
                    {data?.billing?.pgs_order_id
                      ? data?.billing?.pgs_order_id
                      : "-"}
                  </div>
                </div>
                <div className="admin_item">
                  <div className="admin_info_field_key">PHC Pickup Id</div>
                  <div className="admin_info_field_text large_text_wrapper">
                    <div className="overflow_text pick_up_Id_width">
                      {data?.billing?.phc_pickup_id
                        ? data?.billing?.phc_pickup_id
                        : "-"}
                    </div>
                    <div
                      className={data?.billing?.phc_pickup_id && "fa fa-eye"}
                      onClick={() => {
                        handleViewClickPickupId(data?.billing?.phc_pickup_id);
                      }}
                    ></div>
                  </div>
                </div>
                <div className="admin_item">
                  <div className="admin_info_field_key">PGS Response Code</div>
                  <div className="admin_info_field_text">
                    {data?.billing?.pgs_resp_code
                      ? data?.billing?.pgs_resp_code
                      : "-"}
                  </div>
                </div>
                {data?.billing && (
                  <div className="admin_item">
                    <div className="admin_info_field_key">
                      PGS Response Body
                    </div>
                    <div className="admin_info_field_text">
                      <div
                        className="admin-order-details-personal-message"
                        onClick={() => {
                          handleViewClick(jsonStrings);
                        }}
                      >
                        View
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="admin_order_contant_3">
                <div className="admin_title_billing">Auth Info</div>
                <div className="admin_item">
                  <div className="admin_info_field_key">AuthRCode</div>
                  <div className="admin_info_field_text">
                    {data?.billing?.auth_r_code
                      ? data?.billing?.auth_r_code
                      : "-"}
                  </div>
                </div>
                <div className="admin_item">
                  <div className="admin_info_field_key">AuthRMsg</div>
                  <div className="admin_info_field_text">
                    {data?.billing?.auth_r_msg
                      ? data?.billing?.auth_r_msg
                      : "-"}
                  </div>
                </div>
                <div className="admin_item">
                  <div className="admin_info_field_key">AuthRFlag</div>
                  <div className="admin_info_field_text">
                    {data?.billing?.auth_r_flag
                      ? data?.billing?.auth_r_flag
                      : "-"}
                  </div>
                </div>
                <div className="admin_item">
                  <div className="admin_info_field_key">AuthRespCode</div>
                  <div className="admin_info_field_text">
                    {data?.billing?.auth_resp_code
                      ? data?.billing?.auth_resp_code
                      : "-"}
                  </div>
                </div>
                <div className="admin_item">
                  <div className="admin_info_field_key">AuthRespBody</div>
                  <div className="admin_info_field_text">
                    <div
                      className="admin-order-details-personal-message"
                      onClick={() => {
                        handleViewClick(jsondatas);
                      }}
                    >
                      View
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <DataNotFound />
      )}
      <AdminViewModal
        handleClose={handleClose}
        jsonStringData={jsonStringdata}
        openModal={salesOrderModal}
        onViewClick={handleCopyClick}
      />
      <StatusModal
        canShow={CanShowBackAlertModal}
        stautsbackground={{ background: "#176267" }}
        statusimge={status_right}
        statusHeading={string.COPIED_SUCCESSFULLY}
        statusDescription={string.YOU_HAVE_COPIED_THE_PAYMENT_DETAILS}
        closebackground={{ background: "#176267" }}
        closeData={{
          closeBtn: string.CLOSE,
          closeHandler: closeHandler,
        }}
      />
      <AdminViewModal
        handleClose={handleClose}
        jsonStringData={largeData}
        openModal={checkBoxModal}
        isOnlyText={true}
      />
    </div>
  );
};

export default AdminOrderDetails;
