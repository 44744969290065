import React from "react";
import { useSelector } from "react-redux";
import { string } from "../../../Localization";
import UploadSpinner from "../UploadSpinner/UploadSpinner";
import "./SuperResFlowLoader.scss";

const SuperResFlowLoader = () => {
  const { showSuperResolutionLoader } = useSelector((state) => state.modal);
  return (
    <div
      className="super-resolution-loader-container"
      style={{ display: showSuperResolutionLoader ? "flex" : "none" }}
    >
      <UploadSpinner text={string.IMPROVE_RESOLUTION_MESSAGE} />
    </div>
  );
};

export default SuperResFlowLoader;
