import axios from "axios";
import {
  API_END_POINT,
  API_V2_END_POINT,
  BASE_URL,
  IMAGE_UPLOAD_API,
  POST_API,
} from "./Constants";
import { appendTimeStampToPath, handleUploadProgress } from "./Helper";

const headers = {};
if (process.env.REACT_APP_DEVELOPMENT_SECRET_KEY) {
  headers["development-secret-key"] =
    process.env.REACT_APP_DEVELOPMENT_SECRET_KEY;
}

const setHeaders = (uploadApi) => {
  switch (uploadApi) {
    case IMAGE_UPLOAD_API:
      headers["Content-Type"] = "multipart/form-data";
      return headers;
    case POST_API:
      headers["Content-Type"] = "multipart/form-data";
      return { headers: headers };
    default:
      return { headers: headers };
  }
};

export const getCurrentUser = () => {
  return axios.get(`${API_END_POINT}/hp-id/user_data`, {
    timeout: 60000,
    headers,
  });
};

export const disableQuickStartGuide = () => {
  return axios.put(
    `${API_END_POINT}/users/disable_quick_start_guide`,
    {},
    setHeaders()
  );
};

export const disableTermsAndCondition = () => {
  return axios.put(
    `${API_END_POINT}/users/accept_legal_terms`,
    {},
    setHeaders()
  );
};

export const signOut = () => {
  return axios.delete(`${API_END_POINT}/hp-id/log_out`, setHeaders());
};

export const getProjects = async (page, limit, sort, recordType = "") => {
  const res = await axios.get(
    appendTimeStampToPath(
      `${API_END_POINT}/projects?page=${page}&limit=${limit}&sort=${sort}&record_type=${recordType}`
    ),
    setHeaders()
  );
  return res.data;
};

export const getFeedData = async (page, limit) => {
  const res = await axios.get(
    `${API_V2_END_POINT}/feeds/retrieve_feeds?page=${page}&pageLimit=${limit}`,
    setHeaders()
  );
  return res.data.data;
};

export const retrievePhotographers = async () => {
  const res = await axios.get(
    `${API_V2_END_POINT}/feeds/retrieve_photographers`
  );
  return res.data.data;
};

export const getPhotographerFeed = async (page, limit, userName) => {
  const res = await axios.get(
    `${API_V2_END_POINT}/feeds/${userName}/retrieve_photographer_feeds?page=${page}&pageLimit=${limit}`,
    setHeaders()
  );
  return res.data.data;
};

export const getPhotographerPreviewData = async (token, page, limit) => {
  const res = await axios.get(
    `${API_V2_END_POINT}/feeds/${token}/retrieve_my_feeds?page=${page}&pageLimit=${limit}`,
    setHeaders()
  );
  return res.data.data;
};

export const getProject = async (token) => {
  const res = await axios.get(
    `${API_END_POINT}/projects/${token}`,
    setHeaders()
  );
  return res.data;
};

let uploadAbortController;

export const uploadImage = (formData) => {
  uploadAbortController = new AbortController();
  return axios.post(`${API_END_POINT}/user_photos/upload`, formData, {
    headers: setHeaders(IMAGE_UPLOAD_API),
    signal: uploadAbortController.signal,
    onUploadProgress: (progressEvent) => {
      handleUploadProgress(progressEvent);
    },
  });
};

export const generateTargetURL = (editedDate, fileName,fileSize) => {
  return axios.get(
    `${API_V2_END_POINT}/user_photos/generate_target_url?project[edited_date]=${editedDate}&project[upload_file_name]=${fileName}&project[uploadFileSize]=${fileSize}`,
    setHeaders()
  );
};

export const uploadToTargetURL = (uploadUrl, file) => {
  uploadAbortController = new AbortController();
  return axios.put(uploadUrl, file, {
    headers: { "Content-Type": file.type },
    signal: uploadAbortController.signal,
    onUploadProgress: (progressEvent) => {
      handleUploadProgress(progressEvent);
    },
  });
};

export const cancelUpload = () => {
  if (uploadAbortController) {
    uploadAbortController.abort();
  }
};

export const verifyBlockchainStatus = (token, productSizeId) => {
  return axios.get(
    `${API_END_POINT}/blockchain/verify_blockchain_status/${token}?productSizeId=${productSizeId}`,
    setHeaders()
  );
};

export const pollBlockchainStatus = (token) => {
  return axios.get(
    `${API_END_POINT}/blockchain/poll_blockchain_status/${token}`,
    setHeaders()
  );
};

export const uploadSignatureImage = (token, formData, config) => {
  config.headers = setHeaders(IMAGE_UPLOAD_API);
  return axios.post(
    `${API_END_POINT}/signature_images/${token}/upload_signature`,
    formData,
    config
  );
};

export const submitOrder = (formData, token) => {
  return axios.post(
    `${API_END_POINT}/projects/${token}/submit_order`,
    formData,
    setHeaders(POST_API)
  );
};

export const saveDraft = (formData, token) => {
  return axios.post(
    `${API_V2_END_POINT}/projects/${token}/save_draft`,
    formData,
    setHeaders(POST_API)
  );
};

export const sendPreviewPhoto = (formData, token) => {
  return axios.post(
    `${API_V2_END_POINT}/projects/${token}/upload_display_image`,
    formData,
    setHeaders(POST_API)
  );
};

export const uploadVisualRefImages = (formData, token) => {
  return axios.post(
    `${API_V2_END_POINT}/projects/${token}/upload_visual_reference_images`,
    formData,
    setHeaders(POST_API)
  );
};

export const encrypt = (formData, token) => {
  return axios.post(
    `${API_V2_END_POINT}/projects/${token}/encrypt`,
    formData,
    setHeaders(POST_API)
  );
};

export const orderArtistProof = (token) => {
  return axios.post(
    `${API_V2_END_POINT}/projects/${token}/order_artist_proof`,
    {},
    setHeaders(POST_API)
  );
};

export const deleteOrder = (token) => {
  return axios.delete(`${API_END_POINT}/projects/${token}`, setHeaders());
};

export const deletePhoto = (token) => {
  return axios.delete(`${API_END_POINT}/projects/${token}`, setHeaders());
};

export const placePrintOrder = (formData, token) => {
  return axios.post(
    `${API_END_POINT}/projects/${token}/print`,
    formData,
    setHeaders(POST_API)
  );
};

export const getQrCode = (token) => {
  return axios.get(`${API_END_POINT}/projects/${token}/qr_code`, setHeaders());
};

export const getPricingInfo = (productSizeId, mediaTypeId) => {
  return axios.get(
    `${API_END_POINT}/product_costs/pricing_info?productSizeId=${productSizeId}&mediaTypeId=${mediaTypeId}`,
    setHeaders()
  );
};

export const fetchMediaTypes = () => {
  return axios.get(`${API_V2_END_POINT}/media_types`, setHeaders());
};

export const updateUserProfile = (formData) => {
  return axios.post(
    `${API_END_POINT}/users/update_user_profile`,
    formData,
    setHeaders(POST_API)
  );
};

export const updateProjectSignature = (formData, token) => {
  if (token) {
    return axios.post(
      `${API_END_POINT}/projects/${token}/upload_project_signature`,
      formData,
      setHeaders(POST_API)
    );
  }
  return axios.post(
    `${API_END_POINT}/projects/upload_project_signature`,
    formData,
    setHeaders(POST_API)
  );
};

export const deleteProjectSignature = (token) => {
  return axios.delete(
    `${API_END_POINT}/projects/${token}/delete_project_signature`,
    setHeaders()
  );
};

export const addNewVariation = (imgToken) => {
  return axios.post(
    `${API_END_POINT}/user_photos/${imgToken}/add_variations`,
    {},
    setHeaders(POST_API)
  );
};

export const getOrderManagementDashboardInfo = () => {
  return axios.get(
    `${API_V2_END_POINT}/orders/order_management_dashboard`,
    setHeaders()
  );
};

export const getOrders = (
  token,
  currentPage,
  perPage,
  sortColumnKey,
  sortTypeKey
) => {
  return axios.get(
    `${API_V2_END_POINT}/projects/${token}/project_orders?page=${currentPage}&pageLimit=${perPage}&sort_column_key=${sortColumnKey}&sort_type_key=${sortTypeKey}`,
    setHeaders()
  );
};

export const getOrderManagementRecords = (
  page,
  pageLimit,
  sortBy,
  sortType
) => {
  return axios.get(
    `${API_V2_END_POINT}/orders/order_management_records?page=${page}&pageLimit=${pageLimit}&sort_column_key=${sortBy}&sort_type_key=${sortType}`,
    setHeaders()
  );
};

export const getOrderInfo = (token) => {
  return axios.get(`${API_V2_END_POINT}/orders/${token}/info`, setHeaders());
};

export const getProjectSummary = (token, source = "") => {
  let baseUrl = `${API_V2_END_POINT}/projects/${token}/project_summary`;
  let url = source ? `${baseUrl}?source=${source}` : baseUrl;
  return axios.get(url, setHeaders());
};

export const getProjectDetails = (token) => {
  return axios.get(`${API_V2_END_POINT}/projects/${token}`, setHeaders());
};

export const getPhotoPrintDetails = (token) => {
  return axios.get(
    `${API_V2_END_POINT}/projects/${token}/photo_print_detail`,
    setHeaders()
  );
};

export const updateProject = (token, formData) => {
  return axios.post(
    `${API_V2_END_POINT}/projects/${token}/update_project`,
    formData,
    setHeaders(POST_API)
  );
};

export const updateOrder = (token, formData) => {
  return axios.post(
    `${API_V2_END_POINT}/orders/${token}/update_order`,
    formData,
    setHeaders(POST_API)
  );
};

export const updateCollectorNotePreference = (token, formData) => {
  return axios.put(
    `${API_V2_END_POINT}/orders/${token}/update_collector_note_preference`,
    formData,
    setHeaders()
  );
};

export const updateCustomerDetails = (token, formData) => {
  return axios.put(
    `${API_V2_END_POINT}/orders/${token}/update_customer_detail`,
    formData,
    setHeaders()
  );
};

export const update_feed_likes = (token) => {
  return axios.put(
    `${API_V2_END_POINT}/feeds/update_feed_likes?project_token=${token}`,
    {},
    setHeaders()
  );
};

export const updateAddressDetails = (token, formData) => {
  return axios.put(
    `${API_V2_END_POINT}/orders/${token}/update_address_detail`,
    formData,
    setHeaders()
  );
};

export const createOrder = (token, formData) => {
  return axios.post(
    `${API_V2_END_POINT}/orders/${token}/create_order`,
    formData,
    setHeaders(POST_API)
  );
};

export const getPgsInformation = (token) => {
  return axios.get(
    `${API_V2_END_POINT}/orders/${token}/pgs_information`,
    setHeaders()
  );
};

export const getPriceInfo = (
  income,
  currencyType,
  productSizeId,
  mediaTypeId
) => {
  return axios.get(
    `${API_V2_END_POINT}/projects/price_info?income=${income}&currencyType=${currencyType}&productSizeId=${productSizeId}&mediaTypeId=${mediaTypeId}`,
    setHeaders()
  );
};

export const getExhaustedProjectDetails = (token, source = "") => {
  let baseUrl = `${API_V2_END_POINT}/projects/${token}/exhausted_project_details`;
  let url = source ? `${baseUrl}?source=${source}` : baseUrl;
  return axios.get(url, setHeaders());
};

export const triggerJondoOrder = (token) => {
  return axios.get(
    `${API_V2_END_POINT}/orders/${token}/trigger_jondo_order`,
    setHeaders()
  );
};

export const validateCouponCode = (token, couponCode, source = "") => {
  let base = `${API_V2_END_POINT}/projects/${token}/validate_coupon_code?couponCode=${couponCode}`;
  let url = source ? `${base}&source=${source}` : base;
  return axios.get(url, setHeaders());
};

export const getProjectCount = async (recordType) => {
  const res = await axios.get(
    `${API_V2_END_POINT}/projects/user_projects_count?record_type=${recordType}`,
    setHeaders()
  );
  return res.data;
};

export const uploadImageV2 = (formData, config) => {
  config.headers = setHeaders(IMAGE_UPLOAD_API);
  return axios.post(`${API_V2_END_POINT}/user_photos/upload`, formData, config);
};

export const pollUploadImageStatus = (token) => {
  return axios.get(
    `${API_V2_END_POINT}/user_photos/${token}/polling_status`,
    setHeaders()
  );
};

export const footerLinks = (countryCode) => {
  return axios.get(`${BASE_URL}/footer_links?country_code=${countryCode}`);
};

export const fetchQrCode = () => {
  return axios.get(`${BASE_URL}/static_qr_code`);
};

export const generateAutoCompletedMessage = (title) => {
  return axios.get(
    `${API_END_POINT}/open_ai/generate_auto_completed_message?title=${title}`,
    setHeaders()
  );
};

export const getCetificateInfo = (order_token) => {
  return axios.get(
    `${API_V2_END_POINT}/orders/${order_token}/certificate_info`,
    setHeaders()
  );
};

export const checkDeploymentStatus = () => {
  return axios.get(
    `${API_V2_END_POINT}/projects/check_deployment_notice`,
    setHeaders()
  );
};

export const generateImage = (prompt, orientation) => {
  return axios.get(
    `${API_END_POINT}/open_ai/generate_image?prompt=${prompt}&orientation=${orientation}`,
    setHeaders()
  );
};

export const uploadImageUsingUrl = (formData) => {
  return axios.post(
    `${API_V2_END_POINT}/user_photos/upload_image_using_url`,
    formData,
    setHeaders(POST_API)
  );
};

export const updateUserCountry = (countryCode) => {
  return axios.put(
    `${API_END_POINT}/users/update_user_country?countryCode=${countryCode}`,
    {},
    setHeaders()
  );
};

export const generatePersonalNote = (order_token) => {
  return axios.get(
    `${API_END_POINT}/open_ai/generate_personal_note?order_token=${order_token}`,
    {},
    setHeaders
  );
};

export const confirmOrder = (order_token) => {
  return axios.put(
    `${API_V2_END_POINT}/orders/${order_token}/confirm_order?confirm=${true}`,
    {},
    setHeaders()
  );
};

export const unsubscribe = () => {
  return axios.put(`${API_END_POINT}/users/unsubscribe`, {}, setHeaders());
};

export const unsubscribeAndRemoveAccount = () => {
  return axios.delete(`${API_END_POINT}/users/remove_account`, setHeaders());
};

export const RetriveUsers = (
  page,
  pageLimit,
  sort_type_key,
  sort_column_key,
  search_key,
  search_value
) => {
  return axios.get(
    `${API_V2_END_POINT}/admins/retrieve_users?page=${page}&pageLimit=${pageLimit}&sort_type_key=${sort_type_key}&sort_column_key=${sort_column_key}&search_key=${search_key}&search_value=${search_value}`,
    setHeaders()
  );
};

export const ToggleAdmin = (user, key) => {
  return axios.put(
    `${API_V2_END_POINT}/admins/toggle_access?user_id=${user}&toggle_key=${key}`,
    {},
    setHeaders()
  );
};

export const RetrieveUserOrders = (
  page,
  pageLimit,
  sort_type_key,
  sort_column_key,
  search_key,
  search_value
) => {
  return axios.get(
    `${API_V2_END_POINT}/admins/retrieve_orders?page=${page}&pageLimit=${pageLimit}&sort_type_key=${sort_type_key}&sort_column_key=${sort_column_key}&search_key=${search_key}&search_value=${search_value}`,
    setHeaders()
  );
};

export const RetrieveNfts = (
  page,
  pageLimit,
  sort_type_key,
  sort_column_key,
  search_key,
  search_value
) => {
  return axios.get(
    `${API_V2_END_POINT}/admins/retrieve_nfts?page=${page}&pageLimit=${pageLimit}&sort_column_key=${sort_column_key}&sort_type_key=${sort_type_key}&search_key=${search_key}&search_value=${search_value}`,
    setHeaders()
  );
};

export const RetrieveProject = (
  page,
  pageLimit,
  sort_type_key,
  sort_column_key,
  search_key,
  search_value
) => {
  return axios.get(
    `${API_V2_END_POINT}/admins/retrieve_projects?page=${page}&pageLimit=${pageLimit}&sort_type_key=${sort_type_key}&sort_column_key=${sort_column_key}&search_key=${search_key}&search_value=${search_value}`,
    setHeaders()
  );
};

export const RetrieveMulesoftOrders = (
  page,
  pageLimit,
  sort_type_key,
  sort_column_key,
  search_key,
  search_value
) => {
  return axios.get(
    `${API_V2_END_POINT}/admins/retrieve_mulesoft_orders?page=${page}&pageLimit=${pageLimit}&sort_type_key=${sort_type_key}&sort_column_key=${sort_column_key}&search_key=${search_key}&search_value=${search_value}`,
    setHeaders()
  );
};

export const updatePersonalMessage = (token, secToken, formData) => {
  return axios.put(
    `${API_V2_END_POINT}/orders/${token}/update_personal_message?sec_token=${secToken}`,
    formData,
    setHeaders()
  );
};

export const stopSelling = (token) => {
  return axios.put(
    `${API_V2_END_POINT}/projects/${token}/stop_selling`,
    {},
    setHeaders()
  );
};

export const startSelling = (token) => {
  return axios.put(
    `${API_V2_END_POINT}/projects/${token}/start_selling`,
    {},
    setHeaders()
  );
};

export const updateTrackingInfo = (token, secToken, formData) => {
  return axios.put(
    `${API_V2_END_POINT}/orders/${token}/update_tracking_info?sec_token=${secToken}`,
    formData,
    setHeaders()
  );
};

export const RetrieveProjectOrders = (
  page,
  pageLimit,
  sort_type_key,
  sort_column_key,
  search_key,
  search_value,
  projectToken
) => {
  return axios.get(
    `${API_V2_END_POINT}/admins/${projectToken}/retrieve_project_orders?page=${page}&pageLimit=${pageLimit}&sort_type_key=${sort_type_key}&sort_column_key=${sort_column_key}&search_key=${search_key}&search_value=${search_value}`,
    setHeaders()
  );
};

export const LoadBillingInformationForOrder = (orderToken) => {
  return axios.get(
    `${API_V2_END_POINT}/admins/${orderToken}/load_billing_information_for_order`,
    setHeaders()
  );
};

export const RetriveWeb2PrintOrders = (
  page,
  pageLimit,
  sort_type_key,
  sort_column_key,
  search_key,
  search_value
) => {
  return axios.get(
    `${API_V2_END_POINT}/admins/retrieve_web2print_orders?page=${page}&pageLimit=${pageLimit}&sort_column_key=${sort_column_key}&sort_type_key=${sort_type_key}&search_key=${search_key}&search_value=${search_value}`,
    setHeaders()
  );
};

export const RetriveMatricsReport = (
  page,
  pageLimit,
  sort_type_key,
  sort_column_key,
  search_key,
  search_value
) => {
  return axios.get(
    `${API_V2_END_POINT}/admins/retrieve_metrics_report?page=${page}&pageLimit=${pageLimit}&sort_column_key=${sort_column_key}&sort_type_key=${sort_type_key}&search_key=${search_key}&search_value=${search_value}`,
    setHeaders()
  );
};

export const RetrieveUserProject = (
  page,
  pageLimit,
  sort_type_key,
  sort_column_key,
  search_key,
  search_value,
  userId
) => {
  return axios.get(
    `${API_V2_END_POINT}/admins/${userId}/retrieve_user_projects?page=${page}&pageLimit=${pageLimit}&sort_type_key=${sort_type_key}&sort_column_key=${sort_column_key}&search_key=${search_key}&search_value=${search_value}`,
    setHeaders()
  );
};

export const copiedSalesLink = (token) => {
  return axios.put(
    `${API_V2_END_POINT}/projects/${token}/copied_sales_link?`,
    {},
    setHeaders()
  );
};

export const customiseSalesLink = (prokectToken, formData) => {
  return axios.post(
    `${API_V2_END_POINT}/url_shortners/${prokectToken}/replace_url_token`,
    formData,
    setHeaders()
  );
};

export const RetrieveUsageSummary = (startDate, endDate) => {
  return axios.get(
    `${API_V2_END_POINT}/admins/retrieve_usage_summary?start_date=${startDate}&end_date=${endDate}`,
    setHeaders()
  );
};

export const updateInstagramProfile = (instaUrl) => {
  return axios.put(
    `${API_END_POINT}/users/update_instagram_profile?instagramUrl=${instaUrl}`,
    {},
    setHeaders()
  );
};

export const validateUserName = (userName) => {
  return axios.get(
    `${API_END_POINT}/users/validate_user_name?userName=${userName}`,
    setHeaders()
  );
};

export const reActivateAccount = () => {
  return axios.put(`${API_END_POINT}/users/recover_account`, {}, setHeaders());
};

export const deleteAccount = () => {
  return axios.delete(`${API_END_POINT}/users/remove_account`, setHeaders());
};

export const Initiate_OpenSea = (projectToken) => {
  return axios.post(
    `${API_V2_END_POINT}/projects/${projectToken}/initiate_opensea_publish`,
    {},
    setHeaders()
  );
};

export const PublishToOpenSea = (tag) => {
  return axios.put(
    `${API_V2_END_POINT}/admins/publish_to_opensea?tag=${tag}`,
    {},
    setHeaders()
  );
};

export const pollPublishToOpenSea = (tag) => {
  return axios.get(
    `${API_V2_END_POINT}/admins/poll_publish_opensea_status?tag=${tag}`,
    setHeaders()
  );
};

export const configureWallet = (formData) => {
  return axios.post(
    `${API_V2_END_POINT}/wallets/configure_wallet`,
    formData,
    setHeaders(POST_API)
  );
};

export const applySuperResolutionForImage = (formData, projectToken) => {
  return axios.post(
    `${API_V2_END_POINT}/projects/${projectToken}/apply_super_resolution_for_image`,
    formData,
    setHeaders(POST_API)
  );
};

export const toggleProjectGalleryAccess = (projectToken, formData) => {
  return axios.put(
    `${API_V2_END_POINT}/projects/${projectToken}/toggle_gallery_access`,
    formData,
    setHeaders(POST_API)
  );
};

export const SetDefaultWallet = () => {
  return axios.post(
    `${API_V2_END_POINT}/wallets/set_active_wallet`,
    {},
    setHeaders()
  );
};

export const pollPendingProjects = () => {
  return axios.get(
    `${API_V2_END_POINT}/projects/poll_pending_projects`,
    setHeaders()
  );
};

export const uploadImageAI = (formData) => {
  uploadAbortController = new AbortController();
  return axios.post(
    `${API_END_POINT}/open_ai/generate_image_variation`,
    formData,
    {
      headers: setHeaders(IMAGE_UPLOAD_API),
      signal: uploadAbortController.signal,
      onUploadProgress: (progressEvent) => {
        handleUploadProgress(progressEvent);
      },
    }
  );
};
