import { fabric } from "fabric";
import { store } from "../Store/Store";
import {
  setSignatureImage,
  setSignatureToken,
  setCanShowSignature,
  setSignatureType,
  setSignatureAR,
} from "../Store/Project/signatureSlice";
import { setImageProperty } from "./Helper";
import { deleteProjectSignature, updateProjectSignature } from "./ApiHelper";
import { setSignatureCoordinates } from "./PersonalizationHelper";
import { PROFILE, UPLOAD } from "./Constants";

export const setUploadedSignatureIntoCanvas = (canvas, imageURL) => {
  fabric.Image.fromURL(
    imageURL,
    (image) => {
      setImageProperty(image);
      let scale = 1;
      const margin = 20;
      const scaledWidth = canvas.getWidth() / canvas.getZoom() - margin;
      const scaledHeight = canvas.getHeight() / canvas.getZoom() - margin;

      const imageAR = image.width / image.height;
      const canvasAR = scaledWidth / scaledHeight;

      if (imageAR > canvasAR) {
        scale = scaledWidth / image.width;
      } else {
        scale = scaledHeight / image.height;
      }
      image.scale(scale).setCoords();
      canvas.add(image);
      canvas.viewportCenterObject(image);
    },
    { crossOrigin: "Anonymous" }
  );
};

export const handleUploadSignature = (type, data) => {
  return new Promise((resolve, reject) => {
    const { signatureToken } = store.getState().signature;

    const formData = new FormData();
    formData.append("signatureImage", data);
    formData.append("updateInProfile", true);
    formData.append("signatureType", type);

    updateProjectSignature(formData, signatureToken)
      .then((res) => {
        store.dispatch(setSignatureImage(res.data.data.signature_image));
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = res.data.data.signature_image;
        img.onload = () => {
          const signatureAR = img.width / img.height;
          store.dispatch(setSignatureAR(signatureAR));
        };
        store.dispatch(setSignatureToken(res.data.data.token));
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const showSignatureinPreview = (croppedCanvas) => {
  setSignatureCoordinates(croppedCanvas);
  store.dispatch(setCanShowSignature(true));
};

export const showSavedSignatureinPreview = (croppedCanvas) => {
  const { coordsAltered } = store.getState().signature;

  if (!coordsAltered) {
    setSignatureCoordinates(croppedCanvas);
  }
  store.dispatch(setCanShowSignature(true));
};

export const handleDeleteSignature = () => {
  return new Promise((resolve) => {
    const { signatureToken } = store.getState().signature;
    if (signatureToken) {
      deleteProjectSignature(signatureToken).then(() => {
        store.dispatch(setCanShowSignature(false));
        store.dispatch(setSignatureImage(null));
        store.dispatch(setSignatureToken(null));
        store.dispatch(setSignatureType(null));
        resolve();
      });
    } else {
      resolve();
    }
  });
};

export const hasProfileSignature = () => {
  const { currentUserDetails } = store.getState().user;
  return (
    currentUserDetails && !currentUserDetails.signature.includes("missing")
  );
};

export const setProfileSignature = () => {
  return new Promise((resolve) => {
    if (hasProfileSignature()) {
      handleUploadSignature(PROFILE, null).then(() => {
        resolve();
      });
    }
  });
};

export const setDefaultSignatureOption = () => {
  let type = UPLOAD;
  if (hasProfileSignature()) {
    type = PROFILE;
  }
  store.dispatch(setSignatureType(type));
};
