import React, { useEffect, useState } from "react";
import "./ProfileUpdatePage.scss";
import backArrowSvg from "../../Utils/Icons/arrow_back.svg";
import SignatureUpload from "../SideViewBar/SignatureUpload/SignatureUpload";
import { useNavigate } from "react-router-dom";
import ProfileButton from "../ProfileButton/ProfileButton";
import { useDispatch, useSelector } from "react-redux";
import { deleteAccount, updateUserProfile } from "../../Utils/ApiHelper";
import { setCurrentUserDetails } from "../../Store/Project/UserSlice";
import LogoUpload from "../SideViewBar/LogoUpload/LogoUpload";
import { Link } from "@mui/material";
import StatusModal from "../../Components/StatusModal/StatusModal";
import { setCanShowBackAlertModal } from "../../Store/Project/ProjectSlice";
import status_right from "../../Utils/Icons/status_right.svg";
import error_icon from "../../Utils/Icons/error_icon.svg";

import {
  sendGAFailedToUpdateProfile,
  sendGALoadedProfileUpdatePage,
  sendGAProfileSignature,
  sendGAUpdatedProfileSuccessfully,
  sendGAUploadedLogoType,
} from "../../Utils/GAHelper";
import { getCurrentUser } from "../../Utils/ApiHelper";
import { showOrHideApiLoadingModal } from "../../Store/Project/ModalSlice";
import ProjectLoader from "../../Components/Loaders/ProjectLoader/ProjectLoader";
import { setCanShowStatusModal } from "../../Store/Project/ProjectSlice";
import { string } from "../../Localization";
import {
  BASIC_INFORMATION,
  GALLERY_TYPE_DEFAULT,
  GALLERY_TYPE_PRIVATE,
  GALLERY_TYPE_PUBLIC,
  PROFILE_LOGOTYPE,
  PROFILE_SIGNATURE,
  PUBLIC_GALLERY,
  SUPPORTED_COUNTRY,
} from "../../Utils/Constants";
import Select from "react-select";
import csc from "country-state-city";
import LogoHP from "../../Components/LogoHP/LogoHP";
import Unsubscribe from "../../Components/Modal/Unsubscribe/Unsubscribe";
import InstagramIcon from "../../Utils/Icons/instagram.svg";
import FacebookIcon from "../../Utils/Icons/facebook.svg";
import TwitterIcon from "../../Utils/Icons/twitter.svg";
import ProfilePhoto from "../SideViewBar/ProfilePhoto/ProfilePhoto";
import CustomRadiobutton from "../SideViewBar/CustomRadiobutton/CustomRadiobutton";
import UserName from "./UserName/UserName";
import DialogBox from "../DialogBox/DialogBox";
import { redirectToHpSignOutPage } from "../../Utils/Helper";
// import InviteFriend from "../InviteFriend/InviteFriend";

const ProfileUpdatePage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { CanShowBackAlertModal } = useSelector((state) => state.project);
  const { canShowStatusModal } = useSelector((state) => state.project);
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [biography, setBiography] = useState("");
  const [Copyright, setCopyright] = useState("");
  const [VATnumber, setVATnumber] = useState("");
  const [userName, setUserName] = useState("");
  const [newUserName, setNewUserName] = useState("");
  const [userNameValid, setUserNameValid] = useState(true);
  const [logo, setLogo] = useState("");
  const [profilePhoto, setProfilePhoto] = useState("");
  const [activeLink, setActiveLink] = useState(BASIC_INFORMATION);
  const [typeOfSignature, setTypeOfSignature] = useState("");
  const [signature, setSignature] = useState(null);
  const [isLogoUploaded, setIsLogoUploaded] = useState(false);
  const [isProfilePhotoUploaded, setIsProfilePhotoUploaded] = useState(false);
  const [isLogoDeleted, setIsLogoDeleted] = useState(false);
  const [isProfilePhotoDeleted, setIsProfilePhotoDeleted] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({
    label: string.COUNTRY_NAME,
    value: "230",
    name: string.COUNTRY_NAME,
    phonecode: "44",
    countryCode: "GB",
  });
  const [alertMessage, setAlertMessage] = useState({ country: null });
  const [isLoading, setIsLoading] = useState(true);
  const [isUnsubscribeOpen, setIsUnsubscribeOpen] = useState(false);
  const [instagramId, setInstagramID] = useState("");
  const [facebookId, setFacebookID] = useState("");
  const [twitterID, setTwitterId] = useState("");
  const [isUnsubscribeAndDeleteOpen, setIsUnsubscribeAndDeleteOpen] =
    useState(false);
  const [isErrorModalOpen, setIsErrorModal] = useState(false);
  const [publicGallerySelected, setPublicGallerySelected] = useState();

  const showPublicGallery = user.currentUserDetails?.real_photographer
    ? user.currentUserDetails?.real_photographer
    : false;
  const showUnsubscribe = !user.currentUserDetails?.unsubscribed;

  const firstName = user.currentUserDetails?.first_name || "";
  const lastName = user.currentUserDetails?.last_name || "";

  const accountHolderName =
    firstName !== "" || lastName !== "" ? `${firstName} ${lastName}` : "";
  const isSaveDisabled =
    name.trim() === "" || selectedCountry.countryCode !== SUPPORTED_COUNTRY;

  const countries = csc.getAllCountries();

  const formattedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country,
  }));

  const validateCountry = (value) => {
    setSelectedCountry({
      label: value.name,
      value: value.id,
      name: value.name,
      phonecode: value.phonecode,
      countryCode: value.sortname,
    });

    if (value.sortname === SUPPORTED_COUNTRY) {
      setAlertMessage({ country: "" });
    } else {
      setAlertMessage({ country: string.SELECTED_COUNTRY_IS_NOT_SUPPORTED });
    }
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "transparent",
      borderRadius: "6px",
      borderColor: "#737373",
      color: "black",
      "&:hover": {
        borderColor: "#737373",
        boxShadow: "none",
      },

      boxShadow: "none",
      "&:focus": {
        borderColor: "#737373",
        boxShadow: "none",
      },
      width: "100%",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: "6px",
      border: "1px solid #737373",
      boxShadow: "none",
      scrollbarWidth: "none",
      maxHeight: "300px",
      overflow: "scroll",
      marginTop: "-1px",

      "&::-webkit-scrollbar": {
        display: "none",
      },
    }),
    menuList: (base) => ({
      ...base,
      color: "black",
      marginTop: "0px",
      padding: "0px",
      margin: "0px",
      border: "none",
      boxShadow: "none",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        width: "2px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#1a1e22",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#353535",
      },
    }),
    placeholder: (base) => ({
      ...base,
      color: "#F8F8F8",
    }),
    singleValue: (base) => ({
      ...base,
      color: "#F8F8F8",
    }),
    input: (base) => ({
      ...base,
      color: "#F8F8F8",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    option: (base, state) => ({
      ...base,
      border: "none",
      boxShadow: "none",
      backgroundColor: state.isFocused ? "#353535" : "#1a1e22",
      color: state.isFocused ? "white" : "#DBDBDB",
      "&:hover": {
        backgroundColor: "#353535",
        color: "white",
      },
      "&:active": {
        backgroundColor: "#353535",
        color: "white",
      },
    }),
  };

  useEffect(() => {
    if (!user.currentUserDetails) {
      dispatch(showOrHideApiLoadingModal(true));
      getCurrentUser()
        .then((res) => {
          if (!res.data?.data?.accepted_legal_terms) {
            navigate("/");
            dispatch(showOrHideApiLoadingModal(false));
            return;
          } else {
            dispatch(setCurrentUserDetails(res.data.data));
            dispatch(showOrHideApiLoadingModal(false));
            sendGALoadedProfileUpdatePage();
          }
        })
        .catch((error) => {
          navigate("/");
          dispatch(showOrHideApiLoadingModal(false));
          console.error("Error:", error);
        });
    } else {
      sendGALoadedProfileUpdatePage();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const deleteProfile = () => {
    dispatch(showOrHideApiLoadingModal(true));
    deleteAccount()
      .then((res) => {
        dispatch(setCurrentUserDetails(res?.data?.data));
        dispatch(setCanShowStatusModal(false));
        redirectToHpSignOutPage();
        dispatch(showOrHideApiLoadingModal(false));
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    setBiography(user?.currentUserDetails?.biography);
    setName(user?.currentUserDetails?.artist_name || "");
    setUserName(user?.currentUserDetails?.user_name || "");
    setNewUserName(user?.currentUserDetails?.user_name || "");
    setVATnumber(user?.currentUserDetails?.vat_number);
    setCopyright(user?.currentUserDetails?.copyright_text);

    if (user?.currentUserDetails?.instagram_url) {
      const instagramUrl = user.currentUserDetails?.instagram_url;
      const instagram = instagramUrl.includes("https://www.instagram.com/")
        ? instagramUrl.split("https://www.instagram.com/")[1]
        : instagramUrl;
      setInstagramID(instagram);
    }

    if (user?.currentUserDetails?.facebook_url) {
      const facebookUrl = user.currentUserDetails?.facebook_url;
      const facebook = facebookUrl.includes("https://www.facebook.com/")
        ? facebookUrl.split("https://www.facebook.com/")[1]
        : facebookUrl;
      setFacebookID(facebook);
    }

    if (user?.currentUserDetails?.twitter_url) {
      const twitterUrl = user.currentUserDetails?.twitter_url;
      const twitter = twitterUrl.includes("https://twitter.com/")
        ? twitterUrl.split("https://twitter.com/")[1]
        : twitterUrl;
      setTwitterId(twitter);
    }

    setPublicGallerySelected(user?.currentUserDetails?.gallery_type);
  }, [user.currentUserDetails]);

  const handleLogoChange = (logo) => {
    setLogo(logo);
    sendGAUploadedLogoType();
  };

  const handleProfilePhotoChange = (photo) => {
    setProfilePhoto(photo);
    // sendGAUploadedLogoType();
  };

  const handleSignatureSelection = (signType, signImage) => {
    setTypeOfSignature(signType);
    setSignature(signImage);
    sendGAProfileSignature(signType);
  };

  const handleChange = (e) => {
    e.preventDefault();
    dispatch(showOrHideApiLoadingModal(true));
    const formData = new FormData();
    formData.append("artist_name", name);
    formData.append("userName", newUserName);
    formData.append("biography", biography);
    formData.append("copyrightText", Copyright);

    if (instagramId != "") {
      formData.append(
        "instagramUrl",
        "https://www.instagram.com/" + instagramId
      );
    } else {
      formData.append("instagramUrl", instagramId);
    }

    if (facebookId != "") {
      formData.append("facebookUrl", "https://www.facebook.com/" + facebookId);
    } else {
      formData.append("facebookUrl", facebookId);
    }

    if (twitterID != "") {
      formData.append("twitterUrl", "https://twitter.com/" + twitterID);
    } else {
      formData.append("twitterUrl", twitterID);
    }

    formData.append("galleryType", publicGallerySelected);
    if (signature) {
      formData.append("signature", signature);
    }
    formData.append("signature_type", typeOfSignature);
    if (isLogoDeleted || isLogoUploaded) {
      formData.append("logo", logo);
    }
    if (isProfilePhotoDeleted || isProfilePhotoUploaded) {
      formData.append("profile_photo", profilePhoto);
    }

    formData.append("vatNumber", VATnumber);
    if (selectedCountry.countryCode === SUPPORTED_COUNTRY) {
      let selectedCountryCode = selectedCountry.countryCode;
      formData.append("countryCode", selectedCountryCode);
    }
    updateUserProfile(formData)
      .then((response) => {
        dispatch(setCurrentUserDetails(response.data.data));
        dispatch(setCanShowBackAlertModal(true));
        sendGAUpdatedProfileSuccessfully();
        dispatch(showOrHideApiLoadingModal(false));
      })
      .catch((error) => {
        console.log("error:", error);
        setIsErrorModal(true);
        sendGAFailedToUpdateProfile();
        dispatch(showOrHideApiLoadingModal(false));
      });
  };

  const closeHandler = () => {
    dispatch(setCanShowBackAlertModal(false));
    navigate("/");
  };

  const setCopyRight = () => {
    return accountHolderName + " © " + string.COPY_RIGHT;
  };

  const handleValidLogo = (uploaded) => {
    setIsLogoUploaded(uploaded);
  };
  const handleValidProfilePhoto = (uploaded) => {
    setIsProfilePhotoUploaded(uploaded);
  };

  const handleDeleteLogo = (deleted) => {
    setIsLogoDeleted(deleted);
  };
  const handleDeleteProfilePhoto = (deleted) => {
    setIsProfilePhotoDeleted(deleted);
  };

  const cancelHandler = () => {
    dispatch(setCanShowStatusModal(false));
  };
  const DeleteHandler = () => {
    deleteProfile();
  };

  const closeErrorModal = () => {
    setIsErrorModal(false);
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      setIsLoading(false);
    }, 500);
    return () => clearTimeout(delay);
  }, []);

  if (isLoading) {
    return <ProjectLoader />;
  }

  return (
    <>
      {!isLoading && (
        <div className="profile-update-page-container">
          <div className="topbar-profile-upload">
            <div className="logo-profile">
              <LogoHP />
            </div>
            <div className="profile-update-wrap">
              <ProfileButton />
            </div>
          </div>
          <div className="profile-page-update">
            <div className="back-navigation">
              <div
                className="back-nav-image"
                onClick={() => {
                  navigate("/");
                }}
              >
                <img src={backArrowSvg} alt="back"></img>
                <div className="back-navigation-text">{string.BACK}</div>
              </div>
            </div>
            <div className="profile-container-wrap">
              <div className="profile-container">
                <div className="profile-update-inner-container">
                  <div className="profile-update-text-container">
                    <div className="my-account-text">{string.MY_ACCOUNT}</div>
                    <div className="edit-information-text">
                      {string.HERE_YOU_CAN_EDIT_THE_INFORMATION}
                    </div>
                  </div>
                  <div className="basic-profile-container">
                    <div
                      className="basic-information-container"
                      id="BasicInformation"
                    >
                      <div className="basic-info-text-items">
                        <div className="basic-information">
                          {string.BASIC_INFORMATION}
                        </div>
                        <div className="basic-info-text">
                          {string.BASIC_INFORMATION_DESCRIPTION}
                        </div>
                      </div>
                      <div className="text-field-container">
                        <div className="info-items">
                          <div className="text-box">
                            <label htmlFor="">
                              {string.FIRST_AND_LAST_NAME}
                            </label>
                            <div className="input-title empty">
                              {accountHolderName}
                            </div>
                          </div>
                          <div className="text-box">
                            <label htmlFor="">{string.ARTIST_NAME}</label>
                            <input
                              type="text"
                              className="input-title"
                              maxLength={30}
                              placeholder={
                                name.trim() === "" ? string.TYPE_HERE : name
                              }
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              required
                            />
                            {name.trim() !== "" && (
                              <div className="max-characters">
                                {string.MAXIMUM_CHARACTERS}: 30
                              </div>
                            )}
                            {name.trim() === "" && (
                              <div className="error-div required-message ">
                                {string.ARTIST_NAME_IS_REQUIRED}
                              </div>
                            )}
                          </div>
                          <UserName
                            userNameValid={userNameValid}
                            setUserNameValid={setUserNameValid}
                            newUserName={newUserName}
                            setNewUserName={setNewUserName}
                          />

                          <div className="text-box">
                            <label htmlFor="">{string.BIO}</label>
                            <textarea
                              className="input-description input-title"
                              maxLength={500}
                              style={{
                                minHeight: "32px",
                                maxHeight: "220px",
                              }}
                              placeholder={string.TYPE_HERE}
                              value={biography}
                              onChange={(e) => setBiography(e.target.value)}
                            />
                            <div className="max-characters">
                              {string.MAXIMUM_CHARACTERS}: 500
                            </div>
                          </div>
                          <div className="text-box">
                            <label htmlFor="">{string.COPY_RIGHT}</label>
                            <input
                              type="text"
                              className="input-title"
                              placeholder={setCopyRight()}
                              value={Copyright}
                              onChange={(e) => setCopyright(e.target.value)}
                            />
                            {/* {!(Copyright && Copyright.includes("@copyright")) && (
                            <p className="required-message">
                              @copyright is required
                            </p>
                          )} */}
                          </div>
                          <div className="text-box">
                            <label htmlFor="">{string.VAT_NUMBER}</label>
                            <input
                              type="text"
                              className="input-title"
                              placeholder={string.TYPE_HERE}
                              value={VATnumber}
                              onChange={(e) => setVATnumber(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="profile-underline"></div>
                        <div className="social-media-container">
                          <div className="social-media-heading">
                            {string.SOCIAL_MEDIA}
                          </div>
                          <div className="social-media-list-container">
                            <div className="social-media-list">
                              <label htmlFor="">{string.INSTAGRAM}</label>
                              <div className="input-icon-container">
                                <input
                                  type="text"
                                  className="social-media-input-title"
                                  placeholder={string.TYPE_HERE}
                                  value={instagramId}
                                  onChange={(e) =>
                                    setInstagramID(e.target.value)
                                  }
                                />

                                {instagramId === "" && (
                                  <img
                                    src={InstagramIcon}
                                    alt="instagram"
                                    className="insta-icon"
                                  />
                                )}
                              </div>
                              <div className="social_media_url">
                                https://www.instagram.com/{instagramId}
                              </div>
                            </div>
                            <div className="social-media-list">
                              <label htmlFor="">{string.FACEBOOK}</label>
                              <div className="input-icon-container">
                                <input
                                  type="text"
                                  className="social-media-input-title"
                                  placeholder={string.TYPE_HERE}
                                  value={facebookId}
                                  onChange={(e) =>
                                    setFacebookID(e.target.value)
                                  }
                                />
                                {facebookId === "" && (
                                  <img
                                    src={FacebookIcon}
                                    alt="facebook"
                                    className="facebook-icon"
                                  />
                                )}
                              </div>
                              <div className="social_media_url">
                                https://www.facebook.com/{facebookId}
                              </div>
                            </div>
                            <div className="social-media-list">
                              <label htmlFor="">{string.TWITTER}</label>
                              <div className="input-icon-container">
                                <input
                                  type="text"
                                  className="social-media-input-title"
                                  placeholder={string.TYPE_HERE}
                                  value={twitterID}
                                  onChange={(e) => setTwitterId(e.target.value)}
                                />
                                {twitterID === "" && (
                                  <img
                                    src={TwitterIcon}
                                    alt="twitter"
                                    className="twitter-icon"
                                  />
                                )}
                              </div>
                              <div className="social_media_url">
                                https://twitter.com/{twitterID}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="profile-underline"></div>
                        <div className="text-box">
                          <div className="country-heading">
                            {string.COUNTRY}
                          </div>
                          <div className="select-country-label-text">
                            {string.SELECT__YOUR_COUNTRY_REGION}
                          </div>
                          <div className="country-dropdown">
                            <Select
                              id="country"
                              name="country"
                              placeholder={string.SELECT__YOUR_COUNTRY_REGION}
                              options={formattedCountries}
                              value={selectedCountry}
                              styles={customStyles}
                              onChange={(value) => validateCountry(value)}
                            />

                            <div className="error-div">
                              {alertMessage.country}
                            </div>
                          </div>
                        </div>
                        <div className="profile-underline"></div>
                      </div>
                    </div>
                    {/* <InviteFriend /> */}
                    <div className="basic-information-container" id="Signature">
                      <div className="basic-info-text-items">
                        <div className="basic-information">
                          {string.SIGNATURE}
                        </div>
                        <div className="basic-info-text signature-margin">
                          {string.SIGNATURE_TEXT}
                        </div>
                      </div>

                      <div className="text-field-container">
                        <div className="signature-subOptions">
                          <SignatureUpload
                            uploadText={string.UPLOAD_FROM_MY_DEVICE}
                            onChangeProfileSignature={handleSignatureSelection}
                            isProfilePage={true}
                          />
                        </div>
                        <div className="profile-underline"></div>
                      </div>
                    </div>

                    <div className="basic-information-container" id="Logotype">
                      <div className="basic-info-text-items">
                        <div className="basic-information">
                          {string.LOGOTYPE}
                        </div>
                        <div className="basic-info-text signature-margin">
                          {string.LOGOTYPE_DESCRIPTION}
                        </div>
                      </div>

                      <div className="text-field-container">
                        <div className="signature-subOptions">
                          <LogoUpload
                            logoText=""
                            radText=""
                            uploadLogoText={string.LOGOTYPE_TEXT}
                            onLogoChange={handleLogoChange}
                            isValid={handleValidLogo}
                            isDistroyed={handleDeleteLogo}
                          />
                        </div>
                        <div className="profile-underline"></div>
                      </div>
                    </div>
                    <div className="basic-information-container">
                      <div className="basic-info-text-items">
                        <div className="basic-information">Profile photo</div>
                        <div className="basic-info-text signature-margin">
                          {string.UPLOAD_PROFILE_TEXT1} <br />
                          {string.UPLOAD_PROFILE_TEXT2}
                        </div>
                      </div>

                      <div className="text-field-container">
                        <div className="signature-subOptions">
                          <ProfilePhoto
                            ProfilePhotoText=""
                            radText=""
                            uploadProfilePhotoText={string.PROFILE_TEXT}
                            onProfilePhotoChange={handleProfilePhotoChange}
                            isValid={handleValidProfilePhoto}
                            isDistroyed={handleDeleteProfilePhoto}
                          />
                        </div>
                        <div className="profile-underline"></div>
                      </div>
                    </div>
                    {showUnsubscribe && (
                      <div className="basic-information-container">
                        <div className="basic-info-text-items">
                          <div className="basic-information">
                            {string.DELETE_ACCOUNT}
                          </div>
                          <div className="basic-info-text signature-margin">
                            {string.DELETE_ACCOUNT_TEXT1} <br />
                            {string.DELETE_ACCOUNT_TEXT2}
                          </div>
                          <div className="upload-btn-wrapper">
                            <button
                              className="upload-signature"
                              style={{
                                paddingLeft: "25px",
                                paddingRight: "25px",
                                width: "unset",
                              }}
                              onClick={() => {
                                dispatch(setCanShowStatusModal(true));
                              }}
                            >
                              {string.DELETE_MY_ACC}
                            </button>
                          </div>
                        </div>

                        <div className="text-field-container">
                          <div className="profile-underline"></div>
                        </div>
                      </div>
                    )}
                    {showPublicGallery && (
                      <div
                        className="basic-information-container mb-4 max-width-600"
                        id="publicGallery"
                      >
                        <div className="basic-info-text-items">
                          <label className="basic-information mb-2">
                            {string.PUBLIC_GALLERY}
                          </label>
                          <div className="d-flex align-items-start">
                            <CustomRadiobutton
                              id="public-gallery1"
                              title=""
                              handleRadio={() => {
                                setPublicGallerySelected(GALLERY_TYPE_PUBLIC);
                              }}
                              name="public-gallery"
                              isChecked={
                                publicGallerySelected === GALLERY_TYPE_PUBLIC
                              }
                            />

                            <div style={{ marginTop: "12px" }}>
                              <span>{string.PUBLIC_GALLERY_FLAG_TEXT}</span>
                              <span
                                onClick={() => {
                                  window.open(`/gallery`);
                                }}
                                className="text-underline"
                              >
                                {string.VIEW_PUBLIC_GALLERY}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-start">
                            <CustomRadiobutton
                              id="public-gallery2"
                              title=""
                              handleRadio={() => {
                                setPublicGallerySelected(GALLERY_TYPE_PRIVATE);
                              }}
                              name="public-gallery"
                              isChecked={
                                publicGallerySelected === GALLERY_TYPE_PRIVATE
                              }
                            />

                            <div style={{ marginTop: "12px" }}>
                              <span>{string.MY_GALLERY_TEXT}</span>
                              <span
                                onClick={() => {
                                  window.open(`/mygallery/${userName}`);
                                }}
                                className="text-underline"
                              >
                                {string.CLICK_MY_GALLERY}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-start">
                            <CustomRadiobutton
                              id="public-gallery3"
                              title=""
                              handleRadio={() => {
                                setPublicGallerySelected(GALLERY_TYPE_DEFAULT);
                              }}
                              name="public-gallery"
                              isChecked={
                                publicGallerySelected === GALLERY_TYPE_DEFAULT
                              }
                            />

                            <div style={{ marginTop: "12px" }}>
                              <span>
                                {string.DONT_APPEAR_IN_PUBLIC_GALLERY}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <button
                      className={
                        isSaveDisabled || !userNameValid
                          ? "upload-button btn-disabled"
                          : "upload-button btn-enabled"
                      }
                      onClick={(e) => handleChange(e)}
                      disabled={isSaveDisabled}
                    >
                      {string.SAVE_CHANGES}
                    </button>
                  </div>
                </div>
              </div>
              <div className="profile-links">
                <Link
                  href="#BasicInformation"
                  className={
                    activeLink === BASIC_INFORMATION
                      ? "activeStyle"
                      : "nonactiveStyle"
                  }
                  onClick={() => setActiveLink(BASIC_INFORMATION)}
                >
                  {string.BASIC_INFORMATION}
                </Link>
                <Link
                  href="#Signature"
                  className={
                    activeLink === PROFILE_SIGNATURE
                      ? "activeStyle"
                      : "nonactiveStyle"
                  }
                  onClick={() => setActiveLink(PROFILE_SIGNATURE)}
                >
                  {string.SIGNATURE}
                </Link>
                <Link
                  href="#Logotype"
                  className={
                    activeLink === PROFILE_LOGOTYPE
                      ? "activeStyle"
                      : "nonactiveStyle"
                  }
                  onClick={() => setActiveLink(PROFILE_LOGOTYPE)}
                >
                  {string.LOGOTYPE}
                </Link>
                {showPublicGallery && (
                  <Link
                    href="#publicGallery"
                    className={
                      activeLink === PUBLIC_GALLERY
                        ? "activeStyle"
                        : "nonactiveStyle"
                    }
                    onClick={() => setActiveLink(PUBLIC_GALLERY)}
                  >
                    {string.PUBLIC_GALLERY}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <StatusModal
        canShow={CanShowBackAlertModal}
        stautsbackground={{ background: "#176267" }}
        statusimge={status_right}
        statusHeading={string.YOUR_PROFILE_HAS_BEEN_UPDATED}
        closebackground={{ background: "#176267" }}
        closeData={{
          closeBtn: string.CLOSE,
          closeHandler: closeHandler,
        }}
      />

      <ProjectLoader />

      <StatusModal
        canShow={isErrorModalOpen}
        stautsbackground={{ background: "#212121" }}
        statusimge={error_icon}
        statusHeading={"Profile update failed"}
        statusDescription={""}
        closebackground={{ background: "#212121" }}
        closeData={{
          closeBtn: string.CLOSE,
          closeHandler: closeErrorModal,
        }}
      />
      <DialogBox
        canShow={canShowStatusModal}
        heading={string.DELETE_ACCOUNT}
        text1={string.DELETE_IMAGE_MODAL_TEXT_1}
        text2={string.DELETE_IMAGE_MODAL_TEXT_2}
        submitData={{
          submitText: string.DELETE,
          submitHandler: DeleteHandler,
          cancelBtn: string.CANCEL,
          cancelHandler: cancelHandler,
        }}
      />
    </>
  );
};

export default ProfileUpdatePage;
