import { createSlice } from "@reduxjs/toolkit";
import { AnimationType } from "../../Utils/Constants";

const initialState = {
  canShowUploadFileModal: false,
  canShowUploadAIFileModal: false,
  canShowUploadAIOptionModal: false,
  canShowUploadAISelectModal:false,
  shippingModal: {
    canShowShippingDetailModal: false,
    shippingDetails: {
      firstName: "",
      lastName: "",
      email: "",
      addressLine1: "",
      addressLine2: "",
      city: {
        id: null,
        label: null,
        name: null,
      },
      region: {
        id: null,
        label: null,
        name: null,
      },
      country: {
        id: null,
        label: null,
        name: null,
        countryCode: null,
        phoneCode: null,
      },
      postalCode: "",
      phoneNumber: "",
      company: "",
      sampleType: 1,
      shippingType: 2,
      location: {
        lat: -3.745,
        lng: -38.523,
      },
      personalMessage: "",
    },
  },
  apiLoading: {
    text: null,
    canShowApiLoadingModal: false,
    animationType: AnimationType.CIRCULAR,
  },
  showUploadSignModal: false,
  canShowProfileUpdateModal: false,
  showEditAddressModal: false,
  showEditCustomerModal: false,
  imageAlreadyPresentError: false,
  salesOrderModal: false,
  checkBoxModal: false,
  showSuperResolutionLoader: false,
  notSupportedMediaModal: {
    show: false,
    mediaName: "",
  },
};

export const modalSlice = createSlice({
  name: "modal",
  initialState: initialState,
  reducers: {
    showOrHideUploadFileModal(state, action) {
      state.canShowUploadFileModal = action.payload;
    },
    showOrHideUploadAIFileModal(state, action) {
      state.canShowUploadAIFileModal = action.payload;
    },
    showOrHideUploadAIOptionModal(state, action) {
      state.canShowUploadAIOptionModal = action.payload;
    },
    showOrHideUploadAISelectModal(state, action) {
      state.canShowUploadAISelectModal = action.payload;
    },
    toggleEditAddressModal(state, action) {
      state.showEditAddressModal = action.payload;
    },
    toggleEditCustomerModal(state, action) {
      state.showEditCustomerModal = action.payload;
    },
    showOrHideShippingDetailModal(state, action) {
      state.shippingModal.canShowShippingDetailModal = action.payload;
    },
    setCustomerFirstName(state, action) {
      state.shippingModal.shippingDetails.firstName = action.payload;
    },
    setCustomerLastName(state, action) {
      state.shippingModal.shippingDetails.lastName = action.payload;
    },
    setCustomerEmail(state, action) {
      state.shippingModal.shippingDetails.email = action.payload;
    },
    setAddressLine1(state, action) {
      state.shippingModal.shippingDetails.addressLine1 = action.payload;
    },
    setAddressLine2(state, action) {
      state.shippingModal.shippingDetails.addressLine2 = action.payload;
    },
    setCity(state, action) {
      state.shippingModal.shippingDetails.city = action.payload;
    },
    setRegion(state, action) {
      state.shippingModal.shippingDetails.region = action.payload;
    },
    setCountry(state, action) {
      state.shippingModal.shippingDetails.country = action.payload;
    },
    setPostalCode(state, action) {
      state.shippingModal.shippingDetails.postalCode = action.payload;
    },
    setPhoneNumber(state, action) {
      state.shippingModal.shippingDetails.phoneNumber = action.payload;
    },
    setCompany(state, action) {
      state.shippingModal.shippingDetails.company = action.payload;
    },
    setSampleType(state, action) {
      state.shippingModal.shippingDetails.sampleType = action.payload;
    },
    setShippingType(state, action) {
      state.shippingModal.shippingDetails.shippingType = action.payload;
    },
    setLocation(state, action) {
      state.shippingModal.shippingDetails.location = action.payload;
    },
    setPersonalMessage(state, action) {
      state.shippingModal.shippingDetails.personalMessage = action.payload;
    },

    setShowUploadSignModal(state, action) {
      state.showUploadSignModal = action.payload;
    },
    showOrHideProfileUpdateModal(state, action) {
      state.canShowProfileUpdateModal = action.payload;
    },
    setApiLoadingText(state, action) {
      state.apiLoading.text = action.payload;
    },
    showOrHideApiLoadingModal(state, action) {
      state.apiLoading.canShowApiLoadingModal = action.payload;
    },
    setApiLoadingAnimationType(state, action) {
      state.apiLoading.animationType = action.payload;
    },
    resetApiLoadingAnimation(state) {
      state.apiLoading.canShowApiLoadingModal = false;
      state.apiLoading.animationType = AnimationType.CIRCULAR;
      state.apiLoading.text = "";
    },
    setImageAlreadyPresentError(state, action) {
      state.imageAlreadyPresentError = action.payload;
    },
    setSalesOrderModal(state, action) {
      state.salesOrderModal = action.payload;
    },
    setCheckBoxModal(state, action) {
      state.checkBoxModal = action.payload;
    },
    setShowSuperResolutionLoader(state, action) {
      state.showSuperResolutionLoader = action.payload;
    },

    setNotSupportedMediaModal(state, action) {
      state.notSupportedMediaModal = action.payload;
    },
  },
});

export const {
  showOrHideUploadFileModal,
  showOrHideUploadAIFileModal,
  showOrHideUploadAIOptionModal,
  showOrHideUploadAISelectModal,
  showOrHideShippingDetailModal,
  toggleEditAddressModal,
  toggleEditCustomerModal,
  setCustomerFirstName,
  setCustomerLastName,
  setCustomerEmail,
  setAddressLine1,
  setAddressLine2,
  setCity,
  setRegion,
  setCountry,
  setPostalCode,
  setPhoneNumber,
  setCompany,
  setSampleType,
  setShippingType,
  setLocation,
  setPersonalMessage,
  setShowUploadSignModal,
  showOrHideProfileUpdateModal,
  setApiLoadingText,
  showOrHideApiLoadingModal,
  setApiLoadingAnimationType,
  resetApiLoadingAnimation,
  setImageAlreadyPresentError,
  setSalesOrderModal,
  setCheckBoxModal,
  setNotSupportedMediaModal,
  setShowSuperResolutionLoader,
} = modalSlice.actions;
export default modalSlice.reducer;
