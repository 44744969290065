import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./UsePagination.jsx";
import "./pagination.scss";

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  // console.log(currentPage, totalCount, siblingCount, pageSize);

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  const onNext = () => {
    if (currentPage < lastPage) {
      onPageChange(currentPage + 1);
    }
  };

  const onPrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  let lastPage = paginationRange[paginationRange?.length - 1];

  return (
    <ul
      className={classnames("pagination-container", { [className]: className })}
    >
      <li
        className="pagination-item"
        style={{
          cursor: currentPage === 1 ? "not-allowed" : "pointer",
        }}
        onClick={onPrevious}
      >
        <div
          className="fa fa-angle-left"
          style={{ fontSize: "20px", color: "white" }}
        ></div>
      </li>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <li key={index} className="pagination-item dots">
              &#8230;
            </li>
          );
        }

        return (
          <li
            key={index}
            className={classnames("pagination-item", {
              selected: pageNumber === currentPage,
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className="pagination-item"
        style={{
          cursor: currentPage === lastPage ? "not-allowed" : "pointer",
        }}
        onClick={onNext}
      >
        <div
          className="fa fa-angle-right"
          style={{ fontSize: "20px", color: "white" }}
        ></div>
      </li>
    </ul>
  );
};

export default Pagination;
