import { createSlice } from "@reduxjs/toolkit";
import { SIGNATURE_HEIGHT, SIGNATURE_WIDTH } from "../../Utils/Constants";

const initialState = {
  finalImage: null,
  signaturePadCanvasURL: null,
  signatureJson: null,
  signatureImage: null,
  signatureAR: 1,
  signatureToken: null,
  signatureType: null,
  canShowSignature: null,
  coordsAltered: false, //used to track if saved signature is scaled or moved. not used for signature type DRAW and UPLOAD
  drawSignatureCache: null, //used to store draw signature content temporarily so that signatureImage can be set to this value when user closes the draws signature modal without confirming
  signatureDetails: {
    size: { width: SIGNATURE_WIDTH, height: SIGNATURE_HEIGHT },
    position: {
      x: 0,
      y: 0,
    },
  },
};

export const signatureSlice = createSlice({
  name: "signature",
  initialState: initialState,
  reducers: {
    resetSignatureDetails: () => initialState,
    setSignatureImage(state, action) {
      state.signatureImage = action.payload;
    },

    setSignatureAR(state, action) {
      state.signatureAR = action.payload;
    },

    setSignatureType(state, action) {
      state.signatureType = action.payload;
    },

    setSignatureToken(state, action) {
      state.signatureToken = action.payload;
    },

    setFinalImage(state, action) {
      state.finalImage = action.payload;
    },

    setSignaturePadCanvasURL(state, action) {
      state.signaturePadCanvasURL = action.payload;
    },

    setSignatureJson(state, action) {
      state.signatureJson = action.payload;
    },

    // These data are wrt thumbnail image hence while sending to backend need to multiply by image scale.
    setSignatureDetails(state, action) {
      state.signatureDetails = { ...state.signatureDetails, ...action.payload };
    },

    setCanShowSignature(state, action) {
      state.canShowSignature = action.payload;
    },
    setCoordsAltered(state, action) {
      state.coordsAltered = action.payload;
    },
    setDrawSignatureCache(state, action) {
      state.drawSignatureCache = action.payload;
    },
  },
});

export const {
  resetSignatureDetails,
  updateIsselected,
  updateCheclistValue,
  setFinalImage,
  setSignatureImage,
  setSignatureAR,
  setSignatureType,
  setSignatureToken,
  setSignaturePadCanvasURL,
  setSignatureJson,
  setSignatureDetails,
  setCanShowSignature,
  setCoordsAltered,
  setDrawSignatureCache,
} = signatureSlice.actions;
export default signatureSlice.reducer;
