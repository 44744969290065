import "./OrderSummaryFooter.scss";
import HpLogoBlue from "../../../Utils/Icons/hp_blue.svg";

const OrderSummaryFooter = (props) => {
  const { orderData } = props;

  return (
    <div className="d-flex logo-footer py-2">
      {orderData?.user?.logo || orderData?.user?.artist_name ? (
        <div className="customer-logo-block">
          {orderData?.user?.logo ? (
            <img
              className="customer-logo"
              src={orderData?.user?.logo}
              alt="logo"
            />
          ) : (
            <div className="artist-name">
              {orderData?.user?.artist_name?.toUpperCase()}
            </div>
          )}
        </div>
      ) : null}
      {orderData?.user?.logo || orderData?.user?.artist_name ? (
        <div className="seperator" />
      ) : null}
      <div className="hp-logo-block py-2">
        <img className="hp-logo" src={HpLogoBlue} alt="hp logo"></img>
      </div>
    </div>
  );
};

export default OrderSummaryFooter;
