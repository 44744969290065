import { string } from "../../../Localization";
import "./OrderInfo.scss";
import infoIconBlack from "../../../Utils/Icons/info-black.svg";
import { useState } from "react";
import { Modal } from "react-bootstrap";

const OrderInfo = (props) => {
  const { project, isSmallScreen, isReviewStep } = props;

  const [isopen, setIsOpen] = useState(false);

  const handleInfoModalOpen = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setIsOpen(false);
  };

  const editionText = project.upcoming_edition;

  return (
    <div className="order-info-block">
      <div className="image-block">
        <img
          className="photo"
          src={isReviewStep ? project.preview : project.preview_image}
          alt="preview"
        ></img>
      </div>
      <div
        className={
          isSmallScreen ? "general-info-container" : "general-container"
        }
      >
        <div className="order-title">{string.GENERAL_INFO}</div>
        <div className="py-2">
          <div className="d-flex order-desc">
            <div className="order-desc-text orderInfo-paper">
              {string.PHOTO_TITLE}
            </div>
            <div className="order-text-value">{project.title}</div>
          </div>
          <div className="d-flex order-desc">
            <div className="order-desc-text">{string.PRINT_SIZE}:</div>
            <div className="order-text-value">{project.print_size}</div>
          </div>
          <div className="d-flex order-desc">
            <div className="order-desc-text">{string.PHOTO_SIZE}</div>
            <div className="order-text-value">{project.photo_size}</div>
          </div>
          <div className="d-flex order-desc">
            <div className="order-desc-text">{string.PAPER_TYPE}</div>
            <div className="order-text-value">{project.media_type_name}</div>
          </div>
          <div className="d-flex order-desc">
            <div className="order-desc-text">{string.EDITION}:</div>
            <div className="order-text-value">{editionText}</div>
          </div>
        </div>
      </div>
      <div className="blockchain-data-container">
        <div className="order-title">{string.HP_BLOCKCHAIN_LEDGER}</div>
        <div className="blockchain-data">
          <div className="order-text-value">
            {project?.blockchain?.public_ledger}{" "}
            {project?.blockchain?.authenticity_str}
          </div>
        </div>
      </div>
      <div className="secre-payment-container">
        <div className="order-title">{string.SECURE_PAYMENT}</div>
        <div className="secure-data">
          <div className="order-text-value">
            {string.SECURE_PAYMENT_TEXT}{" "}
            <span>
              <img
                src={infoIconBlack}
                alt="info"
                onClick={handleInfoModalOpen}
                className="info-icon"
              />
            </span>
          </div>
        </div>
      </div>
      {isopen && (
        <div className="order-info-modal">
          <Modal show={isopen} className="inner-modal" centered>
            <div className="orderInfo-sub-contents">
              <div className="orderInfo-head-contents">
                <Modal.Header
                  closeButton
                  className="head-content"
                  onClick={handleModalClose}
                ></Modal.Header>
                <div className="heading-deatils">
                  <h2 className="heading-text">{string.SECURE_PAYMENT}</h2>
                  <Modal.Body className="body-data">
                    <p>{string.ORDER_INFO_TEXT_1}</p>
                    <p>{string.ORDER_INFO_TEXT_2}</p>
                    <p>{string.ORDER_INFO_TEXT_3}</p>
                  </Modal.Body>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default OrderInfo;
