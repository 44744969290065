import React, { useContext, useState } from "react";
import "./Border.scss";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { GlobalContext } from "../../../Context/GlobalState";
import { setBorderWidth } from "../../../Store/Project/ProjectSlice";
import { store } from "../../../Store/Store";
import {
  convertInchesToMm,
  convertMmToInches,
  borderOptions,
} from "../../../Utils/Helper";
import {
  BORDER_INCHES,
  BORDER_MM,
  BORDER_UNIT_VALUE_FIRST,
  UNIT_SYSTEM_MM,
} from "../../../Utils/Constants";
import { didChangeBorderSelection } from "../../../Utils/ProjectRender";
import { setHighlightedBorderValues } from "../../../Utils/projectHelper";
import { resetLimitedEditionPlot } from "../../../Utils/PersonalizationHelper";

const Border = (props) => {
  const { borderWidth } = props;
  const { croppedImageCanvas } = useContext(GlobalContext);
  const { selectedUnitSystem } = useSelector((state) => state.project);

  let initialActiveIndex = 0;

  const [value, setValue] = useState(0);

  if (selectedUnitSystem === UNIT_SYSTEM_MM) {
    const borderInMm = Math.trunc(convertInchesToMm(borderWidth));
    const index = BORDER_MM.indexOf(borderInMm);
    initialActiveIndex = index >= 0 ? index : initialActiveIndex;
  } else {
    const index = BORDER_INCHES.indexOf(borderWidth);
    initialActiveIndex = index >= 0 ? index : initialActiveIndex;
  }

  const [activeIndex, setActiveIndex] = useState(initialActiveIndex);

  useEffect(() => {
    setValue(activeIndex);

    if (activeIndex >= 0) {
      const range = document.getElementById("range");
      if (range) {
        range.style.backgroundSize = (activeIndex * 100) / 5 + "% 100%";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeIndex >= 0) {
      selectBoder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex, selectedUnitSystem]);

  const knobIsChanged = (inValue) => {
    setActiveIndex(inValue);
  };

  const borderValueAt = (index) => {
    const unitArray =
      selectedUnitSystem === UNIT_SYSTEM_MM ? BORDER_MM : BORDER_INCHES;
    return unitArray[index];
  };

  const selectBoder = () => {
    let borderInInches = BORDER_INCHES[activeIndex];
    if (selectedUnitSystem === UNIT_SYSTEM_MM) {
      borderInInches = convertMmToInches(BORDER_MM[activeIndex]);
    }
    store.dispatch(setBorderWidth(borderInInches));
    if (croppedImageCanvas) {
      didChangeBorderSelection(croppedImageCanvas);
      setHighlightedBorderValues();
      resetLimitedEditionPlot();
    }
  };

  useEffect(() => {
    const steps = document.querySelectorAll(".step");
    const rangeInput = document.getElementById("range");
    steps.forEach((step, index) => {
      if (index < value) {
        step.style.background = "#176267";
      } else {
        step.style.background = "#737373";
      }
    });
    if (value > 0) {
      rangeInput.style.setProperty("--thumb-size", "16px");
      rangeInput.style.setProperty("--thumb-color", "#176267");
      rangeInput.style.setProperty("--thumb-border-color", "white");
      rangeInput.style.setProperty("margin-top", "-6px");
    } else {
      rangeInput.style.setProperty("--thumb-size", "16px");
      rangeInput.style.setProperty("--thumb-border-color", "#bdbdbd");
      rangeInput.style.setProperty("--thumb-color", "#737373");
      rangeInput.style.setProperty("margin-top", "-6px");
    }
  }, [value]);

  const options = borderOptions().filter((option) => option.active === true);
  const borderOptionsCount = options.length;
  const lastOption = options[borderOptionsCount - 1];

  return (
    <div className="border-slider-container">
      <div>
        <div>
          <div className="slider d-flex">
            <input
              id="range"
              type="range"
              min="0"
              max={borderOptionsCount - 1}
              step="1"
              value={value}
              onChange={(e) => {
                const newValue = parseInt(e.target.value);
                setValue(newValue);
                knobIsChanged(e.target.value);
              }}
            />
            <div className="track">
              {[...Array(borderOptionsCount - 1)].map(() => (
                <div className="step"></div>
              ))}
            </div>
          </div>
          <div className="d-flex border-label">
            {options.map(
              (option, i) =>
                i <= borderOptionsCount - 2 && (
                  <div
                    className={i == 0 ? "border-name-0" : "border-name"}
                    key={option.value}
                    style={{ flexGrow: 1 }}
                  >
                    {value === option.value ||
                    option.value === BORDER_UNIT_VALUE_FIRST
                      ? borderValueAt(option.value)
                      : " "}
                  </div>
                )
            )}
            <div
              className={
                value === lastOption.value
                  ? "border-name-5-active"
                  : "border-name-5"
              }
            >
              {borderValueAt(lastOption.value)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Border;
