import React from "react";
import "./Modal.scss";
import download_certificate from "../../../Utils/Icons/download_certificate.svg";
import Closebutton from "../../../Utils/Icons/close.svg";
import { string } from "../../../Localization";
import { MISSING_ICC_PROFILE } from "../../../Utils/Constants";

const Modal = (props) => {
  const {
    closeAction,
    modalTitle,
    modalContent,
    paperWeight,
    paperThickness,
    paperOpacity,
    paperFinish,
    paperCatg,
    icc_profile,
  } = props;

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = icc_profile;
    link.download = "icc_profile.icc";
    link.click();
  };

  return (
    <>
      <div className="modal-wrapper">
        <div className="modal-overlay">
          <div className="modal-content-text">
            <img
              alt="close"
              className="close-moda-btn"
              src={Closebutton}
              onClick={closeAction}
            />
            <div className="modal-content-container">
              <div className="modal-heading">{modalTitle}</div>
              <div className="modal-body-text">
                <div className="paper-details-tab">{paperWeight}</div>
                <div className="paper-details-tab">{paperThickness}</div>
                <div className="paper-details-tab">{paperOpacity}</div>
                <div className="paper-details-tab">{paperFinish}</div>
                {paperCatg && (
                  <div className="paper-details-tab">{paperCatg}</div>
                )}
              </div>
              <div className="modal-paragraph-text">{modalContent}</div>
              {icc_profile !== MISSING_ICC_PROFILE && (
                <div
                  className="modal-download-certificate"
                  // onClick={handleDownload}
                >
                  <div
                    className="modal-download-certificate-container"
                    onClick={handleDownload}
                  >
                    {" "}
                    <div className="modal-download-certificate-img">
                      <img
                        src={download_certificate}
                        alt=""
                        className="download_certificate-image"
                      />
                    </div>
                    <div className="modal-download-certificate-text">
                      {string.DOWNLOAD_ICC_PROFILE}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
