import "./OrderExhausted.scss";
import HpLogoBlue from "../../Utils/Icons/hp_blue.svg";
import { useEffect, useState } from "react";
import { getExhaustedProjectDetails } from "../../Utils/ApiHelper";
import BlockchainLogo from "../../Utils/Icons/blockchain_logo.svg";
import Ellipse from "../../Utils/Icons/EllipseGreen.svg";
import { string } from "../../Localization";
import { useParams, useSearchParams } from "react-router-dom";
import { sendGALoadedCopyExhausedPage } from "../../Utils/GAHelper";
import ShipmentLogo from "../../Utils/Icons/local_shipping.svg";
import Footer from "../LandingPage/Footer/Footer";
import { OPENSEA } from "../../Utils/Constants";

const OrderExhausted = () => {
  const { project_token } = useParams();

  const [searchParams] = useSearchParams();
  const [exhaustedProjectData, setExhaustedProjectData] = useState({});
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const queryStrings = Object.fromEntries([...searchParams]);
  const source = queryStrings["source"];

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1300);
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getExhaustedProjectDetails(project_token, source).then((res) => {
      const exhaust_data = res?.data?.data;
      setExhaustedProjectData(exhaust_data);
      sendGALoadedCopyExhausedPage(exhaust_data.user?.id, project_token);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="order-exhausted-block-content">
        <div className="order-exhausted-block">
          {exhaustedProjectData?.project && (
            <>
              <div className="order-exhausted-header">
                <div className={isSmallScreen ? "d-flex head" : "d-flex"}>
                  {exhaustedProjectData?.user?.logo ||
                  exhaustedProjectData?.user?.artist_name ? (
                    <>
                      {exhaustedProjectData?.user?.logo ? (
                        <div className="customer-logo-block">
                          <img
                            className="customer-logo"
                            src={exhaustedProjectData?.user?.logo}
                            alt="logo"
                          />
                        </div>
                      ) : (
                        <div className="artist-name">
                          {exhaustedProjectData?.user?.artist_name?.toUpperCase()}
                        </div>
                      )}
                    </>
                  ) : null}
                  {exhaustedProjectData?.user?.logo ||
                  exhaustedProjectData?.user?.artist_name ? (
                    <div className="seperator" />
                  ) : null}

                  <div
                    className={
                      exhaustedProjectData?.user?.logo ||
                      exhaustedProjectData?.user?.artist_name
                        ? "hp-logo-block"
                        : "hp-logo-block logo-container"
                    }
                  >
                    <img
                      className="hp-logo"
                      src={HpLogoBlue}
                      alt="hp logo"
                    ></img>
                  </div>
                  <div className="partnernship-text">
                    {string.UNDER_LICENSE_FROM}&nbsp;
                    {exhaustedProjectData?.user?.artist_name}&nbsp;
                    {string.FOR_HP}
                  </div>
                </div>

                <div className="d-flex my-auto ship-container align-items-center">
                  <div className="shipment-logo-block">
                    <img
                      className="shipment-logo"
                      src={ShipmentLogo}
                      alt="shipment logo"
                    ></img>
                  </div>
                  <div className="content">
                    {string.WE_ONLY_DELIVER_ORDERS_TO_UK}
                  </div>
                </div>
              </div>
              {!isSmallScreen ? (
                <div className="d-flex justify-content-between order-exhausted-body">
                  <div>
                    <div className="order-exhausted-title">
                      {string.YOU_HAVE_GREAT_TASTE}
                    </div>

                    <div className="order-exhausted-description">
                      {string.THE_PHOTOGRAPHER_PLACED_A_LIMIT_OF}&nbsp;
                      {exhaustedProjectData?.project?.no_of_copies}&nbsp;
                      {exhaustedProjectData?.project?.no_of_copies > 1
                        ? string.COPIES_FOR_THIS
                        : string.COPY_FOR_THIS}
                      <br />
                      {source == OPENSEA && string.OPENSEA + " "}
                      {string.LIMITED_EDITION_PRINT}
                      <br /> {string.COPIES_HAVE_ALREADY_BEEN_PURCHASED}
                      <br /> <br />
                      {string.WHY_NOT_CHECK_WITH_THE_PHOTOGRAPHER}
                      <br /> {string.SIZES_OF_THIS_EDITION_AVAILABLE} <br />
                      {string.MAY_BE_OF_INTEREST}
                    </div>
                    <div className="d-flex">
                      <div className="blockchain-logo-block">
                        <img
                          className="blockchain-logo"
                          src={BlockchainLogo}
                          alt="blockchain logo"
                        />
                      </div>
                      <div className="edition-block">
                        <div className="ellipse-block">
                          <img
                            className="edition-image"
                            src={Ellipse}
                            alt="edition"
                          />
                          <div className="edition-centered-text">
                            {exhaustedProjectData?.project?.no_of_copies +
                              "/" +
                              exhaustedProjectData?.project?.no_of_copies}
                          </div>
                        </div>
                        <div className="ellipse-block-text ">
                          {string.PRINTS_PURCHASED}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="preview-image-block">
                    <img
                      className="preview-image"
                      src={exhaustedProjectData?.project?.preview_image}
                      alt="preview"
                    />
                  </div>
                </div>
              ) : (
                <div className="order-exhausted-body">
                  <div className="order-exhausted-title">
                    {string.YOU_HAVE_GREAT_TASTE}
                  </div>
                  <div className="order-exhausted-description">
                    {string.THE_PHOTOGRAPHER_PLACED_A_LIMIT_OF}&nbsp;
                    {exhaustedProjectData?.project?.no_of_copies}&nbsp;
                    {exhaustedProjectData?.project?.no_of_copies > 1
                      ? string.COPIES_FOR_THIS
                      : string.COPY_FOR_THIS}{" "}
                    {source == OPENSEA && string.OPENSEA + " "}
                    {string.LIMITED_EDITION_PRINT}
                    &nbsp;
                    {string.COPIES_HAVE_ALREADY_BEEN_PURCHASED}
                    <br /> {string.WHY_NOT_CHECK_WITH_THE_PHOTOGRAPHER}&nbsp;
                    {string.SIZES_OF_THIS_EDITION_AVAILABLE}&nbsp;
                    {string.MAY_BE_OF_INTEREST}
                  </div>
                  <div className="preview-image-block">
                    <img
                      className="preview-image"
                      src={exhaustedProjectData?.project?.preview_image}
                      alt="preview"
                    />
                  </div>
                  <div className="d-flex blockchain-ellipse-container">
                    <div className="blockchain-logo-block">
                      <img
                        className="blockchain-logo"
                        src={BlockchainLogo}
                        alt="blockchain"
                      />
                    </div>
                    <div className="edition-block">
                      <div className="ellipse-block">
                        <img
                          className="edition-image"
                          src={Ellipse}
                          alt="edition"
                        />
                        <div className="edition-centered-text">
                          {exhaustedProjectData?.project?.no_of_copies +
                            "/" +
                            exhaustedProjectData?.project?.no_of_copies}
                        </div>
                      </div>
                      <div className="ellipse-block-text ">
                        {string.PRINTS_PURCHASED}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="order-exhausted-footer-conatiner">
          <Footer isLandingPage={false} />
        </div>
      </div>
    </>
  );
};

export default OrderExhausted;
