import { store } from "../Store/Store";
import { UNIT_SYSTEM_MM } from "./Constants";
import {
  convertInchesToMm,
  getCurrentYear,
  getOrientation,
  getSelectedMediaTypeName,
} from "./Helper";
import { Icons } from "./Icons";
import { editionUpperText } from "./PersonalizationHelper";

export const certificateData = () => {
  const { project, user, signature, limitedEdition, cache } = store.getState();

  const dimensionString = getDimensionString(
    project.sizeSelectionDetails.selectedDimension,
    project.selectedUnitSystem
  );

  const edition = editionUpperText(limitedEdition.noOfCopies);
  const paperName = getSelectedMediaTypeName();

  return {
    title: project.projectDetails.title,
    artistName: user.currentUserDetails.artist_name,
    description: project.projectDetails.description,
    year: getCurrentYear(),
    dimensions: dimensionString,
    paperType: paperName,
    qrCode: cache.qrCodeUrl ? cache.qrCodeUrl : Icons.qrCode,
    edition: edition,
    noOfCopies: limitedEdition.noOfCopies,
    previewImage: project.previewImage,
    token: "",
    mintTime: "",
    signature: signature.signatureImage,
    biography: user.currentUserDetails.biography,
    isLimited: limitedEdition.isLimited,
  };
};

export const getDimensionString = (dimension, inUnitSystem) => {
  const photoWidth =
    inUnitSystem === UNIT_SYSTEM_MM
      ? `${parseInt(convertInchesToMm(dimension.width))}mm`
      : `${dimension.width}"`;

  const photoHeight =
    inUnitSystem === UNIT_SYSTEM_MM
      ? `${parseInt(convertInchesToMm(dimension.height))}mm`
      : `${dimension.height}"`;

  return `${getOrientation(dimension)} ${photoWidth} X ${photoHeight}`;
};
