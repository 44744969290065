import "./SubmitOrder.scss";
import React, { useEffect, useState } from "react";
import { string } from "../../../Localization";
import "react-phone-number-input/style.css";
import OrderInfo from "../OrderInfo/OrderInfo";
import CustomerDetails from "./CustomerDetails/CustomerDetails";
import PriceInfo from "../PriceInfo/PriceInfo";
import { getOrderInfo, getProjectSummary } from "../../../Utils/ApiHelper";
import { useParams, useSearchParams } from "react-router-dom";
import {
  sendGALoadedOrderPublicView,
  sendGALoadedOrderPublicViewViaQR,
} from "../../../Utils/GAHelper";
import dropdownIconDown from "../../../Utils/Icons/dropdown_arrow.svg";
import dropdownIconUP from "../../../Utils/Icons/dropdown_arrow_up.svg";
import { useSelector } from "react-redux";
import {
  defaultCountryObj,
  getCountryByName,
} from "../../../Utils/OrderHelper";
import { PUBLIC_VIEW } from "../../../Utils/Constants";
import PublicViewLoader from "../../../Components/Loaders/PublicViewLoader/PublicViewLoader";

const SubmitOrder = (props) => {
  const { orderToken } = useSelector((state) => state.order);

  const [searchParams] = useSearchParams();
  const { project_token } = useParams();

  const [onSubmit, setOnSubmit] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isOpenReviewOrder, setIsOpenReviewOrder] = useState(false);
  const [addressLine1, setAddressLine1] = useState("");

  const [orderData, setOrderData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    country: defaultCountryObj,
    postalCode: "",
    addressLine2: "",
    city: "",
    region: "",
  });

  const [alertMessage, setAlertMessage] = useState({
    firstName: null,
    lastName: null,
    email: null,
    phoneNumber: null,
    country: null,
    postalCode: null,
    addressLine1: null,
    city: null,
    region: null,
    couponCode: null,
  });

  const { setProjectSummaryData, projectSummaryData } = props;

  const queryStrings = Object.fromEntries([...searchParams]);
  const source = queryStrings["source"];

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1110);
    };
    window.addEventListener("resize", handleResize);
    prepopulateData();
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getProjectSummary(project_token, source).then((res) => {
      const projectSummaryData = res?.data?.data;
      setProjectSummaryData(projectSummaryData);
      if (checkIfLoadedViaQR()) {
        sendGALoadedOrderPublicViewViaQR(
          projectSummaryData?.user?.id,
          project_token,
          queryStrings["utm_source"] ?? ""
        );
      } else {
        sendGALoadedOrderPublicView(
          projectSummaryData?.user?.id,
          project_token,
          queryStrings["utm_source"] ?? ""
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReviewOrderClick = () => {
    setIsOpenReviewOrder(!isOpenReviewOrder);
  };

  const checkIfLoadedViaQR = () => {
    return (
      queryStrings["utm_source"] === PUBLIC_VIEW &&
      queryStrings["utm_medium"] === "qrcode"
    );
  };

  const prepopulateData = () => {
    if (orderToken) {
      getOrderInfo(orderToken).then((res) => {
        const order = res?.data?.data?.order;

        setOrderData({
          firstName: order?.first_name,
          lastName: order?.last_name,
          email: order?.email,
          phoneNumber: order?.contact_number,
          country: getCountryByName(order?.address?.country),
          postalCode: order?.address?.zip_code,
          addressLine2: order?.address?.address_line2,
          city: order?.address?.city,
          region: order?.address?.region,
        });
        setAddressLine1(order?.address?.address_line1);
      });
    }
  };
  return (
    <>
      <div className="submit-order-block">
        <div>
          {projectSummaryData?.project && (
            <div className="submit-order-body">
              <div className={onSubmit ? "blur-background" : null}>
                <div className="submit-order-title">
                  {projectSummaryData.project.artist_proof
                    ? string.SUBMIT_YOUR_ARTIST_PROOF
                    : string.SUBMIT_YOUR_ORDER_TO_BUY_FROM_HP}
                </div>
                {!isSmallScreen ? (
                  <div className="submit-order-description">
                    <OrderInfo project={projectSummaryData.project} />
                    <CustomerDetails
                      orderData={orderData}
                      setOrderData={setOrderData}
                      alertMessage={alertMessage}
                      setAlertMessage={setAlertMessage}
                      addressLine1={addressLine1}
                      setAddressLine1={setAddressLine1}
                    />
                    <PriceInfo
                      isStepOne={true}
                      projectToken={project_token}
                      price={projectSummaryData.price}
                      orderData={orderData}
                      alertMessage={alertMessage}
                      addressLine1={addressLine1}
                      setAlertMessage={setAlertMessage}
                      setProjectSummaryData={setProjectSummaryData}
                      setOnSubmit={setOnSubmit}
                      userId={projectSummaryData?.user?.id}
                      isSubmitOrder={true}
                    />
                  </div>
                ) : (
                  <>
                    <div
                      className={
                        isOpenReviewOrder
                          ? "dropdown-container top-bottom-border"
                          : "dropdown-container "
                      }
                    >
                      <div className="dropdown-button">
                        <button
                          className={`dropdown-option ${
                            isOpenReviewOrder ? "active" : ""
                          }`}
                          onClick={() => handleReviewOrderClick()}
                          style={{
                            background: isOpenReviewOrder
                              ? "#EEEEEE"
                              : "#ffffff",
                          }}
                        >
                          {string.REVIEW_ORDER}
                          {isOpenReviewOrder ? (
                            <img src={dropdownIconUP} alt="dropup" />
                          ) : (
                            <img src={dropdownIconDown} alt="dropdown" />
                          )}
                        </button>
                      </div>
                      {isOpenReviewOrder && (
                        <div className="submit-order-description">
                          <OrderInfo
                            project={projectSummaryData.project}
                            isSmallScreen={isSmallScreen}
                          />
                        </div>
                      )}
                    </div>

                    <div className="submit-order-description">
                      <div className="address-container">
                        <CustomerDetails
                          orderData={orderData}
                          setOrderData={setOrderData}
                          alertMessage={alertMessage}
                          setAlertMessage={setAlertMessage}
                          addressLine1={addressLine1}
                          setAddressLine1={setAddressLine1}
                        />
                      </div>
                      <div className="seperator-price" />
                    </div>
                    <PriceInfo
                      isStepOne={true}
                      projectToken={project_token}
                      price={projectSummaryData.price}
                      orderData={orderData}
                      alertMessage={alertMessage}
                      addressLine1={addressLine1}
                      setAlertMessage={setAlertMessage}
                      setProjectSummaryData={setProjectSummaryData}
                      setOnSubmit={setOnSubmit}
                      userId={projectSummaryData?.user?.id}
                      isSubmitOrder={true}
                    />
                  </>
                )}
              </div>
              {onSubmit && <PublicViewLoader />}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SubmitOrder;
