import { fabric } from "fabric";
import { string } from "../Localization";
import {
  setNoOfCopiesPlot,
  setTextHeightToFontSizeRatio,
} from "../Store/Project/limitedEditionSlice";
import { setSignatureScale } from "../Store/Project/ProjectSlice";
import {
  setCoordsAltered,
  setSignatureDetails,
} from "../Store/Project/signatureSlice";
import { store } from "../Store/Store";
import { renderImage, renderLimitedEditionInPreview } from "./CanvasDownload";
import {
  NO_OF_COPIES,
  PROJECT_PREVIEW_MODE_PERSONALIZATION,
  SIGNATURE,
  STEP_ONE_PHOTO_DETAILS,
} from "./Constants";
import {
  sendGARepositionedEditionNumber,
  sendGARepositionedSignature,
  sendGAResizedEditionNumber,
  sendGAResizedSignature,
} from "./GAHelper";
import { getScaledInchesToPixels } from "./Helper";
import { finalProjectSize } from "./PhotoFrameProject";
var FontFaceObserver = require("fontfaceobserver");

export const removeLimitedEdition = (inCanvas) => {
  let limitedEditionIds = [
    NO_OF_COPIES,
    `${NO_OF_COPIES}-dbClickText`,
    `${NO_OF_COPIES}-border`,
  ];
  removeCanvasObjects(inCanvas, limitedEditionIds);
};

export const removeSignature = (inCanvas) => {
  let signatureIds = [
    SIGNATURE,
    `${SIGNATURE}-dbClickText`,
    `${SIGNATURE}-border`,
  ];
  removeCanvasObjects(inCanvas, signatureIds);
};

export const getEditionData = () => {
  const { noOfCopies, canShowNoOfCopies, font, isLimited } =
    store.getState().limitedEdition;
  if (canShowNoOfCopies) {
    const textToDisplay = isLimited
      ? `${editionUpperText(noOfCopies)} / ${noOfCopies}`
      : "1";
    return {
      text: textToDisplay,
      font: font.type,
      coords: getLimitedEditionCoords(),
    };
  }
  return null;
};

export const addLimitedEditionToCanvas = (inCropCanvas) => {
  const editionData = getEditionData();
  renderLimitedEditionInPreview(inCropCanvas, editionData).then(() => {
    const limitedEditionElement = getCanvasElement(inCropCanvas, NO_OF_COPIES);
    if (limitedEditionElement) {
      initializeItem(inCropCanvas, limitedEditionElement, NO_OF_COPIES);
    }
  });
};

export const addSignatureToCanvas = (inCropCanvas, signatureImage) => {
  const coords = getSignatureCoords();
  if (signatureImage) {
    renderImage(inCropCanvas, {
      imageSrc: signatureImage,
      imageScale: 1,
      isCenterAlign: false,
      coords: coords,
      id: SIGNATURE,
    }).then(() => {
      const signatureElement = getCanvasElement(inCropCanvas, SIGNATURE);
      initializeItem(inCropCanvas, signatureElement, SIGNATURE);
      const { activeStep } = store.getState().page;
      if (activeStep === STEP_ONE_PHOTO_DETAILS) {
        removeSignature(inCropCanvas);
      }
    });
  }
};

export const setSignatureCoordinates = () => {
  const {
    previewCanvasWidth,
    previewCanvasHeight,
    itemHeight,
    space,
    borderInPixels,
  } = getPreviewCanvasDetails();

  const signatureWidth = calculateSignatureWidth(itemHeight);
  store.dispatch(
    setSignatureDetails({
      size: {
        width: signatureWidth,
        height: itemHeight,
      },
      position: {
        x: previewCanvasWidth - borderInPixels - signatureWidth,
        y: previewCanvasHeight - borderInPixels + space,
      },
    })
  );
};

export const setLimitedEditionCoordinates = () => {
  const { noOfCopies, font, isLimited, plot } = store.getState().limitedEdition;
  const { borderWidth } = store.getState().project;
  let textHeightInInches = borderWidth;
  if (borderWidth === 0) {
    textHeightInInches = 1;
  }
  textHeightInInches = textHeightInInches * 0.590551;

  const textheightInPixels = getScaledInchesToPixels(textHeightInInches);
  const fontSize = plot.fontSize ? plot.fontSize : textheightInPixels;
  const textToDisplay = isLimited
    ? `${editionUpperText(noOfCopies)} / ${noOfCopies}`
    : "1";

  const textContent = new fabric.Text(textToDisplay, {
    fontFamily: font.type,
    id: NO_OF_COPIES,
    fill: "#212121",
    fontWeight: 400,
    fontSize: fontSize,
    textAlign: "center",
    editable: false,
    borderScaleFactor: 4,
  });
  const { previewCanvasHeight, borderInPixels } = getPreviewCanvasDetails();
  const verticalSpace = borderInPixels - textheightInPixels;
  const space = verticalSpace / 2;

  const newPlot = {
    size: {
      width: textContent.width,
      height: textContent.height,
    },
    position: {
      x: borderInPixels,
      y: previewCanvasHeight - borderInPixels + space,
    },
    fontSize: fontSize,
  };

  store.dispatch(setNoOfCopiesPlot(newPlot));

  const textHeightToFontSizeRatio = textContent.height / fontSize;
  store.dispatch(setTextHeightToFontSizeRatio(textHeightToFontSizeRatio));
};

export const resetLimitedEditionPlot = () => {
  setLimitedEditionCoordinates();
};

export const getSignatureCoords = () => {
  let { signatureDetails } = store.getState().signature;
  const { size, position } = signatureDetails;
  const { width, height } = size;

  return {
    x: position.x,
    y: position.y,
    width: width,
    height: height,
  };
};

export const getLimitedEditionCoords = () => {
  let { plot } = store.getState().limitedEdition;
  const { size, position, fontSize } = plot;
  const { width, height } = size;

  return {
    x: position.x,
    y: position.y,
    width: width,
    height: height,
    fontSize: fontSize,
  };
};

export const getMagnifierVisibleCanvasCoords = () => {
  const sideViewContainer = document.getElementById("sideview-bar-wrapper");
  if (sideViewContainer) {
    let previewWidth = window.innerWidth - sideViewContainer.clientWidth;
    let previewHeight = window.innerHeight;
    return {
      width: previewWidth - 120,
      height: previewHeight - 120,
    };
  }
  return null;
};

const calculateLabelCoords = (refElement, labelBox) => {
  return {
    top: refElement?.top + refElement?.height * refElement?.scaleY,
    left:
      refElement?.left +
      (refElement?.width * refElement.scaleX) / 2 -
      labelBox.width / 2,
  };
};

const initializeItem = (inCropCanvas, element, itemType) => {
  // add "Double click to hide preview background" text below no of copies
  appendLabelToCanvasObject(inCropCanvas, element, itemType);

  //activate LimitedEdition/Signature object if active preview mode is PROJECT_PREVIEW_MODE_PERSONALIZATION.
  const { activePreviewMode } = store.getState().project;
  let enable = activePreviewMode === PROJECT_PREVIEW_MODE_PERSONALIZATION;
  toggleElementState(inCropCanvas, element, enable, itemType);

  // store.dispatch(setCroppedCanvasActiveObject(itemType));
  //when the current obj (i.e Signature or LE) is active, deactivate  the other one
  // deActivateObject(inCropCanvas);

  inCropCanvas.on({
    "mouse:down": (e) => handleCropCanvasClick(e, inCropCanvas, itemType),
  });

  var isObjectMoving = false;
  var isObjectResizing = false;

  element.on("scaling", function (e) {
    isObjectResizing = true;
    rePositionLabel(inCropCanvas, e.transform.target, itemType);
    updatePlotDetailsAfterScaling(inCropCanvas, e.transform.target, itemType);
    store.dispatch(setCoordsAltered(true));
  });

  element.on("moving", function (e) {
    isObjectMoving = true;
    rePositionLabel(inCropCanvas, e.transform.target, itemType);
    updatePlotDetailsAfterMove(inCropCanvas, e.transform.target, itemType);
    store.dispatch(setCoordsAltered(true));
  });

  inCropCanvas.on("mouse:up", function (event) {
    if (isObjectMoving) {
      isObjectMoving = false;
      objectIsMoved(event.target);
    }
    if (isObjectResizing) {
      isObjectResizing = false;
      objectIsResized(event.target);
    }
  });
};

export const handleCropCanvasClick = (e, inCropCanvas, itemType) => {
  const { activePreviewMode } = store.getState().project;
  const { x: pointX, y: pointY } = e.pointer;

  let coords;
  if (itemType === SIGNATURE) {
    coords = getSignatureCoords();
  }
  if (itemType === NO_OF_COPIES) {
    coords = getLimitedEditionCoords();
  }
  let { x: startX, y: startY, width: itemWidth, height: itemHeight } = coords;
  startX = startX * inCropCanvas.getZoom();
  startY = startY * inCropCanvas.getZoom();
  itemWidth = itemWidth * inCropCanvas.getZoom();
  itemHeight = itemHeight * inCropCanvas.getZoom();

  const endX = startX + itemWidth;
  const endY = startY + itemHeight;

  if (pointX > startX && pointX < endX && pointY > startY && pointY < endY) {
    const element = getCanvasElement(inCropCanvas, itemType);
    if (element) {
      if (activePreviewMode === PROJECT_PREVIEW_MODE_PERSONALIZATION) {
        if (e.e.detail === 1) {
          toggleElementState(inCropCanvas, element, true, itemType);
        } else if (e.e.detail === 2) {
          toggleElementState(inCropCanvas, element, false, itemType);
        }
      }
    }
  }
};

// CanvasObject is either signature image OR Limited Edition
const appendLabelToCanvasObject = (inCanvas, refElement, itemType) => {
  const { borderWidth } = store.getState().project;
  const borderWidthInPixels = borderWidth
    ? getScaledInchesToPixels(borderWidth)
    : getScaledInchesToPixels(1);

  const doubleClickText = new fabric.Text(string.DOUBLE_CLICK_TO_HIDE, {
    fontFamily: "Raleway",
    id: `${itemType}-dbClickText`,
    fontWeight: 400,
    editable: false,
    selectable: false,
    hasControls: false,
    textAlign: "center",
    fontSize: borderWidthInPixels * (0.1 / inCanvas.getZoom()),
    hasRotatingPoint: false,
    hoverCursor: "default",
  });

  const border = new fabric.Rect({
    id: `${itemType}-border`,
    fill: "white",
    height: doubleClickText.height + 10,
    width: doubleClickText.width + 10,
    stroke: "black",
    strokeWidth: 4,
    selectable: false,
    hasControls: false,
    borderColor: "black",
    hasRotatingPoint: false,
    hasBorders: true,
    hoverCursor: "default",
  });

  const { left, top } = calculateLabelCoords(refElement, border);
  border.set({ left: left, top: top }).setCoords();

  doubleClickText
    .set({
      left: left + border.width / 2 - doubleClickText.width / 2,
      top:
        top +
        (border.height * border?.scaleY -
          doubleClickText.height * doubleClickText?.scaleY) /
          2,
    })
    .setCoords();

  inCanvas.add(border);
  inCanvas.add(doubleClickText);
  inCanvas.renderAll();
};

export const deActivateObject = (inCanvas) => {
  const currentItem = store.getState().project.croppedCanvasActiveObject;
  //obj is the item that has to be deactivated
  let objId =
    currentItem && currentItem === SIGNATURE ? NO_OF_COPIES : SIGNATURE;
  let obj = getCanvasElement(inCanvas, objId);
  if (obj) {
    toggleElementState(inCanvas, obj, false, objId);
  }
};

const rePositionLabel = (canvas, refElement, itemType) => {
  const border = getCanvasElement(canvas, `${itemType}-border`);

  if (border) {
    const { left, top } = calculateLabelCoords(refElement, border);

    border.set({ top: top, left: left }).setCoords();
    let doubleClickText = getCanvasElement(canvas, `${itemType}-dbClickText`);

    if (doubleClickText) {
      doubleClickText
        .set({
          top:
            top +
            (border.height * border?.scaleY -
              doubleClickText.height * doubleClickText?.scaleY) /
              2,
          left:
            left +
            (border.width * border?.scaleX) / 2 -
            (doubleClickText.width * border?.scaleX) / 2,
        })
        .setCoords();
    }
  }
};

export const getCanvasElement = (inCanvas, elementId) => {
  let foundElement = "";
  inCanvas.forEachObject((element) => {
    if (
      Object.prototype.hasOwnProperty.call(element, "id") &&
      elementId === element.id
    ) {
      foundElement = element;
    }
  });
  return foundElement;
};

export const toggleElementState = (
  canvas,
  element,
  shouldActivate,
  itemType
) => {
  element.selectable = shouldActivate;
  element.hasControls = shouldActivate;
  element.hasBorders = shouldActivate;
  element.lockMovementX = !shouldActivate;
  element.lockMovementY = !shouldActivate;
  element.hoverCursor = shouldActivate ? "move" : "default";
  element.borderColor = shouldActivate ? "#176267" : "transparent";
  let color = shouldActivate ? "white" : "transparent";
  element.set("backgroundColor", color);
  if (shouldActivate) {
    element.setControlsVisibility({
      ml: false,
      mt: false,
      mr: false,
      mb: false,
      mtr: false,
    });
  }
  const dbClickText = getCanvasElement(canvas, `${itemType}-dbClickText`);

  if (dbClickText) {
    dbClickText.visible = shouldActivate;
  }
  const border = getCanvasElement(canvas, `${itemType}-border`);

  if (border) {
    border.visible = shouldActivate;
  }
  if (shouldActivate) {
    canvas.setActiveObject(element);
  }

  canvas.renderAll();
};

export const getPreviewCanvasDetails = () => {
  const { borderWidth } = store.getState().project;
  const borderInInches = borderWidth ? borderWidth : 1;
  const borderInPixels = getScaledInchesToPixels(borderInInches);

  const projectSize = finalProjectSize();
  const previewCanvasWidth = projectSize.width;
  const previewCanvasHeight = projectSize.height;

  //itemHeight-> height of signature or limited edition exclusing vertical spaces
  const itemHeight = getScaledInchesToPixels(borderInInches * 0.590551); //1.5cm

  const verticalSpace = borderInPixels - itemHeight;
  const space = verticalSpace / 2;

  return {
    previewCanvasWidth,
    previewCanvasHeight,
    itemHeight,
    space,
    borderInPixels,
  };
};

const calculateSignatureWidth = (signatureHeight) => {
  const { signatureAR } = store.getState().signature;
  return signatureHeight * signatureAR;
};

const removeCanvasObjects = (inCanvas, objArray) => {
  inCanvas?.forEachObject((element) => {
    if (
      Object.prototype.hasOwnProperty.call(element, "id") &&
      objArray.includes(element.id)
    ) {
      inCanvas.remove(element);
    }
  });
};

export const updatePlotDetailsAfterScaling = (
  inCropCanvas,
  object,
  itemType
) => {
  const { signatureScale } = store.getState().project;
  let scaleX = Math.abs(object.scaleX / signatureScale);
  let scaleY = Math.abs(object.scaleY / signatureScale);

  let objectSize, objectPosition;
  if (itemType === SIGNATURE) {
    objectSize = store.getState().signature.signatureDetails.size;
    objectPosition = store.getState().signature.signatureDetails.position;
  } else {
    objectSize = store.getState().limitedEdition.plot.size;
    objectPosition = store.getState().limitedEdition.plot.position;
  }

  checkForBoundary(object, inCropCanvas, objectPosition);
  const newPlot = {
    position: {
      x: object.left,
      y: object.top,
    },
    size: {
      width:
        itemType === SIGNATURE
          ? objectSize.width * scaleX
          : object.getScaledWidth(),
      height:
        itemType === SIGNATURE
          ? objectSize.height * scaleY
          : object.getScaledHeight(),
    },
  };

  if (itemType === NO_OF_COPIES) {
    const { textHeightToFontSizeRatio } = store.getState().limitedEdition;
    newPlot.fontSize = object.getScaledHeight() / textHeightToFontSizeRatio;
  }

  if (itemType === SIGNATURE) {
    //track object.scaleX value in redux to calculate scaleX in every iteration of scaling event
    store.dispatch(setSignatureScale(object.scaleX));
    store.dispatch(setSignatureDetails(newPlot));
  } else {
    store.dispatch(setNoOfCopiesPlot(newPlot));
  }
};

export const updatePlotDetailsAfterMove = (inCropCanvas, object, itemType) => {
  let objectSize, objectPosition;
  if (itemType === SIGNATURE) {
    objectSize = store.getState().signature.signatureDetails.size;
    objectPosition = store.getState().signature.signatureDetails.position;
  } else {
    objectSize = store.getState().limitedEdition.plot.size;
    objectPosition = store.getState().limitedEdition.plot.position;
  }

  checkForBoundary(object, inCropCanvas, objectPosition);
  const newPlot = {
    position: {
      x: object.left,
      y: object.top,
    },
    size: {
      width: objectSize.width,
      height: objectSize.height,
    },
  };

  if (itemType === SIGNATURE) {
    store.dispatch(setSignatureDetails(newPlot));
  } else {
    store.dispatch(setNoOfCopiesPlot(newPlot));
  }
};

const objectIsMoved = (element) => {
  if (element.id === SIGNATURE) {
    sendGARepositionedSignature();
  } else if (element.id === NO_OF_COPIES) {
    sendGARepositionedEditionNumber();
  }
};

const objectIsResized = (element) => {
  if (element.id === SIGNATURE) {
    sendGAResizedSignature();
  } else if (element.id === NO_OF_COPIES) {
    sendGAResizedEditionNumber();
  }
};

const checkForBoundary = (inObject, inCanvas, oldPosition) => {
  const objectPosX = inObject.left * inCanvas.getZoom();
  const objectPosY = inObject.top * inCanvas.getZoom();

  if (objectPosX < 0) {
    inObject.set({ left: 0 }).setCoords();
  }
  if (objectPosY < 0) {
    inObject.set({ top: 0 }).setCoords();
  }
  if (objectPosX > inCanvas.width) {
    inObject.set({ left: oldPosition.x }).setCoords();
  }
  if (objectPosY > inCanvas.height) {
    inObject.set({ top: oldPosition.y }).setCoords();
  }
};

//to be used in draft flow only
export const setEditionSignaturePlot = (object, itemType) => {
  const newPlot = {
    position: {
      x: object.left,
      y: object.top,
    },
    size: {
      width: object.width,
      height: object.height,
    },
  };

  if (itemType === SIGNATURE) {
    store.dispatch(setSignatureDetails(newPlot));
  } else {
    store.dispatch(setNoOfCopiesPlot(newPlot));
  }
};

export const editionUpperText = (copies) => {
  // if (copies > 9 && copies < 100) {
  //   return "01";
  // } else if (copies > 99) {
  //   return "001";
  // }
  return "1";
};

export const getScrollableCropCanvasContainer = () => {
  return document.getElementById("cropped-canvas-container");
};

export const updateFontFamilyInCanvas = (inCanvas, fontFamily) => {
  const limitedEditionObject = getCanvasElement(inCanvas, NO_OF_COPIES);
  if (limitedEditionObject) {
    var fontObj = new FontFaceObserver(fontFamily, {
      weight: 400,
    });
    fontObj.load().then(function () {
      limitedEditionObject.set({ fontFamily: fontFamily, dirty: true });
      inCanvas.renderAll();
    });
  }
};
