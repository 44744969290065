import React from "react";
import "./NavigateBackButton.scss";
import backArrowSvg from "../../Utils/Icons/arrow_back.svg";
import { sendGAClickedBack } from "../../Utils/GAHelper";
import { string } from "../../Localization";

const NavigateBackButton = ({ onNavigateBack }) => {
  return (
    <div
      className="navigateBackContainer"
      onClick={() => {
        onNavigateBack();
        sendGAClickedBack();
      }}
    >
      <img src={backArrowSvg} alt="" />
      <span>{string.BACK}</span>
    </div>
  );
};

export default NavigateBackButton;
