//Coordinate systems in Project Render Canvas
//CRCS - Canvas Render Coordinate system.
//This is the canvas used in PROJECT_PREVIEW_MODE_SIZE_SELECTION. Excluding footer size.
//CCS = Canvas Coordinate system
//This is the canvas used in PROJECT_PREVIEW_MODE_SIZE_SELECTION. Including footer size.

import { store } from "../Store/Store";
import {
  createCroppedPreviewCanvas,
  createImagePreviewCanvas,
  getCropRectSizeInPixels,
  Rect,
  setSelectionProperty,
  Size,
  setImageProperty,
  getPixelsToInches,
  insetSize,
  getScaledInchesToPixels,
} from "./Helper";
import {
  finalProjectSize,
  loadProjectImage,
  getCropDefaultPosition,
} from "./PhotoFrameProject";
import { fabric } from "fabric";
import {
  setIsCanvasLoading,
  setProjectCropRect,
  setSizeSelectionDetails,
  setProjectImageScale,
  setBorderScale,
} from "../Store/Project/ProjectSlice";
import {
  LEFT_SIDEBAR_WIDTH,
  TOP_BAR_HEIGHT,
  PROJECT_PREVIEW_MODE_INITIAL,
  PROJECT_PREVIEW_MODE_SIZE_SELECTION,
  PROJECT_PREVIEW_MODE_CROPPED,
  IMAGE_ELEMENT_OFFSET,
  MAXIMUM_CANVAS_SIZE,
  HUMAN_VIEW,
  SOFA_WIDTH_INCHES,
  HUMAN_HEIGHT_INCHES,
  SOFA_VIEW,
} from "./Constants";
import { getHumanViewSize, getSofaViewSize } from "./VirtualViewHelper";
import { getMagnifierVisibleCanvasCoords } from "./PersonalizationHelper";

export const loadProject = (inCanvas, mode, onCompletion) => {
  laodProjectPreview(inCanvas, mode, onCompletion);
};

//=================================================================================================================================
//Preview
//=================================================================================================================================

//Mode: PROJECT_PREVIEW_MODE_INITIAL - default/initial -> Only content will be present. (image is the only content present right now)
//Mode: PROJECT_PREVIEW_MODE_SIZE_SELECTION - Print Size Selection -> A mask rect (with user opted szie) will appear so that user can choose visible area
//Mdoe: PROJECT_PREVIEW_MODE_CROPPED - Crop finalized
export const laodProjectPreview = (inCanvas, mode, onCompletion) => {
  store.dispatch(setIsCanvasLoading(true));
  //1. Project Preview Elements:
  // (1.1) Project Content -  at present only image. Image might have undergone changes across modes, Eg, cropped.
  // (1.2) UI elements required for editing. Eg crop selection layer
  // (1.3) A canvas to show the project content
  //2. Show the project preview with Zoom-to-Fit applied so that user can view compeltley.

  let previewCanvas = inCanvas ? inCanvas : createPreviewCanvas(mode);
  loadProjectImageContent(mode, (imageElem) => {
    loadProjectImageContentInPreview(mode, imageElem, previewCanvas);
    if (mode === PROJECT_PREVIEW_MODE_SIZE_SELECTION) {
      applyImageFill(imageElem);
      addSizeSelecionLayer(previewCanvas);
    }
    scaleProjectToFitIntoPreview(previewCanvas, mode);
    previewCanvas.renderAll();
    store.dispatch(setIsCanvasLoading(false));
    onCompletion(previewCanvas);
  });
};

export const applyImageFill = (imageEle) => {
  const { project } = store.getState();
  const { projectImageScale } = project;
  imageEle.scale(projectImageScale).setCoords();
};

export const reloadPreview = (mode, previewCanvas) => {
  if (mode === PROJECT_PREVIEW_MODE_SIZE_SELECTION) {
    removeSizeSelectionLayer(previewCanvas);
    previewCanvas.setZoom(1);

    addSizeSelecionLayer(previewCanvas);
    scaleProjectToFitIntoPreview(previewCanvas, mode);
  } else if (mode === PROJECT_PREVIEW_MODE_INITIAL) {
    removeSizeSelectionLayer(previewCanvas);
    previewCanvas.setZoom(1);

    let imageElem = getImageElement(previewCanvas);
    loadProjectImageContentInPreview(mode, imageElem, previewCanvas);
    scaleProjectToFitIntoPreview(previewCanvas, mode);
  }
};

export const createPreviewCanvas = (mode) => {
  let previewCanvas = createImagePreviewCanvas();
  if (mode === PROJECT_PREVIEW_MODE_CROPPED) {
    previewCanvas = createCroppedPreviewCanvas();
  }
  return previewCanvas;
};

export const loadProjectImageContent = (mode, onCompletion) => {
  if (
    mode === PROJECT_PREVIEW_MODE_INITIAL ||
    mode === PROJECT_PREVIEW_MODE_SIZE_SELECTION
  ) {
    loadProjectImage(mode, (imageElem) => {
      onCompletion(imageElem);
    });
  } else {
    //Here we need to show cropped image.
    //Cropped image will not be directly available in Redux. Data requried to generate cropped image will be present in redux.
    //We need to generate cropped image using this detail/
    generateCroppedImage((cropped) => {
      cropped.onload = function () {
        //Once cropped image is generated, add it to preview as project-content.
        const croppedimage = new fabric.Image(cropped);
        setImageProperty(croppedimage);
        onCompletion(croppedimage);
      };
    });
  }
};

export const addSizeSelecionLayer = (previewCanvas) => {
  //We need to mask the content that does not comes-in final project preview.
  //For this, we need touse even-odd fill rule.
  //Create full Project rect, add visible portion rect (the content inside this will be visible)
  //Generate single Path from these two rects and fill the path. it appears as mask, showing content in visible rect.

  let mode = PROJECT_PREVIEW_MODE_SIZE_SELECTION;
  //Full project rect points

  const strokeWidth = 3;

  let projectSize = getProjectCanvasSize(mode);
  let startCanvasX = -strokeWidth;
  let startCanvasY = -strokeWidth;
  let endCanvasX = startCanvasX + projectSize.width + strokeWidth * 2;
  let endCanvasY = startCanvasY + projectSize.height + strokeWidth * 2;

  //Visible rect points
  //In backend it is in ICS.
  //But as per the workflow given in the UI (crop-rect is fixed and image is moving), we need to show it in Project Coordinate (origin is relative to project).
  //So, we are centering crop rect and
  const {
    left: cropCanvasLeft,
    top: cropCanvasTop,
    right: cropCanvasRight,
    bottom: cropCanvasBottom,
  } = getCropRectCoordinatesInCanvas();

  var maskPath = new fabric.Path(
    `M ${startCanvasX} ${startCanvasY} L ${endCanvasX} ${startCanvasY} L ${endCanvasX} ${endCanvasY} L ${startCanvasX} ${endCanvasY} M ${cropCanvasLeft} ${cropCanvasTop} L ${cropCanvasRight} ${cropCanvasTop} L ${cropCanvasRight} ${cropCanvasBottom} L ${cropCanvasLeft} ${cropCanvasBottom} z`,
    {
      fill: "rgba(26,30,34,0.8)",
      fillRule: "evenodd",
      strokeWidth: strokeWidth,
      stroke: "#176267",
    }
  );
  setSelectionProperty(maskPath);
  previewCanvas.add(maskPath);
};

export const removeSizeSelectionLayer = (previewCanvas) => {
  let selectionElement = getSelectionElement(previewCanvas);
  previewCanvas.remove(selectionElement);

  let selectionFooterMaskElement = getSelectionFooterMaskElement(previewCanvas);
  previewCanvas.remove(selectionFooterMaskElement);

  const signaturePlaceholderElement =
    getSignaturePlaceholderElement(previewCanvas);
  previewCanvas.remove(signaturePlaceholderElement);
};

export const removeCroppedImageWhiteBackgroundLayer = (previewCanvas) => {
  let selectionElement = getWhiteBackgroundElement(previewCanvas);
  previewCanvas.remove(selectionElement);
};

//=================================================================================================================================

//=================================================================================================================================
//Actions
//=================================================================================================================================

export const didChangeCropSizeSelection = (sizeInInches, previewCanvas) => {
  let { project } = store.getState();

  const newSizeInPixels = getCropRectSizeInPixels(sizeInInches);
  const { originalProjectSize, projectCropRect } = project;

  if (
    projectCropRect.width !== newSizeInPixels.width ||
    projectCropRect.height !== newSizeInPixels.height
  ) {
    const imageEle = getImageElement(previewCanvas);
    const printAR = newSizeInPixels.width / newSizeInPixels.height;
    const imageAR = originalProjectSize.width / originalProjectSize.height;

    const scale =
      printAR > imageAR
        ? newSizeInPixels.width / originalProjectSize.width
        : newSizeInPixels.height / originalProjectSize.height;

    imageEle.scale(scale).setCoords();

    const scaledImgWidth = imageEle.getScaledWidth();
    const scaledImgHeight = imageEle.getScaledHeight();

    store.dispatch(setProjectImageScale(scale));
    const imageLeft = (scaledImgWidth - newSizeInPixels.width) / 2;
    const imageTop = (scaledImgHeight - newSizeInPixels.height) / 2;

    const newRect = Rect(
      imageLeft,
      imageTop,
      newSizeInPixels.width,
      newSizeInPixels.height
    );

    store.dispatch(setProjectCropRect(newRect));
    reloadPreview(PROJECT_PREVIEW_MODE_SIZE_SELECTION, previewCanvas);
  }
};

export const applyCrop = (inCanvas, onCompletion) => {
  laodProjectPreview(inCanvas, PROJECT_PREVIEW_MODE_CROPPED, onCompletion);
};

//=================================================================================================================================

//=====================================================================================================================================
//Utility
//=====================================================================================================================================

export const generateCroppedImage = (onCompletion) => {
  const { projectCropRect } = store.getState().project;
  let mode = PROJECT_PREVIEW_MODE_SIZE_SELECTION;

  //We will require a offscreen-canvas without zoom and selection layer to generate cropped image.
  let offscreenCanvas = createPreviewCanvas(mode);
  loadProjectImageContent(mode, (imageElem) => {
    loadProjectImageContentInPreview(mode, imageElem, offscreenCanvas);
    offscreenCanvas.renderAll();

    applyImageFill(imageElem);

    //Crop Rect is in ICS
    //But, in the UI we need to fix crop-rect by showing it center aligned. Allow the image to be moved.
    //Hence, image is positoned using croprect details. Now we need to show crop rect center aligned.
    //In UI, we fix Crop Rect and move relative to
    const { left: cropCanvasLeft, top: cropCanvasTop } =
      getCropRectCoordinatesInCanvas();
    const { width, height } = projectCropRect;

    var cropped = new Image();
    cropped.crossOrigin = "Anonymous";
    cropped.src = offscreenCanvas.toDataURL({
      left: cropCanvasLeft,
      top: cropCanvasTop,
      width: Math.ceil(width),
      height: Math.ceil(height),
    });
    onCompletion(cropped);
  });
};

export const loadProjectImageContentInPreview = (
  mode,
  imageElem,
  previewCanvas
) => {
  // const projectSize = getProjectCanvasSize(mode);
  // previewCanvas.setWidth(projectSize.width);
  // previewCanvas.setHeight(projectSize.height);
  previewCanvas.add(imageElem);
};

export const resetCanvas = (inCanvas) => {
  if (inCanvas) inCanvas.setZoom(1);
  const ele = getImageElement(inCanvas);
  if (inCanvas) inCanvas.remove(ele);
};

export const scaleProjectToFitIntoPreview = (inputCanvas, mode) => {
  const { isMagnifierModeOn, canvasViewMode, sizeSelectionDetails } =
    store.getState().project;
  const projectSize = getProjectCanvasSize(mode);

  let zoomToFitScale = 1;
  if (isMagnifierModeOn) {
    if (
      projectSize.width > MAXIMUM_CANVAS_SIZE ||
      projectSize.height > MAXIMUM_CANVAS_SIZE
    ) {
      zoomToFitScale = generateZoomToFitScaleFactorForMaximumCanvasSize(mode);
    }
  } else {
    zoomToFitScale = generateZoomToFitScaleFactor(mode);
  }
  if (canvasViewMode === HUMAN_VIEW) {
    zoomToFitScale = generateZoomToFitScaleFactorForHuman(
      mode,
      sizeSelectionDetails.selectedDimension.height
    );
  } else if (canvasViewMode === SOFA_VIEW) {
    zoomToFitScale = generateZoomToFitScaleFactorForSofa(
      mode,
      sizeSelectionDetails.selectedDimension.width
    );
  }
  let zoomedWidth = projectSize.width * zoomToFitScale;
  let zoomedHeight = projectSize.height * zoomToFitScale;

  const uiScale = checkForUIScale();
  inputCanvas.setZoom(zoomToFitScale * uiScale);
  inputCanvas.setWidth(zoomedWidth);
  inputCanvas.setHeight(zoomedHeight);

  inputCanvas.renderAll();
};

export const convertScale = (inZoomFactor) => {
  var scale = 1;
  if (inZoomFactor <= 0) {
    let backendScaleMax = 1;
    let backendSclaeMin = 0.25;
    let uiSclaeMin = -10;
    let uiScaleMax = 0;
    let scaleConversion =
      (backendScaleMax - backendSclaeMin) / (uiScaleMax - uiSclaeMin);
    scale = backendSclaeMin + scaleConversion * (inZoomFactor - uiSclaeMin);
  } else {
    let backendScaleMax = 2;
    let backendSclaeMin = 1;
    let uiSclaeMin = 0;
    let uiScaleMax = 10;
    let scaleConversion =
      (backendScaleMax - backendSclaeMin) / (uiScaleMax - uiSclaeMin);
    scale = backendSclaeMin + scaleConversion * (inZoomFactor - uiSclaeMin);
  }
  return scale;
};

export const getBorderElement = (inCanvas) => {
  let borderElement = null;
  inCanvas?.forEachObject((element) => {
    if (
      Object.prototype.hasOwnProperty.call(element, "layer") &&
      element.layer === "Border"
    ) {
      borderElement = element;
    }
  });
  return borderElement;
};

export const getImageElement = (inCanvas) => {
  let imageElement = null;
  inCanvas?.forEachObject((element) => {
    if (
      Object.prototype.hasOwnProperty.call(element, "layer") &&
      element.layer === "Image" &&
      !Object.prototype.hasOwnProperty.call(element, "id") // only signature images have id property set.both project image and signature image has layer's value set to "Image"
    ) {
      imageElement = element;
    }
  });
  return imageElement;
};

export const getSelectionElement = (inCanvas) => {
  let imageElement = null;
  inCanvas.forEachObject((element) => {
    if (
      Object.prototype.hasOwnProperty.call(element, "layer") &&
      element.layer === "Selection"
    ) {
      imageElement = element;
    }
  });
  return imageElement;
};

export const getSelectionFooterMaskElement = (inCanvas) => {
  let imageElement = null;
  inCanvas.forEachObject((element) => {
    if (
      Object.prototype.hasOwnProperty.call(element, "layer") &&
      element.layer === "SelectionFooterMask"
    ) {
      imageElement = element;
    }
  });
  return imageElement;
};

export const getSignaturePlaceholderElement = (inCanvas) => {
  let imageElement = null;
  inCanvas.forEachObject((element) => {
    if (
      Object.prototype.hasOwnProperty.call(element, "layer") &&
      element.layer === "SignaturePlaceholder"
    ) {
      imageElement = element;
    }
  });
  return imageElement;
};

export const getWhiteBackgroundElement = (inCanvas) => {
  let imageElement = null;
  inCanvas.forEachObject((element) => {
    if (
      Object.prototype.hasOwnProperty.call(element, "layer") &&
      element.layer === "WhiteBackground"
    ) {
      imageElement = element;
    }
  });
  return imageElement;
};

export const getProjectCanvasSize = (mode) => {
  let { projectCropRect, originalProjectSize, projectImageScale } =
    store.getState().project;

  if (mode === PROJECT_PREVIEW_MODE_SIZE_SELECTION) {
    const { width, height } = projectCropRect;
    const scaledImageSize = {
      width: originalProjectSize.width * projectImageScale,
      height: originalProjectSize.height * projectImageScale,
    };
    var pWidth = width > scaledImageSize.width ? width : scaledImageSize.width;
    var pHeight =
      height > scaledImageSize.height ? height : scaledImageSize.height;

    return Size(pWidth, pHeight);
  } else if (mode === PROJECT_PREVIEW_MODE_INITIAL) {
    return originalProjectSize;
  } else {
    const uiScale = checkForUIScale();
    const projectSize = finalProjectSize();
    const newSize = {
      width: projectSize.width * uiScale,
      height: projectSize.height * uiScale,
    };
    return newSize;
  }
};

const checkForUIScale = () => {
  let uiScale = 1;
  let { isMagnifierModeOn } = store.getState().project;
  if (isMagnifierModeOn) {
    const projectSize = finalProjectSize();
    const magnifierViewSize = getMagnifierVisibleCanvasCoords();
    if (
      projectSize.width < magnifierViewSize.width ||
      projectSize.height < magnifierViewSize.height
    ) {
      uiScale = magnifierViewSize.width / projectSize.width;
    }
  }
  return uiScale;
};

const getCropRectCoordinatesInCanvas = () => {
  const { projectCropRect } = store.getState().project;
  const { left, top, width, height } = projectCropRect;
  const endX = left + width;
  const endY = top + height;
  return {
    left,
    top,
    right: endX,
    bottom: endY,
  };
};

const updateCropRect = (e) => {
  // current position
  const { left: imageCanvasCurrentLeft, top: imageCanvasCurrentTop } = {
    left: e.target.left,
    top: e.target.top,
  };

  // center aligned one
  const { left: defaultCropCanvasLeft, top: defaultCropCanvasTop } =
    getCropDefaultPosition();

  let { projectCropRect } = store.getState().project;
  let updatedCropRectX = defaultCropCanvasLeft - imageCanvasCurrentLeft;
  let updatedCropRectY = defaultCropCanvasTop - imageCanvasCurrentTop;

  let shouldUpdateCropRect = true;
  if (updatedCropRectX < 0) {
    e.target.set({ left: defaultCropCanvasLeft }).setCoords();
    shouldUpdateCropRect = false;
  }
  if (updatedCropRectY < 0) {
    e.target.set({ top: defaultCropCanvasTop }).setCoords();
    shouldUpdateCropRect = false;
  }
  if (updatedCropRectX > defaultCropCanvasLeft * 2) {
    e.target.set({ left: -defaultCropCanvasLeft }).setCoords();
    shouldUpdateCropRect = false;
  }
  if (updatedCropRectY > defaultCropCanvasTop * 2) {
    e.target.set({ top: -defaultCropCanvasTop }).setCoords();
    shouldUpdateCropRect = false;
  }

  if (shouldUpdateCropRect) {
    const convertedCropRect = Rect(
      updatedCropRectX,
      updatedCropRectY,
      projectCropRect.width,
      projectCropRect.height
    );
    store.dispatch(setProjectCropRect(convertedCropRect));
  }
};

export const getPreviewBoundary = () => {
  return {
    width: window.innerWidth - LEFT_SIDEBAR_WIDTH - IMAGE_ELEMENT_OFFSET,
    height: window.innerHeight - TOP_BAR_HEIGHT - IMAGE_ELEMENT_OFFSET,
  };
};

export const magnifierScaleFactor = (inCanvasSize) => {
  const previewBoundary = getPreviewBoundary();

  const wAR = inCanvasSize.width / previewBoundary.width;
  const hAR = inCanvasSize.height / previewBoundary.height;

  let scale = 1;
  if (wAR > hAR) {
    scale = previewBoundary.width / inCanvasSize.width;
  } else {
    scale = previewBoundary.height / inCanvasSize.height;
  }
  return scale;
};

export const generateZoomToFitScaleFactorForHuman = (
  mode,
  posterHeightInInches
) => {
  const projectSize = getProjectCanvasSize(mode);
  const humanSize = getHumanViewSize();

  const posterHeightInPixels =
    (posterHeightInInches * humanSize.height) / HUMAN_HEIGHT_INCHES;

  return posterHeightInPixels / projectSize.height;
};

export const generateZoomToFitScaleFactorForSofa = (
  mode,
  posterWidthInInches
) => {
  const projectSize = getProjectCanvasSize(mode);
  const sofaSize = getSofaViewSize();

  const posterWidthInPixels =
    (posterWidthInInches * sofaSize.width) / SOFA_WIDTH_INCHES;

  return posterWidthInPixels / projectSize.width;
};

export const generateZoomToFitScaleFactor = (mode) => {
  const previewBoundary = getPreviewBoundary();
  const projectSize = getProjectCanvasSize(mode);

  const wAR = projectSize.width / previewBoundary.width;
  const hAR = projectSize.height / previewBoundary.height;

  let scale = 1;
  if (wAR > hAR) {
    scale = previewBoundary.width / projectSize.width;
  } else {
    scale = previewBoundary.height / projectSize.height;
  }
  return scale;
};

export const generateZoomToFitScaleFactorForMaximumCanvasSize = (mode) => {
  const projectSize = getProjectCanvasSize(mode);

  let scale = 1;
  if (projectSize.width > projectSize.height) {
    scale = MAXIMUM_CANVAS_SIZE / projectSize.width;
  } else {
    scale = MAXIMUM_CANVAS_SIZE / projectSize.height;
  }
  return scale;
};

//=================================================================================================================================

//=================================================================================================================================
//Editing selection layer
//=================================================================================================================================

export const addCanvasEditedNotifications = (inputCanvas) => {
  inputCanvas.on({
    "object:moving": (e) => handleCropRectMovement(e),
  });
  inputCanvas.on({
    "object:modified": (e) => handleCropRectMovement(e),
  });
};

const handleCropRectMovement = (e) => {
  updateCropRect(e);
};

//=================================================================================================================================

export const getZoomedFrameCanvasSize = () => {
  // const scale = gg(2);
  // const projectSize = getProjectCanvasSize(2, null);
  // const zoomedWidth = projectSize.width * scale;
  // const zoomedHeight = projectSize.height * scale;
  // return Size(zoomedWidth, zoomedHeight);
};

export const frameTypeChanged = (framedCanvas) => {};

//=================================================================================================================================

// const fitImageToCropRect = (imageCanvas) => {
//   const { uploadedImageData, projectCropRect } = store.getState().project;
//   const { lowResolutionSize } = uploadedImageData;
//   const actualImageWidth = lowResolutionSize.width;
//   const actualImageHeight = lowResolutionSize.height;

//   const cropRectWidth = projectCropRect.width;
//   const cropRectHeight = projectCropRect.height;

//   let scale;
//   const widthScale = cropRectWidth / actualImageWidth;
//   const heightScale = cropRectHeight / actualImageHeight;
//   if (widthScale < heightScale) {
//     scale = widthScale;
//   } else {
//     scale = heightScale;
//   }

//   imageCanvas.forEachObject((ele) => {
//     if (
//       Object.prototype.hasOwnProperty.call(ele, "layer") &&
//       ele.layer === "Image"
//     ) {
//       const newWidth = ele.width * scale;
//       const newHeight = ele.height * scale;
//       ele.scaleToWidth(newWidth);
//       ele.scaleToHeight(newHeight);
//       imageCanvas.viewportCenterObject(ele);
//       updateCropRect(PROJECT_PREVIEW_MODE_SIZE_SELECTION, imageCanvas);
//     }
//   });
// };

// const resetFitImage = (imageCanvas) => {
//   let { lowResolutionSize } = store.getState().project.uploadedImageData;
//   const actualImageWidth = lowResolutionSize.width;
//   const actualImageHeight = lowResolutionSize.height;

//   const actualZoom = imageCanvas.getZoom();
//   imageCanvas.forEachObject((ele) => {
//     if (
//       Object.prototype.hasOwnProperty.call(ele, "layer") &&
//       ele.layer === "Image"
//     ) {
//       imageCanvas.setZoom(1);
//       ele.scaleToWidth(actualImageWidth);
//       ele.scaleToHeight(actualImageHeight);

//       imageCanvas.setZoom(actualZoom);
//       imageCanvas.viewportCenterObject(ele);

//       updateCropRect(PROJECT_PREVIEW_MODE_SIZE_SELECTION, imageCanvas);
//     }
//   });
// };

export const resetSizeSelection = () => {
  const { uploadedImageData } = store.getState().project;
  const { lowResolutionSize, imageScale } = uploadedImageData;
  const actualImageWidth = lowResolutionSize.width * imageScale;
  const actualImageHeight = lowResolutionSize.height * imageScale;

  const cropDimension = {
    width: getPixelsToInches(actualImageWidth).toFixed(1),
    height: getPixelsToInches(actualImageHeight).toFixed(1),
  };
  store.dispatch(
    setSizeSelectionDetails({ id: "selectedDimension", value: cropDimension })
  );
  store.dispatch(setSizeSelectionDetails({ id: "selectedId", value: null }));

  const cropRect = Rect(
    0,
    0,
    lowResolutionSize.width,
    lowResolutionSize.height
  );
  store.dispatch(setProjectCropRect(cropRect));
};

/*
export const updateProjectBorder = (croppedCanvas) => {
  const { borderWidth, sizeSelectionDetails } = store.getState().project;
  const { selectedDimension } = sizeSelectionDetails;

  laodProjectPreview(PROJECT_PREVIEW_MODE_SIZE_SELECTION, (inCanvas) => {
    const offscreenCanvas = inCanvas;
    didChangeCropSizeSelection(
      {
        width: selectedDimension.width - borderWidth * 2,
        height: selectedDimension.height - borderWidth * 2,
      },
      offscreenCanvas
    );
    const ele = getImageElement(croppedCanvas);
    croppedCanvas.remove(ele);
    loadProjectImageContent(PROJECT_PREVIEW_MODE_CROPPED, (imageElem) => {
      loadProjectImageContentInPreview(
        PROJECT_PREVIEW_MODE_CROPPED,
        imageElem,
        croppedCanvas
      );
      scaleProjectToFitIntoPreview(croppedCanvas, PROJECT_PREVIEW_MODE_CROPPED);
      croppedCanvas.viewportCenterObject(imageElem);
      croppedCanvas.renderAll();
    });
  });
};
*/

export const didChangeBorderSelection = (croppedCanvas) => {
  const { project } = store.getState();
  const { borderWidth, projectCropRect } = project;
  const { left, top, width: cropWidth, height: cropHeight } = projectCropRect;
  const borderThicknessInPixels = getScaledInchesToPixels(borderWidth);

  let projectSizeInPixels = finalProjectSize();
  const newSizeInPixels = insetSize(
    projectSizeInPixels,
    borderThicknessInPixels
  );
  const oldWidth = cropWidth;
  const newWidth = newSizeInPixels.width;
  const oldHeight = cropHeight;
  const newHeight = newSizeInPixels.height;

  const newLeft = left + (oldWidth - newWidth) / 2;
  const newTop = top + (oldHeight - newHeight) / 2;

  const newRect = Rect(
    newLeft,
    newTop,
    newSizeInPixels.width,
    newSizeInPixels.height
  );

  //0: Image covers projectRect itself. but frame is kept over it.
  //1: Image covers inside area exlcuding frame. width has white
  //2: Image covers inside area exlcuding frame. in case of 1 there was white, we will cover white.
  //Due to aspect ratio height will be cropped.
  let imageFillType = 2;
  const imageEle = getImageElement(croppedCanvas);
  const { width, height } = imageEle;
  var scale = 1;
  if (imageFillType === 1) {
    //Aspect Fit
    scale =
      newRect.width < newRect.height
        ? newRect.width / width
        : newRect.height / height;
  } else if (imageFillType === 2) {
    scale =
      newRect.width > newRect.height
        ? newRect.width / width
        : newRect.height / height;
  }

  imageEle.scale(scale).setCoords();
  store.dispatch(setBorderScale(scale));

  const scaledImgWidth = imageEle.getScaledWidth();
  const scaledImgHeight = imageEle.getScaledHeight();

  imageEle.left = (oldWidth - scaledImgWidth) / 2;
  imageEle.top = (oldHeight - scaledImgHeight) / 2;

  imageEle.setCoords();

  const borderElement = getBorderElement(croppedCanvas);
  croppedCanvas.remove(borderElement);

  const rect = new fabric.Rect({
    layer: "Border",
    height: oldHeight,
    width: oldWidth,
    left: -borderThicknessInPixels,
    top: -borderThicknessInPixels,
    selectable: false,
    fill: "rgba(0, 0, 0, 0)",
    strokeWidth: borderThicknessInPixels * 2,
    stroke: "white",
  });
  croppedCanvas.add(rect);

  croppedCanvas.renderAll();
};
