import { useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { string } from "../../../Localization";
import { showOrHideApiLoadingModal } from "../../../Store/Project/ModalSlice";
import { customiseSalesLink } from "../../../Utils/ApiHelper";
import Closebutton from "../../../Utils/Icons/close.svg";
import "./CustomiseLinkModal.scss";
import { setCanShowStatusModal } from "../../../Store/Project/ProjectSlice";
import StatusModal from "../../StatusModal/StatusModal";
import status_right from "../../../Utils/Icons/status_right.svg";
import { getHostUrl } from "../../../Utils/Helper";

const CustomiseLinkModal = (props) => {
  const { canShowStatusModal } = useSelector((state) => state.project);

  const [customLink, setCustomLink] = useState("");
  const [result, setResult] = useState("");
  const [error, setError] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const { isOpen, onClose, projectToken, setLink } = props;
  const dispatch = useDispatch();

  const handleCustomiseLink = () => {
    setDisableBtn(true);
    if (customLink != "") {
      dispatch(showOrHideApiLoadingModal(true));
      setError(false);

      const payload = {
        new_token: customLink,
      };
      customiseSalesLink(projectToken, payload)
        .then((res) => {
          if (res?.data?.result == "success") {
            setError(false);
            onClose();
            dispatch(setCanShowStatusModal(true));
          }
          setLink(res?.data?.data?.project?.public_view_url);
          setResult(res?.data?.data?.project?.public_view_url);
          dispatch(showOrHideApiLoadingModal(false));
        })
        .catch((res) => {
          if (res?.response?.status == 422) {
            setError(true);
            setResult("");
          }
          dispatch(showOrHideApiLoadingModal(false));
        });
    }
  };

  const closeHandler = () => {
    dispatch(setCanShowStatusModal(false));
    setCustomLink("");
  };

  const handleClose = () => {
    setCustomLink("");
    setError(false);
    onClose();
  };

  return (
    <>
      {" "}
      <Modal
        show={isOpen}
        animation={false}
        className="customise-container-wrap"
      >
        <ModalBody className="customise-container-modal">
          <div className="body">
            <div className="close-btn-container">
              <img
                className="close-btn"
                src={Closebutton}
                onClick={handleClose}
                alt="Close"
              />
            </div>

            <div className="customise_link_modal_contant">
              <div className="d-flex justify-content-center">
                <span className="title d-block ">{string.CUSTOMIZE_LINK}</span>
              </div>
              <div className="customise_link_subtext">
                <span className="customise_link_text">
                  {string.CUSTOMIZE_LINK_TEXT}
                </span>
              </div>

              <div className="customise_link_input_field">
                <input
                  type="text"
                  className="input_title"
                  placeholder="Enter custom path"
                  value={customLink}
                  onChange={(e) => {
                    setCustomLink(e.currentTarget.value);
                    setError(false);
                    setDisableBtn(false);
                    setResult("");
                  }}
                />
              </div>

              <div>
                {error ? (
                  <div className="error">{string.FAILED_CUSTOMISED_LINK}</div>
                ) : (
                  <div className="customise_link_url">
                    {getHostUrl() + "/saleslink/" + customLink}
                  </div>
                )}
              </div>

              <button
                className={
                  customLink == "" || disableBtn
                    ? "custom-link-button_disabled ms-2"
                    : "custom-link-button ms-2"
                }
                onClick={() => {
                  !disableBtn && handleCustomiseLink();
                }}
              >
                {string.APPLY}
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <StatusModal
        canShow={canShowStatusModal}
        stautsbackground={{ background: "#176267" }}
        statusimge={status_right}
        statusHeading={string.YOUR_LINK_WAS_UPDATED}
        statusDescription={`${string.YOUR_NEW_LINK}: ${result}`}
        closebackground={{ background: "#176267" }}
        closeData={{
          closeBtn: string.CLOSE,
          closeHandler: closeHandler,
        }}
      />
    </>
  );
};

export default CustomiseLinkModal;
