import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PAGE_PHOTO_PRINT_DETAILS,
  PAGE_PROJECT_SUMMARY,
} from "../../Utils/Constants";
import "./Home.scss";
import {
  handleFooterLinks,
  redirectToHpSignInPage,
  redirectToHpSignUpPage,
} from "../../Utils/Helper";
import { setCurrentUserDetails } from "../../Store/Project/UserSlice";
import { setShowGuideModal } from "../../Store/Project/PageSlice";
import { getCurrentUser } from "../../Utils/ApiHelper";
import Body from "../Body/Body";
import SideViewBar from "../SideViewBar/SideViewBar";
import LandingPage from "../LandingPage/LandingPage";
import ProjectSummary from "../ProjectSummary/ProjectSummary";
import PhotoPrintDetails from "../PhotoPrintDetails/PhotoPrintDetails";
import ModalGuide from "../Guide/ModalGuide/ModalGuide";
import WelcomePage from "../WelcomePage/WelcomePage";
import { sendGALoadedQuickStartGuide } from "../../Utils/GAHelper";
import TermsAndCondition from "../../Components/Modal/TermsAndCondition/TermsAndCondition";
import ReActivateAccount from "../ReActivateAccount/ReActivateAccount";

const Home = () => {
  const { showGuideModal, activePageIndex } = useSelector(
    (state) => state.page
  );
  const { currentUserDetails } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const [isUserPresent, setIsUserPresent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isBodyLoading, setIsBodyLoading] = useState(true);

  useEffect(() => {
    getCurrentUser()
      .then((res) => {
        dispatch(setCurrentUserDetails(res.data.data));
        setIsUserPresent(true);
        setIsLoading(false);
        const showQuickStartGuide = res.data.data.quick_start_guide;
        dispatch(setShowGuideModal(showQuickStartGuide));
        if (showQuickStartGuide) sendGALoadedQuickStartGuide();
      })
      .catch(function (error) {
        setIsUserPresent(false);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleFooterLinks();
  }, []);

  const goToSignIn = () => {
    redirectToHpSignInPage();
  };

  const goToSignUp = () => {
    redirectToHpSignUpPage();
  };

  const guideModalHandler = (modalvalue) => {
    dispatch(setShowGuideModal(modalvalue));
    localStorage.setItem("showQuickStartGuide", "false");
  };

  const handleBodyLoadingComplete = () => {
    setIsBodyLoading(false);
  };

  const getRenderedComponent = () => {
    switch (true) {
      case isLoading:
        return (
          <div className="home-screen-loader">
            <div className="upload-spinner"></div>
          </div>
        );

      case activePageIndex === PAGE_PROJECT_SUMMARY:
        return <ProjectSummary />;

      case activePageIndex === PAGE_PHOTO_PRINT_DETAILS:
        return <PhotoPrintDetails />;

      case isUserPresent:
        if (currentUserDetails.beta_user && !currentUserDetails.unsubscribed) {
          return (
            <>
              {!isBodyLoading && (
                <TermsAndCondition
                  canShow={!currentUserDetails.accepted_legal_terms}
                />
              )}

              <div className="order_scroll">
                {!isBodyLoading && (
                  <ModalGuide
                    modahandler={guideModalHandler}
                    isOpen={showGuideModal}
                  />
                )}
                <SideViewBar />
                <Body onLoadingComplete={handleBodyLoadingComplete} />
              </div>
            </>
          );
        } else if (currentUserDetails.unsubscribed) {
          return <ReActivateAccount />;
        } else {
          return <WelcomePage />;
        }

      default:
        return <LandingPage goToSignIn={goToSignIn} goToSignUp={goToSignUp} />;
    }
  };

  return getRenderedComponent();
};

export default Home;
