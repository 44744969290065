import { getProjectDetails } from "./ApiHelper";
import { store } from "../Store/Store";
import { setActivePageIndex, setActiveStep } from "../Store/Project/PageSlice";
import {
  NO_OF_COPIES,
  PAGE_CREATE_PROJECT,
  SIGNATURE,
  UNIT_SYSTEM_INCHES,
  UNIT_SYSTEM_MM,
  PROJECT_PREVIEW_MODE_CROPPED,
  PROJECT_PREVIEW_MODE_SIZE_SELECTION,
  PROJECT_PREVIEW_MODE_PERSONALIZATION,
  PROJECT_PREVIEW_MODE_INITIAL,
  PAGE_HOME,
  PROJECT_PREVIEW_MODE_CERTIFICATE,
  PRICE_CURRENCY_GBP,
  PRICE_CURRENCY_GBP_ID,
  STEP_ONE_PHOTO_DETAILS,
  STEP_THREE_CERTIFICATE,
  STEP_TWO_PERSONALISATION,
  PROFILE,
} from "./Constants";
import {
  resetAllProjectDetails,
  setActivePreviewMode,
  setBorderScale,
  setBorderWidth,
  setImageDimensions,
  setPreviewImage,
  setProjectCropRect,
  setProjectDetails,
  setProjectImageScale,
  setPriceInfo,
  setSelectedMediaTypeId,
  setSelectedUnitSystem,
  setSizeSelectionDetails,
  setProjectToken,
  setUploadedImageData,
  setSizeApplied,
  setSelectedSuperResolutionScale,
  setMaxSuperResolutionScale,
} from "../Store/Project/ProjectSlice";
import {
  activeMediaTypeData,
  convertSelectedUnitSystem,
  getCropRectDimension,
  getSelectedMediaTypeData,
  getSelectedSizeInInches,
  Size,
} from "./Helper";
import {
  setEditionSignaturePlot,
  getLimitedEditionCoords,
  getSignatureCoords,
} from "./PersonalizationHelper";
import {
  resetLimitedEdition,
  setCanShowNoOfCopies,
  setFontFamily,
  setIsLimited,
  setLimitedEditionSelected,
  setNoOfCopies,
  setNoOfCopiesPlot,
} from "../Store/Project/limitedEditionSlice";
import {
  resetSignatureDetails,
  setCanShowSignature,
  setCoordsAltered,
  setSignatureImage,
  setSignatureToken,
  setSignatureType,
} from "../Store/Project/signatureSlice";
import {
  fetchMediaTypesForDraftFlow,
  loadQRCode,
  setHighlightedBorderValues,
  setHighlightedPrintSizeValues,
  setUpVirtualRefImages,
} from "./projectHelper";
import { generatePreviewImage } from "./CanvasDownload";
import {
  setNotSupportedMediaModal,
  showOrHideApiLoadingModal,
} from "../Store/Project/ModalSlice";

export const handleDraft = (projectToken) => {
  store.dispatch(showOrHideApiLoadingModal(true));
  loadQRCode();
  fetchMediaTypesForDraftFlow().then(() => {
    continueDraftFlow(projectToken);
  });
};

const continueDraftFlow = (projectToken) => {
  getProjectDetails(projectToken)
    .then((response) => {
      const { project, price, user_photo, placing } = response?.data?.data;
      let previewMode = PROJECT_PREVIEW_MODE_INITIAL;
      let activeStep = STEP_ONE_PHOTO_DETAILS;
      let selectedMediaNotSupported = false;

      const {
        title,
        caption,
        product_size_id,
        media_type_id,
        scale_ratio,
        print_unit_system,
        borderWidth,
        border_scale,
        designer_options,
        limited_edition,
        x_offset,
        y_offset,
        no_of_copies,
        size_applied,
        enhance_image,
        super_resolution_scale,
      } = project;

      //set basic project details
      const {
        image_dimensions,
        image_url,
        scale,
        thumbnail_url,
        project_token,
        ai_image,
        sofa_ref_image,
        human_ref_image,
        max_super_resolution_scale,
      } = user_photo;

      const superResolutionFlow = enhance_image;

      setUpVirtualRefImages(sofa_ref_image, human_ref_image);

      store.dispatch(setProjectToken(project_token));

      store.dispatch(
        setProjectDetails({
          id: "isAiImage",
          value: ai_image,
        })
      );
      store.dispatch(
        setUploadedImageData({
          originalImageURL: image_url,
          imageURL: thumbnail_url,
          lowResolutionSize: Size(
            image_dimensions.thumbnail_width,
            image_dimensions.thumbnail_height
          ),
          imageScale: scale,
        })
      );

      //set STEP 1 details:
      store.dispatch(
        setProjectDetails({
          id: "title",
          value: title === "Untitled" ? "" : title,
        })
      );
      store.dispatch(
        setProjectDetails({
          id: "description",
          value: caption === "No description is Available" ? "" : caption,
        })
      );

      if (media_type_id) {
        store.dispatch(setSelectedMediaTypeId(parseInt(media_type_id)));
        // } else {
        previewMode = PROJECT_PREVIEW_MODE_SIZE_SELECTION;
      }

      store.dispatch(setSizeApplied(size_applied));

      if (print_unit_system)
        store.dispatch(
          setSelectedUnitSystem(
            print_unit_system === "imperial"
              ? UNIT_SYSTEM_INCHES
              : UNIT_SYSTEM_MM
          )
        );

      //borderwidth is always in inches
      if (borderWidth) store.dispatch(setBorderWidth(parseInt(borderWidth)));
      if (border_scale) store.dispatch(setBorderScale(border_scale));
      setHighlightedBorderValues();

      if (super_resolution_scale) {
        store.dispatch(setSelectedSuperResolutionScale(super_resolution_scale));
      }
      if (max_super_resolution_scale) {
        store.dispatch(setMaxSuperResolutionScale(max_super_resolution_scale));
      }

      if (product_size_id) {
        const selectedMediaTypeData = getSelectedMediaTypeData();
        const mediaTypeSupported = selectedMediaTypeData
          ? selectedMediaTypeData.active
          : true;

        const mediaType = mediaTypeSupported
          ? selectedMediaTypeData
          : activeMediaTypeData()[0];

        if (!mediaTypeSupported) {
          selectedMediaNotSupported = true;
          store.dispatch(
            setNotSupportedMediaModal({
              show: true,
              mediaName: selectedMediaTypeData.name,
            })
          );
        } else {
          store.dispatch(setImageDimensions(mediaType["product_sizes"]));

          const cropDimension = getCropRectDimension(
            x_offset,
            y_offset,
            parseInt(product_size_id)
          );
          store.dispatch(setProjectCropRect(cropDimension));

          if (superResolutionFlow) {
            const imageAR =
              image_dimensions.thumbnail_width /
              image_dimensions.thumbnail_height;
            const printAR = cropDimension.width / cropDimension.height;
            const scale =
              printAR > imageAR
                ? cropDimension.width / image_dimensions.thumbnail_width
                : cropDimension.height / image_dimensions.thumbnail_height;
            store.dispatch(setProjectImageScale(scale));
          } else {
            store.dispatch(setProjectImageScale(scale_ratio));
          }
          store.dispatch(
            setSizeSelectionDetails({
              id: "selectedId",
              value: parseInt(product_size_id),
            })
          );

          store.dispatch(
            setSizeSelectionDetails({
              id: "selectedDimension",
              value: getSelectedSizeInInches(parseInt(product_size_id)),
            })
          );

          setHighlightedPrintSizeValues();
        }
        if (product_size_id && size_applied) {
          previewMode = PROJECT_PREVIEW_MODE_CROPPED;
        }
      }

      if (price) {
        store.dispatch(setPriceInfo({ id: "income", value: price.income }));
        store.dispatch(
          setPriceInfo({ id: "currency", value: price.currency_type })
        );
      }

      //STEP- 2 Personalization
      if (designer_options) {
        if (designer_options.signature) {
          let object = {
            left: parseInt(designer_options.signature.x_offset) / scale,
            top: parseInt(designer_options.signature.y_offset) / scale,
            width: parseInt(designer_options.signature.width) / scale,
            height: parseInt(designer_options.signature.height) / scale,
          };
          setEditionSignaturePlot(object, SIGNATURE, scale);
          store.dispatch(setSignatureImage(placing.signature.image));
          store.dispatch(setSignatureType(placing.signature.signature_type));
          store.dispatch(setSignatureToken(placing.signature.token));
          store.dispatch(setCanShowSignature(true));
          // to retain the signature coords for profile type
          if (placing.signature.signature_type === PROFILE) {
            store.dispatch(setCoordsAltered(true));
          }
        } else {
          store.dispatch(setCanShowSignature(false));
        }
        if (designer_options.edition) {
          let object = {
            width: parseInt(designer_options.edition.width) / scale,
            height: parseInt(designer_options.edition.height) / scale,
            left: parseInt(designer_options.edition.x_offset) / scale,
            top: parseInt(designer_options.edition.y_offset) / scale,
          };
          setEditionSignaturePlot(object, NO_OF_COPIES, scale);
          store.dispatch(
            setNoOfCopiesPlot({
              fontSize: parseInt(designer_options.edition.font_size) / scale,
            })
          );
          store.dispatch(
            setFontFamily({
              type: designer_options.edition.font_type,
              family: designer_options.edition.font_family,
            })
          );
          store.dispatch(setCanShowNoOfCopies(true));
        }
        // possible values of "limited_edition" are "true", "false" and ''( '' when user has not selected any option yet)
        if (limited_edition) {
          const isLimited =
            limited_edition.toLowerCase() === "true" ? true : false;
          store.dispatch(setIsLimited(isLimited));
          store.dispatch(setLimitedEditionSelected(true));
        }

        const copies = parseInt(no_of_copies) < 1 ? 1 : parseInt(no_of_copies);
        store.dispatch(setNoOfCopies(copies));

        if (designer_options?.signature && limited_edition) {
          previewMode = PROJECT_PREVIEW_MODE_CERTIFICATE;
          activeStep = STEP_THREE_CERTIFICATE;
          generatePreviewImage().then((image) => {
            store.dispatch(setPreviewImage(image));
          });
        } else if (designer_options?.signature || limited_edition) {
          previewMode = PROJECT_PREVIEW_MODE_PERSONALIZATION;
          activeStep = STEP_TWO_PERSONALISATION;
        }
      }

      if (selectedMediaNotSupported) {
        // go to step1, size, media, price is reset
        previewMode =
          previewMode > PROJECT_PREVIEW_MODE_SIZE_SELECTION
            ? PROJECT_PREVIEW_MODE_SIZE_SELECTION
            : previewMode;

        store.dispatch(setActivePreviewMode(1));
        store.dispatch(setActiveStep(STEP_ONE_PHOTO_DETAILS));
      } else {
        store.dispatch(setActivePreviewMode(previewMode));
        store.dispatch(setActiveStep(activeStep));
      }

      store.dispatch(showOrHideApiLoadingModal(false));
      store.dispatch(setActivePageIndex(PAGE_CREATE_PROJECT));
    })
    .catch((e) => {});
};

export const resetAndRedirectToHomePage = () => {
  resetProjectDetails();
  store.dispatch(setActivePageIndex(PAGE_HOME));
};

export const resetProjectDetails = () => {
  store.dispatch(resetAllProjectDetails());
  store.dispatch(resetLimitedEdition());
  store.dispatch(resetSignatureDetails());
  store.dispatch(setActiveStep(STEP_ONE_PHOTO_DETAILS));
};

export const getProjectPayload = (inFinalPreview, isArtistProof) => {
  const { project, signature, limitedEdition } = store.getState();
  const {
    projectDetails,
    projectImageScale,
    sizeSelectionDetails,
    selectedMediaTypeId,
    priceInfo,
    projectCropRect,
    borderScale,
    borderWidth,
    selectedUnitSystem,
    uploadedImageData,
    sizeApplied,
  } = project;
  const { imageScale } = uploadedImageData;
  const { isLimited, canShowNoOfCopies, noOfCopies, limitedEditionSelected } =
    limitedEdition;
  const { signatureToken, canShowSignature } = signature;

  const { title, description, copyrightText } = projectDetails;
  const { selectedId } = sizeSelectionDetails;
  const { income, currency } = priceInfo;
  const currencyType =
    currency === PRICE_CURRENCY_GBP ? PRICE_CURRENCY_GBP_ID : "";

  const unitSystem = convertSelectedUnitSystem(selectedUnitSystem);

  let signatureData = null;
  if (canShowSignature) {
    const {
      x: signX,
      y: signY,
      width: signWidth,
      height: signHeight,
    } = getSignatureCoords();

    signatureData = {
      width: signWidth * imageScale,
      height: signHeight * imageScale,
      x_offset: signX * imageScale,
      y_offset: signY * imageScale,
      scale: "1.0",
    };
  }

  let editionData = null;
  if (canShowNoOfCopies) {
    const {
      x: ednX,
      y: ednY,
      width: ednWidth,
      height: ednHeight,
      fontSize,
    } = getLimitedEditionCoords();

    editionData = {
      width: ednWidth * imageScale,
      height: ednHeight * imageScale,
      x_offset: ednX * imageScale,
      y_offset: ednY * imageScale,
      scale: "1.0",
      font_type: limitedEdition.font.type,
      font_size: fontSize * imageScale,
    };
  }

  const data = {};
  if (signatureData) {
    data["signature"] = signatureData;
  }
  if (editionData) {
    data["edition"] = editionData;
  }
  const designerOptions = JSON.stringify(data);

  const formData = new FormData();
  formData.append("project[productSizeId]", selectedId);
  formData.append("project[sizeApplied]", sizeApplied);
  formData.append("project[mediaTypeId]", selectedMediaTypeId);
  formData.append("project[title]", title);
  formData.append("project[noOfCopies]", noOfCopies);
  formData.append("project[caption]", description);
  formData.append("project[copyrightText]", copyrightText);
  formData.append("project[scaleRatio]", projectImageScale);
  // skip sending project[limitedEdition] in request body if user has not selected option for LE yet.
  if (limitedEditionSelected)
    formData.append("project[limitedEdition]", isLimited);
  formData.append("project[previewImage]", inFinalPreview);
  formData.append("project[designerOptions]", designerOptions);
  formData.append(
    "placing[signatureToken]",
    signatureToken ? signatureToken : ""
  );
  formData.append("price[income]", income);
  formData.append("price[currencyType]", currencyType);
  formData.append("project[xOffset]", projectCropRect.left * imageScale);
  formData.append("project[yOffset]", projectCropRect.top * imageScale);
  formData.append("project[printUnitSystem]", unitSystem);
  formData.append("project[borderUnitSystem]", unitSystem);
  formData.append("project[borderWidth]", borderWidth);
  formData.append("project[borderScale]", borderScale);
  if (isArtistProof != null) {
    formData.append("project[artistProof]", isArtistProof);
  }
  return formData;
};
