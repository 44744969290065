import React from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { string } from "../../../Localization";
import { setNotSupportedMediaModal } from "../../../Store/Project/ModalSlice";
import Closebutton from "../../../Utils/Icons/close.svg";
import "./MediaNotSupported.scss";

const MediaNotSupported = () => {
  const { show, mediaName } = useSelector(
    (state) => state.modal.notSupportedMediaModal
  );

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(
      setNotSupportedMediaModal({
        show: false,
        mediaName: "",
      })
    );
  };

  return (
    <>
      <Modal show={show} animation={false} centered>
        <ModalBody className="not-supported-media-wrapper">
          <div>
            <div className="d-flex justify-content-end">
              <img
                className="close-btn cursor-pointer"
                src={Closebutton}
                onClick={handleClose}
                alt="Close"
              />
            </div>
            <div className="py-3 px-5 d-flex flex-column align-item-center">
              {/* <div className="d-flex justify-content-center mb-4">
                <span className="title d-block ">
                  {string.THANK_YOU_FOR_YOUR_ORDER}
                </span>
              </div> */}
              <span className="alert-text mb-3">
                {string.formatString(string.MEDIA_NOT_SUPPORTED, mediaName)}
              </span>
              <div className="d-flex justify-content-center">
                <Button className="close-button" onClick={handleClose}>
                  {string.CLOSE}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default MediaNotSupported;
