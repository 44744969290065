import { useNavigate } from "react-router-dom";
import { string } from "../../Localization";
import Logo from "../../Utils/Icons/hplogo.svg";
import "./HpLogoBlue.scss";

const HpLogoBlue = () => {
  const navaigate = useNavigate();
  return (
    <div className="logo-wrapper w-100 ">
      <img
        src={Logo}
        alt=""
        className="welcome-logo cursor-pointer"
        onClick={() => navaigate("/?utm_source=gallery")}
      />
      <span className="d-flex align-items-center beta-text">
        {string.AN_HP_DEVELOPMENT_BETA}
      </span>
    </div>
  );
};
// use this component only for photographer public page
// if used outside, update utm_source accordingly
export default HpLogoBlue;
