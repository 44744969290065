import React from "react";
import { string } from "../../../Localization";

const NextBtn = ({ nextSlide, modalhandler }) => {
  return (
    <>
      <div className="arrows">
        <div className="next-skip">
          <span className="next">
            <button className="skip-btn" onClick={() => modalhandler(false)}>
              {string.SKIP}
            </button>
          </span>
          <span className="next" onClick={nextSlide}>
            <button className="next-btn">{string.NEXT}</button>
          </span>
        </div>
      </div>
    </>
  );
};

export default NextBtn;
