import React, { useState } from "react";
import BasicModal from "../../../../Components/Modal/BasicModal/BasicModal";
import { useDispatch, useSelector } from "react-redux";
import { SetDefaultWallet } from "../../../../Utils/ApiHelper";
import { setCurrentUserDetails } from "../../../../Store/Project/UserSlice";
import { showOrHideApiLoadingModal } from "../../../../Store/Project/ModalSlice";
import "./SetWallet.scss";
import { string } from "../../../../Localization";

const SetWallet = (props) => {
  const { setRefresh, refresh } = props;

  const { wallet_active } = useSelector(
    (state) => state.user.currentUserDetails
  );
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const ActiveWallet = () => {
    dispatch(showOrHideApiLoadingModal(true));

    SetDefaultWallet()
      .then((res) => {
        if (res?.status == 200) {
          dispatch(showOrHideApiLoadingModal(false));
          dispatch(setCurrentUserDetails(res?.data?.data));
          setRefresh(!refresh);
          setShowModal(true);
        }
      })
      .catch((err) => {
        dispatch(showOrHideApiLoadingModal(false));
        console.log("err", err);
      });
  };

  return (
    <>
      <button
        onClick={() => {
          ActiveWallet();
        }}
        className={`publish-btn wallet-btn ${
          wallet_active ? "disabled" : "enabled"
        }`}
        disabled={wallet_active}
      >
        {string.SET_DEFAULT}
      </button>
      <BasicModal
        openModal={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
      >
        <div className="set_wallet_wrapper">
          <div className="set_wallet_text">{string.WALLET_SUCCESS_POPUP}</div>
          <button
            className="set_wallet wallet-btn"
            onClick={() => setShowModal(false)}
          >
            {string.CLOSE}
          </button>
        </div>
      </BasicModal>
    </>
  );
};

export default SetWallet;
