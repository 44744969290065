import { configureStore } from "@reduxjs/toolkit";
import ProjectSlice from "./Project/ProjectSlice";
import pageSlice from "./Project/PageSlice";
import signatureSlice from "./Project/signatureSlice";
import progressBarSlice from "./Project/ProgressBarSlice";
import modalSlice from "./Project/ModalSlice";
import UserSlice from "./Project/UserSlice";
import orderSlice from "./Project/OrderSlice";
import OrderManagementSlice from "./Project/OrderManagementSlice";
import limitedEditionSlice from "./Project/limitedEditionSlice";
import CacheSlice from "./Project/CacheSlice";
import AdminSlice from "./Project/AdminSlice";

export const store = configureStore({
  reducer: {
    project: ProjectSlice,
    cache: CacheSlice,
    page: pageSlice,
    signature: signatureSlice,
    limitedEdition: limitedEditionSlice,
    progressBar: progressBarSlice,
    modal: modalSlice,
    user: UserSlice,
    order: orderSlice,
    orderManagement: OrderManagementSlice,
    admin: AdminSlice,
  },
});
