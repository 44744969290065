import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Check from "../../../../Utils/Icons/check.svg";
import closebtn from "../../../../Utils/Icons/close.svg";
import "../../../../Components/StatusModal/StatusModal";
import "./ProfileErrorModal.scss";
import closeImg from "../../../../Utils/Icons/closeImg.svg";
import { getHostUrl } from "../../../../Utils/Helper";
import { string } from "../../../../Localization";

const ProfileErrorModal = (props) => {
  const [selectedItem, setSelectedItem] = useState("");

  const {
    canShow,
    closeBtnFtn,
    newUserName,
    setNewUserName,
    validUserName,
    setUserNameValid,
    userNameValid,
    suggestions,
    showValidateButton,
    setShowValidateButton,
  } = props;

  return (
    <>
      <div>
        <div className="dialog-container">
          <Modal show={canShow} className="dialog-container-status">
            <Modal.Body className="link_container">
              <div className="close-modal-btn">
                <img
                  src={closebtn}
                  onClick={closeBtnFtn}
                  style={{ cursor: "pointer" }}
                  alt="close"
                />
              </div>
              <div className="link_modal_conatiner">
                <div
                  className="link_image cursor-pointer"
                  onClick={closeBtnFtn}
                >
                  <img src={closeImg} />
                </div>
                <div className="link_modal_title">
                  {string.USER_NAME_ALREADY_EXISTS}
                </div>
                <div className="link_modal_subheading">
                  {string.ENTER_DIFFERENT_SUGGESTION}
                </div>

                <div className="link_modal_input position-relative">
                  <input
                    type="text"
                    className="link_input_title"
                    placeholder="Typre Here"
                    value={newUserName}
                    onChange={(e) => {
                      setNewUserName(
                        e.target.value?.replace(/[^a-zA-Z0-9]/g, "")
                      );
                      setUserNameValid(false);
                      if (
                        e.target.value.length > 3 &&
                        e.target.value.length <= 20
                      ) {
                        setShowValidateButton(true);
                      } else {
                        setShowValidateButton(false);
                      }
                    }}
                  />
                  {!showValidateButton && userNameValid && (
                    <div className="check-mark">
                      <img src={Check} alt="" />
                    </div>
                  )}
                  <div className="customise_link_url">
                    {string.MIN_MAX_CHARACTERS}{" "}
                  </div>
                  <div className="customise_link_url">
                    {string.EXAMPLE}:{getHostUrl() + "/gallery/" + newUserName}
                  </div>
                </div>

                <div className="link_modal_box">
                  {suggestions.map((suggestion) => (
                    <div
                      className={
                        suggestion && suggestion === selectedItem
                          ? "link_modal_boxes active-link-modal"
                          : "link_modal_boxes "
                      }
                      onClick={() => {
                        setSelectedItem(suggestion);
                        setNewUserName(suggestion);
                        setShowValidateButton(true);
                      }}
                    >
                      <div className="link_modal_text">{suggestion}</div>
                    </div>
                  ))}
                </div>
                <div className="link_btn">
                  <button
                    className={
                      showValidateButton ? "link_btns" : "link_btns_disabled"
                    }
                    onClick={() => validUserName()}
                  >
                    {string.VALIDATE}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default ProfileErrorModal;
