import React, { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { useDispatch, useSelector } from "react-redux";
import DialogBox from "../../DialogBox/DialogBox";
import {
  setBorderMeasurementStatus,
  setCanShowAlertModal,
  setDraftStatus,
} from "../../../Store/Project/ProjectSlice";
import {
  HANDLE_DRAFT_SIDEVIEWBAR,
  PROJECT_PREVIEW_MODE_CROPPED,
  SECTION_BORDER,
  SECTION_INFO,
  SECTION_PAPER,
  SECTION_PRICING,
  SECTION_PRINT_SIZE,
  STEP_ONE_PHOTO_DETAILS,
} from "../../../Utils/Constants";
import { applyPrintSize } from "../../../Utils/projectHelper";
import UploadImage from "../../../Components/Modal/UploadImage/UploadImage";

import "./Accordian.scss";
import { sendGALoadedModalSizeWasNotApplied } from "../../../Utils/GAHelper";
import { validateImage } from "../../../Utils/Helper";
import UpdateImage from "../UpdateImage/UpdateImage";
import { string } from "../../../Localization";

const AccordianComponent = (props) => {
  const { accordionData } = props;

  const dispatch = useDispatch();

  const {
    canShowAlertModal,
    activePreviewMode,
    sizeSelectionDetails,
    projectToken,
    incomeModal,
  } = useSelector((state) => state.project);
  const { activeStep } = useSelector((state) => state.page);

  const [activeTabId, setActiveTabId] = useState(accordionData[0].id);

  const handleClick = (elementId) => {
    if (elementId === SECTION_BORDER) {
      dispatch(setBorderMeasurementStatus(true));
    }
    if (
      elementId === SECTION_BORDER &&
      activePreviewMode !== PROJECT_PREVIEW_MODE_CROPPED &&
      sizeSelectionDetails.selectedId
    ) {
      dispatch(setCanShowAlertModal(true));
      sendGALoadedModalSizeWasNotApplied();
    }
    if (elementId === SECTION_PRICING) {
      document.getElementById("focus").focus();
    }
    if (elementId === SECTION_INFO) {
      document.getElementById("infofocus").focus();
    }
    if (elementId === SECTION_PRINT_SIZE || elementId === SECTION_PAPER) {
      dispatch(setDraftStatus(HANDLE_DRAFT_SIDEVIEWBAR));
    }
  };

  const submitHandler = () => {
    validateImage(projectToken, sizeSelectionDetails.selectedId)
      .then(() => {
        applyPrintSize();
      })
      .catch(() => {});
  };

  const cancelHandler = () => {
    dispatch(setCanShowAlertModal(false));
  };

  return (
    <div className="accordian-wrapper">
      {activeStep === STEP_ONE_PHOTO_DETAILS && <UpdateImage />}

      <div
        className={
          incomeModal ? "accordian-component adjusted" : "accordian-component"
        }
      >
        <Accordion
          allowZeroExpanded
          onChange={(uuids) => {
            handleClick(uuids[0]);
            setActiveTabId(uuids ? uuids[0] : null);
          }}
          preExpanded={[accordionData[0].id]}
        >
          {accordionData.map((element) => (
            <AccordionItem uuid={element.id}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <div className="accordian-header">
                    <div className="accordian-heading">
                      {element && element.heading}
                    </div>

                    <div
                      className={
                        element.id === SECTION_INFO
                          ? "accordian-subLine extra-margin"
                          : "accordian-subLine"
                      }
                      style={{ opacity: activeTabId !== element.id ? 1 : 0 }}
                    >
                      {element && element.content}
                    </div>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>

              {((element && element.id) === SECTION_BORDER &&
                activePreviewMode !== PROJECT_PREVIEW_MODE_CROPPED) || (
                <AccordionItemPanel>
                  {element && element.dropContent}
                </AccordionItemPanel>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
      <DialogBox
        canShow={canShowAlertModal}
        heading={string.THE_SIZE_WASNT_APPLIED}
        text1={string.THE_SIZE_YOU_SELECTED_WILL_NOT_BE_APPLIED_NOW}
        text2={string.DO_YOU_WANT_TO_CONFIRM_THE_SELECTED_SIZE}
        submitData={{
          submitText: string.APPLY_NOW,
          submitHandler: submitHandler,
          cancelBtn: string.CANCEL,
          cancelHandler: cancelHandler,
        }}
      />
      <UploadImage />
    </div>
  );
};

export default AccordianComponent;
