import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orderDashboardInfo: {},
};

const orderManagementSlice = createSlice({
  name: "orderManagement",
  initialState: initialState,
  reducers: {
    setOrderDashboardInfo(state, action) {
      state.orderDashboardInfo = action.payload;
    },
  },
});

export const { setOrderDashboardInfo } = orderManagementSlice.actions;
export default orderManagementSlice.reducer;
