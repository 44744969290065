import React, { useEffect, useState } from "react";
import "./UploadByAIModal.scss";
import { useDispatch, useSelector } from "react-redux";
import ProjectLoader from "../../Loaders/ProjectLoader/ProjectLoader";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  handleUploadClose,
  hideUploadProgress,
} from "../../../Utils/projectHelper";
import closeSvg from "../../../Utils/Icons/close.svg";
import { string } from "../../../Localization";
import ModalFooter from "../../ModalFooter/ModalFooter";
import uploadSvg from "../../../Utils/Icons/upload.svg";
import Dropzone from "react-dropzone";
import UploadSpinner from "../../Loaders/UploadSpinner/UploadSpinner";
import {
  showOrHideApiLoadingModal,
  showOrHideUploadAIFileModal,
  showOrHideUploadAIOptionModal,
  showOrHideUploadAISelectModal,
} from "../../../Store/Project/ModalSlice";
import {
  convertBytesToMegaBytes,
  formatDateTime,
  getFileExtension,
  getImageDimension,
  getPreviewUrlFromFile,
} from "../../../Utils/Helper";
import { MAX_SIZE_AI_IN_BYTES } from "../../../Utils/Constants";
import UploadDialog from "../UploadDialog/UploadDialog";
import { uploadImageAI } from "../../../Utils/ApiHelper";
import UploadResultModal from "../UploadResultModal/UploadResultModal";
import { setIsUploadCancelled } from "../../../Store/Project/ProjectSlice";
import {
  updateProgressBarValue,
  updateProgressBarVisibilty,
} from "../../../Store/Project/ProgressBarSlice";
import backArrowSvg from "../../../Utils/Icons/arrow_back.svg";

const UploadByAIModal = ({
  IsOpen,
  inputImageContent,
  uploadedImageDetails,
}) => {
  const dispatch = useDispatch();
  const { progressBarValue } = useSelector((state) => state.progressBar);

  const [uploadSuccess, setUploadSuccess] = useState(true);
  const [imageDetails, setImageDetails] = useState({});
  const [inputImage, setInputImage] = useState({});
  const [response, setResponse] = useState("");
  const [resultUrl, setResultUrl] = useState([]);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [imageName, setImageName] = useState(false);

  useEffect(() => {
    setImageDetails(uploadedImageDetails);
    setInputImage(inputImageContent);
    setImageName(uploadedImageDetails?.name);
  }, [uploadedImageDetails]);

  const resetFileSelection = () => {
    setImageDetails(null);
    setInputImage("");
    setUploadSuccess(true);
    dispatch(showOrHideUploadAIFileModal(false));
  };

  const resetFileAndNavigateBack = () => {
    resetFileSelection();
    dispatch(showOrHideUploadAIOptionModal(true));
  };

  const handleCloseClick = () => {
    dispatch(updateProgressBarValue(0));
    dispatch(showOrHideUploadAISelectModal(false));
    dispatch(showOrHideUploadAIOptionModal(false));
    setImageDetails(null);
    setInputImage("");
    setPrivacyPolicy(false);
  };

  const handleUploadButton = () => {
    const fileExtension = getFileExtension(inputImage?.name);
    if (fileExtension === "png") {
      if (inputImage.size <= MAX_SIZE_AI_IN_BYTES) {
        setResultUrl([]);
        const formData = new FormData();
        formData.append("image", inputImage);
        dispatch(setIsUploadCancelled(false));
        dispatch(updateProgressBarVisibilty(true));

        uploadImageAI(formData)
          .then((res) => {
            dispatch(updateProgressBarVisibilty(false));
            dispatch(showOrHideUploadAIFileModal(false));
            setResultUrl(res?.data?.data?.map((item) => item.url) || []);
            dispatch(showOrHideUploadAISelectModal(true));
            setUploadSuccess(true);
          })
          .catch((res) => {
            hideUploadProgress();
            let message = null;
            if (res) {
              if (res.message) {
                message = res.message;
              } else {
                message = res.response?.data?.error_message;
              }
            }
          });
      } else {
        dispatch(showOrHideApiLoadingModal(false));

        setResponse(string.FILE_SIZE_EXCEEDED);
        setUploadSuccess(false);
      }
    } else {
      dispatch(showOrHideApiLoadingModal(false));
      setResponse(string.UPLOAD_INVALID_FORMAT_MESSAGES);
      setUploadSuccess(false);
    }
  };

  const handleEditClick = () => {
    dispatch(updateProgressBarValue(0));
    dispatch(showOrHideUploadAIFileModal(true));
    dispatch(showOrHideUploadAISelectModal(false));
  };

  const handleBack = () => {
    dispatch(updateProgressBarValue(0));
    dispatch(showOrHideUploadAIFileModal(false));
    dispatch(showOrHideUploadAIOptionModal(true));
    setImageDetails(null);
    setInputImage("");
    setPrivacyPolicy(false);
  };

  const bodyClassName =
    progressBarValue === 100 && uploadSuccess
      ? "modal-body-container-spinner"
      : "modal-body-container";

  const headerClassName =
    progressBarValue === 100 && uploadSuccess
      ? "header-background header-background-spinner"
      : "header-background";
  const titleClassName =
    progressBarValue === 100 && uploadSuccess
      ? "search-result-back-containerc_upload-spinner"
      : "search-result-back-containerc_upload";

  return (
    <>
      {" "}
      <ProjectLoader />
      <Modal isOpen={IsOpen} className="upload-modal" centered>
        <ModalHeader className={headerClassName}>
          <div className={titleClassName} onClick={handleBack}>
            <img src={backArrowSvg} alt="back"></img>
            <div className="back-text_upload">Back</div>
          </div>
          <img
            alt="close"
            src={closeSvg}
            className="close-modal-svg"
            onClick={() => {
              handleUploadClose();
              resetFileSelection();
            }}
          />
          <>
            {progressBarValue === 100 &&
            uploadSuccess ? null : uploadSuccess ? (
              <>
                <ModalHeaderComponent uploadPhoto="Please upload your image to generate using AI" />
              </>
            ) : (
              <>
                <p className="modal-title-text">
                  {string.SOMETHING_WENT_WRONG}
                </p>
                <p className="modal-title-description">
                  {string.UPLOAD_FAILED_DESCRIPTION}
                </p>
              </>
            )}
          </>
        </ModalHeader>
        <ModalBody className={bodyClassName}>
          {progressBarValue === 100 && uploadSuccess ? (
            <UploadSpinner message="Processing" />
          ) : imageDetails && imageDetails.name ? (
            <>
              <UploadDialog
                uploadAction={handleUploadButton}
                imageDetails={imageDetails}
                uploadSuccess={uploadSuccess}
                onReset={resetFileAndNavigateBack}
                termsAndCondition={true}
                response={response}
              />
            </>
          ) : (
            <></>
          )}
        </ModalBody>
      </Modal>
      <UploadResultModal
        closeClick={handleCloseClick}
        handleEdit={handleEditClick}
        searchResults={resultUrl}
        privacyPolicy={privacyPolicy}
        setPrivacyPolicy={setPrivacyPolicy}
        imageName={imageName}
        handleSearchClick={handleUploadButton}
      />
    </>
  );
};
const ModalHeaderComponent = ({ uploadPhoto, photoDescription }) => {
  return (
    <>
      <p className="modal-title-text">{uploadPhoto}</p>
    </>
  );
};
export default UploadByAIModal;
