import React from "react";
import { string } from "../../Localization";

const DataNotFound = () => {
  return (
    <div>
      <span
        style={{ color: "white", fontSize: "25px" }}
        className="d-flex justify-content-center m-5"
      >
        {string.DATA_NOT_FOUND}
      </span>
    </div>
  );
};

export default DataNotFound;
