import { useEffect, useState } from "react";
import "./LandingPage.scss";
import Landing_image_1 from "../../Utils/Icons/landingpage_img.png";
import email_contact from "../../Utils/Icons/email_contact1.svg";
import whatsapp_contact from "../../Utils/Icons/whatsapp_contact1.svg";
import create_landing from "../../Utils/Icons/create_landing.jpg";
import accordian2 from "../../Utils/Icons/accordian2.jpg";
import accordian3 from "../../Utils/Icons/accordian3.jpg";
import accordian4 from "../../Utils/Icons/accordian4.jpg";
import landingsubimage1 from "../../Utils/Icons/landingsubimage1.jpg";
import landingsubimage2 from "../../Utils/Icons/landingsubimage2.jpg";
import landingsubimage3 from "../../Utils/Icons/landingsubimage3.jpg";
import landingsubimage4 from "../../Utils/Icons/landingsubimage4.jpg";
import landingsubimage5 from "../../Utils/Icons/landingsubimage5.jpg";
import pricing1 from "../../Utils/Icons/pricing1.jpg";
import pricing2 from "../../Utils/Icons/pricing2.jpg";
import menu from "../../Utils/Icons/hamburger.svg";
import menu_close from "../../Utils/Icons/hamburger_close.svg";
import {
  sendGAClickedHPEmailSupportLink,
  sendGAClickedMenuAbout,
  sendGAClickedMenuBlockchain,
  sendGAClickedMenuPersonalization,
  sendGAClickedMenuWorldwide,
  sendGALoadedLandingPage,
  sendGALoadedLandingPageViaQRCode,
} from "../../Utils/GAHelper";
import { string } from "../../Localization";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import LandingSubdivision from "./LandingSubdivision/LandingSubdivision";
import {
  LANDINGPAGE_ACCORDIAN1,
  LANDINGPAGE_ACCORDIAN2,
  LANDINGPAGE_ACCORDIAN3,
  LANDINGPAGE_ACCORDIAN4,
  LANDING_PAGE,
} from "../../Utils/Constants";
import LogoHP from "../../Components/LogoHP/LogoHP";
import Footer from "./Footer/Footer";
import LandingPlan from "./LandingPlan/LandingPlan";
import { useNavigate, useSearchParams } from "react-router-dom";

const LandingPage = (props) => {
  const { goToSignIn } = props;
  const [imageAccordian, setImageAccordian] = useState(create_landing);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [dropDown, setDropDown] = useState(true);
  const [activeSection, setActiveSection] = useState("LimitedEditions");

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const items = [
    {
      id: LANDINGPAGE_ACCORDIAN1,
      heading: string.LANDINGPAGE_ACCORDIAN_1,
      dropContent: string.LANDINGPAGE_ACCORDIAN_DESCRIPTION_1,
    },

    {
      id: LANDINGPAGE_ACCORDIAN2,
      heading: string.LANDINGPAGE_ACCORDIAN_2,
      dropContent: string.LANDINGPAGE_ACCORDIAN_DESCRIPTION_2,
    },

    {
      id: LANDINGPAGE_ACCORDIAN3,
      heading: string.LANDINGPAGE_ACCORDIAN_3,
      dropContent: string.LANDINGPAGE_ACCORDIAN_DESCRIPTION_3,
    },
    {
      id: LANDINGPAGE_ACCORDIAN4,
      heading: string.LANDINGPAGE_ACCORDIAN_4,
      dropContent: string.LANDINGPAGE_ACCORDIAN_DESCRIPTION_4,
    },
  ];

  const activeStyleMenu = {
    fontWeight: 700,
    color: "#FFFFFF",
    fontSize: "16px",
  };

  const activeStyleMenuDisabled = {
    fontWeight: 400,
    color: "#BDBDBD",
    fontSize: "16px",
  };

  const queryStrings = Object.fromEntries([...searchParams]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (checkIfLoadedViaQR()) {
      sendGALoadedLandingPageViaQRCode(queryStrings["utm_source"] ?? "");
    } else {
      sendGALoadedLandingPage(queryStrings["utm_source"] ?? "");
    }
  }, []);

  const activeStyle = {
    fontWeight: 700,
    color: "white",
  };

  const checkIfLoadedViaQR = () => {
    return (
      queryStrings["utm_source"] === LANDING_PAGE &&
      queryStrings["utm_medium"] === "qrcode"
    );
  };

  const image = (id) => {
    switch (id) {
      case LANDINGPAGE_ACCORDIAN1:
        setImageAccordian(create_landing);
        break;

      case LANDINGPAGE_ACCORDIAN2:
        setImageAccordian(accordian2);
        break;

      case LANDINGPAGE_ACCORDIAN3:
        setImageAccordian(accordian3);
        break;

      case LANDINGPAGE_ACCORDIAN4:
        setImageAccordian(accordian4);
        break;
      default:
        break;
    }
  };
  const utm_source = queryStrings["utm_source"]
    ? queryStrings["utm_source"]
    : "";
  return (
    <div className="landing_page_container">
      <div className="header_main">
        <div className="header">
          <div className="header-left">
            <div className="header-logo">
              <LogoHP />
            </div>

            <div className="header-links">
              <a
                href="#LimitedEditions"
                onClick={() => {
                  setActiveSection("LimitedEditions");
                  sendGAClickedMenuBlockchain(queryStrings["utm_source"] ?? "");
                }}
                style={activeSection === "LimitedEditions" ? activeStyle : {}}
              >
                {string.WHAT_IS_LIMITED_EDITIONS}
              </a>
              <a
                href="#KeyBenifit"
                onClick={() => {
                  setActiveSection("KeyBenifit");
                  sendGAClickedMenuWorldwide(queryStrings["utm_source"] ?? "");
                }}
                style={activeSection === "KeyBenifit" ? activeStyle : {}}
              >
                {string.KEY_BENEFITS}
              </a>
              <a
                href="#GetStarted"
                onClick={() => {
                  setActiveSection("GetStarted");
                  sendGAClickedMenuPersonalization(
                    queryStrings["utm_source"] ?? ""
                  );
                }}
                style={activeSection === "GetStarted" ? activeStyle : {}}
              >
                {string.GETTING_STARTED}
              </a>
              <a
                href="#pricing"
                onClick={() => {
                  setActiveSection("pricing");
                  sendGAClickedMenuAbout(queryStrings["utm_source"] ?? "");
                }}
                style={activeSection === "pricing" ? activeStyle : {}}
              >
                {string.PRICING}
              </a>
              <a
                className="cursor-pointer"
                onClick={() => {
                  setActiveSection("gallery");
                  navigate("/gallery?utm_source=landing_page");
                }}
                style={activeSection === "gallery" ? activeStyle : {}}
              >
                {string.PUBLIC_GALLERY1}
              </a>
            </div>
          </div>

          <div className="d-flex align-tems-center">
            <div class="header-right me-3">
              <a
                href={"/photographer_sign_in?gtm_source=" + utm_source}
                style={{ color: "white" }}
              >
                {string.LOGIN}
              </a>
            </div>
            <div class="signup header-get-started">
              <a
                href={"/photographer_sign_up?gtm_source=" + utm_source}
                style={{ textDecoration: "none", color: "white" }}
              >
                {string.GET_STARTED}
              </a>
            </div>
          </div>
          <div
            className="header_meanu"
            onClick={() => {
              setDropDown(!dropDown);
            }}
          >
            <img
              src={dropDown ? menu : menu_close}
              className="hamburger"
              alt="hamburger"
            />
          </div>

          {!dropDown && (
            <>
              <div className="menu_drop_down_container">
                <div className="menu_links">
                  <div className="menu_links_content">
                    <a
                      href="#LimitedEditions"
                      onClick={() => {
                        setActiveSection("LimitedEditions");
                        sendGAClickedMenuBlockchain(
                          queryStrings["utm_source"] ?? ""
                        );
                        setDropDown(true);
                      }}
                      style={
                        activeSection === "LimitedEditions"
                          ? activeStyleMenu
                          : activeStyleMenuDisabled
                      }
                    >
                      {string.WHAT_IS_LIMITED_EDITIONS}
                    </a>
                  </div>

                  <div className="menu_links_content">
                    <a
                      href="#KeyBenifit"
                      onClick={() => {
                        setActiveSection("KeyBenifit");
                        sendGAClickedMenuWorldwide(
                          queryStrings["utm_source"] ?? ""
                        );
                        setDropDown(true);
                      }}
                      style={
                        activeSection === "KeyBenifit"
                          ? activeStyleMenu
                          : activeStyleMenuDisabled
                      }
                    >
                      {string.KEY_BENEFITS}
                    </a>
                  </div>

                  <div className="menu_links_content">
                    <a
                      href="#GetStarted"
                      onClick={() => {
                        setActiveSection("GetStarted");
                        sendGAClickedMenuPersonalization(
                          queryStrings["utm_source"] ?? ""
                        );
                        setDropDown(true);
                      }}
                      style={
                        activeSection === "GetStarted"
                          ? activeStyleMenu
                          : activeStyleMenuDisabled
                      }
                    >
                      {string.GETTING_STARTED}
                    </a>
                  </div>

                  <div className="menu_links_content">
                    <a
                      href="#pricing"
                      onClick={() => {
                        setActiveSection("pricing");
                        sendGAClickedMenuAbout(
                          queryStrings["utm_source"] ?? ""
                        );
                        setDropDown(true);
                      }}
                      style={
                        activeSection === "pricing"
                          ? activeStyleMenu
                          : activeStyleMenuDisabled
                      }
                    >
                      {string.PRICING}
                    </a>
                  </div>
                  <div className="menu_links_content">
                    <a
                      onClick={() => {
                        setActiveSection("gallery");
                        navigate("/gallery?utm_source=landing_page");
                      }}
                      className="cursor-pointer"
                      style={
                        activeSection === "gallery"
                          ? activeStyleMenu
                          : activeStyleMenuDisabled
                      }
                    >
                      {string.PUBLIC_GALLERY1}
                    </a>
                  </div>
                </div>

                <div className="login_menu">
                  <a
                    href={"/photographer_sign_in?gtm_source=" + utm_source}
                    style={{ color: "white" }}
                  >
                    {string.LOGIN}
                  </a>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="photographer_main">
        <div className="section_photographers">
          <div className="landing-page-section-text">
            {" "}
            <p className="heading">{string.HEY_ARTIST}</p>
            <div className="L-description">
              <p className="description">
                {string.HEY_PHOTOGRAPHERS_DESCRIPTION}
              </p>
            </div>
            <div class="signup">
              <a
                href={"/photographer_sign_up?gtm_source=" + utm_source}
                style={{ textDecoration: "none", color: "white" }}
              >
                {string.GET_STARTED}
              </a>
            </div>
          </div>
          <div className="img-container">
            <img
              className="Landing_image_2"
              src={Landing_image_1}
              alt="landing 2"
            ></img>
          </div>
        </div>
      </div>
      <div className="Limited_edition" data-section id="LimitedEditions">
        <div className="limited-edition-para">
          <div className="limited-edition-heading">
            {string.WHAT_IS_LIMITED_EDITIONS}
          </div>
          <div className="limited-edition-text">
            {string.WHAT_IS_LIMITED_EDITIONS_DESCRIPTION}
          </div>
        </div>
      </div>
      <div className="landing-container" data-section id="KeyBenifit">
        <div className="key-benefits">
          <div className="key-benefits-heading">{string.KEY_BENEFITS}</div>
          <div className="key-benefits-text">
            {string.KEY_BENEFITS_DESCRIPTION}
          </div>
        </div>
        <div className="section_process_1">
          <p className="process_1">{string.DEVELOPED_WITH_PHOTOGRAPHERS}</p>
          <div className="section_process_2">
            <p className="process_2">
              {string.DEVELOPED_WITH_PHOTOGRAPHERS_DESCRIPTION_1}
            </p>
            <div className="s_process_2">
              {string.DEVELOPED_WITH_PHOTOGRAPHERS_DESCRIPTION_2}
            </div>
          </div>
        </div>
        {!isSmallScreen ? (
          <div className="key_container2">
            <LandingSubdivision
              landing_style={{
                borderBottom: "1px solid #ffffff42",
                gap: "75px",
              }}
              landingimage={landingsubimage1}
              landing_title={string.HP_BLOCKCHAIN_VERIFIED_COPIES}
              landing_text1={string.HP_BLOCKCHAIN_VERIFIED_COPIES_DESCRIPTION}
            />
            <LandingSubdivision
              imagestyle={{ gap: "50px" }}
              landing_style={{
                flexDirection: "row-reverse",
                borderBottom: "1px solid #ffffff42",
              }}
              landingimage={landingsubimage2}
              landing_title={string.PRODUCTION_FACILITIES}
              landing_text1={string.PRODUCTION_FACILITIES_DESCRIPTION_1}
              landing_text2={string.PRODUCTION_FACILITIES_DESCRIPTION_2}
            />
            <LandingSubdivision
              landing_style={{
                borderBottom: "1px solid #ffffff42",
                gap: "75px",
              }}
              landingimage={landingsubimage3}
              landing_title={string.CERTIFICATE_OF_AUTHENTICITY}
              landing_text1={string.CERTIFICATE_OF_AUTHENTICITY_DESCRIPTION_1}
              landing_text2={string.CERTIFICATE_OF_AUTHENTICITY_DESCRIPTION_2}
              landing_text3={string.CERTIFICATE_OF_AUTHENTICITY_DESCRIPTION_3}
            />
            <LandingSubdivision
              landing_style={{
                flexDirection: "row-reverse",
                borderBottom: "1px solid #ffffff42",
              }}
              landingimage={landingsubimage4}
              landing_title={string.SAVE_TIME_PERSONALIZING}
              landing_text1={string.SAVE_TIME_PERSONALIZING_DESCRIPTION}
            />
            <LandingSubdivision
              landing_style={{
                gap: "75px",
              }}
              landingimage={landingsubimage5}
              landing_title={string.SUSTAINABILITY}
              landing_text1={string.SUSTAINABILITY_DESCRIPTION_1}
              landing_text2={string.SUSTAINABILITY_DESCRIPTION_2}
              landing_text3={string.SUSTAINABILITY_DESCRIPTION_3}
            />
          </div>
        ) : (
          <div className="key_container2">
            <LandingSubdivision
              landing_style={{
                borderBottom: "1px solid #ffffff42",
              }}
              landingimage={landingsubimage1}
              landing_title={string.HP_BLOCKCHAIN_VERIFIED_COPIES}
              landing_text1={string.HP_BLOCKCHAIN_VERIFIED_COPIES_DESCRIPTION}
            />
            <LandingSubdivision
              imagestyle={{ gap: "50px" }}
              landing_style={{
                borderBottom: "1px solid #ffffff42",
              }}
              landingimage={landingsubimage2}
              landing_title={string.PRODUCTION_FACILITIES}
              landing_text1={string.PRODUCTION_FACILITIES_DESCRIPTION_1}
              landing_text2={string.PRODUCTION_FACILITIES_DESCRIPTION_2}
            />
            <LandingSubdivision
              landing_style={{
                borderBottom: "1px solid #ffffff42",
              }}
              landingimage={landingsubimage3}
              landing_title={string.CERTIFICATE_OF_AUTHENTICITY}
              landing_text1={string.CERTIFICATE_OF_AUTHENTICITY_DESCRIPTION_1}
              landing_text2={string.CERTIFICATE_OF_AUTHENTICITY_DESCRIPTION_2}
              landing_text3={string.CERTIFICATE_OF_AUTHENTICITY_DESCRIPTION_3}
            />
            <LandingSubdivision
              landing_style={{
                borderBottom: "1px solid #ffffff42",
              }}
              landingimage={landingsubimage4}
              landing_title={string.SAVE_TIME_PERSONALIZING}
              landing_text1={string.SAVE_TIME_PERSONALIZING_DESCRIPTION}
              landing_titlestyle={{ width: "315px" }}
            />
            <LandingSubdivision
              landingimage={landingsubimage5}
              landing_title={string.SUSTAINABILITY}
              landing_text1={string.SUSTAINABILITY_DESCRIPTION_1}
              landing_text2={string.SUSTAINABILITY_DESCRIPTION_2}
              landing_text3={string.SUSTAINABILITY_DESCRIPTION_3}
            />
          </div>
        )}
      </div>
      <div className="ss_section" data-section id="GetStarted">
        <div className="ss_section_content">
          <div className="ss_section_title">
            {string.GET_STARTED_IN_SIMPLE_STEPS}
          </div>
          <div className="ss_section_container">
            <div className="ss_section_dummy">
              <img alt="" className="accordian_style" src={imageAccordian} />
            </div>
            <div className="accordian_button">
              <Accordion preExpanded={[items[0].id]}>
                {items.map((item) => (
                  <AccordionItem
                    key={item.id}
                    onClick={() => {
                      image(item.id);
                    }}
                    uuid={item.id}
                  >
                    <AccordionItemHeading>
                      <AccordionItemButton>{item.heading}</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>{item.dropContent}</AccordionItemPanel>
                  </AccordionItem>
                ))}
                <div className="get-started-button">
                  <div class="signup">
                    <a
                      href={"/photographer_sign_up?gtm_source=" + utm_source}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      {string.GET_STARTED}
                    </a>
                  </div>
                </div>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <div className="pricing_content">
        <div className="pricing_container" data-section id="pricing">
          <div className="pricing_title">{string.PRICING}</div>
          <div className="pricing_imgcontainer">
            <div className="pricing_left">
              <img src={pricing1} alt="pricing" className="pricing_img1" />
              <div className="pricing_left_title">{string.SERVICE_COSTS}</div>
              <div className="pricing_left_text_main">
                <div className="pricing_left_text">
                  <div className="pricing_left_text1">
                    {string.SERVICE_COSTS_DESCRIPTION_1}
                  </div>
                  <div className="pricing_left_text1">
                    {string.SERVICE_COSTS_DESCRIPTION_2}
                  </div>
                </div>
                <div className="pricing_points">
                  <ul>
                    {string.SERVICE_COSTS_POINT.map((serviceCost) => (
                      <li className="pricing_points_text">
                        <span>{serviceCost}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="pricing_right">
              <img src={pricing2} alt="pricing" className="pricing_img2" />
              <div className="pricing_right_title">{string.SHIPPING}</div>
              <div className="pricing_right_text">
                <div className="pricing_right_description">
                  {string.SHIPPING_TEXT_1}
                </div>
                <div className="pricing_right_description">
                  {string.SHIPPING_TEXT_2}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="subscriber" data-section>
        <div className="subscriber-container">
          <div className="subscriber-heading">{string.CHOOSE_THE_PLAN}</div>
          <div className="subscriber-card-container">
            <LandingPlan goToSignIn={goToSignIn} />
          </div>
        </div>
      </div>
      <div className="contact">
        <div className="contact_keep">
          {string.KEEP_IN_TOUCH} {string.WITH_OUR_TEAM}
        </div>
        <div className="contact_1">
          <div className="email_maim">
            <img
              className="email_section_img"
              src={email_contact}
              alt="email"
            />
            <div className="email_1">
              <div className="email_1_description">{string.HP_SUPPORT}</div>
              <a
                href="mailto:hpsupport.limitededitions@hp.com"
                className="email_2_description"
                onClick={() =>
                  sendGAClickedHPEmailSupportLink(
                    queryStrings["utm_source"] ?? ""
                  )
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {string.HP_SUPPORT_LIMITED_EDITIONS}
              </a>
            </div>
          </div>
          {/* <div className="email_maim">
            <img src={whatsapp_contact} alt="what'sapp" />
            <div className="email_1">
              <div className="email_1_description">
                {string.HP_WHATSAPP_CHAT}
              </div>
              <a
                href="https://chat.whatsapp.com/KsFXRmAF0uzKVMANxcP2SK"
                className="email_2_description"
                onClick={sendGAClickedHPWatsAppChatLink}
              >
                {string.LINK_TO_WHATSAPP_GROUP}
              </a>
            </div>
          </div> */}
        </div>
      </div>

      <div className="footer_div">
        <div className="div_footer">
          <Footer isLandingPage={true} />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
