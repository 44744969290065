import React from "react";
import { Modal } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import "../../../Components/StatusModal/StatusModal.scss";
import { string } from "../../../Localization";
import status_right from "../../../Utils/Icons/status_right.svg";
import closebtn from "../../../Utils/Icons/close.svg";

const PlanModal = (props) => {
  const {
    canShow,
    statusDescription,
    closeBtnFtn,
    width,
    closeHandler,
    subHeadingWidth,
  } = props;

  const [searchParams] = useSearchParams();
  const queryStrings = Object.fromEntries([...searchParams]);

  return (
    <>
      <div>
        <div className="dialog-container">
          <Modal show={canShow} className="dialog-container-status">
            <Modal.Body
              className="status-modal-container"
              style={{ background: "#176267" }}
            >
              <div className="close-modal-btn">
                <img
                  src={closebtn}
                  onClick={closeBtnFtn}
                  style={{ cursor: "pointer" }}
                  alt="close"
                />
              </div>

              <div className="status-modal-content">
                <img alt="status" src={status_right} />
                <div
                  className="status-modal-heading"
                  style={{ width: width ? width : "" }}
                >
                  {string.CONGRATULATIONS}
                </div>
                <div
                  className="status-modal-description"
                  style={{ width: subHeadingWidth ? subHeadingWidth : "" }}
                >
                  {statusDescription}
                </div>

                <div
                  className="status-modal-cancel"
                  style={{ background: "#176267" }}
                >
                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href={
                      "/photographer_sign_up?gtm_source=" +
                        queryStrings["utm_source"] ?? ""
                    }
                    onClick={() => {
                      closeHandler();
                    }}
                  >
                    {string.CLICK_HERE_TO_FINALIZE_YOUR_SUBSCRIPTION}
                  </a>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default PlanModal;
