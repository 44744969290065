import { useEffect, useState } from "react";
import { string } from "../../../Localization";
import "./OrderSummaryInfo.scss";

const OrderSummaryInfo = (props) => {
  const { orderData } = props;
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1110);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="order-summary-info-block">
        <div className="order-summary-info-header">
          {string.HERE_YOU_HAVE_A_SUMMARY_OF_YOUR_PURCHASE}
        </div>
        {orderData && !isSmallScreen ? (
          <>
            <div className="grid-block">
              <div className="block-content">
                <div className="order-title">{string.GENERAL_INFO}</div>
                <div className="order-desc-block">
                  <div className="d-flex order-desc">
                    <div className="order-desc-text">{string.PHOTO_TITLE}</div>
                    <div className="order-desc-value">
                      {orderData?.project?.title}
                    </div>
                  </div>
                  <div className="d-flex order-desc">
                    <div className="order-desc-text">{string.PRINT_SIZE}:</div>
                    <div className="order-desc-value">
                      {orderData?.project?.print_size}
                    </div>
                  </div>
                  <div className="d-flex order-desc">
                    <div className="order-desc-text">{string.PHOTO_SIZE}</div>
                    <div className="order-desc-value">
                      {orderData?.project?.photo_size}
                    </div>
                  </div>
                  <div className="d-flex order-desc">
                    <div className="order-desc-text">{string.PAPER_TYPE}</div>
                    <div className="order-desc-value">
                      {orderData?.project?.media_type_name}
                    </div>
                  </div>
                  <div className="d-flex order-desc">
                    <div className="order-desc-text">{string.EDITION}:</div>
                    <div className="order-desc-value">
                      {orderData?.order?.edition}
                    </div>
                  </div>
                </div>

                <div className="blockchain-data-block">
                  <div className="order-title">
                    {string.HP_BLOCKCHAIN_LEDGER}
                  </div>
                  <div className="blockchain-data">
                    <div className="blockchain-data-text">
                      {orderData?.project?.blockchain?.public_ledger}{" "}
                      {orderData?.project?.blockchain?.authenticity_str}
                    </div>
                  </div>
                </div>
              </div>
              <div className="customer-info-block">
                <div>
                  <div className="customer-info-title">
                    {string.ADDRESS_DETAILS}
                  </div>
                  <div>
                    <div className="d-flex customer-desc">
                      <div className="customer-desc-text">
                        {string.COUNTRY}:
                      </div>
                      <div className="customer-desc-value text-country">
                        {orderData?.order?.address?.country}
                      </div>
                    </div>
                    <div className="d-flex customer-desc">
                      <div className="customer-desc-text">
                        {string.POST_CODE}:
                      </div>
                      <div className="customer-desc-value text-postcode">
                        {orderData?.order?.address?.zip_code}
                      </div>
                    </div>
                    <div className="d-flex customer-desc">
                      <div className="customer-desc-text ">
                        {string.ADDRESS_LINE_1}:
                      </div>
                      <div className="customer-desc-value text-address">
                        {orderData?.order?.address?.address_line1}
                      </div>
                    </div>
                    <div className="d-flex customer-desc">
                      <div className="customer-desc-text">
                        {string.ADDRESS_LINE_2}:
                      </div>
                      <div className="customer-desc-value text-address">
                        {orderData?.order?.address?.address_line2}
                      </div>
                    </div>
                    <div className="d-flex customer-desc">
                      <div className="customer-desc-text">
                        {string.TOWN_CITY}:
                      </div>
                      <div className="customer-desc-value text-city">
                        {orderData?.order?.address?.city}
                      </div>
                    </div>
                    <div className="d-flex customer-desc">
                      <div className="customer-desc-text">{string.COUNTY}:</div>
                      <div className="customer-desc-value text-county">
                        {orderData?.order?.address?.region}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="customer-info-details">
                  <div className="customer-info-title">
                    {string.CUSTOMER_DETAILS}
                  </div>
                  <div>
                    <div className="d-flex customer-desc">
                      <div className="customer-desc-text">
                        {string.FIRST_NAME}:
                      </div>
                      <div className="customer-desc-value coustomer-text-color">
                        {orderData?.order?.first_name}
                      </div>
                    </div>
                    <div className="d-flex customer-desc">
                      <div className="customer-desc-text">
                        {string.LAST_NAME}:
                      </div>
                      <div className="customer-desc-value coustomer-text-color">
                        {orderData?.order?.last_name}
                      </div>
                    </div>
                    <div className="d-flex customer-desc">
                      <div className="customer-desc-text">{string.EMAIL}:</div>
                      <div className="customer-desc-value coustomer-text-color">
                        {orderData?.order?.email}
                      </div>
                    </div>
                    <div className="d-flex customer-desc">
                      <div className="customer-desc-text">
                        {string.MOBILE_NUMBER}:
                      </div>
                      <div className="customer-desc-value coustomer-text-color">
                        {orderData?.order?.contact_number}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="order-price-block">
                <div className="order-price-title "></div>
                <div className="cost-feild-container">
                  <div className="d-flex justify-content-between order-price-body-text">
                    <div className="">{string.PRICE}</div>
                    <div>{orderData?.price?.selling_price}</div>
                  </div>
                  <div className="d-flex justify-content-between order-price-body-text">
                    <div className="">{string.SHIPPING_COSTS}</div>
                    <div>{orderData?.price?.shipping_fees}</div>
                  </div>
                  {orderData?.price?.discount_amount && (
                    <div className="d-flex justify-content-between order-price-body-text">
                      <div>{string.DISCOUNT}</div>
                      <div>{orderData?.price?.discount_amount}</div>
                    </div>
                  )}
                  <div className="d-flex justify-content-between order-price-body-text">
                    <div>{string.VAT}</div>
                    <div>{orderData?.price?.vat}</div>
                  </div>
                  <div className="d-flex justify-content-between order-total-price">
                    <div>{string.TOTAL_PRICE}</div>
                    <div className="order-total-price-value cost-data-field">
                      {orderData?.price?.total_price}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="grid-block">
              <div className="block-content">
                <div className="order-title">{string.GENERAL_INFO}</div>
                <div className="order-desc-block">
                  <div className="d-flex order-desc">
                    <div className="order-desc-text">{string.PHOTO_TITLE}</div>
                    <div className="order-desc-value">
                      {orderData?.project?.title}
                    </div>
                  </div>
                  <div className="d-flex order-desc">
                    <div className="order-desc-text">{string.PRINT_SIZE}:</div>
                    <div className="order-desc-value">
                      {orderData?.project?.print_size}
                    </div>
                  </div>
                  <div className="d-flex order-desc">
                    <div className="order-desc-text">{string.PHOTO_SIZE}</div>
                    <div className="order-desc-value">
                      {orderData?.project?.photo_size}
                    </div>
                  </div>
                  <div className="d-flex order-desc">
                    <div className="order-desc-text">{string.PAPER_TYPE}</div>
                    <div className="order-desc-value">
                      {orderData?.project?.media_type_name}
                    </div>
                  </div>
                  <div className="d-flex order-desc">
                    <div className="order-desc-text">{string.EDITION}:</div>
                    <div className="order-desc-value">
                      {orderData?.order?.edition}
                    </div>
                  </div>
                </div>
              </div>
              <div className="customer-info-block">
                <div>
                  <div className="customer-info-title">
                    {string.ADDRESS_DETAILS}
                  </div>
                  <div>
                    <div className="d-flex customer-desc">
                      <div className="customer-desc-text">
                        {string.COUNTRY}:
                      </div>
                      <div className="customer-desc-value text-country">
                        {orderData?.order?.address?.country}
                      </div>
                    </div>{" "}
                    <div className="d-flex customer-desc">
                      <div className="customer-desc-text">
                        {string.POST_CODE}:
                      </div>
                      <div className="customer-desc-value text-postcode">
                        {orderData?.order?.address?.zip_code}
                      </div>
                    </div>
                    <div className="d-flex customer-desc">
                      <div className="customer-desc-text ">
                        {string.ADDRESS_LINE_1}:
                      </div>
                      <div className="customer-desc-value text-address">
                        {orderData?.order?.address?.address_line1}
                      </div>
                    </div>
                    <div className="d-flex customer-desc">
                      <div className="customer-desc-text">
                        {string.ADDRESS_LINE_2}:
                      </div>
                      <div className="customer-desc-value text-address">
                        {orderData?.order?.address?.address_line2}
                      </div>
                    </div>{" "}
                    <div className="d-flex customer-desc">
                      <div className="customer-desc-text">
                        {string.TOWN_CITY}:
                      </div>
                      <div className="customer-desc-value text-city">
                        {orderData?.order?.address?.city}
                      </div>
                    </div>
                    <div className="d-flex customer-desc">
                      <div className="customer-desc-text">{string.COUNTY}:</div>
                      <div className="customer-desc-value text-county">
                        {orderData?.order?.address?.region}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="customer-info-block">
                <div className="customer-info-details">
                  <div className="customer-info-title">
                    {string.CUSTOMER_DETAILS}
                  </div>
                  <div>
                    <div className="d-flex customer-desc">
                      <div className="customer-desc-text">
                        {string.FIRST_NAME}:
                      </div>
                      <div className="customer-desc-value coustomer-text-color">
                        {orderData?.order?.first_name}
                      </div>
                    </div>
                    <div className="d-flex customer-desc">
                      <div className="customer-desc-text">
                        {string.LAST_NAME}:
                      </div>
                      <div className="customer-desc-value coustomer-text-color">
                        {orderData?.order?.last_name}
                      </div>
                    </div>
                    <div className="d-flex customer-desc">
                      <div className="customer-desc-text">{string.EMAIL}:</div>
                      <div className="customer-desc-value coustomer-text-color">
                        {orderData?.order?.email}
                      </div>
                    </div>
                    <div className="d-flex customer-desc">
                      <div className="customer-desc-text">
                        {string.MOBILE_NUMBER}:
                      </div>
                      <div className="customer-desc-value coustomer-text-color">
                        {orderData?.order?.contact_number}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="block-content">
                <div className="blockchain-data-block">
                  <div className="order-title">
                    {string.HP_BLOCKCHAIN_LEDGER}
                  </div>
                  <div className="blockchain-data">
                    <div className="blockchain-data-text">
                      {orderData?.project?.blockchain?.public_ledger}{" "}
                      {orderData?.project?.blockchain?.authenticity_str}
                    </div>
                  </div>
                </div>
                <div className="order-price-block">
                  <div className="order-price-title"></div>
                  <div>
                    <div className="d-flex justify-content-between order-price-body-text">
                      <div>{string.PRICE}</div>
                      <div>{orderData?.price?.selling_price}</div>
                    </div>
                    <div className="d-flex justify-content-between order-price-body-text">
                      <div>{string.SHIPPING_COSTS}</div>
                      <div>{orderData?.price?.shipping_fees}</div>
                    </div>
                    <div className="d-flex justify-content-between order-price-body-text">
                      <div>{string.VAT}</div>
                      <div>{orderData?.price?.vat}</div>
                    </div>
                    <div className="d-flex justify-content-between order-total-price">
                      <div>{string.TOTAL_PRICE}</div>
                      <div className="order-total-price-value">
                        {orderData?.price?.total_price}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default OrderSummaryInfo;
