import { Button } from "react-bootstrap";
import { string } from "../../../Localization";
import { Icons } from "../../../Utils/Icons";
import "./PreviewPanel.scss";
import StatusModal from "../../../Components/StatusModal/StatusModal";
import {
  setCanShowBackAlertModal,
  setCanShowErrorAlertModal,
  setCanShowStatusModal,
} from "../../../Store/Project/ProjectSlice";
import { useDispatch, useSelector } from "react-redux";
import status_right from "../../../Utils/Icons/status_right.svg";
import ProjectSpinner from "../../../Components/Loaders/ProjectSpinner/ProjectSpinner";
import {
  sendGAClickedCopySalesLinkButton,
  sendGAClickedDownloadZIPButton,
  sendGAClickedViewPageButton,
} from "../../../Utils/GAHelper";
import {
  Initiate_OpenSea,
  copiedSalesLink,
  toggleProjectGalleryAccess,
} from "../../../Utils/ApiHelper";
import { useEffect, useState } from "react";
import ShareLinkModal from "../../../Components/Modal/ShareLinkModal/ShareLinkModal";
import ShareIcon from "../../../Utils/Icons/share.svg";
import error_icon from "../../../Utils/Icons/error_icon.svg";
import CustomiseLinkModal from "../../../Components/Modal/CustomiseLinkModal/CustomiseLinkModal";
import edit_custom_link from "../../../Utils/Icons/edit_custom_link.svg";
import { Tooltip } from "@mui/material";
import {
  GALLERY_TYPE_DEFAULT,
  GALLERY_TYPE_PUBLIC,
} from "../../../Utils/Constants";

const PreviewPanel = (props) => {
  const { projectDetails, handleStartSelling } = props;

  const {
    preview_image,
    public_view_url,
    photo_print_zip_url,
    title,
    description,
    copied_sales_link,
    public_access,
    initiate_publish_to_opensea,
    open_sea_publish_exhausted,
    gallery_access,
    limited_edition,
  } = projectDetails;

  const {
    CanShowBackAlertModal,
    projectToken,
    CanShowErrorAlertModal,
    canShowStatusModal,
  } = useSelector((state) => state.project);

  const { currentUserDetails } = useSelector((state) => state.user);
  const { gallery_type } = currentUserDetails;

  const [galleryAccess, toggleGalleryAccess] = useState(gallery_access);
  const [isOpen, setIsOpen] = useState(false);
  const [link, setLink] = useState("");
  const [stausMessage, setStausMessage] = useState("");
  const [showCustomiseModal, setShowCustomiseModal] = useState(false);
  const [initiatePublish, setInitiatePublish] = useState(
    initiate_publish_to_opensea
  );
  const [showSpinner, toggleSpinner] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setLink(public_view_url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeHandler = () => {
    dispatch(setCanShowBackAlertModal(false));
  };

  const handleDownloadCertificate = () => {
    const link = document.createElement("a");
    link.href = photo_print_zip_url;
    link.style.display = "none";
    link.click();
  };

  const handleIntitaeOpensea = () => {
    Initiate_OpenSea(projectToken)
      .then((res) => {
        const response = res?.data?.data;
        setStausMessage(response?.message);
        dispatch(setCanShowStatusModal(true));
        const initiatePublishToOpensea = response?.initiate_publish_to_opensea;
        setInitiatePublish(initiatePublishToOpensea);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onCopy = () => {
    if (!copied_sales_link) {
      copiedSalesLink(projectToken);
    }
    setIsOpen(false);
    navigator.clipboard.writeText(link);
    dispatch(setCanShowBackAlertModal(true));
    sendGAClickedCopySalesLinkButton();
  };

  const handleSaleLinkClick = async () => {
    setIsOpen(true);
  };

  const closeErrorHandler = () => {
    dispatch(setCanShowErrorAlertModal(false));
  };

  const StatusCloseHandler = () => {
    dispatch(setCanShowStatusModal(false));
  };

  const handleTogglePublish = () => {
    toggleSpinner(true);
    const payload = { publishToGallery: !galleryAccess };
    toggleProjectGalleryAccess(projectToken, payload)
      .then((res) => {
        toggleGalleryAccess(res?.data?.data?.project?.gallery_access);
        toggleSpinner(false);
      })
      .catch(() => {
        toggleSpinner(false);
      });
  };

  const openSeaPublishExhausted = open_sea_publish_exhausted === true;
  const disablePublish = openSeaPublishExhausted
    ? openSeaPublishExhausted
    : !initiatePublish;

  const publishButtonUI = (
    <button
      className="OpenSea_Link link_margin_openSea"
      onClick={() => {
        handleIntitaeOpensea();
      }}
      disabled={disablePublish}
    >
      {openSeaPublishExhausted
        ? string.PUBLISH_TO_OPENSEA
        : initiatePublish
        ? string.PUBLISH_TO_OPENSEA
        : string.PUBLISH_TO_OPENSEA_INITIATED}
    </button>
  );

  const publishButton = () => {
    return openSeaPublishExhausted ? (
      <Tooltip title={string.OPENSEA_EXHAUSTED} arrow>
        {publishButtonUI}
      </Tooltip>
    ) : (
      <>{publishButtonUI}</>
    );
  };

  const showPublishToGallery = gallery_type !== GALLERY_TYPE_DEFAULT;

  return (
    <div className="preview-panel">
      <div className="panel1">
        <div className="px-2">
          <div className="py-2 p-title">{string.START_SELLING}</div>
          {public_access ? (
            <>
              <div className="py-2 p-description">
                {string.START_SELLING_DESC}
              </div>
              <div className="div-container">
                <div
                  className="d-flex cursor-pointer btn-1"
                  onClick={() => {
                    handleSaleLinkClick();
                  }}
                >
                  <div className="button">
                    <button className="share_link_btn share-link_1">
                      {string.SHARE_LINK}{" "}
                      <img src={ShareIcon} alt="share" className="share-icon" />
                    </button>
                  </div>
                </div>
                <div className="customise_button">
                  {" "}
                  <button
                    className="link_btn share-link_3"
                    onClick={() => setShowCustomiseModal(true)}
                  >
                    {string.CUSTOMIZE_LINK}
                    <img
                      alt="edit_custom_link"
                      className="copy-icon"
                      src={edit_custom_link}
                    ></img>
                  </button>
                </div>
                <div
                  className="view_page_button"
                  onClick={(event) => {
                    event.preventDefault();
                    navigator.clipboard.writeText(link);
                    window.open(link + "?utm_source=saleslink", "_blank");
                    sendGAClickedViewPageButton();
                  }}
                >
                  <button className=" link_btn share-link_2">
                    {string.VIEW_PAGE}
                    <img
                      alt="copy-icon"
                      className="copy-icon"
                      src={Icons.eye.default}
                    ></img>
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className="py-2 p-description">
                {string.START_SELLING_DESC2}
              </div>
              <div className="div-container">
                <div
                  className="d-flex cursor-pointer btn-1"
                  onClick={() => {
                    handleStartSelling();
                  }}
                >
                  <div className="button">
                    <button className="share_link_btn share-link_1">
                      {string.START_SELLING}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="center-image">
          <img
            alt="shipping-view"
            className="shipping-view"
            src={Icons.shippingView.default}
          ></img>
        </div>{" "}
      </div>
      <div className="d-flex align-items-stretch">
        {public_access &&
          limited_edition &&
          currentUserDetails?.initiate_publish_to_opensea === true && (
            <div
              className={
                "panel2  d-block panel3 " +
                (showPublishToGallery ? " margin-right" : "")
              }
            >
              <div className="px-2">
                <div className="py-2 p-title">{string.PUBLISH_TO_OPENSEA}</div>
                <div className="py-2 p-description">
                  {string.PUBLISH_TO_OPENSEA_DESCRIPTION}
                </div>
                {publishButton()}
              </div>
            </div>
          )}
        {showPublishToGallery && (
          <div className=" d-block panel2 panel3">
            <div className="px-2">
              <div className="py-2 p-title">
                {string.formatString(
                  string.PUBLISH_TO_GALLERY1,
                  gallery_type === GALLERY_TYPE_PUBLIC ? "HP LE" : "My"
                )}
              </div>
              <div className="py-2 p-description">
                {galleryAccess
                  ? string.PUBLISH_TO_GALLERY_DESCRIPTION
                  : string.UNPUBLISH_TO_GALLERY_DESCRIPTION}
              </div>
              {showSpinner ? (
                <ProjectSpinner isLoading={true} customClass="spinnerStyle" />
              ) : (
                <button
                  className=" OpenSea_Link link_margin_gallery"
                  onClick={() => {
                    handleTogglePublish();
                  }}
                >
                  {galleryAccess
                    ? string.UNPUBLISH_TO_GALLERY
                    : string.PUBLISH_TO_GALLERY}
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="my-2 panel2">
        <div className="px-2">
          <div className="py-2 p-title">{string.IMAGES_OF_YOUR_WEBSITE}</div>
          <div className="py-2 p-description">
            {string.IMAGES_OF_YOUR_WEBSITE_DESC}
          </div>
          <div
            className="d-flex py-2"
            onClick={() => {
              sendGAClickedDownloadZIPButton();
            }}
          >
            {photo_print_zip_url && (
              <Button
                className="link zip"
                onClick={() => {
                  handleDownloadCertificate();
                }}
              >
                {string.DOWNLOAD_ZIP}

                <img
                  alt="download-icon"
                  className="download-icon"
                  src={Icons.download.default}
                ></img>
              </Button>
            )}
          </div>
        </div>
        <div className="center-image">
          <img
            alt="preview-icon"
            className="grid-panel-view"
            src={Icons.preview.default}
          ></img>
          <img
            alt="preview1-icon"
            className="grid-panel-view"
            src={Icons.previewObj1.default}
          ></img>
          <img
            alt="preview2-icon"
            className="grid-panel-view"
            src={Icons.previewObj2.default}
          ></img>
        </div>
      </div>
      <StatusModal
        canShow={CanShowBackAlertModal}
        stautsbackground={{ background: "#176267" }}
        statusimge={status_right}
        statusHeading={string.SALES_LINK_COPIED_SUCCESSFULLY}
        statusDescription={string.YOU_CAN_NOW_SHARE_THE_COPIED_LINK}
        closebackground={{ background: "#176267" }}
        closeData={{
          closeBtn: string.CLOSE,
          closeHandler: closeHandler,
        }}
      />
      <ShareLinkModal
        isOpen={isOpen}
        onClose={onClose}
        onCopy={onCopy}
        link={link}
        previewImage={preview_image}
        message={
          "Exciting news, photography enthusiasts! I'm thrilled to share that my limited edition prints are now available for purchase!  Don't miss out on the chance to own a piece of my creative journey at"
        }
        title={title}
        description={description}
      />
      <CustomiseLinkModal
        isOpen={showCustomiseModal}
        onClose={() => setShowCustomiseModal(false)}
        projectToken={projectToken}
        link={link}
        setLink={setLink}
      />
      <StatusModal
        canShow={canShowStatusModal}
        stautsbackground={{ background: "#176267" }}
        statusimge={status_right}
        statusHeading={"Pubish to OpenSea"}
        statusDescription={stausMessage}
        closebackground={{ background: "#176267" }}
        closeData={{
          closeBtn: string.CLOSE,
          closeHandler: StatusCloseHandler,
        }}
      />
      <StatusModal
        canShow={CanShowErrorAlertModal}
        stautsbackground={{ background: "#212121" }}
        statusimge={error_icon}
        statusHeading={"Something went wrong"}
        statusDescription={`${"Please try again later"}`}
        closebackground={{ background: "#212121" }}
        closeData={{
          closeBtn: string.CLOSE,
          closeHandler: closeErrorHandler,
        }}
      />
    </div>
  );
};

export default PreviewPanel;
