import React, { useEffect, useState } from "react";
import {
  getFileExtension,
  getImageDimension,
  getPreviewUrlFromFile,
} from "../../../Utils/Helper";
import "../LogoUpload/LogoUpload.scss";
import Dropzone from "react-dropzone";
import {
  JPEG,
  JPG,
  MAX_PROFILE_PHOTO_SIZE_IN_BYTES,
  PNG,
  UPLOAD,
} from "../../../Utils/Constants";
import deleteBtn from "../../../Utils/Icons/delete-signature.svg";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUserDetails } from "../../../Store/Project/UserSlice";
import StatusModal from "../../../Components/StatusModal/StatusModal";
import error_icon from "../../../Utils/Icons/error_icon.svg";
import { string } from "../../../Localization";

const ProfilePhoto = (props) => {
  const {
    ProfilePhotoText,
    uploadProfilePhotoText,
    onProfilePhotoChange,
    isValid,
    isDistroyed,
  } = props;
  const dispatch = useDispatch();
  const { currentUserDetails } = useSelector((state) => state.user);

  const [profilePhoto, setProfilePhoto] = useState("");
  const [isError, setIsError] = useState(false);
  const [exceededMaxLimit, setExceededMaxLimit] = useState(false);

  const profilephotoType = UPLOAD;

  useEffect(
    () =>
      (window.onbeforeunload = function () {
        localStorage.removeItem("profilePhoto");
      }),
    []
  );

  const handleProfilePhotoImage = (photo) => {
    setProfilePhoto(photo);
  };

  const onDrop = (files) => {
    if (files.length > 0) {
      let fileUploaded = files[0];
      const fileExtension = getFileExtension(fileUploaded.name);

      if (
        fileExtension === PNG ||
        fileExtension === JPG ||
        fileExtension === JPEG
      ) {
        if (fileUploaded.size <= MAX_PROFILE_PHOTO_SIZE_IN_BYTES) {
          getImageDimension(fileUploaded).then((imageDimensions) => {
            onProfilePhotoChange(fileUploaded);
            isValid(true);
            handleProfilePhotoImage(getPreviewUrlFromFile(fileUploaded));
          });
        } else {
          setExceededMaxLimit(true);
        }
      } else {
        setIsError(true);
      }
    }
  };

  const handleDeleteProfilePhoto = () => {
    setProfilePhoto("");
    onProfilePhotoChange("");
    isDistroyed(true);
    dispatch(
      setCurrentUserDetails({
        ...currentUserDetails,
        profile_photo: "/profile_photos/original/missing.png",
      })
    );
  };

  const handleClose = () => {
    setIsError(false);
  };

  const handleClose2 = () => {
    setExceededMaxLimit(false);
  };

  return (
    <>
      <div>
        <div className="signature-subOption1">
          {(profilePhoto && profilephotoType === UPLOAD) ||
          currentUserDetails?.profile_photo !==
            "/profile_photos/original/missing.png" ? (
            <>
              <div className="subOption-description">{ProfilePhotoText}</div>
              <div className="logo-type-container">
                <div className="signature-container">
                  <div>
                    <img
                      draggable="false"
                      className="signature-image"
                      src={
                        currentUserDetails?.profile_photo ===
                        "/profile_photos/original/missing.png"
                          ? profilePhoto
                          : currentUserDetails?.profile_photo
                      }
                      alt="profilePhoto"
                    />
                  </div>
                </div>

                <img
                  draggable="false"
                  className="logo-delete-btn"
                  src={deleteBtn}
                  alt=""
                  onClick={() => {
                    handleDeleteProfilePhoto();
                  }}
                />
              </div>
            </>
          ) : (
            <>
              {profilephotoType === UPLOAD && (
                <Dropzone onDrop={onDrop} multiple={false}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: "dropzonee" })}>
                      <input {...getInputProps()} />

                      <>
                        <div className="upload-btn-wrapper">
                          <button className="upload-signature">
                            {string.UPLOAD_PROFILE_PHOTO}
                          </button>
                        </div>
                      </>
                    </div>
                  )}
                </Dropzone>
              )}
              {profilePhoto && (
                <img
                  draggable="false"
                  className="signature-image"
                  src={profilePhoto}
                  alt="profilePhoto"
                />
              )}
              <div className="subOption-description" style={{ width: "340px" }}>
                {uploadProfilePhotoText}
              </div>
            </>
          )}

          {isError && (
            <StatusModal
              canShow={isError}
              stautsbackground={{ background: "#212121" }}
              statusimge={error_icon}
              statusHeading={string.INVALID_IMAGE_FORMAT}
              statusDescription={string.PROFILE_PHOTO_FORMATS}
              closebackground={{ background: "#212121" }}
              closeData={{
                closeBtn: string.CLOSE,
                closeHandler: handleClose,
              }}
            />
          )}
          {exceededMaxLimit && (
            <StatusModal
              canShow={exceededMaxLimit}
              stautsbackground={{ background: "#212121" }}
              statusimge={error_icon}
              statusHeading={string.UPLOAD_FAILED}
              statusDescription={string.FILE_SIZE_EXCEEDED}
              closebackground={{ background: "#212121" }}
              closeData={{
                closeBtn: string.CLOSE,
                closeHandler: handleClose2,
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ProfilePhoto;
