import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import "./modalGuide.scss";
import Closebutton from "../../../Utils/Icons/close.svg";
import { string } from "../../../Localization";
import Carousal from "../Carousal/Carousal";
import NextBtn from "../NextBtn/NextBtn";
import Slider from "../Slider/Slider";
import data from "../Carousal/CarousalContent";
import { disableQuickStartGuide } from "../../../Utils/ApiHelper";
import {
  sendGAClickedDonotShowMeThisAgain,
  sendGAClosedQuickStartGuide,
} from "../../../Utils/GAHelper";

const ModalGuide = ({ isOpen, modahandler }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);

  const len = data.length - 1;

  const modalhandler = (modaldata) => {
    modahandler(modaldata);
  };

  useEffect(() => {
    if (privacyPolicy) {
      localStorage.setItem("showQuickStartGuide", "false");
      disableQuickStartGuide();
      sendGAClickedDonotShowMeThisAgain();
    }
  }, [privacyPolicy]);

  return (
    <>
      <Modal isOpen={isOpen} className="upload-modal" centered>
        <img
          alt="close"
          className="close-guide"
          src={Closebutton}
          onClick={() => {
            modalhandler(false);
            sendGAClosedQuickStartGuide();
          }}
        />
        <ModalHeader className="header-background">
          <div className="GModal-header"> {string.QUICK_START_GUIDE}</div>
        </ModalHeader>
        <ModalBody className="modal-body modal-body-bg">
          <div className="guide-Main">
            <Carousal
              activeIndex={activeIndex}
              data={data}
              className="increase_width"
            />

            <Slider
              activeIndex={activeIndex}
              data={data}
              onclick={(activeIndex) => setActiveIndex(activeIndex)}
            />
            <div className="guide-checkbox my-4">
              <label className="checkbox-container">
                <input
                  type="checkbox"
                  checked={privacyPolicy}
                  onClick={() => {
                    setPrivacyPolicy(!privacyPolicy);
                  }}
                />
                <span className="checkmark"></span>
              </label>

              <span>{string.DONT_SHOW_THIS_AGAIN}</span>
            </div>

            <NextBtn
              nextSlide={() =>
                setActiveIndex(
                  activeIndex === len ? modalhandler(false) : activeIndex + 1
                )
              }
              modalhandler={modalhandler}
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ModalGuide;
