import React, { useState } from "react";
import { fontOptions } from "../../../Utils/Constants";
import "./Dropdown.scss";
import downArrowSvg from "../../../Utils/Icons/down-arrow.svg";

const Dropdown = ({ selected, setSelected }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="dropdown-wrapper">
      <div className="dropdown">
        <div className="dropdown-btn" onClick={(e) => setIsActive(!isActive)}>
          <div>{selected.type}</div>
          <div>
            <img src={downArrowSvg} className="dropdown-arrow" alt="" />
          </div>
        </div>
        {isActive && (
          <div className="dropdown-options">
            {fontOptions.map((option) => (
              <div
                style={{ fontFamily: `${option}` }}
                className="dropdown-option"
                onClick={(e) => {
                  setSelected(option);
                  setIsActive(false);
                }}
              >
                {option.type}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
