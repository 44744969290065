import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { string } from "../../Localization";
import { setActivePageIndex } from "../../Store/Project/PageSlice";
import { UNIT_SYSTEM_INCHES, UNIT_SYSTEM_MM } from "../../Utils/Constants";
import {
  setCanShowErrorAlertModal,
  setCanShowBackAlertModal,
  setOnClickLoader,
  setImageErrorMessage,
} from "../../Store/Project/ProjectSlice";
import { getPriceInfo } from "../../Utils/ApiHelper";
import {
  CERTIFICATE,
  PACKAGING,
  PAGE_CREATE_PROJECT,
  PHOTO_PRINT,
  PRICE_CURRENCY_GBP,
} from "../../Utils/Constants";
import { getSelectedMediaTypeName } from "../../Utils/Helper";
import { handleOrderArtistProof, handleSaveDraft } from "../../Utils/SaveDraft";
import TopBar from "../TopBar/TopBar";
import Packaging from "./Packaging/Packaging";
import PhotoPrint from "../../Components/PhotoPrint/PhotoPrint";
import "./ProjectSummary.scss";
import ProjectSummaryModal from "./ProjectSummaryModal/ProjectSummaryModal";
import StatusModal from "../../Components/StatusModal/StatusModal";
import error_icon from "../../Utils/Icons/error_icon.svg";
import { convertInchesToMm } from "../../Utils/Helper";
import PrintedCertificate from "../Certificate/PrintedCertificate/PrintedCertificate";
import {
  sendGAClickedEncryptAndLocktoSell,
  sendGAClickedSaveAsaDraft,
  sendGAClickedSaveDraftAndOrderArtisticProof,
  sendGALoadedCertificateSection,
  sendGALoadedPackagingSection,
  sendGALoadedPhotoPrintSection,
} from "../../Utils/GAHelper";
import {
  setImageAlreadyPresentError,
  showOrHideApiLoadingModal,
} from "../../Store/Project/ModalSlice";
import ProjectLoader from "../../Components/Loaders/ProjectLoader/ProjectLoader";

const ProjectSummary = () => {
  const dispatch = useDispatch();

  const {
    CanShowErrorAlertModal,
    CanShowBackAlertModal,
    onClickLoader,
    selectedMediaTypeId,
    projectDetails,
    priceInfo,
    sizeSelectionDetails,
    previewImage,
    borderString,
    selectedUnitSystem,
    imageErrorMessage,
  } = useSelector((state) => state.project);

  const { noOfCopies, isLimited } = useSelector(
    (state) => state.limitedEdition
  );
  const { currentUserDetails } = useSelector((state) => state.user);
  const photoSize = useSelector((state) => state.project.sizeSelectionDetails);
  const { imageAlreadyPresentError } = useSelector((state) => state.modal);

  const photoDimensions = photoSize.selectedDimension;
  const { title, description } = projectDetails;
  const { selectedId } = sizeSelectionDetails;

  const [tabActive, setTabActive] = useState({
    PhotoPrint: true,
    Certificate: false,
    Packaging: false,
  });
  const [pricingData, setPricingData] = useState({});
  const [currentTab, setCurrentTab] = useState(PHOTO_PRINT);
  const [isPriceLoaded, setIsPriceLoaded] = useState(false);

  const numberOfCopiesFieldValue = isLimited ? noOfCopies : "N/A";

  useEffect(() => {
    // Pricing API call
    dispatch(showOrHideApiLoadingModal(true));
    const { currency, income } = priceInfo;
    const currencyType = currency === PRICE_CURRENCY_GBP ? 3 : "";

    getPriceInfo(income, currencyType, selectedId, selectedMediaTypeId).then(
      (res) => {
        setPricingData(res.data.data);
        setIsPriceLoaded(true);
        dispatch(showOrHideApiLoadingModal(false));
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabs = (key) => {
    const tabs = {
      PhotoPrint: false,
      Certificate: false,
      Packaging: false,
    };
    setCurrentTab(key);
    switch (key) {
      case PHOTO_PRINT:
        setTabActive({ ...tabs, PhotoPrint: true });
        break;
      case CERTIFICATE:
        setTabActive({ ...tabs, Certificate: true });
        break;
      case PACKAGING:
        setTabActive({ ...tabs, Packaging: true });
        break;
      default:
        break;
    }
  };

  const renderTabView = (currentTab) => {
    switch (currentTab) {
      case PHOTO_PRINT:
        return <PhotoPrint preview_url={previewImage} />;
      case CERTIFICATE:
        return <PrintedCertificate />;
      case PACKAGING:
        return <Packaging />;
      default:
        break;
    }
  };

  const handleNavigateBack = () => {
    dispatch(setActivePageIndex(PAGE_CREATE_PROJECT));
  };

  const cancelHandler = () => {
    dispatch(setCanShowBackAlertModal(false));
  };

  const closeHandler = () => {
    dispatch(setCanShowErrorAlertModal(false));
    if (imageAlreadyPresentError) {
      dispatch(setImageAlreadyPresentError(false));
      dispatch(setImageErrorMessage(""));
    }
  };

  const printWidth =
    selectedUnitSystem === UNIT_SYSTEM_MM
      ? convertInchesToMm(photoDimensions.width)
      : photoDimensions.width;

  const printHeight =
    selectedUnitSystem === UNIT_SYSTEM_MM
      ? convertInchesToMm(photoDimensions.height)
      : photoDimensions.height;

  const unitSymbol = selectedUnitSystem === UNIT_SYSTEM_INCHES ? `"` : "mm";

  const printSizeString = `${Math.trunc(
    printWidth
  )}${unitSymbol} X ${Math.trunc(printHeight)}${unitSymbol}`;

  const photoSizeString = `${
    Math.trunc(printWidth) - 2 * borderString
  }${unitSymbol} X ${Math.trunc(printHeight) - 2 * borderString}${unitSymbol}`;

  const handleEncryptAndLock = () => {
    if (!currentUserDetails?.unsubscribed) {
      dispatch(setCanShowBackAlertModal(true));
      sendGAClickedEncryptAndLocktoSell();
    }
  };

  return (
    <>
      <TopBar pageTitle="Summary" onNavigateBack={handleNavigateBack} />
      {isPriceLoaded ? (
        <div className="summary-block container">
          {pricingData.price && (
            <>
              <div className="tab-block">
                <div className="d-flex custom-tabs">
                  <div
                    className={
                      tabActive.PhotoPrint ? "tab-view tab-active" : "tab-view"
                    }
                    onClick={() => {
                      handleTabs(PHOTO_PRINT);
                      sendGALoadedPhotoPrintSection();
                    }}
                  >
                    {string.PHOTO_PRINT}
                  </div>
                  <div
                    className={
                      tabActive.Certificate ? "tab-view tab-active" : "tab-view"
                    }
                    onClick={() => {
                      handleTabs(CERTIFICATE);
                      sendGALoadedCertificateSection();
                    }}
                  >
                    {CERTIFICATE}
                  </div>
                  <div
                    className={
                      tabActive.Packaging ? "tab-view tab-active" : "tab-view"
                    }
                    onClick={() => {
                      handleTabs(PACKAGING);
                      sendGALoadedPackagingSection();
                    }}
                  >
                    {PACKAGING}
                  </div>
                </div>
                <div className="tab-content">{renderTabView(currentTab)}</div>
              </div>
              <div className="content-block">
                <div className="content-header">
                  <div className="py-2 title">{title}</div>
                  <div className="py-2 description">{description}</div>
                  <div className="py-2 copyright">
                    {currentUserDetails.copyright_text}
                  </div>
                </div>
                <div className="content-body">
                  <div className="d-flex add-gap">
                    <div className="block-1">
                      <div className="py-2 title">{string.PRINT_DETAILS}</div>
                      <div className="info-blocks">
                        <div className="d-flex">
                          <div className="label-text paper-width">
                            {string.PAPER_TYPE}
                          </div>
                          <div className="px-1 hash-value">
                            {getSelectedMediaTypeName()}
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="label-text">{string.PRINT_SIZE}:</div>
                          <div className="px-1 hash-value">
                            <span className="desc-value">
                              {printSizeString}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="label-text">{string.PHOTO_SIZE}</div>
                          <div className="px-1 hash-value">
                            <span className="desc-value">
                              {photoSizeString}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="label-text">{string.BORDER_SIZE}</div>
                          <div className="px-1 hash-value">
                            <span className="desc-value">
                              {`${borderString}${unitSymbol}`}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="block-2">
                      <div className="py-2 title">
                        {string.PRICING_AND_INCOME}
                      </div>
                      <div className="info-blocks">
                        <div className="d-flex justify-content-sm-between">
                          <div className="label-text">
                            {string.ARTIST_INCOME}
                          </div>
                          <div className="px-1 hash-value-2">
                            {pricingData.price.income}
                          </div>
                        </div>
                        <div className="d-flex justify-content-sm-between">
                          <div className="label-text">
                            {string.SERVICE_FEES}
                          </div>
                          <div className="px-1 hash-value-2">
                            {pricingData.price.service_fees}
                          </div>
                        </div>
                        <div className="d-flex justify-content-sm-between">
                          <div className="label-text">{string.SHIPPING}</div>
                          <div className="px-1 hash-value-2">
                            {pricingData.price.shipping_fees}
                          </div>
                        </div>
                        <div className="d-flex justify-content-sm-between">
                          <div className="label-text hash-value-3">
                            {string.SELLING_PRICE}
                          </div>
                          <div className="px-1 hash-value-3">
                            {pricingData.price.selling_price}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex add-gap">
                    <div className="block">
                      <div className="py-2 title">{string.IMAGE_SECURITY}</div>
                      <div className="d-flex">
                        <div className="label-text">
                          {string.NUMBER_OF_COPIES}
                        </div>
                        <div className="px-1 hash-value">
                          {numberOfCopiesFieldValue}
                        </div>
                      </div>
                    </div>
                    <div className="summary-info-buttons">
                      <div
                        className={
                          currentUserDetails?.unsubscribed
                            ? "custom-button-1 disabled-custom-button-1"
                            : "custom-button-1"
                        }
                        onClick={handleEncryptAndLock}
                      >
                        {string.ENCRYPT_AND_LOCK_TO_SELL}
                      </div>
                      <div
                        className=" custom-button-2"
                        onClick={() => {
                          dispatch(setOnClickLoader(true));
                          dispatch(showOrHideApiLoadingModal(true));

                          handleOrderArtistProof();
                          sendGAClickedSaveDraftAndOrderArtisticProof();
                        }}
                      >
                        {string.SAVE_DRAFT_AND_ORDER}
                      </div>
                      <div
                        className=" custom-button-2"
                        onClick={() => {
                          handleSaveDraft();
                          dispatch(showOrHideApiLoadingModal(true));
                          dispatch(setOnClickLoader(true));
                          sendGAClickedSaveAsaDraft();
                        }}
                      >
                        {string.SAVE_AS_DRAFT}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <ProjectLoader />
      )}
      <ProjectSummaryModal
        canShow={CanShowBackAlertModal}
        submitData={{
          submitText: string.ENCRYPT_AND_LOCK,
          cancelBtn: string.CANCEL,
          cancelHandler: cancelHandler,
        }}
        navigateTo={"/"}
      />
      <>
        <StatusModal
          canShow={CanShowErrorAlertModal}
          stautsbackground={{ background: "#212121" }}
          statusimge={error_icon}
          statusHeading={
            imageAlreadyPresentError ? string.THIS_IMAGE_ALREADY_EXISTS : ""
          }
          statusDescription={`${imageErrorMessage}`}
          closebackground={{ background: "#212121" }}
          closeData={{
            closeBtn: string.CLOSE,
            closeHandler: closeHandler,
          }}
        />
      </>

      {onClickLoader && (
        <>
          <ProjectLoader />
        </>
      )}
    </>
  );
};

export default ProjectSummary;
