import React, { useEffect } from "react";
import "./TermsOfService.scss";
import { sendGALoadedTermsOfService } from "../../../../../Utils/GAHelper";
import { string } from "../../../../../Localization";

const TermsOfService = () => {
  useEffect(() => {
    sendGALoadedTermsOfService();
  }, []);

  return (
    <div className="termsOfService-container">
      <div className="main-container">
        <div className="sub-container">
          <div className="title-block">
            <h6 className="text-normal">
              {string.HP_LIMITED_EDITION_CONSUMER_TERMS_OF_SERVICE}
            </h6>
            <h6 className="text-normal">{string.DATE_OF_RELEASE}</h6>
          </div>
          <h1 className="heading letter-spacing capitalize">
            {string.HP_LIMITED_EDITION_CONSUMER_TERMS_OF_SERVICE}
          </h1>
          <h2 className="sub-heading letter-spacing">
            1. {string.IDENTIFY_THE_TRADER}
          </h2>
          <p className="contents">
            {string.HP_INC_UK_LIMITED}{" "}
            <a
              href="https://www.hplimiteditions.com/"
              target="_blank"
              rel="noreferrer"
            >
              {string.HP_LIMITED_EDITION}
            </a>
            <strong>(“{string.HP_LIMITED_EDITION}")</strong>
            <br />
            {string.TERMS_OF_SERVICE_ADDRESS}
            <br />
            {string.TERMS_OF_SERVICE_ADDRESS2}
            <br />
            {string.COUNTRY_NAME}
            <br />
            {string.TERMS_OF_SERVICE_REG_NO}
            <br />
            {string.TERMS_OF_SERVICE_VAT_ID}
            <br />
            {string.EMAIL}:{" "}
            <a
              href="mailto:hpsupport.limitededitions@hp.com"
              target="_blank"
              rel="noreferrer"
            >
              {string.HP_SUPPORT_LIMITED_EDITIONS}
            </a>
          </p>
          <h2 className="sub-heading letter-spacing">2. {string.SCOPE}</h2>
          <p className="contents">
            {string.SCOPE_TEXT_1} ({string.THE}
            <strong>"{string.TERMS}"</strong>) {string.SCOPE_TEXT_2}
          </p>
          {/* <p className="contents">
            {string.SCOPE_TEXT_1} ({string.THE}
            <strong>"{string.TERMS}"</strong>) govern any order placed via the
            HP Limited Editions website. Therefore, before placing an order, you
            should carefully read the terms and conditions set out below. If you
            wish to purchase any product (as defined below), you must click to
            acknowledge and affirmatively accept the Terms on the website. If
            you do not accept these Terms, then please do not order any products
            from this website.
          </p> */}
          <p className="contents">{string.UNDER_THESE_TERMS}:</p>
          <p className="contents">
            <strong> “{string.ARTWORK}” </strong>
            {string.ARTWORK_DESCRIPTION}
          </p>
          <p className="contents">
            <strong>“{string.PRODUCT}”</strong> {string.PRODUCT_DESCRIPTION}
          </p>
          <p className="contents">
            {string.SOME_SECTIONS_OF_THESE_TERMS}
            <strong> {string.CONSUMER_CUSTOMER}</strong>{" "}
            {string.CONSUMER_CUSTOMER_TEXT_1}
            <strong> {string.NON_BUSINESS_USE}</strong>
            {string.CONSUMER_CUSTOMER_TEXT_2}
          </p>
          <p className="contents">
            {string.IN_ADDITION_TO_THESE_TERMS}{" "}
            <a
              href="https://www.hp.com/us-en/terms-of-use.html"
              target="_blank"
              rel="noreferrer"
            >
              {string.TERMS_OF_USE}
            </a>
            .
          </p>
          <h2 className="sub-heading letter-spacing">
            3. {string.PRICE_TAXES_DELIVERY_CHARGES}
          </h2>
          <p className="contents">
            {string.PRICE_TAXES_DELIVERY_CHARGES_TEXT_1}
          </p>
          <p className="contents">
            {string.PRICE_TAXES_DELIVERY_CHARGES_TEXT_2}
          </p>
          <p className="contents">
            {string.PRICE_TAXES_DELIVERY_CHARGES_TEXT_3}
          </p>
          <p className="contents">
            {string.PRICE_TAXES_DELIVERY_CHARGES_TEXT_4}
          </p>
          <h2 className="sub-heading letter-spacing">
            4. {string.INVOICING_AND_PAYMENT_METHODS}
          </h2>
          <p className="contents">
            {string.INVOICING_AND_PAYMENT_METHODS_TEXT_1}
          </p>
          <p className="contents">
            {string.IF_YOU_DO_NOT_HAVE_ADOBE_READER}{" "}
            <a
              href="https://get.adobe.com/uk/reader/?loc=uk"
              target="_blank"
              rel="noreferrer"
            >
              {string.DOWNLOAD_HERE}
            </a>
            .
          </p>
          <p className="contents">
            {string.INVOICING_AND_PAYMENT_METHODS_TEXT_2}
          </p>

          <h2 className="sub-heading letter-spacing">5. {string.ORDERS}</h2>
          <p className="contents">
            {string.ORDERS_TEXT_1} <u>{string.ORDERS_TEXT_2}</u>.
          </p>
          <p className="contents">{string.ORDERS_TEXT_3}</p>
          <p className="contents">{string.ORDERS_TEXT_4}</p>
          <p className="contents">{string.ORDERS_TEXT_5}</p>
          <p className="contents">{string.ORDERS_TEXT_6}</p>
          <h2 className="sub-heading letter-spacing">
            6. {string.IF_WE_CANNOT_FULFILL_YOUR_ORDER}
          </h2>
          <p className="contents">
            {string.IF_WE_CANNOT_FULFILL_YOUR_ORDER_TEXT_1}
          </p>
          <ul>
            <li>{string.IF_WE_CANNOT_FULFILL_YOUR_ORDER_TEXT_2}</li>
            <li>{string.IF_WE_CANNOT_FULFILL_YOUR_ORDER_TEXT_3}</li>
            <li>{string.IF_WE_CANNOT_FULFILL_YOUR_ORDER_TEXT_4}</li>
            <li>{string.IF_WE_CANNOT_FULFILL_YOUR_ORDER_TEXT_5}</li>
            <li>{string.IF_WE_CANNOT_FULFILL_YOUR_ORDER_TEXT_6}</li>
            <li>{string.IF_WE_CANNOT_FULFILL_YOUR_ORDER_TEXT_7}</li>
            <li>{string.IF_WE_CANNOT_FULFILL_YOUR_ORDER_TEXT_8}</li>
          </ul>
          <p className="contents">
            {string.IF_WE_CANNOT_FULFILL_YOUR_ORDER_TEXT_9}
          </p>
          <h2 className="sub-heading letter-spacing">
            7. {string.YOUR_RIGHT_TO_CANCEL_YOUR_ORDER_BEFORE_SHIPMENT}
          </h2>
          <p className="contents">
            {string.YOUR_RIGHT_TO_CANCEL_YOUR_ORDER_BEFORE_SHIPMENT_TEXT_1}
          </p>
          <p className="contents">
            {string.YOUR_RIGHT_TO_CANCEL_YOUR_ORDER_BEFORE_SHIPMENT_TEXT_2}{" "}
            <a
              href="/docs/terms/uk/consumer/collector-withdrawal-form"
              target="_blank"
              rel="noreferrer"
            >
              {string.WITHDRAWAL_FORM}
            </a>{" "}
            {string.VIA_EMAIL_TO_OUR_POST}{" "}
            <a
              href="mailto:hpsupport.limitededitions@hp.com"
              target="_blank"
              rel="noreferrer"
            >
              {string.HP_SUPPORT_LIMITED_EDITIONS}
            </a>
            .
          </p>
          <p className="contents">
            {string.YOUR_RIGHT_TO_CANCEL_YOUR_ORDER_BEFORE_SHIPMENT_TEXT_3}
          </p>

          <h2 className="sub-heading letter-spacing">8. {string.DELIVERY}</h2>
          <p className="contents">{string.DELIVERY_TEXT_1}</p>
          <p className="contents">{string.DELIVERY_TEXT_2}</p>
          <p className="contents">{string.DELIVERY_TEXT_3}</p>

          <h2 className="sub-heading letter-spacing">
            9. {string.OWNERSHIP_AND_RISK_TRANSFER}
          </h2>
          <p className="contents">
            {string.OWNERSHIP_AND_RISK_TRANSFER_TEXT_1}
          </p>

          <h2 className="sub-heading letter-spacing">
            10. {string.INTELLECTUAL_PROPERTY_RIGHTS_1}
          </h2>
          <p className="contents">
            {string.INTELLECTUAL_PROPERTY_RIGHTS_TEXT_1}
          </p>
          <p className="contents">
            {string.INTELLECTUAL_PROPERTY_RIGHTS_TEXT_2}
          </p>
          <h2 className="sub-heading letter-spacing">
            11. {string.YOUR_RIGHT_TO_CHANGE_YOUR_MIND_AFTER_SHIPMENT}
          </h2>
          <p className="contents">
            {string.YOUR_RIGHT_TO_CHANGE_YOUR_MIND_AFTER_SHIPMENT_TEXT_1}
            <strong>{string.CALENDER_DAYS}</strong>
            {string.AFTER_YOUR_ORDER_IS_DELIVERED_IN_FULL}
            <strong>{string.IF_YOUVE_HANDLED_AND_INSPECTED}</strong>
            {string.YOUR_RIGHT_TO_CHANGE_YOUR_MIND_AFTER_SHIPMENT_TEXT_2} (
            <strong>{string.LIKE_YOU_WOULD_IF_YOU_WERE_IN_A_SHOP}</strong>),{" "}
            {string.YOUR_RIGHT_TO_CHANGE_YOUR_MIND_AFTER_SHIPMENT_TEXT_3}
          </p>
          <p className="contents">
            {string.YOUR_RIGHT_TO_CHANGE_YOUR_MIND_AFTER_SHIPMENT_TEXT_4}
          </p>
          <p className="contents">
            {string.YOUR_RIGHT_TO_CHANGE_YOUR_MIND_AFTER_SHIPMENT_TEXT_5}
          </p>
          <h2 className="sub-heading letter-spacing">
            12. {string.RETURN_TO_HP_LIMITED_EDITIONS}
          </h2>
          <p className="contents">
            {string.YOU_CAN_CONTACT_HP_LIMITED_EDITIONS_AT}{" "}
            <a
              href="mailto:hpsupport.limitededitions@hp.com"
              target="_blank"
              rel="noreferrer"
            >
              {string.HP_SUPPORT_LIMITED_EDITIONS}
            </a>
            {string.RETURN_TO_HP_LIMITED_EDITIONS_TEXT_1}{" "}
            <a
              href="/docs/terms/uk/consumer/collector-withdrawal-form"
              target="_blank"
              rel="noreferrer"
            >
              {string.WITHDRAWAL_FORM}
            </a>
            , {string.BUT_THIS_IS_NOT_MANDATORY}
          </p>
          <p className="contents">
            {string.RETURN_TO_HP_LIMITED_EDITIONS_TEXT_2}
          </p>
          <p className="contents">
            {string.RETURN_TO_HP_LIMITED_EDITIONS_TEXT_3}
          </p>
          <p className="contents">
            {string.RETURN_TO_HP_LIMITED_EDITIONS_TEXT_4}
          </p>
          <ul>
            <li>{string.RETURN_TO_HP_LIMITED_EDITIONS_TEXT_5}</li>
            <li>{string.RETURN_TO_HP_LIMITED_EDITIONS_TEXT_6}</li>
            <li>{string.RETURN_TO_HP_LIMITED_EDITIONS_TEXT_7}</li>
            <li>{string.RETURN_TO_HP_LIMITED_EDITIONS_TEXT_8}</li>
            <li>{string.RETURN_TO_HP_LIMITED_EDITIONS_TEXT_9}</li>
          </ul>

          <h2 className="sub-heading letter-spacing">
            13. {string.EFFECTS_OF_CANCELLATION_WITHDRAWAL}
          </h2>
          <p className="contents">
            {string.EFFECTS_OF_CANCELLATION_WITHDRAWAL_TEXT_1}
          </p>
          <h2 className="sub-heading letter-spacing">
            {string.REFUND_EXCEPTIONS}:
          </h2>
          <p className="contents">
            {string.EFFECTS_OF_CANCELLATION_WITHDRAWAL_TEXT_2}
          </p>
          <p className="contents">
            {string.EFFECTS_OF_CANCELLATION_WITHDRAWAL_TEXT_3}
          </p>
          <p className="contents">
            {string.EFFECTS_OF_CANCELLATION_WITHDRAWAL_TEXT_4}
          </p>
          <p className="contents">
            {string.EFFECTS_OF_CANCELLATION_WITHDRAWAL_TEXT_5}
          </p>
          <h2 className="sub-heading letter-spacing">
            14. {string.DEFECTS_ON_DELIVERY}
          </h2>
          <p className="contents">{string.DEFECTS_ON_DELIVERY_TEXT_1}</p>
          <p className="contents">
            {string.DEFECTS_ON_DELIVERY_TEXT_2}
            <strong>{string.NO_MORE_THAN_30_DAYS}</strong>
            {string.TO_EXCERCISE_YOUR_30_DAY_RIGHT}
          </p>
          <p className="contents">{string.DEFECTS_ON_DELIVERY_TEXT_3}</p>
          <p className="contents">{string.DEFECTS_ON_DELIVERY_TEXT_4}</p>
          <p className="contents">{string.DEFECTS_ON_DELIVERY_TEXT_5}</p>
          <h2 className="sub-heading letter-spacing">
            15. {string.CONSUMER_LEGAL_GUARANTEE_1}
          </h2>
          <p className="contents">{string.CONSUMER_LEGAL_GUARANTEE_TEXT_1}</p>
          <p className="contents">{string.CONSUMER_LEGAL_GUARANTEE_TEXT_2}</p>
          <p className="contents">{string.CONSUMER_LEGAL_GUARANTEE_TEXT_3}</p>
          <p className="contents">{string.CONSUMER_LEGAL_GUARANTEE_TEXT_4}</p>
          <p className="contents">
            {string.CLICK_HERE_FOR_MORE_INFORMATION}:{" "}
            <a
              href="https://uk.hyperx.com/pages/legal-guarantee-of-conformity-of-goods"
              target="_blank"
              rel="noreferrer"
            >
              {string.CONSUMER_LEGAL_GUARANTEE} ({string.COUNTRY_NAME})
            </a>
            .
          </p>
          <h2 className="sub-heading letter-spacing">
            16. {string.GUARANTEE_LIMITATIONS_AND_EXCLUSIONS}
          </h2>
          <p className="contents">
            {string.GUARANTEE_LIMITATIONS_AND_EXCLUSIONS_TEXT_1}
          </p>
          <ol>
            <li>{string.GUARANTEE_LIMITATIONS_AND_EXCLUSIONS_TEXT_2}</li>
            <li>{string.GUARANTEE_LIMITATIONS_AND_EXCLUSIONS_TEXT_3}</li>
            <li>{string.GUARANTEE_LIMITATIONS_AND_EXCLUSIONS_TEXT_4}</li>
          </ol>
          <h2 className="sub-heading letter-spacing">
            17. {string.DISPUTE_RESOLUTION}
          </h2>
          <p className="contents">
            {string.THE_HP_LIMITED_EDITIONS_CUSTOMER_SERVICE_DEPARTMENT}:{" "}
            <a
              href="mailto:hpsupport.limitededitions@hp.com"
              target="_blank"
              rel="noreferrer"
            >
              {string.HP_SUPPORT_LIMITED_EDITIONS}
            </a>
            .
          </p>
          <h2 className="sub-heading letter-spacing">
            18. {string.LIMITATION_OF_LIABILITY}
          </h2>
          <p className="contents">{string.LIMITATION_OF_LIABILITY_TEXT_1}</p>
          <p className="contents">{string.LIMITATION_OF_LIABILITY_TEXT_2}</p>
          <p className="contents">{string.LIMITATION_OF_LIABILITY_TEXT_3}</p>
          <h2 className="sub-heading letter-spacing">
            19. {string.PROTECTION_OF_PERSONAL_DATA}
          </h2>
          <p className="contents">
            {string.PROTECTION_OF_PERSONAL_DATA_TEXT_1}{" "}
            <a
              href="https://www.hp.com/gb-en/privacy/privacy_notice.html"
              target="_blank"
              rel="noreferrer"
            >
              "{string.PERSONAL_DATA_RIGHTS_NOTICE}"
            </a>
            .
          </p>
          <h2 className="sub-heading letter-spacing">
            20. {string.OTHER_APPLICABLE_TERMS}
          </h2>
          <p className="contents">{string.OTHER_APPLICABLE_TERMS_TEXT_1}</p>
          <p className="contents">{string.OTHER_APPLICABLE_TERMS_TEXT_2}</p>
          <p className="contents">{string.OTHER_APPLICABLE_TERMS_TEXT_3}</p>
          <p className="contents">{string.OTHER_APPLICABLE_TERMS_TEXT_4}</p>
          <p className="contents">{string.OTHER_APPLICABLE_TERMS_TEXT_5}</p>
          <p className="contents">
            <strong>{string.OTHER_APPLICABLE_TERMS_TEXT_6}</strong>
          </p>
          <p className="contents">{string.REVISION_DATE}</p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
