import React, { useEffect, useState } from "react";
import "./PersonalNotePage.scss";
import LogoHP from "../../Components/LogoHP/LogoHP";
import footerLogo from "../../Utils/Icons/footer-logo.svg";
import { string } from "../../Localization";
import { useNavigate } from "react-router-dom";
import { footerLinks } from "../../Utils/ApiHelper";
import { SUPPORTED_COUNTRY } from "../../Utils/Constants";
import { sendGALoadedPersonalNotePage } from "../../Utils/GAHelper";

const PersonalNotePage = (props) => {
  const { PersonalNote_title } = props;

  const navigate = useNavigate();

  const [links, setLinks] = useState("");

  useEffect(() => {
    footerLinks(SUPPORTED_COUNTRY).then((res) => {
      setLinks(res.data);
    });
    sendGALoadedPersonalNotePage(PersonalNote_title);
  }, []);

  return (
    <>
      <div className="PersonalNotePage-container">
        <div className="PersonalNotePage-text">
          <div className="PersonalNotePage-top-header">
            <LogoHP />
          </div>
          <div className="PersonalNotePage-page-text">
            <div className="PersonalNotePage-title">{PersonalNote_title}</div>

            <div className="PersonalNotePage-content-text">
              {/* <div className="tr1">{string.START_PRINTING_TEXT_1}</div>
              <div className="tr2">{string.START_PRINTING_TEXT_2}</div> */}
              <button
                className="PersonalNotePage-button"
                onClick={() => navigate("/")}
              >
                Go home
              </button>
            </div>
          </div>
        </div>
        <div className="footer-container-persoanlNote">
          <div className="PersonalNotePage-page-copyright">
            <div className="PersonalNotePage-page-footer-img">
              <img
                src={footerLogo}
                alt=""
                className="PersonalNotePage-page-footer-logo"
              />
            </div>
            <div className="PersonalNotePage-page-footer-copyright">
              {string.HP_DEVELOPMENT_COMPANY_FOOTER}
            </div>
          </div>
          <div className="personalNote-footer-right">
            <a
              href={links["terms-and-conditions"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="personalNote-footer-text">
                {string.TERMS_AND_CONDITION_FOOTER}
              </div>
            </a>
            <a
              href={links["personal-data-rights"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="personalNote-footer-text">
                {string.PERSONAL_DATA_RIGHTS_NOTICE}
              </div>
            </a>
            <a
              href={links["cookies"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className="personalNote-footer-text"
                style={{ border: "none" }}
              >
                {string.COOKIES}
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalNotePage;
