import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./PgsPayment.scss";
import { getPgsInformation } from "../../../../Utils/ApiHelper";
import { sendGALoadedPgsPaymentPage } from "../../../../Utils/GAHelper";

const PgsPayment = () => {
  const [pgsData, setPgsData] = useState({});
  const [pgsUrl, setPgsurl] = useState("");

  const { order_token } = useParams();

  useEffect(() => {
    sendGALoadedPgsPaymentPage(order_token);
    getPgsInformation(order_token).then((pgsRes) => {
      setPgsurl(pgsRes?.data?.data?.pgs_redirect_url);
      setPgsData(pgsRes?.data?.data?.pgs_request_body);
      document.getElementById("pgsForm").submit();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pgs-block">
      {pgsData && (
        <form action={pgsUrl} id="pgsForm" method="POST">
          <input
            type="hidden"
            name="phcRequest"
            value={JSON.stringify(pgsData)}
          ></input>
        </form>
      )}
    </div>
  );
};

export default PgsPayment;
