import React from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import "./AdminModal.scss";
import Closebutton from "../../../Utils/Icons/close.svg";
import { string } from "../../../Localization";

const AdminModal = ({
  openModal,
  handleClose,
  handleConfirm,
  heading,
  subheading,
}) => {
  return (
    <>
      <Modal show={openModal} animation={false} className="adminModal-block">
        <ModalBody className="adminModal-wrapper">
          <div className="adminModal-body">
            <div className="close-btn-container">
              <img
                className="close-btn"
                src={Closebutton}
                onClick={handleClose}
                alt="Close"
              />
            </div>
            <div className="pt-3 px-5 d-flex flex-column align-item-center">
              <div className="d-flex justify-content-center mb-4">
                <span className="title d-block ">{heading}</span>
              </div>
              <span className="text-message">{subheading}</span>
              <div className="d-flex justify-content-center">
                <Button
                  className="order-button no-button me-3"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  {string.NO}
                </Button>
                <Button
                  className="order-button yes-button"
                  onClick={() => {
                    handleConfirm();
                  }}
                >
                  {string.YES}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AdminModal;
