import { string } from "../../../Localization";

const UsageReportHash = (usageData) => {
  const toDecimal = (value) => value?.toFixed(2);
  const toPercent = (value) => toDecimal(value) + "%";

  return [
    { title: string.REPORT_UPDATED_DATE, value: usageData?.report_date },
    {
      title: string.TOTAL_NUMBER_OF_PHOTOGRAPHERS,
      value: usageData?.no_of_photographers,
    },
    {
      title: string.TOTAL_PHOTOGRAPHER_APPROVED,
      value: usageData?.no_of_photographers_approved,
    },
    {
      title: string.TOTAL_PHOTOGRAPHER_UPLOADED,
      value: usageData?.no_of_images_uploaded,
    },
    {
      title: string.TOTAL_IMAGES_ENCRYPTED,
      value: usageData?.no_of_images_encrypted,
    },
    {
      title: `${string.TOTAL_IMAGES_ENCRYPTED} (${string.LIMITED_EDITIONS_SMALL})`,
      value: usageData?.no_of_images_encrypted_by_limited_edition,
    },
    {
      title: string.TOTAL_IMAGES_ARTIST_PROOF,
      value: usageData?.no_of_images_used_by_artist_proof,
    },
    {
      title: string.TOTAL_COPIES_LIMITED_EDITION,
      value: usageData?.no_of_copies_for_all_limited_edition,
    },
    {
      title: string.AVG_COPIES_NUM_LIMITED_EDITION,
      value: toDecimal(usageData?.avg_no_of_copies_per_limited_edition),
    },
    {
      title: string.AVG_SELLING_PRICE_DESIGNS,
      value: toDecimal(
        usageData?.aunapo_sales_revenue_data
          ?.desigs_available_for_sale_avg_selling_price
      ),
    },
    {
      title: string.AVG_REVENUE_PRICE_DESIGNS,
      value: toDecimal(
        usageData?.aunapo_sales_revenue_data
          ?.desigs_available_for_sale_avg_income_price
      ),
    },
    {
      title: `${string.TOTAL_SALE} (${string.WITH_TAX})`,
      value: toDecimal(
        usageData?.aunapo_sales_revenue_data?.total_sales_with_tax
      ),
    },
    {
      title: `${string.AVG_SALES} (${string.WITH_TAX})`,
      value: toDecimal(
        usageData?.aunapo_sales_revenue_data?.avg_sales_with_tax
      ),
    },
    {
      title: string.TOTAL_PRICING,
      value: toDecimal(usageData?.aunapo_sales_revenue_data?.total_sales),
    },
    {
      title: string.AVG_PRICING,
      value: toDecimal(usageData?.aunapo_sales_revenue_data?.avg_sales),
    },
    {
      title: string.TOTAL_HP_REVENUES,
      value: toDecimal(usageData?.aunapo_sales_revenue_data?.total_hp_revenue),
    },
    {
      title: string.AVG_HP_REVENUE,
      value: toDecimal(usageData?.aunapo_sales_revenue_data?.avg_hp_revenue),
    },
    {
      title: string.AVG_HP_REVENUE_PHOTOGRAPHER,
      value: toDecimal(
        usageData?.aunapo_sales_revenue_data?.avg_hp_revenue_per_photographer
      ),
    },
    {
      title: string.TOTAL_PSP_REVENUES,
      value: toDecimal(usageData?.aunapo_sales_revenue_data?.total_psp_revenue),
    },
    {
      title: string.TOTAL_PHOTOGRAPHER_REVENUES,
      value: toDecimal(
        usageData?.aunapo_sales_revenue_data?.total_photographer_revenue
      ),
    },
    {
      title: string.AVG_REVENUE_PER_PHOTOGRAPHER_ORDER,
      value: toDecimal(
        usageData?.aunapo_sales_revenue_data?.avg_photographer_revenue_per_order
      ),
    },
    {
      title: string.AVG_PHOTOGRAPHER_REVENUE,
      value: toDecimal(
        usageData?.aunapo_sales_revenue_data?.avg_photographer_revenue
      ),
    },
    {
      title: string.LIMITED_EDITION_CREATED,
      value: usageData?.no_of_limited_editions_created,
    },
    {
      title: string.AVG_NUMBER_OF_LIMITED_EDITION,
      value: toDecimal(usageData?.avg_no_of_limited_editions_created),
    },
    {
      title: string.AVG_NUMBER_OF_EDITION_OPEN_LIMITED,
      value: toDecimal(usageData?.avg_no_of_all_editions_created),
    },
    {
      title: string.NUMBER_OF_AUTHORIZED_COPIES,
      value: usageData?.no_of_authorized_copies,
    },
    {
      title: string.AVG_NUMBER_OF_AUTHORIZED_COPIED_PER_PHOTOGRAPGHER,
      value: toDecimal(usageData?.no_of_authorized_copies_per_photographer),
    },
    { title: string.NO_OF_PRINT_SOLD, value: usageData?.no_of_prints_sold },
    {
      title: string.AVG_NUMBER_OF_PRINTS_SOLD_PER_PHOTOGRAPGHER,
      value: toDecimal(usageData?.avg_no_of_prints_sold_per_photographer),
    },
    {
      title: string.TOTAL_NO_OPEN_EDITION,
      value: usageData?.no_of_open_editions_created,
    },
    {
      title: string.AVG_NUMBER_OF_OPEN_EDITION,
      value: toDecimal(usageData?.avg_no_of_open_editions_created),
    },
    {
      title: string.TOTAL_DESIGN_DRAFT,
      value: usageData?.no_of_projects_in_draft,
    },
    {
      title: string.TOTAL_ARTIST_INITIATED,
      value: usageData?.no_of_artist_proof_initiated,
    },
    {
      title: string.AVG_IMAGES_PHOTOTOGRAPGER,
      value: toDecimal(usageData?.avg_no_of_images_uploaded_per_photographer),
    },
    {
      title: string.TOTAL_PHOTOGRAPHER_ATLEAST_1_PHOTO,
      value: usageData?.no_of_photographers_with_atleast_one_uploaded_image,
    },
    {
      title: string.PERCENTAGE_PHOTOGRAPHER_ATLEAST_1_PHOTO,
      value: toPercent(
        usageData?.perc_of_photographers_who_uploaded_atleast_one_image
      ),
    },
    {
      title: string.TOTAL_ENCRYPTED_ATLEAST_1_PHOTO,
      value: usageData?.no_of_photographers_with_atleast_one_encryption,
    },
    {
      title: `${string.TOTAL_CLICKED} "${string.SALES_LINK}" ${string.OR} "${string.COPY_1} ${string.SALES_LINK}" ${string.BUTTON}`,
      value: usageData?.no_of_photographers_copied_sales_link,
    },
    {
      title: `${string.PERCENTAGE_CLICKED} "${string.SALES_LINK}" ${string.OR} "${string.COPY_1} ${string.SALES_LINK}" ${string.BUTTON}`,
      value: toPercent(usageData?.perc_of_photographers_copied_sales_link),
    },
    {
      title: string.TOTAL_SCAN_QR_CODE,
      value: usageData?.no_of_qr_scan_views,
    },
    {
      title: string.AVG_SCAN_QR_CODE,
      value: toDecimal(usageData?.avg_no_of_qr_scan_views),
    },
    {
      title: string.TOTAL_PUBLIC_PAGE_VIEW,
      value: usageData?.total_public_page_views,
    },
    {
      title: `${string.TOTAL_PUBLIC_PAGE_VIEW} (${string.LIMITED_EDITIONS_SMALL})`,
      value: usageData?.total_limited_edition_public_page_views,
    },
    {
      title: `${string.TOTAL_PUBLIC_PAGE_VIEW} (${string.OPEN_EDITION})`,
      value: usageData?.total_open_edition_public_page_views,
    },
    {
      title: string.PHOTOGRAPHER_ADD_SIGN,
      value: usageData?.no_of_photographers_added_signature,
    },
    {
      title: string.NUMBER_ARTIST_SOLD,
      value: usageData?.no_of_artist_proofs_sold,
    },
    {
      title: string.PHOTOGRAPHER_ASKING_ARTIST_PROOF,
      value: usageData?.no_of_photographers_requesting_artist_proof,
    },
    {
      title: string.COPIES_WITH_PERSONAL_NOTE,
      value: usageData?.no_of_limited_editions_sold_with_personal_note,
    },
    {
      title: `${string.PERCENTAGE_OF_COPIES_WITH_PERSONAL_NOTE}`,
      value: toPercent(
        usageData?.perc_of_limited_editions_sold_with_personal_note
      ),
    },
    { title: string.OPEN_API_COUNT, value: usageData?.openai_api_usage_count },
    {
      title: string.PHOTOGRAPHER_USING_OPEN_AI,
      value: usageData?.active_ai_photographer_count,
    },
    { title: string.AI_IMAGE_USED, value: usageData?.no_of_ai_images_used },
    {
      title: string.AI_IMAGE_ENCYPTED,
      value: usageData?.no_of_ai_images_encrypted,
    },
    {
      title: string.COPIES_OF_AI_IMAGES,
      value: usageData?.no_of_copies_of_ai_based_limited_edition,
    },
    {
      title: string.AVG_COPIES_PER_AI_BASED,
      value: toDecimal(
        usageData?.avg_no_of_copies_per_ai_based_limited_edition
      ),
    },
    {
      title: string.AVG_AI_IMAGES_PER_PHOTOGRAPHER,
      value: toDecimal(usageData?.avg_no_of_ai_images_used_per_photographer),
    },
    {
      title: string.PHOTOGRAPHERS_ATLEAT_1_AI_IMAGES,
      value: usageData?.no_of_photographers_with_atleast_one_ai_image,
    },
    {
      title: `${string.PERCENTAGE_PHOTOGRAPHER_ATLEAST_1_AIPHOTO}`,
      value: toPercent(
        usageData?.perc_of_photographers_who_used_atleast_one_ai_image
      ),
    },
    {
      title: string.PHOTOGRAPHERS_ATLEAT_1_AI_IMAGES_ENCRYPTION,
      value:
        usageData?.no_of_photographers_with_atleast_one_ai_image_encryption,
    },
    {
      title: string.UNSOLD_AUTHORIZED_COPIES,
      value: usageData?.no_of_unsold_authorized_copies,
    },
    {
      title: `${string.PERCENTAGE_QR_SCANNED_AMONG_PRINT}`,
      value: toPercent(usageData?.perc_of_qr_code_scanned_among_prints_sold),
    },
    {
      title: `${string.PERCENTAGE_SCANNED_PHOTOGRAPGER_ATLEAST_ONCE}`,
      value: toPercent(usageData?.perc_of_photographers_scanned_atleast_once),
    },
    {
      title: `${string.PERCENTAGE_MOBILE_WITH_PERSONAL_NOTE}`,
      value: toPercent(
        usageData?.perc_of_limited_editions_sold_with_personal_note_in_mobile
      ),
    },
    {
      title: `${string.PERCENTAGE_MOBILE_USER_MONITORING_DASHBOARD}`,
      value: toPercent(
        usageData?.perc_of_photographers_accessing_through_mobile
      ),
    },
    {
      title: string.AVG_IMAGE_ENCRYPTED,
      value: toDecimal(
        usageData?.avg_no_of_images_encrypted_by_all_photographers
      ),
    },
    {
      title: string.AVG_IMAGE_ENCRYPTED_ACTIVE_PHOTOGRAPHER,
      value: toDecimal(
        usageData?.avg_no_of_images_encrypted_by_active_photographers
      ),
    },
    {
      title: `${string.GA} : ${string.PURCHASE_PAGE_EVENT_COUNT}`,
      value: usageData?.ga_metric_public_view_event_count,
    },
    {
      title: `${string.GA} : ${string.PURCHASE_PAGE_USER_COUNT}`,
      value: usageData?.ga_metric_public_view_total_users,
    },
    {
      title: `${string.GA} : ${string.PURCHASE_PAGE_EVENT_COUNT_PER_PERSON}`,
      value: usageData?.ga_metric_public_view_event_count_per_user?.toFixed(2),
    },
    {
      title: `${string.GA} : ${string.PURCHASE_PAGE_SESSION_COUNT}`,
      value: usageData?.ga_metric_public_view_sessions_count,
    },
    {
      title: `${string.GA} : ${string.PURCHASE_PAGE_SOLD_PERCENT}`,
      value: usageData?.ga_metric_public_view_conversion_rate,
    },
  ];
};

export default UsageReportHash;
