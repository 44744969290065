import { useState } from "react";
import { Icons } from "../../../Utils/Icons";
import "./Packaging.scss";
import rightArrow from "../../../Utils/Icons/arrow-right.svg";
import leftArrow from "../../../Utils/Icons/arrow-left.svg";
import { string } from "../../../Localization";

const Packaging = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + 2) % 2);
  };

  const handleNext = (e) => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % 2);
  };

  const getImageByIndex = (index) => {
    switch (index) {
      case 0:
        return Icons.package1;
      case 1:
        return Icons.package2;
      default:
        return null;
    }
  };

  const currentImage = getImageByIndex(currentIndex);
  return (
    <div className="summary-package-wrapper">
      <div className="package-container">
        <img
          className="package-preview"
          src={currentImage}
          alt="package preview"
        ></img>
        <div className="carousel">
          <div
            onClick={() => {
              handlePrevious();
            }}
          >
            <img src={leftArrow} alt="previous" className="carousel-icon" />
          </div>
          <div
            onClick={() => {
              handleNext();
            }}
          >
            <img src={rightArrow} alt="next" className="carousel-icon" />
          </div>
        </div>
        <div className="radio-buttons">
          <div className="btns">
            <input
              type="radio"
              id="previous"
              name="carousel"
              checked={currentIndex === 0}
              onChange={() => handlePrevious()}
            />

            <input
              type="radio"
              id="next"
              name="carousel"
              checked={currentIndex === 1}
              onChange={() => handleNext()}
            />
          </div>
        </div>
      </div>
      <div>
        <ul className="list-contents">
          <li>{string.PACKAGING_TEXT_1} </li>
          <li>{string.PACKAGING_TEXT_2}</li>
          <li>{string.PACKAGING_TEXT_3}</li>
          <li>{string.PACKAGING_TEXT_4}</li>
          <li>{string.PACKAGING_TEXT_5}</li>
          <li>{string.CERTIFIED_AND_CLIMATE_NEUTRAL}</li>
        </ul>
      </div>
    </div>
  );
};

export default Packaging;
