import React, { useEffect, useState } from "react";
import "./WelcomePage.scss";
import welcomeImage from "../../Utils/Icons/welcome-image.jpg";
import instagram_logo from "../../Utils/Icons/instagram_logo.svg";
import { sendGALoadedWelcomeMessage } from "../../Utils/GAHelper";
import LogoHP from "../../Components/LogoHP/LogoHP";
import { string } from "../../Localization";
import ProfileButton from "../ProfileButton/ProfileButton";
import SinglePageFooter from "../../Components/ModalFooter/SinglePageFooter";
import { useDispatch, useSelector } from "react-redux";
import {
  reActivateAccount,
  updateInstagramProfile,
} from "../../Utils/ApiHelper";
import { setCurrentUserDetails } from "../../Store/Project/UserSlice";
import ConfirmUsernameModal from "./ConfirmUsernameModal/ConfirmUsernameModal";
import { setCanShowStatusModal } from "../../Store/Project/ProjectSlice";
import { useNavigate } from "react-router-dom";
import { redirectToHomePage } from "../../Utils/projectHelper";

const WelcomePage = () => {
  const { currentUserDetails } = useSelector((state) => state.user);
  const { canShowStatusModal } = useSelector((state) => state.project);
  const [instaUrl, setInstaUrl] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    sendGALoadedWelcomeMessage();
  }, []);

  const handleInstagramUpdateProfile = () => {
    if (instaUrl !== "") {
      const instaUrlformat = `https://www.instagram.com/${instaUrl}`;
      updateInstagramProfile(instaUrlformat).then((res) => {
        dispatch(setCurrentUserDetails(res.data.data));
      });
    }
  };

  return (
    <>
      <div className="welcome-page-component">
        <div className="welcome-page-text-container">
          <div className="welcome-text">
            <div className="welcome-top-header">
              <LogoHP />
              <div className="welcome-profile-button-container">
                <ProfileButton />
              </div>
            </div>

            {!currentUserDetails?.instagram_url == "" ? (
              <div className="welcome-page-text">
                <div className="welcome-to-HP-limited-editions">
                  {string.THANK_YOU_FOR_JOINING_1}
                </div>
                <div className="limited-editions-container">
                  <div className="tr2">{string.WELCOME_PAGE_TEXT_2}</div>
                </div>
              </div>
            ) : (
              <div className="welcome-page-text">
                <div className="welcome-to-HP-limited-editions">
                  {string.WELCOME_TO_HP_LIMITED_EDITIONS}
                </div>

                <div className="limited-editions-container">
                  <div className="tr1">{string.THANK_YOU_FOR_JOINING}</div>
                  <div className="tr2">{string.WELCOME_PAGE_TEXT_1}</div>
                  <label htmlFor="">{string.INSTAGRAM_USERNAME}*</label>
                  <div className="welcome_page_input_field">
                    <input
                      type="text"
                      placeholder={string.YOUR_USERNAME}
                      className="welcome_input_box"
                      onChange={(e) => setInstaUrl(e.target.value)}
                    />
                    <img
                      src={instagram_logo}
                      alt="instagram_logo"
                      className="welcome_page_instagram_logo"
                    />
                    <div className="max-characters">
                      https://www.instagram.com/{instaUrl}
                    </div>
                  </div>

                  <button
                    className={`welcome_page_btn ${
                      instaUrl == "" ? "disable_username" : "enable_username"
                    }`}
                    onClick={() => {
                      instaUrl != "" && dispatch(setCanShowStatusModal(true));
                    }}
                  >
                    {string.CONFIRM}
                  </button>
                </div>
              </div>
            )}

            <div className="welcome-page-copyright">
              <SinglePageFooter />
            </div>
          </div>
        </div>
        <div className="welcome-image-container">
          <img
            src={welcomeImage}
            alt="welcome-image"
            className="welcome-image"
          />
        </div>
      </div>
      <ConfirmUsernameModal
        canShow={canShowStatusModal}
        instaUrl={instaUrl}
        handleInstagramUpdateProfile={handleInstagramUpdateProfile}
      />
    </>
  );
};

export default WelcomePage;
