import React, { useEffect, useState } from "react";
import {
  ARTIST_NAME_SORT,
  ASCENDING_ORDERS,
  DESCENDING_ORDERS,
  EMAIL_SORT,
  PROJECT_ORDERS_PAGE,
  TITLE_SORT,
} from "../../../Utils/Constants";
import ProjectLoader from "../../../Components/Loaders/ProjectLoader/ProjectLoader";
import { string } from "../../../Localization";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setActivePageIndex,
  setProjectToken,
  setUserColumnKey,
  setUserTypeKey,
} from "../../../Store/Project/AdminSlice";
import { setSalesOrderModal } from "../../../Store/Project/ModalSlice";
import AdminViewModal from "../../../Components/Modal/AdminViewModal/AdminViewModal";
import StatusColumn from "./StatusColumn";
import { Tooltip } from "@mui/material";

const LimitedEditionUserTable = (props) => {
  const { result, isLimitedEdition } = props;
  const { salesOrderModal } = useSelector((state) => state.modal);

  const [data, setData] = useState([]);
  const [email, setemail] = useState(true);
  const [artistNameSort, setArtistNameSort] = useState(true);
  const [titleSort, setTileSort] = useState(true);
  const [createdAtSort, setCreatedAtSort] = useState(true);
  const [largeData, setLargeData] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setData(result);
  }, [result]);

  const handleRowClick = (item) => {
    navigate(`../project_orders/${item.project_token}`);
    dispatch(setProjectToken(item.project_token));
    dispatch(setActivePageIndex(PROJECT_ORDERS_PAGE));
  };
  const handleRowClickLimited = (item) => {
    navigate(`project_orders/${item.project_token}`);
    dispatch(setProjectToken(item.project_token));
    dispatch(setActivePageIndex(PROJECT_ORDERS_PAGE));
  };
  const handleViewClick = (data) => {
    setLargeData(data);
    dispatch(setSalesOrderModal(true));
  };

  const handleClose = () => {
    dispatch(setSalesOrderModal(false));
  };

  return (
    <>
      <div className="admin_tables_wrapper">
        <ProjectLoader />
        <table className="table table-borderless table_block">
          <thead className="table_head">
            <tr>
              <th>
                #
                {createdAtSort ? (
                  <div
                    className="admin_navBar_sort fa fa-angle-down"
                    onClick={() => {
                      dispatch(setUserColumnKey(""));
                      dispatch(setUserTypeKey(ASCENDING_ORDERS));
                      setCreatedAtSort(false);
                    }}
                  ></div>
                ) : (
                  <div
                    className="admin_navBar_sort fa fa-angle-up"
                    onClick={() => {
                      dispatch(setUserColumnKey(""));
                      dispatch(setUserTypeKey(DESCENDING_ORDERS));
                      setCreatedAtSort(true);
                    }}
                  ></div>
                )}
              </th>
              <th>{string.CREATED_AT}</th>
              <th>
                {string.ARTIST_NAME}{" "}
                {artistNameSort ? (
                  <div
                    className="admin_navBar_sort fa fa-angle-down"
                    onClick={() => {
                      dispatch(setUserColumnKey(ARTIST_NAME_SORT));
                      dispatch(setUserTypeKey(ASCENDING_ORDERS));
                      setArtistNameSort(false);
                    }}
                  ></div>
                ) : (
                  <div
                    className="admin_navBar_sort fa fa-angle-up"
                    onClick={() => {
                      dispatch(setUserColumnKey(ARTIST_NAME_SORT));
                      dispatch(setUserTypeKey(DESCENDING_ORDERS));
                      setArtistNameSort(true);
                    }}
                  ></div>
                )}
              </th>
              <th>
                {string.EMAIL}{" "}
                {email ? (
                  <div
                    className="admin_navBar_sort fa fa-angle-down"
                    onClick={() => {
                      dispatch(setUserColumnKey(EMAIL_SORT));
                      dispatch(setUserTypeKey(ASCENDING_ORDERS));
                      setemail(false);
                    }}
                  ></div>
                ) : (
                  <div
                    className="admin_navBar_sort fa fa-angle-up"
                    onClick={() => {
                      dispatch(setUserColumnKey(EMAIL_SORT));
                      dispatch(setUserTypeKey(DESCENDING_ORDERS));
                      setemail(true);
                    }}
                  ></div>
                )}
              </th>
              <th>
                {string.TITLE}
                {titleSort ? (
                  <div
                    className="admin_navBar_sort fa fa-angle-down"
                    onClick={() => {
                      dispatch(setUserColumnKey(TITLE_SORT));
                      dispatch(setUserTypeKey(ASCENDING_ORDERS));
                      setTileSort(false);
                    }}
                  ></div>
                ) : (
                  <div
                    className="admin_navBar_sort fa fa-angle-up"
                    onClick={() => {
                      dispatch(setUserColumnKey(TITLE_SORT));
                      dispatch(setUserTypeKey(DESCENDING_ORDERS));
                      setTileSort(true);
                    }}
                  ></div>
                )}
              </th>
              <th>{string.THUMBNAIL}</th>
              <th>{string.DESCRIPTION}</th>
              <th>{string.STATUS}</th>
              <th>{string.WITH_AI}</th>
              <th>{string.DIMENSION}</th>
              <th>{string.REMAINING_COPIES}</th>
              <th>{string.TOTAL_COPIES}</th>
              <th>{string.VIEW_COUNT}</th>
              <th>
                {isLimitedEdition == true
                  ? string.SELLING_PRICE_2
                  : string.PRICE}
              </th>
              {/* <th>{string.PRICE}</th> */}
              <th>{string.COPY_RIGHT}</th>
              <th>{string.LIMITED_EDITION}</th>
              <th>{string.ARTIST_PROOF}</th>
              <th>{string.ORDERS_PLACED}</th>
              <th>{string.DELETED}</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr className="admin_table_row" key={index}>
                  {isLimitedEdition == true ? (
                    <>
                      <td
                        className="order_number"
                        onClick={() => {
                          handleRowClickLimited(item);
                        }}
                      >
                        <Tooltip title={string.CLICK_TO_VIEW_DETAILS} arrow>
                          <span>{item.id}</span>
                        </Tooltip>
                      </td>
                    </>
                  ) : (
                    <>
                      <td
                        className="order_number"
                        onClick={() => {
                          handleRowClick(item);
                        }}
                      >
                        <Tooltip title={string.CLICK_TO_VIEW_DETAILS} arrow>
                          <span>{item.id}</span>
                        </Tooltip>
                      </td>
                    </>
                  )}
                  <td>{item.created_at}</td>
                  <td>{item.artist_name ? item.artist_name : "-"}</td>
                  <td className="text-start">{item.email}</td>
                  <td>{item.title}</td>
                  <td>
                    <img
                      src={item.preview_thubmnail_url}
                      className="thumbnail-img"
                    />
                  </td>
                  <td>
                    <div className="large_text_wrapper">
                      <div
                        className={
                          item.description == "No description is Available"
                            ? ""
                            : "overflow_text"
                        }
                      >
                        {item.description === "" ? "-" : item.description}
                      </div>
                      <div
                        className={
                          item.description == "No description is Available" ||
                          item.description === "null" ||
                          item.description === ""
                            ? ""
                            : "fa fa-eye"
                        }
                        onClick={() => {
                          handleViewClick(item.description);
                        }}
                      ></div>
                    </div>
                  </td>
                  <td>
                    <StatusColumn status={item?.status} />
                  </td>
                  <td>{item.id}</td>
                  <td>{item.dimension}</td>
                  <td>{item.remaining_print_count}</td>
                  <td>{item.no_of_copies}</td>
                  <td>{item.view_count}</td>
                  <td>{item.price}</td>
                  <td>
                    {item.copyright_text === "" ? "-" : item.copyright_text}
                  </td>
                  <td>{item.limited_edition ? "Yes" : "No"}</td>
                  <td>{item.artist_proof ? "Yes" : "No"}</td>
                  <td>{item.orders_placed}</td>
                  <td>{item.deleted ? "Yes" : "No"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <AdminViewModal
        handleClose={handleClose}
        jsonStringData={largeData}
        openModal={salesOrderModal}
        isOnlyText={true}
      />
    </>
  );
};

export default LimitedEditionUserTable;
