import React from "react";
import "./ShareLinkModal.scss";
import { Modal, ModalBody } from "react-bootstrap";
import Closebutton from "../../../Utils/Icons/close.svg";
import FacebookIcon from "../../../Utils/Icons/facebook-black.svg";
import TwitterIcon from "../../../Utils/Icons/twitter-black.svg";
import LinkedIN_I from "../../../Utils/Icons/linkedIn-i-black.svg";
import LinkedIN_N from "../../../Utils/Icons/linkedIn-n-black.svg";
import {
  sendGAClickedsharethroughfacebook,
  sendGAClickedsharethroughlinkedin,
  sendGAClickedsharethroughtwitter,
} from "../../../Utils/GAHelper";

const ShareLinkModal = ({ isOpen, onClose, onCopy, link, message }) => {
  return (
    <Modal show={isOpen} className="shareLink-container-wrap">
      <ModalBody className="shareLink-modal-container">
        <div className="shareLink-body">
          <div className="close-btn-container">
            <img
              className="close-btn"
              src={Closebutton}
              onClick={onClose}
              alt="Close"
            />
          </div>
          <div className="shareLink-header">
            <h4 className="text">Share sales link in social media</h4>
          </div>
          <div className="shareLink-description">
            Please select the social media where you want to share
          </div>
          <div className="shareLink-button-container">
            {/* <a
              className="icon-container"
              href={`https://www.instagram.com/stories?source=ig_share_sheet&url=${link}`}
              // href={`https://www.instagram.com?url=${link}`}
              target="_blank"
              rel="noreferrer"
            >
              <img src={InstagramIcon} alt="insta" className="insta-icon" />
            </a> */}
            <a
              target="_blank"
              rel="noreferrer"
              className="icon-container"
              onClick={sendGAClickedsharethroughfacebook}
              href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                link + "?utm_source=facebook_saleslink"
              )}`}
            >
              <img
                src={FacebookIcon}
                alt="facebook"
                className="facebook-icon"
              />
            </a>{" "}
            <a
              href={`https://twitter.com/intent/tweet?url=${link}?utm_source=twitter_saleslink&text=${encodeURI(
                message
              )}`}
              target="_blank"
              rel="noreferrer"
              className="icon-container"
              onClick={sendGAClickedsharethroughtwitter}
            >
              {" "}
              <img src={TwitterIcon} alt="twitter" className="twitter-icon" />
            </a>{" "}
            <a
              className="icon-container"
              href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
                link + "?utm_source=linked_in_saleslink"
              )}`}
              target="_blank"
              rel="noreferrer"
              onClick={sendGAClickedsharethroughlinkedin}
            >
              <div className="linkedIn-icon">
                <img src={LinkedIN_I} alt="linkedIn" />
                <img src={LinkedIN_N} alt="linkedIn" />
              </div>
            </a>
          </div>
          <div className="shareLink-input-container">
            <div className="input-box">
              <div className="link-text">{link}</div>
              <div onClick={onCopy}>
                <div className="copy-btn">Copy</div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ShareLinkModal;
