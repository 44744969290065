import React, { useState } from "react";
import BasicModal from "../../../../Components/Modal/BasicModal/BasicModal";
import "./ViewWallet.scss";
import { string } from "../../../../Localization";

const ViewWallet = (props) => {
  const { user_infro, wallet_address, Collection_name, Url_link } = props;
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div
        onClick={() => {
          setShowModal(true);
        }}
        className="download-btn wallet-btn"
      >
        {string.VIEW_WALLET}
      </div>
      <BasicModal
        openModal={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
      >
        <div className="view_wallet_wrapper">
          <div className="view_wallet_hedding">{string.WALLET_INFO}</div>
          <div className="set_wallet_content1">
            <div className="set_wallet_content">
              <div className="set_wallet_textHeading">{string.OWNER} : </div>
              <div className="set_wallet_text">{user_infro}</div>
            </div>
            <div className="set_wallet_content">
              <div className="set_wallet_textHeading">{string.ADDRESS} : </div>
              <div className="set_wallet_text">{wallet_address}</div>
            </div>
            <div className="set_wallet_content">
              <div className="set_wallet_textHeading">
                {string.COLLECTION_NAME} :{" "}
              </div>
              <div className="set_wallet_text">{Collection_name}</div>
            </div>
          </div>
          <a
            href={Url_link}
            target="_blank"
            className={`${
              Url_link === ""
                ? "opensea_button_disabled"
                : "opensea_button_enabled"
            }`}
          >
            {string.VIEW_COLLECTION}
          </a>
        </div>
      </BasicModal>
    </>
  );
};

export default ViewWallet;
