import React, { useEffect, useState } from "react";
import "./AdminTables.scss";
import Pagination from "./Pagination/Pagination";
import AdminSearchBar from "../AdminSearchBar/AdminSearchBar";
import { useDispatch, useSelector } from "react-redux";
import {
  setPage,
  setUserColumnKey,
  setUserTypeKey,
} from "../../../Store/Project/AdminSlice";
import { RetriveMatricsReport } from "../../../Utils/ApiHelper";
import {
  ASCENDING_ORDERS,
  DESCENDING_ORDERS,
  PAGE_SIZE,
  REPORT_DATE,
} from "../../../Utils/Constants";
import ProjectLoader from "../../../Components/Loaders/ProjectLoader/ProjectLoader";
import { showOrHideApiLoadingModal } from "../../../Store/Project/ModalSlice";
import { string } from "../../../Localization";
import { showAdminSearchBox } from "../../../Utils/Helper";

const MetricsTable = () => {
  const { userSearch, userColumnKey, userTypeKey, userSearchKey } = useSelector(
    (state) => state.admin
  );

  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [metricsData, setMetricsData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [reportDate, setReportDate] = useState(true);
  const [createdAtSort, setCreatedAtSort] = useState(true);
  const [recordCount, setRecordCount] = useState(0);
  const [refresh, setRefresh] = useState(true);

  const options = [{ value: "0", label: "Report Date" }];

  useEffect(() => {
    dispatch(showOrHideApiLoadingModal(true));
    RetriveMatricsReport(
      currentPage - 1,
      PAGE_SIZE,
      userTypeKey,
      userColumnKey,
      userSearchKey,
      userSearch
    )
      .then((res) => {
        // console.log("a", res);
        setMetricsData(res?.data?.data?.records_arr);
        setTotalPage(res?.data?.data?.total_pages);
        dispatch(showOrHideApiLoadingModal(false));
        setRecordCount(res?.data?.data?.records_count);
      })
      .catch((err) => {
        dispatch(showOrHideApiLoadingModal(false));
      });
  }, [
    userColumnKey,
    userSearch,
    userTypeKey,
    userSearchKey,
    currentPage,
    refresh,
  ]);

  const totalCount = totalPage * PAGE_SIZE;

  return (
    <>
      <ProjectLoader />
      <div className="admin_tables">
        {/* <div className="d-flex  align-items-center"> */}
        {showAdminSearchBox(userSearchKey, recordCount) && (
          <div
            className="admin_search_bar_container d-flex"
            style={{ justifyContent: "space-between" }}
          >
            <div className="metric_load">
              <AdminSearchBar
                setUserTypeKey={setUserTypeKey}
                setUserColumnKey={setUserColumnKey}
                options={options}
              />
              <i
                className="fa refresh_load"
                onClick={() => {
                  setRefresh(!refresh);
                }}
              >
                &#xf021;
              </i>
            </div>
            <div className="download-btn">
              <a
                href="/admins/download_metrics"
                target="_blank"
                rel="noopener noreferrer"
              >
                {string.DOWNLOAD_CSV}
              </a>
            </div>
          </div>
        )}
        {/* </div> */}
        {recordCount === 0 ? (
          <>
            <div className="admin_user_back_btn">
              <div className="admin_no_user_details">
                <div className="order_no_user_details_text">
                  {string.NO_RECORDS_FOUND}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {" "}
            <div className="admin_tables_wrapper">
              <table className="table table-borderless table_block">
                <thead className="table_head">
                  <tr>
                    <th>
                      #
                      {createdAtSort ? (
                        <div
                          className="admin_navBar_sort fa fa-angle-down"
                          onClick={() => {
                            dispatch(setUserColumnKey(""));
                            dispatch(setUserTypeKey(ASCENDING_ORDERS));
                            setCreatedAtSort(false);
                          }}
                        ></div>
                      ) : (
                        <div
                          className="admin_navBar_sort fa fa-angle-up"
                          onClick={() => {
                            dispatch(setUserColumnKey(""));
                            dispatch(setUserTypeKey(DESCENDING_ORDERS));
                            setCreatedAtSort(true);
                          }}
                        ></div>
                      )}
                    </th>
                    <th>
                      {string.REPORT_DATE}
                      {reportDate ? (
                        <div
                          className="admin_navBar_sort fa fa-angle-down"
                          onClick={() => {
                            dispatch(setUserColumnKey(REPORT_DATE));
                            dispatch(setUserTypeKey(ASCENDING_ORDERS));
                            setReportDate(false);
                          }}
                        ></div>
                      ) : (
                        <div
                          className="admin_navBar_sort fa fa-angle-up"
                          onClick={() => {
                            dispatch(setUserColumnKey(REPORT_DATE));
                            dispatch(setUserTypeKey(DESCENDING_ORDERS));
                            setReportDate(true);
                          }}
                        ></div>
                      )}
                    </th>
                    <th>{string.NO_OF_PHOTOGRAPHER_SIGNUPS}</th>
                    <th>{string.NO_OF_PHOTOGRAPHER_APPROVED}</th>
                    <th>{string.NO_OF_PHOTOGRAPHER_WITH_ORDER}</th>
                    <th>{string.NO_OF_LIMITED_EDITIONS}</th>
                    <th>{string.NO_OF_ORDERED_LIMITED_EDITIONS}</th>
                    <th>{string.NO_OF_OPEN_EDITION}</th>
                    <th>{string.NO_OF_ORDERED_OPEN_EDITIONS}</th>
                    <th>{string.NO_OF_ARTIST_PROOF}</th>
                    <th>{string.NO_OF_ORDERED_ARTIST_PROOF}</th>
                    <th>{string.NO_OF_PRINTS}</th>
                    <th>{string.NO_OF_COLLECTORS}</th>
                    {/* <th>{string.NO_OF_COUNTRIES}</th> */}
                    <th>{string.TOTAL_SALES}</th>
                    <th>{string.TOTAL_HP_REVENUE}</th>
                    <th>{string.TOTAL_PSP_REVENUE}</th>
                    <th>{string.TOTAL_PHOTOGRAPHER_REVENUE}</th>
                    <th>{string.AVG_REVENUE_PER_PHOTOGRAPHER}</th>
                    <th>{string.AVG_REVENUE_PER_TRANSACTION}</th>
                    <th>{string.VIEW_COUNT}</th>
                  </tr>
                </thead>
                <tbody>
                  {metricsData.map((item, index) => {
                    return (
                      <tr className="admin_table_row" key={index}>
                        <td>{item.id}</td>
                        <td>{item.report_date}</td>
                        <td>{item.no_of_photographers}</td>
                        <td>{item.no_of_photographers_approved}</td>
                        <td>{item.no_of_uniq_photographers_with_order}</td>
                        <td>{item.no_of_limited_editions}</td>
                        <td>{item.no_of_ordered_limited_editions}</td>
                        <td>{item.no_of_open_editions}</td>
                        <td>{item.no_of_ordered_open_editions}</td>
                        <td>{item.no_of_artist_proofs}</td>
                        <td>{item.no_of_ordered_artist_proofs}</td>
                        <td>{item.no_of_prints}</td>
                        <td>{item.no_of_collectors}</td>
                        {/* <td>{item.no_of_countries}</td> */}
                        <td>{item.total_sales}</td>
                        <td>{item.total_hp_revenue}</td>
                        <td>{item.total_psp_revenue}</td>
                        <td>{item.total_photographers_revenue}</td>
                        <td>{item.avg_revenue_per_photographer}</td>
                        <td>{item.avg_revenue_per_transaction}</td>
                        <td>{item.view_count}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="admin_pagination">
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={totalCount}
                pageSize={PAGE_SIZE}
                onPageChange={(page) => {
                  setCurrentPage(page);
                  dispatch(setPage(page));
                }}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MetricsTable;
