import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mediaTypesData: [],
  footerLinksData: [],
  qrCodeUrl: null,
  horizontalRefImageUrl: "",
  verticalRefImageUrl: "",
};

export const cacheSlice = createSlice({
  name: "cache",
  initialState: initialState,
  reducers: {
    setMediaTypesData(state, action) {
      state.mediaTypesData = action.payload;
    },
    setFooterLinksData(state, action) {
      state.footerLinksData = action.payload;
    },
    set(state, action) {
      state.footerLinksData = action.payload;
    },
    setQrCodeUrl(state, action) {
      state.qrCodeUrl = action.payload;
    },
    setHorizontalRefImageUrl(state, action) {
      state.horizontalRefImageUrl = action.payload;
    },
    setVerticalRefImageUrl(state, action) {
      state.verticalRefImageUrl = action.payload;
    },
  },
});

export const {
  setMediaTypesData,
  setFooterLinksData,
  setQrCodeUrl,
  setHorizontalRefImageUrl,
  setVerticalRefImageUrl,
} = cacheSlice.actions;
export default cacheSlice.reducer;
