import React from "react";
import "./EditInfoModal.scss";
import closeIcon from "../../../Utils/Icons/close_black.svg";
import EditAddressModal from "./EditAddressModal";
import EditCustomerModal from "./EditCustomerModal";
import { Modal } from "react-bootstrap";

const EditInfoModal = (props) => {
  const {
    title,
    isEditAddressModal,
    isEditCustomerModal,
    closeHandler,
    projectSummaryData,
  } = props;

  return (
    <>
      <Modal show={true} className="edit_info_modal_container">
        <Modal.Body
          className="edit_info_modal_content"
          id="edit_info_modal_content"
        >
          <div
            className="close_image"
            onClick={() => {
              closeHandler();
            }}
          >
            <img src={closeIcon} alt="close" className="edit-icon" />
          </div>
          <div className="edit_info_modal_title">{title}</div>
          <div className="edit_info_text_box">
            {isEditAddressModal && (
              <EditAddressModal
                customerAddress={projectSummaryData?.order?.address}
              />
            )}

            {isEditCustomerModal && (
              <EditCustomerModal details={projectSummaryData?.order} />
            )}

            {/* <div className="update_btn_container">
            {" "}
            <button className="update_button">{string.UPDATE}</button>
          </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditInfoModal;
