import React, { useEffect, useState } from "react";
import { string } from "../../../Localization";
import Check from "../../../Utils/Icons/check.svg";
import { validateUserName } from "../../../Utils/ApiHelper";
import { getHostUrl } from "../../../Utils/Helper";
import ProfileErrorModal from "./ProfileErrorModal/ProfileErrorModal";

const UserName = (props) => {
  const [showValidateButton, setShowValidateButton] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const { userNameValid, setUserNameValid, newUserName, setNewUserName } =
    props;

  useEffect(() => {
    if (!userNameValid && !showValidateButton && newUserName.length > 3) {
      setShowErrorModal(true);
    }
  }, [userNameValid, showValidateButton, newUserName]);

  const validUserName = () => {
    if (newUserName !== "") {
      validateUserName(newUserName)
        .then((res) => {
          setUserNameValid(true);
          setShowValidateButton(false);
        })
        .catch((error) => {
          setUserNameValid(false);
          setShowValidateButton(false);
          setSuggestions(error?.response?.data?.data?.suggested_names ?? []);
        });
    }
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
  };
  return (
    <>
      <div className="text-box">
        <label htmlFor="">{string.USER_NAME}</label>
        <div className="position-relative" style={{ width: "356px" }}>
          <input
            type="text"
            className="input-title "
            maxLength={20}
            minLength={4}
            placeholder={
              newUserName.trim() === "" ? string.TYPE_HERE : newUserName
            }
            value={newUserName}
            onChange={(e) => {
              setNewUserName(e.target.value?.replace(/[^a-zA-Z0-9]/g, ""));
              setUserNameValid(false);
              if (e.target.value.length > 3 && e.target.value.length <= 20) {
                setShowValidateButton(true);
              } else {
                setShowValidateButton(false);
              }
            }}
            required
          />
          {showValidateButton && (
            <button className="validate-button" onClick={() => validUserName()}>
              {string.VALIDATE}
            </button>
          )}
          {!showValidateButton && userNameValid && (
            <div className="check-mark">
              <img src={Check} alt="" />
            </div>
          )}
          <div className="check-mark loader"></div>
        </div>
        {newUserName.trim() !== "" && (
          <div className="max-characters ">{string.USER_NAME_VALIDATION}</div>
        )}
        <span
          style={{
            color: "#BDBDBD",
            fontSize: "10px",
          }}
        >
          Example: {getHostUrl() + "/gallery/" + newUserName}
        </span>

        {newUserName.trim() === "" && (
          <div className="error-div required-message ">
            {string.USER_NAME_IS_REQUIRED}
          </div>
        )}
      </div>
      <ProfileErrorModal
        canShow={showErrorModal}
        newUserName={newUserName}
        setNewUserName={setNewUserName}
        validUserName={validUserName}
        userNameValid={userNameValid}
        suggestions={suggestions}
        showValidateButton={showValidateButton}
        setShowValidateButton={setShowValidateButton}
        closeBtnFtn={closeErrorModal}
        setUserNameValid={setUserNameValid}
      />
    </>
  );
};

export default UserName;
