import React, { useEffect, useState } from "react";
import "./ErrorPage.scss";
import errorImage from "../../Utils/Icons/404-img.jpg";
import { useNavigate } from "react-router-dom";
import LogoHP from "../../Components/LogoHP/LogoHP";
import { string } from "../../Localization";
import ProfileButton from "../ProfileButton/ProfileButton";
import { getCurrentUser } from "../../Utils/ApiHelper";
import SinglePageFooter from "../../Components/ModalFooter/SinglePageFooter";

const ErrorPage = () => {
  const [isBetaUser, setIsBetaUser] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getCurrentUser().then((res) => {
      setIsBetaUser(res?.data?.data?.beta_user);
    });
  }, []);

  return (
    <>
      <div className="error-page-wrapper">
        <div className="error-page-text-container">
          <div className="error-text">
            <div className="error-page-logo">
              <LogoHP />
              {isBetaUser && (
                <div className="error-page-profile-button-container">
                  <ProfileButton />
                </div>
              )}
            </div>
            <div className="error-page-text">
              <div className="error-to-HP-limited-editions">
                {string.WERE_SORRY_PAGE_NOT_FOUND}
              </div>

              <div className="error-text-container">
                <div className="tr1"></div>
              </div>
              {isBetaUser && (
                <div
                  className="navigate-to-gallary"
                  onClick={() => navigate("/")}
                >
                  {string.BACK_TO_MY_GALLERY}
                </div>
              )}
            </div>
            <div className="error_page_footer">
              <SinglePageFooter />
            </div>
          </div>
        </div>
        <div className="error-image-container">
          <img src={errorImage} alt="error" className="error-image" />
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
