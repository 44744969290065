import React, { useEffect, useState } from "react";
import "./AdminUserProject.scss";
import { useDispatch, useSelector } from "react-redux";
import { RetrieveUserProject } from "../../../Utils/ApiHelper";
import {
  ADMIN_NAV_BAR,
  PAGE_SIZE,
  PROJECT_ORDERS_PAGE,
} from "../../../Utils/Constants";
import AdminSearchBar from "../AdminSearchBar/AdminSearchBar";
import ProjectLoader from "../../../Components/Loaders/ProjectLoader/ProjectLoader";
import { string } from "../../../Localization";
import {
  setActivePageIndex,
  setPage,
  setProjectToken,
  setUserColumnKey,
  setUserSearch,
  setUserTypeKey,
} from "../../../Store/Project/AdminSlice";
import backArrowSvg from "../../../Utils/Icons/arrow_back.svg";
import Pagination from "../AdminTables/Pagination/Pagination";
import { showOrHideApiLoadingModal } from "../../../Store/Project/ModalSlice";
import { useNavigate, useParams } from "react-router-dom";
import InstagramIcon from "../../../Utils/Icons/instagram.svg";
import FacebookIcon from "../../../Utils/Icons/facebook.svg";
import TwitterIcon from "../../../Utils/Icons/twitter.svg";
import { AdminSearchReset } from "../../../Utils/projectHelper";
import LimitedEditionUserTable from "../AdminTables/LimitedEditionUserTable";
import DataNotFound from "../DataNotFound";
import { showAdminSearchBox } from "../../../Utils/Helper";

const AdminUserProject = () => {
  const { userSearch, userColumnKey, userTypeKey, userSearchKey, userKeyId } =
    useSelector((state) => state.admin);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [userProjectData, setUserProjectData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [recordCount, setRecordCount] = useState(0);
  const [failed, setFailed] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user_token } = useParams();

  useEffect(() => {
    dispatch(showOrHideApiLoadingModal(true));
    RetrieveUserProject(
      currentPage - 1,
      PAGE_SIZE,
      userTypeKey,
      userColumnKey,
      userSearchKey,
      userSearch,
      user_token
    )
      .then((res) => {
        setFailed(false);
        setTotalPage(res?.data?.data?.total_pages);
        setUserProjectData(res?.data?.data?.records_arr);
        setUserData(res?.data?.data?.parent_record);
        dispatch(showOrHideApiLoadingModal(false));
        setRecordCount(res?.data?.data?.records_count);
      })
      .catch((err) => {
        dispatch(showOrHideApiLoadingModal(false));
        if (err?.response?.status === 401) {
          navigate("/");
        } else if (err?.response?.status === 422) {
          setFailed(true);
        }
      });
  }, [
    userColumnKey,
    userSearch,
    userTypeKey,
    userSearchKey,
    userSearch,
    currentPage,
  ]);

  const options = [
    { value: 0, label: "Email" },
    { value: 1, label: "Artist Name" },
    { value: 2, label: "Title" },
  ];

  const onNavigateBack = () => {
    navigate(-1);
    dispatch(setActivePageIndex(ADMIN_NAV_BAR));
  };

  const totalCount = totalPage * PAGE_SIZE;

  const handleRowClick = (item) => {
    navigate("../project_orders");
    dispatch(setProjectToken(item.project_token));
    dispatch(setActivePageIndex(PROJECT_ORDERS_PAGE));
  };

  return (
    <>
      <ProjectLoader />

      <div className="admin_tables">
        {!failed ? (
          <div className="admin_project_user_details">
            <div className="admin_user_back_btn">
              <div className="admin_back_btn">
                <div
                  className="navigateBackContainer"
                  onClick={() => {
                    AdminSearchReset();
                    onNavigateBack();
                  }}
                >
                  <img src={backArrowSvg} alt="" />
                  <span>{string.BACK}</span>
                </div>
              </div>
              <div className="admin_order_details">
                <div className="order_details_text">
                  {string.PROJECT_USER_DETAILS}
                </div>
                <div className="user-details-container">
                  {/* section 1 */}
                  <div className="user-details-container-1">
                    <div>
                      <span className="user-label">
                        {" "}
                        {string.USER} {string.ID}:
                      </span>{" "}
                      <span className="user-value">{userData?.id}</span>
                    </div>
                    <div>
                      <span className="user-label"> {string.NAME}:</span>{" "}
                      <span className="user-value">
                        {userData?.artist_name}
                      </span>
                    </div>
                    <div>
                      <span className="user-label"> {string.EMAIL_1}:</span>{" "}
                      <span className="user-value">{userData?.email}</span>
                    </div>{" "}
                    <div>
                      <span className="user-label">
                        {" "}
                        {string.BIOGRAPHY.toUpperCase()}:
                      </span>{" "}
                      <span className="user-value">
                        {userData?.biography ? userData?.biography : "-"}
                      </span>
                    </div>
                    <div>
                      <span className="user-label">
                        {`Onboarding Date`.toUpperCase()}
                      </span>{" "}
                      <span className="user-value">
                        {userData?.on_boarding_date}
                      </span>
                    </div>{" "}
                    <div>
                      <span className="user-label"> {string.USER_TOKEN}:</span>{" "}
                      <span className="user-value">{userData?.token}</span>
                    </div>{" "}
                    <div>
                      <span className="user-label">
                        {string.WEBSITE_URL1} :{" "}
                      </span>{" "}
                      <span className="user-value">
                        <span
                          className={`social-icons ${
                            !(
                              userData.instagram_url ||
                              userData.facebook_url ||
                              userData.twitter_url
                            )
                              ? "isEmpty"
                              : ""
                          }`}
                        >
                          {userData.instagram_url && (
                            <a
                              href={userData.instagram_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={InstagramIcon}
                                alt="instagram"
                                className="insta-icon"
                              />
                            </a>
                          )}
                          {userData.facebook_url && (
                            <a
                              href={userData.facebook_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={FacebookIcon}
                                alt="facebook"
                                className="facebook-icon"
                              />
                            </a>
                          )}
                          {userData.twitter_url && (
                            <a
                              href={userData.twitter_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={TwitterIcon}
                                alt="twitter"
                                className="twitter-icon"
                              />
                            </a>
                          )}
                          {!(
                            userData.instagram_url ||
                            userData.facebook_url ||
                            userData.twitter_url
                          ) && "-"}
                        </span>
                      </span>
                    </div>
                  </div>
                  {/* section 2 */}
                  <div className="user-details-container-2">
                    <div>
                      <span className="user-label">{string.ADMIN_ACCESS}:</span>{" "}
                      <span className="user-value">
                        {userData?.is_admin ? "True" : "False"}
                      </span>
                    </div>
                    <div>
                      <span className="user-label">
                        {string.MULESOFT_LIVE}:
                      </span>{" "}
                      <span className="user-value">
                        {userData?.mulesoft_live_mode ? "True" : "False"}
                      </span>
                    </div>
                    <div>
                      <span className="user-label">{string.TEST_ACCOUNT}:</span>{" "}
                      <span className="user-value">
                        {" "}
                        {userData?.is_test ? "True" : "False"}
                      </span>
                    </div>{" "}
                    <div>
                      <span className="user-label">
                        {string.APPROVE_ACCOUNT}:
                      </span>{" "}
                      <span className="user-value">
                        {userData?.beta_user ? "True" : "False"}
                      </span>
                    </div>
                    <div>
                      <span className="user-label">{string.OPEN_AI}:</span>{" "}
                      <span className="user-value">
                        {userData?.open_ai ? "True" : "False"}
                      </span>
                    </div>{" "}
                    <div>
                      <span className="user-label"> {string.WEB2PRINT}:</span>{" "}
                      <span className="user-value">
                        {userData?.w2p_live_mode ? "True" : "False"}
                      </span>
                    </div>
                    <div>
                      <span className="user-label"> {string.BETA_USER}:</span>{" "}
                      <span className="user-value">
                        {userData?.beta_user ? "True" : "False"}
                      </span>
                    </div>
                    <div>
                      <span className="user-label">
                        {string.REAL_PHOTOGRAPHER}:
                      </span>{" "}
                      <span className="user-value">
                        {userData?.real_photographer ? "True" : "False"}
                      </span>
                    </div>
                  </div>
                  {/* section 3 */}
                  <div className="user-details-container-2">
                    <div>
                      <span className="user-label">
                        {string.TOTAL_LIMITED_EDITION}:
                      </span>{" "}
                      <span className="user-value">
                        {userData?.total_limited_edition}
                      </span>
                    </div>
                    <div>
                      <span className="user-label">
                        {string.TOTAL_OPEN_EDITONS}:
                      </span>{" "}
                      <span className="user-value">
                        {userData?.total_open_edition}
                      </span>
                    </div>
                    <div>
                      <span className="user-label">
                        {string.PENDING_ORDERS}:
                      </span>{" "}
                      <span className="user-value">
                        {" "}
                        {userData?.pending_orders}
                      </span>
                    </div>{" "}
                    <div>
                      <span className="user-label">
                        {string.COMPLETED_ORDERS}:
                      </span>{" "}
                      <span className="user-value">
                        {userData?.completed_orders}
                      </span>
                    </div>
                    <div>
                      <span className="user-label">{string.TOTAL_ORDERS}:</span>{" "}
                      <span className="user-value">
                        {userData?.total_orders}
                      </span>
                    </div>{" "}
                    <div>
                      <span className="user-label">
                        {" "}
                        {string.ARTIST_PROOF_PENDING_ORDERS}:
                      </span>{" "}
                      <span className="user-value">
                        {userData?.artist_proof_pending_orders}
                      </span>
                    </div>
                    <div>
                      <span className="user-label">
                        {" "}
                        {string.ARTIST_PROOF_COMPLETED_ORDERS}:
                      </span>{" "}
                      <span className="user-value">
                        {userData?.artist_proof_completed_orders}
                      </span>
                    </div>
                    <div>
                      <span className="user-label">
                        {" "}
                        {string.NO_OF_PROJECTS_IN_DRAFT}:
                      </span>{" "}
                      <span className="user-value">
                        {userData?.no_of_projects_in_draft}
                      </span>
                    </div>
                    <div>
                      <span className="user-label">
                        {" "}
                        {string.NO_OF_ARTIST_PROOF_SOLD}:
                      </span>{" "}
                      <span className="user-value">
                        {userData?.no_of_artist_proofs_sold}
                      </span>
                    </div>
                  </div>

                  <div className="user-details-container-2">
                    <div>
                      <span className="user-label">
                        {string.TOTAL_UPLOADED_IMAGES}:
                      </span>{" "}
                      <span className="user-value">
                        {userData?.total_uploaded_images}
                      </span>
                    </div>
                    <div>
                      <span className="user-label">{string.TOTAL_SALES}:</span>{" "}
                      <span className="user-value">
                        {userData?.total_sales?.toFixed(2)}
                      </span>
                    </div>
                    <div>
                      <span className="user-label">
                        {string.TOTAL_HP_REVENUE}:
                      </span>{" "}
                      <span className="user-value">
                        {" "}
                        {userData?.total_hp_revenue?.toFixed(2)}
                      </span>
                    </div>{" "}
                    <div>
                      <span className="user-label">
                        {string.TOTAL_PSP_REVENUE}:
                      </span>{" "}
                      <span className="user-value">
                        {userData?.total_psp_revenue?.toFixed(2)}
                      </span>
                    </div>
                    <div>
                      <span className="user-label">
                        {string.TOTAL_PHOTOGRAPHER_REVENUE}:
                      </span>{" "}
                      <span className="user-value">
                        {userData?.total_photographer_revenue?.toFixed(2)}
                      </span>
                    </div>{" "}
                    <div>
                      <span className="user-label">
                        {" "}
                        {string.HAS_ENCRYPTED_ATLEAST_ONE_PHOTO}:
                      </span>{" "}
                      <span className="user-value">
                        {userData?.has_encrypted_atleast_one_photo
                          ? "Yes"
                          : "No"}
                      </span>
                    </div>
                    <div>
                      <span className="user-label">
                        {" "}
                        {string.AUTHORIZED_COPIES_PER_PHOTOGRAPHER}:
                      </span>{" "}
                      <span className="user-value">
                        {userData?.authorized_copies_per_photographer?.toFixed(
                          2
                        )}
                      </span>
                    </div>
                    <div>
                      <span className="user-label">
                        {" "}
                        {string.TOTAL_NO_OF_IMAGES_ENCRYPTED}:
                      </span>{" "}
                      <span className="user-value">
                        {userData?.no_of_images_encrypted}
                      </span>
                    </div>
                    <div>
                      <span className="user-label">
                        {" "}
                        {string.TOTAL_NO_OF_DESIGNS_DELETED}:
                      </span>{" "}
                      <span className="user-value">
                        {userData?.no_of_images_deleted}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {showAdminSearchBox(userSearchKey, recordCount) && (
              <div className="admin_search_bar_container">
                <AdminSearchBar
                  setUserTypeKey={setUserTypeKey}
                  setUserColumnKey={setUserColumnKey}
                  options={options}
                />
              </div>
            )}
            {recordCount == 0 ? (
              <>
                <div className="admin_user_back_btn">
                  <div className="admin_order_details">
                    <div className="empty-records">
                      {string.NO_PROJECTS_FOUND}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <LimitedEditionUserTable result={userProjectData} />
                <div className="admin_pagination">
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={totalCount}
                    pageSize={PAGE_SIZE}
                    onPageChange={(page) => {
                      setCurrentPage(page);
                      dispatch(setPage(page));
                    }}
                  />
                </div>
              </>
            )}
          </div>
        ) : (
          <DataNotFound />
        )}
      </div>
    </>
  );
};

export default AdminUserProject;
