import { store } from "../Store/Store";
import { getDimensionString } from "./CertificateHelper";
import {
  DRAFT_PROJECT_TYPE,
  DRAW,
  PROFILE,
  RECENT_PROJECT_TYPE,
  SOLD_OUT_PROJECT_TYPE,
  STEP_ONE_PHOTO_DETAILS,
  STEP_THREE_CERTIFICATE,
  STEP_TWO_PERSONALISATION,
  UPLOAD,
} from "./Constants";
import { getOrientation, getSelectedMediaTypeName } from "./Helper";
import { getBestMatchString } from "./projectHelper";

const getDeviceType = () => {
  const userAgent = window.navigator.userAgent;

  if (/iPad/.test(userAgent)) {
    return "iPad";
  } else if (/iPhone/.test(userAgent)) {
    return "iPhone";
  } else if (/iPod/.test(userAgent)) {
    return "iPod";
  } else if (/Android/.test(userAgent)) {
    if (/Tablet/.test(userAgent)) {
      return "Android Tablet";
    } else {
      return "Android Phone";
    }
  } else {
    return "Web";
  }
};

const getGAClientID = () => {
  const cookie = Object.fromEntries(
    document.cookie.split(";").map((el) => {
      const [key, value] = el.split("=").map((item) => item.trim());
      return [key, value];
    })
  );

  return cookie["_ga"] && cookie["_ga"].length >= 6
    ? cookie["_ga"].substring(6)
    : null;
};

const eventTrack = (dimension_hash, category, action, label) => {
  const extendedDimensionHash = Object.assign({}, dimension_hash, {
    DeviceType: getDeviceType(),
    ClientID: getGAClientID(),
  });
  const event_hash = { event_category: category, event_label: label };
  const final_hash = Object.assign({}, event_hash, extendedDimensionHash);
  window.gtag("event", action, final_hash);
};

const isAiImage = store.getState().project?.projectDetails?.isAiImage
  ? store.getState().project?.projectDetails?.isAiImage
  : false;

export const sendGAEmptyGallery = () => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "Gallery View",
    "Loaded Empty GalleryView",
    ""
  );
};
export const sendGAGalleryView = () => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "Gallery View",
    "Loaded GalleryView",
    "MoreRecent "
  );
};
export const sendGARecentGalleryView = (data) => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "Gallery View",
    "Loaded " + getGALabel(data),
    "MoreRecent "
  );
};

const getGALabel = (inData) => {
  let gaLabel = "";
  switch (inData) {
    case RECENT_PROJECT_TYPE:
      gaLabel = "Recents";
      break;
    case DRAFT_PROJECT_TYPE:
      gaLabel = "Draft";
      break;
    case SOLD_OUT_PROJECT_TYPE:
      gaLabel = "Sold Out";
      break;
    default:
      break;
  }
  return gaLabel;
};

export const sendGAClickedGalleryView = (data) => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "Gallery View",
    "Clicked " + getGALabel(data),
    "MoreRecent "
  );
};

export const sendGAClickedSorting = (isDesc) => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "Gallery View",
    "Clicked Sorting",
    `Is Descending: ${isDesc}`
  );
};

export const sendGAScrolledGalleryView = () => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "Gallery View",
    "Scrolled GalleryView",
    "MoreRecent "
  );
};

export const sendGAAcceptedTermsAndCondition = () => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "Welcome Page",
    "Accepted Terms and Conditions",
    ""
  );
};

export const sendGAUploadedFirstPhoto = () => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "Photo Upload",
    "Uploading First Photo",
    ""
  );
};

export const sendGAUploadedPhoto = (isAI = false) => {
  const { currentUserDetails } = store.getState().user;
  const action = isAI ? "Uploaded Photo with AI" : "Uploaded Photo";
  eventTrack(
    { UserID: currentUserDetails.id, isAiImage: isAI },
    "Photo Upload",
    action,
    ""
  );
};

export const sendGAUploadSuccess = (imageToken) => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    {
      UserID: currentUserDetails.id,
      UserPhotoToken: imageToken,
      isAiImage,
    },
    "Photo Upload",
    "Upload Success",
    ""
  );
};

export const sendGAUploadFail = () => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id, isAiImage },
    "Photo Upload",
    "Upload Fail",
    ""
  );
};

export const sendGACancelUpload = () => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id, isAiImage },
    "Photo Upload",
    "Cancel Upload",
    ""
  );
};

export const sendGAClickedCreatePhotoWithAI = () => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "Photo Upload",
    "Clicked Create photo with AI",
    ""
  );
};

export const sendGAClickedAISearch = () => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "Photo Upload",
    "Clicked Upload photo with AI",
    ""
  );
};

export const sendGAClickedAIGenerateMore = () => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "Photo Upload",
    "Clicked Generate again with AI",
    ""
  );
};

export const sendGACheckedTermsWithAI = () => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "Photo Upload",
    "Checked Agreed to Terms with AI",
    ""
  );
};

export const sendGAUncheckedTermsWithAI = () => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "Photo Upload",
    "Unchecked Agreed to Terms with AI",
    ""
  );
};

export const sendGAGenerateDescriptionWithAI = () => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "PhotoDetails",
    "Generate description using AI",
    ""
  );
};

export const sendGAUpdatedSearchContentForAI = () => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "Photo Upload",
    "Updated search content for AI",
    ""
  );
};

export const sendGAReuploadedPhotoAfterUploadFailure = () => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "Photo Upload",
    "Reuploaded Photo After Upload Failure",
    ""
  );
};

export const sendGAClickedUpdatePhoto = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;

  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "PhotoDetails",
    "Clicked Update Photo",
    " "
  );
};

export const sendGALoadedModalSizeWasNotApplied = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;

  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "PhotoDetails",
    "Loaded Modal Size Was Not Applied",
    ""
  );
};

export const sendGAClickedVirtaulPreviewPerson = (type) => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;

  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "PhotoDetails",
    "Clicked Virtual Preview " + type,
    ""
  );
};
export const sendGALoadedModalBackHome = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;

  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "PhotoDetails",
    "Loaded Modal Back Home "
  );
};

export const sendGAClickedSaveAsDraft = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;

  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "PhotoDetails",
    "Clicked SaveAsDraft",
    ""
  );
};

export const sendGAAddedDeliveryAddress = (userId, projectToken) => {
  eventTrack(
    { UserID: userId, ProjectToken: projectToken },
    "PublicView",
    "Collector provided delivery address details",
    ""
  );
};

export const sendGAAddedCustomerDetails = (userId, projectToken) => {
  eventTrack(
    { UserID: userId, ProjectToken: projectToken },
    "PublicView",
    "Collector provided Customer details",
    ""
  );
};

export const sendGAClickedBack = (inStep) => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;

  const step =
    inStep === STEP_ONE_PHOTO_DETAILS
      ? "in Photo Details"
      : inStep === STEP_TWO_PERSONALISATION
      ? "in Personization"
      : inStep === STEP_THREE_CERTIFICATE
      ? "in Certificate"
      : "";

  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    `Clicked Back ${step}`,
    `Clicked Back ${step}`,
    ""
  );
};

export const sendStep1GA = () => {
  sendGASelectedMaterial();
  sendGASelectedProductSize();
  sendGASelectedOrientation();
  sendGAIncome();
};

export const sendGASelectedMaterial = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken, sizeSelectionDetails, selectedMediaTypeId } =
    store.getState().project;

  eventTrack(
    {
      UserID: currentUserDetails.id,
      ProjectToken: projectToken,
      ProductSizeID: sizeSelectionDetails.selectedId,
      MediaTypeID: selectedMediaTypeId,
    },
    "PhotoDetails",
    `Selected Material ${getSelectedMediaTypeName()}`,
    ""
  );
};

export const sendGASelectedProductSize = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken, sizeSelectionDetails, selectedMediaTypeId } =
    store.getState().project;

  eventTrack(
    {
      UserID: currentUserDetails.id,
      ProjectToken: projectToken,
      ProductSizeID: sizeSelectionDetails.selectedId,
      MediaTypeID: selectedMediaTypeId,
    },
    "PhotoDetails",
    `Selected product size ${getDimensionString(
      sizeSelectionDetails.selectedDimension
    )}`,
    ""
  );
};

export const sendGASelectedOrientation = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken, sizeSelectionDetails, selectedMediaTypeId } =
    store.getState().project;

  eventTrack(
    {
      UserID: currentUserDetails.id,
      ProjectToken: projectToken,
      ProductSizeID: sizeSelectionDetails.selectedId,
      MediaTypeID: selectedMediaTypeId,
    },
    "PhotoDetails",
    `Clicked Print Size tab ${getOrientation(
      sizeSelectionDetails.selectedDimension
    )}`,
    ""
  );
};

export const sendGAIncome = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken, sizeSelectionDetails, selectedMediaTypeId } =
    store.getState().project;

  eventTrack(
    {
      UserID: currentUserDetails.id,
      ProjectToken: projectToken,
      ProductSizeID: sizeSelectionDetails.selectedId,
      MediaTypeID: selectedMediaTypeId,
    },
    "PhotoDetails",
    `Provided Income`,
    ""
  );
};

export const sendGAClickedNext = (inStep) => {
  const { currentUserDetails } = store.getState().user;
  const { project, limitedEdition, signature } = store.getState();
  const { projectDetails, projectToken } = project;

  let gaCategory = "";
  let gaLabel = "";
  let gaData = {};

  switch (inStep) {
    case STEP_ONE_PHOTO_DETAILS:
      gaCategory = "Clicked Next in Photo Details";
      gaLabel = "Next in Photo Details";
      gaData = {
        title: projectDetails.title,
        description: projectDetails.description,
        paper: getSelectedMediaTypeName(),
        bestMatch: getBestMatchString(),
        printSize: project.printSizeString,
        border: project.borderString,
        price: project.priceInfo.income,
      };
      break;
    case STEP_TWO_PERSONALISATION:
      gaCategory = "Clicked Next in Personization";
      gaLabel = "Next in Personization";
      gaData = {
        hasSignature: signature.canShowSignature === true ? "Yes" : "No",
        signatureType: signature.signatureType,
        limitedEdition: limitedEdition.isLimited,
        noOfCopies: limitedEdition?.noOfCopies,
        fontType: limitedEdition?.font.type,
        fontFamily: limitedEdition?.font.family,
      };
      break;
    case STEP_THREE_CERTIFICATE:
      gaCategory = "Clicked Next in Certificate";
      gaLabel = "Review in Certificate";
      gaData = {
        signatureType: signature.signatureType,
      };
      break;
    default:
      break;
  }
  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    gaCategory,
    `Clicked ${gaLabel}`,
    gaData
  );
};

export const sendGAClickedMagnifierIcon = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;
  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "Personization",
    "Clicked Magnifier Icon",
    ""
  );
};

export const sendGAExitedMagnifierView = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;
  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "Personization",
    "Exited Magnifier View",
    ""
  );
};

export const sendGAAddedSignInPersonalization = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;
  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "Personization",
    "Added signature in Personalisation",
    ""
  );
};

export const sendGASelectedSignatureType = (signatureType) => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;
  const signType =
    signatureType === PROFILE
      ? "saved"
      : signatureType === UPLOAD
      ? "new"
      : signatureType;
  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "Personization",
    `Opted for ${signType} Signature`,
    ""
  );
};

export const sendGAAddedSignatureInCertificate = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;
  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "Personization",
    `Added signature in certificate`,
    ""
  );
};

export const sendGAAddedEditionInPersonalization = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;
  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "Personization",
    `Added edition number in Personalisation`,
    ""
  );
};

export const sendGAClickedOrders = () => {
  const { currentUserDetails } = store.getState().user;

  eventTrack(
    { UserID: currentUserDetails.id },
    "OrderManagement",
    "Clicked Orders",
    ""
  );
};

export const sendGAClickedSales = () => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "OrderManagement",
    "Clicked Sales",
    ""
  );
};

export const sendGAClickedProfit = () => {
  const { currentUserDetails } = store.getState().user;

  eventTrack(
    { UserID: currentUserDetails.id },
    "OrderManagement",
    "Clicked Profit",
    ""
  );
};

export const sendGAClickedGraph = () => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "OrderManagement",
    "Clicked Graph",
    ""
  );
};

export const sendGALoadedOrderManagement = () => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "OrderManagement",
    "Loaded OrderManagement",
    ""
  );
};

export const sendGASelectedOrder = () => {
  const { currentUserDetails } = store.getState().user;
  const { orderToken } = store.getState().order;
  eventTrack(
    {
      UserID: currentUserDetails.id,
      OrderToken: orderToken,
    },
    "OrderManagement",
    "Selected Order",
    ""
  );
};

export const sendGAScrolledOrderManagement = () => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "OrderManagement",
    "Scrolled OrderManagement",
    ""
  );
};

export const sendGALoadedOrderDetails = (projectToken) => {
  const { currentUserDetails } = store.getState().user;
  const { orderToken } = store.getState().order;

  eventTrack(
    {
      projectToken: projectToken,
      UserID: currentUserDetails.id,
      OrderToken: orderToken,
    },
    "OrderDetails",
    "Loaded OrderDetails",
    ""
  );
};

export const sendGAClickedProfileDropdown = () => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "ProfileSettings",
    "Clicked Profile Dropdown ",
    ""
  );
};
export const sendGALoadedProfileSettings = () => {
  const { currentUserDetails } = store.getState().user;

  eventTrack(
    { UserID: currentUserDetails.id },
    "ProfileSettings",
    "Loaded Profile Settings",
    ""
  );
};
export const sendGAProfileSignature = (type) => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "ProfileSettings",
    type === DRAW ? "Draw Signature" : "Uploaded Signature",
    ""
  );
};
export const sendGAUploadedLogoType = () => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "ProfileSettings",
    "Uploaded Logo Type",
    ""
  );
};
export const sendGAUpdatedProfileSuccessfully = () => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "ProfileSettings",
    "Updated Profile Successfully",
    ""
  );
};
export const sendGAFailedToUpdateProfile = () => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "ProfileSettings",
    "Failed To Update Profile",
    ""
  );
};

export const sendGALoadedPhotoPrintSection = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;

  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "ProjectSummary",
    "Loaded Photo Print Section",
    ""
  );
};
export const sendGALoadedPrintedCertificateSection = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;
  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "PhotoPrintDetails",
    "Loaded Printed Certificate Section",
    ""
  );
};

export const sendGALoadedCertificateSection = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;

  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "ProjectSummary",
    "Loaded Certificate Section",
    ""
  );
};
export const sendGAClickedCopySalesLinkButton = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;
  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "PhotoPrintDetails",
    "Clicked Copy Sales Link Button",
    ""
  );
};
export const sendGAClickedViewPageButton = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;
  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "PhotoPrintDetails",
    "Clicked View Page in photo print details",
    ""
  );
};
export const sendGAClickedDownloadZIPButton = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;
  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "PhotoPrintDetails",
    "Clicked Download ZIP Button",
    ""
  );
};

export const sendGAClickedDownloadCertificateButton = (UTMSource) => {
  eventTrack(
    { UTMSource: UTMSource },
    " Digital Certificate",
    "Clicked Download Certificate Button",
    ""
  );
};
export const sendGAUpdatedDescription = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;
  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "PhotoPrintDetails",
    "Updated Description in photo print details",
    ""
  );
};

export const sendGALoadedPackagingSection = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;

  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "ProjectSummary",
    "Loaded Packaging Section",
    ""
  );
};

export const sendGAClickedEncryptAndLocktoSell = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;

  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "ProjectSummary",
    "Clicked Encrypt And Lock to Sell",
    ""
  );
};

export const sendGAClickedSaveDraftAndOrderArtisticProof = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;

  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "ProjectSummary",
    "Clicked Save Draft And Order Artistic Proof",
    ""
  );
};

export const sendGAClickedSaveAsaDraft = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;

  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "ProjectSummary",
    "Clicked Save As a Draft",
    ""
  );
};

export const sendGAClickedEncryptAndLockinModal = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;

  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "ProjectSummary",
    "Clicked Encrypt And Lock in Modal",
    ""
  );
};

export const sendGACanceledEncryptAndLockinModal = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;

  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "ProjectSummary",
    "Canceled Encrypt And Lock in Modal",
    ""
  );
};

export const sendGAClickedGoToFinalProduct = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;

  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "ProjectSummary",
    "Clicked Go To Final Product",
    ""
  );
};

export const sendGAUpdatedSellingPrice = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;
  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "PhotoPrintDetails",
    "Updated Selling Price in photo print details",
    ""
  );
};
export const sendGAUpdatingSellingPrice = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;
  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "PhotoPrintDetails",
    "Updating Selling Price",
    ""
  );
};
export const sendGAUpdatingDescription = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;
  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "PhotoPrintDetails",
    "Updating description",
    ""
  );
};
export const sendGAClickedLogout = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;
  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "PhotoPrintDetails",
    "Clicked Logout",
    ""
  );
};

export const sendGALoadedQuickStartGuide = () => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "QuickStartGuide",
    "Loaded QuickStartGuide",
    ""
  );
};
export const sendGAClosedQuickStartGuide = () => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "QuickStartGuide",
    "Closed QuickStartGuide",
    ""
  );
};
export const sendGAClickedDonotShowMeThisAgain = () => {
  const { currentUserDetails } = store.getState().user;
  eventTrack(
    { UserID: currentUserDetails.id },
    "QuickStartGuide",
    "Clicked Don’t show me this again",
    ""
  );
};

export const sendGALoadedPrintedCertifacte = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;

  eventTrack(
    { UserID: currentUserDetails.id, ProjectToken: projectToken },
    "Certificate",
    "Loaded Printed Certificate",
    ""
  );
};

export const sendGALoadeddigitalCertificate = (UTMSource) => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;

  eventTrack(
    {
      UserID: currentUserDetails?.id,
      ProjectToken: projectToken,
      UTMSource: UTMSource,
    },
    "Certificate",
    "Loaded digital Certificate",
    ""
  );
};
export const sendGALoadedScannedigitalCertificate = (UTMSource) => {
  eventTrack(
    { UTMSource: UTMSource },
    "Digital Certificate",
    "Loaded Scanned Digital Certificate",
    ""
  );
};

export const sendGALoadedScanneDigitalCertificateQR = (UTMSource) => {
  eventTrack(
    { UTMSource: UTMSource },
    "Digital Certificate",
    "Loaded digital certificate via QRCode",
    ""
  );
};

export const sendGALoadedLandingPage = (UTMSource) => {
  eventTrack({ UTMSource: UTMSource }, "LandingPage", "Loaded LandingPage", "");
};

export const sendGALoadedLandingPageViaQRCode = (UTMSource) => {
  eventTrack(
    { UTMSource: UTMSource },
    "LandingPage",
    "Loaded landing page via QR code",
    ""
  );
};

export const sendGAClickedMenuBlockchain = (UTMSource) => {
  eventTrack(
    { UTMSource: UTMSource },
    "LandingPage",
    "Clicked Menu Blockchain",
    ""
  );
};

export const sendGAClickedMenuWorldwide = (UTMSource) => {
  eventTrack(
    { UTMSource: UTMSource },
    "LandingPage",
    "Clicked Menu Worldwide",
    ""
  );
};

export const sendGAClickedMenuPersonalization = (UTMSource) => {
  eventTrack(
    { UTMSource: UTMSource },
    "LandingPage",
    "Clicked Menu Personalization",
    ""
  );
};

export const sendGAClickedMenuAbout = (UTMSource) => {
  eventTrack({ UTMSource: UTMSource }, "LandingPage", "Clicked Menu About", "");
};

export const sendGAClickedHPEmailSupportLink = (UTMSource) => {
  eventTrack(
    {
      UTMSource: UTMSource,
    },
    "LandingPage",
    "Clicked HP Email Support Link",
    ""
  );
};

export const sendGAClickedHPWatsAppChatLink = () => {
  eventTrack({}, "LandingPage", "Clicked HP WatsApp Chat Link", "");
};

export const sendGAClickedLogin = () => {
  eventTrack({}, "UserLogin", "Clicked Login", "");
};

export const sendGAClickedGetStarted = () => {
  const { currentUserDetails } = store.getState().user;

  eventTrack(
    { UserID: currentUserDetails?.id },
    "UserSignup",
    "Clicked Get Started",
    ""
  );
};

export const sendGALoadedOrderPublicView = (
  UserID,
  project_token,
  UTMSource
) => {
  eventTrack(
    {
      UserID: UserID,
      ProjectToken: project_token,
      UTMSource: UTMSource,
    },
    "PublicView",
    "Loaded Limited Edition Public View",
    ""
  );
};

export const sendGALoadedOrderPublicViewViaQR = (
  UserID,
  project_token,
  UTMSource
) => {
  eventTrack(
    {
      UserID: UserID,
      ProjectToken: project_token,
      UTMSource: UTMSource,
    },
    "PublicView",
    "Loaded public view via QRCode",
    ""
  );
};

export const sendGAUpdatedAddressDetails = (project_token) => {
  eventTrack(
    {
      ProjectToken: project_token,
    },
    "PublicView",
    "Updated Address Details",
    ""
  );
};

// export const sendGAUpdatedCustomerDetails = (project_token) => {
//   eventTrack(
//     {
//       ProjectToken: project_token,
//     },
//     "PublicView",
//     "Updated Customer Details",
//     ""
//   );
// };

export const sendGAAppliedCouponCode = (project_token) => {
  eventTrack(
    {
      ProjectToken: project_token,
    },
    "PublicView",
    "Updated Customer Details",
    ""
  );
};

export const sendGACheckedAgreedtoTerms = (projectToken, userId) => {
  eventTrack(
    {
      UserId: userId,
      ProjectToken: projectToken,
    },
    "PublicView",
    "Collector Checked Agreed to Terms",
    ""
  );
};

export const sendGAUncheckedAgreedtoTerms = (projectToken) => {
  eventTrack(
    {
      ProjectToken: projectToken,
    },
    "PublicView",
    "Collector unchecked Agreed to Terms",
    ""
  );
};

export const sendGALoadedAddressMap = (project_token) => {
  eventTrack(
    {
      ProjectToken: project_token,
    },
    "PublicView",
    "Loaded Address Map",
    ""
  );
};

export const sendGALoadedPaymentSuccessPage = (
  userId,
  order_token,
  projectToken,
  isCouponFlow = false
) => {
  eventTrack(
    {
      ProjectToken: projectToken,
      UserID: userId,
      OrderToken: order_token,
    },
    "PublicView",
    isCouponFlow
      ? "Loaded Coupon Payment Success Page"
      : "Loaded PGS Payment Success Page",
    ""
  );
};

export const sendGALoadedPaymentFailurePage = (userId, order_token) => {
  eventTrack(
    {
      UserID: userId,
      OrderToken: order_token,
    },
    "PublicView",
    "Loaded Payment Failure Page",
    ""
  );
};

export const sendGALoadedCopyExhausedPage = (UserID, project_token) => {
  eventTrack(
    {
      UserID: UserID,
      ProjectToken: project_token,
    },
    "PublicView",
    "Loaded Copy Exhaused Page",
    ""
  );
};

export const sendGAClickedRetryPayment = (order_token) => {
  eventTrack(
    {
      OrderToken: order_token,
    },
    "PublicView",
    "Clicked Retry Payment",
    ""
  );
};

export const sendGAToNonPGSPaymentPage = (projectToken, userId) => {
  eventTrack(
    {
      UserId: userId,
      ProjectToken: projectToken,
    },
    "PaymentGateway",
    "Before Redirection To Non PGS PaymentPage",
    ""
  );
};

export const sendGAToPGSPaymentPage = (projectToken, userId) => {
  eventTrack(
    {
      userId: userId,
      ProjectToken: projectToken,
    },
    "PaymentGateway",
    "Before Redirection To PGS PaymentPage",
    ""
  );
};

export const sendGALoadedWelcomeMessage = () => {
  const { currentUserDetails } = store.getState().user;

  eventTrack(
    {
      UserID: currentUserDetails.id,
    },
    "WelcomeMessage",
    "Loaded WelcomeMessage",
    ""
  );
};

export const sendGAUpdatedPersonalMessage = () => {
  const { currentUserDetails } = store.getState().user;
  const { projectToken } = store.getState().project;
  const { orderToken } = store.getState().order;

  eventTrack(
    {
      UserID: currentUserDetails?.id,
      ProjectToken: projectToken,
      OrderToken: orderToken,
    },
    "OrderDetails",
    "Updated PersonalMessage",
    ""
  );
};

export const sendGAOrderWithoutPersonalMessage = (orderToken) => {
  eventTrack(
    {
      OrderToken: orderToken,
    },
    "StartPrinting",
    "Triggered without PersonalMessage",
    ""
  );
};

export const sendGAForCollectorCountryPreference = (projectToken, country) => {
  eventTrack(
    {
      ProjectToken: projectToken,
    },
    "PublicView",
    "Collector country preference is " + country,
    ""
  );
};

export const sendGAAppliedValidCoupon = (projectToken, userId) => {
  eventTrack(
    {
      UserId: userId,
      ProjectToken: projectToken,
    },
    "PublicView",
    "Applied Valid Coupon",
    ""
  );
};

export const sendGAAppliedInValidCoupon = (projectToken) => {
  eventTrack(
    {
      ProjectToken: projectToken,
    },
    "PublicView",
    "Applied InValid Coupon",
    ""
  );
};

export const sendGAOrderCreationFailure = (projectToken) => {
  eventTrack(
    {
      ProjectToken: projectToken,
    },
    "PublicView",
    "Order Creation Failed",
    ""
  );
};

export const sendGARepositionedSignature = (userID) => {
  const { project } = store.getState();
  const { currentUserDetails } = store.getState().user;

  eventTrack(
    {
      userID: currentUserDetails?.id,
      ProjectToken: project.projectToken,
    },
    "Personization",
    "Repositioned Signature",
    ""
  );
};

export const sendGAResizedSignature = () => {
  const { project } = store.getState();
  const { currentUserDetails } = store.getState().user;

  eventTrack(
    {
      userId: currentUserDetails?.id,
      ProjectToken: project.projectToken,
    },
    "Personization",
    "Resized Signature",
    ""
  );
};

export const sendGARepositionedEditionNumber = () => {
  const { project } = store.getState();
  eventTrack(
    {
      ProjectToken: project.projectToken,
    },
    "Personization",
    "Repositioned Edition Number",
    ""
  );
};

export const sendGAResizedEditionNumber = () => {
  const { project } = store.getState();
  const { currentUserDetails } = store.getState().user;

  eventTrack(
    {
      userID: currentUserDetails?.id,
      ProjectToken: project.projectToken,
    },
    "Personization",
    "Resized Edition Number",
    ""
  );
};

export const sendGAClickedChooseBasicPlan = () => {
  eventTrack({}, "LandingPage", "Clicked Choose Basic Plan", "");
};

export const sendGAClickedChooseProPlan = () => {
  eventTrack({}, "LandingPage", "Clicked Choose Pro Plan", "");
};
export const sendGALoadedReviewPageWithValidCoupon = (userId) => {
  eventTrack(
    { UserID: userId },
    "Review Page",
    "Loaded Review Page Using Valid Coupon Code",
    ""
  );
};
export const sendGALoadedReviewPageWithOutCouponCode = (userId) => {
  eventTrack(
    { UserID: userId },
    "Review Page",
    "Loaded Review Page Without Using Coupon Code",
    ""
  );
};

export const sendGALoadedAddPersonalNotePage = (
  userId,
  projectToken,
  orderToken
) => {
  eventTrack(
    {
      UserID: userId,
      ProjectToken: projectToken,
      OrderToken: orderToken,
    },

    "Add Personal Note Page",
    "Loaded Add Personal Note Page",
    ""
  );
};

export const sendGAAddPersonalPageUsingAI = (orderToken, userID) => {
  eventTrack(
    {
      userID: userID,
      OrderToken: orderToken,
    },

    "Add Personal Note Page",
    "Generated Personal Note Using AI",
    ""
  );
};
export const sendGASendPersonalMessage = (orderToken, userId) => {
  eventTrack(
    {
      userId: userId,
      OrderToken: orderToken,
    },

    "Add Personal Note Page",
    "Submit Personal Note",
    ""
  );
};
export const sendGALoadedAddTackingInfo = (userId, orderToken) => {
  eventTrack(
    {
      userId: userId,
      OrderToken: orderToken,
    },

    "Add Tacking Info Page",
    "Loaded Add Tacking Info Page",
    ""
  );
};

export const sendGASubmitTackingInfo = (orderToken) => {
  eventTrack(
    {
      OrderToken: orderToken,
    },

    "Add Tacking Info Page",
    "Clicked On Submit With Tracking Number",
    ""
  );
};
export const sendGASubmitTackingDetails = (orderToken) => {
  eventTrack(
    {
      OrderToken: orderToken,
    },

    "Add Tacking Info Page",
    "Clicked On Submit With Both Tracking Number And Tracking Url",
    ""
  );
};

export const sendGALoadedPersonalNotePage = (personalNote) => {
  eventTrack({}, "Loaded Personal Note Page", personalNote, "");
};

export const sendGALoadedStartPrintingPage = (orderToken) => {
  eventTrack(
    { orderToken: orderToken },
    "Start Printing Page",
    "Loaded Start Printing Page",
    ""
  );
};
export const sendGALoadedProfileUpdatePage = () => {
  const { currentUserDetails } = store.getState().user;

  eventTrack(
    { userID: currentUserDetails?.id },
    "Profile Update Page",
    "Loaded Profile Update Page",
    ""
  );
};

export const sendGALoadedPgsPaymentPage = (orderToken) => {
  eventTrack(
    { orderToken: orderToken },
    "Pgs Payment Page",
    "Loaded PGS Payment Page",
    ""
  );
};
export const sendGALoadedTermsOfService = () => {
  eventTrack({}, "Terms Of Service Page", "Loaded Terms Of Service Page", "");
};

export const sendGALoadedSubscriptionTermsOfService = () => {
  eventTrack(
    {},
    "Photographer Subscription Terms Page",
    "Loaded Subscription Terms Of Service Page",
    ""
  );
};
export const sendGALoadedPaymentTermsOfService = () => {
  eventTrack(
    {},
    "Photographer Payment Terms Page",
    "Loaded Payment Terms Of Service Page",
    ""
  );
};

export const sendGACollectorclickedEditDeliveryAddress = () => {
  eventTrack({}, "Review Page", "Collector clicked edit delivery address", "");
};

export const sendGACollectorClickedEditCustomerDetails = () => {
  eventTrack({}, "Review Page", "Collector clicked edit Customer details", "");
};

export const sendGACollectorUpdatedDeliveryAddressDetails = () => {
  eventTrack(
    {},
    "Review Page",
    "Collector updated delivery address details",
    ""
  );
};

export const sendGACollectorUpdatedCustomerDetails = () => {
  eventTrack({}, "Review Page", "Collector updated customer details", "");
};
export const sendGACollectorCheckedraffleTerms = () => {
  eventTrack({}, "PublicView", "Collector Checked raffle Terms", "");
};
export const sendGACollectorClickedReviewOrder = () => {
  eventTrack({}, "PublicView", "Collector Clicked Review Order", "");
};
export const sendGACollectorclickedsupportemail = () => {
  eventTrack({}, "PublicView", "Collector clicked support email", "");
};
export const sendGACollectorclickedGotoSecurePayment = () => {
  eventTrack({}, "PublicView", "Collector clicked Go to Secure Payment", "");
};
export const sendGAClickedsharethroughfacebook = () => {
  eventTrack({}, "PublicView", "Clicked share through facebook", "");
};
export const sendGAClickedsharethroughtwitter = () => {
  eventTrack({}, "PublicView", "Clicked share through twitter", "");
};
export const sendGAClickedsharethroughlinkedin = () => {
  eventTrack({}, "PublicView", "Clicked share through linkedin", "");
};

export const sendGACheckedAgreedToTerms = () => {
  eventTrack({}, "Photo Upload", "Checked Agreed to Terms", "");
};

export const sendGAClickedUploadPhoto = () => {
  eventTrack({}, "Photo Upload", "Clicked Upload photo", "");
};

export const sendGALoadedInternalFeed = () => {
  eventTrack({}, "Feeds", "Loaded feeds page", "");
};

// photographer's pages
export const sendGALoadedGalleryHome = (UTMSource) => {
  eventTrack(
    { UTMSource: UTMSource },
    "PublicView",
    "Loaded gallery home page",
    ""
  );
};

export const sendGALoadedPhotographerGalleryPage = (UserID, UTMSource) => {
  eventTrack(
    { UserID, UTMSource: UTMSource },
    "PublicView",
    "Loaded photographer gallery page",
    ""
  );
};

export const sendGALoadedGalleryDesignPage = (
  UserID,
  ProjectToken,
  UTMSource
) => {
  eventTrack(
    { UserID, ProjectToken, UTMSource: UTMSource },
    "PublicView",
    "Loaded gallery design page",
    ""
  );
};

export const sendGAClickedViewGallery = (UserID, UTMSource) => {
  eventTrack(
    { UserID, UTMSource: UTMSource },
    "PublicView",
    "Clicked view photographer gallery",
    ""
  );
};

export const sendGAClickedViewDetailsInGallery = (
  UserID,
  ProjectToken,
  UTMSource
) => {
  eventTrack(
    { UserID, ProjectToken, UTMSource: UTMSource },
    "PublicView",
    "Clicked view details in gallery",
    ""
  );
};

export const sendGAClickedOrderNow = (UserID, ProjectToken, UTMSource) => {
  eventTrack(
    { UserID, ProjectToken, UTMSource: UTMSource },
    "PublicView",
    "Clicked order now in gallery design",
    ""
  );
};
