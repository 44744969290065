import { useEffect, useRef, useState } from "react";
import { certificateData } from "../../../Utils/CertificateHelper";
import { Icons } from "../../../Utils/Icons";
import "./DigitalCertficate.scss";
import {
  sendGAClickedDownloadCertificateButton,
  sendGALoadedScannedigitalCertificate,
  sendGALoadeddigitalCertificate,
  sendGALoadedScanneDigitalCertificateQR,
} from "../../../Utils/GAHelper";
import { string } from "../../../Localization";
import { getCetificateInfo } from "../../../Utils/ApiHelper";
import downloadIcon from "../../../Utils/Icons/download-icon.svg";
import { useDispatch } from "react-redux";
import { showOrHideApiLoadingModal } from "../../../Store/Project/ModalSlice";
import ProjectLoader from "../../../Components/Loaders/ProjectLoader/ProjectLoader";
import { useParams, useSearchParams } from "react-router-dom";
import { PRINTED_CERTIFICATE } from "../../../Utils/Constants";

const DigitalCertficate = ({ finalPrint }) => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const certificateRef = useRef(null);

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [searchParams] = useSearchParams();

  const queryStrings = Object.fromEntries([...searchParams]);

  useEffect(() => {
    if (finalPrint) {
      if (checkIfLoadedViaQR()) {
        sendGALoadedScanneDigitalCertificateQR(
          queryStrings["utm_source"] ?? ""
        );
      } else {
        sendGALoadedScannedigitalCertificate(queryStrings["utm_source"] ?? "");
      }
      setIsLoading(true);
      dispatch(showOrHideApiLoadingModal(true));
      getCetificateInfo(token)
        .then((res) => {
          setData(formatProjectSummaryData(res.data.data));
          dispatch(showOrHideApiLoadingModal(false));
          setIsLoading(false);
        })
        .catch((error) => {
          dispatch(showOrHideApiLoadingModal(false));
          console.log(error);
        });
    } else {
      //step 3 certificate data
      sendGALoadeddigitalCertificate(sendGALoadeddigitalCertificate);
      let result = certificateData();
      setData(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkIfLoadedViaQR = () => {
    return (
      queryStrings["utm_source"] === PRINTED_CERTIFICATE &&
      queryStrings["utm_medium"] === "qrcode"
    );
  };

  const formatProjectSummaryData = (projectSummaryData) => {
    return {
      title: projectSummaryData?.project?.title,
      artistName: projectSummaryData?.project?.artist_name,
      biography: projectSummaryData?.project?.biography,
      description: projectSummaryData?.project?.description,
      dimensions: projectSummaryData?.project?.dimensions,
      edition: projectSummaryData?.order?.edition,
      mintTime: projectSummaryData?.order?.mint_time,
      paperType: projectSummaryData?.project?.paper_type,
      previewImage: projectSummaryData?.project?.preview_image,
      signature: projectSummaryData?.project?.signature,
      year: projectSummaryData?.order?.year,
      token: projectSummaryData?.order?.nft_token,
      digitalPattern: projectSummaryData?.order?.digital_pattern,
      blockchainLogo: projectSummaryData?.order?.blockchain_logo,
      digitalCertificate: projectSummaryData?.order?.digital_certificate,
    };
  };

  // const handleDownload = async () => {
  //   sendGAClickedDownloadCertificateButton();
  //   dispatch(showOrHideApiLoadingModal(true));
  //   const textBlock = document.querySelector(".text-block");

  //   try {
  //     const pdfDoc = await PDFDocument.create({
  //       dpi: 300,
  //     });
  //     const page = pdfDoc.addPage(PageSizes.A4);

  //     if (textBlock) {
  //       textBlock.style.opacity = 0;
  //     }

  //     const canvas = await html2canvas(certificateRef.current, {
  //       useCORS: true,
  //       allowTaint: true,
  //       scale: 2,
  //       windowWidth: 1024,
  //       windowHeight: 1034,
  //     });
  //     const image = canvas.toDataURL("image/png");

  //     const pngImage = await pdfDoc.embedPng(image);

  //     page.drawImage(pngImage, {
  //       x: 0,
  //       y: page.getHeight() - canvas.height * (595 / canvas.width),
  //       width: 595,
  //       height: canvas.height * (595 / canvas.width),
  //     });

  //     // if (data?.signature && data?.previewImage) {
  //     //   if (data?.previewImage) {
  //     //     const previewImageExtension = data.previewImage
  //     //       .split(".")
  //     //       .pop()
  //     //       .split("?")[0]
  //     //       .toLowerCase();

  //     //     const previewImageBytes = await fetch(data.previewImage).then((res) =>
  //     //       res.arrayBuffer()
  //     //     );

  //     //     const backgroundColor = rgb(255 / 255, 255 / 255, 255 / 255);
  //     //     page.drawRectangle({
  //     //       x: page.getWidth() - 150,
  //     //       y: 0,
  //     //       width: 200,
  //     //       height: page.getHeight() / 2 - 140,
  //     //       color: backgroundColor,
  //     //       fillOpacity: 1,
  //     //     });

  //     //     const desiredWidth = 195;
  //     //     const desiredHeight = 195;
  //     //     let image;

  //     //     if (
  //     //       previewImageExtension === "jpeg" ||
  //     //       previewImageExtension === "jpg"
  //     //     ) {
  //     //       image = await pdfDoc.embedJpg(previewImageBytes);
  //     //     } else if (previewImageExtension === "png") {
  //     //       image = await pdfDoc.embedPng(previewImageBytes);
  //     //     } else if (
  //     //       previewImageExtension === "tiff" ||
  //     //       previewImageExtension === "tif"
  //     //     ) {
  //     //       image = await pdfDoc.embedTiff(previewImageBytes);
  //     //     } else if (previewImageExtension === "svg") {
  //     //       const svgString = await fetch(data.previewImage).then((res) =>
  //     //         res.text()
  //     //       );
  //     //       image = await pdfDoc.embedSvg(svgString);
  //     //     } else {
  //     //       throw new Error(
  //     //         `Unsupported image format: ${previewImageExtension}`
  //     //       );
  //     //     }

  //     //     const imageAspectRatio = image.width / image.height;
  //     //     const canvasAspectRatio = desiredWidth / desiredHeight;

  //     //     let width, height, x, y;

  //     //     if (imageAspectRatio > canvasAspectRatio) {
  //     //       width = desiredWidth;
  //     //       height = width / imageAspectRatio;
  //     //       x = page.getWidth() - 250;
  //     //       y = 50 + (desiredHeight - height) / 2;
  //     //     } else {
  //     //       height = desiredHeight;
  //     //       width = height * imageAspectRatio;
  //     //       x = page.getWidth() - 250 + (desiredWidth - width) / 2;
  //     //       y = 50;
  //     //     }

  //     //     page.drawImage(image, {
  //     //       x,
  //     //       y,
  //     //       width,
  //     //       height,
  //     //     });
  //     //   }

  //     //   if (data?.signature) {
  //     //     const signatureImageExtension = data.signature
  //     //       .split(".")
  //     //       .pop()
  //     //       .split("?")[0]
  //     //       .toLowerCase();

  //     //     let signatureBlob;
  //     //     if (signatureImageExtension === "png") {
  //     //       signatureBlob = await fetch(data.signature).then((res) =>
  //     //         res.blob()
  //     //       );

  //     //       const signaturePngBytes = await fetch(data.signature).then((res) =>
  //     //         res.arrayBuffer()
  //     //       );

  //     //       const signaturePngEmbed = await pdfDoc.embedPng(signaturePngBytes);
  //     //       page.drawImage(signaturePngEmbed, {
  //     //         x: 50,
  //     //         y: 20,
  //     //         width: 140,
  //     //         height: 65,
  //     //       });
  //     //     } else {
  //     //       signatureBlob = await fetch(data.signature).then((res) =>
  //     //         res.blob()
  //     //       );

  //     //       const signatureBase64 = await new Promise((resolve) => {
  //     //         const reader = new FileReader();
  //     //         reader.onloadend = () => resolve(reader.result.split(",")[1]);
  //     //         reader.readAsDataURL(signatureBlob);
  //     //       });

  //     //       const signatureImage = await new Promise((resolve) => {
  //     //         const image = new Image();
  //     //         image.src = `data:image/svg+xml;base64,${signatureBase64}`;
  //     //         image.onload = () => resolve(image);
  //     //       });

  //     //       const signatureCanvas = document.createElement("canvas");
  //     //       signatureCanvas.width = signatureImage.width;
  //     //       signatureCanvas.height = signatureImage.height;

  //     //       const ctx = signatureCanvas.getContext("2d");
  //     //       ctx.drawImage(signatureImage, 0, 0);

  //     //       const signaturePng = signatureCanvas.toDataURL("image/png");
  //     //       const signaturePngBytes = await fetch(signaturePng).then((res) =>
  //     //         res.arrayBuffer()
  //     //       );

  //     //       const signaturePngEmbed = await pdfDoc.embedPng(signaturePngBytes);
  //     //       page.drawImage(signaturePngEmbed, {
  //     //         x: 50,
  //     //         y: 20,
  //     //         width: 140,
  //     //         height: 65,
  //     //       });
  //     //     }
  //     //   }
  //     // }

  //     if (textBlock) {
  //       textBlock.style.transform = "rotate(180deg)";
  //     }

  //     const textBlockContent = [
  //       {
  //         text: string.POWERED_BY_HP,
  //         x: 570,
  //         y: 55,
  //         size: 10,
  //         alignment: "right",
  //         lineHeight: 12,
  //       },

  //       {
  //         text: string.BLOCKCHAIN_AUTHENTICATED_CERTIFICATE,
  //         x: 570,
  //         y: 140,
  //         size: 10,
  //         alignment: "right",
  //         lineHeight: 12,
  //       },
  //     ];

  //     const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
  //     const textOptions = {
  //       font: helveticaFont,
  //       size: textBlockContent[0].size,
  //       color: rgb(0, 0, 0),
  //       rotate: degrees(90),
  //       lineHeight: textBlockContent[0].lineHeight,
  //       maxWidth: 500,
  //       maxHeight: 40,
  //       x: textBlockContent[0].x,
  //     };

  //     textBlockContent.forEach((content) => {
  //       const textY = content.y;

  //       page.drawText(content.text, {
  //         ...textOptions,
  //         y: textY,
  //         align: content.alignment,
  //       });
  //     });
  //     // const hpLogoBytes = await fetch(Icons.hpLogoSideway).then((res) =>
  //     //   res.arrayBuffer()
  //     // );
  //     // const hpLogoImage = await pdfDoc.embedPng(hpLogoBytes);
  //     // page.drawImage(hpLogoImage, {
  //     //   x: 560,
  //     //   y: 30,
  //     //   width: 12,
  //     //   height: 12,
  //     // });
  //     const pdfBytes = await pdfDoc.save();

  //     const blob = new Blob([pdfBytes], { type: "application/pdf" });
  //     const link = document.createElement("a");
  //     link.crossOrigin = "Anonymous";
  //     link.href = URL.createObjectURL(blob);

  //     link.download = "certificate.pdf";
  //     link.click();

  //     dispatch(showOrHideApiLoadingModal(false));
  //     if (textBlock) {
  //       textBlock.style.opacity = 1;
  //     }
  //   } catch (error) {
  //     dispatch(showOrHideApiLoadingModal(false));
  //     console.error("Error capturing screenshot:", error);
  //     if (textBlock) {
  //       textBlock.style.opacity = 1;
  //     }
  //   }
  // };

  return (
    <>
      <ProjectLoader />
      {(!isLoading || !finalPrint) && (
        <div className={finalPrint ? "print-digitial-certificate" : ""}>
          <div className={finalPrint ? "sub-container" : ""}>
            <div
              className={
                finalPrint
                  ? "digital-certificate-block blocker"
                  : "digital-certificate-block"
              }
              style={{
                backgroundImage: finalPrint
                  ? `url(${data?.digitalPattern})`
                  : `url(${Icons.digitalPattern})`,
              }}
              ref={certificateRef}
            >
              <div
                className="blockchain-logo"
                style={{
                  backgroundImage: finalPrint
                    ? `url(${data?.blockchainLogo})`
                    : `url(${Icons.blockchainLogo})`,
                }}
              >
                <div className="title">{data?.title}</div>
                <div className="artist-name">{data?.artistName}</div>

                {data?.biography && (
                  <div className="paragraph-block">
                    <div className="font-head">{string.PHOTOGRAPHERS_BIO}</div>
                    <div className="font-body">{data?.biography}</div>
                  </div>
                )}

                {data?.description && (
                  <div className="paragraph-block">
                    <div className="font-head">
                      {string.PHOTOGRAPHERS_DESCRIPTION}
                    </div>
                    <div className="font-body">{data?.description}</div>
                  </div>
                )}

                <div className="digital-grid-block">
                  <div className="grid-content-block">
                    <div className="grid-title">{string.INFORMATION}</div>
                    <div>
                      <div className="head-content">{string.YEAR}</div>
                      <div className="body-content">{data?.year}</div>
                    </div>
                    <div>
                      <div className="head-content">{string.DIMENSIONS}</div>
                      <div className="body-content">{data?.dimensions}</div>
                    </div>
                    <div>
                      <div className="head-content">{string.PAPER_TYPE_1}</div>
                      <div className="body-content">{data?.paperType}</div>
                    </div>
                  </div>
                  <div className="grid-content-block">
                    <div className="grid-title">{string.BLOCKCHAIN}</div>
                    <div>
                      <div className="head-content">{string.TOKEN}</div>
                      <div className="body-content max-content">
                        {data?.token}
                      </div>
                    </div>
                    <div>
                      <div className="head-content">{string.MINT_TIME}</div>
                      <div className="body-content">{data?.mintTime}</div>
                    </div>
                  </div>
                </div>

                <div className="d-flex preview-block justify-content-between">
                  <div className="signature-block">
                    {data?.signature && (
                      <img
                        alt="signature"
                        className="signature"
                        src={data?.signature}
                      />
                    )}
                  </div>
                  <div
                    className={
                      !finalPrint
                        ? "d-flex"
                        : "d-flex image-copyright-container"
                    }
                  >
                    <div className="edition-image">
                      <div className="edition-font">
                        {finalPrint
                          ? data?.edition
                          : data?.isLimited
                          ? data?.edition + "/" + data?.noOfCopies
                          : "1"}{" "}
                      </div>
                      <div
                        className={finalPrint ? "edition-image-content" : ""}
                      >
                        <img
                          alt=""
                          className="final-image"
                          src={data?.previewImage}
                        />
                      </div>
                    </div>
                    <div className="copyright-block">
                      <div className="d-flex text-block">
                        <div className="copyright-left-text">
                          {string.POWERED_BY_HP}
                        </div>
                        <div className="copyright-right-text">
                          {string.BLOCKCHAIN_AUTHENTICATED_CERTIFICATE}
                        </div>
                      </div>
                      <div>
                        <img
                          alt="hp-logo"
                          className="hp-logo"
                          src={Icons.hpLogoSideway}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {finalPrint && (
            <div className="button-container">
              <button
                onClick={() => {
                  window.open(data?.digitalCertificate);
                  sendGAClickedDownloadCertificateButton(
                    queryStrings["utm_source"] ?? ""
                  );
                }}
              >
                {string.DOWNLOAD_CERTIFICATE}
                <span className="download-image-container">
                  <img src={downloadIcon} alt="download" />
                </span>
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DigitalCertficate;
