import React, { useEffect, useRef, useState } from "react";
import "./AdminTables.scss";
import Pagination from "./Pagination/Pagination";
import AdminSearchBar from "../AdminSearchBar/AdminSearchBar";
import { useDispatch, useSelector } from "react-redux";
import status_right from "../../../Utils/Icons/status_right.svg";
import error_icon from "../../../Utils/Icons/error_icon.svg";

import {
  PublishToOpenSea,
  RetrieveNfts,
  pollPublishToOpenSea,
  getCurrentUser,
} from "../../../Utils/ApiHelper";
import {
  setPage,
  setUserColumnKey,
  setUserTypeKey,
} from "../../../Store/Project/AdminSlice";
import {
  ASCENDING_ORDERS,
  DESCENDING_ORDERS,
  PAGE_SIZE,
  PROJECT_ID,
  STATE,
  STATUS_CODE_ACCEPTED,
  STATUS_CODE_SUCCESS,
  TAG,
} from "../../../Utils/Constants";
import { showOrHideApiLoadingModal } from "../../../Store/Project/ModalSlice";
import ProjectLoader from "../../../Components/Loaders/ProjectLoader/ProjectLoader";
import { string } from "../../../Localization";
import {
  setCanShowBackAlertModal,
  setCanShowErrorAlertModal,
} from "../../../Store/Project/ProjectSlice";
import StatusModal from "../../../Components/StatusModal/StatusModal";
import { Icons } from "../../../Utils/Icons";
import StatusColumn from "./StatusColumn";
import ConfigureWallet from "./ConfigureWallet/ConfigureWallet";
import { showAdminSearchBox } from "../../../Utils/Helper";
import SetWallet from "./SetWallet/SetWallet";
import ViewWallet from "./ViewWallet/ViewWallet";
import { setCurrentUserDetails } from "../../../Store/Project/UserSlice";

const NFTTables = () => {
  const { userSearch, userColumnKey, userTypeKey, userSearchKey } = useSelector(
    (state) => state.admin
  );
  const { CanShowBackAlertModal, CanShowErrorAlertModal } = useSelector(
    (state) => state.project
  );

  const { publish_to_opensea, wallet_configured, wallet_active } = useSelector(
    (state) => state.user.currentUserDetails
  );
  const dispatch = useDispatch();

  const mounted = useRef();

  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [recordCount, setRecordCount] = useState(0);
  const [projectId, setProjectId] = useState(true);
  const [tag, setTag] = useState(true);
  const [state, setState] = useState(true);
  const [walletDetail, setWalletDetail] = useState("");
  const [refresh, setRefresh] = useState(true);

  const options = [
    { value: 0, label: "Tag" },
    { value: 1, label: "State" },
    { value: 2, label: "Last Name" },
    { value: 3, label: "project_id" },
  ];

  useEffect(() => {
    mounted.current = true;
  }, []);

  useEffect(() => {
    if (wallet_configured) {
      dispatch(showOrHideApiLoadingModal(true));
      RetrieveNfts(
        currentPage - 1,
        PAGE_SIZE,
        userTypeKey,
        userColumnKey,
        userSearchKey,
        userSearch
      )
        .then((res) => {
          // console.log(res?.data?.data?.records_count);
          dispatch(showOrHideApiLoadingModal(false));
          setData(res?.data?.data?.records_arr);
          setTotalPage(res?.data?.data?.total_pages);
          setRecordCount(res?.data?.data?.records_count);
          if (res?.data?.data?.parent_record !== "") {
            setWalletDetail(res.data.data.parent_record);
          }
          if (mounted.current) {
            getCurrentUser().then((res) => {
              dispatch(setCurrentUserDetails(res.data.data));
            });
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(showOrHideApiLoadingModal(false));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userColumnKey, userSearch, userTypeKey, userSearchKey, currentPage]);

  useEffect(() => {
    if (wallet_configured) {
      RetrieveNfts(
        currentPage - 1,
        PAGE_SIZE,
        userTypeKey,
        userColumnKey,
        userSearchKey,
        userSearch
      )
        .then((res) => {
          setData(res?.data?.data?.records_arr);
          setTotalPage(res?.data?.data?.total_pages);
          setRecordCount(res?.data?.data?.records_count);
          if (res?.data?.data?.parent_record !== "") {
            setWalletDetail(res.data.data.parent_record);
          }
          if (mounted.current) {
            getCurrentUser().then((res) => {
              dispatch(setCurrentUserDetails(res.data.data));
            });
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(showOrHideApiLoadingModal(false));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userColumnKey,
    userSearch,
    userTypeKey,
    userSearchKey,
    currentPage,
    refresh,
  ]);
  useEffect(() => {
    if (wallet_configured) {
      RetrieveNfts(
        currentPage - 1,
        PAGE_SIZE,
        userTypeKey,
        userColumnKey,
        userSearchKey,
        userSearch
      )
        .then((res) => {
          if (res?.data?.data?.parent_record !== "") {
            setWalletDetail(res.data.data.parent_record);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userColumnKey,
    userSearch,
    userTypeKey,
    userSearchKey,
    currentPage,
    refresh,
    wallet_active,
  ]);
  const handleCopyClick = (jsonString) => {
    navigator.clipboard
      .writeText(jsonString)
      .then(() => {
        dispatch(setCanShowBackAlertModal(true));
      })
      .catch((error) => {
        console.error("Failed to copy ID to clipboard:", error);
      });
  };

  const handlePublishToOpenSea = (tag) => {
    dispatch(showOrHideApiLoadingModal(true));
    PublishToOpenSea(tag)
      .then((res) => {
        if (res?.status === STATUS_CODE_ACCEPTED) {
          handlePollPublishToOpenSea(tag);
        }
      })
      .catch(function (error) {
        dispatch(showOrHideApiLoadingModal(false));
        dispatch(setCanShowErrorAlertModal(true));
        console.log(error);
      });
  };

  let publishToOpenSeaPoller = null;

  const stopPolling = () => {
    clearInterval(publishToOpenSeaPoller);
  };

  const handlePollPublishToOpenSea = (tag) => {
    publishToOpenSeaPoller = setInterval(function () {
      pollPublishToOpenSea(tag)
        .then((res) => {
          switch (res.status) {
            case STATUS_CODE_SUCCESS:
              const response = res?.data?.data;
              const currentId = response?.id;
              setData((prevArray) =>
                prevArray.map((item) =>
                  item.id === currentId
                    ? {
                        ...item,
                        tag: response?.tag,
                        state: response?.state,
                        edition: response?.edition,
                        sl_num: response?.sl_num,
                        open_sea_identifier_id:
                          response?.open_sea_identifier_id,
                        transaction_id: response?.transaction_id,
                        published_url: response?.published_url,
                        req_status: response?.req_status,
                      }
                    : item
                )
              );
              stopPolling();
              dispatch(showOrHideApiLoadingModal(false));
              dispatch(setCanShowBackAlertModal(true));
              setHeading("Successfully Published to OpenSea");
              break;
            case STATUS_CODE_ACCEPTED:
            default:
          }
        })
        .catch(function (error) {
          stopPolling();
          dispatch(showOrHideApiLoadingModal(false));
          dispatch(setCanShowErrorAlertModal(true));

          console.error("Error in publishing to OpenSea:", error);
        });
    }, 5000);
  };

  const rightCloseHandler = () => {
    dispatch(setCanShowBackAlertModal(false));
  };

  const wrongHandleClose = () => {
    dispatch(setCanShowErrorAlertModal(false));
  };

  const defaultUI = (message) => (
    <div className="admin_user_back_btn">
      <div className="admin_no_user_details">
        <div className="order_no_user_details_text">{message}</div>
      </div>
    </div>
  );

  const totalCount = totalPage * PAGE_SIZE;

  return (
    <>
      <ProjectLoader />
      <div className="admin_tables">
        <div className="d-flex  align-items-center justify-content-between">
          <div
            className="admin_search_bar_container"
            style={{ display: "flex", gap: "10px" }}
          >
            {showAdminSearchBox(userSearchKey, recordCount) && (
              <>
                <AdminSearchBar
                  setUserTypeKey={setUserTypeKey}
                  setUserColumnKey={setUserColumnKey}
                  options={options}
                />
                <i
                  className="fa refresh_load"
                  onClick={() => {
                    setRefresh(!refresh);
                  }}
                >
                  &#xf021;
                </i>
              </>
            )}
          </div>
          <div style={{ display: "flex", marginRight: "20px" }}>
            <SetWallet setRefresh={setRefresh} refresh={refresh} />
            {walletDetail && (
              <ViewWallet
                user_infro={walletDetail?.user}
                wallet_address={walletDetail?.wallet_address}
                Collection_name={walletDetail?.collection_name}
                Url_link={walletDetail?.open_sea_collection_url}
              />
            )}
            <ConfigureWallet setRefresh={setRefresh} refresh={refresh} />
          </div>
        </div>
        {wallet_configured ? (
          recordCount === 0 ? (
            defaultUI(string.NO_RECORDS_FOUND)
          ) : (
            <>
              <div className="admin_tables_wrapper">
                <table className="table table-borderless table_block">
                  <thead className="table_head">
                    <tr>
                      <th>
                        #
                        {projectId ? (
                          <div
                            className="admin_navBar_sort fa fa-angle-down"
                            onClick={() => {
                              dispatch(setUserColumnKey(PROJECT_ID));
                              dispatch(setUserTypeKey(ASCENDING_ORDERS));
                              setProjectId(false);
                            }}
                          ></div>
                        ) : (
                          <div
                            className="admin_navBar_sort fa fa-angle-up"
                            onClick={() => {
                              dispatch(setUserColumnKey(PROJECT_ID));
                              dispatch(setUserTypeKey(DESCENDING_ORDERS));
                              setProjectId(true);
                            }}
                          ></div>
                        )}
                      </th>
                      <th>{string.CREATED_AT}</th>
                      <th>
                        {string.TAG}
                        {tag ? (
                          <div
                            className="admin_navBar_sort fa fa-angle-down"
                            onClick={() => {
                              dispatch(setUserColumnKey(TAG));
                              dispatch(setUserTypeKey(ASCENDING_ORDERS));
                              setTag(false);
                            }}
                          ></div>
                        ) : (
                          <div
                            className="admin_navBar_sort fa fa-angle-up"
                            onClick={() => {
                              dispatch(setUserColumnKey(TAG));
                              dispatch(setUserTypeKey(DESCENDING_ORDERS));
                              setTag(true);
                            }}
                          ></div>
                        )}{" "}
                      </th>
                      <th>{string.THUMBNAIL}</th>
                      <th>
                        {string.STATE}
                        {state ? (
                          <div
                            className="admin_navBar_sort fa fa-angle-down"
                            onClick={() => {
                              dispatch(setUserColumnKey(STATE));
                              dispatch(setUserTypeKey(ASCENDING_ORDERS));
                              setState(false);
                            }}
                          ></div>
                        ) : (
                          <div
                            className="admin_navBar_sort fa fa-angle-up"
                            onClick={() => {
                              dispatch(setUserColumnKey(STATE));
                              dispatch(setUserTypeKey(DESCENDING_ORDERS));
                              setState(true);
                            }}
                          ></div>
                        )}{" "}
                      </th>
                      <th>{string.SL_NUM}</th>
                      <th>{string.PRINT_STATUS}</th>
                      <th>{string.OPENSEA_IDENTIFIER_ID}</th>
                      <th>{string.TRANSACTION_ID}</th>
                      <th>{string.PUBLISHED_BY}</th>
                      <th>{string.PUBLISH_URL}</th>
                      <th>{string.OPENSEA_STATUS}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => {
                      return (
                        <tr className="admin_table_row" key={index}>
                          <td>{item.id}</td>
                          <td>{item.created_at}</td>
                          <td>{item.tag ? item.tag : "-"}</td>
                          <td>
                            <img
                              src={item.display_image_url}
                              className="thumbnail-img"
                              alt=""
                            />
                          </td>
                          <td>{item.state ? item.state : "-"}</td>
                          <td>{item.sl_num ? item.sl_num : "-"}</td>
                          <td>
                            {item?.print_status ? (
                              <StatusColumn status={item.print_status} />
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>
                            {item.open_sea_identifier_id
                              ? item.open_sea_identifier_id
                              : "-"}
                          </td>
                          <td>
                            {item.transaction_id ? (
                              <div className="admin_view_btn">
                                {item.transaction_id}
                                <img
                                  src={Icons.copy.default}
                                  alt="copy"
                                  className="copy-icon"
                                  onClick={() => {
                                    setHeading(string.COPIED_SUCCESSFULLY);
                                    setSubHeading(
                                      string.TRACKING_ID_COPIED_SUCCESSFULLY
                                    );
                                    handleCopyClick(item.transaction_id);
                                  }}
                                />
                              </div>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>
                            {item.published_by ? (
                              <div className="d-flex justify-content-center">
                                {item.published_by}
                              </div>
                            ) : (
                              <>-</>
                            )}
                          </td>
                          <td>
                            {item.published_url ? (
                              <div className="d-flex justify-content-center">
                                <a
                                  className="image-tag-2"
                                  href={item.published_url}
                                  alt="view"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {string.VIEW}
                                </a>
                              </div>
                            ) : (
                              <>-</>
                            )}
                          </td>
                          <td>
                            {item?.req_status ? (
                              <StatusColumn status={item.req_status} />
                            ) : (
                              <div
                                className="admin_view_btn"
                                onClick={() => {
                                  if (publish_to_opensea)
                                    handlePublishToOpenSea(item.tag);
                                }}
                              >
                                <div
                                  disabled={!publish_to_opensea}
                                  className={
                                    publish_to_opensea
                                      ? "publish_to_NFT "
                                      : "publish_to_NFT publish_to_NFT_disabled"
                                  }
                                >
                                  {string.PUBLISH_TO_OPENSEA}
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="admin_pagination">
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={totalCount}
                  pageSize={PAGE_SIZE}
                  onPageChange={(page) => {
                    setCurrentPage(page);
                    dispatch(setPage(page));
                  }}
                />
              </div>
            </>
          )
        ) : (
          defaultUI(string.WALLET_NOT_CONFIGURED)
        )}
      </div>
      <StatusModal
        canShow={CanShowBackAlertModal}
        stautsbackground={{ background: "#176267" }}
        statusimge={status_right}
        statusHeading={heading}
        statusDescription={subHeading}
        closebackground={{ background: "#176267" }}
        closeData={{
          closeBtn: string.CLOSE,
          closeHandler: rightCloseHandler,
        }}
      />
      <StatusModal
        canShow={CanShowErrorAlertModal}
        stautsbackground={{ background: "#212121" }}
        statusimge={error_icon}
        statusHeading="Failed to Publish to OpenSea"
        closebackground={{ background: "#212121" }}
        closeData={{
          closeBtn: string.CLOSE,
          closeHandler: wrongHandleClose,
        }}
      />
    </>
  );
};

export default NFTTables;
