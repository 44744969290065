import React, { useEffect, useState } from "react";
import "./AdminTables.scss";
import Pagination from "./Pagination/Pagination";
import AdminSearchBar from "../AdminSearchBar/AdminSearchBar";
import { RetriveUsers, ToggleAdmin } from "../../../Utils/ApiHelper";
import { useDispatch, useSelector } from "react-redux";
import {
  setActivePageIndex,
  setPage,
  setUserColumnKey,
  setUserKeyId,
  setUserTypeKey,
} from "../../../Store/Project/AdminSlice";
import {
  ASCENDING_ORDERS,
  DESCENDING_ORDERS,
  EMAIL_SORT,
  FIRST_NAME_SORT,
  LAST_NAME_SORT,
  PAGE_SIZE,
  USER_PROJECT_PAGE,
} from "../../../Utils/Constants";
import { showOrHideApiLoadingModal } from "../../../Store/Project/ModalSlice";
import ProjectLoader from "../../../Components/Loaders/ProjectLoader/ProjectLoader";
import AdminModal from "../../../Components/Modal/AdminModal/AdminModal";
import { string } from "../../../Localization";
import {
  setSalesOrderModal,
  setCheckBoxModal,
} from "../../../Store/Project/ModalSlice";
import InstagramIcon from "../../../Utils/Icons/instagram.svg";
import FacebookIcon from "../../../Utils/Icons/facebook.svg";
import TwitterIcon from "../../../Utils/Icons/twitter.svg";
import AdminViewModal from "../../../Components/Modal/AdminViewModal/AdminViewModal";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { showAdminSearchBox } from "../../../Utils/Helper";

const UserTable = () => {
  const { salesOrderModal, checkBoxModal } = useSelector(
    (state) => state.modal
  );

  const { userSearch, userColumnKey, userTypeKey, userSearchKey } = useSelector(
    (state) => state.admin
  );
  const { currentUserDetails } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [userData, setUserData] = useState([]);
  const [email, setemail] = useState(true);
  const [firstNameSort, setFistNameSort] = useState(true);
  const [lastNameSort, setLastNameSort] = useState(true);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [totalPage, setTotalPage] = useState(0);
  const [createdAtSort, setCreatedAtSort] = useState(true);
  const [userId, setUserId] = useState();
  const [subHeading, setSubHeading] = useState(false);
  const [key, setKey] = useState("");
  const [largeData, setLargeData] = useState("");
  const [recordCount, setRecordCount] = useState(0);
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    dispatch(showOrHideApiLoadingModal(true));
    retriveUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userColumnKey,
    userTypeKey,
    currentPage,
    userSearch,
    userSearchKey,
    refresh,
  ]);

  const options = [
    { value: "0", label: "Email" },
    { value: "1", label: "First Name" },
    { value: "2", label: "Last Name" },
  ];

  const retriveUser = () => {
    RetriveUsers(
      currentPage - 1,
      PAGE_SIZE,
      userTypeKey,
      userColumnKey,
      userSearchKey,
      userSearch
    )
      .then((res) => {
        setUserData(res?.data?.data?.records_arr);
        setTotalPage(res?.data?.data?.total_pages);
        dispatch(showOrHideApiLoadingModal(false));
        setRecordCount(res?.data?.data?.records_count);
      })
      .catch((error) => {
        dispatch(showOrHideApiLoadingModal(false));
        if (error?.response?.status === 401) {
          navigate("/");
        }
      });
  };

  const handleYesClick = () => {
    dispatch(setCheckBoxModal(false));
    dispatch(showOrHideApiLoadingModal(true));
    ToggleAdmin(userId, key).then((res) => {
      retriveUser();
    });
  };

  const totalCount = totalPage * PAGE_SIZE;

  const handleRowClick = (userToken) => {
    navigate(`user_project/${userToken}`);
    dispatch(setUserKeyId(userToken));
    dispatch(setActivePageIndex(USER_PROJECT_PAGE));
  };
  const handleNoClick = () => {
    dispatch(setCheckBoxModal(false));
  };
  const handleCheckBoxClick = (itemId) => {
    setUserId(itemId);
    dispatch(setCheckBoxModal(true));
  };

  const handleModalClick = (data) => {
    setLargeData(data);
    dispatch(setSalesOrderModal(true));
  };

  const handleClose = () => {
    dispatch(setSalesOrderModal(false));
  };

  const showOpensea = currentUserDetails?.opensea_admin === true;

  return (
    <>
      <div className="admin_tables">
        <div className="d-flex  align-items-center">
          {showAdminSearchBox(userSearchKey, recordCount) && (
            <>
              <div className="admin_search_bar_container">
                <AdminSearchBar
                  setUserTypeKey={setUserTypeKey}
                  setUserColumnKey={setUserColumnKey}
                  options={options}
                />
              </div>
              <i
                className="fa refresh_load"
                onClick={() => {
                  setRefresh(!refresh);
                }}
              >
                &#xf021;
              </i>
            </>
          )}
        </div>
        {recordCount === 0 ? (
          <>
            <ProjectLoader />
            <div className="admin_user_back_btn">
              <div className="admin_no_user_details">
                <div className="order_no_user_details_text">
                  {string.NO_RECORDS_FOUND}{" "}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="admin_tables_wrapper">
              <ProjectLoader />
              <table className="table table-borderless table_block">
                <thead className="table_head">
                  <tr className="user-tabel-row">
                    <th>
                      #{" "}
                      {createdAtSort ? (
                        <div
                          className="admin_navBar_sort fa fa-angle-down"
                          onClick={() => {
                            dispatch(setUserColumnKey(""));
                            dispatch(setUserTypeKey(ASCENDING_ORDERS));
                            setCreatedAtSort(false);
                          }}
                        ></div>
                      ) : (
                        <div
                          className="admin_navBar_sort fa fa-angle-up"
                          onClick={() => {
                            dispatch(setUserColumnKey(""));
                            dispatch(setUserTypeKey(DESCENDING_ORDERS));
                            setCreatedAtSort(true);
                          }}
                        ></div>
                      )}
                    </th>
                    <th>
                      {string.EMAIL_1}{" "}
                      {email ? (
                        <div
                          className="admin_navBar_sort fa fa-angle-down"
                          onClick={() => {
                            dispatch(setUserColumnKey(EMAIL_SORT));
                            dispatch(setUserTypeKey(ASCENDING_ORDERS));
                            setemail(false);
                          }}
                        ></div>
                      ) : (
                        <div
                          className="admin_navBar_sort fa fa-angle-up"
                          onClick={() => {
                            dispatch(setUserColumnKey(EMAIL_SORT));
                            dispatch(setUserTypeKey(DESCENDING_ORDERS));
                            setemail(true);
                          }}
                        ></div>
                      )}
                    </th>
                    <th>
                      {string.FIRST_NAME}{" "}
                      {firstNameSort ? (
                        <div
                          className="admin_navBar_sort fa fa-angle-down "
                          onClick={() => {
                            setFistNameSort(false);
                            dispatch(setUserColumnKey(FIRST_NAME_SORT));
                            dispatch(setUserTypeKey(ASCENDING_ORDERS));
                          }}
                        ></div>
                      ) : (
                        <div
                          className="admin_navBar_sort fa fa-angle-up"
                          onClick={() => {
                            setFistNameSort(true);
                            dispatch(setUserColumnKey(FIRST_NAME_SORT));
                            dispatch(setUserTypeKey(DESCENDING_ORDERS));
                          }}
                        ></div>
                      )}
                    </th>
                    <th>
                      {string.LAST_NAME}{" "}
                      {lastNameSort ? (
                        <div
                          className="admin_navBar_sort fa fa-angle-down "
                          onClick={() => {
                            setLastNameSort(false);
                            dispatch(setUserColumnKey(LAST_NAME_SORT));
                            dispatch(setUserTypeKey(ASCENDING_ORDERS));
                          }}
                        ></div>
                      ) : (
                        <div
                          className="admin_navBar_sort fa fa-angle-up"
                          onClick={() => {
                            setLastNameSort(true);
                            dispatch(setUserColumnKey(LAST_NAME_SORT));
                            dispatch(setUserTypeKey(DESCENDING_ORDERS));
                          }}
                        ></div>
                      )}
                    </th>
                    <th>
                      {string.ONBOARING_DATE}
                      {createdAtSort ? (
                        <div
                          className="admin_navBar_sort fa fa-angle-down "
                          onClick={() => {
                            setCreatedAtSort(false);
                            dispatch(setUserColumnKey(""));
                            dispatch(setUserTypeKey(ASCENDING_ORDERS));
                          }}
                        ></div>
                      ) : (
                        <div
                          className="admin_navBar_sort fa fa-angle-up"
                          onClick={() => {
                            setCreatedAtSort(true);
                            dispatch(setUserColumnKey(""));
                            dispatch(setUserTypeKey(DESCENDING_ORDERS));
                          }}
                        ></div>
                      )}
                    </th>
                    <th>{string.BIOGRAPHY}</th>
                    {/* <th>{string.WEBSITE_URL}</th> */}
                    <th>{string.GALLERY_TYPE}</th>
                    <th>{string.USER_TOKEN}</th>
                    <th>{string.FACEBOOK}</th>
                    <th>{string.TWITTER}</th>
                    <th>{string.INSTAGRAM}</th>
                    <th>{string.ENCRYPT_PRINTABLE_ASSET}</th>
                    <th>{string.MULESOFT_LIVE}</th>
                    <th>{string.WEB2PRINT_LIVE}</th>
                    <th>{string.ADMIN_ACCESS}</th>
                    <th>{string.TEST_ACCOUNT}</th>
                    <th>{string.APPROVED_ACCOUNT}</th>
                    <th>{string.REAL_PHOTOGRAPHER}</th>
                    <th>{string.OPEN_AI}</th>
                    {showOpensea && <th>{string.OPENSEA_ARTIST}</th>}
                  </tr>
                </thead>
                <tbody>
                  {userData.map((item, index) => {
                    return (
                      <tr className="admin_table_row" key={index}>
                        <td
                          className={`${
                            hoveredRowIndex === index ? "expanded" : ""
                          }${"order_number"}`}
                          onClick={() => handleRowClick(item.token)}
                        >
                          <Tooltip title={string.CLICK_TO_VIEW_DETAILS} arrow>
                            <span>{item.id}</span>
                          </Tooltip>
                        </td>
                        <td className="text-start">{item.email}</td>
                        <td>{item.first_name}</td>
                        <td>{item.last_name}</td>
                        <td>{item.on_boarding_date}</td>

                        <td>
                          {item.biography === "" ? (
                            "-"
                          ) : (
                            <div className="large_text_wrapper">
                              <div className="overflow_text">
                                {item.biography === "" ? "-" : item.biography}
                              </div>

                              <div
                                className={
                                  item.biography === "" ||
                                  item.biography === "null"
                                    ? ""
                                    : "fa fa-eye"
                                }
                                onClick={() => {
                                  handleModalClick(item.biography);
                                }}
                              ></div>
                            </div>
                          )}
                        </td>
                        <td>{item.gallery_type}</td>
                        <td>{item.token}</td>
                        <td>
                          <div className="social-icons">
                            {item.facebook_url === "" ? (
                              "-"
                            ) : (
                              <a
                                href={item.facebook_url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={FacebookIcon}
                                  alt="facebook"
                                  className="facebook-icon"
                                />
                              </a>
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="social-icons">
                            {item.twitter_url === "" ? (
                              "-"
                            ) : (
                              <a
                                href={item.twitter_url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={TwitterIcon}
                                  alt="twitter"
                                  className="twitter-icon"
                                />
                              </a>
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="social-icons">
                            {item.instagram_url === "" ? (
                              "-"
                            ) : (
                              <a
                                href={item.instagram_url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={InstagramIcon}
                                  alt="instagram"
                                  className="insta-icon"
                                />
                              </a>
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="justify-content-center">
                            <label className="checkbox-container">
                              <input
                                type="checkbox"
                                checked={item.encrypt_printable_asset}
                                onChange={() => {
                                  setKey("encrypt_printable_asset");
                                  handleCheckBoxClick(item.id);
                                  setSubHeading("Encrypt Printable Asset");
                                }}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="justify-content-center">
                            <label className="checkbox-container">
                              <input
                                type="checkbox"
                                checked={item.mulesoft_live_mode}
                                onChange={() => {
                                  setKey("mulesoft");
                                  handleCheckBoxClick(item.id);
                                  setSubHeading("Mulesoft Live");
                                }}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </td>
                        <td className="justify-content-center">
                          <div className="justify-content-center">
                            <label className="checkbox-container">
                              <input
                                type="checkbox"
                                checked={item.w2p_live_mode}
                                onChange={() => {
                                  setKey("w2p");
                                  handleCheckBoxClick(item.id);
                                  setSubHeading("Web 2 Print");
                                }}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="justify-content-center">
                            <label className="checkbox-container">
                              <input
                                type="checkbox"
                                checked={item.is_admin}
                                onChange={() => {
                                  setKey("admin");
                                  handleCheckBoxClick(item.id);
                                  setSubHeading("Admin Access");
                                }}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="justify-content-center">
                            <label className="checkbox-container">
                              <input
                                type="checkbox"
                                checked={item.is_test}
                                onChange={() => {
                                  setKey("test");
                                  handleCheckBoxClick(item.id);
                                  setSubHeading("Test Account");
                                }}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="justify-content-center">
                            <label className="checkbox-container">
                              <input
                                type="checkbox"
                                checked={item.beta_user}
                                onChange={() => {
                                  setKey("beta_user");
                                  handleCheckBoxClick(item.id);
                                  setSubHeading("Approve Account");
                                }}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="justify-content-center">
                            <label className="checkbox-container">
                              <input
                                type="checkbox"
                                checked={item.real_photographer}
                                onChange={() => {
                                  setKey("real_photographer");
                                  handleCheckBoxClick(item.id);
                                  setSubHeading("Real Photographer");
                                }}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="justify-content-center">
                            <label className="checkbox-container">
                              <input
                                type="checkbox"
                                checked={item.open_ai}
                                onChange={() => {
                                  setKey("open_ai");
                                  handleCheckBoxClick(item.id);
                                  setSubHeading("Open Ai");
                                }}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </td>
                        {showOpensea && (
                          <td>
                            <div className="justify-content-center">
                              <label className="checkbox-container">
                                <input
                                  type="checkbox"
                                  checked={item.opensea_artist}
                                  onChange={() => {
                                    setKey("opensea_artist");
                                    handleCheckBoxClick(item.id);
                                    setSubHeading("Opensea Artist");
                                  }}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="admin_pagination">
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={totalCount}
                pageSize={PAGE_SIZE}
                onPageChange={(page) => {
                  setCurrentPage(page);
                  dispatch(setPage(page));
                }}
              />
            </div>
          </>
        )}
      </div>
      <AdminModal
        heading={string.ARE_YOU_SURE_YOU_WANT_TO_CONTINUE}
        subheading={
          string.ARE_YOU_SURE_YOU_WANT_TO_MAKE_THE_CHANGES1 +
          subHeading +
          string.ARE_YOU_SURE_YOU_WANT_TO_MAKE_THE_CHANGES2
        }
        openModal={checkBoxModal}
        handleClose={handleNoClick}
        handleConfirm={handleYesClick}
      />
      <AdminViewModal
        handleClose={handleClose}
        jsonStringData={largeData}
        openModal={salesOrderModal}
        // onViewClick={handleCopyClick}
        isOnlyText={true}
      />
    </>
  );
};

export default UserTable;
