import React, { useState } from "react";
import {
  PRICE_CURRENCY_DOLLAR,
  PRICE_CURRENCY_GBP,
} from "../../../Utils/Constants";
import RadioButton from "../RadioButton/RadioButton";
import "./Pricing.scss";
import { string } from "../../../Localization";

const Pricing = (props) => {
  const { onChangePrice, income } = props;

  const [priceCurrency, setPriceCurrency] = useState(PRICE_CURRENCY_GBP);
  const [price, setPrice] = useState(income);

  const priceCurrencyChoices = [
    {
      text: PRICE_CURRENCY_GBP,
      value: PRICE_CURRENCY_GBP,
      id: "currencyGBP",
      group: "currency",
    },
    {
      text: PRICE_CURRENCY_DOLLAR,
      value: PRICE_CURRENCY_DOLLAR,
      id: "currencyDollar",
      group: "currency",
    },
  ];

  const handleChangePriceCurrency = (e) => {
    setPriceCurrency(e.target.value);
    onChangePrice(price, e.target.value);
  };

  const onBlur = (event) => {
    event.preventDefault();
    onChangePrice(price, priceCurrency);
  };

  const onKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };
  const handlePriceChange = (value) => {
    const regex = /^\d+(\.\d{0,2})?$/;
    if (regex.test(value) || value === "") {
      setPrice(value);
    }
  };
  return (
    <>
      <div className="pricing-wrapper">
        <form noValidate onSubmit={onBlur} className="text-box">
          <label htmlFor="" className="selling-price-text">
            {string.ARTIST_INCOME}
          </label>
          <input
            type="number"
            className="input-title"
            onBlur={onBlur}
            id="focus"
            autoFocus
            placeholder="0"
            value={price}
            onblur="focus()"
            onChange={(e) => handlePriceChange(e.target.value)}
            onKeyDown={onKeyDown}
          />
        </form>

        <div className="radio-btn">
          {priceCurrencyChoices.map((priceCurrencyChoice) => (
            <RadioButton
              details={priceCurrencyChoice}
              onChange={handleChangePriceCurrency}
              activeValue={priceCurrency}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Pricing;
