import React, { useEffect, useState } from "react";
import "./PhotographerPhoto.scss";
import Footer from "../LandingPage/Footer/Footer";
import HpLogoBlue from "../../Components/HpLogoBlue/HpLogoBlue";
import { getProjectSummary } from "../../Utils/ApiHelper";
import { useParams, useSearchParams } from "react-router-dom";
import { string } from "../../Localization";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import PublicViewLoader from "../../Components/Loaders/PublicViewLoader/PublicViewLoader";
import {
  sendGAClickedOrderNow,
  sendGALoadedGalleryDesignPage,
} from "../../Utils/GAHelper";

const PhotographerPhoto = () => {
  const [summary, setSummary] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [loadScreen, setLoadScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);

  const [searchParams] = useSearchParams();
  const { project_token } = useParams();

  const isPreview = window.location.pathname.includes("/mygallery");

  const queryStrings = Object.fromEntries([...searchParams]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    setShowLoader(true);
    setLoadScreen(false);

    getProjectSummary(project_token).then((res) => {
      setSummary(res?.data?.data);
      setShowLoader(false);
      setLoadScreen(true);
      sendGALoadedGalleryDesignPage(
        res?.data?.data?.user?.id,
        project_token,
        queryStrings["utm_source"] ?? ""
      );
    });

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 600);
  };

  return (
    <>
      {showLoader ? (
        <div className="public_loader">
          <PublicViewLoader />
        </div>
      ) : (
        <div className="photography_gallary_wrapper">
          <HpLogoBlue />
          {!isPreview ? (
            <div className="breadcrumb_container">
              {loadScreen && (
                <>
                  {isSmallScreen ? (
                    // mobile screen breadcrumbs from internal feed public gallery
                    <>
                      <div className="photography_photo_breadcrumb">
                        <a
                          className="breadcrumb_links"
                          href={`/gallery/${summary?.user?.user_name}?utm_source=gallery`}
                        >
                          <div> {"  <  "}</div>
                          <div>
                            {summary?.user?.artist_name} {string.GALLERY_1}
                          </div>
                        </a>
                      </div>
                    </>
                  ) : (
                    // desktop screen breadcrumbs from internal feed public gallery
                    <>
                      <div className="photography_photo_breadcrumb">
                        <Breadcrumb
                          items={[
                            {
                              label: `${string.HOME}`,
                              link: "/gallery?utm_source=gallery",
                            },
                            {
                              label: `${summary?.user?.artist_name} ${string.GALLERY_1}`,
                              link: `/gallery/${summary?.user?.user_name}?utm_source=gallery`,
                            },
                            {
                              label: `${string.PHOTO_DETAILS}`,
                            },
                          ]}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          ) : (
            // gallery preview page - my_gallery
            <>
              {isSmallScreen ? (
                // mobile screen
                <>
                  <div className="photography_photo_breadcrumb">
                    <a
                      className="breadcrumb_links"
                      href={`/mygallery/${summary?.user?.user_name}`}
                    >
                      <div> {"  <  "}</div>
                      <div>
                        {summary?.user?.artist_name} {string.GALLERY_1}
                      </div>
                    </a>
                  </div>
                </>
              ) : (
                // desktop screen
                <>
                  <div className="photography_photo_breadcrumb">
                    <Breadcrumb
                      items={[
                        {
                          label: `${summary?.user?.artist_name} ${string.GALLERY_1}`,
                          link: `/mygallery/${summary?.user?.user_name}`,
                        },
                        {
                          label: `${string.PHOTO_DETAILS}`,
                        },
                      ]}
                    />
                  </div>
                </>
              )}
            </>
          )}

          <div className="photographers_gallery_container">
            <div className="photographers_gallery_contant_1">
              <img
                src={summary?.project?.preview_thubmnail_url}
                alt="gallery"
                className="photographers_gallary_img"
              />
            </div>

            <div className="photographers_gallery_contant_2">
              <div className="photographers_gallery_txt_1">
                {string.GENERAL_INFO}
              </div>
              <div className="photographers_gallery_txt_2">
                <span className="gallery_txt">{string.TITLE}:</span> "
                {summary?.project?.title}"
              </div>
              {summary?.project?.description !==
                "No description is Available" && (
                <div className="photographers_gallery_txt_2">
                  <span className="gallery_txt">{string.DESCRIPTION}:</span>{" "}
                  {summary?.project?.description}
                </div>
              )}

              <div className="photographers_gallery_txt_2">
                <div className="photo_price">
                  {summary?.price?.selling_price}
                </div>
              </div>
              <div className="photographers_gallery_txt_2">
                <span className="gallery_txt">
                  {" "}
                  {string.LIMITED_EDITIONS_1}:
                </span>{" "}
                {summary?.project?.no_of_copies}
              </div>
              <div className="photographers_gallery_txt_2">
                <span className="gallery_txt">{string.EDITIONS}:</span>{" "}
                {summary?.project?.upcoming_edition}
              </div>
              <div className="photographers_gallery_txt_2">
                <span className="gallery_txt">{string.PHOTO_SIZE_1}:</span>{" "}
                {summary?.project?.photo_size}
              </div>
              <div className="photographers_gallery_txt_2">
                <span className="gallery_txt">{string.PAPER_TYPE_2}:</span>{" "}
                {summary?.project?.media_type_name}
              </div>
              <div className="photographers_gallery_txt_1">
                {string.HP_BLOCKCHAIN_LEDGER}
              </div>
              <div className="photographers_gallery_txt_2 txt_2">
                {summary?.project?.blockchain?.public_ledger}
              </div>
              <button
                className="photographers_gallery_btn"
                onClick={() => {
                  sendGAClickedOrderNow(
                    summary?.user?.id,
                    project_token,
                    queryStrings["utm_source"] ?? ""
                  );
                  window.open(
                    `/web/projects/${project_token}/preview?utm_source=gallery`,
                    "_blank"
                  );
                }}
              >
                {string.ORDER_NOW}
              </button>
            </div>
          </div>
          <div className="photography_gallery_footer">
            <div className="photography_gallery_inner_container">
              <Footer />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PhotographerPhoto;
