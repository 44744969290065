import React, { useEffect, useState } from "react";
import PhotoDetails from "../PhotoDetails/PhotoDetails";
import checkSvg from "../../../Utils/Icons/check.svg";
import Personalization from "../Personalization/Personalization";
import Certificate from "../Certificate/Certificate";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { getPriceInfo } from "../../../Utils/ApiHelper";
import {
  setCanShowBackAlertModal,
  setActivePreviewMode,
  setCanvasViewMode,
  setPreviewImage,
  setIncomeModal,
} from "../../../Store/Project/ProjectSlice";
import {
  DESIGN_STEPS,
  NORMAL_VIEW,
  PAGE_PROJECT_SUMMARY,
  PRICE_CURRENCY_GBP,
  PROJECT_PREVIEW_MODE_CERTIFICATE,
  PROJECT_PREVIEW_MODE_CROPPED,
  PROJECT_PREVIEW_MODE_PERSONALIZATION,
  STEP_ONE_PHOTO_DETAILS,
  STEP_THREE_CERTIFICATE,
  STEP_TWO_PERSONALISATION,
} from "../../../Utils/Constants";
import {
  setActivePageIndex,
  setActiveStep,
} from "../../../Store/Project/PageSlice";
import { generatePreviewImage } from "../../../Utils/CanvasDownload";
import addSvg from "../../../Utils/Icons/add.svg";
import minimizeSvg from "../../../Utils/Icons/minimize.svg";
import "./Stepper.scss";
import {
  handleMagnifierClick,
  sendPhotoPreview,
} from "../../../Utils/projectHelper";
import {
  sendGAClickedBack,
  sendGAClickedNext,
  sendStep1GA,
} from "../../../Utils/GAHelper";
import DialogBox from "../../DialogBox/DialogBox";
import { useNavigate } from "react-router-dom";
import { resetAndRedirectToHomePage } from "../../../Utils/DraftHelper";
import { handleSaveDraft } from "../../../Utils/SaveDraft";
import { string } from "../../../Localization";
import { setLimitedEditionSelected } from "../../../Store/Project/limitedEditionSlice";
import MediaNotSupported from "../../../Components/Modal/MediaNotSupported/MediaNotSupported";

const Stepper = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    activePreviewMode,
    projectDetails,
    selectedMediaTypeId,
    priceInfo,
    sizeSelectionDetails,
    isMagnifierModeOn,
    magnifierIsCompleted,
    canvasViewMode,
    incomeModal,
  } = useSelector((state) => state.project);
  const { noOfCopies, isLimited } = useSelector(
    (state) => state.limitedEdition
  );
  const { CanShowBackAlertModal } = useSelector((state) => state.project);
  const { activeStep } = useSelector((state) => state.page);
  const { signatureImage } = useSelector((state) => state.signature);

  const { income, currency } = priceInfo;
  const { selectedId } = sizeSelectionDetails;

  const [pricingData, setPricingData] = useState({});
  const [shouldDelay, setShouldDelay] = useState(false);

  useEffect(() => {
    const currencyType = currency === PRICE_CURRENCY_GBP ? 3 : "";
    getPriceInfo(income, currencyType, selectedId, selectedMediaTypeId).then(
      (res) => {
        setPricingData(res.data.data);
      }
    );
  }, [income, currency, selectedId, selectedMediaTypeId]);

  useEffect(() => {
    if (shouldDelay && magnifierIsCompleted) {
      const timer = setInterval(() => {
        if (magnifierIsCompleted) {
          dispatch(setActiveStep(STEP_THREE_CERTIFICATE));
          dispatch(setActivePreviewMode(PROJECT_PREVIEW_MODE_CERTIFICATE));
          clearInterval(timer);
        }
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [magnifierIsCompleted]);

  const submitHandler = () => {
    navigate("/");
    resetAndRedirectToHomePage();
  };
  const cancelHandler = () => {
    handleSaveDraft();
    dispatch(setCanShowBackAlertModal(false));
  };

  const handleNextButton = () => {
    switch (activeStep) {
      case STEP_ONE_PHOTO_DETAILS:
        sendStep1GA();
        sendPhotoPreview().then(() => {});
        if (canvasViewMode !== NORMAL_VIEW) {
          dispatch(setCanvasViewMode(NORMAL_VIEW));
        }
        dispatch(setActiveStep(STEP_TWO_PERSONALISATION));
        dispatch(setActivePreviewMode(PROJECT_PREVIEW_MODE_PERSONALIZATION));
        dispatch(setLimitedEditionSelected(true));

        break;
      case STEP_TWO_PERSONALISATION:
        if (isMagnifierModeOn) {
          setShouldDelay(true);
          handleMagnifierClick(false);
        } else {
          setShouldDelay(false);
          dispatch(setActivePreviewMode(PROJECT_PREVIEW_MODE_CERTIFICATE));
          dispatch(setActiveStep(STEP_THREE_CERTIFICATE));
        }
        generatePreviewImage().then((image) => {
          dispatch(setPreviewImage(image));
        });
        break;
      case STEP_THREE_CERTIFICATE:
        dispatch(setActivePageIndex(PAGE_PROJECT_SUMMARY));
        break;
      default:
        break;
    }
  };

  const handlePrevButton = () => {
    switch (activeStep) {
      case STEP_THREE_CERTIFICATE:
        setShouldDelay(false);
        dispatch(setActiveStep(STEP_TWO_PERSONALISATION));
        dispatch(setActivePreviewMode(PROJECT_PREVIEW_MODE_PERSONALIZATION));
        break;
      case 2:
        if (isMagnifierModeOn) {
          handleMagnifierClick(false);
        }
        dispatch(setActiveStep(STEP_ONE_PHOTO_DETAILS));
        dispatch(setActivePreviewMode(PROJECT_PREVIEW_MODE_CROPPED));
        break;
      default:
        break;
    }
    if (activeStep === STEP_ONE_PHOTO_DETAILS) {
      dispatch(setCanShowBackAlertModal(true));
    }
  };

  const shouldDisableNextButton = () => {
    if (activeStep === STEP_ONE_PHOTO_DETAILS) {
      return (
        projectDetails.title === "" ||
        activePreviewMode !== PROJECT_PREVIEW_MODE_CROPPED
      );
    } else if (activeStep === STEP_TWO_PERSONALISATION) {
      return isLimited && (noOfCopies === "" || parseInt(noOfCopies) === 0);
    } else if (activeStep === STEP_THREE_CERTIFICATE) {
      return !signatureImage;
    }
    return false;
  };

  return (
    <>
      <div className="stepper-container">
        <div className="stepper-wrapper">
          {DESIGN_STEPS?.map((step, i) => (
            <div
              key={step}
              className={`step-item ${activeStep === i + 1 && "active"} ${
                i + 1 < activeStep && "complete" && "complete"
              }`}
            >
              <div className="step-number">
                {i + 1 < activeStep && "complete" ? (
                  <img src={checkSvg} alt=""></img>
                ) : (
                  i + 1
                )}
              </div>
              <p className="step-text">{step}</p>
            </div>
          ))}
        </div>
        {activeStep === STEP_ONE_PHOTO_DETAILS ? (
          <PhotoDetails />
        ) : activeStep === STEP_TWO_PERSONALISATION ? (
          <Personalization />
        ) : (
          <Certificate />
        )}

        <div className="button-section">
          <div
            className="btn-top"
            onClick={() => {
              dispatch(setIncomeModal(true));
            }}
          >
            <div className="income">{string.SELLING_PRICE}</div>
            <div className="income-container">
              <div className="income-number">
                {pricingData.price && pricingData.price.selling_price}
              </div>
              {incomeModal === false ? (
                <>
                  {" "}
                  <div className="income-plus-btn">
                    <img src={addSvg} alt="" className="income-add-btn" />
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="btn-bottom">
            <button
              className={
                // activeStep == STEP_ONE_PHOTO_DETAILS
                //   ? "btn-style next-button-disable"
                //   :
                "btn-style back-button"
              }
              onClick={() => {
                handlePrevButton();
                sendGAClickedBack(activeStep);
              }}
            >
              {string.BACK}
            </button>
            <Tooltip
              title={
                activeStep === STEP_ONE_PHOTO_DETAILS &&
                shouldDisableNextButton() &&
                activePreviewMode !== PROJECT_PREVIEW_MODE_CROPPED
                  ? string.PLEASE_SELECT_A_SIZE
                  : ""
              }
              arrow
            >
              <button
                className={
                  shouldDisableNextButton()
                    ? "btn-style next-button-disable"
                    : "btn-style next-button"
                }
                disabled={shouldDisableNextButton()}
                onClick={() => {
                  handleNextButton();

                  sendGAClickedNext(activeStep);
                }}
              >
                {activeStep === DESIGN_STEPS.length
                  ? string.REVIEW
                  : string.NEXT}
              </button>
            </Tooltip>
          </div>
          {incomeModal === true ? (
            <>
              <div className="income-modal-container">
                <div
                  className="income-modal-content"
                  onClick={() => {
                    dispatch(setIncomeModal(false));
                  }}
                >
                  <div className="income-modal-text-bold">
                    {string.PRICE_DESCRIPTION}
                  </div>

                  <div className="income-modal-price">
                    <img
                      src={minimizeSvg}
                      alt=""
                      className="income-minus-btn"
                    />
                  </div>
                </div>
                <div className="income-modal-content">
                  <div className="income-modal-text">
                    {string.ARTIST_INCOME}
                  </div>
                  <div className="income-modal-price">
                    {pricingData.price && pricingData.price.income}
                  </div>
                </div>
                <div className="income-modal-content">
                  <div className="income-modal-text">{string.SERVICE_FEES}</div>
                  <div className="income-modal-price">
                    {pricingData.price && pricingData.price.service_fees}
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <DialogBox
        canShow={CanShowBackAlertModal}
        heading={string.BACK_HOME}
        text1={string.ARE_YOU_SURE_THAT_YOU_WANT_TO_GO_BACK_HOME}
        text2={string.SAVE_A_DRAFT_FIRST}
        submitData={{
          submitText: string.BACK_HOME,
          submitHandler: submitHandler,
          cancelBtn: string.SAVE_AS_DRAFT,
          cancelHandler: cancelHandler,
        }}
        navigateTo={"/"}
      />
      <MediaNotSupported />
    </>
  );
};

export default Stepper;
