import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUserDetails: null,
};

export const userSlice = createSlice({
  name: "currentUserDetails",
  initialState: initialState,
  reducers: {
    setCurrentUserDetails(state, action) {
      state.currentUserDetails = action.payload;
    },
  },
});

export const { setCurrentUserDetails } = userSlice.actions;
export default userSlice.reducer;
