import React, { useEffect } from "react";
import "./PaymentTermsOfService.scss";
import { sendGALoadedPaymentTermsOfService } from "../../../../../Utils/GAHelper";
import { string } from "../../../../../Localization";

const PaymentTermsOfService = () => {
  useEffect(() => {
    sendGALoadedPaymentTermsOfService();
  }, []);

  return (
    <div className="paymentTermsOfService-container">
      <div className="main-container">
        <div className="title-block">
          <h6 className="text-normal">{string.PHOTOGRAPHERS_LICENSE_TERMS} </h6>
          <h6 className="text-normal">{string.DATE_OF_RELEASE}</h6>
        </div>
        <h1 className="heading letter-spacing">
          {string.PHOTOGRAPHERS_LICENSE_TERMS1}
        </h1>
        <p className="contents">
          {string.PHOTOGRAPHERS_LICENSE_PAYMENT_TERMS_PT1}{" "}
          <a
            href="/docs/terms/uk/photographer/subscription-terms-of-service.html"
            target="_blank"
            rel="noreferrer"
          >
            {string.HP_LIMITED_EDITION_PRINT_SERVICES1}
            <span>-</span>
            {string.SUBSCRIBER_TERMS}
          </a>{" "}
          {string.AND}{" "}
          <u>
            <a
              href="https://www.hp.com/gb-en/terms-of-use.html"
              target="_blank"
              rel="noreferrer"
            >
              {string.LINK_TO_SUBSCRIBER}
            </a>
          </u>{" "}
          ({string.COLLECTIVELY_THE} <strong>"{string.AGREEMENT}"</strong>){" "}
          {string.SUBSCRIBER_TERMS_CONTENT_1a} (<strong>"{string.HP}"</strong>{" "}
          {string.OR}
          <strong>"{string.WE}"</strong> {string.OR}{" "}
          <strong>"{string.US}"</strong> {string.OR}{" "}
          <strong>“{string.LICENSEE}”</strong> {string.OR}{" "}
          <strong>“{string.OUR}”</strong>) {string.SUBSCRIBER_TERMS_CONTENT_1f}{" "}
          (<strong>"{string.YOU}"</strong> {string.OR}{" "}
          <strong>"{string.YOUR}"</strong> {string.OR}
          <strong>“{string.LICENSOR}”</strong>){" "}
          {string.SUBSCRIBER_TERMS_CONTENT_1e} <strong>“{string.PARTY}”</strong>{" "}
          {string.SUBSCRIBER_TERMS_CONTENT_1d}{" "}
          <strong>“{string.PARTIES}”</strong>.
        </p>
        <p className="contents">{string.PHOTOGRAPHERS_LICENSE_CONTENT_2}</p>
        <p className="contents">{string.PHOTOGRAPHERS_LICENSE_CONTENT_3}</p>
        <p className="contents">
          {string.SUBSCRIBER_TERMS_CONTENT_4a}{" "}
          <a
            href="/docs/terms/uk/photographer/subscription-terms-of-service.html"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            {string.HP_LIMITED_EDITION_PRINT_SERVICES1}
            <span>-</span>
            {string.SUBDCRIBER_TERMS_AND_SERVICE}{" "}
          </a>{" "}
          {string.PAYMENT_PT} (the{" "}
          <strong>“{string.SUBSCRIBER_TERMS_CONTENT_4c}”</strong>
          ).
        </p>
        <p className="contents">{string.SUBSCRIBER_TERMS_CONTENT_6}</p>
        <p className="contents">{string.PHOTOGRAPHERS_LICENSE_CONTENT_6}.</p>
        <ol>
          <li>
            <span className="main-list"> {string.INTERPRETATION}.</span>
            <div className="list-elements">
              <span>1.1</span>
              <div>{string.INTERPRETATION_PT_1}</div>
            </div>
            <div className="list-elements">
              <span>1.2</span>
              <div>{string.INTERPRETATION_PT_2}</div>
            </div>
            <div className="list-elements">
              <span>1.3</span>
              <div>{string.INTERPRETATION_PT_3}</div>
            </div>
            <div className="list-elements">
              <span>1.4</span>
              <div>{string.INTERPRETATION_PT_4}</div>
            </div>
            <div className="list-elements">
              <span>1.5</span>
              <div>{string.INTERPRETATION_PT_5}</div>
            </div>
            <div className="list-elements">
              <span>1.6</span>
              <div>{string.INTERPRETATION_PT_6}</div>
            </div>
            <div className="list-elements">
              <span>1.7</span>
              <div>{string.INTERPRETATION_PT_7}</div>
            </div>
            <div className="list-elements">
              <span>1.8</span>
              <div>{string.INTERPRETATION_PT_9}</div>
            </div>
          </li>
          <li>
            <span className="main-list"> {string.DEFINITIONS}.</span>
            <p className="list-element-content">
              <strong>{string.ACCEPTED_IMAGES}: </strong>{" "}
              {string.DEFINITIONS_PT_1}
            </p>
            <p className="list-element-content">
              <strong>{string.AFFILIATE}: </strong> {string.DEFINITIONS_PT_2}
            </p>
            <p className="list-element-content">
              <strong>{string.COLLECTOR}: </strong> {string.DEFINITIONS_PT_P3}
            </p>
            <p className="list-element-content">
              <strong>{string.CONFIDENTIAL_INFORMATION}: </strong>{" "}
              {string.DEFINITIONS_PT_P4}
            </p>
            <p className="list-element-content">
              <strong>{string.DOCUMENTATION}: </strong>
              {string.DEFINITIONS_PT_7}
            </p>
            <p className="list-element-content">
              <strong>{string.HP_ENTERPRISE_LEDGER}: </strong>{" "}
              {string.DEFINITIONS_PT_5}
            </p>
            <p className="list-element-content">
              <strong>{string.DIGITAL_IMAGE}: </strong>{" "}
              {string.DEFINITIONS_PT_P6}
            </p>
            <p className="list-element-content">
              <strong>{string.INTELLECTUAL_PROPERTY_RIGHTS}: </strong>
              {string.DEFINITIONS_PT_P8}
            </p>
            <p className="list-element-content">
              <strong>{string.LICENSOR_CONTENT}: </strong>
              {string.LICENSOR_CONTENT_TEXT}
            </p>
            <p className="list-element-content">
              <strong>{string.NORMAL_BUISNESS_HOUR}: </strong>
              {string.DEFINITIONS_PT_9}
            </p>
            <p className="list-element-content">
              <strong>{string.RESELLER}: </strong>
              {string.RESELLER_TEXT}
            </p>
            <p className="list-element-content">
              <strong>{string.SERVICES}: </strong>
              {string.SERVICES_TEXT_1}{" "}
              <a
                href="/docs/terms/uk/photographer/subscription-terms-of-service.html"
                target="_blank"
                rel="noreferrer"
              >
                {string.HP_LIMITED_EDITION_PRINT_SERVICES1}
                <span>-</span>
                {string.SUBSCRIBER_TERMS}
              </a>
              .
            </p>
            <p className="list-element-content">
              <strong>{string.SOFTWARE}: </strong>
              {string.DEFINITIONS_PT_12}
            </p>
            <p className="list-element-content">
              <strong>{string.TERM}: </strong>
              {string.DEFINITIONS_PT_p17}
            </p>
            <p className="list-element-content">
              <strong>{string.VIRUS}: </strong>
              {string.VIRUS_TEXT_1}
            </p>
            <p className="list-element-content">
              <strong>{string.VULNERABILITY}: </strong>
              {string.DEFINITIONS_PT_19}{" "}
              <strong>“{string.VULNERABILITIES}”</strong>{" "}
              {string.DEFINITIONS_PT_20a}
            </p>
            <p className="list-element-content">
              <strong>{string.WEBSITE}: </strong>
              <a
                href="https://www.hplimiteditions.com/"
                target="_blank"
                rel="noreferrer"
              >
                https://www.hplimiteditions.com/
              </a>
              {string.DEFINITIONS_PT_P20}
            </p>
          </li>
          <li>
            <span className="main-list">
              {string.SUBMISSION_ACCEPTANCE_PROPERTY_IMAGES}
            </span>
            <div className="list-elements">
              <span>3.1</span>
              <div>{string.SUBMISSION_ACCEPTANCE_PROPERTY_IMAGES_PT_P1}</div>
            </div>
            <div className="list-elements">
              <span>3.2</span>
              <div>{string.SUBMISSION_ACCEPTANCE_PROPERTY_IMAGES_PT_P2}</div>
            </div>
            <div className="list-elements">
              <span>3.3</span>
              <div>
                {string.SUBMISSION_ACCEPTANCE_PROPERTY_IMAGES_PT_3a}: "[
                {string.CONTRIBUTORS}]"{" "}
                {string.SUBMISSION_ACCEPTANCE_PROPERTY_IMAGES_PT_3ab}
              </div>
            </div>
            <div className="list-elements">
              <span>3.4</span>
              <div>{string.SUBMISSION_ACCEPTANCE_PROPERTY_IMAGES_PT_4}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.PROPERTY_RIGHTS_LISENSE}</span>
            <div className="list-elements">
              <span>4.1</span>
              <div>
                {string.LICENSE_RIGHTS_TEXT_2}{" "}
                <strong>(“{string.LICENSE_RIGHTS}”)</strong>.{" "}
                {string.LICENSE_RIGHTS_TEXT_1}{" "}
                <strong>(“{string.LICENSE_FEE}”)</strong>.
              </div>
            </div>
            <div className="list-elements">
              <span>4.2</span>
              <div>{string.PROPERTY_RIGHTS_LISENSE_PT_P2}</div>
            </div>
            <div className="list-elements">
              <span>4.3</span>
              <div>
                <u>{string.PROPERTY_RIGHTS_LISENSE_PT_2u}.</u>{" "}
                {string.PROPERTY_RIGHTS_LISENSE_PT_P3}
              </div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.PRICING_PAYMENT}</span>
            <div className="list-elements">
              <span>5.1</span>
              <div>
                <u>{string.LICENSE_FEE}: </u> {string.PRICING_PAYMENT_PT1}
              </div>
            </div>
            <div className="list-elements">
              <span>5.2</span>
              <div>
                <u>{string.SELLING_PRICE_2}: </u> {string.PRICING_PAYMENT_PT2}{" "}
                <strong>(“{string.SELLING_PRICE_2}”)</strong>.{" "}
                {string.PRICING_PAYMENT_PT3}
              </div>
            </div>
            <div className="list-elements">
              <span>5.3</span>
              <div>{string.PRICING_PAYMENT_PT4}</div>
            </div>
            <div className="list-elements">
              <span>5.4</span>
              <div>{string.PRICING_PAYMENT_PT5}</div>
            </div>
            <div className="list-elements">
              <span>5.5</span>
              <div>{string.PRICING_PAYMENT_PT6}</div>
            </div>
            <div className="list-elements">
              <span>5.6</span>
              <div>{string.PRICING_PAYMENT_PT7}</div>
            </div>
            <div className="list-elements">
              <span>5.7</span>
              <div>{string.PRICING_PAYMENT_PT8}</div>
            </div>
            <div className="list-elements">
              <span>5.8</span>
              <div>
                <u>{string.PAYING_SERVICES_PT_9u}.</u>{" "}
                {string.PRICING_PAYMENT_PT9}
                <strong> “{string.PAYING_SERVICES_PT_9u}”</strong>
                {")"}. {string.PRICING_PAYMENT_PT10}
              </div>
            </div>
            <div className="list-elements">
              <span></span>
              <div>{string.PRICING_PAYMENT_PT11}</div>
            </div>
            <div className="list-elements">
              <span>5.9</span>
              <div>{string.PRICING_PAYMENT_PT12}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.CONFIDENTIALITY_AND_DATA}</span>
            <div className="list-elements">
              <span>6.1</span>
              <div>{string.CONFIDENTIALITY_AND_DATA_PT_P1}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.YOUR_UNDERTAKINGS}</span>
            <div className="list-elements">
              <span>7.1</span>
              <div>
                {string.YOUR_UNDERTAKINGS_PT_1}
                <ol type="i" className="suborder-list">
                  <li>{string.YOUR_UNDERTAKINGS_PT_P1}</li>
                  <li>{string.YOUR_UNDERTAKINGS_PT_1b}</li>
                  <li>{string.YOUR_UNDERTAKINGS_PT_1c}</li>
                  <li>{string.YOUR_UNDERTAKINGS_PT_1d}</li>
                  <li>{string.YOUR_UNDERTAKINGS_PT_1e}</li>
                  <li>{string.YOUR_UNDERTAKINGS_PT_1f}</li>
                  <li>
                    {string.YOUR_UNDERTAKINGS_PT_1g}
                    <ol type="a" className="suborder-list list-2">
                      <li>{string.YOUR_UNDERTAKINGS_PT_1g1}</li>
                      <li>{string.YOUR_UNDERTAKINGS_PT_1g2}</li>
                    </ol>
                  </li>
                </ol>
              </div>
            </div>
            <div className="list-elements">
              <span>7.2</span>
              <div>{string.YOUR_UNDERTAKINGS_PT_2}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.INDEMNITY}</span>
            <div className="list-elements">
              <div>{string.INDEMNITY_PT_P1}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.LIMITATION_AND_LIABILITY}</span>
            <div className="list-elements">
              <div>{string.LIMITATION_AND_LIABILITY_PT_1i}</div>
            </div>
            <div className="list-elements">
              <div>{string.LIMITATION_AND_LIABILITY_PT_P2}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.TERMS_AND_TERMINATION}</span>
            <div className="list-elements">
              <span>10.1.</span>
              <div>{string.TERMS_AND_TERMINATION_PT_P1}</div>
            </div>
            <div className="list-elements">
              <span>10.2</span>
              <div>{string.TERMS_AND_TERMINATION_PT_P2}</div>
            </div>
            <div className="list-elements">
              <span>10.3</span>
              <div>
                {string.TERMS_AND_TERMINATION_PT_4}
                <ol type="i" className="suborder-list">
                  <li>
                    {string.TERMS_AND_TERMINATION_PT_P3}
                    <ol type="a">
                      <li>{string.TERMS_AND_TERMINATION_PT_P4}</li>
                      <li>{string.TERMS_AND_TERMINATION_PT_P5}</li>
                      <li>{string.TERMS_AND_TERMINATION_PT_P6}</li>
                      <li>{string.TERMS_AND_TERMINATION_PT_4d}</li>
                    </ol>
                  </li>
                </ol>
              </div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.FORCE_MAJEURE}</span>
            <div className="list-elements">
              <div>{string.FORCE_MAJEURE_PT_1}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.VARIATION}</span>
            <div className="list-elements">{string.VARIATION_PT_2}</div>
          </li>
          <li>
            <span className="main-list">{string.WAIVER}</span>
            <div className="list-elements">{string.WAIVER_PT_1}</div>
          </li>
          <li>
            <span className="main-list">{string.RIGHTS_AND_REMEDIES}</span>
            <div className="list-elements">
              <div>{string.RIGHTS_AND_REMEDIES_PT_1}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.SEVERANCE}</span>
            <div className="list-elements">
              <div>{string.SEVERANCE_PT_1}</div>
            </div>
            <div className="list-elements">
              <div>{string.SEVERANCE_PT_P4}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.ENTIRE_AGREEMENT}</span>
            <div className="list-elements">
              <span>16.1</span>
              <div>{string.ENTIRE_AGREEMENT_PT_1}</div>
            </div>
            <div className="list-elements">
              <span>16.2</span>
              <div>{string.ENTIRE_AGREEMENT_PT_2}</div>
            </div>
            <div className="list-elements">
              <span>16.3</span>
              <div>{string.ENTIRE_AGREEMENT_PT_3}</div>
            </div>
            <div className="list-elements">
              <span>16.4</span>
              <div>{string.ENTIRE_AGREEMENT_PT_4}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.ASSIGNMENT}</span>
            <div className="list-elements">
              <span>17.1</span>
              <div>{string.ASSIGNMENT_PT_3}</div>
            </div>
            <div className="list-elements">
              <span>17.2</span>
              <div>{string.ASSIGNMENT_PT_2}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.NO_PARTNERSHIP_AGENCY}</span>
            <div className="list-elements">
              <div>{string.NO_PARTNERSHIP_AGENCY_PT_1}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.THIRD_PARTY_RIGHTS}</span>
            <div className="list-elements">
              <div>{string.THIRD_PARTY_RIGHTS_PT_1}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.NOTICES}</span>
            <div className="list-elements">
              <span>20.1</span>
              <div>
                {string.NOTICES_PT_1}
                <ol type="i" className="suborder-list">
                  <li>{string.NOTICES_PT_1a}</li>
                  <li>{string.NOTICES_PT_1b}</li>
                </ol>
              </div>
            </div>
            <div className="list-elements">
              <span>20.2</span>
              <div>{string.NOTICES_PT_2}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.GOVERNING_LAW}</span>
            <div className="list-elements">
              <div>{string.GOVERNING_LAW_PT_1}</div>
            </div>
          </li>
          <li>
            <span className="main-list">{string.JURISDICTION}</span>
            <div className="list-elements">
              <div>{string.JURISDICTION_PT_1}</div>
            </div>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default PaymentTermsOfService;
