//OPCS Documentation:
//OPCS -> Original Project Coordinate System
//All the postion, dimension are defined wrt OPCS.
//By default Crop rect is center aligned in OPCS; Crop rect size can vary depending on the selected size.

import {
  setOriginalProjectSize,
  setProjectCropRect,
} from "../Store/Project/ProjectSlice";
import { store } from "../Store/Store";
import { FRAMED, PROJECT_PREVIEW_MODE_INITIAL } from "./Constants";
import {
  getCropRectSizeInPixels,
  Rect,
  Size,
  updateImageProperty,
} from "./Helper";
import { fabric } from "fabric";

export const finalProjectSize = () => {
  //Final project Size = ProjectCropRect (this will exclude footer even if side bar shows with footer) + Footer.
  const { sizeSelectionDetails } = store.getState().project;
  const { selectedDimension } = sizeSelectionDetails;
  return getCropRectSizeInPixels(selectedDimension);
};

export const getCropDefaultPosition = () => {
  let { projectCropRect, originalProjectSize, projectImageScale } =
    store.getState().project;
  const cropDefaultLeft =
    (originalProjectSize.width * projectImageScale - projectCropRect.width) / 2;
  const cropDefaultTop =
    (originalProjectSize.height * projectImageScale - projectCropRect.height) /
    2;
  return { left: cropDefaultLeft, top: cropDefaultTop };
};

export const isFramed = () => {
  const { orderDetails } = store.getState().order;
  if (orderDetails) {
    return orderDetails.framed === "true";
  } else {
    const { frameType } = store.getState().frame;
    return frameType === FRAMED;
  }
};

export const loadProjectImage = (mode, onCompletion) => {
  fabric.Image.fromURL(
    getImageURL(),
    (myImage) => {
      const offscreenCanvas = new fabric.Canvas("offscreen-canvas", {
        backgroundColor: "",
        preserveObjectStacking: true,
      });
      offscreenCanvas.setWidth(myImage.width);
      offscreenCanvas.setHeight(myImage.height);
      offscreenCanvas.add(myImage);
      offscreenCanvas.renderAll();
      var renderedImg = new Image();
      renderedImg.crossOrigin = "Anonymous";
      renderedImg.src = offscreenCanvas.toDataURL({
        left: 0,
        top: 0,
        width: Math.ceil(myImage.width),
        height: Math.ceil(myImage.height),
      });
      renderedImg.onload = function () {
        //Once cropped image is generated, add it to preview as project-content.
        const rennderedFabricImg = new fabric.Image(renderedImg);

        updateImageProperty(rennderedFabricImg);
        if (mode === PROJECT_PREVIEW_MODE_INITIAL) {
          const cropRect = Rect(
            0,
            0,
            rennderedFabricImg.width,
            rennderedFabricImg.height
          );
          store.dispatch(setProjectCropRect(cropRect));
        }
        const { originalProjectSize } = store.getState().project;
        if (originalProjectSize.width === 0) {
          let projSize = Size(
            rennderedFabricImg.width,
            rennderedFabricImg.height
          );
          store.dispatch(setOriginalProjectSize(projSize));
        }
        onCompletion(rennderedFabricImg);
      };
    },
    { crossOrigin: "Anonymous" }
  );
};

const getImageURL = () => {
  const { uploadedImageData } = store.getState().project;
  // if (isMagnifierModeOn) {
  //   return uploadedImageData.originalImageURL;
  // }
  return uploadedImageData.imageURL;
};
