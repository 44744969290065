import React, { useState } from "react";
import OrderManagement from "./OrderManagement";
import OrderDetails from "../OrderDetails/OrderDetails";
import NavigateBackButton from "../../../Components/NavigateBackButton/NavigateBackButton";
import ProfileButton from "../../ProfileButton/ProfileButton";
import "./NavOrderManagement.scss";

export default function NavOrderManagement() {
  const [showOrdeManagment, setShowOrdeManagment] = useState(true);

  const onClickRow = () => {
    setShowOrdeManagment(false);
  };
  const onClickBack = () => {
    setShowOrdeManagment(true);
  };

  return (
    <div className="order-main-wrapper">
      {!showOrdeManagment ? (
        <div className="d-flex justify-content-between align-items-center">
          <NavigateBackButton onNavigateBack={onClickBack} />
          <ProfileButton />
        </div>
      ) : (
        <ProfileButton />
      )}

      {showOrdeManagment ? (
        <OrderManagement onClickRow={onClickRow} />
      ) : (
        <OrderDetails />
      )}
    </div>
  );
}
