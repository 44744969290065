import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Containers/Home/Home.jsx";
import { GlobalProvider } from "./Context/GlobalState";
import { Route, Routes } from "react-router-dom";
import ProfileUpdatePage from "./Containers/ProfileUpdatePage/ProfileUpdatePage";
import PaymentSuccess from "./Containers/OrderSummary/PaymentSuccess/PaymentSuccess";
import PaymentFailure from "./Containers/OrderSummary/PaymentFailure/PaymentFailure";
import OrderExhausted from "./Containers/OrderExhausted/OrderExhausted";
import ErrorPage from "./Containers/ErrorPage/ErrorPage";
import StartPrinting from "./Containers/StartPrinting/StartPrinting";
import DigitalCertficate from "./Containers/Certificate/DigitalCertficate/DigitalCertficate";
import SystemDownMessage from "./Containers/SystemDownMessage/SystemDownMessage";
import PersonalNotePage from "./Containers/PersonalNotePage/PersonalNotePage";
import { string } from "./Localization";
import PgsPayment from "./Containers/Order/Checkout/PgsPayment/PgsPayment";
import AddPersonalMessage from "./Components/AddPersonalMessage/AddPersonalMessage";
import Order from "./Containers/Order/Order";
import {
  PAYMENT_STEP,
  REVIEW_STEP,
  SUBMIT_ORDER_STEP,
} from "./Utils/Constants";
import TermsOfService from "./Containers/Docs/Terms/UK/Consumer/TermsOfService";
import SubscriptionTermsOfService from "./Containers/Docs/Terms/UK/Photographer/SubscriptionTermsOfService";
import PaymentTermsOfService from "./Containers/Docs/Terms/UK/Photographer/PaymentTermsOfService";
import AddTrackingNumber from "./Components/AddTrackingNumber/AddTrackingNumber";

import Admin from "./Containers/Admin/Admin";
import AdminUserProject from "./Containers/Admin/AdminUserProject/AdminUserProject";
import AdminOrderDetails from "./Containers/Admin/AdminOrderDetails/AdminOrderDetails";
import AdminProjectOrders from "./Containers/Admin/AdminProjectOrders/AdminProjectOrders";
import Layout from "./Containers/Admin/Layout/Layout";
import PhotographerGallery from "./Containers/PhotographerGallery/PhotoGrapherGallery";
import PhotographerPhoto from "./Containers/PhotographerPhoto/PhotographerPhoto";
import PublicGallery from "./Containers/PublicGallery/PublicGallery";
function App() {
  return (
    <>
      <GlobalProvider>
        <>
          <SystemDownMessage />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/web/projects/:project_token/preview"
              element={<Order currentStep={SUBMIT_ORDER_STEP} />}
            />
            <Route path="/profile" element={<ProfileUpdatePage />} />
            <Route
              path="/web/orders/:order_token/payment_success"
              element={<PaymentSuccess />}
            />
            <Route
              path="/web/orders/:order_token/payment_failure"
              element={<PaymentFailure />}
            />
            <Route
              path="/web/projects/:project_token/exhaust_edition"
              element={<OrderExhausted />}
            />
            <Route
              path="/web/orders/:order_token/start_print"
              element={<StartPrinting />}
            />
            <Route
              path="/web/orders/:order_token/:sec_token/add_personal_message"
              element={<AddPersonalMessage />}
            />
            <Route
              path="/web/orders/:token/preview"
              element={<DigitalCertficate finalPrint={true} />}
            />
            <Route
              path="/triggered_for_printing_with_personal_note"
              element={
                <PersonalNotePage
                  PersonalNote_title={string.TITLE_WITH_PERSONAL_NOTE}
                />
              }
            />
            <Route
              path="/triggered_for_printing_without_personal_note"
              element={
                <PersonalNotePage
                  PersonalNote_title={string.TITLE_WITHOUT_PERSONAL_NOTE}
                />
              }
            />
            <Route
              path="/web/orders/:order_token/checkout"
              element={<Order currentStep={PAYMENT_STEP} />}
            />
            <Route
              path="/web/orders/:order_token/payment"
              element={<PgsPayment />}
            />
            <Route
              path="/web/orders/:order_token/review"
              element={<Order currentStep={REVIEW_STEP} />}
            />
            <Route
              path="/docs/terms/uk/consumer/terms-of-service.html"
              element={<TermsOfService />}
            />
            <Route
              path="/docs/terms/uk/photographer/subscription-terms-of-service.html"
              element={<SubscriptionTermsOfService />}
            />
            <Route
              path="/docs/terms/uk/photographer/payment-terms-of-service.html"
              element={<PaymentTermsOfService />}
            />
            <Route
              path="/web/orders/:order_token/:sec_token/add_tracking_info"
              element={<AddTrackingNumber />}
            />
            <Route
              path="/triggered_for_adding_tracking_info"
              element={
                <PersonalNotePage
                  PersonalNote_title={string.TITLE_ALREADY_SET_TRACKING_NUMBER}
                />
              }
            />
            <Route path="/gallery" element={<PublicGallery />} />
            <Route
              path="/gallery/:user_name"
              element={<PhotographerGallery />}
            />
            <Route
              path="/gallery/designs/:project_token"
              element={<PhotographerPhoto />}
            />
            <Route
              path="/mygallery/:user_name"
              element={<PhotographerGallery />}
            />
            <Route
              path="/mygallery/designs/:project_token"
              element={<PhotographerPhoto />}
            />

            <Route path="/page_not_found" element={<ErrorPage />} />
            <Route path="/admin" element={<Layout />}>
              <Route element={<Admin />} index />
              <Route
                path="order_details/:order_token"
                element={<AdminOrderDetails />}
              />
              <Route
                path="project_orders/:project_token"
                element={<AdminProjectOrders />}
              />
              <Route
                path="user_project/:user_token"
                element={<AdminUserProject />}
              />
            </Route>
          </Routes>
        </>
      </GlobalProvider>
    </>
  );
}

export default App;
