import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import "./SearchResultModal.scss";
import Closebutton from "../../../Utils/Icons/close.svg";
import { uploadImageUsingUrl } from "../../../Utils/ApiHelper";
import { processUploadResponse } from "../../../Utils/projectHelper";
import { string } from "../../../Localization";
import { showOrHideApiLoadingModal } from "../../../Store/Project/ModalSlice";
import { useDispatch, useSelector } from "react-redux";
import SearchByAIError from "../SearchByAiError/SearchByAiError";
import backArrowSvg from "../../../Utils/Icons/arrow_back.svg";
import ModalFooter from "../../ModalFooter/ModalFooter";
import EditIcon from "../../../Utils/Icons/edit.svg";
import {
  sendGACheckedTermsWithAI,
  sendGAUncheckedTermsWithAI,
  sendGAClickedAIGenerateMore,
  sendGAUploadedPhoto,
} from "../../../Utils/GAHelper";
import {
  setAiOrientation,
  setAiOrientationKey,
} from "../../../Store/Project/ProjectSlice";
import ProjectSpinner from "../../Loaders/ProjectSpinner/ProjectSpinner";

const SearchResultModal = ({
  isOpen,
  onClose,
  searchResults,
  searchText,
  setSearchText,
  handleSearchClick,
  handleEdit,
  privacyPolicy,
  setPrivacyPolicy,
  setResultUrl,
}) => {
  const dispatch = useDispatch();
  const { footerLinksData } = useSelector((state) => state.cache);

  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [imgLoadedCount, setImgLoadedCount] = useState(0);

  useEffect(() => {
    if (!isOpen) {
      setSelectedImageUrl(null);
    }
  }, [isOpen]);

  const handleCloseClick = () => {
    setResultUrl([]);
    dispatch(setAiOrientation("Landscape"));
    dispatch(setAiOrientationKey(0));
    setSearchText("");
    setErrorMessage("");
    onClose(false);
    setPrivacyPolicy(false);
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = currentDate.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleSelectedPhoto = () => {
    if (selectedImageUrl) {
      onClose(false);
      dispatch(showOrHideApiLoadingModal(true));
      sendGAUploadedPhoto(true); //argument true => AI

      const formData = new FormData();
      formData.append("project[imageUrl]", selectedImageUrl);
      formData.append("project[edited_date]", getCurrentDate());
      formData.append("project[title]", searchText);

      const inDetails = {
        name: `${searchText.slice(0, 40)}`,
        dimensions: "1792 X 1024",
        size: "3",
        date: getCurrentDate(),
        preview: selectedImageUrl,
      };
      uploadImageUsingUrl(formData)
        .then((res) => {
          processUploadResponse(res, inDetails);
          dispatch(showOrHideApiLoadingModal(false));
        })
        .catch((error) => {
          setErrorMessage(error?.response?.data?.error_message);
          console.log("error", error);
          dispatch(showOrHideApiLoadingModal(false));
        });
    }
  };

  const handleRegeneratePhoto = () => {
    setSelectedImageUrl(null);
    handleSearchClick();
    setImgLoadedCount(0);
    sendGAClickedAIGenerateMore();
  };

  const increamentLoadCount = () => {
    setImgLoadedCount((prevCount) => prevCount + 1);
  };

  return (
    <>
      <Modal show={isOpen} className="search-result-container-wrap">
        <ModalBody className="search-result-modal-container">
          <div className="close-btn-container">
            <div className="search-result-back-container" onClick={handleEdit}>
              <img src={backArrowSvg} alt="back"></img>
              <div className="back-text">{string.EDIT}</div>
            </div>
            <img
              className="close-btn"
              src={Closebutton}
              onClick={handleCloseClick}
              alt="Close"
            />
          </div>
          <div className="search-result-body">
            <h4 className="search-result-header">
              {string.WE_HAVE_CREATED_PHOTO_FOR_YOU}
            </h4>
            <div className="prompt-container">
              <h3 className="edit-prompt">
                {string.PROMPT} <span>{searchText}</span>
              </h3>
              <img
                src={EditIcon}
                className="prompt-edit-icon"
                onClick={handleEdit}
                alt="edit"
              />
            </div>
            <div className="search-result-photos">
              {searchResults.length > 0 ? (
                searchResults.map((photo, index) => (
                  <>
                    {imgLoadedCount < searchResults.length && (
                      <ProjectSpinner
                        isLoading={true}
                        customClass="ai-result-spinner"
                      />
                    )}
                    <img
                      key={index}
                      src={photo}
                      className={`search-result-photo ${
                        selectedImageUrl
                          ? selectedImageUrl === photo
                            ? "selected"
                            : "not-selected"
                          : ""
                      }`}
                      alt={`Result ${index + 1}`}
                      onLoad={() => {
                        setSelectedImageUrl(photo);
                        increamentLoadCount();
                      }}
                    />
                  </>
                ))
              ) : (
                <div className="search-result-photo"></div>
              )}
            </div>
            <div className="terms-container">
              <label className="checkbox-container">
                <input
                  type="checkbox"
                  checked={privacyPolicy}
                  onChange={() => {
                    setPrivacyPolicy(!privacyPolicy);
                    if (!privacyPolicy) {
                      sendGACheckedTermsWithAI();
                    } else {
                      sendGAUncheckedTermsWithAI();
                    }
                  }}
                />
                <span className="checkmark"></span>
              </label>

              <p className="upload-image-privacy-policy">
                {string.I_HAVE_READ_AND_ACCEPT_THE}&nbsp;
                <a
                  href={footerLinksData["privacy-statement"]}
                  target="_blank"
                  rel="noreferrer"
                >
                  {string.HP} {string.PRIVACY_STATEMENT}
                </a>
                &nbsp;{string.AND}&nbsp;
                <a
                  href={footerLinksData["terms-and-conditions"]}
                  target="_blank"
                  rel="noreferrer"
                >
                  {string.TERMS_AND_CONDITIONS}
                </a>
              </p>
            </div>
            <div className="search-result-button-container">
              <Button
                className={`search-result-button select-btn ${
                  !selectedImageUrl || !privacyPolicy ? "disabled" : ""
                }`}
                onClick={handleSelectedPhoto}
                disabled={!selectedImageUrl || !privacyPolicy}
              >
                {string.USE_PHOTO}
              </Button>
              <Button
                className="search-result-button generate-more-btn"
                onClick={handleRegeneratePhoto}
              >
                {string.GENERATE_MORE}
              </Button>
            </div>
          </div>
          <div className="search_result_footer">
            <ModalFooter />
          </div>
        </ModalBody>
      </Modal>
      <SearchByAIError
        isOpen={errorMessage}
        onClose={handleCloseClick}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default SearchResultModal;
