import React, { useContext, useEffect, useRef, useState } from "react";
import "./SignatureUpload.scss";
import { useDispatch, useSelector } from "react-redux";
import CustomRadiobutton from "../CustomRadiobutton/CustomRadiobutton";
import SignatureModal from "../SignatureModal/SignatureModal";
import Dropzone from "react-dropzone";
import { DragDropContainer } from "react-drag-drop-container";
import {
  convertBytesToMegaBytes,
  getImageDimension,
  formatDateTime,
  getFileExtension,
  getPreviewUrlFromFile,
} from "../../../Utils/Helper";
import {
  UPLOAD,
  DRAW,
  PROFILE,
  STEP_TWO_PERSONALISATION,
  STEP_THREE_CERTIFICATE,
} from "../../../Utils/Constants";
import {
  setCanShowSignature,
  setCoordsAltered,
  setDrawSignatureCache,
  setSignatureImage,
  setSignatureType,
} from "../../../Store/Project/signatureSlice";
import { setCurrentUserDetails } from "../../../Store/Project/UserSlice";
import StatusModal from "../../../Components/StatusModal/StatusModal";
import { setCanShowErrorAlertModal } from "../../../Store/Project/ProjectSlice";
import { store } from "../../../Store/Store";
import error_icon from "../../../Utils/Icons/error_icon.svg";
import editIcon from "../../../Utils/Icons/edit.svg";
import {
  hasProfileSignature,
  setProfileSignature,
  showSavedSignatureinPreview,
  showSignatureinPreview,
} from "../../../Utils/signatureHelper";
import { GlobalContext } from "../../../Context/GlobalState";
import { string } from "../../../Localization";
import { sendGASelectedSignatureType } from "../../../Utils/GAHelper";

// signatureImage is used in the project and certificate. Hence kept in Redux
// profileSignatureImage is used only in profile page. If user click save changes then only it is considred. hence its kept in local state.

const SIGNATURE_UPLOAD_DESCRIPTION =
  "The signature should be a png with transparent background or svg and a minimum of 500 px x 500 px";
const SIGNATURE_DRAG_DESCRIPTION =
  "Reposition and resize to personalize your signature";

const SignatureUpload = (props) => {
  const { uploadText, onChangeProfileSignature, isProfilePage } = props;

  const dispatch = useDispatch();
  const { croppedImageCanvas } = useContext(GlobalContext);

  const { CanShowErrorAlertModal } = store.getState().project;

  const { activeStep } = useSelector((state) => state.page);
  const { currentUserDetails } = useSelector((state) => state.user);
  const { signatureImage, signatureType } = useSelector(
    (state) => state && state.signature
  );

  const [profileSignatureImage, setProfileSignatureImage] = useState("");
  const [imageDetails, setImageDetails] = useState(null);
  const [inputImage, setInputImage] = useState({});
  const [canOpenModal, setCanOpenModal] = useState(false);

  const mounted = useRef();

  useEffect(() => {
    if (isProfilePage) {
      dispatch(setSignatureType(currentUserDetails?.signature_type));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserDetails?.signature_type]);

  useEffect(() => {
    if (signatureType) sendGASelectedSignatureType(signatureType);
  }, [signatureType]);

  const handleDrawSignature = (drawImage) => {
    onChangeProfileSignature && onChangeProfileSignature(DRAW, drawImage);
    dispatch(
      setCurrentUserDetails({
        ...currentUserDetails,
        signature_type: DRAW,
      })
    );
    const blob = new Blob([drawImage], { type: "image/svg+xml" });
    const blobUrl = URL.createObjectURL(blob);

    setProfileSignatureImage(blobUrl);
  };

  const onDrop = (files) => {
    if (files.length > 0) {
      let fileUploaded = files[0];
      const fileExtension = getFileExtension(fileUploaded.name);
      const allowedExtensions = ["svg", "png"];

      if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
        dispatch(setCanShowErrorAlertModal(true));
        return;
      }
      if (!isProfilePage) setCanOpenModal(true);
      setInputImage(fileUploaded);

      getImageDimension(fileUploaded).then((imageDimensions) => {
        setImageDetails({
          name: fileUploaded.name,
          dimensions: imageDimensions,
          size: convertBytesToMegaBytes(fileUploaded.size),
          date: formatDateTime(new Date(fileUploaded.lastModified)),
          extension: getFileExtension(fileUploaded.name),
          preview: getPreviewUrlFromFile(fileUploaded),
        });
      });

      // Profile UI related
      onChangeProfileSignature &&
        onChangeProfileSignature(UPLOAD, fileUploaded);

      dispatch(
        setCurrentUserDetails({
          ...currentUserDetails,
          signature_type: UPLOAD,
        })
      );

      if (isProfilePage) {
        setProfileSignatureImage(getPreviewUrlFromFile(fileUploaded));
      }
    }
  };

  const modalHandler = (canOpen) => {
    setCanOpenModal(canOpen);
  };

  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      mounted.current = true;
    } else {
      if (signatureType !== PROFILE) {
        dispatch(setSignatureImage(null));

        if (!isProfilePage) {
          dispatch(setCanShowSignature(false));
          dispatch(setCoordsAltered(false));
        }
      }
    }

    setProfileSignatureImage("");
    if (!isProfilePage && signatureType === PROFILE) {
      dispatch(setCanShowSignature(false));
    }
    if (signatureType === PROFILE) {
      setProfileSignature().then(() => {
        if (!isProfilePage && activeStep === STEP_TWO_PERSONALISATION) {
          showSavedSignatureinPreview(croppedImageCanvas);
        }
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signatureType]);

  useEffect(() => {
    if (signatureImage && signatureType === DRAW) {
      dispatch(setDrawSignatureCache(signatureImage));
    }
  }, [signatureImage, signatureType]);

  const drawSignatureUI = (imgSrc) => (
    <div className="d-flex">
      <div className="signature-container">
        <div>
          <img
            draggable="false"
            className="signature-image"
            src={imgSrc}
            alt=""
          />
        </div>
      </div>
      {signatureType === DRAW && (
        <img
          src={editIcon}
          alt=""
          className="sign-edit"
          onClick={() => {
            dispatch(setCanShowSignature(false));
            setCanOpenModal(true);
          }}
        />
      )}
    </div>
  );

  const getDraggableSignature = () => {
    return (
      <>
        {activeStep === STEP_TWO_PERSONALISATION && (
          <div className="subOption-description">
            {SIGNATURE_DRAG_DESCRIPTION}
          </div>
        )}
        <DragDropContainer targetKey="dnd-signature" dragClone>
          {drawSignatureUI(signatureImage)}
        </DragDropContainer>
      </>
    );
  };

  const signatureUploadElements = () => {
    if (signatureType === UPLOAD) {
      if (signatureImage) {
        // Sidebar signature UI
        return getDraggableSignature();
      } else {
        // Profile signature UI
        let profileUploadSignature;
        if (profileSignatureImage) {
          profileUploadSignature = profileSignatureImage;
        } else if (currentUserDetails?.signature_type === UPLOAD) {
          profileUploadSignature = hasProfileSignature()
            ? currentUserDetails?.signature
            : null;
        }
        return (
          <>
            <div className="profile-signature-container">
              {isProfilePage && profileUploadSignature && (
                <img
                  draggable="false"
                  className="signature-image"
                  src={profileUploadSignature}
                  alt="siganture"
                />
              )}

              <Dropzone onDrop={onDrop} multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzonee" })}>
                    <input {...getInputProps()} />

                    <>
                      <div className="upload-btn-wrapper">
                        <button className="upload-signature">
                          {string.UPLOAD_SIGNATURE}
                        </button>
                        {isProfilePage &&
                          currentUserDetails?.signature_type === DRAW && (
                            <div className="subOption-description">
                              {SIGNATURE_UPLOAD_DESCRIPTION}
                            </div>
                          )}
                        {!isProfilePage &&
                          (activeStep === STEP_TWO_PERSONALISATION ||
                            activeStep === STEP_THREE_CERTIFICATE) && (
                            <div className="subOption-description">
                              {SIGNATURE_UPLOAD_DESCRIPTION}
                            </div>
                          )}
                      </div>
                    </>
                  </div>
                )}
              </Dropzone>
            </div>
          </>
        );
      }
    }
  };

  const signatureDrawElements = () => {
    if (signatureType === DRAW) {
      if (signatureImage) {
        // Sidebar signature UI
        return getDraggableSignature();
      } else {
        // Profile signature UI
        let profileDrawSignature;
        if (profileSignatureImage) {
          profileDrawSignature = profileSignatureImage;
        } else if (currentUserDetails?.signature_type === DRAW) {
          profileDrawSignature = hasProfileSignature()
            ? currentUserDetails?.signature
            : null;
        }
        return (
          <>{profileDrawSignature && drawSignatureUI(profileDrawSignature)}</>
        );
      }
    }
  };

  const subOptionStyle = hasProfileSignature() ? "signature-subOption2" : "";

  const handleClose = () => {
    dispatch(setCanShowErrorAlertModal(false));
  };

  return (
    <>
      <div>
        <div className="signature-subOption1">
          {hasProfileSignature() ? (
            <>
              <CustomRadiobutton
                name="radio2"
                id="radio1"
                title={string.USE_YOUR_SAVED_SIGNATURE}
                isChecked={signatureType === PROFILE}
                handleRadio={() => {
                  dispatch(setSignatureType(PROFILE));
                }}
              />
              {signatureImage &&
                signatureType === PROFILE &&
                getDraggableSignature()}
            </>
          ) : null}

          <div className={subOptionStyle}>
            <CustomRadiobutton
              name="radio2"
              id="radio2"
              title={uploadText}
              isChecked={signatureType === UPLOAD}
              handleRadio={() => {
                dispatch(setSignatureType(UPLOAD));
              }}
            />
            {signatureUploadElements()}
          </div>
        </div>

        <div className="signature-subOption2">
          <CustomRadiobutton
            name="radio2"
            id="radio3"
            title={string.DRAW_IT}
            isChecked={signatureType === DRAW}
            handleRadio={() => {
              dispatch(setSignatureType(DRAW));
              setCanOpenModal(true);
            }}
          />
          {signatureDrawElements()}
        </div>

        <SignatureModal
          isProfileUpdatePage={isProfilePage}
          signatureType={signatureType}
          imageDetails={imageDetails}
          inputImage={inputImage}
          canOpenModal={canOpenModal}
          modalHandler={modalHandler}
          onConfirmDrawSignature={handleDrawSignature}
        />
      </div>
      <StatusModal
        canShow={CanShowErrorAlertModal}
        stautsbackground={{ background: "#212121" }}
        statusimge={error_icon}
        statusHeading={string.INVALID_IMAGE_FORMAT}
        statusDescription={string.SIGNATURE_FORMATS}
        closebackground={{ background: "#212121" }}
        closeData={{
          closeBtn: string.CLOSE,
          closeHandler: handleClose,
        }}
      />
    </>
  );
};

export default SignatureUpload;
