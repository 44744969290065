import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Web3 from "web3";
import ProjectLoader from "../../../../Components/Loaders/ProjectLoader/ProjectLoader";
import BasicModal from "../../../../Components/Modal/BasicModal/BasicModal";
import { showOrHideApiLoadingModal } from "../../../../Store/Project/ModalSlice";
import {
  setCanShowStatusModal,
  setCanShowSuccessModal,
} from "../../../../Store/Project/ProjectSlice";
import infoIcon from "../../../../Utils/Icons/info.svg";
import { configureWallet } from "../../../../Utils/ApiHelper";
import { string } from "../../../../Localization";
import "./ConfigureWallet.scss";
import { Tooltip } from "@mui/material";

const ConfigureWallet = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [accountAddress, setAccountAddress] = useState();
  const [key, setKey] = useState();
  const [collection, setCollection] = useState();

  const dispatch = useDispatch();
  const { canShowStatusModal, canShowSuccessModal } = useSelector(
    (state) => state.project
  );

  const { user_wallet_configured } = useSelector(
    (state) => state.user.currentUserDetails
  );

  const { setRefresh, refresh } = props;

  // const checkForHardwareWallet = async () => {
  //   if (window.ethereum) {
  //     const connectedWeb3 = new Web3(window.ethereum);
  //     setConnected(connectedWeb3);

  //     window.ethereum.request({ method: "eth_requestAccounts" }).then((res) => {
  //       console.log("res", res);
  //       setAccountAddress(res[0]);
  //       // Request the network ID
  //       window.ethereum
  //         .request({ method: "net_version" })
  //         .then((networkId) => {
  //           console.log("Current network ID:", networkId);

  //           // Map network IDs to network names
  //           const networks = {
  //             1: "Mainnet",
  //             3: "Ropsten",
  //             4: "Rinkeby",
  //             5: "Goerli",
  //             42: "Kovan",
  //             // Add more networks as needed
  //           };

  //           // Get the network name based on the retrieved network ID
  //           const currentNetworkName = networks[networkId] || "Unknown Network";
  //           console.log("Current network:", currentNetworkName);
  //         })
  //         .catch((error) => {
  //           console.error("Error fetching network ID:", error);
  //         });
  //     });
  //   } else {
  //     console.error("Please install MetaMask or a compatible Ethereum wallet.");
  //   }
  // };

  const handlefailClose = () => {
    dispatch(setCanShowStatusModal(false));
    setShowModal(true);
  };

  const handleSuccessClose = () => {
    dispatch(setCanShowSuccessModal(false));
  };

  const handleConfigure = () => {
    dispatch(showOrHideApiLoadingModal(true));
    const payload = {
      address: accountAddress,
      private_key: key,
      collection_name: collection,
    };
    configureWallet(payload)
      .then((res) => {
        dispatch(showOrHideApiLoadingModal(false));
        if (res?.status == 200) {
          dispatch(setCanShowSuccessModal(true));
          setRefresh(!refresh);
        }
      })
      .catch((error) => {
        dispatch(showOrHideApiLoadingModal(false));
        if (error?.response?.status == 422) {
          dispatch(setCanShowStatusModal(true));
        }
      });
  };

  const trimInput = (value) => value.replace(/\s/g, "");

  const disabled = !accountAddress || !key || !collection;

  return (
    <>
      {user_wallet_configured ? (
        <>
          {" "}
          {/* <div className="admin_tooltip"> */}
          <div className="download-btn wallet-btn disbaled-btn-configure">
            {string.CONFIGURE_WALLET}
          </div>
          <Tooltip title="Wallet already configured" arrow>
            <img alt="" src={infoIcon} className="ms-2 admin_tooltip" />
          </Tooltip>
          {/* </div> */}
        </>
      ) : (
        <>
          <div
            onClick={() => {
              setShowModal(true);
            }}
            className="download-btn wallet-btn"
          >
            {string.CONFIGURE_WALLET}
          </div>
        </>
      )}

      <BasicModal
        openModal={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
      >
        <div className="configure-wallet-wrapper">
          <div className="cw-header mb-2">{string.CONFIGURE_WALLET}</div>
          <div className="mb-3">
            <div className="m-1">{string.ACCOUNT}: </div>
            <input
              type="text"
              className="cw-value"
              value={accountAddress}
              placeholder="Enter account address"
              onChange={(e) => {
                setAccountAddress(trimInput(e.target.value));
              }}
            />
          </div>
          <div className="mb-3">
            <div className="m-1">{string.PRIVATE_KEY}: </div>
            <input
              type="password"
              className="cw-value"
              value={key}
              placeholder="Enter private key"
              onChange={(e) => {
                setKey(trimInput(e.target.value));
              }}
            />
          </div>
          <div className="mb-3">
            <div className="m-1">Collection Name: </div>
            <input
              type="text"
              className="cw-value"
              value={collection}
              placeholder="Enter Collection Name"
              maxLength="20"
              onChange={(e) => {
                const newValue = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
                setCollection(trimInput(newValue));
              }}
            />

            <div className="m-2 note">
              {" "}
              Note: Collection names must consist solely of alphanumeric
              characters. The server will not append any additional characters.
              (Maximum characters: 20)
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div
              className={disabled ? "config-btn disbaled-btn" : "config-btn"}
              onClick={() => {
                setShowModal(false);
                handleConfigure();
              }}
            >
              {string.CONFIGURE}
            </div>
          </div>
        </div>
      </BasicModal>
      <BasicModal
        openModal={canShowSuccessModal}
        handleClose={() => {
          handleSuccessClose();
        }}
      >
        <div className="configure_success">{string.CONFIGURE_SUCCESS}</div>
      </BasicModal>
      <BasicModal
        openModal={canShowStatusModal}
        handleClose={() => {
          handlefailClose();
        }}
      >
        <div className="configure_success">{string.CONFIGURE_FAIL}</div>
      </BasicModal>
      <ProjectLoader />
    </>
  );
};

export default ConfigureWallet;
