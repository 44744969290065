import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { string } from "../../../Localization";
import { handleCustomerEdit } from "../../../Utils/OrderHelper";
import Asterick from "../../Asterick/Asterick";
import { sendGACollectorUpdatedCustomerDetails } from "../../../Utils/GAHelper";

const EditCustomerModal = ({ details }) => {
  const [customerDetail, setCustomerDetail] = useState({
    firstName: details?.first_name,
    lastName: details?.last_name,
    phone: details?.contact_number,
    email: details?.email,
  });
  const [error, setError] = useState({});

  const { order_token } = useParams();
  return (
    <>
      <div className="edit_text_box">
        <label htmlFor="">
          {string.FIRST_NAME}
          <Asterick />
        </label>
        <input
          type="text"
          className="input_title"
          placeholder={string.TYPE_YOUR_FIRST_NAME}
          value={customerDetail.firstName}
          onChange={(e) => {
            setCustomerDetail({
              ...customerDetail,
              firstName: e.currentTarget.value,
            });
          }}
        />{" "}
        <div className="error-div">{error[`${string.FIRST_NAME}`]}</div>
      </div>
      <div className="edit_text_box">
        <label htmlFor="">
          {string.LAST_NAME} <Asterick />
        </label>
        <input
          type="text"
          className="input_title"
          placeholder={string.TYPE_YOUR_LAST_NAME}
          value={customerDetail.lastName}
          onChange={(e) => {
            setCustomerDetail({
              ...customerDetail,
              lastName: e.currentTarget.value,
            });
          }}
        />{" "}
        <div className="error-div">{error[`${string.LAST_NAME}`]}</div>
      </div>
      <div className="edit_text_box">
        <label htmlFor="">
          {string.PHONE_NUMBER} <Asterick />
        </label>
        <input
          type="text"
          className="input_title"
          placeholder="000 000 000"
          value={customerDetail.phone}
          onChange={(e) => {
            setCustomerDetail({
              ...customerDetail,
              phone: e.currentTarget.value,
            });
          }}
        />{" "}
        <div className="error-div">{error[`${string.PHONE_NUMBER}`]}</div>
      </div>
      <div className="edit_text_box">
        <label htmlFor="">
          {string.EMAIL_ADDRESS} <Asterick />
        </label>
        <input
          type="text"
          className="input_title"
          placeholder="email@example.com"
          value={customerDetail.email}
          onChange={(e) => {
            setCustomerDetail({
              ...customerDetail,
              email: e.currentTarget.value,
            });
          }}
        />{" "}
        <div className="error-div">{error[`${string.EMAIL}`]}</div>
      </div>
      <div className="update_btn_container">
        {" "}
        <button
          className="update_button"
          onClick={() => {
            sendGACollectorUpdatedCustomerDetails();
            handleCustomerEdit(customerDetail, setError, order_token);
          }}
        >
          {string.UPDATE}
        </button>
      </div>
    </>
  );
};

export default EditCustomerModal;
